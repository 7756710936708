/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import FoodIllustration from 'libs/components/Illustrations/Food';
import { EditIcon } from 'libs/components/Icons';

/* Actions */
import actions from 'packs/meals/actions';

/**
 * MealEditingHeader
 */
const MealEditingHeader = ({
  actions,
  mealStore,
  translate,
  userMealOrderable,
}) => {
  const timezone = mealStore.get('timezone');
  const deadlineAt = moment(mealStore.get('deadlineAt')).tz(timezone);
  const orderableVariations = userMealOrderable.get('orderVariations') || userMealOrderable.get('cartVariations');

  const displayHeader = () => (
    translate('groupedOrdersMessagesEditingYourOrder')
  );

  const displayMessage = () => (
    translate(
      'groupedOrdersMessagesYouMakeChangesUpTill',
      { date: deadlineAt.format('dddd, D MMMM'), time: deadlineAt.format('h:mmA') },
    )
  );

  const handleConfirm = (e) => {
    e.preventDefault();

    if (orderableVariations.size === 0) {
      actions.resetUserMealCart(mealStore.get('mealPlanId'), userMealOrderable.get('uuid'));
    } else {
      actions.confirmUserMealCart(mealStore.get('mealPlanId'), userMealOrderable.get('uuid'));
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card card-panel meal-banner meal-banner--warning">
          <div className="card-body meal-banner__body">
            <div className="meal-banner__image" >
              <FoodIllustration />
            </div>
            <div className="meal-banner__content" >
              <h5>{displayHeader()}</h5>

              <p className="lead meal-banner__lead">
                {displayMessage()}
              </p>

              <div className="lead meal-banner__actions">
                <button
                  className="btn btn-bright font-600"
                  onClick={handleConfirm}
                >
                  <span className="mr-2"><EditIcon /></span>
                  {
                    orderableVariations.isEmpty()
                    ? translate('groupedOrdersActionsRemoveSelections')
                    : translate('groupedOrdersActionsSubmitOrderChanges')
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  userMealOrderable: state.$$mealStore.get('confirmed') ? state.$$userMealOrderStore : state.$$userMealCartStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

MealEditingHeader.propTypes = {
  actions: object.isRequired,
  mealStore: object.isRequired,
  translate: func.isRequired,
  userMealOrderable: object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(withI18n(MealEditingHeader)));
