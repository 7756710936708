/* eslint react/no-danger: 0 */

import React from 'react';
import { string, func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

const GuidedInfo = ({
  title,
  content,
  onClick,
  translate,
}) => (
  <div className="guided-info">
    <div className="guided-info__title">
      <span className="guided-info__title-badge">
        NEW
      </span>
      <span className="guided-info__title-text">
        { title }
      </span>
    </div>
    <div className="guided-info__content">
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>
    <div className="guided-info__actions">
      <button className="btn btn-light" onClick={onClick}>
        { translate('guidedInfoActionsGotIt') }
      </button>
    </div>
  </div>
);

GuidedInfo.propTypes = {
  title: string.isRequired,
  content: string.isRequired,
  onClick: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(GuidedInfo);
