/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from 'packs/meal_plan_users/actions';

import NewUserForm from './NewUserForm/';
import UserList from './UserList/';

/**
 * MealPlanUsers
 */

const MealPlanUsers = ({
  mealPlanUsersStore,
  translate,
}) => (
  <div className="tw-container-fluid md:!tw-container md:!tw-max-w-[1280px] tw-my-4 md:tw-mt-12">
    <div className="tw-grid tw-grid-cols-12">
      <div className="tw-col-span-12 tw-mb-4">
        <div className="card card-cart tw-mb-5 !tw-mx-4 md:!tw-mx-auto">
          {/* HEADER */}
          <div className="card-header">
            <div className="h6 text-uppercase tw-m-0 tw-py-3">
              { translate('mealPlanUsersTitle') }
            </div>
          </div>

          {
            mealPlanUsersStore.getIn(['userForm', 'show']) ? <NewUserForm /> : <UserList />
          }
        </div>
      </div>
    </div>
  </div>
);

MealPlanUsers.propTypes = {
  mealPlanUsersStore: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  mealPlanUsersStore: state.$$mealPlanUsersStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(MealPlanUsers));
