/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */
/* eslint react/no-danger: 0 */

import React, { Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notBlank } from 'libs/support/string';
import { prevent } from 'libs/support/prevent';
import { CircleCheckIcon } from 'libs/components/Icons';

/* Actions */
import { dismissFlash } from 'packs/meals/actions/flashActions';


/**
 * Flash
 */
const Flash = ({ error, success, actions }) => (
  <Fragment>
    {
      notBlank(error) &&
      <div className="alert alert-danger d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span
            className="text-center"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
        <button
          type="button"
          className="close mr-n3"
          onClick={prevent(actions.dismissFlash)}
        >
          &times;
        </button>
      </div>
    }

    {
      notBlank(success) &&
      <div className="alert alert-success d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="mb-2">
            <CircleCheckIcon />
          </span>
          <span
            className="text-center"
            dangerouslySetInnerHTML={{ __html: success }}
          />
        </div>
        <button
          type="button"
          className="close mr-n3"
          onClick={prevent(actions.dismissFlash)}
        >
          &times;
        </button>
      </div>
    }
  </Fragment>
);

Flash.propTypes = {
  error: string,
  success: string,
  actions: shape({
    dismissFlash: func.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  error: state.$$flashStore.get('error'),
  success: state.$$flashStore.get('success'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ dismissFlash }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
