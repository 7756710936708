import * as menuItemActions from 'packs/common/actions/menuItemActions';
import * as cartVariationsActions from 'packs/common/actions/cartVariationsActions';
import * as orderVariationsActions from 'packs/common/actions/orderVariationsActions';

/* Actions */
import * as mealActions from './mealActions';
import * as mealCartActions from './mealCartActions';
import * as customMenuItemActions from './customMenuItemActions';
import * as userMealCartActions from './userMealCartActions';
import * as userMealPlanSettingActions from './userMealPlanSettingActions';
import * as flashActions from './flashActions';

export default {
  // Meals
  ...mealActions,
  ...mealCartActions,
  ...customMenuItemActions,
  ...userMealCartActions,
  ...userMealPlanSettingActions,
  ...flashActions,

  // Common
  ...cartVariationsActions,
  ...orderVariationsActions,
  ...menuItemActions,
};
