import classNames from 'classnames';
import styled from 'styled-components';

export const Button = styled.button.attrs(props => ({
  className: classNames(
    'tw-border tw-border-broccoli-600 tw-bg-broccoli-600 ',
    'tw-text-base tw-text-white tw-font-bold',
    'tw-rounded tw-px-4 tw-py-1.5',
    'hover:!tw-no-underline focus:!tw-no-underline',
    'hover:!tw-bg-broccoli-500 hover:!tw-text-white',
    props.klass,
  ),
}))`
  display: flex;
`;

export const SecondaryButton = styled.button.attrs(props => ({
  className: classNames(
    'tw-border tw-border-broccoli-600 tw-bg-white',
    'tw-text-base tw-text-broccoli-600 tw-font-bold',
    'tw-rounded tw-px-4 tw-py-1.5',
    'hover:!tw-no-underline focus:!tw-no-underline',
    'hover:!tw-text-broccoli-600',
    props.klass,
  ),
}))`
  display: flex;
`;

export const Link = styled.a.attrs(props => ({
  className: classNames(
    'tw-border tw-border-broccoli-600 tw-bg-broccoli-600 ',
    'tw-text-base tw-text-white tw-font-bold',
    'tw-rounded tw-px-4 tw-py-1.5',
    'hover:!tw-no-underline focus:!tw-no-underline',
    'hover:!tw-bg-broccoli-500 hover:!tw-text-white',
    props.klass,
  ),
}))`
  display: flex;
`;

export const SecondaryLink = styled.a.attrs(props => ({
  className: classNames(
    'tw-border tw-border-broccoli-600 tw-bg-white',
    'tw-text-base tw-text-broccoli-600 tw-font-bold',
    'tw-rounded tw-px-4 tw-py-1.5',
    'hover:!tw-no-underline focus:!tw-no-underline',
    'hover:!tw-text-broccoli-600',
    props.klass,
  ),
}))`
  display: flex;
`;
