/* eslint no-return-assign: 0 */

import Immutable from 'immutable';

import { isNil } from 'lodash';
import { blankish, notBlank } from 'libs/support/string';
import { getOrderableVariationField } from 'packs/common/helpers/orderableVariation';
import { KIND_CHOICES, KIND_UPLOAD, KIND_TEXT } from 'packs/common/constants/addOnConstants';

function processOptions(props) {
  const { checked, finalData, itemName, lastName, parentName, parentId, id, quantity } = props;

  return finalData.update(parentName, data => {
    const index = data.findIndex(option => (option.get(lastName) === id) && (option.get('parent_id') === parentId));

    if (checked) {
      let params = { name: itemName, parent_id: parentId, quantity: quantity || 1 };
      params[lastName] = id;
      params = Immutable.fromJS(params);

      if (index < 0) return data.push(params);

      return data.setIn([index, 'quantity'], params.get('quantity'));
    }

    return data.delete(index);
  });
}

export function processValidAddOn(data, selectedOptions, forceValid = false) {
  let finalData = data;
  let count = 0;
  let isValid = false;

  if (forceValid) return finalData.set('is_valid', true);

  const originalMin = finalData.get('min_order_quantity');
  const originalMax = finalData.get('max_order_quantity');
  const kind = finalData.get('kind');
  const max = isNil(originalMax) ? 1 : originalMax;
  const min = isNil(originalMin) ? 1 : originalMin;

  selectedOptions.forEach(option => count += option.get('quantity'));
  finalData = finalData.set('total_quantity', count);

  if (((min !== max) && (count > min) && (count < max)) || (count === min) || (count === max)) {
    isValid = true;
  }

  if (blankish(originalMin) && notBlank(originalMax)) {
    isValid = (count === max);
  }

  if (blankish(originalMax) && notBlank(originalMin) && count >= min) isValid = true;

  if (blankish(originalMax) && blankish(originalMax)) isValid = true;

  selectedOptions.forEach(option => {
    if (kind === KIND_CHOICES) return;

    const text = option.get('text');
    const photos = option.get('photos');

    if (selectedOptions.size > 0 && kind === KIND_TEXT && blankish(text)) isValid = false;
    if (selectedOptions.size > 0 && kind === KIND_UPLOAD && (blankish(photos) || photos.size === 0)) isValid = false;
  });

  return finalData.set('is_valid', isValid);
}

export function checkAddOnValidity(response, defaultHash, $$state, type = 'cart') {
  const { parentType, parentId, lastName, name, value, checked, quantity } = response;
  const parentName = `${type}_variation_${name}`;

  const itemAddOns = $$state.getIn(['selectedMenuItem', `menu_item_${parentType}`]);
  const itemAddOnEntry = itemAddOns.findEntry(addOn => addOn.getIn(['choice', 'uuid']) === parentId);
  const itemAddOn = itemAddOnEntry[1].get('choice');
  const max = itemAddOn.get('max_order_quantity');
  const itemOption = itemAddOn.get('options').find(option => option.get('uuid') === value);
  const orderableVariationField = getOrderableVariationField(type);

  return $$state.withMutations(state => {
    state
      .update(orderableVariationField, data => {
        let finalData = data;

        const selectedOptions = $$state.getIn([orderableVariationField, parentName])
          .findEntry(option => option.get('parent_id') === parentId);

        if ((max === 1) && notBlank(selectedOptions) && value !== selectedOptions[1].get(lastName)) {
          finalData = finalData.deleteIn([parentName, selectedOptions[0]]);
        }

        return processOptions({
          checked,
          quantity,
          lastName,
          parentName,
          parentId,
          finalData,
          id: value,
          itemName: itemOption.get('name'),
        });
      })
      .updateIn(['selectedMenuItem', `menu_item_${parentType}`, itemAddOnEntry[0], 'choice'], data => {
        const selectedOptions = state.getIn([orderableVariationField, parentName])
          .filter(option => option.get('parent_id') === parentId);

        return processValidAddOn(data, selectedOptions);
      })
      .merge(defaultHash);
  });
}
