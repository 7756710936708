/* eslint max-len: 0, jsx-a11y/label-has-for: 0 */

import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

import BaseComponent from 'libs/components/BaseComponent';
import TypeNumberComponent from 'libs/components/TypeNumberComponent';
import { notBlank } from 'libs/support/string';
import { formattedPrice } from 'libs/support/formattedPrice';

import VariationInput from './VariationInput';

class MenuItemFormCartVariationVariationForm extends BaseComponent {
  static propTypes = {
    quantity: number,
    selectedVariationId: string,
    variations: object.isRequired,
    onSelectVariation: func.isRequired,
    showPrice: bool.isRequired,
    isEditable: bool.isRequired,
    mealLimit: object,
    minOrderQuantity: number,
    translate: func,
  };

  constructor(props) {
    super();

    this.state = { currentQuantity: props.quantity || 1 };
  }

  handleQuantityChange = (_previousQuantity, currentQuantity) => {
    this.setState({ currentQuantity });
  }

  render() {
    const {
      onSelectVariation,
      quantity,
      selectedVariationId,
      showPrice,
      variations,
      isEditable,
      mealLimit,
      mealAvailableLimit,
      minOrderQuantity,
      userTotalQuantity,
      translate,
    } = this.props;


    const minValue = minOrderQuantity || 1;
    const maxValue = mealAvailableLimit - userTotalQuantity;
    const usedQuantity = userTotalQuantity + this.state.currentQuantity;
    const withMealLimit = mealLimit && mealLimit.size > 0;
    const quantityLeft = withMealLimit && mealLimit.get('mealAvailableLimit') - userTotalQuantity;
    const exceededLimit = mealAvailableLimit < usedQuantity;
    const text = quantityLeft > 1 ? 'orders' : 'order';

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h6 className="display-6">
              {translate('formFieldsSelectVariation')}
            </h6>

            <div className="form-inline clearfix">
              <div className="variation-quantity">
                <TypeNumberComponent
                  disabled={!isEditable}
                  name="cart_variation[quantity]"
                  placeholder={minValue}
                  min={minValue}
                  max={maxValue}
                  value={quantity}
                  onChange={this.handleQuantityChange}
                />

                {
                  (parseInt(minOrderQuantity, 10) > 1) &&
                  <label>
                    <span className="text-muted">
                      {translate('cartVariationsFormMinOrderQuantity', { quantity: minOrderQuantity })}
                    </span>
                  </label>
                }
              </div>
            </div>
            {
              isEditable && withMealLimit && !exceededLimit &&
                <div>{translate('groupOrderLimitMessagesRemainingLimit', { quantityLeft, text })}</div>
            }

            {
              isEditable && withMealLimit && exceededLimit &&
                <div>{translate('groupOrderLimitMessagesExceededLimit', { periodType: mealLimit.get('periodType') })}</div>
            }
          </div>
        </div>

        {
          (variations.size > 1) ?
            <div className="row">
              <div className="col-sm-6">
                <h6 className="display-6">
                  {translate('formFieldsSelect')}
                </h6>

                {
                  variations.map((variation, index) => {
                    const id = variation.get('uuid');

                    let isChecked = index === 0;

                    if (notBlank(selectedVariationId)) isChecked = (selectedVariationId === id);

                    return (
                      <VariationInput
                        key={`variation-${id}`}
                        disabled={!isEditable}
                        isChecked={isChecked}
                        id={id}
                        name={variation.get('name')}
                        price={formattedPrice(variation.get('price'))}
                        servingsDescription={variation.get('servings_description_string')}
                        onSelectVariation={onSelectVariation}
                        showPrice={showPrice}
                      />
                    );
                  })
                }
              </div>
            </div> :
            <input
              type="hidden"
              name="cart_variation[variation_id]"
              value={variations.get(0).get('uuid')}
            />
        }
      </div>
    );
  }
}

export default withI18n(MenuItemFormCartVariationVariationForm);
