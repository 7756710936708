/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { Fragment, useEffect } from 'react';
import { withI18n } from 'libs/support/i18n';
import { any, bool, func, string } from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import classNames from 'classnames';

const CustomAlertContainer = ({
  dimension,
  title,
  message,
  onSubmit,
  onClose,
  cancelText,
  submitText,
  submitButtonClassName,
  cancelButtonClassName,
}) => (
  <div className={classNames('tw-bg-white', dimension, {
    'tw-p-6 tw-rounded-lg tw-shadow-lg': true,
    'tw-border-1 tw-border-gray-100': true,
  })}
  >
    <div className="tw-flex tw-flex-col tw-gap-1 tw-p-4 tw-text-center">
      <h5>{title}</h5>
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </div>
    <div className="tw-flex tw-gap-2 tw-mt-2">
      <button
        className={classNames(
          'tw-flex-1 tw-py-1.5 tw-px-4 tw-rounded tw-border-[1px]',
          cancelButtonClassName,
        )}
        onClick={onClose}
      >
        {cancelText}
      </button>
      {submitText && (
        <button
          className={classNames(
            'tw-flex-1 tw-py-1.5 tw-px-4 tw-rounded tw-border-[1px]',
            submitButtonClassName,
          )}
          onClick={(e) => {
            onSubmit(e);
            onClose(e);
          }}
        >
          {submitText}
        </button>
      )}
    </div>
  </div>
);

CustomAlertContainer.propTypes = {
  dimension: string,
  title: any,
  message: any,
  onSubmit: any,
  onClose: any,
  cancelText: any,
  submitText: any,
  submitButtonClassName: string,
  cancelButtonClassName: string,
};

const AlertBox = ({
  show,
  title,
  message,
  submitText,
  cancelText,
  onSubmit,
  onCancel,
  dimension = 'tw-min-w-80 tw-max-w-sm',
  submitButtonClassName = 'tw-bg-broccoli-600 tw-text-white tw-text-sm tw-border-broccoli-600',
  cancelButtonClassName = 'tw-bg-gray-100 tw-text-gray-900 tw-text-sm tw-border-gray-200',
}) => {
  const options = {
    title,
    message,
    closeOnEscape: false,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => { },
    afterClose: onCancel,
    onClickOutside: () => { },
    onKeypress: () => { },
    onKeypressEscape: () => { },
    overlayClassName: 'overlay-custom-alert-box',
    customUI: ({ onClose }) =>
      (<CustomAlertContainer {...{
          onClose,
          onSubmit,
          submitText,
          cancelText,
          title,
          message,
          submitButtonClassName,
          cancelButtonClassName,
          dimension,
        }}
      />),
  };

  const showAlert = () => confirmAlert(options);

  useEffect(() => {
    if (show) showAlert();
  }, [show]);

  return (
    <Fragment />
  );
};

AlertBox.propTypes = {
  show: bool,
  title: string,
  message: string,
  submitText: string,
  cancelText: string,
  dimension: string,
  onSubmit: func,
  onCancel: func,
  translate: func,
  submitButtonClassName: string,
  cancelButtonClassName: string,
};

export default withI18n(AlertBox);
