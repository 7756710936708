/* eslint-disable no-alert */
/* eslint-disable no-shadow */
import React, { Fragment, useEffect, useReducer } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import Immutable from 'immutable';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { fetchUserCreditCards, createUserCreditCard } from 'libs/services/userCreditCardsApi';
import { formattedPrice } from 'libs/support/formattedPrice';
import { blankish } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';
import CreditCardOption from './CreditCardOption';

/**
 * StripePaymentForm
 */

const StripePaymentForm = ({
  amountToBePaid,
  checkoutIsAllowed,
  mealPlanId,
  onCheckout,
  translate,
  stripe,
  userMealOrderableId,
}) => {
  const [state, setState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      creditCards: [],
      isCheckingOut: false,
      isNewCard: true,
      name: '',
      selectedCardId: '',
    },
  );

  useEffect(() => {
    // Get User credit cards
    fetchUserCreditCards(
      mealPlanId,
      (data) => {
        const defaultCard = data[0] || { uuid: '' };
        setState({
          isNewCard: data.length === 0,
          selectedCardId: defaultCard.uuid,
          creditCards: Immutable.fromJS(data),
        });
      },
    );
  }, [mealPlanId]);

  const handleOnCheckout = (cardId) => {
    onCheckout(mealPlanId, userMealOrderableId, { user_credit_card_id: cardId });
  };

  const handleOnOrderNow = () => {
    if (!checkoutIsAllowed) {
      alert('Your cart is empty, Please add items before paying');
      return;
    }

    setState({ isCheckingOut: true });
    if (!state.isNewCard) {
      handleOnCheckout(state.selectedCardId);
    } else {
      stripe.createToken({ name: state.name }).then(result => {
        if (blankish(result.error)) {
          createUserCreditCard(
            mealPlanId,
            { payment_method_nonce: result.token.id },
            (creditCard) => {
              setState({ selectedCardId: creditCard.uuid });
              handleOnCheckout(creditCard.uuid);
            },
            (_) => {
              alert('Failed to process your card');
              setState({ isCheckingOut: false });
            },
          );
        } else {
          alert(result.error.message);
          setState({ isCheckingOut: false });
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="card-footer payment-form">
        <div className="row">
          <div className="col-6">
            <h6 className="my-2">
              {translate('userMealCartsPaymentFormTitle')}
            </h6>
          </div>

          <div className="col-6 d-flex justify-content-end align-items-center">
            {
              state.isNewCard ?
              state.creditCards.size > 0 &&
              <a
                role="button"
                style={{ cursor: 'pointer' }}
                tabIndex={0}
                onClick={_ => setState({ isNewCard: false })}
              >
                {translate('userMealCartsPaymentFormTermsUseExistingCard')}
              </a> :
              <a
                role="button"
                style={{ cursor: 'pointer' }}
                tabIndex={0}
                onClick={_ => setState({ isNewCard: true })}
              >
                {translate('userMealCartsPaymentFormTermsAddNewCard')}
              </a>
            }
          </div>
        </div>

        <br />

        { /* New Card Form */ }
        {
          state.isNewCard &&
          <div className="form-group row">
            <div className="col-sm-12 mb-2">
              <input
                type="text"
                required="required"
                className="form-control"
                placeholder="Name"
                onChange={e => setState({ name: e.target.value })}
                value={state.name}
                id="card-name"
              />
            </div>

            <div className="col-sm-12 mb-2">
              <CardNumberElement
                placeholder="Card Number"
                classes={{ base: 'form-control', focus: 'form-control-focus' }}
              />
            </div>

            <div className="col-sm-6 mb-2">
              <CardExpiryElement
                classes={{ base: 'form-control', focus: 'form-control-focus' }}
              />
            </div>

            <div className="col-sm-6 mb-2">
              <CardCVCElement
                classes={{ base: 'form-control', focus: 'form-control-focus' }}
              />
            </div>
          </div>
        }

        { /* Existing Card Form */ }
        {
          !state.isNewCard && state.creditCards.size > 0 &&
          <div className="cc-option-container">
            {
              state.creditCards.map((card) => (
                <CreditCardOption
                  card={card}
                  isDefault={state.selectedCardId === card.get('uuid')}
                  key={`cc-${card.get('uuid')}`}
                  onChange={card => setState({ selectedCardId: card.get('uuid') })}
                />
              ))
            }
          </div>
        }

        <p style={{ color: 'darkgray' }}>
          { translate('userMealCartsTermsOrderNowNotice') }
        </p>

        <div className="form-group row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-primary btn-block"
              disabled={state.isCheckingOut}
              onClick={handleOnOrderNow}
            >
              {
                state.isCheckingOut ?
                translate('userMealCartsTermsOrdering', { amount: formattedPrice(amountToBePaid) }) :
                translate('userMealCartsTermsOrderNow', { amount: formattedPrice(amountToBePaid) })
              }
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StripePaymentForm.propTypes = {
  amountToBePaid: number.isRequired,
  checkoutIsAllowed: bool.isRequired,
  mealPlanId: string.isRequired,
  onCheckout: func.isRequired,
  translate: func.isRequired,
  stripe: object.isRequired,
  userMealOrderableId: string.isRequired,
};

export default withI18n(injectStripe(StripePaymentForm));
