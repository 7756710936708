import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/orderVariationsConstants';

export function fetchOrderVariation(mealPlanId, id) {
  const types = [
    actionTypes.FETCHING_ORDER_VARIATION,
    actionTypes.FETCH_ORDER_VARIATION_SUCCESS,
    actionTypes.FETCH_ORDER_VARIATION_FAILURE,
  ];

  return api.get({
    types,
    endpoint: `/meal_plans/${mealPlanId}/order_variations`,
    params: null,
    id,
  });
}
