/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import humps from 'lodash-humps';
import * as actionTypes from '../constants/userMealPlanSettingConstants';

export const $$initialState = Immutable.fromJS({
  seenConfirmationFlowAt: null,
});

export default function userMealPlanSettingReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.SAVE_USER_MEAL_PLAN_SETTING_SUCCESS: {
      return $$state.merge(humps(response.user_meal_plan_setting));
    }

    default: {
      return $$state;
    }
  }
}
