/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/* Actions */
import actions from 'packs/meals/actions';

import { prevent } from 'libs/support/prevent';

/**
 * Flash
 */
const Flash = ({ error, actions }) => {
  if (error) {
    return (
      <div className="row">
        <div className="alert alert-danger fixed-top text-center p-3">
          { error }
          <button
            type="button"
            className="close"
            onClick={() => prevent(actions.dismissFlash())}
          >
            &times;
          </button>
        </div>
      </div>
    );
  }
  return null;
};

Flash.propTypes = {
  error: PropTypes.string,
  actions: PropTypes.shape({
    dismissFlash: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  error: state.$$flashStore.get('error'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Flash));
