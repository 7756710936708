import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const MenuItemFormPhotos = ({
  className,
  photos,
}) => {
  const finalClassName = `more-details-carousel ${className}`;

  useEffect(() => {
    if (photos) {
      setTimeout(() => {
        $('.more-details-carousel-main').flickity({
          pageDots: false,
          percentPosition: false,
        });
        $('.more-details-carousel-nav').flickity({
          asNavFor: '.more-details-carousel-main',
          contain: true,
          pageDots: false,
          prevNextButtons: false,
          percentPosition: false,
        });
      }, 200);
    }
  }, [photos]);

  if (!photos) return <div />;

  return (
    <div className={finalClassName}>
      {
        photos.map(photo => (
          <div
            key={`photo-${photo.get('id')}`}
            className="carousel-cell"
          >
            <div
              className="banner-img"
              style={{ background: `url(${photo.get('medium')})` }}
            />
          </div>
        ))
      }
    </div>
  );
};

MenuItemFormPhotos.propTypes = {
  className: PropTypes.string,
  photos: PropTypes.object.isRequired,
};

export default MenuItemFormPhotos;
