/* eslint camelcase: 0 */

import humps from 'lodash-humps';
import {
  compose,
  createStore,
  applyMiddleware,
  combineReducers,
} from 'redux';

import thunkMiddleware from 'redux-thunk';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    meal: {
      orderables,
      allow_self_checkout,
      budget,
      meal_plan_id,
      selected,
      total,
      uuid,
    },
    user_meal_orderable,
    meal_plan_user,
  } = props;

  const { $$mealCardState, $$mealState } = initialStates;

  const mealAdmin = meal_plan_user && meal_plan_user.is_admin;
  const mealPlanUserId = meal_plan_user && meal_plan_user.uuid;

  const initialState = {
    $$mealCardStore: $$mealCardState.merge({
      isLoading: !orderables,
      meal: humps(props.meal),
      userMealOrderable: humps(user_meal_orderable),
      mealPlanUser: humps(meal_plan_user),
    }),
    $$mealStore: $$mealState.merge({
      mealPlanId: meal_plan_id,
      mealAdmin,
      mealPlanUserId,
      budget,
      selected,
      total,
      uuid,
      allowSelfCheckout: allow_self_checkout,
    }).merge({
      meal: humps(props.meal),
      userMealOrderable: humps(user_meal_orderable),
    }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = compose(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
