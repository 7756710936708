/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { defaultMessages } from 'libs/i18n/default';
import bindAll from 'lodash/bindAll';

import BaseComponent from 'libs/components/BaseComponent';

import Modal from 'react-bootstrap/Modal';

/**
 * Bulk Import
 */

class BulkImportModal extends BaseComponent {
  static propTypes = {
    view: PropTypes.object.isRequired,
    mealPlanId: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired,
  }

  constructor() {
    super();

    this.state = {
      fileName: '',
    };

    bindAll(this, 'handleOnChange', 'handleOnImport');
  }

  handleOnChange = () => {
    this.setState({ fileName: this.fileInput.files[0].name });
  }

  handleOnImport = () => {
    const {
      mealPlanId,
      onImport,
    } = this.props;

    const params = new FormData(this.form);

    onImport(mealPlanId, params);
  }

  render() {
    const {
      view,
      onHide,
      intl,
    } = this.props;

    const { fileName } = this.state;
    const { formatMessage } = intl;

    const errors = view.getIn(['flash', 'errors']);

    return (
      <Modal show={view.get('show')} onHide={onHide} dialogClassName="modal-lg">
        <div className="modal-content">

          { /* Modal Header */}
          <div className="modal-header">
            <h6 className="modal-title" id="editeDateTime">
              {formatMessage(defaultMessages.mealPlanUsersBulkImportModalTitle)}
            </h6>

            <button type="button" className="close" onClick={onHide}>
              <span aria-hidden="true">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.165 11.4881L6.12082 7.44393L1.88434 11.681C1.45895 12.1063 0.768848 12.1063 0.343457 11.681C-0.0819337 11.2556 -0.0819337 10.5655 0.343457 10.1401L4.58048 5.90359L0.536272 1.85938C0.110881 1.43399 0.110881 0.744434 0.536272 0.319043C0.961663 -0.106348 1.65122 -0.106348 2.07661 0.319043L6.12082 4.36325L9.97222 0.511858C10.3976 0.0864671 11.0872 0.0864671 11.5131 0.511858C11.9385 0.937249 11.9385 1.62681 11.5131 2.0522L7.66116 5.90359L11.7054 9.9478C12.1308 10.3732 12.1308 11.0628 11.7054 11.4881C11.28 11.9135 10.5904 11.9135 10.165 11.4881V11.4881Z"
                    fill="#141415"
                  />
                </svg>
              </span>
            </button>
          </div>

          { /* Modal Body */}
          <div className="modal-body">
            <div className="modal-body-content container-fluid">
              <form ref={node => this.form = node}>
                { /* Errors */}
                {
                  !errors.isEmpty() &&
                  <div className="form-group row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        {
                          errors.map((error, i) => (
                            <span key={i}>
                              {error}
                            </span>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                }

                <div className="form-group row">
                  <label className="col-3 col-form-label" htmlFor="file">
                    {formatMessage(defaultMessages.mealPlanUsersBulkImportModalLabelFile)}
                    <span className="label-required"> * </span>
                  </label>

                  <div className="col-9">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="custom-file">
                          <input
                            ref={node => this.fileInput = node}
                            type="file"
                            name="file"
                            className="required-field form-control form-control-sm custom-file-input"
                            id="input-file"
                            aria-describedby="input-file"
                            accept=".csv"
                            onChange={this.handleOnChange}
                          />
                          <label className="custom-file-label" htmlFor="input-file">
                            {fileName}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </form>

              <div>
                <a href="/sample_bulk_import.csv">
                  {formatMessage(defaultMessages.mealPlanUsersBulkImportModalDownloadTemplate)}
                </a>
              </div>
            </div>
          </div>

          { /* Modal Footer */}
          <div className="modal-footer">
            <button type="button" className="btn btn-light btn-sm" onClick={onHide}>
              {formatMessage(defaultMessages.mealPlanUsersBulkImportModalButtonCancel)}
            </button>

            <button type="submit" form="schedule-form" className="btn btn-primary btn-sm" onClick={this.handleOnImport}>
              {formatMessage(defaultMessages.mealPlanUsersBulkImportModalButtonImport)}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(BulkImportModal);
