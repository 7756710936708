/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import classNames from 'classnames';
import StickyBox from 'react-sticky-box';
import { object, string, bool } from 'prop-types';
import { notBlank } from 'libs/support/string';
import { setImageUrl } from 'helpers/cartVariationsHelper';

import IMAGE_PLACEHOLDER from 'assets/images/img-placeholder.png';

const SIZE_PLACEHOLDER = 4;

const CartVariationSkeletal = () => (
  <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-8 tw-animate-pulse">
    <StickyBox offsetTop={0}>
      <div className={classNames('vendor-info !tw-bg-white', {
          'tw-flex tw-flex tw-items-center tw-gap-2': true,
          'tw-border-b tw-border-gray-200 tw-py-2': true,
        })}
      >
        <div className="tw-w-10 tw-min-h-10 tw-max-h-10 tw-bg-gray-50 tw-rounded-md tw-mt-1">
          <div className={classNames('tw-bg-gray-50 tw-rounded', {
            'tw-h-10 tw-w-10 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
          })}
          />
        </div>
        <span className={classNames('tw-m-0 tw-bg-gray-100 tw-rounded', {
            'tw-h-6 tw-w-48 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
          })}
        />
      </div>
    </StickyBox>
    <ul
      data-testid="media-body"
      className="tw-list-none tw-list-inside tw-m-0 tw-ml-12"
    >
      {
        Array.from(new Array(SIZE_PLACEHOLDER)).map((_, index) => (
          <li
            key={index}
            className={classNames('tw-mb-4 tw-flex tw-flex-col tw-gap-1 tw-h-8')}
          >
            <span className={classNames('tw-bg-gray-100 tw-rounded', {
              'tw-h-4 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
            })}
            />
            <span className={classNames('tw-bg-gray-100 tw-rounded', {
              'tw-h-3 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
            })}
            />
          </li>
        ))
      }
    </ul>
  </div>
);

const CartVariationItems = ({
  variations,
  vendorName,
  vendorLogo,
  isLoading,
}) => {
  if (isLoading) return <CartVariationSkeletal />;

  if (!variations.size) return <Fragment />;

  const items = variations.filter(v => notBlank(v.get('menuItem')));

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-8">
      <StickyBox offsetTop={0}>
        <div className={classNames('vendor-info !tw-bg-white', {
          'tw-flex tw-flex tw-items-center tw-gap-2': true,
          'tw-border-b tw-border-gray-200 tw-py-2': true,
        })}
        >
          <div className="tw-w-10 tw-min-h-10 tw-max-h-10 tw-bg-gray-50 tw-rounded-md tw-mt-1">
            {vendorLogo && notBlank(vendorLogo.getIn(['thumb', 'url']))
              ? (
                <object
                  className="tw-w-full tw-h-auto tw-object-contain"
                  data={setImageUrl(vendorLogo.getIn(['thumb', 'url']))}
                >
                  <img className="tw-rounded !tw-object-contain" src={IMAGE_PLACEHOLDER} alt="" />
                </object>
                )
              : <div className={classNames('tw-bg-gray-50 tw-rounded', {
                  'tw-h-12 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                })}
              />
            }
          </div>
          <span className="tw-text-base tw-font-bold tw-text-gray-900 tw-m-0">
            {vendorName}
          </span>
        </div>
      </StickyBox>
      <ul
        data-testid="media-body"
        className="tw-list-disc tw-list-inside tw-m-0"
      >
        {items.map(item => (
          <li
            key={item.get('uuid')}
            className="tw-text-base tw-text-gray-500 tw-m-0 tw-truncate tw-py-1"
          >
            {item.getIn(['menuItem', 'name'])}
          </li>
        ))}
      </ul>
    </div>
  );
};

CartVariationItems.propTypes = {
  variations: object,
  vendorName: string,
  vendorLogo: object,
  isLoading: bool,
};

export default CartVariationItems;
