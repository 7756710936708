import React, { Fragment } from 'react';
import { any, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const NumericInput = styled.input`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const HeadcountField = ({
  className = '',
  editable,
  headcount,
  setHeadcount,
  averageSpending,
  translate,
}) => (
  <Fragment>
    <div className={classNames('tw-flex tw-flex-col tw-items-start tw-gap-4', {
      'tw-bg-gray-50 tw-py-4 tw-px-6 md:tw-px-8 tw-mt-4': true,
    })}
    >
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
        <div>{translate('plannedMealsHowManyPeople2')}</div>
        <NumericInput
          type="number"
          className={classNames(className, 'form-control tw-border tw-rounded', {
            '!tw-p-0 !tw-text-center !tw-text-base tw-border-gray-200 tw-bg-white': true,
            '!tw-bg-gray-20 !tw-border-0': !editable,
          })}
          name="planned_meal_setting[headcount]"
          value={headcount}
          disabled={!editable}
          onChange={e => setHeadcount(e.target.value)}
        />
      </div>

      <div className="tw-flex tw-items-start tw-justify-between tw-w-full">
        <div className="tw-flex tw-flex-col">
          <span className="tw-text-base tw-text-gray-900 tw-font-bold">
            Average spend per pax
          </span>
          <span className="tw-text-sm tw-text-gray-700">
            (Excl. GST, excl. delivery)
          </span>
        </div>
        <div className="tw-text-lg tw-text-gray-900 tw-font-bold">
          {averageSpending}
        </div>
      </div>
    </div>
  </Fragment>
);

HeadcountField.propTypes = {
  className: string,
  editable: bool.isRequired,
  headcount: number,
  setHeadcount: func.isRequired,
  averageSpending: any,
  translate: func.isRequired,
};

export default HeadcountField;
