/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useState, useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { withI18n } from 'libs/support/i18n';
import FoodIllustration from 'libs/components/Illustrations/Food';
import { DeleteXCircleIcon, EditIcon } from 'libs/components/Icons';

/* Actions */
import actions from 'packs/meals/actions';

/* Components */
import AlertBox from 'libs/components/AlertBox';

/**
 * MealSelectedHeader
 */
const MealSelectedHeader = ({
  actions,
  isMealConfirmed,
  mealStore,
  translate,
  userMealOrderable,
}) => {
  const [isResetConfirmShow, setResetConfirmShow] = useState(false);
  const [orderablesGuest, setOrderablesGuest] = useState([]);

  const timezone = mealStore.get('timezone');
  const scheduleAt = moment(mealStore.get('scheduleAt')).tz(timezone);
  const deadlineAt = moment(mealStore.get('deadlineAt')).tz(timezone);
  const orderables = isMealConfirmed ? userMealOrderable.get('orderVariations') : userMealOrderable.get('cartVariations');

  const displayHeader = () => (
    translate('groupedOrdersMessagesOneScheduledOrderFor', { date: scheduleAt.format('dddd, h:mmA') })
  );

  const displayMessage = () => {
    if (isMealConfirmed) { return translate('groupedOrdersMessagesEnjoyYourMeal'); }

    return translate(
      'groupedOrdersMessagesYouMakeChangesUpTill',
      { date: deadlineAt.format('dddd, D MMMM'), time: deadlineAt.format('h:mmA') },
    );
  };

  const handleReset = (e) => {
    e.preventDefault();

    setResetConfirmShow(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    setResetConfirmShow(false);
    await actions.editUserMealCart(mealStore.get('mealPlanId'), userMealOrderable.get('uuid'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setResetConfirmShow(!isResetConfirmShow);
    actions.resetUserMealCart(mealStore.get('mealPlanId'), userMealOrderable.get('uuid'));
  };

  useEffect(() => {
    if (orderables.size > 0) {
      setOrderablesGuest(orderables.filter(cv => (isMealConfirmed
        ? cv.get('orderUserIsGuest')
        : cv.get('cartUserIsGuest'))));
    }
  }, [orderables]);

  return (
    <div className="row">
      <div className="col">
        <div className="card card-panel meal-banner meal-banner--warning">
          <div className="card-body meal-banner__body">
            <div className="meal-banner__image" >
              <FoodIllustration />
            </div>
            <div className="meal-banner__content" >
              <h5>{displayHeader()}</h5>

              <p className="lead meal-banner__lead">
                { displayMessage() }
              </p>

              {
                !isMealConfirmed &&
                  <div className="lead meal-banner__actions">
                    <button
                      className="btn btn-light font-600 meal-banner__button"
                      onClick={handleReset}
                    >
                      <span className="mr-2"><DeleteXCircleIcon /></span>
                      { translate('groupedOrdersActionsRemoveSelections') }
                    </button>

                    <button
                      className="btn btn-light font-600 meal-banner__button"
                      onClick={handleUpdate}
                    >
                      <span className="mr-2"><EditIcon /></span>
                      { translate('groupedOrdersActionsUpdateSelections') }
                    </button>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>

      {isResetConfirmShow && (
        <AlertBox
          dimension="tw-min-w-[28rem] tw-max-w-md"
          show={isResetConfirmShow}
          title={translate('groupedOrdersMessagesCancelOrderConfirmationTitle')}
          message={
            (orderablesGuest && orderablesGuest.size > 0)
              ? translate('groupedOrdersMessagesCancelOrderWithGuestMessage', { count: orderablesGuest.size })
              : translate('groupedOrdersMessagesCancelOrderConfirmationMessage')
          }
          cancelText={translate('groupedOrdersActionsAlertGoBack')}
          submitText={translate('groupedOrdersActionsAlertConfirm')}
          onCancel={() => setResetConfirmShow(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMealConfirmed: state.$$mealStore.get('confirmed'),
  mealStore: state.$$mealStore,
  userMealOrderable: state.$$mealStore.get('confirmed') ? state.$$userMealOrderStore : state.$$userMealCartStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

MealSelectedHeader.propTypes = {
  actions: object.isRequired,
  isMealConfirmed: bool.isRequired,
  mealStore: object.isRequired,
  translate: func.isRequired,
  userMealOrderable: object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(MealSelectedHeader));
