import React, { memo } from 'react';
import { bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from 'packs/meals/actions';
import MenuItems from 'packs/meals/components/GroupOrderMeal/MenuItems/MenuItems';
import { getOrderableVariations } from 'packs/meals/helpers/userMealOrderables';
import { groupMenuItemsByVendor, selectedMenuItems } from 'packs/meals/helpers/menuItems';
import { USER_MEAL_ORDERABLE_STATUSES } from 'packs/meals/constants/userMealCartConstants';

const SelectedMenuItemsContainer = ({
  fetchCartVariation,
  fetchMenuItem,
  fetchOrderVariation,
  hide,
  isMealConfirmed,
  mealPlanId,
  menuItemsByVendor,
  selectedText,
  showPrice,
}) => (
  <div id="selected-menu-items">
    <MenuItems
      {
        ...{
          fetchOrderableVariation: isMealConfirmed ? fetchOrderVariation : fetchCartVariation,
          fetchMenuItem,
          hide,
          mealPlanId,
          menuItemsByVendor,
          selectedText,
          showPrice,
          showMealCountCap: false,
        }
      }
    />
  </div>
);

const mapStateToProps = ({
  $$mealStore,
  $$menuItemsStore,
  $$userMealCartStore,
  $$userMealOrderStore,
  $$vendorsStore,
}) => {
  const isMealConfirmed = $$mealStore.get('confirmed');
  const userMealOrderable = isMealConfirmed ? $$userMealOrderStore : $$userMealCartStore;
  const isUserMealOrderableConfirmed = userMealOrderable.get('currentState') === USER_MEAL_ORDERABLE_STATUSES.confirmed;
  const selectedOderableVariations = getOrderableVariations(userMealOrderable, isMealConfirmed);

  return {
    hide: !(isUserMealOrderableConfirmed && selectedOderableVariations.size > 0),
    isMealConfirmed,
    mealPlanId: $$mealStore.get('mealPlanId'),
    menuItemsByVendor: groupMenuItemsByVendor(
      selectedMenuItems($$menuItemsStore, selectedOderableVariations),
      $$vendorsStore,
    ),
    selectedText: isUserMealOrderableConfirmed ? 'Ordered' : 'Selected',
    showPrice: $$mealStore.getIn(['groupOrderSetting', 'showPrice']),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCartVariation: actions.fetchCartVariation,
  fetchMenuItem: actions.fetchMenuItem,
  fetchOrderVariation: actions.fetchOrderVariation,
}, dispatch);

SelectedMenuItemsContainer.propTypes = {
  fetchCartVariation: func.isRequired,
  fetchMenuItem: func.isRequired,
  fetchOrderVariation: func.isRequired,
  isMealConfirmed: bool.isRequired,
  hide: bool.isRequired,
  mealPlanId: string.isRequired,
  menuItemsByVendor: object.isRequired,
  selectedText: string,
  showPrice: bool.isRequired,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(SelectedMenuItemsContainer));
