import dayjs from 'dayjs';
import 'intl/locale-data/jsonp/en-SG';

export const formatDate = (text) => {
  if (text.length >= 8) {
    return dayjs(text, 'YYYYMMDD').format('YYYY-MM-DD');
  }
  return dayjs(text, 'YYYYMM').format("MMM'YY");
};

export const formatMoney = (
  text,
  {
    withCents = true,
    withSymbol = true,
    compact = false,
    truncate = false,
  } = {},
) => {
  const formatted = Intl.NumberFormat('en-SG', {
    ...(withSymbol && {
      style: 'currency',
      currency: 'SGD',
    }),
    ...(compact && {
      notation: 'compact',
    }),
    ...(withCents && {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    ...(!withCents && {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  }).format(text);

  if (truncate) {
    return formatted.replace(',000,000', 'm').replace(',000', 'k');
  }

  return formatted;
};

export const PROGRAM_COLORS = {
  Total: '#95989D',
  MealPlan: '#B7D06C',
  PantryProgram: '#93D7F3',
  Event: '#7F82D3',
};

export const MEAL_PLAN_COLORS = [
  '#6cd076',
  '#d7e813',
  '#0d8553',
  '#93e29b',
  '#98a709',
  '#0cad69',
  '#c3efc6',
  '#717f0c',
  '#16d182',
  '#e0f8e2',
  '#5a6410',
  '#3fe9a1',
];

export const PANTRY_PROGRAM_COLORS = ['#93D7F3', '#44A3CB', '#3B6B6F'];

export const PROGRAM_SORTING = {
  MealPlan: 0,
  PantryProgram: 1,
  Event: 2,
};
