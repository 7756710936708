export const CHECK_ADD_ON_VALIDITY = 'CHECK_ADD_ON_VALIDITY';
export const SELECT_VARIATION = 'SELECT_VARIATION';

export const DELETING_CART_VARIATION = 'DELETING_CART_VARIATION';
export const DELETE_CART_VARIATION_SUCCESS = 'DELETE_CART_VARIATION_SUCCESS';
export const DELETE_CART_VARIATION_FAILURE = 'DELETE_CART_VARIATION_FAILURE';

export const FETCHING_CART_VARIATION = 'FETCHING_CART_VARIATION';
export const FETCH_CART_VARIATION_SUCCESS = 'FETCH_CART_VARIATION_SUCCESS';
export const FETCH_CART_VARIATION_FAILURE = 'FETCH_CART_VARIATION_FAILURE';

export const SAVING_CART_VARIATION = 'SAVING_CART_VARIATION';
export const SAVE_CART_VARIATION_SUCCESS = 'SAVE_CART_VARIATION_SUCCESS';
export const SAVE_CART_VARIATION_FAILURE = 'SAVE_CART_VARIATION_FAILURE';

export const UPDATING_QUANTITY_CART_VARIATION = 'UPDATING_QUANTITY_CART_VARIATION';
export const UPDATE_QUANTITY_CART_VARIATION_SUCCESS = 'UPDATE_QUANTITY_CART_VARIATION_SUCCESS';
export const UPDATE_QUANTITY_CART_VARIATION_FAILURE = 'UPDATE_QUANTITY_CART_VARIATION_FAILURE';

export const RESET_QUANTITY_CART_VARIATION = 'RESET_QUANTITY_CART_VARIATION';
export const RESET_QUANTITY_CART_VARIATION_SUCCESS = 'RESET_QUANTITY_CART_VARIATION_SUCCESS';
export const RESET_QUANTITY_CART_VARIATION_FAILURE = 'RESET_QUANTITY_CART_VARIATION_FAILURE';

export const SET_CART_VARIATION = 'SET_CART_VARIATION';
export const RESET_CART_VARIATION = 'RESET_CART_VARIATION';
