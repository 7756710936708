/* eslint-disable max-len */
import React from 'react';
import { func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import NotificationItem from './NotificationItem';
import {
  NotificationGroupContainer,
  SectionTitle,
  GroupHeading,
  Title,
  Wrapper,
  Description,
  SubSettings,
} from '../../styles/mobile';

const NotificationGroup = ({ section, groups, role, onNotificationSelect, currentSettings, translate }) => {
  const handleNotificationSelect = (subSetting, groupName) => {
    onNotificationSelect({ ...subSetting, groupName, settingName: subSetting.name });
  };

  return (
    <NotificationGroupContainer>
      { role === 'all' && <SectionTitle>{translate(`accountSettingsNotificationSettingsSection${uppercamelcase(section)}Title`)}</SectionTitle>}
      {groups.map(group => (
        <div key={group.name}>
          <GroupHeading>
            <Title>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(group.name)}Title`)}</Title>
            <Wrapper>
              <Description>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(group.name)}Description`)}</Description>
            </Wrapper>
          </GroupHeading>
          <SubSettings>
            {group.subNotifications.map(notification => (
              <NotificationItem
                group={group.name}
                key={notification.name}
                name={notification.name}
                onNotificationSelect={(subSetting) => handleNotificationSelect(subSetting, group.name)}
                currentSettings={currentSettings}
              />
            ))}
          </SubSettings>
        </div>
      ))}
    </NotificationGroupContainer>
  );
};

NotificationGroup.propTypes = {
  section: string,
  groups: object,
  role: string,
  onNotificationSelect: func,
  currentSettings: object,
  translate: func,
};

export default withI18n(NotificationGroup);
