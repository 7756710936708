/* eslint max-len: 0 */

import React from 'react';

const Visa = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="33" viewBox="0 0 52 33" fill="none" style={{ fill: '#fff' }}>
    <g filter="url(#filter0_d)">
      <rect width="52" height="32" rx="2.66667" fill="white" />
      <rect x="0.5" y="0.5" width="51" height="31" rx="2.16667" stroke="#DBDBDB" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.2189 14.465C26.1999 15.9747 27.5536 16.8173 28.5734 17.3182C29.6211 17.8321 29.973 18.1617 29.969 18.6213C29.961 19.3248 29.1332 19.6352 28.3584 19.6473C27.0067 19.6684 26.2209 19.2794 25.5961 18.9851L25.1092 21.282C25.736 21.5733 26.8968 21.8272 28.1005 21.8383C30.9258 21.8383 32.7744 20.4324 32.7844 18.2524C32.7954 15.4859 28.9883 15.3327 29.0143 14.0961C29.0233 13.7212 29.3782 13.3211 30.156 13.2193C30.5409 13.1679 31.6036 13.1286 32.8084 13.6879L33.2812 11.4656C32.6334 11.2278 31.8006 11 30.7638 11C28.1045 11 26.2339 12.4251 26.2189 14.465M37.8253 11.1912C37.3094 11.1912 36.8745 11.4945 36.6806 11.9602L32.6445 21.6747H35.4679L36.0297 20.1096H39.4799L39.8058 21.6747H42.2942L40.1228 11.1912H37.8253M38.2198 14.0234L39.0346 17.96H36.8031L38.2198 14.0234M22.7958 11.1912L20.5703 21.6747H23.2607L25.4851 11.1912H22.7958M18.8157 11.1912L16.0153 18.3267L14.8826 12.2595C14.7496 11.5822 14.2248 11.1912 13.6419 11.1912H9.06398L9 11.4955C9.93978 11.7011 11.0075 12.0327 11.6544 12.3875C12.0503 12.6042 12.1632 12.7936 12.2932 13.3087L14.4387 21.6747H17.282L21.641 11.1912H18.8157" fill="url(#paint0_linear)" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="52" height="32.6667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="0.666667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.917647 0 0 0 0 0.921569 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient id="paint0_linear" x1="9.05803" y1="14.3336" x2="18.4689" y2="1.07963" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
    </defs>
  </svg>
);

export default Visa;
