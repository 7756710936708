/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, object } from 'prop-types';
import { prevent } from 'libs/support/prevent';
import { notBlank } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';

/* Actions */
import actions from 'packs/meals/actions';

/* from */
import * as fromCarts from 'packs/meals/reducers/cartsReducer';

import AmountField from 'packs/meals/components/Shared/AmountField';

/**
 * MealCart
 */
const MealCart = ({ mealStore, cartsStore, cartVariationsStore, actions, translate, flagIsEnabled }) => {
  const totals = fromCarts.getTotals(cartsStore);
  const checkoutProcessing = mealStore.get('processingMealCheckout');
  const errorMessage = mealStore.get('mealCheckoutErrorMsg');
  const successMessage = mealStore.get('mealCheckoutSuccessMsg');
  const requiresHeadcountInput = mealStore.get('requiresHeadcountInput');
  const isDemoMeal = mealStore.get('demoMeal');

  const [headcount, setHeadcount] = useState(mealStore.getIn(['plannedMealSetting', 'headcount']));

  const checkout = () => {
    if (isDemoMeal) {
      alert(translate('plannedMealsCheckoutDemoMealAlert'));
      return;
    }

    actions.mealCheckout(mealStore.get('mealPlanId'), mealStore.get('uuid'));

    if (flagIsEnabled('headcount_input_enabled')) {
      const finalHeadcount = requiresHeadcountInput ? headcount : cartVariationsStore.get('totalQuantity');
      actions.updateHeadcount(
        mealStore.get('mealPlanId'),
        mealStore.get('uuid'),
        mealStore.getIn(['plannedMealSetting', 'uuid']),
        finalHeadcount,
      );
    }
  };

  return (
    <div className="card-footer">
      <div className="row">
        <div className="col" />
        <div className="col-lg-5">
          <div className="row">
            <AmountField title={translate('adminMealCartsTermsSubtotal')} value={totals.get('subtotal')} />

            {notBlank(totals.get('totalSmallOrderFee')) && (
              <AmountField
                title={`+ ${translate('adminMealCartsTermsSmallOrderFee')}`}
                value={totals.get('totalSmallOrderFee')}
              />
            )}

            <AmountField
              title={`+ ${translate('adminMealCartsTermsDeliveryFee')}`}
              value={totals.get('deliveryAmount')}
            />

            <AmountField
              title={`+ ${translate('adminMealCartsTermsSurcharge', { names: totals.get('surchargeNames') })}`}
              value={totals.get('surchargeAmount')}
            />

            {totals.get('taxEnabled') && (
              <AmountField title={`+ ${translate('adminMealCartsTermsTax')}`} value={totals.get('taxAmount')} />
            )}

            <AmountField title={`- ${translate('adminMealCartsTermsDiscount')}`} value={totals.get('discountAmount')} />

            <AmountField
              title={`- ${translate('adminMealCartsTermsCaterspotRefund')}`}
              value={totals.get('caterspotRefund')}
            />

            <AmountField
              title={`- ${translate('adminMealCartsTermsVendorRefund')}`}
              value={totals.get('vendorRefund')}
            />

            <AmountField
              title={
                totals.get('taxEnabled')
                  ? translate('adminMealCartsTermsTotalIncludeTax')
                  : translate('adminMealCartsTermsTotalExcludeTax')
              }
              value={totals.get('total')}
              className="total-price"
            />
          </div>

          {flagIsEnabled('headcount_input_enabled') && requiresHeadcountInput && (
            <>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="font-weight-bold">{translate('plannedMealsHeadcount')}</div>
                  <div>{translate('plannedMealsHowManyPeople1')}</div>
                </div>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    style={{ width: 100 }}
                    name="planned_meal_setting[headcount]"
                    value={headcount}
                    onChange={e => setHeadcount(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          {/* Error alert */}
          {errorMessage && (
            <div className="col-sm alert alert-danger">
              {errorMessage}
              <button
                type="button"
                className="close"
                style={{ margin: '0 0 0 0' }}
                onClick={prevent(actions.dismissAlert)}
              >
                &times;
              </button>
            </div>
          )}

          {/* Success alert */}
          {successMessage && (
            <div className="col-sm alert alert-success text-dark">
              {successMessage}
              <button
                type="button"
                className="close"
                style={{ margin: '0 0 0 0' }}
                onClick={prevent(actions.dismissAlert)}
              >
                &times;
              </button>
            </div>
          )}

          {/* Confirm Button */}
          {!mealStore.get('confirmed') && (
            <button
              className="btn btn-primary btn-block py-3 mt-2"
              onClick={prevent(checkout)}
              disabled={checkoutProcessing}
            >
              {checkoutProcessing ? 'Processing ...' : 'Confirm'}
            </button>
          )}

          {/* Confirm hint */}
          {!mealStore.get('confirmed') && (
            <div className="row">
              <div className="col text-center">{translate('plannedMealsCheckoutHint')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MealCart.propTypes = {
  mealStore: object.isRequired,
  cartsStore: object.isRequired,
  cartVariationsStore: object.isRequired,
  actions: object.isRequired,
  translate: func.isRequired,
  flagIsEnabled: func.isRequired,
};

const mapStateToProps = state => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(withI18n(MealCart)));
