/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';

const EditingIndicator = ({
  currentState,
  translate,
}) => {
  if (currentState !== 'editing') return null;

  return (
    <div className="editing-indicator">
      {translate('groupedOrdersTermsEditing')}
    </div>
  );
};

EditingIndicator.propTypes = {
  currentState: string,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  currentState: state.$$userMealCartStore.get('currentState'),
});

export default connect(mapStateToProps)(withI18n(EditingIndicator));
