import requestsManager from 'libs/middlewares/requestsManager';

/**
 * API to skip meal
 * GET /meal_plans/:mealPlanId/skip_meals/:id
 */
export const skipMeal = ({ mealId, mealPlanId }, successCallback, failedCallback) => {
  const endpoint = `/meal_plans/${mealPlanId}/skip_meals`;

  return requestsManager.updateEntity({}, endpoint, mealId)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
