/* eslint max-len: 0 */

import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/customMenuItemConstants';

export function saveCustomMenuItem(mealPlanId, id, params) {
  const {
    SAVING_CUSTOM_MENU_ITEM,
    SAVE_CUSTOM_MENU_ITEM_SUCCESS,
    SAVE_CUSTOM_MENU_ITEM_FAILURE,
  } = actionTypes;

  return api.save({
    types: [
      SAVING_CUSTOM_MENU_ITEM,
      SAVE_CUSTOM_MENU_ITEM_SUCCESS,
      SAVE_CUSTOM_MENU_ITEM_FAILURE,
    ],
    endpoint: `/meal_plans/${mealPlanId}/custom_menu_items`,
    params,
    id,
  });
}
