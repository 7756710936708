/* eslint-disable react/no-array-index-key */
import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import Immutable from 'immutable';
import dayjs from 'dayjs';
import { bool, func, number, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

import MealCartItem from './MealCartItem';

/**
 * MealCartListItems
 */

const MealCartListItems = ({
  menuItemsStore,
  vendor,
  enableVendor,
  cartVariations,
  mealPlanId,
  mealAdmin,
  mealConfirmed,
  mealEditable,
  customMenuItems,
  updateQuantity,
  updateCustomQuantity,
}) => {
  const [items, setItems] = useState(Immutable.fromJS([]));
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const itemized = cartVariations
      ? cartVariations
        .filter(variation => variation.get('quantity') > 0)
        .sortBy(variation => dayjs(variation.get('updated_at')).unix())
        .reverse()
      : Immutable.fromJS([]);

    const customItemized = customMenuItems
      ? customMenuItems.filter(variation => variation.get('quantity') > 0)
      : Immutable.fromJS([]);

    const variations = customItemized && customItemized.size > 0
      ? itemized.withMutations(i => customItemized.map(c => i.push(c)))
      : itemized;

    setTotal(variations.reduce((acc, item) => acc + item.get('quantity'), 0));
    setItems(variations);
  }, [cartVariations, customMenuItems]);

  return (items.size === 0)
    ? <Fragment />
    : (
      <Fragment>
        {enableVendor && (
          <div className={classNames('tw-py-2 tw-px-2 tw-my-4 tw-mb-0 -tw-ml-6 md:-tw-ml-6 tw-pl-6 md:tw-pl-6', {
            'tw-bg-gray-50 tw-w-full': true,
            'tw-flex': true,
          })}
          >
            <span className="tw-text-base tw-text-gray-900 tw-font-bold tw-px-6 md:tw-px-8 tw-flex-1">
              {vendor.get('name')}
            </span>
            <span className="tw-flex-shrink-0">{total} items</span>
          </div>
        )}
        <ul className="tw-list-none tw-m-0">
          {items.map((item, index) => {
            const hasBorder = index !== items.size - 1;
            return (
              <MealCartItem
                key={index}
                store={menuItemsStore}
                variation={item}
                bordered={hasBorder}
                mealPlanId={mealPlanId}
                mealAdmin={mealAdmin}
                mealConfirmed={mealConfirmed}
                mealEditable={mealEditable}
                updateQuantity={updateQuantity}
                updateCustomQuantity={updateCustomQuantity}
              />
            );
          })}
        </ul>
      </Fragment>
    );
};

MealCartListItems.propTypes = {
  mealPlanId: string,
  menuItemsStore: object,
  vendor: object,
  enableVendor: bool,
  cartVariations: object,
  customMenuItems: object,
  mealAdmin: bool,
  mealConfirmed: bool,
  mealEditable: bool,
  updateQuantity: func,
  updateCustomQuantity: func,
};

/**
 * MealCartList
 */

const MealCartList = ({
  cartsStore,
  cartVariationsByCartId,
  cartVariationsStore,
  customMenuItemsStore,
  enableVendorSection,
  isEditable,
  mealAdmin,
  mealConfirmed,
  mealPlanId,
  menuItemsStore,
  saveCustomMenuItem,
  totalQuantities,
  updateQuantityCartVariation,
  vendors,
  vendorsStore,
}) => (
  <Fragment>
    {totalQuantities === 0
        ? (
          <div className="tw-flex tw-items-center tw-justify-center tw-min-h-[160px]">
            <span className="tw-text-base tw-text-gray-500">Your cart is empty</span>
          </div>
        )
        : (
          <div className="tw-bg-white tw-overflow-x-hidden tw-pb-6">
            {cartsStore.get('allIds').map((cartId, i) => {
              const vendorId = cartsStore.getIn(['byId', cartId, 'vendorId']);
              const vendor = vendorsStore.getIn(['byId', vendorId]);
              const cartVariations = cartVariationsByCartId.get(cartId);
              const customMenuItems = customMenuItemsStore.getIn(['byVendorId', vendorId]);

              return (
                <MealCartListItems
                  key={i}
                  mealPlanId={mealPlanId}
                  cartVariationsStore={cartVariationsStore}
                  menuItemsStore={menuItemsStore}
                  vendors={vendors}
                  vendor={vendor}
                  cartVariations={cartVariations}
                  customMenuItems={customMenuItems}
                  mealAdmin={mealAdmin}
                  mealEditable={isEditable}
                  mealConfirmed={mealConfirmed}
                  updateQuantity={updateQuantityCartVariation}
                  updateCustomQuantity={saveCustomMenuItem}
                  enableVendor={enableVendorSection}
                />
              );
            })}
          </div>
        )
      }
  </Fragment>
);

MealCartList.propTypes = {
  cartsStore: object,
  cartVariationsByCartId: object,
  cartVariationsStore: object,
  customMenuItemsStore: object,
  enableVendorSection: bool,
  isEditable: bool,
  mealAdmin: bool,
  mealConfirmed: bool,
  mealPlanId: string,
  menuItemsStore: object,
  saveCustomMenuItem: func,
  totalQuantities: number,
  updateQuantityCartVariation: func,
  vendors: object,
  vendorsStore: object,
};

export default withI18n(MealCartList);
