/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-danger */
/* eslint no-return-assign: 0 */

import React, { useEffect, useState, useRef, Fragment } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import $ from 'jquery';
import { injectIntl, intlShape } from 'react-intl';

import { blankish, notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';
import AlertBox from 'libs/components/AlertBox';
import { CloseModalIcon } from 'libs/components/Icons';

import IncludedItems from './IncludedItems';
import PresentationsAndDietaries from './PresentationsAndDietaries';
import Photos from './Photos';
import CartVariationForm from './CartVariationForm/CartVariationForm';

const MenuItemForm = ({
  hide,
  isSavingCartVariation,
  isNonZeroVat,
  isGroupOrder,
  isEditable,
  cartId,
  cartUserId,
  hideAddOns,
  hideSpecialInstruction,
  mealAdmin,
  mealId,
  mealLimit,
  mealPlanId,
  mealPlanUserId,
  catererId,
  saveErrors,
  menuItem,
  cartVariation,
  onClickOption,
  onDeleteItemAddOnOptionPhoto,
  onDropItemAddOnOptionPhoto,
  onDropItemAddOnOptionPhotoFailure,
  onSelectVariation,
  onSubmit,
  onUpdateItemAddOnOptionText,
  onClickCartVariationBackButton,
  onHide,
  showPrice,
  maxVariationQuantity,
  budget,
  intl,
  cartVariationBackButton,
  orderableVariations,
}) => {
  if (blankish(menuItem) || menuItem.size === 0) return null;

  const { formatMessage } = intl;
  const description = menuItem.get('description');
  const otherNotes = menuItem.get('other_notes');
  const photos = menuItem.get('photos');
  const modal = useRef();

  const [showExceededMaxOptionsAlert, setShowExceededMaxOptionsAlert] = useState(false);
  const [maxOptions, setMaxOptions] = useState(0);

  const onShowExceededMaxOptionsAlert = (maxOptions) => {
    setShowExceededMaxOptionsAlert(true);
    setMaxOptions(maxOptions);
  };

  const filterVariationsByQuantity = (variations, maxQuantity) => {
    if (maxQuantity) { return variations.filter(v => v.get('min_order_quantity') <= maxQuantity); }
    return variations;
  };

  const filterVariationsByBudget = (variations, mealAdmin, budget) => {
    if (budget && !mealAdmin) { return variations.filter(v => v.get('price') <= budget); }
    return variations;
  };

  const filterVariations = (variations, maxQuantity, mealAdmin, budget) => {
    const variationsByBudget = filterVariationsByBudget(variations, mealAdmin, budget);

    return filterVariationsByQuantity(
      variationsByBudget,
      maxQuantity,
    );
  };

  const variations = filterVariations(
    menuItem.get('variations'),
    maxVariationQuantity,
    mealAdmin,
    budget,
  );

  useEffect(() => {
    if ($('.modal-backdrop').length > 1) {
      $('.modal-backdrop').not(':last').remove();
    }
    if (hide) {
      $(modal.current).modal('hide');
      $('.modal-backdrop').css('z-index', '1040');
      $('.modal-backdrop').remove();
    }
    if (!hide) {
      $(modal.current).modal('show');
      $(modal.current).on('hidden.bs.modal', onHide);
      $('.modal-backdrop').css('z-index', '1000');
    }
  }, [hide]);

  return (
    <Fragment>
      {showExceededMaxOptionsAlert && (
        <AlertBox
          show={showExceededMaxOptionsAlert}
          title="Exceeded Max Options"
          message={formatMessage(defaultMessages.cartVariationsFormErrorExceededMaxOptions, { quantity: maxOptions })}
          cancelText="Okay"
          onCancel={() => setShowExceededMaxOptionsAlert(false)}
        />
      )}
      <div
        ref={modal}
        id="menu-item-form"
        className="modal fade tw-modal tw-bg-transparent tw-min-h-screen !tw-p-0"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg tw-bg-white tw-min-h-screen md:tw-min-h-[55vh] tw-rounded-lg tw-overflow-hidden">
          <div className="modal-content">
            {
              (isSavingCartVariation) &&
              <div className="loading" />
            }

            <div className="modal-header">
              <div className="modal-header-container tw-w-full md:tw-pr-8">
                <div className="modal-header-content tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-justify-between">
                  <h4 className="modal-title">{menuItem.get('name')}</h4>
                  {
                    showPrice &&
                    <div className="tw-text-base md:tw-text-right">
                      <h4 className="media-heading">
                        {menuItem.get('price')}
                        &nbsp;

                        {
                          (isNonZeroVat) &&
                          <span className="text-variation">
                            {formatMessage(defaultMessages.cartVariationsCartVariationGst)}
                          </span>
                        }
                      </h4>
                    </div>
                  }
                </div>
              </div>
              <button
                type="button"
                className="close-icon-modal !tw-block"
                data-dismiss="modal"
              >
                <CloseModalIcon />
              </button>
            </div>

            <div className="modal-body tw-w-full">
              <div className="modal-body-content">
                <div className="more-details">
                  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
                    <div className="tw-col-span-1 md:tw-col-span-1">
                      {
                        (notBlank(description)) &&
                        <div
                          className="more-details-desc media-desc tw-mb-4"
                          dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
                        />
                      }

                      <IncludedItems
                        includedItems={menuItem.get('included_items')}
                      />

                      <PresentationsAndDietaries
                        presentations={menuItem.get('presentations')}
                        dietaries={menuItem.get('dietaries')}
                      />

                      {
                        (notBlank(otherNotes)) &&
                        <div className="row">
                          <div className="col-md-12">
                            <p className="more-details-headline">
                              {formatMessage(defaultMessages.cartVariationsCartVariationOtherNotes)}
                            </p>

                            <div className="more-details-notes">
                              <p>{otherNotes}</p>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="tw-col-span-1 md:tw-col-span-1">
                      {
                        (photos && photos.size > 0) &&
                        <div>
                          <Photos
                            className="more-details-carousel-main"
                            photos={photos}
                          />

                          <Photos
                            className="more-details-carousel-nav hidden-md-down"
                            photos={photos}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <CartVariationForm
                  isGroupOrder={isGroupOrder}
                  isEditable={isEditable}
                  catererId={catererId}
                  cartId={cartId}
                  hideSpecialInstruction={hideSpecialInstruction}
                  hideAddOns={hideAddOns}
                  mealId={mealId}
                  mealLimit={mealLimit}
                  mealPlanId={mealPlanId}
                  mealPlanUserId={mealPlanUserId}
                  cartUserId={cartUserId}
                  saveErrors={saveErrors}
                  variations={variations}
                  choices={menuItem.get('menu_item_choices')}
                  itemAddOns={menuItem.get('menu_item_item_add_ons')}
                  showPrice={showPrice}
                  serviceAddOns={menuItem.get('menu_item_service_add_ons')}
                  cartVariation={cartVariation}
                  orderableVariations={orderableVariations}
                  onClickOption={onClickOption}
                  onSelectVariation={onSelectVariation}
                  onSubmit={onSubmit}
                  onShowExceededMaxOptionsAlert={onShowExceededMaxOptionsAlert}
                  {
                  ...{
                    onDeleteItemAddOnOptionPhoto,
                    onDropItemAddOnOptionPhoto,
                    onDropItemAddOnOptionPhotoFailure,
                    onUpdateItemAddOnOptionText,
                    cartVariationBackButton,
                    onClickCartVariationBackButton,
                  }
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MenuItemForm.propTypes = {
  hide: bool,
  isSavingCartVariation: bool,
  isNonZeroVat: bool,
  isGroupOrder: bool,
  isEditable: bool,
  cartId: string,
  cartUserId: string,
  hideAddOns: bool,
  hideSpecialInstruction: bool,
  mealAdmin: bool.isRequired,
  mealId: string.isRequired,
  mealLimit: object,
  mealPlanId: string.isRequired,
  mealPlanUserId: string,
  catererId: string,
  saveErrors: object,
  menuItem: object,
  cartVariation: object,
  onClickOption: func.isRequired,
  onDeleteItemAddOnOptionPhoto: func.isRequired,
  onDropItemAddOnOptionPhoto: func.isRequired,
  onDropItemAddOnOptionPhotoFailure: func.isRequired,
  onSelectVariation: func.isRequired,
  onSubmit: func.isRequired,
  onUpdateItemAddOnOptionText: func.isRequired,
  onClickCartVariationBackButton: func,
  onHide: func,
  showPrice: bool.isRequired,
  maxVariationQuantity: number,
  budget: number,
  intl: intlShape.isRequired,
  cartVariationBackButton: bool,
  orderableVariations: object,
};

export default injectIntl(MenuItemForm);
