/* eslint-disable camelcase */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateTime from 'react-datetime';
import { withI18n } from 'libs/support/i18n';
import { notBlank } from 'libs/support/string';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'libs/components/Icons/Spinner';

// Moment format
const DATE_FORMAT = 'ddd MMM Do';
const TIME_FORMAT = 'hh:mm a';

/**
 * ScheduleModal
 */

const ScheduleModal = ({
  mealPlanId,
  mealId,
  scheduleAt,
  show,
  onHide,
  onSave,
  translate,
}) => {
  const [date, setDate] = useState(moment(scheduleAt));
  const [time, setTime] = useState(moment(scheduleAt));
  const [isLoading, setLoading] = useState(false);

  const onDateChange = (val) => {
    setDate(val);
  };

  const onTimeChange = (val) => {
    setTime(val);
  };

  const submitForm = (event) => {
    event.preventDefault();
    setLoading(true);

    const schedule_at = `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`;
    onSave(mealPlanId, mealId, { meal: { schedule_at } });

    setTimeout(() => {
      window.location.reload();
      setLoading(false);
    }, 300);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <div className="modal-content">

        {/* Header */}
        <div className="modal-header">

          {/* Title */}
          <h6 className="modal-title" id="editeDateTime">
            {translate('plannedMealsScheduleModalDateTime')}
          </h6>

          {/* x button */}
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M10.165 11.4881L6.12082 7.44393L1.88434 11.681C1.45895 12.1063 0.768848 12.1063 0.343457 11.681C-0.0819337 11.2556 -0.0819337 10.5655 0.343457 10.1401L4.58048 5.90359L0.536272 1.85938C0.110881 1.43399 0.110881 0.744434 0.536272 0.319043C0.961663 -0.106348 1.65122 -0.106348 2.07661 0.319043L6.12082 4.36325L9.97222 0.511858C10.3976 0.0864671 11.0872 0.0864671 11.5131 0.511858C11.9385 0.937249 11.9385 1.62681 11.5131 2.0522L7.66116 5.90359L11.7054 9.9478C12.1308 10.3732 12.1308 11.0628 11.7054 11.4881C11.28 11.9135 10.5904 11.9135 10.165 11.4881V11.4881Z" fill="#141415" />
              </svg>
            </span>
          </button>
        </div>

        {/* Body */}
        <div className="modal-body">
          {isLoading
            ? (
              <div className="tw-flex tw-align-center tw-justify-center tw-items-center tw-w-full tw-transform tw-scale-[70%]">
                <Spinner />
              </div>
            )
            : (
              <form>
                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    {translate('plannedMealsScheduleModalDate')}
                  </label>
                  <div className="col-8">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="dropdown">
                          <DateTime
                            className="border-0 width-50 d-inline-block ml-4"
                            value={notBlank(date) ? moment(date).format(DATE_FORMAT) : ''}
                            dateFormat={DATE_FORMAT}
                            timeformat={false}
                            onChange={onDateChange}
                            closeOnSelect
                            inputProps={{ placeholder: 'Select Date', autoComplete: 'off' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Time</label>
                  <div className="col-8">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="dropdown">
                          <DateTime
                            className="border-0 width-50 d-inline-block ml-4"
                            value={notBlank(time) ? moment(time).format(TIME_FORMAT) : ''}
                            dateFormat={false}
                            timeformat={TIME_FORMAT}
                            timeConstraints={{ minutes: { step: 15 } }}
                            onChange={onTimeChange}
                            closeOnSelect
                            inputProps={{ placeholder: 'Select Time', autoComplete: 'off' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )
          }

        </div>

        {/* Footer */}
        {!isLoading &&
          (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={onHide}
            >
              { translate('plannedMealsScheduleModalCancel') }
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={submitForm}
            >
              {translate('plannedMealsScheduleModalSave') }
            </button>
          </div>
          )
        }
      </div>
    </Modal>
  );
};

ScheduleModal.propTypes = {
  mealPlanId: PropTypes.string.isRequired,
  mealId: PropTypes.string.isRequired,
  scheduleAt: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withI18n(ScheduleModal);
