/* eslint-disable max-len */
import React from 'react';

const MuslimFriendly = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#CD00CD"
    />
    <path
      d="M6.47648 7.6H4.76048V16H6.04448V9.496L8.55248 15.256H9.82448L12.3325 9.496V16H13.6165V7.6H11.9005L9.18848 13.732L6.47648 7.6ZM15.3545 16H16.6865V12.424H19.1465V11.236H16.6865V8.8H19.4945V7.6H15.3545V16Z"
      fill="white"
    />
  </svg>
);

export default MuslimFriendly;
