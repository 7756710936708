import dayjs from 'dayjs';
import { trackEvent } from 'libs/support/segment';

const buildViewParameters = (params = {}) => ({
  referrer: document.referrer,
  pagetype: 'reporting',
  country: window.CS.userTraits.country,
  url: window.location.href,
  ...params,
});

const formatDateRange = (startDate, endDate) => ([
  dayjs(startDate).format('YYYY-MM'),
  dayjs(endDate).format('YYYY-MM'),
]);

export const trackViewedReportingPage = () => {
  trackEvent('Viewed_Reporting_Page', buildViewParameters());
};

export const trackViewedSummaryTab = () => {
  trackEvent('Viewed_Summary_Tab', buildViewParameters());
};

export const trackViewedMealPlansTab = () => {
  trackEvent('Viewed_Meal_Plans_Tab', buildViewParameters());
};

export const trackViewedPantryProgramsTab = () => {
  trackEvent('Viewed_Pantry_Programs_Tab', buildViewParameters());
};

export const trackEventsTab = () => {
  trackEvent('Viewed_Events_or_Ad-hoc_Tab', buildViewParameters());
};

export const trackClickedExportOrdersButton = (startDate, endDate) => {
  const [formattedStartDate, formattedEndDate] = formatDateRange(startDate, endDate);

  trackEvent('Clicked_Export_Orders_Button', {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    country: window.CS.userTraits.country,
  });
};

export const trackDownloadedReport = (url, startDate, endDate) => {
  const [formattedStartDate, formattedEndDate] = formatDateRange(startDate, endDate);

  trackEvent('Downloaded_Report', {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    url,
    fileExtension: 'csv',
    country: window.CS.userTraits.country,
  });
};

export const trackFilteredDateRange = (startDate, endDate) => {
  const [formattedStartDate, formattedEndDate] = formatDateRange(startDate, endDate);

  trackEvent('Filtered_Date_Range', {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  });
};
