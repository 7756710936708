/* eslint-disable max-len */
import { number, string } from 'prop-types';
import React from 'react';

const Email = ({ size = 20, color = '#95989D' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.42294 4.38525C3.04076 4.38525 2.72413 4.70092 2.72413 5.08197V14.918C2.72413 15.2991 3.04076 15.6148 3.42294 15.6148H16.5769C16.9591 15.6148 17.2757 15.2991 17.2757 14.918V5.08197C17.2757 4.70092 16.9591 4.38525 16.5769 4.38525H3.42294ZM0.833252 5.08197C0.833252 3.65973 1.99645 2.5 3.42294 2.5H16.5769C18.0034 2.5 19.1666 3.65973 19.1666 5.08197V14.918C19.1666 16.3403 18.0034 17.5 16.5769 17.5H3.42294C1.99645 17.5 0.833252 16.3403 0.833252 14.918V5.08197Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.00424 4.5624C1.30367 4.1428 1.89318 4.04075 2.32094 4.33447L9.99992 9.60729L17.6789 4.33447C18.1067 4.04075 18.6962 4.1428 18.9956 4.5624C19.295 4.98201 19.191 5.56028 18.7632 5.854L10.5421 11.4991C10.2166 11.7226 9.78328 11.7226 9.45775 11.4991L1.2366 5.854C0.808835 5.56028 0.704805 4.98201 1.00424 4.5624Z" fill={color} />
  </svg>
);

Email.propTypes = {
  size: number,
  color: string,
};

export default Email;
