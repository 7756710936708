/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { withAppContext } from 'libs/support/appContext';
import SelectIllustration from 'libs/components/Illustrations/Select';

/* Actions */
import actions from 'packs/meals/actions';

import { prevent } from 'libs/support/prevent';


/**
 * SelectYourMealHeader
 */
const SelectYourMealHeader = ({
  actions,
  mealStore,
  userMealCartStore,
}) => {
  const userName = mealStore.getIn(['currentUser', 'firstName']);
  const timezone = mealStore.get('timezone');
  const scheduleAt = moment(mealStore.get('scheduleAt')).tz(timezone);
  const deadlineAt = moment(mealStore.get('deadlineAt')).tz(timezone);
  const openAt = mealStore.get('openAt') ? moment(mealStore.get('openAt')).tz(timezone) : '';
  const picksOneMeal = mealStore.getIn(['groupOrderSetting', 'picksOneMeal']);
  const allowSelfCheckout = mealStore.getIn(['groupOrderSetting', 'allowSelfCheckout']);
  const budget = mealStore.getIn(['groupOrderSetting', 'budget']);
  const showPrice = mealStore.getIn(['groupOrderSetting', 'showPrice']);
  const isEditable = !mealStore.get('preview');

  const timeLeftString = (deadlineAt) => {
    const now = moment();
    const daysLeft = moment(deadlineAt).diff(now, 'days');
    const hoursLeft = moment(deadlineAt).diff(now, 'hours');
    const minutesLeft = moment(deadlineAt).diff(now, 'minutes');

    if (daysLeft > 0) {
      return `(${daysLeft} days to go)`;
    } else if (hoursLeft > 0) {
      return `(${hoursLeft} hours to go)`;
    }
    return `(${minutesLeft} minutes to go)`;
  };

  const timeToGoString = (openAt) => {
    const now = moment();
    const daysToGo = moment(openAt).diff(now, 'days');
    const hoursToGo = moment(openAt).diff(now, 'hours');
    const minutesToGo = moment(openAt).diff(now, 'minutes');

    if (daysToGo > 0) {
      return `(${daysToGo} days to go)`;
    } else if (hoursToGo > 0) {
      return `(${hoursToGo} hours to go)`;
    }
    return `(${minutesToGo} minutes to go)`;
  };

  const handleSkip = (e) => {
    e.preventDefault();

    prevent(actions.skipUserMealCart(mealStore.get('mealPlanId'), userMealCartStore.get('uuid')));
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card card-panel meal-banner meal-banner--success">
          <div className="card-body meal-banner__body">
            <div className="meal-banner__image" >
              <SelectIllustration />
            </div>
            <div className="meal-banner__content" >
              <h5>Hi, { userName }</h5>

              {
                isEditable ? (
                  <p className="lead meal-banner__lead">

                    Select your meal for  { scheduleAt.format('dddd, D MMMM') }

                    <span>
                      &nbsp;or&nbsp;
                      <a
                        href="#"
                        data-turbolinks="false"
                        onClick={handleSkip}
                      >
                        Skip your meal
                      </a> for this day.
                    </span>
                    <br />
                    {
                      !allowSelfCheckout && (
                        picksOneMeal ?
                          <span>Pick 1 meal item - </span>
                        : showPrice && <span>Budget: ${ budget } - </span>
                      )
                    }

                    Deadline to order: { deadlineAt.format('dddd, h:mmA') }
                    <span>&nbsp;</span>
                    <span className="text-danger">
                      { timeLeftString(deadlineAt) }
                    </span>
                  </p>
                ) : (
                  <p className="lead meal-banner__lead">
                    This meal for { scheduleAt.format('dddd, D MMMM') }, { scheduleAt.format('h:mmA') } is in preview.
                    <br />
                    {openAt && (
                      <>
                        Open for ordering: {openAt.format('dddd, D MMMM')} at {openAt.format('h:mmA')}
                        <span>&nbsp;</span>
                        <span className="text-danger">
                          {timeToGoString(openAt)}
                        </span>
                        <br />
                      </>
                    )}
                    Deadline to order: { deadlineAt.format('dddd, D MMMM') }, { deadlineAt.format('h:mmA') }
                    <span>&nbsp;</span>
                    <span className="text-danger">
                      { timeLeftString(deadlineAt) }
                    </span>
                  </p>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  userMealCartStore: state.$$userMealCartStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

SelectYourMealHeader.propTypes = {
  actions: object.isRequired,
  mealStore: object.isRequired,
  userMealCartStore: object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(SelectYourMealHeader));
