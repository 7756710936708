import Immutable from 'immutable';

import { constructCartVariationsStore } from 'packs/meals/stores/mealStore';

import * as mealActionTypes from 'packs/meals/constants/mealConstants';
import * as userMealCartActionTypes from 'packs/meals/constants/userMealCartConstants';
import * as actionTypes from '../constants/cartVariationsConstants';
import * as addOnActionTypes from '../constants/addOnConstants';
import * as menuItemActionTypes from '../constants/menuItemConstants';
import * as cartActionTypes from '../constants/cartConstants';
import * as orderVariationsActionTypes from '../constants/orderVariationsConstants';
import * as addOnReducer from './cartVariation/addOnReducer';
import * as cartVariationReducer from './cartVariation/cartVariationReducer';
import * as menuItemReducer from './cartVariation/menuItemReducer';
import * as cartReducer from './cartVariation/cartReducer';

export const defaultCartVariation = Immutable.fromJS({
  quantity: 0,
  variation: {},
  cart_variation_option_choices: [],
  cart_variation_item_add_on_options: [],
  cart_variation_service_add_on_options: [],
});

export const $$initialState = Immutable.fromJS({
  subtotal: '$0',
  isSavingCartVariation: false,
  showMenuItemForm: false,
  cartUserId: null,
  cartVariations: [],
  saveErrors: [],
  selectedMenuItem: {},
  selectedCartVariation: defaultCartVariation,
  totalQuantity: 0,
});

export default function cartVariationsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;
  const defaultHash = {
    isSavingCartVariation: false,
    saveErrors: [],
    segmentTrack: {},
  };

  switch (type) {
    case mealActionTypes.FETCH_MEAL_SUCCESS: {
      return $$state.withMutations(state => {
        state.merge(constructCartVariationsStore(response));
      });
    }
    case actionTypes.CHECK_ADD_ON_VALIDITY: {
      return addOnReducer.checkAddOnValidity(response, defaultHash, $$state);
    }

    case actionTypes.SELECT_VARIATION: {
      return $$state.update('selectedCartVariation', data => data.set('variation_id', response));
    }

    case orderVariationsActionTypes.FETCH_ORDER_VARIATION_SUCCESS: {
      return cartVariationReducer.fetchSuccess(response, defaultHash, $$state, true);
    }

    case actionTypes.FETCH_CART_VARIATION_SUCCESS: {
      return cartVariationReducer.fetchSuccess(response, defaultHash, $$state);
    }

    case actionTypes.UPDATING_QUANTITY_CART_VARIATION:
    case actionTypes.SAVING_CART_VARIATION: {
      return cartVariationReducer.saving($$state);
    }

    case userMealCartActionTypes.SKIP_USER_MEAL_CART_SUCCESS:
    case userMealCartActionTypes.UNSKIP_USER_MEAL_CART_SUCCESS:
    case userMealCartActionTypes.RESET_USER_MEAL_CART_SUCCESS: {
      return cartVariationReducer.resetUserCartVariations(response.user_meal_cart, defaultHash, $$state);
    }

    case actionTypes.UPDATE_QUANTITY_CART_VARIATION_SUCCESS:
    case actionTypes.RESET_QUANTITY_CART_VARIATION_SUCCESS:
    case actionTypes.SAVE_CART_VARIATION_SUCCESS: {
      return cartVariationReducer.save(response, defaultHash, $$state);
    }
    case actionTypes.SAVE_CART_VARIATION_FAILURE: {
      return cartVariationReducer.error(response, defaultHash, $$state);
    }

    case actionTypes.DELETE_CART_VARIATION_SUCCESS: {
      return cartVariationReducer.destroy(response, defaultHash, $$state);
    }

    case menuItemActionTypes.HIDE_MENU_ITEM_FORM: {
      return menuItemReducer.hideMenuItemForm(defaultHash, $$state);
    }

    case menuItemActionTypes.FETCH_MENU_ITEM_SUCCESS: {
      return menuItemReducer.fetchSuccess(response, defaultHash, $$state);
    }

    case cartActionTypes.SAVE_CART_LOCAL: {
      return cartReducer.saveLocal(response, $$state);
    }

    case addOnActionTypes.ADD_TEMPORARY_ITEM_ADD_ON_OPTION_PHOTO: {
      return cartVariationReducer.addTemporaryItemAddOnOptionPhoto(response, defaultHash, $$state);
    }

    case addOnActionTypes.ADD_TEMPORARY_ITEM_ADD_ON_OPTION_PHOTO_FAILURE: {
      return cartVariationReducer.addTemporaryItemAddOnOptionPhotoFailure(response, $$state);
    }

    case addOnActionTypes.DELETE_ITEM_ADD_ON_OPTION_PHOTO: {
      return cartVariationReducer.deleteItemAddOnOptionPhoto(response, defaultHash, $$state);
    }

    case addOnActionTypes.UPDATE_ITEM_ADD_ON_OPTION_TEXT: {
      return cartVariationReducer.updateItemAddOnOptionText(response, defaultHash, $$state);
    }
    default: {
      return $$state;
    }
  }
}

/*
 * Find by Menu Item Id
 */
export const findByMenuItemId = (cartVariations, menuItemId) => {
  let cartVariation = null;

  const index = cartVariations.findLastIndex((data) => data.getIn(['menu_item_id']) === menuItemId);

  if (index >= 0) {
    cartVariation = cartVariations.get(index);
  }

  return Immutable.fromJS(cartVariation);
};

/*
 * Find all cart variations by cart id
 */
export const findAllByCartId = (cartVariations, cartId) => {
  const filteredCartVariations = cartVariations.filter((data) => data.get('cart_id') === cartId);

  return Immutable.fromJS(filteredCartVariations);
};

export function groupByCardId(cartVariations) {
  return Immutable.fromJS(cartVariations.reduce((hash, cartVariation) => {
    const cartId = cartVariation.get('cart_id');

    // eslint-disable-next-line no-param-reassign
    hash[cartId] = hash[cartId] || [];
    hash[cartId].push(cartVariation);

    return hash;
  }, {}));
}
