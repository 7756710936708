/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import { blankish } from 'libs/support/string';

import MenuItemPhoto from '../shared/MenuItemPhoto';
import MenuItemInfo from '../shared/MenuItemInfo';

/**
 * CustomMenuItem
 */

const CustomMenuItem = ({
  customMenuItem,
  mealAdmin,
  mealConfirmed,
  updateQuantity,
  mealPlanId,
}) => {
  const updateQuantityHandler = (e, quantity) => {
    e.preventDefault();

    return updateQuantity(mealPlanId, customMenuItem.get('uuid'), {
      cart_id: customMenuItem.get('cart_id'),
      custom_menu_item: { quantity },
    });
  };

  return (
    <div className="media tw-flex-col md:tw-flex-row">
      <MenuItemPhoto />

      <div className="media-body">
        <div className="order-item">
          <div className="tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-items-center">
            <div className="tw-space-0">
              <span className="order-item-title">
                { customMenuItem.get('name') }
              </span>
            </div>

            <div className="tw-mt-4 md:tw-m-0">
              <MenuItemInfo
                objectId={customMenuItem.get('uuid')}
                quantity={customMenuItem.get('quantity')}
                minQuantity={1}
                subtotal={customMenuItem.get('subtotal')}
                mealAdmin={mealAdmin}
                mealConfirmed={mealConfirmed}
                updateQuantityHandler={updateQuantityHandler}
              />
            </div>
          </div>

          <div className={classNames('tw-mb-4', {
            '!tw-mt-1': !blankish(customMenuItem.get('description')),
          })}
          >
            {renderHTML(customMenuItem.get('description'))}
          </div>

          {/* Additional Instructions */}
          {
            customMenuItem.get('special_instructions') &&
            <div className={classNames('tw-w-full tw-flex tw-flex-row tw-gap-1', {
              'tw-px-2 tw-py-2 tw-my-2 tw-bg-blueberry-50 tw-rounded': true,
            })}
            >
              <span className="tw-text-gray-900">
                <span className="tw-inline-block tw-font-bold tw-mr-2">
                  Special Instructions:
                </span>
                {customMenuItem.get('special_instructions')}
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

CustomMenuItem.propTypes = {
  customMenuItem: PropTypes.object.isRequired,
  mealAdmin: PropTypes.bool.isRequired,
  mealConfirmed: PropTypes.bool.isRequired,
  mealPlanId: PropTypes.string.isRequired,
  updateQuantity: PropTypes.func.isRequired,
};

export default CustomMenuItem;
