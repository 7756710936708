/* eslint max-len: 0 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import PastUserMealCarts from 'packs/meals/components/GroupOrderMeal/PastUserMealCarts';
import { string } from 'prop-types';

/**
 * PastUserMealCartsContainer
 */
const PastUserMealCartsContainer = ({
  mealId,
  mealPlanId,
}) => (
  <PastUserMealCarts
    {
        ...{
          mealId,
          mealPlanId,
        }
      }
  />
);

const mapStateToProps = ({
  $$mealStore,
}) => ({
  mealId: $$mealStore.get('uuid'),
  mealPlanId: $$mealStore.get('mealPlanId'),
});

PastUserMealCartsContainer.propTypes = {
  mealId: string,
  mealPlanId: string,
};

export default memo(connect(mapStateToProps)(PastUserMealCartsContainer));
