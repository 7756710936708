import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { useExportMealPlanUsers } from "packs/meal_plan_users/queries/useExportMealPlanUsers";

const BulkExportModal = ({
  mealPlanId,
  isShow,
  onHide,
  translate,
  cable,
}) => {
  const { mutate: exportMealPlanUsers } = useExportMealPlanUsers({
    mealPlanId: mealPlanId,
    sid: cable.socketId,
  });

  const [status, setStatus] = useState(null);
  const [exportResult, setExportResult] = useState({});

  useEffect(() => {
    const channel = cable.subscribeChannel(
      `mealPlanUserExportChannel`,
      (response) => {
        setStatus(response.status);

        if (response.status === "succeeded") {
          return setExportResult({ message: response.msg, link: response.link });
        }

        if (response.status === "failed") {
          return setExportResult({ errors: response.errors });
        }

        if (response.msg) {
          return setExportResult({ message: response.msg });
        }
      }
    );

    return () => cable.unsubscribeChannel(channel);
  }, []);

  useEffect(() => {
    if (isShow) {
      exportMealPlanUsers();
    }
  }, [isShow]);

  return (
    <Modal show={isShow} onHide={onHide} dialogClassName="modal-sm">
      <div className="modal-content">
        <div className="modal-header">
          <h6 className="modal-title">
            {translate('mealPlanUsersBulkExportModalTitle')}
          </h6>

          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.165 11.4881L6.12082 7.44393L1.88434 11.681C1.45895 12.1063 0.768848 12.1063 0.343457 11.681C-0.0819337 11.2556 -0.0819337 10.5655 0.343457 10.1401L4.58048 5.90359L0.536272 1.85938C0.110881 1.43399 0.110881 0.744434 0.536272 0.319043C0.961663 -0.106348 1.65122 -0.106348 2.07661 0.319043L6.12082 4.36325L9.97222 0.511858C10.3976 0.0864671 11.0872 0.0864671 11.5131 0.511858C11.9385 0.937249 11.9385 1.62681 11.5131 2.0522L7.66116 5.90359L11.7054 9.9478C12.1308 10.3732 12.1308 11.0628 11.7054 11.4881C11.28 11.9135 10.5904 11.9135 10.165 11.4881V11.4881Z" // eslint-disable-line max-len
                  fill="#141415"
                />
              </svg>
            </span>
          </button>
        </div>

        <div className="modal-body">
          <div className="modal-body-content container-fluid">

            {exportResult.errors && <p>{exportResult.errors}</p>}
            {exportResult.message && <p>{exportResult.message}</p>}
            {
              exportResult.link &&
              <a
                href={exportResult.link}
                rel="noreferrer"
                target="_blank"
              >
                {translate('mealPlanUsersBulkExportModalDownloadCsv')}
              </a>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
};

BulkExportModal.propTypes = {
  mealPlanId: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  cable: PropTypes.shape({
    socketId: PropTypes.string.isRequired,
    subscribeChannel: PropTypes.func.isRequired,
    unsubscribeChannel: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAppContext(withI18n(BulkExportModal));
