import styled from 'styled-components';

export const CalendarNavigator = styled.button.attrs(props => ({
  className: props.klass,
}))`
  background: white;
  display: flex;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #D5D6D8;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 10;

  :disabled {
    opacity: 0.3;
    cursor: auto;
  }
`;

export const CalendarButtonGroup = styled.div`
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
`;

export const MobileLeftSeparator = styled.div`
  background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
  width: 24px;
  height: 36px;
  position: absolute;
  left: 0;
  margin-left: -0.5rem;
`;
export const MobileRightSeparator = styled.div`
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
  width: 24px;
  height: 36px;
  position: absolute;
  right: 0;
`;
