/* eslint-disable no-shadow */
import React from 'react';
import uppercamelcase from 'uppercamelcase';
import styled, { css } from 'styled-components';
import {
  number,
  string,
  func,
} from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import Nbsp from 'libs/components/Nbsp';
import BaseComponent from 'libs/components/BaseComponent';

const Container = styled.div`
  > div {
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  ${props => props.active && css`
    border-bottom: 2px solid ${props => props.theme.colors.lima};
    color: ${props => props.theme.colors.blackRussian};
    font-weight: bold;
  `}
`;


class MealStatusTab extends BaseComponent {
  static propTypes = {
    count: number.isRequired,
    tab: string.isRequired,
    activeTab: string,
    onClick: func.isRequired,
    translate: func.isRequired,
  };

  handleOnClick = e => {
    e.preventDefault();

    const {
      tab,
      activeTab,
      onClick,
    } = this.props;

    if (tab !== activeTab) onClick(tab);
  }

  render = () => {
    const {
      count,
      tab,
      activeTab,
      translate,
    } = this.props;

    return (
      <Container onClick={this.handleOnClick} active={tab === activeTab}>
        <div>
          { translate(`groupedOrdersTerms${uppercamelcase(tab)}`) }
          <Nbsp />
          <span className="badge badge-light mb-2">
            { count }
          </span>
        </div>
      </Container>
    );
  }
}

export default withI18n(MealStatusTab);
