/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import BaseComponent from 'libs/components/BaseComponent';
import renderHTML from 'react-render-html';
import $ from 'jquery';

import DietaryBadgeContainer from 'packs/meals/components/Shared/DietaryBadgeContainer';

import MenuItemSelection from './MenuItemSelection';
import MenuItemIncludedItems from './MenuItemIncludedItems';
import MenuItemMetas from '../shared/MenuItemMetas';
import MenuItemPhoto from '../shared/MenuItemPhoto';
import MenuItemInfo from '../shared/MenuItemInfo';

/**
 * MenuItem
 */

class MenuItem extends BaseComponent {
  static propTypes = {
    cartVariation: PropTypes.object.isRequired,
    menuItem: PropTypes.object.isRequired,
    mealPlanId: PropTypes.string.isRequired,
    onEditSelection: PropTypes.func.isRequired,
    mealAdmin: PropTypes.bool.isRequired,
    mealConfirmed: PropTypes.bool.isRequired,
    updateQuantity: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    bindAll(this, ['updateQuantityHandler']);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  updateQuantityHandler(e, quantity) {
    e.preventDefault();

    const { cartVariation, mealPlanId, updateQuantity } = this.props;
    return updateQuantity(mealPlanId, cartVariation.get('uuid'), quantity, cartVariation);
  }

  render() {
    const {
      cartVariation,
      menuItem,
      mealAdmin,
      mealConfirmed,
      onEditSelection,
    } = this.props;

    const dietaries = menuItem.get('dietaries');

    return (
      <div className="media flex-column flex-md-row">
        <MenuItemPhoto photo={menuItem.getIn(['main_photo', 'medium'])} />

        <div className="media-body">
          <div className="order-item">
            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mb-2">
              <div>
                <span className="order-item-title">
                  { cartVariation ? cartVariation.get('name') : menuItem.get('name') }
                </span>

                {
                  dietaries &&
                    <div className="badges">
                      <DietaryBadgeContainer
                        type="caterer"
                        dietaries={dietaries}
                      />
                    </div>
                }

              </div>

              <MenuItemInfo
                objectId={cartVariation.get('uuid')}
                quantity={cartVariation.get('quantity')}
                minQuantity={cartVariation.get('min_order_quantity') || 1}
                subtotal={cartVariation.get('subtotal')}
                mealAdmin={mealAdmin}
                mealConfirmed={mealConfirmed}
                updateQuantityHandler={this.updateQuantityHandler}
              />
            </div>

            <div className="mb-2">
              { renderHTML(menuItem.get('description')) }
            </div>

            <MenuItemIncludedItems includedItems={menuItem.get('included_items')} />
            <MenuItemSelection
              cartVariation={cartVariation}
              editable={!mealConfirmed}
              onEdit={onEditSelection}
            />
            <MenuItemMetas
              quantity={menuItem.get('quantity')}
              quantityType={menuItem.get('quantity_type')}
              minServings={menuItem.get('min_servings')}
              maxServings={menuItem.get('max_servings')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MenuItem;
