/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, func, object } from 'prop-types';
import { prevent } from 'libs/support/prevent';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { notBlank } from 'libs/support/string';
import $ from 'jquery';

/* Actions */
import actions from 'packs/meals/actions';

/* from */
import * as fromCarts from 'packs/meals/reducers/cartsReducer';
import * as fromCartVariations from 'packs/common/reducers/cartVariationsReducer';

import CloseModal from 'libs/components/Icons/CloseModal';
import MealCartList from './MealCartList';
import HeadcountField from './HeadcountField';
import AmountField from './AmountField';
import MealCartListSkeletal from './MealCartListSkeletal';

/**
 * MealCart
 */
const MealCart = ({
  mealStore,
  cartsStore,
  cartVariationsStore,
  menuItemsStore,
  customMenuItemsStore,
  vendorsStore,
  vendors,
  actions,
  translate,
}) => {
  const totalVariationsQuantities = cartVariationsStore.get('totalQuantity');
  const totalCustomMenuQuantities = customMenuItemsStore.get('totalQuantity');
  const totalQuantities = (totalVariationsQuantities + totalCustomMenuQuantities);
  const checkoutProcessing = mealStore.get('processingMealCheckout');
  const errorMessage = mealStore.get('mealCheckoutErrorMsg');
  const successMessage = mealStore.get('mealCheckoutSuccessMsg');
  const requiresHeadcountInput = mealStore.get('requiresHeadcountInput');
  const cartVariationsByCartId = fromCartVariations.groupByCardId(cartVariationsStore.get('cartVariations'));
  const mealConfirmed = mealStore.get('confirmed');
  const mealCancelled = mealStore.get('cancelled');
  const mealProcessing = mealStore.get('processing');
  const mealOpen = mealStore.get('open');
  const mealDue = mealStore.get('due');
  const mealExpired = mealStore.get('expired');
  const mealAdmin = mealStore.get('mealAdmin');
  const mealPlanId = mealStore.get('mealPlanId');
  const isLoading = mealStore.get('isLoading');
  const isUnprocessable = (mealExpired || mealConfirmed || mealCancelled);
  const isEditable = isUnprocessable ? false : mealDue ? false : (mealOpen || mealProcessing || !mealDue);
  const isDemoMeal = mealStore.get('demoMeal');

  const [headcount, setHeadcount] = useState(mealStore.getIn(['plannedMealSetting', 'headcount']));
  const [show, setShow] = useState(false);
  const [averageSpending, setAverageSpending] = useState(0);
  const [enableVendorSection, setEnableVendorSection] = useState(false);

  const denominator = requiresHeadcountInput ? headcount : totalQuantities;
  const totals = fromCarts.getTotals(cartsStore, denominator);

  const cartItems = cartVariationsStore.get('cartVariations').filter(variation => variation.get('quantity') > 0);

  const checkout = () => {
    if (isDemoMeal) return;

    actions.mealCheckout(mealStore.get('mealPlanId'), mealStore.get('uuid'));

    const finalHeadcount = requiresHeadcountInput ? headcount : cartVariationsStore.get('totalQuantity');
    actions.updateHeadcount(
      mealStore.get('mealPlanId'),
      mealStore.get('uuid'),
      mealStore.getIn(['plannedMealSetting', 'uuid']),
      finalHeadcount,
    );
  };

  const toggleModal = () => setShow(!show);

  useEffect(() => {
    setAverageSpending(totals.get('average'));
  }, [totals]);

  useEffect(() => {
    // available vendor: 2, cart by vendor: 2 -> true
    // available vendor: 2, cart by vendor: 1 -> false
    // available vendor: 1, cart by vendor: 1 -> false
    const available = vendors.map(v => v.get('id'));
    const current = cartItems.map(i => i.get('vendor_id')).toSet();
    const enable = (mealConfirmed)
      ? (available.size > 1) ? current.size > 0 : false
      : available.size > 1;

    setEnableVendorSection(enable);
  }, [cartItems]);

  useEffect(() => {
    if (show) {
      $('body').addClass('tw-overflow-hidden');
      $('[data-testid="vendors-navigator"').addClass('-tw-translate-y-24');
    } else {
      $('body').removeClass('tw-overflow-hidden');
      $('[data-testid="vendors-navigator"').removeClass('-tw-translate-y-24');
    }
  }, [show]);

  return (
    <Fragment>
      {show &&
        <div
          className="tw-fixed tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-[100] tw-bg-gray-900 tw-bg-opacity-[0.6]"
          onClick={toggleModal}
        />
      }
      <div
        data-testid="meal-cart"
        className={classNames('tw-hidden md:tw-block tw-bg-white tw-overflow-hidden', {
          'tw-border-[1px] tw-border-gray-200 tw-rounded-xl tw-shadow-lg': true,
          'tw-fixed tw-bottom-0 tw-left-0 tw-z-[1000]': show,
          '!tw-block tw-w-full tw-rounded-es-none tw-rounded-ee-none': show,
        })}
      >
        <div
          className={classNames('tw-absolute tw-w-full -tw-top-8', {
            'tw-flex tw-items-center tw-justify-center': true,
            'md:tw-hidden': true,
          })}
          onClick={toggleModal}
        >
          <CloseModal />
        </div>
        <div className={classNames('tw-bg-gray-50 tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 md:tw-px-8', {
          'tw-p-4 tw-py-3 tw-rounded-tl-xl tw-rounded-tr-xl tw-border-b-[1px] tw-border-gray-100': true,
        })}
        >
          <h4 className="tw-m-0 tw-text-lg tw-text-gray-900">Cart</h4>
          <p className="tw-m-0">{`${totalQuantities} items`}</p>
        </div>
        <div className={classNames('tw-max-h-[88vh] md:tw-max-h-[inherit]', {
          'tw-overflow-hidden tw-overflow-y-auto md:tw-overflow-hidden': true,
          '!tw-pb-24 md:!tw-pb-0': show,
        })}
        >
          {isLoading ?
            <MealCartListSkeletal size={2} /> :
            <MealCartList
              {...{
                averageSpending,
                cartsStore,
                cartVariationsByCartId,
                cartVariationsStore,
                customMenuItemsStore,
                denominator,
                enableVendorSection,
                isEditable,
                mealAdmin,
                mealConfirmed,
                mealPlanId,
                menuItemsStore,
                requiresHeadcountInput,
                saveCustomMenuItem: actions.saveCustomMenuItem,
                setHeadcount,
                totalQuantities,
                totals,
                updateQuantityCartVariation: actions.updateQuantityCartVariation,
                vendors,
                vendorsStore,
                translate,
              }}
              carts={cartsStore}
            />
          }

          <div className="tw-bg-gray-50">
            <div className="tw-flex tw-flex-col tw-gap-1 tw-p-6 md:tw-p-8">
              <AmountField
                data-testid="cart-subtotal"
                title={`+ ${translate('adminMealCartsTermsSubtotal')}`}
                value={totals.get('subtotal')}
                className="tw-text-base"
                labelClassName="tw-text-base !tw-text-gray-700"
              />

              {notBlank(totals.get('totalSmallOrderFee')) && (
                <AmountField
                  title={`+ ${translate('adminMealCartsTermsSmallOrderFee')}`}
                  value={totals.get('totalSmallOrderFee')}
                  className="tw-text-base"
                  labelClassName="tw-text-base !tw-text-gray-700"
                />
              )}

              <AmountField
                title={`+ ${translate('adminMealCartsTermsDeliveryFee')}`}
                value={totals.get('deliveryAmount')}
                className="tw-text-base"
                labelClassName="tw-text-base !tw-text-gray-700"
              />

              <AmountField
                title={`+ ${translate('adminMealCartsTermsSurcharge', { names: totals.get('surchargeNames') })}`}
                value={totals.get('surchargeAmount')}
                className="tw-text-base"
                labelClassName="tw-text-base !tw-text-gray-700"
              />

              {totals.get('taxEnabled') && (
                <AmountField
                  title={`+ ${translate('adminMealCartsTermsTax')}`}
                  value={totals.get('taxAmount')}
                  className="tw-text-base"
                  labelClassName="tw-text-base !tw-text-gray-700"
                />
              )}

              <AmountField
                title={`- ${translate('adminMealCartsTermsDiscount')}`}
                value={`${totals.get('discountAmount')}`}
                className="tw-text-base"
                labelClassName="tw-text-base !tw-text-gray-700"
              />

              <AmountField
                title={`- ${translate('adminMealCartsTermsCaterspotRefund')}`}
                value={`${totals.get('caterspotRefund')}`}
                className="tw-text-base"
                labelClassName="tw-text-base !tw-text-gray-700"
              />

              <AmountField
                title={`- ${translate('adminMealCartsTermsVendorRefund')}`}
                value={`${totals.get('vendorRefund')}`}
                className="tw-text-base"
                labelClassName="tw-text-base !tw-text-gray-700"
              />

              <hr className="tw-my-1 tw-border-b-[1px] tw-border-gray-200" />

              <AmountField
                data-testid="cart-total"
                title={
                  totals.get('taxEnabled')
                    ? translate('adminMealCartsTermsTotalIncludeTax')
                    : translate('adminMealCartsTermsTotalExcludeTax')
                }
                value={totals.get('total')}
                labelClassName="tw-text-base tw-font-semibold tw-text-gray-900"
                className="total-price tw-text-2xl tw-font-bold tw-text-gray-900"
              />
            </div>
          </div>

          {mealAdmin &&
            <HeadcountField
              headcount={denominator}
              setHeadcount={setHeadcount}
              translate={translate}
              editable={requiresHeadcountInput && !mealConfirmed}
              averageSpending={averageSpending}
              className="!tw-w-11"
            />
          }

          {/* Error alert */}
          {errorMessage && (
            <div className="tw-bg-white tw-px-4">
              <div className="alert alert-danger">
                {/* {errorMessage} */}
                <button
                  type="button"
                  className="close"
                  style={{ margin: '0 0 0 0' }}
                  onClick={prevent(actions.dismissAlert)}
                >
                  &times;
                </button>
              </div>
            </div>
          )}

          {/* Success alert */}
          {successMessage && (
            <div className="tw-bg-white tw-px-4">
              <div className="alert alert-success tw-text-gray-900">
                {successMessage}
                <button
                  type="button"
                  className="close"
                  style={{ margin: '0 0 0 0' }}
                  onClick={prevent(actions.dismissAlert)}
                >
                  &times;
                </button>
              </div>
            </div>
          )}

          {mealAdmin &&
            <div className="tw-fixed md:tw-relative tw-bottom-0 tw-bg-white md:tw-bg-transparent tw-w-full">
              <div className={classNames('tw-flex tw-flex-col tw-gap-1 tw-p-4 md:tw-p-8 md:tw-py-6 tw-mb-1', {
                'tw-shadow-[0_-2px_10px_rgba(0,0,0,0.1)]': show,
              })}
              >
                {/* Confirm/Meal Lock Button */}
                <div className="tw-w-full">
                  {isEditable && (
                    <button
                      className={classNames('btn btn-primary btn-block !tw-text-base !tw-font-bold tw-py-2 tw-mt-2', {
                        '!tw-border-gray-200 !tw-text-gray-500': totalQuantities === 0,
                      })}
                      onClick={prevent(checkout)}
                      disabled={checkoutProcessing || totalQuantities === 0}
                    >
                      {checkoutProcessing ? 'Processing ...' : 'Confirm Order'}
                    </button>
                  )}
                  {!isEditable && (
                    <button
                      className={classNames('btn btn-primary btn-block !tw-text-base !tw-font-bold tw-py-2 tw-mt-2', {
                        '!tw-border-gray-200 !tw-text-gray-500': true,
                      })}
                      disabled
                    >
                      Meal Locked
                    </button>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div
        className={classNames('tw-fixed tw-z-10 tw-w-full tw-bottom-0 tw-left-0', {
          'md:tw-hidden tw-shadow-[0_-2px_10px_rgba(0,0,0,0.1)]': true,
        })}
        onClick={toggleModal}
      >
        <div className="tw-bg-white tw-px-4 tw-py-4 tw-overflow-hidden">
          <div
            className={
              classNames('tw-bg-gray-300 tw-rounded tw-px-3 tw-py-3 tw-flex tw-flex-row tw-justify-between tw-gap-1 tw-text-[16px]', {
                '!tw-bg-broccoli-600 ': mealAdmin && isEditable,
              })}
          >
            <div className={classNames('tw-text-gray-900', {
              '!tw-text-white': isEditable,
            })}
            >
              <span className="tw-m-0">Cart &bull;</span>
              <span className="tw-ml-1">{`${totalQuantities} items`}</span>
            </div>
            <div
              className={classNames('tw-text-gray-900 tw-m-0', {
                '!tw-text-white': isEditable,
              })}
            >
              {totals.get('total')}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MealCart.propTypes = {
  mealStore: object.isRequired,
  cartsStore: object.isRequired,
  cartVariationsStore: object.isRequired,
  menuItemsStore: object.isRequired,
  customMenuItemsStore: object.isRequired,
  vendorsStore: object.isRequired,
  vendors: any,
  actions: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = state => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
  menuItemsStore: state.$$menuItemsStore,
  customMenuItemsStore: state.$$customMenuItemsStore,
  vendorsStore: state.$$vendorsStore,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(withI18n(MealCart)));
