/* eslint jsx-a11y/label-has-for: 0, no-return-assign: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import $ from 'jquery';
import bindAll from 'lodash/bindAll';
import map from 'lodash/map';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'react-select';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

import { generateMonths, generateDays } from './Shared';

const TEXTAREA = 'textarea';
const SELECT_CITY = 'selectCity';
const SELECT_DATE_MONTH = 'selectDateMonth';
const SELECT_DATE_MONTH_DATEPICKER = 'selectDateMonthPicker';
const SELECT = 'select';
const REACT_SELECT = 'react-select';

class FieldComponent extends BaseComponent {
  static propTypes = {
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.any,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    disabled: false,
  }

  constructor() {
    super();

    bindAll(this, ['handleOnKeyUp', 'handleOnEvent']);
  }

  componentDidMount() {
    if (this.props.type === SELECT_DATE_MONTH_DATEPICKER) {
      $(this.inputDatePicker).datetimepicker({
        format: 'MM/DD/YYYY',
        useCurrent: false,
        minDate: moment().add(1, 'days').startOf('day'),
        icons: {
          previous: 'cs-icon-prev',
          next: 'cs-icon-next',
        },
      });
    }
  }

  handleOnKeyUp(e) {
    const { isRequired, onKeyUp } = this.props;

    if (isRequired) {
      const $field = $(e.currentTarget);
      const fieldValue = $field.val();

      if (notBlank(fieldValue)) {
        $field.removeClass('form-control-danger');
        $field.closest('.form-group').removeClass('has-danger');
      }
    }

    if (onKeyUp) onKeyUp(e);
  }

  handleOnEvent(e) {
    const { isRequired, onChange } = this.props;

    if (isRequired) {
      const $field = $(e.currentTarget);
      const fieldValue = $field.val();

      if (notBlank(fieldValue)) {
        $field.removeClass('form-control-danger');
        $field.closest('.form-group').removeClass('has-danger');
      }
    }

    if (onChange) onChange(e);
  }

  renderField() {
    const {
      defaultValue,
      disabled,
      isRequired,
      rows,
      type,
      name,
      placeholder,
      options,
      value,
      onChange,
      onBlur,
      intl,
    } = this.props;
    const { formatMessage } = intl;
    const className = classnames({ 'form-control': type !== REACT_SELECT, 'required-field': isRequired });
    const defaultHash = { disabled, name, className, placeholder, defaultValue, value, onChange, onBlur };

    switch (type) {
      case TEXTAREA: {
        return (
          <textarea
            {...defaultHash}
            rows={rows}
            onKeyUp={this.handleOnKeyUp}
          />
        );
      }

      case SELECT_CITY: {
        return (
          <select
            {...defaultHash}
            name="inquiry[city]"
            onChange={this.handleOnKeyUp}
          >
            <option value="" />

            {
              map(options, city => {
                const optionValue = formatMessage(defaultMessages[`countries${city}`]);
                return <option key={`city-${city}`} value={optionValue}>{optionValue}</option>;
              })
            }
          </select>
        );
      }

      case SELECT_DATE_MONTH: {
        return (
          <div className="form-group row">
            <div className="col-sm-6">
              <select
                name={`${name}[month]`}
                className={`${className} c-select`}
                style={{ width: '100%' }}
                onChange={this.handleOnKeyUp}
              >
                {generateMonths()}
              </select>
            </div>

            <div className="col-sm-6">
              <select
                name={`${name}[day]`}
                className={`${className} c-select`}
                style={{ width: '100%' }}
                onChange={this.handleOnKeyUp}
              >
                {generateDays()}
              </select>
            </div>
          </div>
        );
      }

      case SELECT_DATE_MONTH_DATEPICKER: {
        return (
          <input
            ref={node => this.inputDatePicker = node}
            {...defaultHash}
            type="text"
          />
        );
      }

      case SELECT: {
        return (
          <select
            name={name}
            className="c-select form-control"
            style={{ width: '100%' }}
            onChange={onChange}
            {...defaultHash}
          >
            {options}
          </select>
        );
      }

      case REACT_SELECT: {
        return (
          <Select
            name={name}
            onChange={onChange}
            options={options}
            isDisabled={disabled}
            {...defaultHash}
          />
        );
      }

      default: {
        return (
          <input
            {...defaultHash}
            type={type}
            onKeyUp={this.handleOnKeyUp}
          />
        );
      }
    }
  }

  render() {
    const { isRequired, label, fieldClassName, labelClassName, inputClassName, intl } = this.props;
    const { formatMessage } = intl;
    const finalFieldClassName = fieldClassName || 'form-group row';
    const finalLabelClassName = labelClassName || 'col-sm-4 form-control-label';
    const finalInputClassName = inputClassName || 'col-sm-8';

    return (
      <div className={finalFieldClassName}>
        <label className={finalLabelClassName}>
          {formatMessage(defaultMessages[`${label}`])}

          {
            (isRequired) &&
            <span className="label-required"> * </span>
          }
        </label>

        <div className={finalInputClassName}>
          {this.renderField()}
        </div>
      </div>
    );
  }
}

export default injectIntl(FieldComponent);
