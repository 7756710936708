/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import MenuItemContainer from '../menuItemContainer';
import PhotoUploadContainer from '../PhotosFeedback/photoUploadContainer';

export function ConfirmReportStep({
  deliveryReports,
  setDeliveryReports,
  deliveryPhotos,
  setDeliveryPhotos,
  order,
}) {
  const missingReports = _filter(deliveryReports, dr => dr.deliveredQuantity < dr.orderVariationQuantity || !dr.checked);
  return (
    <div>
      {
        !_isEmpty(missingReports) &&
        <div className="pt-3">
          <p className="text-dark mb-3">
            Based on what you have entered, these items below are missing from delivery:
          </p>
          {_map(missingReports, dr => (
            <div key={dr.uuid} className="my-1">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <MenuItemContainer {...dr} />
                </div>
                <div>
                  <span className="text-danger">{dr.orderVariationQuantity === dr.deliveredQuantity ? dr.orderVariationQuantity : dr.orderVariationQuantity - dr.deliveredQuantity}</span>
                </div>
              </div>
              <div>
                <textarea
                  className="form-control"
                  rows="3"
                  value={dr.description}
                  placeholder="Leave reason or more details here (optional)"
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    dr.description = event.target.value;
                    setDeliveryReports([...deliveryReports]);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      }
      <hr className="mt-3" />
      <PhotoUploadContainer
        header="Please attach photos of delivery:"
        setPhotos={setDeliveryPhotos}
        photos={deliveryPhotos}
        order={order}
      />
      <hr className="mt-3" />
      <div>
        <p className="text-danger">
          <b>Note</b>: All submissions are final and cannot be edited.
          Please ensure all details are correct before submitting
        </p>
      </div>
    </div>
  );
}

ConfirmReportStep.propTypes = {
  deliveryReports: PropTypes.array.isRequired,
  setDeliveryReports: PropTypes.func.isRequired,
  deliveryPhotos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    preview: PropTypes.string,
    hash: PropTypes.string.isRequired,
    caption: PropTypes.string,
    orderId: PropTypes.string.isRequired,
  })).isRequired,
  setDeliveryPhotos: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};
