/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { any, bool, object, string } from 'prop-types';
import ChevronLeft from 'libs/components/Icons/ChevronLeft';
import ChevronRight from 'libs/components/Icons/ChevronRight';
import Spinner from 'libs/components/Icons/Spinner';
import {
  CalendarNavigator,
  CalendarButtonGroup,
  MobileLeftSeparator,
  MobileRightSeparator,
} from './styles';

const THRESHOLD = 10; // Max dates per page

const FormattedDate = forwardRef(({ properties, klass, mealId }, ref) => {
  const isActive = properties.uuid === mealId;
  const url = `/meal_plans/${properties.mealPlanId}/meals/${properties.uuid}`;

  const handleClick = (e) => {
    e.preventDefault();

    const isEditing = window.localStorage.getItem(`editing-${mealId}`);
    if (isEditing === 'true') {
      e.preventDefault();
      document.dispatchEvent(new CustomEvent('before-visit', { detail: { url } }));
      return false;
    }

    return (window.Turbolinks)
      ? window.Turbolinks.visit(url)
      : window.location.replace(url);
  };

  return (
    <li
      className={
        classNames(klass, {
          'tw-bg-broccoli-50 tw-border-broccoli-600 tw-border-2 tw-font-bold': isActive,
          'hover:!tw-bg-broccoli-50 hover:!tw-border-broccoli-400 hover:!tw-border-2': isActive,
          'hover:!tw-border-none': !isActive,
          active: isActive,
        })
      }
      ref={isActive ? ref : null}
    >
      <a
        // eslint-disable-next-line no-script-url
        href="#"
        data-target={url}
        data-type={properties.type}
        data-turbolinks
        onClick={handleClick}
        className={
          classNames('tw-text-center tw-text-gray-600 hover:tw-no-underline tw-cursor-pointer', {
            '!tw-text-broccoli-600': isActive,
            'hover:!tw-text-gray-900': properties.type !== 'past',
            '!tw-text-gray-400 hover:!tw-text-gray-400': properties.type === 'past',
            active: isActive,
          })
        }
      >
        {dayjs(properties.scheduleAt).format('ddd, D MMM')}
      </a>
    </li>
  );
});

FormattedDate.propTypes = {
  klass: string,
  mealId: string,
  properties: object,
};

const CalendarDates = ({
  dates,
  mealId,
  mealAdmin,
  isLoading,
}) => {
  const scrollRef = useRef();
  const listRef = useRef();
  const mobileOrientation = window.matchMedia('(orientation: landscape)');
  const mobilePointer = window.matchMedia('(pointer:coarse)');
  const [availableDates, setAvailableDates] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [disablePreviousAction, setPreviousAction] = useState(true);
  const [disableNextAction, setNextAction] = useState(true);
  const [isMobile] = useState(window.outerWidth <= 480);
  const [isMobileLandscape] = useState(mobileOrientation.matches && mobilePointer.matches);

  const handleScroll = (direction) => {
    const scrollAmount = (isMobile) ? 120 : 300;
    const offset = (direction === 'next') ? scrollAmount : -scrollAmount;
    const newScrollPosition = scrollPosition + offset;

    setScrollPosition(newScrollPosition);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!listRef.current || !scrollRef.current) return;
      let current;
      if (dates.length > THRESHOLD) {
        current = listRef.current.getBoundingClientRect();
        const offset = current.x >= (scrollRef.current.clientWidth);
        setScrollPosition(offset ? current.x - 80 : 0);
      } else {
        setScrollPosition(0);
      }
    }, 200);
  }, [scrollRef, listRef, isLoading]);

  useEffect(() => {
    setPreviousAction(false);
    setNextAction(false);

    if (scrollPosition <= 0) {
      setPreviousAction(true);
    }
    if (scrollPosition >= (scrollRef.current.scrollWidth / 2)) {
      setNextAction(true);
    }

    scrollRef.current.scrollLeft = scrollPosition;
  }, [scrollPosition]);

  useEffect(() => {
    if (!dates) return;
    let _dates = [];

    dates.forEach((date) => {
      if (mealAdmin) {
        _dates.push(date);
      } else if ((date.isGroupOrder && !date.isPreview) || date.isConfirmed) {
        _dates.push(date);
      }
    });

    _dates = Immutable.fromJS(_dates)
      .toSet()
      .toList()
      .sortBy((date) => date.get('scheduleAt'));

    setAvailableDates(_dates.toJS());
  }, [dates]);

  useEffect(() => {
    setTimeout(() => {
      if (!listRef.current || !scrollRef.current) return;
      if (isMobile || isMobileLandscape) {
        const current = listRef.current.getBoundingClientRect();
        const offset = current.x >= (scrollRef.current.clientWidth - isMobileLandscape ? 80 : 40);
        setScrollPosition(offset ? current.x - 60 : 0);
      }
    }, 200);
  }, [isMobile, isMobileLandscape, listRef, scrollRef]);

  return (
    <Fragment>
      <section
        id="calendar-navigator"
        data-testid="calendar-navigator"
        className="tw-relative tw-flex tw-gap-0 tw-my-2 tw-py-2 md:tw-mt-4 md:tw-mb-4"
      >
        <MobileLeftSeparator className="md:tw-hidden tw-pr-4" />
        { isLoading ? (
          <div className="tw-flex tw-align-center tw-justify-center tw-items-center tw-w-full tw-transform tw-scale-[70%]">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            <ul
              className="tw-flex tw-grow tw-overflow-hidden tw-overflow-x-auto tw-snap-x tw-scroll-smooth tw-scrollable tw-m-0 tw-p-0 tw-pr-20"
              ref={scrollRef}
            >
              {availableDates && availableDates.map((prop, index) => {
                const klass = 'tw-min-w-[120px] tw-max-w-[120px] tw-text-sm tw-py-1.5 tw-px-2.5 tw-flex ' +
                              'tw-items-center tw-justify-center tw-rounded-full hover:!tw-bg-gray-50';
                return (
                  <FormattedDate
                    key={index}
                    properties={prop}
                    ref={listRef}
                    mealId={mealId}
                    klass={klass}
                  />
                );
              })}
            </ul>
            <CalendarButtonGroup
              data-testid="date-navigator-scroll-buttons"
              className={classNames('tw-hidden md:tw-flex tw-items-center tw-gap-1 tw-pl-10', {
                'tw-absolute tw-right-0 tw-h-9': true,
                '!tw-hidden': availableDates && availableDates.length < THRESHOLD,
              })}
            >
              <CalendarNavigator
                klass="tw-align-left"
                data-testid="date-navigator-button-previous"
                onClick={() => handleScroll('previous')}
                disabled={disablePreviousAction}
              >
                <ChevronLeft size={16} />
              </CalendarNavigator>
              <CalendarNavigator
                klass="tw-align-right"
                data-testid="date-navigator-button-next"
                onClick={() => handleScroll('next')}
                disabled={disableNextAction}
              >
                <ChevronRight size={16} />
              </CalendarNavigator>
            </CalendarButtonGroup>
          </Fragment>
        )}
        <MobileRightSeparator className="xl:tw-hidden tw-pl-4" />
      </section>
    </Fragment>
  );
};

CalendarDates.propTypes = {
  dates: any,
  mealId: string,
  mealAdmin: bool,
  isLoading: any,
};

export default CalendarDates;
