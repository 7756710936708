import React from 'react';

const Vegetarian = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#9FC925"
    />
    <path
      d="M12.7129 16L15.8569 7.6H14.3929L11.9929 14.428L9.60492 7.6H8.14092L11.2729 16H12.7129Z"
      fill="white"
    />
  </svg>
);

export default Vegetarian;
