/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withI18n } from 'libs/support/i18n';

import * as fromCartVariations from 'packs/common/reducers/cartVariationsReducer';
import MenuItem from './MenuItem/MenuItem';
import CustomMenuItem from './CustomMenuItem/CustomMenuItem';

/**
 * MenuItemList
 */

const MenuItemList = ({
  cartVariationsStore,
  cartsStore,
  customMenuItemsStore,
  mealStore,
  menuItemsStore,
  vendorsStore,
  onSaveCustomMenuItem,
  onUpdateSelection,
  onEditSelection,
  onAddSelection,
}) => {
  const mealConfirmed = mealStore.get('confirmed');
  const mealCancelled = mealStore.get('cancelled');
  const mealProcessing = mealStore.get('processing');
  const mealOpen = mealStore.get('open');
  const mealDue = mealStore.get('due');
  const mealExpired = mealStore.get('expired');
  const isUnprocessable = (mealExpired || mealConfirmed || mealCancelled);
  const isEditable = isUnprocessable ? false : mealDue ? false : (mealOpen || mealProcessing || !mealDue);
  const mealAdmin = mealStore.get('mealAdmin');
  const mealPlanId = mealStore.get('mealPlanId');
  const cartVariationsByCartId = fromCartVariations.groupByCardId(cartVariationsStore.get('cartVariations'));

  return (
    <div className="cart-order-list">
      {
        cartsStore.get('allIds').map((cartId, i) => {
          // Hide if the meal is confirmed and there is no cart variatiopns for the given vendor
          const vendorId = cartsStore.getIn(['byId', cartId, 'vendorId']);
          const vendor = vendorsStore.getIn(['byId', vendorId]);
          const cartVariations = cartVariationsByCartId.get(cartId);
          const customMenuItems = customMenuItemsStore.getIn(['byVendorId', vendorId]);
          const minOrder = vendor.get('minimumOrderValue');

          return (
            <div id={vendorId} key={`vendorMenuItem-${i}`}>
              <div
                className={classNames('tw-flex', {
                'tw-bg-gray-50 tw-py-3 tw-px-6 tw-mb-2': true,
                'tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-between md:tw-items-center': true,
              })}
              >
                <h6 className="tw-text-base tw-font-normal tw-m-0">
                  { vendor.get('name') }
                </h6>
                {
                  mealAdmin && minOrder &&
                    <span className="text-gray-400">
                      {`Minimum order value: $${minOrder}`}
                    </span>
                }
              </div>

              {/* Menu Items */}
              {
                cartVariations && (() => {
                  const groupedByMenuItem = cartVariations.reduce((acc, cv) => {
                    const menuItemId = cv.get('menu_item_id');
                    if (!acc.has(menuItemId)) {
                      acc.set(menuItemId, []);
                    }
                    acc.get(menuItemId).push(cv);
                    return acc;
                  }, new Map());

                  return Array.from(groupedByMenuItem.entries()).map(([menuItemId, variations], i, array) => {
                    const menuItem = menuItemsStore.getIn(['byId', menuItemId]);
                    const isLastGroup = i === array.length - 1;

                    if (!menuItem) { return null; }
                    if (!mealAdmin && !mealConfirmed) { return null; }

                    return (
                      <div
                        key={`menuitem-group-${menuItemId}`}
                        className={classNames('tw-mx-3', {
                          'tw-border-b tw-border-gray-200': !isLastGroup,
                        })}
                      >
                        {variations.map((variation, j) => (
                          <div
                            className="tw-px-3 tw-my-3"
                            key={`menuitem-${menuItemId}-${j}`}
                          >
                            <MenuItem
                              cartVariation={variation}
                              menuItem={menuItem}
                              mealAdmin={mealAdmin}
                              mealConfirmed={mealConfirmed}
                              mealPlanId={mealPlanId}
                              mealEditable={isEditable}
                              onEditSelection={(e) => onEditSelection(e, menuItem, variation)}
                              onAddAnotherSelection={(e) => onAddSelection(e, menuItem)}
                              updateQuantity={onUpdateSelection}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  });
                })()
              }

              {/* Custom Menu Items */}
              {
                customMenuItems && customMenuItems.map((customMenuItem, i, array) => {
                  if (!mealAdmin && !mealConfirmed) { return null; }
                  const isLastItem = i === array.length - 1;

                  return (
                    <div
                      className={classNames('tw-px-6 tw-mb-4', {
                        'tw-border-b tw-border-gray-200': !isLastItem,
                      })}
                      key={`menuitem-${i}`}
                    >
                      <CustomMenuItem
                        key={`custommenuitem-${i}`}
                        customMenuItem={customMenuItem}
                        mealAdmin={mealAdmin}
                        mealConfirmed={mealConfirmed}
                        mealPlanId={mealPlanId}
                        updateQuantity={onSaveCustomMenuItem}
                      />
                    </div>
                  );
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

MenuItemList.propTypes = {
  mealStore: PropTypes.object.isRequired,
  cartsStore: PropTypes.object.isRequired,
  cartVariationsStore: PropTypes.object.isRequired,
  menuItemsStore: PropTypes.object.isRequired,
  customMenuItemsStore: PropTypes.object.isRequired,
  vendorsStore: PropTypes.object.isRequired,
  onSaveCustomMenuItem: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  onEditSelection: PropTypes.func,
  onAddSelection: PropTypes.func,
};


export default withI18n(MenuItemList);
