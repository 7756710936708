/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint camelcase: 0 */

import humps from 'lodash-humps';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import thunkMiddleware from 'redux-thunk';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';
import merge from 'lodash/merge';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    pantry_program,
  } = props;

  const { $$pantryProgramState } = initialStates;

  const initialState = {
    $$pantryProgramStore: merge($$pantryProgramState, {
      pantryProgram: humps(pantry_program),
    }),
  };

  const composeEnhancers = (typeof window !== 'undefined') ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
  const reducer = combineReducers(reducers);
  const composedStore = composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware));
  return composedStore(createStore)(reducer, initialState);
};
