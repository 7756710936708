/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';


/*
 * Returns minQuantity or the quantity itself
 *
 * Ex.
 * setQuantity(2, 3) => 3
 * setQuantity(0, 3) => 0
 * setQuantity(4, 3) => 4
 */
function setQuantity(quantity, minQuantity) {
  const newQuantity = quantity || 0;
  if (newQuantity !== 0 && newQuantity < minQuantity) { return minQuantity; }
  return newQuantity;
}

/*
 * Returns 0 or quantity - 1
 *
 * Ex.
 * decreaseQuantity(2, 3) => 0
 * decreaseQuantity(0, 3) => 0
 * decreaseQuantity(4, 2) => 3
 */
function decreaseQuantity(quantity, minQuantity) {
  const newQuantity = quantity - 1;
  if (newQuantity < minQuantity) { return 0; }
  return newQuantity;
}

/*
 * Returns minQuantity or quantity + 1
 *
 * Ex.
 * increaseQuantity(0, 3) => 3
 * increaseQuantity(3, 1) => 4
 */
function increaseQuantity(quantity, minQuantity) {
  return setQuantity(quantity + 1, minQuantity);
}

/**
 * MenuItemInfo
 */

const MenuItemInfo = ({
  quantity,
  minQuantity,
  subtotal,
  mealAdmin,
  mealConfirmed,
  updateQuantityHandler,
}) => {
  if (mealAdmin && !mealConfirmed) {
    return (
      <div className="order-actions d-flex justify-content-end align-items-center">
        <div className="custom-number">
          <span
            className="input-number-decrement"
            onClick={(e) => updateQuantityHandler(e, decreaseQuantity(quantity, minQuantity))}
          >
            <svg viewBox="0 0 8 2" width="8" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M4.99919 0H3.00081H0L0 2H3.00081H4.99919H8V0H4.99919Z"
                fillRule="evenodd"
              />
            </svg>
          </span>

          <input
            className="input-number"
            value={quantity}
            onChange={(e) => updateQuantityHandler(e, setQuantity(e.target.value, minQuantity))}
          />

          <span
            className="input-number-increment"
            onClick={(e) => updateQuantityHandler(e, increaseQuantity(quantity, minQuantity))}
          >
            <svg viewBox="0 0 8 8" width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M4.99919 3V0H3.00081V3H0L0 5H3.00081V8H4.99919V5H8V3H4.99919Z"
                fillRule="evenodd"
              />
            </svg>
          </span>
        </div>

        <div className="ml-3">
          {/* Subtotal */}
          { subtotal }
        </div>
      </div>
    );
  }
  if (!mealAdmin) return null;

  return (
    <div className="order-actions d-flex justify-content-end align-items-center">
      <div className="ml-3">
        Quantity: {quantity}
      </div>
      {
          mealAdmin && (
            <div className="ml-3">
              {/* Subtotal */}
              { subtotal }
            </div>
          )
        }
    </div>
  );
};

MenuItemInfo.propTypes = {
  quantity: PropTypes.number.isRequired,
  minQuantity: PropTypes.number.isRequired,
  subtotal: PropTypes.string.isRequired,
  mealAdmin: PropTypes.bool.isRequired,
  mealConfirmed: PropTypes.bool.isRequired,
  updateQuantityHandler: PropTypes.func.isRequired,
};

export default injectIntl(MenuItemInfo);
