import React from 'react';
import {
  bool,
  number,
  string,
  func,
} from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { TwoPeopleIcon } from 'libs/components/Icons';
import MealStatusContainer from 'packs/meals/containers/MealStatusContainer';

const CardHeadline = ({
  allowSelfCheckout,
  budget,
  selected,
  total,
  mealPlanId,
  uuid,
  onFetchMealStatus,
  translate,
}) => {
  const handleOnClick = e => {
    e.preventDefault();

    onFetchMealStatus(mealPlanId, uuid);
  };

  return (
    <div id="card-headline" className="tw-rounded tw-border tw-flex tw-gap-1 tw-px-3 tw-py-2">
      <span className="tw-block">
        <TwoPeopleIcon />
      </span>

      <div className="tw-flex tw-gap-1">
        <span>
          {translate('mealPlanUserMealPlansGroupOrderCardMealGroupOrder')}
        </span>

        {
          !allowSelfCheckout &&
          <span>
            ({translate('mealPlanUserMealPlansGroupOrderCardMealBudget', { budget })})
          </span>
        }
      </div>

      <span className="tw-mx-0 md:tw-mx-1">•</span>

      <span>
        <a
          href="#"
          data-turbolinks="false"
          onClick={handleOnClick}
        >
          {translate('mealPlanUserMealPlansGroupOrderCardMealOrdered', { selected, total })}
        </a>
      </span>

      <MealStatusContainer />
    </div>
  );
};

CardHeadline.propTypes = {
  allowSelfCheckout: bool,
  budget: number,
  selected: number,
  total: number,
  mealPlanId: string.isRequired,
  uuid: string.isRequired,
  onFetchMealStatus: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(CardHeadline);
