/* eslint jsx-a11y/label-has-for: 0 */

import React from 'react';
import { bool, func, string } from 'prop-types';
import bindAll from 'lodash/bindAll';

import { notBlank } from 'libs/support/string';
import BaseComponent from 'libs/components/BaseComponent';

export default class MenuItemFormCartVariationVariationInput extends BaseComponent {
  static propTypes = {
    disabled: bool,
    isChecked: bool,
    id: string.isRequired,
    name: string.isRequired,
    price: string,
    servingsDescription: string,
    showPrice: bool.isRequired,
    onSelectVariation: func.isRequired,
  };

  constructor() {
    super();

    bindAll(this, 'handleOnClick');
  }

  handleOnClick() {
    const { id, onSelectVariation } = this.props;
    onSelectVariation(id);
  }

  render() {
    const {
      disabled,
      id,
      isChecked,
      name,
      price,
      servingsDescription,
      showPrice,
    } = this.props;

    const intDisabled = notBlank(disabled) ? disabled : false;

    return (
      <div className="radio">
        <label>
          <input
            type="radio"
            name="cart_variation[variation_id]"
            value={id}
            defaultChecked={isChecked}
            onClick={this.handleOnClick}
            disabled={intDisabled}
          />

          <span>
            {name}

            &nbsp;

            {showPrice && price}

            <span className="text-variation">
              {servingsDescription}
            </span>
          </span>
        </label>
      </div>
    );
  }
}
