import React from 'react';
import { bool, func, object, string } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { withI18n } from 'libs/support/i18n';
import { trackDownloadedReport } from '../events';

export const OrdersExportModal = withI18n(({ show, onHide, message, downloadLink, translate, startDate, endDate }) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    dialogClassName="orders-export-modal"
    data-testid="orders-export-modal"
  >
    <div className="modal-content">
      <div className="modal-header">
        <h6 className="modal-title">
          {translate('financialReportsOrdersExportTitle')}
        </h6>
      </div>

      <div className="modal-body">{message}</div>

      {downloadLink && (
      <div className="modal-footer">
        <a
          href={downloadLink}
          download="orders"
          className="btn btn-primary"
          onClick={() => {
                  onHide();
                  trackDownloadedReport(downloadLink, startDate, endDate);
                }}
        >
          {translate('financialReportsOrdersExportDownload')}
        </a>
      </div>
          )}
    </div>
  </Modal>
));

OrdersExportModal.propTypes = {
  show: bool.isRequired,
  onHide: func.isRequired,
  message: string,
  downloadLink: string,
  startDate: object.isRequired,
  endDate: object.isRequired,
};
