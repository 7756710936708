import Immutable from 'immutable';

import {
  CHECK_OUT_USER_MEAL_CART_SUCCESS,
  RESET_USER_MEAL_CART_SUCCESS,
  CONFIRM_USER_MEAL_CART_SUCCESS,
  SKIP_USER_MEAL_CART_SUCCESS,
  UNSKIP_USER_MEAL_CART_SUCCESS,
  EDIT_USER_MEAL_CART_SUCCESS,
} from 'packs/meals/constants/userMealCartConstants';

export const $$initialState = Immutable.fromJS({
  version: 0,
  adminMealCart: {},
});

export default function adminMealCartReducer($$state = $$initialState, action = null) {
  const { type } = action;

  switch (type) {
    case EDIT_USER_MEAL_CART_SUCCESS:
    case RESET_USER_MEAL_CART_SUCCESS:
    case CONFIRM_USER_MEAL_CART_SUCCESS:
    case SKIP_USER_MEAL_CART_SUCCESS:
    case UNSKIP_USER_MEAL_CART_SUCCESS:
    case CHECK_OUT_USER_MEAL_CART_SUCCESS: {
      const version = $$state.get('version');
      return $$state.merge({ version: version + 1 });
    }
    default: {
      return $$state;
    }
  }
}
