/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import moment from 'moment';
import { func, object, string, bool } from 'prop-types';
import Alert from 'react-bootstrap/Alert';

import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { NomealIcon } from 'libs/components/Icons';
import { notBlank } from 'libs/support/string';
import MenuItem from '../../shared/MenuItem';
import CartCategoryItems from '../../shared/CartCategoryItems';

const CartOrders = ({
  meal,
  userMealOrderable,
  isLoading,
  translate,
}) => {
  if (isLoading) return <CartCategoryItems isLoading={isLoading} />;

  const orderables = !isLoading && meal.get('orderables');

  if (userMealOrderable.get('isSkipped')) {
    const scheduleAt = meal.get('scheduleAt');
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <div className="tw-py-4">
          <NomealIcon />
        </div>
        {translate('groupOrderCardsChooseToSkip')}
        <strong>
          {`${moment(scheduleAt).format('dddd D')} ${moment(scheduleAt).format('MMM').toUpperCase()}`}
        </strong>
      </div>
    );
  } else if (userMealOrderable.get('isSelected')) {
    if (meal.get('isConfirmed')) {
      return orderables && orderables.map(order => (
        order
          .get('orderVariations')
          .filter(ov => notBlank(ov.get('menuItem')))
          .sort(cv => (cv.get('orderableUserIsGuest') ? 1 : -1))
          .map(orderVariation => (
            <MenuItem
              key={`item-${orderVariation.get('uuid')}`}
              menuItem={orderVariation.get('menuItem')}
              vendorName={order.get('vendorName')}
              isGuest={orderVariation.get('orderableUserIsGuest')}
            />
          ))
      ));
    }
    return orderables && orderables.map(cart => (
      cart
        .get('cartVariations')
        .filter(ov => notBlank(ov.get('menuItem')))
        .sort(cv => (cv.get('orderableUserIsGuest') ? 1 : -1))
        .map(cartVariation => (
          <MenuItem
            key={`item-${cartVariation.get('uuid')}`}
            menuItem={cartVariation.get('menuItem')}
            vendorName={cart.get('vendorName')}
            isGuest={cartVariation.get('orderableUserIsGuest')}
          />
        ))
    ));
  }
  if (meal.get('isConfirmed')) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <div className="tw-py-4">
          <NomealIcon />
        </div>
        <div className="text-danger">
          {translate('groupOrderCardsDidNotOrderMeal')}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <CartCategoryItems
        categories={meal.get('vendorCategories')}
        mealId={meal.get('uuid')}
        mealPlanId={meal.get('mealPlanId')}
      />
      <CartCategoryItems
        categories={meal.get('vendorMenuItems')}
        mealId={meal.get('uuid')}
        mealPlanId={meal.get('mealPlanId')}
      />
    </Fragment>
  );
};

CartOrders.propTypes = {
  meal: object.isRequired,
  userMealOrderable: object.isRequired,
  isLoading: bool,
  translate: func,
};

const CardBody = ({
  meal,
  userMealOrderable,
  errorMsg,
  successMsg,
  isLoading,
  translate,
}) => (
  <div className="card-body tw-bg-white !tw-p-4 !tw-pb-16 !tw-min-h-[330px] !tw-max-h-[330px] tw-scrollable">
    {
      errorMsg &&
        <Alert variant="danger" className="text-center">
          { errorMsg }
        </Alert>
    }

    {
      successMsg &&
        <Alert variant="success" className="text-center">
          <div dangerouslySetInnerHTML={{ __html: successMsg }} />
        </Alert>
    }

    <CartOrders
      meal={meal}
      userMealOrderable={userMealOrderable}
      translate={translate}
      isLoading={isLoading}
    />
  </div>
);

CardBody.propTypes = {
  meal: object.isRequired,
  userMealOrderable: object.isRequired,
  successMsg: string,
  errorMsg: string,
  isLoading: bool,
  translate: func,
};

export default withAppContext(withI18n(CardBody));
