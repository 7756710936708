/* eslint-disable max-len */
import { useQuery } from 'react-query';
import { httpClient } from 'libs/support/httpClient';
import humps from 'lodash-humps';

export const useMealDates = (mealPlanId, mealId) => useQuery(['MEAL_DATES', mealPlanId, mealId], async () => {
  const { data } = await httpClient.get(`/meal_plans/${mealPlanId}/meals/${mealId}/meal_dates.json`);

  return humps(data);
}, {
  keepPreviousData: true,
  staleTime: Infinity,
});
