import React from 'react';
import { bool, func, object } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { CreditCardIcon } from 'libs/components/Icons';

/**
 * CreditCardOption
 */

const CreditCardOption = ({
  card,
  isDefault,
  onChange,
  translate,
}) => (
  <div className="cc-option">
    <div className="cc-option__input">
      <input
        type="radio"
        value={card.get('uuid')}
        name="user_credit_card_id"
        defaultChecked={isDefault}
        id={`cc-option-${card.get('uuid')}`}
        onClick={() => onChange(card)}
      />
    </div>
    <label
      className="cc-option__label"
      htmlFor={`cc-option-${card.get('uuid')}`}
    >
      <div className="cc-option__icon">
        <CreditCardIcon
          type={card.get('brand')}
        />
      </div>
      <div>
        <div className="cc-option__name">
          {card.get('name')}
        </div>
        <div className="cc-option__details">
          {
              `
                  ${translate('creditCardsTermsEnding')}
                  ${card.get('last_four')}
                  |
                  ${translate('creditCardsTermsExp')}
                  ${card.get('exp_month')}/${card.get('exp_year')}
              `
            }
        </div>
      </div>
    </label>
  </div>
);

CreditCardOption.propTypes = {
  card: object.isRequired,
  isDefault: bool.isRequired,
  onChange: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(CreditCardOption);
