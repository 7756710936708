import React from 'react';
import styled from 'styled-components';
import { useMemo } from 'react';
import { withI18n } from 'libs/support/i18n';
import { array } from 'prop-types';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, CardTitle, Group, Stack, TableWrapper } from '../../../styles';
import {
  formatDate,
  formatMoney,
} from '../../utils';
import { Money } from '../../Money';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
);

const ProgramColorLabel = styled.div`
  height: 20px;
  width: 0.5rem;
  background: ${(props) => props.color};
  border-radius: 12px;
`;

export const DailyCostTrend = withI18n(({ pantryPrograms, translate }) => {
  const averageCost = useMemo(() => {
    const total = pantryPrograms
      .find(({ id }) => id === 'total');

    const totalAverageCost = total.data
      .reduce((acc, curr) => {
        // eslint-disable-next-line no-param-reassign
        acc += curr.averageCost;
        return acc;
      }, 0);

    return totalAverageCost / total.data.length;
  }, [pantryPrograms]);

  return (
    <Stack data-testid="reporting-pantry-programs-daily-cost-trend">
      <Card>
        <CardTitle>
          {translate('financialReportsDailyCostAverageCostPerDayPerPax')}
        </CardTitle>
        <Money value={averageCost} fontSize={48} />
        <Bar
          height={80}
          options={{
            elements: {
              point: {
                radius: 6,
                hoverRadius: 6,
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                align: 'end',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (value) =>
                    formatMoney(value, { withCents: false, compact: true }),
                  color: '#BFC1C4',
                },
              },
            },
          }}
          data={{
            labels: pantryPrograms[0].data.map(({ step }) =>
              formatDate(step)),
            datasets: pantryPrograms.map((pantryProgram) => ({
              label: pantryProgram.name,
              data: pantryProgram.data.map(({ average }) => average),
              backgroundColor: pantryProgram.color,
              borderColor: pantryProgram.color,
              ...(pantryProgram.id === 'total' && {
                type: 'line',
                order: 0,
              }),
              ...(pantryProgram.id !== 'total' && {
                barThickness: 20,
                borderRadius: 25,
                borderColor: 'rgba(0,0,0,0)',
                borderWidth: 3,
              }),
            })),
          }}
        />
      </Card>
      <Card>
        <TableWrapper fixedFirstCellWidth={180}>
          <table className="table">
            <thead>
              <tr>
                <th />
                {pantryPrograms[0].data.map(({ step }) => (
                  <th key={step}>{formatDate(step)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pantryPrograms
                .filter(({ id }) => id !== 'total')
                .map((pantryProgram) => (
                  <tr key={pantryProgram.id}>
                    <td className="font-weight-bold">
                      <Group>
                        <ProgramColorLabel color={pantryProgram.color} />
                        <div>{pantryProgram.name}</div>
                      </Group>
                    </td>
                    {pantryProgram.data.map(({ step, average }) => (
                      <td key={step}>{formatMoney(average)}</td>
                    ))}
                  </tr>
                ))}
              <tr>
                <td className="font-weight-bold">{translate('financialReportsTermsTotal')}</td>
                {pantryPrograms
                  .find(({ id }) => id === 'total')
                  .data.map(({ step, average }) => (
                    <td key={step} className="font-weight-bold">
                      {formatMoney(average)}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </Card>
    </Stack>
  );
});

DailyCostTrend.propTypes = {
  pantryPrograms: array.isRequired,
};
