/* eslint-disable no-restricted-globals */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withI18n } from 'libs/support/i18n';
import BaristaCafeIllustration from 'libs/components/Illustrations/BaristaCafe';
import { RefreshIcon } from 'libs/components/Icons';

/* Actions */
import actions from 'packs/meals/actions';

/**
 * CafeOrderingConfirmedHeader
 */
const CafeOrderingConfirmedHeader = ({
  createUserMealCart,
  mealId,
  mealPlanId,
  isMealConfirmed,
  translate,
}) => {
  const handleOnMakeAnotherOrder = () => {
    createUserMealCart(mealPlanId, { meal_id: mealId });
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card card-panel meal-banner meal-banner--warning">
          <div className="card-body meal-banner__body">
            <div className="meal-banner__image">
              <BaristaCafeIllustration />
            </div>
            <div className="meal-banner__content">
              <h5>
                { translate('groupedOrdersMessagesCafeOrderingConfirmedTitle', { name }) }
              </h5>

              <p className="lead meal-banner__lead mb-3">
                { translate('groupedOrdersMessagesCafeOrderingConfirmedSubtext') }
              </p>

              {
                !isMealConfirmed &&
                  <div className="lead meal-banner__actions">
                    <button
                      className="btn btn-light font-600 meal-banner__button"
                      onClick={handleOnMakeAnotherOrder}
                    >
                      <span className="mr-2"><RefreshIcon /></span>
                      { translate('groupedOrdersActionsMakeAnotherOrder') }
                    </button>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  $$mealStore,
}) => ({
  isMealConfirmed: $$mealStore.get('confirmed'),
  mealPlanId: $$mealStore.get('mealPlanId'),
  mealId: $$mealStore.get('uuid'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createUserMealCart: actions.createUserMealCart,
}, dispatch);

CafeOrderingConfirmedHeader.propTypes = {
  mealId: string,
  mealPlanId: string,
  createUserMealCart: func.isRequired,
  isMealConfirmed: bool.isRequired,
  translate: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(CafeOrderingConfirmedHeader));
