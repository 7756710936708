/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';

import defaultPhoto from 'assets/images/food-placeholder.jpg';

/**
 * MenuItemPhoto
 */

const MenuItemPhoto = ({ photo }) => (
  <div className="card-thumbnail md:tw-m-2 md:tw-mr-3">
    <img src={photo || defaultPhoto} alt="" className="tw-rounded" />
  </div>
);

MenuItemPhoto.propTypes = {
  photo: PropTypes.string,
};

export default MenuItemPhoto;
