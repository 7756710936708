import React from 'react';
import PropTypes from 'prop-types';
import ExclamationMarkCircle from 'libs/components/Icons/ExclamationMarkCircle';

export function ConfirmStockCount({
  logDate,
  isDraft,
}) {
  return (
    <div>
      <div className="pt-3">
        <ExclamationMarkCircle size={48} />
      </div>
      <div className="my-3">
        <h3 className="mt-2">Submit Stock Count {isDraft && '(draft)'} for {logDate}</h3>
        <p className="mt-2">
          {
            !isDraft && (
              <span>Note that once you have submitted your stock count,
                you will not be able to make further changes.
              </span>)
          }
          <span>
            {isDraft ? 'You can continue later.' : 'Are you sure you want to submit it?'}
          </span>
        </p>
      </div>
    </div>
  );
}

ConfirmStockCount.propTypes = {
  logDate: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
};
