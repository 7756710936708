export function initializeFluidTabs() {
  const container = document.querySelector('.nav-tabs');

  if (!container) return;

  const primary = container.querySelector('.nav-tabs__list-primary');
  const primaryItems = container.querySelectorAll('.nav-tabs__list-primary > li:not(.nav-tabs__list-more)');

  container.classList.add('nav-tabs--init');

  if (!primary) return;

  primary.insertAdjacentHTML('beforeend', `
    <li class="nav-tabs__item nav-tabs__list nav-tabs__list-more">
      <span class="nav-tabs__item nav-tabs__item-more" aria-haspopup="true" aria-expanded="false">
        ...
      </span>
      <ul class="nav-tabs__list-secondary">
        ${primary.innerHTML}
      </ul>
    </li>
  `);

  const secondary = container.querySelector('.nav-tabs__list-secondary');
  const secondaryItems = secondary.querySelectorAll('li');
  const allItems = container.querySelectorAll('li');
  const moreLi = primary.querySelector('.nav-tabs__list-more');
  const moreBtn = moreLi.querySelector('.nav-tabs__list-more span');

  moreBtn.addEventListener('click', (e) => {
    e.preventDefault();
    container.classList.toggle('nav-tabs--show-secondary');
    moreBtn.setAttribute('aria-expanded', container.classList.contains('nav-tabs--show-secondary'));
  });

  const configureDropdown = () => {
    let stopWidth = moreBtn.offsetWidth;
    const hiddenItems = [];
    const primaryWidth = primary.offsetWidth;

    primaryItems.forEach((item, i) => {
      if (primaryWidth <= stopWidth + item.offsetWidth) {
        item.classList.add('nav-tabs__item--hidden');
        hiddenItems.push(i);
      }
      stopWidth += item.offsetWidth;
    });

    if (!hiddenItems.length) {
      moreLi.classList.add('nav-tabs__item--hidden');
      container.classList.remove('nav-tabs--show-secondary');
      moreBtn.setAttribute('aria-expanded', false);
    } else {
      secondaryItems.forEach((item, i) => {
        if (!hiddenItems.includes(i)) {
          item.classList.add('nav-tabs__item--hidden');
        }
      });
    }
  };

  const scrollToActiveTab = () => {
    const primaryList = document.querySelector('.nav-tabs__list-primary');
    const activeTab = document.querySelector('.nav-tabs__item--active');

    primaryList.scrollLeft = activeTab.offsetLeft - 50;
  };

  const doAdapt = () => {
    allItems.forEach((item) => {
      item.classList.remove('nav-tabs__item--hidden');
    });
    const screenWidth = window.screen.width;

    if (screenWidth > 498) {
      configureDropdown();
      moreLi.classList.remove('nav-tabs__list-more--hidden');
      container.classList.remove('nav-tabs--scrollable');
    } else {
      container.classList.add('nav-tabs--scrollable');
      moreLi.classList.add('nav-tabs__list-more--hidden');
      scrollToActiveTab();
    }
  };

  doAdapt();
  window.addEventListener('resize', doAdapt);
}
