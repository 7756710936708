import { withI18n } from 'libs/support/i18n';
import { object } from 'prop-types';
import React, { useEffect } from 'react';
import { useReport } from '../../../queries/use-report';
import { Stack } from '../../../styles';
import { SectionDivider } from '../../SectionDivider';
import { OrdersCountTrend } from './OrdersCountTrend';
import { SpendingTrend } from './SpendingTrend';
import { Summary } from './Summary';
import { trackEventsTab } from '../../../events';

export const Events = withI18n(({ organisation, filters, translate }) => {
  const { data: summary } = useReport(
    organisation.uuid,
    'events/event_summary',
    filters,
  );

  const { data: events } = useReport(
    organisation.uuid,
    'events/events',
    filters,
  );

  useEffect(() => trackEventsTab(), []);

  return (
    <Stack className="p-3" data-testid="reporting-events">
      {summary && events && (
        <Summary
          summary={summary}
          data={events.find((event) => event.id === 'total').data}
        />
      )}
      {summary && events && (
        <SpendingTrend summary={summary} events={events} />
      )}
      <SectionDivider text={translate('financialReportsTermsOrders')} />
      {events && <OrdersCountTrend events={events} />}
    </Stack>
  );
});

Events.propTypes = {
  organisation: object.isRequired,
  filters: object.isRequired,
};
