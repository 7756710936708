export const SAVE_CART_LOCAL = 'SAVE_CART_LOCAL';

export const SAVE_CART_SUCCESS = 'SAVE_CART_SUCCESS';
export const SAVE_CART_FAILURE = 'SAVE_CART_FAILURE';
export const SAVING_CART = 'SAVING_CART';

export const SAVING_CART_DELIVERY_DATE = 'SAVING_CART_DELIVERY_DATE';
export const SAVE_CART_DELIVERY_DATE_SUCCESS = 'SAVE_CART_DELIVERY_DATE_SUCCESS';
export const SAVE_CART_DELIVERY_DATE_FAILURE = 'SAVE_CART_DELIVERY_DATE_FAILURE';
export const SELECT_CART_DATE = 'SELECT_CART_DATE';
export const SELECT_CART_EARLIER_RANGE = 'SELECT_CART_EARLIER_RANGE';
export const SELECT_CART_LATER_RANGE = 'SELECT_CART_LATER_RANGE';
export const SELECT_CART_TIME = 'SELECT_CART_TIME';
export const SHOW_DELIVERY_DATE_FORM = 'SHOW_DELIVERY_DATE_FORM';

export const HIDE_LOCATION_FORM = 'HIDE_LOCATION_FORM';
export const SAVE_CART_LOCATION_SUCCESS = 'SAVE_CART_LOCATION_SUCCESS';
export const SAVE_CART_LOCATION_FAILURE = 'SAVE_CART_LOCATION_FAILURE';
export const SAVING_CART_LOCATION = 'SAVING_CART_LOCATION';
export const SHOW_LOCATION_FORM = 'SHOW_LOCATION_FORM';

export const SAVE_CART_VOUCHER_SUCCESS = 'SAVE_CART_VOUCHER_SUCCESS';
export const SAVE_CART_VOUCHER_FAILURE = 'SAVE_CART_VOUCHER_FAILURE';
export const SAVING_CART_VOUCHER = 'SAVING_CART_VOUCHER';
