/* eslint-disable no-underscore-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-alert */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { Fragment, useLayoutEffect, useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { statuses } from 'packs/meals/reducers/userMealCartReducer';
import { useLocalStorage } from 'packs/common/hooks/useLocalStorage';
import { MEAL_STATE } from 'packs/meals/constants/mealConstants';
import MealStatusContainer from 'packs/meals/containers/MealStatusContainer';
import MenuItemsContainer from 'packs/meals/containers/GroupOrderMeal/MenuItemsContainer';
import SelectedMenuItemsContainer from 'packs/meals/containers/GroupOrderMeal/SelectedMenuItemsContainer';
import MenuItemForm from 'packs/common/components/MenuItem/MenuItemForm/MenuItemForm';
import AlertBox from 'libs/components/AlertBox';

import EditingIndicator from '../EditingIndicator';
import Header from '../Header/Header';
import Flash from '../Flash';
import ResponsiveMealCart from '../ResponsiveMealCart';
import MealConfirmationGuidedInfo from '../MealConfirmationGuidedInfo';
import VendorsDock from '../../Shared/VendorsDock';
import MenuItemsSkeletal from '../MenuItems/MenuItemSkeletal';

/**
 * GroupOrderMeal
 */
const GroupOrderMeal = ({
  actions,
  cartVariationsStore,
  cartsStore,
  mealStore,
  menuItemsStore,
  userMealOrderable,
  vendorsStore,
  userRoleIn,
  translate,
}) => {
  /* TODO: Update this */
  const isNonZeroVat = false;

  const isMealConfirmed = mealStore.get('confirmed');
  const isMealPreview = mealStore.get('preview');
  const isMealProcessing = mealStore.get('processing');
  const mealId = mealStore.get('uuid');

  /* For MenuItemForm */
  const isGroupOrder = true;
  const cartVariations = cartVariationsStore.get('cartVariations');
  const selectedMenuItem = cartVariationsStore.get('selectedMenuItem');
  const selectedCartVariation = cartVariationsStore.get('selectedCartVariation');
  const catererId = selectedMenuItem.getIn(['vendor_id']);
  const cartId = cartsStore.getIn(['byVendorId', catererId, 'cartId']);
  const currentState = userMealOrderable.get('currentState');
  const isUserMealOrderableConfirmed = currentState === MEAL_STATE.confirmed;
  const isEditable = !userRoleIn(['osc']) && !isMealConfirmed && !isMealPreview && !isMealProcessing && !isUserMealOrderableConfirmed;
  const mealAdmin = userRoleIn(['osc', 'admin']);
  const mealPlanId = mealStore.get('mealPlanId');
  const vendorSetting = vendorsStore.getIn(['byId', catererId, 'vendorSetting']) || Immutable.fromJS({});
  const isShowMenuItems = mealStore.get('showMenuItems');
  const isShowMenuItemForm = cartVariationsStore.get('showMenuItemForm');
  const isLoading = mealStore.get('isLoading');
  const orderableVariations = userMealOrderable.get('orderVariations') || userMealOrderable.get('cartVariations');
  const userTotalQuantity = orderableVariations.reduce((total, variation) => (total + variation.get('quantity')), 0);
  const mealDate = dayjs(mealStore.get('scheduleAt')).format('DD MMM');

  const variationsList = orderableVariations.map(variation =>
    `- ${variation.get('menuItemName')}`,
  ).join('\n');

  const [showAlert, setAlert] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [vendors, setVendors] = useState(Immutable.fromJS([]));

  // eslint-disable-next-line no-unused-vars
  const [mealEditing, setMealEditing] = useLocalStorage(`editing-${mealId}`, null, {
    raw: true,
  });

  const handleAlertClose = () => {
    setAlert(false);
  };

  const handleFinishEditing = (e) => {
    e.preventDefault();

    if (orderableVariations.size === 0) {
      actions.resetUserMealCart(mealStore.get('mealPlanId'), userMealOrderable.get('uuid'));
    } else {
      actions.confirmUserMealCart(mealStore.get('mealPlanId'), userMealOrderable.get('uuid'));
    }
    window.location.href = redirect;
  };

  const handleForceRedirect = (e) => {
    e.preventDefault();
    window.location.href = redirect;
  };

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      const alertMessage = document.getElementsByClassName('alert')[0];
      if (alertMessage?.classList?.contains('--no-scroll')) {
        return;
      }

      if (alertMessage) {
        if (alertMessage.scrollIntoViewIfNeeded) {
          alertMessage.scrollIntoViewIfNeeded();
        } else {
          alertMessage.scrollIntoView();
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [userMealOrderable.get('currentState')]);

  useEffect(() => {
    if (!menuItemsStore.get('byVendorId').size) return;
    let _vendors = [];

    menuItemsStore.get('byVendorId').map((_data, id) => _vendors.push(vendorsStore.getIn(['byId', id])));

    const cartVendors = orderableVariations.map(o =>
      o.get('vendorId'),
    ).toSet();

    _vendors = currentState !== MEAL_STATE.confirmed
      ? Immutable.fromJS(_vendors)
      : Immutable.fromJS(_vendors).filter(v =>
        cartVendors.includes(v.get('uuid')),
      );

    _vendors = _vendors.sortBy(v => v.get('ordinal'));

    setVendors(_vendors);
  }, [
    menuItemsStore,
    vendorsStore,
    orderableVariations,
    currentState,
  ]);

  useEffect(() => {
    const isPendingState = (orderableVariations.size > 0 && currentState === MEAL_STATE.pending);
    const isEditingState = currentState === MEAL_STATE.editing;
    const shouldIntervene = (isPendingState || isEditingState);

    setMealEditing(shouldIntervene);

    document.addEventListener('before-visit', (evt) => {
      evt.preventDefault();
      setRedirect(evt.detail.url);
      setAlert(true);
    });
  }, [orderableVariations, currentState]);

  useEffect(() => {
    if (cartVariations.size === 0) {
      const scope = ['custom_menu_items', 'menu_items'];
      const includes = isMealConfirmed
        ? [
          ...scope,
          'orders',
          'order_variations',
          'order_variation_option_choices',
          'order_variation_item_add_on_options',
          'order_variation_service_add_on_options',
        ]
        : [
          ...scope,
          'carts',
          'cart_variations',
          'cart_variation_option_choices',
          'cart_variation_item_add_on_options',
          'cart_variation_service_add_on_options',
        ];

      actions.fetchMeal(mealPlanId, mealId, { includes });
    }
  }, [cartVariations]);

  return (
    <Fragment>
      {/* Custom Alert */}
      {showAlert && (
        currentState !== MEAL_STATE.pending ? (
          <AlertBox
            show={showAlert}
            title={translate('groupedOrdersActionsAlertAreYouSure')}
            message={
              orderableVariations.isEmpty()
              ? translate('groupedOrdersActionsAlertNoItemsSelected', { date: mealDate })
              : translate('groupedOrdersActionsAlertItemsSelected', { date: mealDate, items: variationsList })
            }
            cancelText={translate('groupedOrdersActionsAlertGoBack')}
            submitText={
              orderableVariations.isEmpty()
              ? translate('groupedOrdersActionsAlertCancelMeal')
              : translate('groupedOrdersActionsAlertFinishEditing')
            }
            submitButtonClassName="!tw-bg-yellow-400 !tw-border-yellow-400 !tw-text-gray-900"
            onCancel={handleAlertClose}
            onSubmit={handleFinishEditing}
          />
        ) : (
          <AlertBox
            show={showAlert}
            title={translate('groupedOrdersActionsAlertAreYouSureUnsaved')}
            message={translate('groupedOrdersActionsAlertSelectionHasNotBeenSaved')}
            cancelText={translate('groupedOrdersActionsAlertGoBack')}
            submitText={translate('groupedOrdersActionsAlertLeaveWithoutSaving')}
            submitButtonClassName="!tw-bg-yellow-400 !tw-border-yellow-400 !tw-text-gray-900"
            onCancel={handleAlertClose}
            onSubmit={handleForceRedirect}
          />
        )
      )}

      <div className={classNames({
        'tw-container tw-max-w-[1280px] pt-4 pb-5 position-relative': true,
      })}
      >
        <div className="tw-grid tw-grid-12">
          <div className="tw-grid-cols-12">
            <EditingIndicator />

            {/* Flash */}
            <Flash />

            { /* Header */}
            <Header />
          </div>
        </div>

        {/* Vendors Navigation */}
        {vendors.size > 1 && !isMealConfirmed && !isUserMealOrderableConfirmed &&
          <div className="tw-col-span-12 md:tw-col-span-8 tw-flex tw-flex-col tw-gap-2 md:tw-gap-4">
            <VendorsDock vendors={vendors} extended />
          </div>
        }

        { /* Body */}
        <div className="row tw-grid-cols-12 tw-mt-4 md:tw-mt-8">
          <div className="col">
            {isLoading ?
              <MenuItemsSkeletal size={3} /> :
              <Fragment>
                { /* Selected Menu Items */ }
                <SelectedMenuItemsContainer />
                {
                  isMealConfirmed &&
                  <Fragment>
                    <div className="mb-4">
                      <button
                        className="pl-0 btn btn-link"
                        onClick={actions.toggleMenuItems}
                      >
                        <span className="mr-2">
                          <i
                            className={classNames('fa', { 'fa-chevron-down': isShowMenuItems, 'fa-chevron-right': !isShowMenuItems })}
                            aria-hidden="true"
                          />
                        </span>
                        <span>{ translate('groupedOrdersActionsViewFullMenu') }</span>
                      </button>
                    </div>
                    <div className="py-4 d-block d-sm-none" />
                  </Fragment>
                }
                { /* Menu Items */}
                <MenuItemsContainer />
              </Fragment>
            }
          </div>

          { /* Responsive Meal Cart */}
          <ResponsiveMealCart
            key={`user-meal-orderable--${userMealOrderable.get('currentState')}`}
            mealSkipped={userMealOrderable.get('currentState') === statuses.skipped}
            showPrice={mealStore.getIn(['groupOrderSetting', 'showPrice'])}
            totalAmount={userMealOrderable.get('totalAmount')}
            totalQuantity={userTotalQuantity}
            isInPreview={isMealPreview}
            {
              ...{
                mealAdmin,
              }
            }
          />
        </div>

        { /* MenuItemFormContainer */ }
        <div className="menuitem-form">
          <MenuItemForm
            mealAdmin={mealAdmin}
            menuItem={selectedMenuItem}
            cartVariation={selectedCartVariation}
            mealId={mealStore.get('uuid')}
            mealLimit={mealStore.get('mealLimit')}
            mealPlanId={mealStore.get('mealPlanId')}
            catererId={catererId}
            cartId={cartId}
            cartUserId={null}
            mealPlanUserId={mealStore.get('mealPlanUserId')}
            showPrice={mealStore.getIn(['groupOrderSetting', 'showPrice'])}
            maxVariationQuantity={mealAdmin ? null : 1}
            budget={mealStore.getIn(['groupOrderSetting', 'budget'])}
            hideSpecialInstruction={vendorSetting.get('hide_special_instruction')}
            hideAddOns={vendorSetting.get('hide_add_ons')}
            hide={!isShowMenuItemForm}
            isSavingCartVariation={cartVariationsStore.get('isSavingCartVariation')}
            isNonZeroVat={isNonZeroVat}
            isGroupOrder={isGroupOrder}
            isEditable={isEditable}
            saveErrors={cartVariationsStore.get('saveErrors')}
            onClickOption={actions.checkAddOnValidity}
            onDeleteItemAddOnOptionPhoto={actions.deleteItemAddOnOptionPhoto}
            onDropItemAddOnOptionPhoto={actions.addTemporaryItemAddOnOptionPhoto}
            onDropItemAddOnOptionPhotoFailure={actions.addTemporaryItemAddOnOptionPhotoFailure}
            onSelectVariation={actions.selectCartVariation}
            onSubmit={actions.saveCartVariation}
            onUpdateItemAddOnOptionText={actions.updateItemAddOnOptionText}
            onHide={actions.hideMenuItemForm}
            orderableVariations={orderableVariations}
          />
        </div>

        <MealStatusContainer />

        <MealConfirmationGuidedInfo />
      </div>
    </Fragment>
  );
};

GroupOrderMeal.propTypes = {
  actions: object.isRequired,
  cartVariationsStore: object.isRequired,
  cartsStore: object.isRequired,
  mealStore: object.isRequired,
  userMealOrderable: object.isRequired,
  vendorsStore: object.isRequired,
  menuItemsStore: object.isRequired,
  userRoleIn: func.isRequired,
  translate: func.isRequired,
  flagIsEnabled: func,
};

export default withAppContext(withI18n(GroupOrderMeal));
