/* eslint max-len: 0 */

import { string } from 'prop-types';
import React from 'react';

const Add = ({ color = '#95989D' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.25 14L7.25 2L8.75 2L8.75 14H7.25Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 7.25L14 7.25V8.75L2 8.75L2 7.25Z" fill={color} />
  </svg>
);

Add.propTypes = {
  color: string,
};

export default Add;
