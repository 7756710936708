/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

/*
 * Returns minQuantity or the quantity itself
 *
 * Ex.
 * setQuantity(2, 3) => 3
 * setQuantity(0, 3) => 0
 * setQuantity(4, 3) => 4
 */
function setQuantity(quantity, minQuantity) {
  const newQuantity = quantity || 0;
  if (newQuantity !== 0 && newQuantity < minQuantity) { return minQuantity; }
  return newQuantity;
}

/*
 * Returns 0 or quantity - 1
 *
 * Ex.
 * decreaseQuantity(2, 3) => 0
 * decreaseQuantity(0, 3) => 0
 * decreaseQuantity(4, 2) => 3
 */
function decreaseQuantity(quantity, minQuantity) {
  const newQuantity = quantity - 1;
  if (newQuantity < minQuantity) { return 0; }
  return newQuantity;
}

/*
 * Returns minQuantity or quantity + 1
 *
 * Ex.
 * increaseQuantity(0, 3) => 3
 * increaseQuantity(3, 1) => 4
 */
function increaseQuantity(quantity, minQuantity) {
  return setQuantity(quantity + 1, minQuantity);
}

export const ModifierButton = styled.span.attrs(props => ({
  className: classNames('tw-rounded-full tw-bg-gray-50 tw-text-gray-200', props.klass),
}))`
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ModifierInput = styled.input.attrs(props => ({
  className: props.klass,
}))`
  max-width: 20px;
  min-width: 20px;
  text-align: center;
  outline: none;
`;


/**
 * Quantity Modifier
 */
export const QuantityModifier = ({
  name,
  isEditable,
  disabled,
  quantity,
  quantityLabel = false,
  quantityLabelText,
  minQuantity,
  updateQuantityHandler,
}) => {
  const [inputValue, setInputValue] = useState(quantity);
  const isUserEditing = useRef(false);

  useEffect(() => {
    if (!isUserEditing.current) {
      setInputValue(quantity);
    }
  }, [quantity]);

  if (!isEditable) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center">
        <span className="tw-text-sm">{quantity}</span>
      </div>
    );
  }

  const handleInputChange = (e) => {
    isUserEditing.current = true;
    setInputValue(e.target.value);
  };

  const handleInputBlur = (e) => {
    isUserEditing.current = false;
    const parsedValue = parseInt(inputValue, 10);

    const validValue = Number.isNaN(parsedValue) ? (minQuantity || 0) : parsedValue;
    const newQuantity = setQuantity(validValue, minQuantity);

    setInputValue(newQuantity);
    updateQuantityHandler(e, newQuantity);
  };

  const canDecrement = minQuantity ? quantity > minQuantity : true;

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
      <div className="custom-numbers tw-w-full tw-flex tw-justify-between tw-items-center tw-text-lg tw-rounded-full tw-border-[1px] tw-border-gray-200 tw-p-[2px]">
        <ModifierButton
          klass={classNames('input-number-decrement', {
            '!tw-bg-broccoli-100': canDecrement,
          })}
          onClick={(e) => updateQuantityHandler(e, decreaseQuantity(quantity, minQuantity))}
        >
          <svg
            className={classNames({
              '!tw-fill-broccoli-600': canDecrement,
              '!tw-fill-gray-200': disabled,
            })}
            width="12"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M0.599609 1.9998C0.599609 1.33706 1.13687 0.799805 1.79961 0.799805L16.1996 0.799805C16.8624 0.799805 17.3996 1.33706 17.3996 1.9998C17.3996 2.66255 16.8624 3.19981 16.1996 3.19981L1.79961 3.1998C1.13687 3.1998 0.599609 2.66255 0.599609 1.9998Z" />
          </svg>
        </ModifierButton>

        <ModifierInput
          klass="input-number tw-text-sm !tw-leading-4 !tw-min-w-8 md:!tw-min-width-26"
          value={inputValue}
          disabled={!isEditable}
          name={name}
          placeholder={minQuantity}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.target.blur();
            }
          }}
        />

        <ModifierButton
          klass="input-number-increment !tw-bg-broccoli-100"
          onClick={(e) => updateQuantityHandler(e, increaseQuantity(quantity, minQuantity))}
        >
          <svg width="12" height="12" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.99961 0.600098C9.66235 0.600098 10.1996 1.13736 10.1996 1.8001V7.8001H16.1996C16.8624 7.8001 17.3996 8.33736 17.3996 9.0001C17.3996 9.66284 16.8623 10.2001 16.1996 10.2001H10.1996V16.2001C10.1996 16.8628 9.66235 17.4001 8.99961 17.4001C8.33687 17.4001 7.79961 16.8628 7.79961 16.2001V10.2001H1.79961C1.13687 10.2001 0.599609 9.66284 0.599609 9.0001C0.599609 8.33736 1.13687 7.8001 1.79961 7.8001L7.79961 7.8001V1.8001C7.79961 1.13736 8.33687 0.600098 8.99961 0.600098Z" fill="#7D9E1D" />
          </svg>
        </ModifierButton>
      </div>
      {
        quantityLabel &&
        <label className="!tw-m-0 !tw-p-0">
          <span className="text-muted tw-text-[12px] md:tw-text-sm !tw-gray-600">
            {quantityLabelText}
          </span>
        </label>
      }
    </div>
  );
};

QuantityModifier.propTypes = {
  name: PropTypes.string,
  isEditable: PropTypes.bool,
  disabled: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  quantityLabel: PropTypes.bool,
  quantityLabelText: PropTypes.string,
  minQuantity: PropTypes.number,
  updateQuantityHandler: PropTypes.func.isRequired,
};

export default QuantityModifier;
