/* eslint-disable max-len */
import React, { memo } from 'react';
import { bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';

import actions from 'packs/meals/actions';
import MenuItems from 'packs/meals/components/GroupOrderMeal/MenuItems/MenuItems';
import { groupMenuItemsByVendor } from 'packs/meals/helpers/menuItems';
import { USER_MEAL_ORDERABLE_STATUSES } from 'packs/meals/constants/userMealCartConstants';

const MenuItemsContainer = ({
  fetchMenuItem,
  hide,
  mealPlanId,
  activeMenuItemsByVendor,
  cappedMenuItemsByVendor,
  showPrice,
}) => (
  <div id="menu-items">
    <MenuItems
      {
        ...{
          fetchMenuItem,
          hide,
          mealPlanId,
          menuItemsByVendor: activeMenuItemsByVendor,
          showPrice,
          showMealCountCap: true,
        }
      }
    />
    <MenuItems
      {
        ...{
          hide,
          mealPlanId,
          menuItemsByVendor: cappedMenuItemsByVendor,
          showPrice,
          showMealCountCap: true,
        }
      }
    />
  </div>
);

const mapStateToProps = ({
  $$cartsStore,
  $$mealStore,
  $$menuItemsStore,
  $$userMealCartStore,
  $$userMealOrderStore,
  $$vendorsStore,
}) => {
  const showMenuItems = $$mealStore.get('showMenuItems');
  const isMealConfirmed = $$mealStore.get('confirmed');
  const userMealOrderable = isMealConfirmed ? $$userMealOrderStore : $$userMealCartStore;
  const menuItemViewableStates = $$mealStore.get('open') ||
                              $$mealStore.get('preview') ||
                              $$mealStore.get('processing');

  const hide = (isMealConfirmed && !showMenuItems) ||
                (!isMealConfirmed && (
                  !menuItemViewableStates ||
                  !([USER_MEAL_ORDERABLE_STATUSES.editing, USER_MEAL_ORDERABLE_STATUSES.pending].includes(userMealOrderable.get('currentState')))
                ));

  let activeMenuItemsByVendor = Immutable.fromJS([]);
  let cappedMenuItemsByVendor = Immutable.fromJS([]);
  groupMenuItemsByVendor(
    $$menuItemsStore.get('byId').valueSeq(),
    $$vendorsStore,
    $$cartsStore,
  ).forEach(m => {
    if (!m.get('mealCountCap') || !m.get('isMealCountCapReached')) {
      activeMenuItemsByVendor = activeMenuItemsByVendor.push(m);
    } else {
      cappedMenuItemsByVendor = cappedMenuItemsByVendor.push(m);
    }
  });

  return {
    hide,
    mealPlanId: $$mealStore.get('mealPlanId'),
    activeMenuItemsByVendor,
    cappedMenuItemsByVendor,
    showPrice: $$mealStore.getIn(['groupOrderSetting', 'showPrice']),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchMenuItem: actions.fetchMenuItem,
}, dispatch);


MenuItemsContainer.propTypes = {
  cartsStore: object,
  fetchMenuItem: func.isRequired,
  hide: bool.isRequired,
  mealPlanId: string.isRequired,
  menuItemsByVendor: object,
  activeMenuItemsByVendor: object.isRequired,
  cappedMenuItemsByVendor: object.isRequired,
  showPrice: bool.isRequired,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(MenuItemsContainer));
