import React from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';

import AppContext from 'libs/support/appContext';
import { defaultTheme } from 'libs/support/theme';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

const StartupApp = (
  storeName,
  Container,
) =>
  (props, railsContext) => {
    let store;

    if (storeName) { store = ReactOnRails.getStore(storeName, false); }

    const context = {
      fflags: railsContext.fflags,
      settings: railsContext.settings,
      currentMealPlanUser: railsContext.current_meal_plan_user,
    };

    const queryClient = new QueryClient();

    window.apiCableUrl = railsContext.settings.api_cable_url;

    return (
      <AppContext.Provider value={context}>
        <QueryClientProvider client={queryClient}>
          {
            store ?
              <Provider store={store}>
                <ThemeProvider theme={defaultTheme} >
                  <Container {...props} />
                </ThemeProvider>
              </Provider> :
              <ThemeProvider theme={defaultTheme} >
                <Container {...props} />
              </ThemeProvider>
          }
        </QueryClientProvider>
      </AppContext.Provider>
    );
  };

export default StartupApp;
