import requestsManager from 'libs/middlewares/requestsManager';

/**
 * API to create meal report
 * GET /meal_reports
 */
export const createMealReport = (params, successCallback, failedCallback) => {
  const endpoint = '/meal_reports';

  return requestsManager.createEntity(params, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
