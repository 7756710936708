/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import { func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { EditIcon, AvatarIcon, ThreeDotsIcon } from 'libs/components/Icons';
import Trash from 'libs/components/Icons/Trash';
import Dropdown from 'react-bootstrap/Dropdown';
import { formattedPrice } from 'libs/support/formattedPrice';

import OrderableVariation from '../MealCartItem/OrderableVariation';
import { buildAddOnDetails } from '../MealCartItem/helpers';

const Item = ({
  orderableVariations,
  vendorName,
  newAddedCartVariationId,
  fetchCartVariation,
  mealPlanId,
  onDeleteItem,
  onEditAssignedUser,
  menuItemsById,
}) => (
  <div>
    <h6 className="mb-0 mt-2 cart-order-list__vendor-name">
      { vendorName }
    </h6>

    <div>
      {
        orderableVariations &&
        orderableVariations.map((orderableVariation) => {
          const menuItem = menuItemsById.get(orderableVariation.get('menuItemId'));
          const userName = orderableVariation.get('cartUserName') || orderableVariation.get('orderUserName');
          const isGuest = orderableVariation.get('cartUserIsGuest') || orderableVariation.get('orderUserIsGuest');
          const itemName = orderableVariation.get('name');
          const quantity = orderableVariation.get('quantity');
          const subtotal = orderableVariation.get('subtotal');
          const addOnDetails = buildAddOnDetails(orderableVariation.toJS());
          const additionalInstructions = orderableVariation.get('additionalInstructions');
          return (
            <div
              className={classNames('row-item tw-grid tw-grid-cols-4 tw-gap-4', {
                'new-item': newAddedCartVariationId === orderableVariation.get('uuid'),
                'tw-border-b tw-border-gray-100': true,
              })}
              key={`cartVariation-${orderableVariation.get('uuid')}`}
            >
              <div className="tw-hidden md:tw-flex desktop-col tw-col-span-3">
                <OrderableVariation
                  userName={userName}
                  isGuest={isGuest}
                  itemName={itemName}
                  quantity={quantity}
                  subtotal={subtotal}
                  addOnDetails={addOnDetails}
                  additionalInstructions={additionalInstructions}
                />
              </div>
              <div className={classNames('tw-hidden md:tw-flex actions-col desktop-col', {
                'tw-col-span-1 tw-flex tw-items-center tw-justify-around tw-gap-2': true,
                'tw-px-4': true,
              })}
              >
                <button
                  className="edit-btn !tw-h-8"
                  onClick={() => { fetchCartVariation(mealPlanId, orderableVariation.get('uuid')); }}
                >
                  <EditIcon />
                </button>
                <button
                  className="avatar-icon assign-btn !tw-h-8"
                  onClick={() => { onEditAssignedUser(mealPlanId, orderableVariation, menuItem); }}
                >
                  <AvatarIcon size="small" />
                </button>
                <button
                  className="delete-btn !tw-h-8"
                  onClick={() => {
                    onDeleteItem(
                      mealPlanId,
                      orderableVariation.get('uuid'),
                      orderableVariation.get('cartUserIsGuest'),
                    );
                  }}
                >
                  <Trash />
                </button>
              </div>
              <div className="tw-block md:tw-hidden mobile-col tw-col-span-4">
                <div className="header">
                  <p><strong>{userName}</strong></p>
                  <Dropdown>
                    <Dropdown.Toggle id="edit-order-dropdown" variant="light" className="edit-order-dropdown btn-light caret-off d-flex justify-content-center align-items-center">
                      <ThreeDotsIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right">
                      <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => { fetchCartVariation(mealPlanId, orderableVariation.get('uuid')); }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => { onEditAssignedUser(mealPlanId, orderableVariation, menuItem); }}
                      >
                        Assign
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => { onDeleteItem(mealPlanId, orderableVariation.get('uuid')); }}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <p><strong>{quantity} x {itemName}</strong></p>
                <p>{addOnDetails}</p>
                <p>{additionalInstructions}</p>
                <p><strong>{formattedPrice(subtotal)}</strong></p>
              </div>
            </div>
          );
      })}
    </div>
  </div>
);

Item.propTypes = {
  orderableVariations: object,
  vendorName: string,
  newAddedCartVariationId: func,
  fetchCartVariation: func,
  mealPlanId: string,
  onDeleteItem: func,
  onEditAssignedUser: func,
  menuItemsById: string,
};

export default withI18n(Item);
