/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { bool, func, number, object, string } from 'prop-types';
import { blankish } from 'libs/support/string';
import throttle from 'lodash/throttle';
import TrashBin from 'libs/components/Icons/TrashBin';

import QuantityModifier from '../../../Shared/QuantityModifier';

const Spacer = styled.span`
  display: flex;
  flex: 1;
  margin: 0 0.5em 0.25em;
  background-image: linear-gradient(to right, #E5E7EB 30%, rgba(255, 255, 255, 0) 0%);
  background-size: 6px 2px;
  height: 2px;
  ::before {
    content: '';
    width: 1px;
  }
`;

/**
 * MealCartVariationItemInfo
 */

const MealCartVariationItemInfo = ({
  name,
  price,
  quantity,
}) => (
  <div className="tw-flex tw-flex-row tw-items-end tw-justify-between">
    <div className="tw-flex tw-gap-1">
      {quantity > 1 &&
        <span className="tw-text-sm">{quantity}x</span>
      }
      <span className="tw-text-sm tw-text-gray-600 tw-leading-2">
        {name}
      </span>
    </div>
    <Spacer />
    <div className="tw-text-sm tw-text-gray-600">
      {price}
    </div>
  </div>
);

MealCartVariationItemInfo.propTypes = {
  price: string,
  name: string,
  quantity: number,
};

/**
 * MealCartVariationItem
 */
const MealCartVariationItem = ({
  variation,
  bordered,
  mealPlanId,
  mealAdmin,
  // eslint-disable-next-line no-unused-vars
  mealConfirmed,
  mealEditable,
  updateQuantity,
  updateCustomQuantity,
}) => {
  if (!variation) { return null; }

  const name = variation.get('name');
  const quantity = variation.get('quantity');
  const minQuantity = variation.get('min_order_quantity');
  const subtotal = variation.get('subtotal');
  const isCustomMenuItem = variation.get('type');

  const addonsOptions = variation.get('cart_variation_option_choices');
  const addonsItems = variation.get('cart_variation_item_add_on_options');
  const addonsServices = variation.get('cart_variation_service_add_on_options');

  const [enableActions, setEnabledActions] = useState(false);
  const [withAddons, setWithAddons] = useState(false);
  const [editable] = useState(minQuantity ? quantity >= minQuantity : true);

  const updateQuantityHandler = throttle((quantity) => updateQuantity(
    mealPlanId,
    variation.get('uuid'),
    quantity,
    variation,
  ), 300);

  const updateCustomQuantityHandler = throttle((quantity) => updateCustomQuantity(
    mealPlanId,
    variation.get('uuid'),
    {
      cart_id: variation.get('cart_id'),
      custom_menu_item: { quantity },
    },
  ), 300);

  const removeQuantityHandler = (e) => {
    e.preventDefault();

    return (!isCustomMenuItem)
      ? updateQuantityHandler(0)
      : updateCustomQuantityHandler(0);
  };

  const handleUpdateQuantity = (e, qty) => {
    e.preventDefault();
    if (e.target.value && blankish(e.target.value)) return;

    return (!isCustomMenuItem)
      ? updateQuantityHandler(qty)
      : updateCustomQuantityHandler(qty);
  };

  const handleToggleEnableActions = (e) => {
    e.preventDefault();
    setEnabledActions(!enableActions);
  };

  useEffect(() => {
    setWithAddons(false);
    if (
      (addonsOptions && addonsOptions.size > 0) ||
      (addonsItems && addonsItems.size > 0) ||
      (addonsServices && addonsServices.size > 0)
    ) {
      setWithAddons(true);
    }
  }, [addonsOptions, addonsItems, addonsServices]);

  return (
    <li
      key={name}
      className={classNames('tw-flex tw-flex-col tw-gap-0 tw-px-6 md:tw-px-8')}
      onMouseEnter={handleToggleEnableActions}
      onMouseLeave={handleToggleEnableActions}
      onBlur={handleToggleEnableActions}
    >
      <div className={
        classNames('tw-relative tw-py-2 tw-pt-4 tw-flex tw-flex-col tw-items-start tw-gap-2', {
          'tw-border-b-[1px] tw-border-gray-200': bordered,
        })}
      >
        {(mealEditable) && enableActions &&
          <div
            className="tw-absolute tw-top-[1.2rem] -tw-left-6 tw-origin-left"
            data-testid="delete-item"
          >
            <div className="tw-flex tw-items-center tw-space-around tw-gap-2">
              <button
                id="delete-cart-variation-item"
                className="tw-flex"
                onClick={removeQuantityHandler}
              >
                <TrashBin color="#1280b7" />
              </button>
            </div>
          </div>
        }
        <div className={classNames('tw-relative tw-flex tw-flex-row tw-justify-between tw-w-full', {
          'tw-items-center': !mealConfirmed,
          '!tw-items-start': !mealEditable,
        })}
        >
          <div className={classNames('tw-text-sm tw-text-gray-900 tw-font-semibold tw-leading-2', {
            'tw-flex tw-gap-2': !mealEditable,
          })}
          >
            {!mealEditable &&
              <span className="tw-inline-block tw-font-normal tw-min-w-[20px]">{quantity}x</span>
            }
            {name}
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <div className="tw-text-sm tw-text-gray-900 tw-font-semibold tw-text-right tw-min-w-[60px]">
              {subtotal}
            </div>
            {mealAdmin && mealEditable &&
              <div className="tw-flex tw-justify-between">
                <div className="tw-transform tw-origin-right">
                  <QuantityModifier
                    {...{
                      quantity,
                      minQuantity,
                      updateQuantityHandler: handleUpdateQuantity,
                      isEditable: editable,
                    }}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <div className={classNames('tw-flex tw-flex-col tw-gap-2 tw-w-full', {
          'tw-pb-4': !variation.get('additional_instructions') && withAddons,
        })}
        >
          {withAddons && <span>Add ons:</span>}

          {/* Options */}
          {addonsOptions && addonsOptions.map((o, index) =>
          (<MealCartVariationItemInfo
            key={index}
            price={o.get('price')}
            name={o.get('name')}
            quantity={o.get('quantity')}
          />))}

          {/* Items Addons */}
          {addonsItems && addonsItems.map((o, index) =>
          (<MealCartVariationItemInfo
            key={index}
            price={o.get('price')}
            name={o.get('name')}
            quantity={o.get('quantity')}
          />))}

          {/* Services Addons */}
          {addonsServices && addonsServices.map((o, index) =>
          (<MealCartVariationItemInfo
            key={index}
            price={o.get('price')}
            name={o.get('name')}
            quantity={o.get('quantity')}
          />))}
        </div>
        {
          (variation.get('additional_instructions') || variation.get('special_instructions')) &&
          <div className={classNames('tw-w-full tw-flex tw-flex-row tw-gap-1', {
            'tw-px-2 tw-py-2 tw-my-2 tw-bg-blueberry-50 tw-rounded': true,
          })}
          >
            <span className="tw-text-gray-900">
              <span className="tw-inline-block tw-font-bold tw-mr-2">
                Special Instructions:
              </span>
              {
                variation.get('additional_instructions') ||
                variation.get('special_instructions')
              }
            </span>
          </div>
        }
      </div>
    </li>
  );
};

MealCartVariationItem.propTypes = {
  store: object,
  variation: object,
  bordered: bool,
  mealPlanId: string,
  mealAdmin: bool,
  mealConfirmed: bool,
  mealEditable: bool,
  updateQuantity: func,
  updateCustomQuantity: func,
};

export default MealCartVariationItem;
