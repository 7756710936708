/* eslint-disable no-multi-assign */
import ReactOnRails from 'react-on-rails';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

import 'bootstrap';
import 'flickity';
import 'jquery-serializejson';
import 'eonasdan-bootstrap-datetimepicker';
import Turbolinks from 'turbolinks';


import mealStore from 'packs/meals/stores/mealStore';
import mealPlanUsersStore from 'packs/meal_plan_users/stores/mealPlanUsersStore';
import pantryProgramStore from 'packs/pantry_programs/stores/pantryProgramStore';
import mealCardStore from 'packs/meal_plans/stores/mealCardStore';
import $ from 'jquery';

import '../assets/stylesheets/application.sass';

import * as SharedApp from '../startups/SharedApp';
import GroupOrderMealApp from '../startups/GroupOrderMealApp';
import GroupOrderMealCardHeadlineApp from '../startups/GroupOrderMealCardHeadlineApp';
import MealPlanUsersApp from '../startups/MealPlanUsersApp';
import PlannedMealApp from '../startups/PlannedMealApp';
import MobileNavApp from '../startups/MobileNavApp';
import PlannedMealCardApp from '../startups/PlannedMealCardApp';
import PantryProgramDailyLogApp from '../startups/PantryProgramDailyLogApp';
import FinancialReportsApp from '../startups/FinancialReportsApp';
import NotificationSettingsApp from '../startups/NotificationSettingsApp';
import GroupOrderMealCardApp from '../startups/GroupOrderMealCardApp';
import MealTabsApp from '../startups/MealTabsApp';

const requireImage = require.context('../assets/images', true, /\.[ico|png|svg|jpg]*$/);
requireImage.keys().forEach(requireImage);

addLocaleData([...en]);
window.$ = window.jQuery = $;
Turbolinks.start();

ReactOnRails.setOptions({
  traceTurbolinks: process.env.TRACE_TURBOLINKS, // eslint-disable-line no-undef
});

ReactOnRails.register({
  GroupOrderMealApp,
  GroupOrderMealCardHeadlineApp,
  MealPlanUsersApp,
  PlannedMealApp,
  MobileNavApp,
  MealTabsApp,
  GroupOrderMealCardApp,
  PlannedMealCardApp,
  PantryProgramDailyLogApp,
  FinancialReportsApp,
  NotificationSettingsApp,
});

ReactOnRails.registerStore({
  mealStore,
  mealPlanUsersStore,
  mealCardStore,
  pantryProgramStore,
});

SharedApp.initialize();
SharedApp.setupTurboListeners();
