import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import NotificationSettings from '../components/NotificationSettings';
import NotificationSettingsMobile from '../components/mobile/NotificationSettings';
import { restructureSettings } from '../components/utils';

const NotificationSettingsContainer = ({ settings: initialSettings }) => {
  const [settings, setSettings] = useState(initialSettings);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const roleMapping = {
    osc: 'admin',
    osc_and_admin: 'admin',
  };
  const role = roleMapping[initialSettings.view_mode] || initialSettings.view_mode || 'all';
  const settingsStructure = restructureSettings(role);

  const updateSetting = (key, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ background: 'white', color: 'black' }}>
      {isMobileView ? (
        <NotificationSettingsMobile
          settings={settings}
          updateSetting={updateSetting}
          settingsStructure={settingsStructure}
          role={role}
        />
      ) : (
        <NotificationSettings
          settings={settings}
          updateSetting={updateSetting}
          settingsStructure={settingsStructure}
          role={role}
        />
      )}
    </div>
  );
};

NotificationSettingsContainer.propTypes = {
  settings: object.isRequired,
};

export default NotificationSettingsContainer;
