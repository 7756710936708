import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BtnXs = styled.button`
  max-width: 30px;
  padding: 0 8px;
`;

export function QuantityInput({ value, onChange, disabled }) {
  const [initialValue] = useState(value);

  useEffect(() => {
    if (disabled) {
      onChange(initialValue);
    }
  }, [disabled]);

  return (
    <div className="input-group" style={{ minWidth: '80px', backgroundColor: 'white' }}>
      <div className="input-group-prepend" id="button-addon1">
        <BtnXs
          className="btn btn-sm btn-outline-secondary"
          type="button"
          onClick={() => {
            if (disabled) return;
            onChange(value - 1);
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faMinus} color="#7D9E1D" fontSize={12} />
        </BtnXs>
      </div>
      <input
        type="text"
        className="form-control"
        style={{ maxWidth: '50px', padding: '0', textAlign: 'center' }}
        aria-label="Quantity"
        aria-describedby="button-addon3"
        value={value}
        onChange={(e) => {
          if (disabled) return;
          onChange(e.target.value);
        }}
        disabled={disabled}
      />
      <div className="input-group-append" id="button-addon2">
        <BtnXs
          className="btn btn-sm btn-outline-secondary"
          type="button"
          onClick={() => {
            if (disabled) return;
            onChange(value + 1);
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlus} color="#7D9E1D" fontSize={12} />
        </BtnXs>
      </div>
    </div>
  );
}


QuantityInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
