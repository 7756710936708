/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useState, useEffect } from 'react';
import PropTypes, { any, bool } from 'prop-types';
import classNames from 'classnames';

/**
 * VendorsDock
 */

const VendorsDock = ({
  vendors,
  isAdmin,
  extended = false,
  editable = true,
}) => {
  const [activeVendor, setActiveVendor] = useState();
  const [activeBanner, setActiveBanner] = useState(false);

  const handleClickTab = (id) => {
    const element = document.getElementById(id);
    if (element) {
      $('html, body').animate({
        scrollTop: element.offsetTop + (extended ? 240 : 120),
      });
    }
  };

  const checkScrollPosition = (position) => {
    if (!position) return;

    if (vendors.size > 0) {
      vendors.forEach((vendor, _index) => {
        const vendorId = vendor.get('uuid');
        const element = document.getElementById(vendorId);

        if (!element) return;
        const offset = element.offsetTop + (extended ? 160 : 0);
        const elementHeightRange = offset + element.scrollHeight;

        if (position >= offset && position <= elementHeightRange) {
          setActiveVendor(vendorId);
        }
      });
    }
  };

  useEffect(() => {
    if (!vendors) return;

    if (vendors.size > 0 && !activeVendor) {
      setActiveVendor(vendors.first().get('uuid'));
    }

    document.removeEventListener('scroll', () => false);

    document.addEventListener('scroll', (e) => {
      e.preventDefault();

      checkScrollPosition(window.scrollY);
      const element = document.getElementsByClassName('menu-items-vendors');
      if (element && element.length > 0) {
        const elementTop = element[0].offsetTop;
        const offset = (window.scrollY >= elementTop);
        setActiveBanner(offset);
      }
    });

    return () => document.removeEventListener('scroll', () => false);
  }, [vendors]);

  return (
    <div className={classNames('tw-col-span-12 md:tw-col-span-8', {
      'tw-flex tw-flex-col tw-gap-2 md:tw-gap-4': true,
      'tw-hidden': !activeBanner && vendors.size <= 1,
      'md:tw-col-start-3': !isAdmin && !editable,
    })}
    >
      <div
        className={classNames('tw-relative tw-transform tw-w-full tw-mx-auto tw-bg-white', {
          'tw-container tw-max-w-[100vw] !tw-fixed !tw-top-0 tw-left-0 tw-z-[200] tw-shadow-lg': activeBanner,
          'tw-border-b tw-border-gray-200': !activeBanner,
        })}
        data-testid="vendors-navigator"
      >
        <div className={classNames('tw-container tw-max-w-[1280px] tw-mx-auto tw-items-center tw-gap-3', {
          'tw-pt-4 tw-flex': activeBanner,
          '!tw-grid md:!tw-px-28': !isAdmin && !editable && activeBanner,
        })}
        >
          <ul className={classNames('tw-list-none tw-m-0 tw-flex tw-gap-6', {
            'tw-overflow-x-auto md:tw-overflow-hidden tw-scrollable': true,
            'md:tw-col-span-12 md:tw-col-start-4': !isAdmin && !editable && activeBanner,
          })}
          >
            {vendors.map((vendor, index) => (
              <li
                id={`vendor-${vendor.get('uuid')}`}
                key={index}
                className={classNames('tw-whitespace-nowrap tw-py-2 tw-px-0', {
                  'hover:!tw-no-underline hover:!tw-decoration-0 tw-text-gray-400 hover:tw-text-broccoli-600': true,
                  '!tw-text-broccoli-600 !tw-border-b-2 !tw-border-broccoli-600 tw-font-bold': activeVendor === vendor.get('uuid'),
                  active: activeVendor,
                })}
              >
                <a
                  href="#"
                  data-turbolinks="false"
                  className={classNames('!tw-font-display !tw-decoration-0 !tw-font-display-bold', {
                    'hover:!tw-no-underline tw-text-gray-400 hover:tw-text-broccoli-600': true,
                    '!tw-text-broccoli-600': activeVendor === vendor.get('uuid'),
                  })}
                  style={{ textDecoration: 'none' }}
                  onClick={() => handleClickTab(vendor.get('uuid'))}
                >
                  {vendor.get('name')}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

VendorsDock.propTypes = {
  vendors: PropTypes.object,
  isAdmin: bool,
  extended: any,
  editable: any,
};

export default VendorsDock;
