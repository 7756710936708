/* eslint max-len: 0 */

import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BaseComponent from 'libs/components/BaseComponent';
import { withAppContext } from 'libs/support/appContext';

import actions from 'packs/meals/actions';
import UserMealCart from 'packs/meals/components/GroupOrderMeal/UserMealCart';

/**
 * UserMealCartContainer
 */
class UserMealCartContainer extends BaseComponent {
  static propTypes = {
    address: object.isRequired,
    allowSelfCheckout: bool.isRequired,
    isCafeOrdering: bool.isRequired,
    confirmUserMealCart: func.isRequired,
    resetUserMealCart: func.isRequired,
    checkoutUserMealCart: func.isRequired,
    closeModal: func,
    deleteCartVariation: func.isRequired,
    fetchCartVariation: func.isRequired,
    mealConfirmed: bool.isRequired,
    isInPreview: bool.isRequired,
    mealId: string.isRequired,
    mealPlanId: string.isRequired,
    scheduleAt: string.isRequired,
    settings: object.isRequired,
    showPrice: bool.isRequired,
    skipUserMealCart: func.isRequired,
    userMealOrderable: object.isRequired,
  };

  render() {
    const {
      address,
      allowSelfCheckout,
      isCafeOrdering,
      confirmUserMealCart,
      resetUserMealCart,
      checkoutUserMealCart,
      closeModal,
      deleteCartVariation,
      fetchCartVariation,
      hasPassedDeadline,
      mealConfirmed,
      isInPreview,
      mealPlanId,
      mealId,
      scheduleAt,
      settings,
      showPrice,
      skipUserMealCart,
      userMealOrderable,
    } = this.props;

    return (
      <StripeProvider apiKey={settings.stripe.key}>
        <Elements>
          <UserMealCart
            {
              ...{
                allowSelfCheckout,
                address,
                isCafeOrdering,
                closeModal,
                checkoutUserMealCart,
                confirmUserMealCart,
                resetUserMealCart,
                deleteCartVariation,
                fetchCartVariation,
                hasPassedDeadline,
                mealConfirmed,
                isInPreview,
                mealId,
                mealPlanId,
                scheduleAt,
                showPrice,
                skipUserMealCart,
                userMealOrderable,
              }
            }
          />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToProps = ({ $$userMealCartStore, $$userMealOrderStore, $$mealStore }) => ({
  allowSelfCheckout: $$mealStore.getIn(['groupOrderSetting', 'allowSelfCheckout']),
  address: $$mealStore.get('address'),
  isCafeOrdering: $$mealStore.getIn(['groupOrderSetting', 'cafeOrdering']),
  hasPassedDeadline: $$mealStore.get('hasPassedDeadline'),
  mealConfirmed: $$mealStore.get('confirmed'),
  isInPreview: $$mealStore.get('preview'),
  mealPlanId: $$mealStore.get('mealPlanId'),
  mealId: $$mealStore.get('uuid'),
  scheduleAt: $$mealStore.get('scheduleAt'),
  showPrice: $$mealStore.getIn(['groupOrderSetting', 'showPrice']),
  userMealOrderable: $$mealStore.get('confirmed') ? $$userMealOrderStore : $$userMealCartStore,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  confirmUserMealCart: actions.confirmUserMealCart,
  resetUserMealCart: actions.resetUserMealCart,
  checkoutUserMealCart: actions.checkoutUserMealCart,
  deleteCartVariation: actions.deleteCartVariation,
  fetchCartVariation: actions.fetchCartVariation,
  skipUserMealCart: actions.skipUserMealCart,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(UserMealCartContainer));
