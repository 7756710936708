/* eslint max-len: 0 */

import React from 'react';

const Mobile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="203" height="152" viewBox="0 0 203 152" fill="none">
    <g clipPath="url(#clip0)">
      <path d="M139 18.9002C159.2 21.8002 184.6 32.6002 189.2 48.7002C193.8 64.8002 177.7 85.9002 162.3 102.9C146.9 119.9 132.4 132.6 114.5 141.2C96.7 149.7 75.6 154.2 61.4 146.1C47.2 138.1 40 117.6 31.6 99.5002C23.2 81.4002 13.5 65.7002 18.1 54.7002C22.7 43.7002 41.4 37.5002 55.5 33.5002C69.6 29.5002 79 27.9002 91.3 24.5002C103.7 21.2002 118.8 16.0002 139 18.9002Z" fill="#F4F5F5" />
      <path d="M74.7 23.7002C71.5 23.7002 70.5 28.8002 70.5 30.8002C70.5 34.2002 74.2 48.4002 77.6 48.4002C81 48.4002 84.7 34.4002 84.7 32.8002C84.7 31.2002 80.4 23.7002 74.7 23.7002Z" fill="#F5D0AE" />
      <path d="M139.8 26.2L89 16.1C88.8 16.1 88.6 16 88.4 16C86.9 16 85.6 17.1 85.3 18.6L66 116.1C65.7 117.8 66.8 119.5 68.5 119.8L119.3 129.8C119.5 129.8 119.7 129.9 119.9 129.9C121.4 129.9 122.7 128.8 123 127.3L142.3 29.8C142.5 29 142.3 28.1 141.8 27.4C141.3 26.7 140.6 26.3 139.8 26.2Z" fill="white" />
      <path d="M117.7 131.7C121.1 132.4 124.3 130.2 125 126.8L125.9 123L64.9 109.5L64 114.8C63.3 118.2 65.5 121.4 68.9 122.1L117.7 131.7Z" fill="#94AF46" />
      <path d="M140.2 24.1L89.4 14.1C89 14 88.7 14 88.3 14C85.8 14 83.6 15.7 83.2 18.2L63.9 115.7C63.3 118.5 65.2 121.3 68 121.9L118.8 131.9C119.1 132 119.5 132 119.8 132C122.3 132 124.5 130.3 124.9 127.8L144.2 30.3C144.9 27.4 143 24.7 140.2 24.1ZM142.3 29.9L123 127.4C122.7 128.9 121.4 130 119.9 130C119.7 130 119.5 130 119.3 129.9L68.5 119.9C66.8 119.6 65.7 117.9 66 116.2L85.3 18.7C85.6 17.2 86.9 16.1 88.4 16.1C88.6 16.1 88.8 16.1 89 16.2L100.2 18.4L99.8 20.5C99.5 22.1 100.5 23.6 102.1 24L123.1 28.1C124.7 28.4 126.2 27.4 126.6 25.8L127 23.8L139.8 26.3C140.6 26.5 141.3 26.9 141.8 27.6C142.3 28.3 142.4 29 142.3 29.9Z" fill="#94AF46" />
      <path d="M64.5 109.8L126.3 122.8" stroke="#E6F7B7" strokeMiterlimit="10" />
      <path d="M93.2 124.1C94.6 124.1 95.7 123 95.7 121.6C95.7 120.2 94.6 119.1 93.2 119.1C91.8 119.1 90.7 120.2 90.7 121.6C90.8 123 91.9 124.1 93.2 124.1Z" fill="white" />
      <path d="M70.9 51.1001C66.7 51.1001 63.8 54.3001 63.8 57.8001C63.8 69.1001 70.3 78.5001 73.1 78.5001C79.2 78.5001 81.4 75.3001 81.4 73.0001C81.4 71.3001 80.6 51.1001 70.9 51.1001Z" fill="#F5D0AE" />
      <path d="M68.6 80.8C69.8 82 79.1 91.9 79.1 98.8C79.1 103.5 78.1 107.1 69.6 107.1C65.6 107.1 54.2 91.1 54.2 84.6C54.2 80.9 56.4 72.5 62.7 72.5C64.5 72.5 68.6 75.7 69.2 76.5C69.8 77.1 70.5 77.8 71.2 78.3C71 79.6 69.5 86.5 69.5 86.5" fill="#F5D0AE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M88.8 64.9001C90.2 56.8001 97.8 51.4001 105.9 52.8001C112.6 53.9001 117.5 59.4001 118.2 65.9001L115.9 65.5001C115.1 65.3001 114.4 66.3001 114.8 67.0001L117.2 72.3001C117.5 73.0001 118.4 73.1001 118.9 72.6001L122.9 68.4001C123.5 67.8001 123.2 66.7001 122.3 66.6001L119.8 66.2001C119.3 58.9001 113.8 52.5001 106.2 51.3001C97.3 49.8001 88.9 55.8001 87.3 64.7001C85.8 73.6001 91.8 82.0001 100.7 83.6001C101.1 83.7001 101.5 83.4001 101.6 83.0001C101.7 82.6001 101.4 82.2001 101 82.1001C92.8 80.6001 87.4 73.0001 88.8 64.9001ZM117.8 70.9001C117.9 71.1001 118.3 71.2001 118.4 71.0001L120.8 68.6001C121 68.4001 120.9 68.0001 120.6 67.9001L116.7 67.2001C116.4 67.1001 116.2 67.5001 116.3 67.7001L117.8 70.9001Z" fill="#94AF46" />
      <path fillRule="evenodd" clipRule="evenodd" d="M97.7 58.5001C98.1 58.6001 98.4 59.0001 98.3 59.4001L97.6 64.0001C97.5 64.4001 97.8 64.8001 98.2 64.9001L99.1 59.5001C99.2 59.1001 99.6 58.8001 100 58.9001C100.4 59.0001 100.7 59.4001 100.6 59.8001L99.7 65.2001C100.1 65.3001 100.5 65.0001 100.6 64.6001L101.4 60.0001C101.5 59.6001 101.9 59.3001 102.3 59.4001C102.7 59.5001 103 59.9001 102.9 60.3001L102.1 64.9001C101.9 66.2001 100.7 67.0001 99.4 66.8001L98.2 73.7001C98.1 74.1001 97.7 74.4001 97.3 74.3001C96.9 74.2001 96.6 73.8001 96.7 73.4001L97.9 66.5001C96.6 66.3001 95.8 65.1001 96 63.8001L96.8 59.2001C96.9 58.7001 97.3 58.4001 97.7 58.5001ZM108.2 61.9001C107.8 61.8001 107.4 62.1001 107.3 62.5001L106.8 65.6001C106.7 66.0001 107 66.4001 107.4 66.5001L108.9 66.8001C109.3 66.9001 109.7 66.6001 109.8 66.2001L110.3 63.1001C110.4 62.7001 110.1 62.3001 109.7 62.2001L108.2 61.9001ZM105.8 62.2001C106 60.9001 107.2 60.1001 108.5 60.3001L110 60.6001C111.3 60.8001 112.1 62.0001 111.9 63.3001L111.4 66.4001C111.2 67.7001 110 68.5001 108.7 68.3001L107.5 75.2001C107.4 75.6001 107 75.9001 106.6 75.8001C106.2 75.7001 105.9 75.3001 106 74.9001L107.2 68.0001C105.9 67.8001 105.1 66.6001 105.3 65.3001L105.8 62.2001Z" fill="#94AF46" />
      <path d="M67.1 121.6C66.5 123.2 66.1 138.9 67.9 147.6C66.7 153.9 61.8 174.3 61.8 174.3H105.8L110.3 156.5C110.3 156.5 137.4 132.8 140.1 128.6C142.7 124.4 148.9 99.6001 148.8 97.2001C148.7 95.6001 119.2 70.7001 118.4 69.6001C117.6 68.6001 103.3 82.8001 127 99.3001C126.8 101.3 126.8 110.3 126.8 110.3C126.8 110.3 110.3 116 108.6 117.2C106.9 118.4 103.7 127 103.2 129C101 128.4 67.1 121.6 67.1 121.6Z" fill="#F5D0AE" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="203" height="152" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Mobile;
