/* eslint camelcase: 0 */

import Immutable from 'immutable';

export function hideMenuItemForm(defaultHash, $$state) {
  return $$state.withMutations(state => {
    state
      .merge(defaultHash)
      .set('selectedMenuItem', Immutable.fromJS({}))
      .set('selectedCartVariation', Immutable.fromJS({}))
      .merge({ showMenuItemForm: false });
  });
}

export function fetchSuccess(response, defaultHash, $$state) {
  const { menu_item, segment_track } = response;

  // DEFAULT CART VARIATION
  const cartVariation = {
    cart_variation_option_choices: [],
    cart_variation_item_add_on_options: [],
    cart_variation_service_add_on_options: [],
  };

  return $$state.withMutations(state => {
    state
      .merge(defaultHash)
      .set('selectedMenuItem', Immutable.fromJS(menu_item))
      .set('selectedCartVariation', Immutable.fromJS(cartVariation))
      .set('segmentTrack', Immutable.fromJS(segment_track))
      .merge({ showMenuItemForm: true });
  });
}
