/* eslint-disable react/no-array-index-key */
import React from 'react';
import dayjs from 'dayjs';
import { array, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { Card } from '../../../styles';
import { Stack, TableWrapper } from '../../../styles';
import { Money } from '../../Money';
import { PROGRAM_COLORS } from '../../utils';
import { BudgetBar } from '../../BudgetBar';
import { Percentage } from '../../Percentage';

export const Summary = withI18n(({ summary, data, translate }) => {
  const showTotal = data.length > 1;

  return (
    <Card
      style={{ borderLeft: `8px solid ${PROGRAM_COLORS.MealPlan}` }}
      data-testid="reporting-meal-plans-summary"
    >
      <Stack>
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          {translate('financialReportsProgramNamesMealPlan')}
        </div>
        {summary.totalBudget > 0 && (
          <BudgetBar
            total={summary.totalBudget}
            spend={summary.budgetSpend}
            color={PROGRAM_COLORS.MealPlan}
          />
        )}
        <TableWrapper fixedFirstCellWidth={180}>
          <table className="table">
            <thead>
              <tr>
                <th />
                {data.map(({ step }) => (
                  <th key={step}>{dayjs(step, 'YYYY-MM').format("MMM'YY")}</th>
                ))}
                {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsBudget')}
                </td>
                {data.map(({ totalBudget }, i) => (
                  <td className="font-weight-bold" key={i}>
                    <Money value={totalBudget} />
                  </td>
                ))}
                {showTotal && (
                  <td className="font-weight-bold">
                    <Money value={summary.totalBudget} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsSpend')}
                </td>
                {data.map(({ totalSpend, budgetSpendPercentage }, i) => (
                  <td className="font-weight-bold" key={i}>
                    <Money value={totalSpend} />
                    <br />
                    <Percentage value={budgetSpendPercentage} />
                  </td>
                ))}
                {showTotal && (
                  <td className="font-weight-bold">
                    <Money value={summary.totalSpend} />
                    <br />
                    <Percentage value={summary.budgetSpendPercentage} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsUnused')}
                </td>
                {data.map(({ budgetUnused, budgetUnusedPercentage }, i) => (
                  <td key={i}>
                    <Money value={budgetUnused} />
                    <br />
                    <Percentage value={budgetUnusedPercentage} />
                  </td>
                ))}
                {showTotal && (
                  <td className="font-weight-bold">
                    <Money value={summary.budgetUnused} />
                    <br />
                    <Percentage value={summary.budgetUnusedPercentage} />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </Stack>
    </Card>
  );
});

Summary.propTypes = {
  summary: object.isRequired,
  data: array.isRequired,
};
