import React from 'react';
import PropTypes from 'prop-types';
import CircleCheckIcon from 'libs/components/Icons/CircleCheck';

export function SubmittedSuccessfullyStep({ vendorName, logDate, hasMissingItem }) {
  return (
    <div>
      <div className="pt-3">
        <CircleCheckIcon color="#7D9E1D" size={48} />
      </div>
      <div className="my-3">
        <h3 className="mt-2">Step Completed</h3>
        { hasMissingItem && <p className="mt-2">The missing items have been reported to the CaterSpot team.</p> }
        <p className="mt-2 text-muted">
          Delivery report for {vendorName} submitted successfully for {logDate}
        </p>
      </div>
    </div>
  );
}
SubmittedSuccessfullyStep.propTypes = {
  vendorName: PropTypes.string.isRequired,
  logDate: PropTypes.string.isRequired,
  hasMissingItem: PropTypes.bool,
};
