import _map from 'lodash/map';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Button from 'react-bootstrap/Button';
import CircleCheckIcon from 'libs/components/Icons/CircleCheck';
import { DeliveryAt, VendorNameContainer } from '../styledComponents';
import { DeliveryReportModal } from './deliveryReportModal';


export default function DeliveryReport({ pantryProgram, dailyLog, ordersForDelivery, saveDailyLog, logDate }) {
  const [showDetail, setShowDetail] = useState(false);
  const [detailOrder, setDetailOrder] = useState();

  const handleClose = () => {
    setShowDetail(false);
  };

  return (
    <div>
      <div className="bg-light p-2">
        <div className="px-2">
          <div className="text-muted">ORDERS:</div>
          <h6>{ordersForDelivery.length} deliveries expected</h6>
        </div>
      </div>
      {_map(ordersForDelivery, order => {
        const submitted = _map(_get(dailyLog, 'status.deliveryReport.data'), 'orderId').includes(order.uuid);
        return (
          <div
            key={order.uuid}
            className="p-2 border-bottom border-light"
            style={{
              backgroundColor: submitted ? '#F2F5E8' : 'white',
            }}
          >
            <div className="d-flex px-2">
              <div className="flex-grow-1">
                <VendorNameContainer>{order.vendorName}</VendorNameContainer>
                <DeliveryAt date={order.deliverAt} dateTo={order.endDeliverAt} />
                {submitted && (
                  <div className="mt-3 text-primary d-flex">
                    <CircleCheckIcon color="#7D9E1D" size={16} />
                    <span className="ml-2">Submitted</span>
                  </div>
                )}
              </div>
              <div className="align-content-center">
                {
                  !submitted && (
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={e => {
                        e.stopPropagation();
                        setDetailOrder(order);
                        setShowDetail(true);
                      }}
                    >
                      View
                    </Button>
                  )
                }
              </div>
            </div>
          </div>
        );
      })}

      <DeliveryReportModal
        pantryProgram={pantryProgram}
        dailyLog={dailyLog}
        show={showDetail}
        onHide={handleClose}
        order={detailOrder}
        saveDailyLog={saveDailyLog}
        logDate={logDate}
      />
    </div>
  );
}

DeliveryReport.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  ordersForDelivery: PropTypes.array,
  saveDailyLog: PropTypes.func,
  logDate: PropTypes.string.isRequired,
};
