/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';

export default class CheckRadioComponent extends BaseComponent {
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    htmlFor: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    setRef: PropTypes.func,
    defaultValue: PropTypes.bool,
  };

  constructor() {
    super();

    bindAll(this, 'handleOnChange');
  }

  handleOnChange(e) {
    const { onChange } = this.props;

    if (notBlank(onChange)) onChange(e);
  }

  render() {
    const {
      checked,
      disabled,
      fieldType,
      htmlFor,
      name,
      onChange,
      setRef,
      title,
      value,
      defaultChecked,
    } = this.props;

    const _disabled = notBlank(disabled) ? disabled : false;

    let params = {};

    if (notBlank(onChange)) {
      params = {
        defaultChecked,
        value,
        checked,
        onChange: this.handleOnChange,
      };
    } else {
      params = {
        defaultChecked: checked,
        defaultValue: value,
      };
    }

    return (
      <div className="custom-control custom-checkbox mb-1">
        <input
          type={fieldType}
          name={name}
          className="custom-control-input"
          disabled={_disabled}
          ref={setRef}
          id={htmlFor}
          {...params}
        />

        <label
          className="custom-control-label"
          htmlFor={htmlFor}
        >
          {title}
        </label>
      </div>
    );
  }
}
