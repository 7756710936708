/* eslint max-len: 0 */

import * as actionTypes from '../constants/mealCartConstants';

export function updateCartVariationQuantity(vendorName, menuItemUuid, value) {
  return { type: actionTypes.UPDATE_CART_VARIATION_QUANTITY, vendorName, menuItemUuid, value };
}

export function increaseCartVariationQuantity(vendorName, menuItemUuid) {
  return { type: actionTypes.INCREASE_CART_VARIATION_QUANTITY, vendorName, menuItemUuid };
}

export function decreaseCartVariationQuantity(vendorName, menuItemUuid) {
  return { type: actionTypes.DECREASE_CART_VARIATION_QUANTITY, vendorName, menuItemUuid };
}
