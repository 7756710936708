import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { StockCountModal } from './stockCountModal';

export const SUBMITTED = 'submitted';
export const DRAFT = 'draft';

export default function StockCount({
  pantryProgram, dailyLog, masterSupplyItems, logDate, saveDailyLog,
}) {
  const [showDetail, setShowDetail] = useState(false);

  const status = _get(dailyLog, 'status.stockCount.status');
  const draftTimestamp = _get(dailyLog, 'status.stockCount.draftTimestamp');
  return (
    <div>
      <div className="px-3">
        <Button
          variant={status === SUBMITTED ? 'outline-dark' : 'primary'}
          onClick={() => {
                  setShowDetail(true);
                }}
          block
          disabled={status === SUBMITTED}
          id="stock-count-step-button"
        >
          {
            status !== DRAFT && 'Start Stock Count'
          }
          {
            status === DRAFT && 'Continue Stock Count'
          }
        </Button>
        {
          status === DRAFT && (
            <div className="text-muted text-center mt-2">
              {`Draft saved ${moment.unix(draftTimestamp).format('DD/MM/YYYY, hh:mm A')}`}
            </div>
          )
        }
      </div>

      <StockCountModal
        pantryProgram={pantryProgram}
        dailyLog={dailyLog}
        masterSupplyItems={masterSupplyItems}
        show={showDetail}
        onHide={() => { setShowDetail(false); }}
        saveDailyLog={saveDailyLog}
        logDate={logDate}
      />
    </div>
  );
}

StockCount.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  masterSupplyItems: PropTypes.array.isRequired,
  saveDailyLog: PropTypes.func.isRequired,
  logDate: PropTypes.string.isRequired,
};
