import requestsManager from 'libs/middlewares/requestsManager';

/**
 * Generic API to get labels pdf link
 * GET /<API>/
 */
export const fetchPdfLink = ({ mealId, mealPlanId, type, params }, successCallback, failedCallback) => {
  const endpoint = `/meal_plans/${mealPlanId}/${type}/${mealId}`;

  return requestsManager.fetchEntities({ ...params, only_sheet: true }, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
