/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */
import humps from 'lodash-humps';

import {
  FETCH_DAILY_LOG_SUCCESS,
  FETCH_MASTER_SUPPLY_ITEMS_SUCCESS,
  FETCH_ORDER_FOR_DELIVERY_FAILURE,
  FETCH_ORDER_FOR_DELIVERY_SUCCESS,
  FETCHING_DAILY_LOG,
  FETCHING_ORDER_FOR_DELIVERY,
  SAVE_DAILY_LOG_SUCCESS,
} from '../constants/pantryProgramConstants';

export const $$initialState = {
  ordersForDelivery: [],
  masterSupplyItems: [],
  pantryProgram: {},
  dailyLog: {},
};

export default function reducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case FETCHING_ORDER_FOR_DELIVERY: {
      break;
    }
    case FETCH_ORDER_FOR_DELIVERY_SUCCESS: {
      $$state.ordersForDelivery = humps(response.orders_for_delivery);
      break;
    }
    case FETCH_MASTER_SUPPLY_ITEMS_SUCCESS: {
      $$state.masterSupplyItems = humps(response.master_supply_items);
      break;
    }
    case FETCH_ORDER_FOR_DELIVERY_FAILURE: {
      break;
    }
    case FETCHING_DAILY_LOG: {
      break;
    }
    case FETCH_DAILY_LOG_SUCCESS: {
      if (response.daily_logs.length > 0) {
        $$state.dailyLog = humps(response.daily_logs[0]);
      } else {
        $$state.dailyLog = {};
      }
      break;
    }
    case SAVE_DAILY_LOG_SUCCESS: {
      $$state.dailyLog = humps(response.daily_log);
      break;
    }
    default: {
      break;
    }
  }
  return { ...$$state };
}
