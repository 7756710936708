/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import { object, string, bool } from 'prop-types';
import { notBlank } from 'libs/support/string';
import IMAGE_PLACEHOLDER from 'assets/images/img-placeholder.png';

const SIZE_PLACEHOLDER = 4;

const CartCategoriesSkeletal = () => Array.from(new Array(SIZE_PLACEHOLDER)).map((_, index) => (
  <div
    key={index}
    className="media tw-flex !tw-items-center tw-gap-4 tw-pb-2 tw-cursor-pointer tw-animate-pulse"
  >
    <div className="card-thumbnail">
      <div className={classNames('tw-bg-gray-50 tw-rounded', {
        'tw-h-12 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
      })}
      />
    </div>
    <div className="media-body">
      <div
        className={classNames('tw-mb-4 tw-flex tw-flex-col tw-gap-1 tw-h-8')}
      >
        <span className={classNames('tw-bg-gray-100 tw-rounded', {
          'tw-h-4 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
        })}
        />
        <span className={classNames('tw-bg-gray-100 tw-rounded', {
          'tw-h-3 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
        })}
        />
      </div>
    </div>
  </div>
));

const CartCategoryItems = ({
  categories,
  mealId,
  mealPlanId,
  isLoading,
}) => {
  if (isLoading) return <CartCategoriesSkeletal />;

  if (!categories) return null;

  return categories.map((category) => {
    const path = `/meal_plans/${mealPlanId}/meals/${mealId}#${category.get('vendorId')}`;

    const handleClick = (e) => {
      e.preventDefault();
      window.location.href = path;
    };

    return (
      <div
        className="media tw-flex !tw-items-center tw-gap-4 tw-pb-2 tw-cursor-pointer"
        key={`category-${category.get('vendorName')}`}
        onClick={handleClick}
      >
        <div className="card-thumbnail">
          {
            notBlank(category.get('vendorLogo')) ?
              <object
                className="tw-w-14 tw-h-14 tw-object-contain tw-bg-gray-50 tw-rounded"
                data={category.get('vendorLogo')}
              >
                <img className="tw-rounded-md" alt="" src={IMAGE_PLACEHOLDER} />
              </object> :
              <div className={classNames('tw-bg-gray-50 tw-rounded', {
                'tw-h-12 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
              })}
              />
          }
        </div>
        <div className="media-body">
          <div className="tw-flex tw-items-center tw-gap-1">
            <span className="!tw-m-0 tw-text-gray-900 tw-text-base tw-font-bold">
              {category.get('vendorName')}
            </span>
          </div>
        </div>
      </div>
    );
  });
};

CartCategoryItems.propTypes = {
  categories: object,
  mealId: string,
  mealPlanId: string,
  isLoading: bool,
};

export default CartCategoryItems;
