export function notEmpty(str) {
  return str !== null && str !== undefined;
}

export function notBlank(str) {
  return str !== null && str !== undefined && str !== '';
}

export function blankish(str) {
  return str === null || str === undefined || str === '';
}

export function truncate(str, num, omission) {
  const om = (blankish(omission)) ? '...' : omission;

  if (num >= str.length) return str;

  str.slice(num);
  return `${str}${om}`;
}

export function parameterize(str) {
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleize(string) {
  return string.split('_').map(capitalize).join(' ');
}
