/* eslint-disable max-len */
import React from 'react';

const GlutenFree = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#7D9E1D"
    />
    <path
      d="M9.45038 7.444C6.94238 7.444 5.15438 9.364 5.15438 11.8C5.15438 14.404 7.03838 16.156 9.42638 16.156C11.0224 16.156 12.0904 15.652 12.9904 14.812V11.608H10.0024V12.748H11.7304V14.164C11.5144 14.392 10.7824 14.92 9.49838 14.92C7.80638 14.92 6.54638 13.66 6.54638 11.788C6.54638 10.024 7.71038 8.68 9.49838 8.68C10.7584 8.68 11.4904 9.304 11.8144 9.628L12.6544 8.752C12.2224 8.272 11.2264 7.444 9.45038 7.444ZM14.5166 16H15.8486V12.424H18.3086V11.236H15.8486V8.8H18.6566V7.6H14.5166V16Z"
      fill="white"
    />
  </svg>
);

export default GlutenFree;
