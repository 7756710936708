import requestsManager from 'libs/middlewares/requestsManager';

/**
 * API to fetch User Credit Cards
 * GET /meal_plans/:meal_plan_id/user_credit_cards
 */
export const fetchAdminMealCart = (mealPlanId, mealId, successCallback, failedCallback) => {
  const endpoint = `/meal_plans/${mealPlanId}/admin_meal_cart`;
  return requestsManager.fetchEntities({ meal_id: mealId }, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
