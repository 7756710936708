import * as userMealCartConstants from 'packs/meals/constants/userMealCartConstants';

import * as menuItemConstants from 'packs/common/constants/menuItemConstants';
import * as cartConstants from 'packs/common/constants/cartConstants';
import * as cartVariationsConstants from 'packs/common/constants/cartVariationsConstants';
import * as flashConstants from './flashConstants';
import * as cartUsersConstants from './cartUsersConstants';
import * as customMenuItemConstants from './customMenuItemConstants';
import * as mealCartConstants from './mealCartConstants';
import * as mealConstants from './mealConstants';
import * as mealSelectionConstants from './mealSelectionConstants';

export default {
  // Meal
  ...flashConstants,
  ...cartUsersConstants,
  ...customMenuItemConstants,
  ...mealCartConstants,
  ...mealConstants,
  ...mealSelectionConstants,

  // Common
  ...cartConstants,
  ...cartVariationsConstants,
  ...menuItemConstants,

  // UserMealCart
  ...userMealCartConstants,
};
