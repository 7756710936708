import React from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseCircle from 'libs/components/Icons/CloseCircle';
import _filter from 'lodash/filter';
import UploadButton from '../uploadButton';

const OverlayIconContainer = styled.div`
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
`;

export default function PhotoUploadContainer({ header, photos, setPhotos, order = {} }) {
  return (
    <div>
      <h6>{header}</h6>
      <p>Indicate which level the picture is taken in the description.</p>
      <div className="d-flex">
        <UploadButton setPhotos={(files) => {
          setPhotos([...photos, ..._map(files, f => ({ preview: f.file.preview, hash: f.hash, orderId: order.uuid }))]);
        }}
        />
      </div>
      <div className="mt-3">
        {
          _map(photos, p => (
            <div className="d-flex mt-2" key={p.hash}>
              <div className="position-relative" style={{ height: '150px', width: '150px' }}>
                <OverlayIconContainer
                  className="position-absolute"
                  onClick={() => { setPhotos(_filter(photos, photo => photo.hash !== p.hash)); }}
                >
                  <CloseCircle />
                </OverlayIconContainer>
                <img src={p.url ? p.url : _get(p, 'preview')} className="img-thumbnail h-100" alt="" />
              </div>
              <textarea
                className="flex-grow-1 ml-1 form-control"
                placeholder="Photo description (optional)"
                rows={3}
                onChange={(event) => {
                  // eslint-disable-next-line no-param-reassign
                  p.caption = event.target.value;
                  setPhotos([...photos]);
              }}
              />
            </div>))
        }
      </div>
    </div>
  );
}

PhotoUploadContainer.propTypes = {
  header: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    preview: PropTypes.string,
    hash: PropTypes.string.isRequired,
    caption: PropTypes.string,
    orderId: PropTypes.string.isRequired,
  })).isRequired,
  setPhotos: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};
