import requestsManager from 'libs/middlewares/requestsManager';

/**
 * API to fetch User Credit Cards
 * GET /meal_plans/:meal_plan_id/user_credit_cards
 */
export const fetchUserCreditCards = (mealPlanId, successCallback, failedCallback) => {
  const endpoint = `/meal_plans/${mealPlanId}/user_credit_cards`;

  return requestsManager.fetchEntities(null, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};

/**
 * API to Create User Credit Card
 * POST /meal_plans/:meal_plan_id/user_credit_cards
 */
export const createUserCreditCard = (mealPlanId, params, successCallback, failedCallback) => {
  const endpoint = `/meal_plans/${mealPlanId}/user_credit_cards`;

  return requestsManager.createEntity(params, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
