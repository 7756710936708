import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

import infoIcon from 'assets/images/info-tooltip-icon.png';

class MenuItemFormIncludedItems extends BaseComponent {
  static propTypes = {
    includedItems: PropTypes.object,
    intl: intlShape.isRequired,
  };

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    const { includedItems, intl } = this.props;
    const { formatMessage } = intl;

    if (includedItems.size < 1) return null;

    return (
      <div className="row">
        <div className="col-md-12">
          <p className="more-details-headline">
            {formatMessage(defaultMessages.cartVariationsCartVariationWhatsIncluded)}
          </p>

          <ul className="more-details-included">
            {
              includedItems.map(item => {
                const description = item.get('description');

                return (
                  <li key={`included-item-${item.get('uuid')}`}>
                    {item.get('name')}
                      &nbsp;

                    {
                      (notBlank(description)) &&
                        <img
                          src={infoIcon}
                          width="13"
                          height="auto"
                          data-toggle="tooltip"
                          data-placement="right"
                          data-title={description}
                          alt={description}
                        />
                    }
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default injectIntl(MenuItemFormIncludedItems);
