/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SAVE_DAILY_LOG_FAILURE } from '../../../constants/pantryProgramConstants';
import SubmittedSuccessfullyStep from './submittedSuccessfullyStep';
import PhotosFeedbackStep from './photosFeedbackStep';

const PHOTOS_FEEDBACK = 'PHOTOS_FEEDBACK';
const SUBMIT_SUCCESSFULLY = 'SUBMIT_SUCCESSFULLY';

export function PhotosFeedbackModal({
  pantryProgram,
  dailyLog,
  show,
  onHide,
  saveDailyLog,
  logDate,
}) {
  const [error, setError] = useState('');
  const [step, setStep] = useState(PHOTOS_FEEDBACK);
  const [wastage, setWastage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [restockPointPhotos, setRestockPointPhotos] = useState([]);
  const [storeRoomPhotos, setStoreRoomPhotos] = useState([]);

  useEffect(() => {
    // todo move to domain objects
    setWastage(dailyLog?.wastage || '');
    setFeedback(dailyLog?.feedback || '');
    setRestockPointPhotos(dailyLog?.restockPointPhotos || []);
    setStoreRoomPhotos(dailyLog?.storeRoomPhotos || []);
  }, [dailyLog]);

  useEffect(() => {
    setError('');
  }, [step]);

  function handleOnHide() {
    setStep(PHOTOS_FEEDBACK);
    onHide();
  }

  function handleSubmit() {
    if (restockPointPhotos.length === 0) {
      setError('Please take a photo of EACH pantry restocking point.');
      return;
    }
    if (storeRoomPhotos.length === 0) {
      setError('Please take photos of the store room.');
      return;
    }

    saveDailyLog({
      ...(dailyLog?.uuid && { uuid: dailyLog.uuid }),
      status: {
        ...(dailyLog?.status),
        status: 'submitted',
      },
      restockPointPhotos,
      storeRoomPhotos,
      wastage,
      feedback,
      logDate,
    }, pantryProgram.uuid)
      .then((data) => {
        if (data.type === SAVE_DAILY_LOG_FAILURE) {
          setError(data.response.errors.join(', '));
        } else {
          setError('');
          setStep(SUBMIT_SUCCESSFULLY);
        }
      });
  }

  function handleBack() {
    switch (step) {
      case PHOTOS_FEEDBACK:
        handleOnHide();
        break;
      case SUBMIT_SUCCESSFULLY:
        setStep(PHOTOS_FEEDBACK);
        break;
      default:
        break;
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleOnHide}
      className="fullscreen-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex">
            <div>
              <Button variant="link" size="sm" onClick={handleBack} style={{ padding: 0 }}>
                <FontAwesomeIcon icon={faArrowLeft} color="#7D9E1D" fontSize={20} />
              </Button>
            </div>
            <div className="flex-grow-1 ml-4">
              {step === PHOTOS_FEEDBACK && 'Photos and Feedback'}
              {step === SUBMIT_SUCCESSFULLY && 'Submitted Successfully'}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'auto' }}>
        {
          error && (
            <div className="alert-danger px-2 py-2 mb-2">
              {error}
            </div>
          )
        }
        {step === PHOTOS_FEEDBACK && (
          <PhotosFeedbackStep
            wastage={wastage}
            setWastage={setWastage}
            feedback={feedback}
            setFeedback={setFeedback}
            pantryRestockPointPhotos={restockPointPhotos}
            setRestockPointPhotos={setRestockPointPhotos}
            storeRoomPhotos={storeRoomPhotos}
            setStoreRoomPhotos={setStoreRoomPhotos}
          />
        )}
        {step === SUBMIT_SUCCESSFULLY && (
          <SubmittedSuccessfullyStep logDate={logDate} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex-column">
          <div className="mt-2">
            {
              step === PHOTOS_FEEDBACK && (
                <Button
                  variant="warning"
                  onClick={handleSubmit}
                  block
                >
                  Submit
                </Button>
              )
            }
            {
              step === SUBMIT_SUCCESSFULLY && (
                <Button
                  variant="primary"
                  onClick={handleOnHide}
                  block
                >
                  Back to Daily Log
                </Button>
              )
            }
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

PhotosFeedbackModal.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  saveDailyLog: PropTypes.func,
  logDate: PropTypes.string.isRequired,
};
