/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withI18n } from 'libs/support/i18n';

import actions from 'packs/meal_plan_users/actions';

import MealPlanUser from './MealPlanUser';
import BulkImportModal from './BulkImportModal';
import BulkExportModal from './BulkExportModal';
import ThElement from './ThElement';

/**
 * UserList
 */

const UserList = ({
  mealPlanUsersStore,
  actions,
  translate,
}) => {
  const [isShowExportModal, setIsShowExportModal] = useState(false);
  const flash = mealPlanUsersStore.getIn(['list', 'flash']);

  return (
    <div className="card-body overflow-auto">
      { /* Succes Alert */}
      {
        flash.get('success') &&
        <div className="form-group row">
          <div className="col-12">
            <div className="alert alert-full alert-success text-center">
              {flash.get('success')}
            </div>
          </div>
        </div>
      }

      {/* Buttons */}
      <div className="responsive-actions">
        <button className="btn btn-light btn-sm" onClick={() => setIsShowExportModal(true)}>
          {translate('mealPlanUsersButtonBulkExport')}
        </button>
        <button className="btn btn-light btn-sm" onClick={actions.showBulkImportModal}>
          {translate('mealPlanUsersButtonBulkImport')}
        </button>
        <button className="btn btn-primary btn-sm" onClick={actions.showNewUserForm}>
          {translate('mealPlanUsersButtonNewUser')}
        </button>
      </div>

      <BulkExportModal
        mealPlanId={mealPlanUsersStore.get('mealPlanId')}
        sid={mealPlanUsersStore.get('currentMealPlanUserId')}
        isShow={isShowExportModal}
        onHide={() => setIsShowExportModal(false)}
      />

      <BulkImportModal
        view={mealPlanUsersStore.get('bulkImportModal')}
        mealPlanId={mealPlanUsersStore.get('mealPlanId')}
        onHide={actions.hideBulkImportModal}
        onImport={actions.importUsers}
      />

      <table className="table table-hover tw-m-0 tw-border-0">
        <thead className="tw-hidden md:tw-table-header-group">
          <tr className="tw-table-row tw-border-b tw-border-gray-200">
            <ThElement>{translate('mealPlanUsersListName')}</ThElement>
            <ThElement>{translate('mealPlanUsersListEmail')}</ThElement>
            <ThElement>{translate('mealPlanUsersListDepartment')}</ThElement>
            <ThElement>{translate('mealPlanUsersListReferenceCode')}</ThElement>
            <ThElement>{translate('mealPlanUsersListRole')}</ThElement>
            <ThElement />
          </tr>
        </thead>
        <tbody>
          {/* MealPlanUser */}
          {
            mealPlanUsersStore.get('byId').valueSeq().sortBy((f) => f.get('fullName').toLowerCase()).map((mpu) => (
              <MealPlanUser mealPlanUser={mpu} key={`list-${mpu.get('uuid')}`} />
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

UserList.propTypes = {
  mealPlanUsersStore: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mealPlanUsersStore: state.$$mealPlanUsersStore,
  fflagsStore: state.$$fflagsStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(UserList));
