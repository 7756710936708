/* eslint import/prefer-default-export: 0, no-undef: 0, no-unused-vars: 0 */

import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity/dist/flickity.pkgd';
import { initializeFluidTabs } from 'libs/support/fluidTabs';
import { initializeSegment, processSegment } from 'libs/support/segment';

export function setupTurboListeners() {
  document.addEventListener('turbolinks:before-visit', (e) => {
    const { url } = e.data;
    const { location } = e.currentTarget;
    const id = location.href.split('/');
    const isEditing = window.localStorage.getItem(`editing-${id[id.length - 1]}`);
    if (isEditing === 'true') {
      e.preventDefault();
      document.dispatchEvent(new CustomEvent('before-visit', { detail: { url } }));
    }
  });

  document.addEventListener('turbolinks:before-fetch-response', $.noop);

  document.addEventListener('turbolinks:after-visit', $.noop);

  document.addEventListener('turbolinks:before-fetch-response', $.noop);

  document.addEventListener('turbolinks:load', () => {
    ReactOnRails.reactOnRailsPageLoaded();
    initializeSegment();
    processSegment();
  });
}

export function initialize() {
  $(document).ready(() => {
    jQueryBridget('flickity', Flickity, $);

    initializeFluidTabs();
  });
}
