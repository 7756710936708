/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { useReport } from '../../../queries/use-report';
import { Stack } from '../../../styles';
import { Summary } from './Summary';
import { SpendingTrend } from './SpendingTrend';
import { MealPlan } from './MealPlan';
import { MEAL_PLAN_COLORS, PROGRAM_COLORS } from '../../utils';
import { SectionDivider } from '../../SectionDivider';
import { OrdersCountTrend } from './OrdersCountTrend';
import { DailyCostTrend } from './DailyCostTrend';
import { trackViewedMealPlansTab } from '../../../events';

export const MealPlans = withI18n(({ organisation, filters, translate }) => {
  const { data: summary } = useReport(
    organisation.uuid,
    'meal_plans/meal_plan_summary',
    filters,
  );

  const { data: mealPlans } = useReport(
    organisation.uuid,
    'meal_plans/meal_plans',
    filters,
  );

  const filterMealPlans = (mealPlans) => mealPlans.filter((mealPlan) => mealPlan.id !== 'total');

  const assignColorToMealPlans = (mealPlans) => {
    const colors = [...MEAL_PLAN_COLORS];

    return mealPlans.map((mealPlan) => {
      if (mealPlan.id === 'total') {
        return { ...mealPlan, color: PROGRAM_COLORS.Total };
      }
      return { ...mealPlan, color: colors.shift() };
    });
  };

  useEffect(() => trackViewedMealPlansTab(), []);

  return (
    <Stack className="p-3" data-testid="reporting-meal-plans">
      {summary && mealPlans && (
        <Summary
          summary={summary}
          data={mealPlans.find((mealPlan) => mealPlan.id === 'total').data}
        />
      )}
      <SectionDivider text={translate('financialReportsTermsMealPlanBreakdown')} />
      {summary && mealPlans && (
        <SpendingTrend
          summary={summary}
          mealPlans={assignColorToMealPlans(mealPlans)}
        />
      )}
      {mealPlans &&
        filterMealPlans(assignColorToMealPlans(mealPlans)).map((mealPlan) => (
          <MealPlan key={mealPlan.id} mealPlan={mealPlan} />
        ))}
      <SectionDivider text={translate('financialReportsTermsOrders')} />
      {mealPlans && <OrdersCountTrend mealPlans={mealPlans} />}
      <SectionDivider text={translate('financialReportsDailyCostTitle')} />
      {mealPlans && <DailyCostTrend mealPlans={assignColorToMealPlans(mealPlans)} />}
    </Stack>
  );
});

MealPlans.propTypes = {
  organisation: object.isRequired,
  filters: object.isRequired,
};
