/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint react/no-danger: 0 */

import React, { useState, useEffect } from 'react';
import { any, bool, func, number, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';
import humps from 'lodash-humps';
import moment from 'moment';

import { withI18n } from 'libs/support/i18n';
import { formattedPrice } from 'libs/support/formattedPrice';
import { notBlank } from 'libs/support/string';
import { prevent } from 'libs/support/prevent';
import { BackIcon, PersonIcon } from 'libs/components/Icons';
import { fetchAdminMealCart } from 'libs/services/adminMealCartsApi';
import { ThreeDotsIcon } from 'libs/components/Icons';
import Alert from 'react-bootstrap/Alert';

import { fetchPdfLink } from 'libs/services/labelsApi';
import { createMealReport } from 'libs/services/mealReportsApi';

import GroupOrderActionsDropdown from 'packs/common/components/GroupOrderActionsDropdown';
import AmountField from 'packs/meals/components/Shared/AmountField';
import {
  fetchMealStatus,
  toggleShowEditOrderModal,
  setSearchOrderModal,
  toggleShowOrderForm,
  checkAddOnValidityOrderForm,
  setCartVariation,
  resetCartVariation,
  getMealPlanUsers,
  saveCartVariationOrderForm,
  fetchCartVariation,
  deleteCartVariation,
  toggleShowSelectUserForm,
} from 'packs/meals/actions/mealActions';
import { fetchMenuItem, resetSelectedMenuItem } from 'packs/meals/actions/menuItemActions';
import MenuItemForm from 'packs/common/components/MenuItem/MenuItemForm/MenuItemForm';

import MealCartItem from './MealCartItem';
import SubsidyCard from './SubsidyCard';
import MealCartItemModal from './MealCartItemModal/MealCartItemModal';
/**
 * AdminMealCart
 */
const AdminMealCart = ({
  actions,
  adminMealCart,
  allowSelfCheckout,
  isConfirmed,
  isInPreview,
  closeModal,
  mealAdmin,
  mealId,
  mealPlanId,
  mealPlanUserId,
  mealSelectedCount,
  mealUserCount,
  orderables,
  subsidyAmount,
  version,
  translate,
  showModalEditOrder,
  searchOrderModal,
  showOrderForm,
  menuItemsByVendorId,
  showMenuItemForm,
  selectedCartVariation,
  selectedMenuItem,
  showUserSelectionForm,
  mealPlanUsers,
  cartsByVendorId,
  isSavingCartVariation,
  newAddedCartVariationId,
  saveErrors,
  mealOpen,
  menuItemsById,
  selectedCartUser,
  showUserSelectionFormOnly,
  vendorsById,
}) => {
  const [viewState, setViewState] = useState({ adminMealCart, loading: true });

  const [state, setState] = useState({
    isProcessing: false,
    downloadableLink: null,
    errorMsg: null,
  });

  const handleOnClickDownloadExcel = () => {
    if (state.isProcessing) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    const params = {
      meal_report: {
        meal_plan_user_id: mealPlanUserId,
        meal_id: mealId,
      },
    };

    createMealReport(
      params,
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  const handleOnClickDownloadMealLabelPdf = (evt) => {
    if (state.isProcessing) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    evt.preventDefault();

    const type = 'meal_labels';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  const handleOnClickDownloadMenuCardPdf = (evt) => {
    if (state.isProcessing) return;

    setState((prev) => ({
      ...prev,
      isProcessing: true,
      downloadableLink: null,
      errorMsg: null,
    }));

    evt.preventDefault();

    fetchPdfLink(
      {
        mealId,
        mealPlanId,
        params: { tz: moment.tz.guess() },
        type: 'menu_cards',
      },
      ({ link }) => {
        setState((prev) => ({
          ...prev,
          isProcessing: false,
          downloadableLink: link,
        }));
      },
      (errorMsg) => {
        setState((prev) => ({
          ...prev,
          isProcessing: false,
          errorMsg,
        }));
      },
    );
  };

  const buildSuccessMsg = () => {
    if (state.isProcessing) {
      return 'Processing...';
    } else if (state.downloadableLink) {
      return `Download <a href='${state.downloadableLink}'>here</a>`;
    }
    return '';
  };

  const getAdminMealCart = () => {
    setViewState({ ...viewState, loading: true });
    fetchAdminMealCart(
      mealPlanId,
      mealId,
      (data) => {
        if (!data.admin_meal_cart) return;

        setViewState({ ...viewState, adminMealCart: Immutable.fromJS(humps(data.admin_meal_cart)), loading: false });
      },
    );
  };

  const onCloseModal = (e) => {
    e.preventDefault();
    closeModal(e);
    if ($('.modal-backdrop').length) {
      $('.modal-backdrop').remove();
    }
  };

  useEffect(() => {
    getAdminMealCart();
    actions.getMealPlanUsers(mealPlanId);
  }, [version]);

  const orders = isConfirmed ? orderables : Immutable.fromJS([]);
  const successMsg = buildSuccessMsg();
  const mergedVariations = viewState.loading ? [] :
    viewState
      .adminMealCart
      .get('cartVariations')
      .merge(viewState.adminMealCart.get('orderVariations'));

  const catererId = selectedMenuItem.getIn(['vendor_id']);
  const cartId = cartsByVendorId.getIn([catererId, 'cartId']);

  const vendorSetting = vendorsById.find((item) => item.get('uuid') == catererId)?.get('vendorSetting');

  const handleUpdateSubmit = async (id, cartVariation) => {
    const newCartVariation = cartVariation;
    newCartVariation.cart_id = cartId;
    newCartVariation.admin = true;

    let params = {
      cart_id: cartId,
      meal_id: mealId,
      cart_user_id: selectedCartUser.get('meal_plan_user_id'),
      cart_variation: newCartVariation,
      admin: mealAdmin,
    };

    if (cartVariation.cart_user_is_guest) {
      params = Object.assign({}, params, {
        is_guest: true,
        guest_name: cartVariation.cart_user_name,
        cart_user_id: selectedCartUser.get('uuid'),
      });
    }

    await actions.saveCartVariationOrderForm(mealPlanId, id, params);
    await getAdminMealCart();

    if ($('.modal-backdrop').length > 1) {
      $('.modal-backdrop').not(':last').remove();
    }
  };

  if (isInPreview) return null;

  return (
    <div className="card card-cart admin-cart" id="admin-card">
      { /* Header Section */ }
      <div className="card-header d-flex flex-row justify-content-between align-items-center flex-wrap">
        <h6 className="m-0 d-flex align-items-center">
          <span
            className="mr-2 d-lg-none back-icon"
            onClick={onCloseModal}
          >
            <BackIcon />
          </span>
          { translate('adminMealCartsTermsAllOrders') }
        </h6>

        <span className="d-flex align-items-center">
          <button
            className="btn btn-link"
            onClick={() => prevent(actions.fetchMealStatus(mealPlanId, mealId))}
          >
            <span className="mr-2">
              <PersonIcon />
            </span>
            <span className="mr-2">
              { translate('adminMealCartsTermsHaveOrdered', { count: mealSelectedCount, total: mealUserCount })}
            </span>
            <span>{ '>' }</span>
          </button>

          <GroupOrderActionsDropdown
            btnClassName="btn-light caret-off d-flex justify-content-center align-items-center"
            btnStyle={{ width: '40px' }}
            className="ml-2"
            mealPlanId={mealPlanId}
            mealId={mealId}
            onClickDownloadExcel={handleOnClickDownloadExcel}
            onClickDownloadMealLabelPdf={handleOnClickDownloadMealLabelPdf}
            onClickDownloadMenuCardPdf={handleOnClickDownloadMenuCardPdf}
            orders={orders}
            toggleShowEditOrderModal={actions.toggleShowEditOrderModal}
            mealOpen={mealOpen}
            mealAdmin={mealAdmin}
          >
            <ThreeDotsIcon />
          </GroupOrderActionsDropdown>
        </span>
      </div>
      <MealCartItemModal
        items={mergedVariations}
        show={showModalEditOrder}
        searchText={searchOrderModal}
        onHide={actions.toggleShowEditOrderModal}
        onSearch={actions.setSearchOrderModal}
        showOrderForm={showOrderForm}
        toggleShowOrderForm={actions.toggleShowOrderForm}
        menuItemsByVendorId={menuItemsByVendorId}
        menuItemsById={menuItemsById}
        orderables={orderables}
        selectedMenuItem={selectedMenuItem}
        fetchMenuItem={actions.fetchMenuItem}
        setCartVariation={actions.setCartVariation}
        showUserSelectionForm={showUserSelectionForm}
        selectedCartVariation={selectedCartVariation}
        resetCartVariation={actions.resetCartVariation}
        mealPlanUsers={mealPlanUsers}
        saveCartVariationOrderForm={actions.saveCartVariationOrderForm}
        mealPlanId={mealPlanId}
        cartId={cartId}
        mealPlanUserId={mealPlanUserId}
        mealId={mealId}
        isSavingCartVariation={isSavingCartVariation}
        newAddedCartVariationId={newAddedCartVariationId}
        errors={saveErrors}
        fetchCartVariation={actions.fetchCartVariation}
        deleteCartVariation={actions.deleteCartVariation}
        toggleShowSelectUserForm={actions.toggleShowSelectUserForm}
        mealAdmin={mealAdmin}
        showUserSelectionFormOnly={showUserSelectionFormOnly}
        getAdminMealCart={getAdminMealCart}
      />
      {showMenuItemForm && (
        <div className="menuitem-form">
          <MenuItemForm
            mealAdmin={mealAdmin}
            menuItem={selectedMenuItem}
            cartVariation={selectedCartVariation}
            mealId={mealId}
            mealPlanId={mealPlanId}
            mealPlanUserId={mealPlanUserId}
            hide={!showMenuItemForm}
            hideSpecialInstruction={vendorSetting?.get('hide_special_instruction')}
            hideAddOns={vendorSetting?.get('hide_add_ons')}
            showPrice
            onDeleteItemAddOnOptionPhoto={() => {}}
            onDropItemAddOnOptionPhoto={() => {}}
            onDropItemAddOnOptionPhotoFailure={() => {}}
            onSelectVariation={() => {}}
            onSubmit={(_mealPlanId, id, params) => {
              if (id) {
                handleUpdateSubmit(id, params.cart_variation);
              } else {
                actions.setCartVariation(params.cart_variation);
              }
            }}
            onUpdateItemAddOnOptionText={() => {}}
            onClickOption={actions.checkAddOnValidityOrderForm}
            saveErrors={saveErrors}
            isEditable
            isGroupOrder
            cartVariationBackButton
            onClickCartVariationBackButton={actions.resetSelectedMenuItem}
          />
        </div>
      )}

      <div className="card-body">
        {
          successMsg &&
            <Alert variant="success" className="text-center m-0">
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
            </Alert>
        }
      </div>

      <div className="card-body card-body-grey">
        <div className="row">
          <div className="col-4 text-uppercase">
            { translate('adminMealCartsTermsName') }
          </div>

          <div className="col-8 text-uppercase text-right">
            { translate('adminMealCartsTermsMenuPrice') }
          </div>
        </div>
      </div>

      { /* Line Items Section */ }
      <div className="card-body card-body-fixed !tw-max-h-[65vh] md:!tw-max-h-80">
        <div className="cart-order-list tw-w-full">
          {
            viewState.loading ?
              <span className="tw-text-center tw-text-base tw-mx-auto">Loading ... </span> :
              mergedVariations
              .groupBy(item => item.get('vendorName'))
              .valueSeq()
              .map((orderableVariations) => {
                const vendorName = orderableVariations.getIn([0, 'vendorName']);
                return (
                  <MealCartItem
                    key={`mealCartItem-${vendorName}`}
                    vendorName={vendorName}
                    orderableVariations={orderableVariations}
                  />
                );
              })
          }
        </div>
      </div>

      { /* Total Section */ }
      <div className="card-footer">
        <div className="row">
          <AmountField
            title={translate('adminMealCartsTermsSubtotal')}
            value={formattedPrice(viewState.adminMealCart.get('totalOrderablesSubtotalAmount'))}
          />

          {
            viewState.adminMealCart.get('totalOrderablesSmallOrderFee') > 0 &&
            <AmountField
              title={translate('adminMealCartsTermsSmallOrderFee')}
              value={formattedPrice(viewState.adminMealCart.get('totalOrderablesSmallOrderFee'))}
            />
          }

          <AmountField
            title={translate('adminMealCartsTermsDeliveryFee')}
            value={formattedPrice(viewState.adminMealCart.get('totalOrderablesDeliveryAmount'))}
          />

          <AmountField
            title={translate('adminMealCartsTermsSurcharge', { names: viewState.adminMealCart.get('orderablesSurchargesList') })}
            value={formattedPrice(viewState.adminMealCart.get('totalOrderablesSurchargesAmount'))}
            tooltipContent={viewState.adminMealCart.get('totalOrderablesSurchargesList')}
            tooltipClassName="surcharge-tooltip"
            tooltip={notBlank(viewState.adminMealCart.get('totalOrderablesSurchargesList'))}
          />

          <AmountField
            title={translate('adminMealCartsTermsDiscount')}
            value={formattedPrice(viewState.adminMealCart.get('totalOrderablesDiscountAmount'))}
          />

          <AmountField
            title={viewState.adminMealCart.get('totalOrderablesTaxAmount') > 0 ? translate('adminMealCartsTermsTotalIncludeTax') : translate('adminMealCartsTermsTotalExcludeTax')}
            value={formattedPrice(viewState.adminMealCart.get('totalOrderablesTotalAmount'))}
            className="total-price"
          />
        </div>
      </div>

      { /* Subsidy Section */ }
      {
        allowSelfCheckout &&
        <SubsidyCard
          subsidyAmount={subsidyAmount}
          totalPaidByUsers={viewState.adminMealCart.get('totalPaidByUsers')}
          totalPaidByCompany={viewState.adminMealCart.get('totalPaidByCompany')}
        />
      }
    </div>
  );
};

AdminMealCart.propTypes = {
  actions: shape({
    fetchMealStatus: func.isRequired,
  }),
  adminMealCart: object.isRequired,
  allowSelfCheckout: bool.isRequired,
  isConfirmed: bool,
  isInPreview: bool,
  closeModal: func.isRequired,
  mealId: string.isRequired,
  mealPlanId: string.isRequired,
  mealPlanUserId: string,
  mealSelectedCount: number.isRequired,
  mealUserCount: number.isRequired,
  orderables: object,
  subsidyAmount: any,
  version: number.isRequired,
  translate: func.isRequired,
  mealAdmin: any,
  showModalEditOrder: any,
  searchOrderModal: any,
  showOrderForm: any,
  menuItemsByVendorId: any,
  showMenuItemForm: any,
  selectedCartVariation: any,
  selectedMenuItem: any,
  showUserSelectionForm: any,
  mealPlanUsers: any,
  cartsByVendorId: any,
  isSavingCartVariation: any,
  newAddedCartVariationId: any,
  saveErrors: any,
  mealOpen: any,
  menuItemsById: any,
  selectedCartUser: any,
  showUserSelectionFormOnly: any,
  vendorsById: object,
};

const mapStateToProps = (state) => ({
  adminMealCart: state.$$adminMealCartStore,
  allowSelfCheckout: state.$$mealStore.getIn(['groupOrderSetting', 'allowSelfCheckout']),
  isConfirmed: state.$$mealStore.get('confirmed'),
  isInPreview: state.$$mealStore.get('preview'),
  mealId: state.$$mealStore.get('uuid'),
  mealPlanId: state.$$mealStore.get('mealPlanId'),
  mealPlanUserId: state.$$mealStore.get('mealPlanUserId'),
  mealSelectedCount: state.$$mealStore.get('mealSelectedCount'),
  mealUserCount: state.$$mealStore.get('mealUserCount'),
  orderables: state.$$mealStore.get('orderables'),
  subsidyAmount: state.$$mealStore.getIn(['groupOrderSetting', 'subsidyAmount']),
  version: state.$$adminMealCartStore.get('version'),
  showModalEditOrder: state.$$mealStore.get('showModalEditOrder'),
  searchOrderModal: state.$$mealStore.get('searchOrderModal'),
  showOrderForm: state.$$mealStore.get('showOrderForm'),
  menuItemsByVendorId: state.$$menuItemsStore.get('byVendorId'),
  menuItemsById: state.$$menuItemsStore.get('byId'),
  showMenuItemForm: state.$$mealStore.get('showMenuItemForm'),
  selectedMenuItem: state.$$mealStore.get('selectedMenuItem'),
  selectedCartVariation: state.$$mealStore.get('selectedCartVariation'),
  showUserSelectionForm: state.$$mealStore.get('showUserSelectionForm'),
  mealPlanUsers: state.$$mealStore.get('mealPlanUsers'),
  cartsByVendorId: state.$$cartsStore.get('byVendorId'),
  isSavingCartVariation: state.$$mealStore.get('isSavingCartVariation'),
  newAddedCartVariationId: state.$$mealStore.get('newAddedCartVariationId'),
  saveErrors: state.$$mealStore.get('saveErrors') || Immutable.fromJS([]),
  mealOpen: state.$$mealStore.get('open'),
  selectedCartUser: state.$$mealStore.get('selectedCartUser'),
  showUserSelectionFormOnly: state.$$mealStore.get('showUserSelectionFormOnly'),
  vendorsById: state.$$vendorsStore.get('byId'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchMealStatus,
    toggleShowEditOrderModal,
    setSearchOrderModal,
    toggleShowOrderForm,
    fetchMenuItem,
    resetSelectedMenuItem,
    setCartVariation,
    checkAddOnValidityOrderForm,
    resetCartVariation,
    getMealPlanUsers,
    saveCartVariationOrderForm,
    fetchCartVariation,
    deleteCartVariation,
    toggleShowSelectUserForm,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(AdminMealCart));
