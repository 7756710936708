import React from 'react';
import MobileNav from 'packs/common/components/MobileNav';
import { IntlProvider } from 'react-intl';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

global.Intl = Intl;

const MobileNavContainer = (props) => {
  const locale = defaultLocale;
  const messages = translations[locale];

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={messages}
    >
      <MobileNav {...props} />
    </IntlProvider>
  );
};

export default MobileNavContainer;
