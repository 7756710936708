/* eslint max-len: 0 */

import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/mealPlanUsersConstants';


/*
 * Show Bulk Import modal
 */
export function showBulkImportModal() {
  return {
    type: actionTypes.SHOW_BULK_IMPORT_MODAL,
  };
}

/*
 * Hide Bulk Import modal
 */
export function hideBulkImportModal() {
  return {
    type: actionTypes.HIDE_BULK_IMPORT_MODAL,
  };
}

/*
 * Bulk Import users
 */
export function importUsers(mealPlanId, params) {
  const actions = [
    actionTypes.IMPORTING_MEAL_PLAN_USERS,
    actionTypes.IMPORT_MEAL_PLAN_USERS_SUCCESS,
    actionTypes.IMPORT_MEAL_PLAN_USERS_FAILURE,
  ];

  return api.save({
    types: actions,
    endpoint: `/meal_plans/${mealPlanId}/meal_plan_users/import`,
    params,
    id: null,
  });
}

/*
 * Show New User Form
 */
export function showNewUserForm() {
  return {
    type: actionTypes.SHOW_NEW_USER_FORM,
  };
}

/*
 * Edit User
 */
export function editUser(id) {
  return {
    type: actionTypes.EDIT_MEAL_PLAN_USER,
    response: { id },
  };
}

/*
 * Shiw User List
 */
export function showUserList() {
  return {
    type: actionTypes.SHOW_USER_LIST,
  };
}

/*
 * Save user
 */
export function saveUser(mealPlanId, id, params) {
  let actions = [];

  if (id) {
    actions = [
      actionTypes.SAVING_MEAL_PLAN_USER,
      actionTypes.SAVE_MEAL_PLAN_USER_SUCCESS,
      actionTypes.SAVE_MEAL_PLAN_USER_FAILURE,
    ];
  } else {
    actions = [
      actionTypes.CREATING_MEAL_PLAN_USER,
      actionTypes.CREATE_MEAL_PLAN_USER_SUCCESS,
      actionTypes.CREATE_MEAL_PLAN_USER_FAILURE,
    ];
  }
  // check if id is null, if null then create

  return api.save({
    types: actions,
    endpoint: `/meal_plans/${mealPlanId}/meal_plan_users`,
    params,
    id,
  });
}

/*
 * Delete user
 */
export function deleteUser(mealPlanId, id) {
  const { DELETING_MEAL_PLAN_USER, DELETE_MEAL_PLAN_USER_SUCCESS, DELETE_MEAL_PLAN_USER_FAILURE } = actionTypes;

  return api.destroy({
    types: [DELETING_MEAL_PLAN_USER, DELETE_MEAL_PLAN_USER_SUCCESS, DELETE_MEAL_PLAN_USER_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/meal_plan_users`,
    params: null,
    id,
  });
}
