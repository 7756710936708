import dayjs from 'dayjs';
import { withI18n } from 'libs/support/i18n';
import { array, object } from 'prop-types';
import React from 'react';
import { Card, Stack, TableWrapper } from '../../../styles';
import { Money } from '../../Money';
import { PROGRAM_COLORS } from '../../utils';

export const Summary = withI18n(({ summary, data, translate }) => {
  const showTotal = data.length > 1;

  return (
    <Card
      style={{ borderLeft: `8px solid ${PROGRAM_COLORS.Event}` }}
      data-testid="reporting-events-summary"
    >
      <Stack>
        <div>
          <div style={{ flex: 1, fontSize: 24, fontWeight: 600 }}>
            {`${translate('financialReportsProgramNamesEvent')} ${translate('financialReportsTermsSpend')}`}
          </div>
          <Money fontSize={48} value={summary.totalSpend} />
        </div>
        <TableWrapper fixedFirstCellWidth={180}>
          <table className="table">
            <thead>
              <tr>
                <th />
                {data.map(({ step }) => (
                  <th key={step}>{dayjs(step, 'YYYY-MM').format("MMM'YY")}</th>
                ))}
                {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsSpend')}
                </td>
                {data.map(({ totalSpend }, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td className="font-weight-bold" key={i}>
                    <Money value={totalSpend} />
                  </td>
                ))}
                {showTotal && (
                  <td>
                    <Money value={summary.totalSpend} />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </Stack>
    </Card>
  );
});

Summary.propTypes = {
  summary: object.isRequired,
  data: array.isRequired,
};
