import React from 'react';
import { Provider } from 'react-redux';

import AppContext from 'libs/support/appContext';
import GroupOrderMealCardHeadlineContainer from 'packs/meals/containers/GroupOrderMealCardHeadlineContainer';

import groupOrderMealStore from 'packs/meals/stores/groupOrderMealStore';

export default (props, railsContext) => {
  const store = groupOrderMealStore(props);

  const context = {
    fflags: railsContext.fflags,
    settings: railsContext.settings,
  };

  return (
    <AppContext.Provider value={context}>
      <Provider store={store}>
        <GroupOrderMealCardHeadlineContainer />
      </Provider>
    </AppContext.Provider>
  );
};
