/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'libs/support/i18n';

/**
 * MenuItemSelection
 */

const MenuItemSelection = ({
  cartVariation,
  translate,
}) => (
  <div className="tw-mb-2">
    <div className="tw-flex tw-items-center tw-gap-4">
      <small className="tw-uppercase tw-text-gray-400">
        {translate('menuItemsSelections')}
      </small>
    </div>

    {/* Selected Items */}
    <div>
      <div>
        {
          cartVariation.get('variation') &&
          <div>
            { cartVariation.getIn(['variation', 'name']) }
          </div>
        }
      </div>

      <div>
        {
          cartVariation.get('cart_variation_option_choices').map((option, i) =>
            (
              <div key={`menuItemSelection-${i}`}>
                { option.get('name') }
              </div>
            ))
        }
      </div>

      <div>
        {
          cartVariation.get('cart_variation_item_add_on_options').map((option, i) =>
            (
              <div key={`menuItemSelection-${i}`}>
                { option.get('name') }
              </div>
            ))
        }
      </div>

      <div>
        {
          cartVariation.get('cart_variation_service_add_on_options').map((option, i) =>
            (
              <div key={`menuItemSelection-${i}`}>
                { option.get('name') }
              </div>
            ))
        }
      </div>
    </div>
  </div>
);

MenuItemSelection.propTypes = {
  cartVariation: PropTypes.object.isRequired,
  translate: PropTypes.func,
};

export default withI18n(MenuItemSelection);
