/* eslint-disable react/no-danger */
import React from 'react';
import { object, string } from 'prop-types';
import { notBlank } from 'libs/support/string';
import Alert from 'react-bootstrap/Alert';
import MenuItem from '../../shared/MenuItem';

const CardBody = ({
  meal,
  errorMsg,
  successMsg,
}) => (
  <div className="card-body">
    {
        errorMsg &&
          <Alert variant="danger" className="text-center">
            { errorMsg }
          </Alert>
      }

    {
        successMsg &&
          <Alert variant="success" className="text-center">
            <div dangerouslySetInnerHTML={{ __html: successMsg }} />
          </Alert>
      }

    {
        meal.get('orderables').map(orderable => (
          (orderable.get('orderVariations') || orderable.get('cartVariations'))
            .filter(ov => notBlank(ov.get('menuItem')))
            .map(item => (
              <MenuItem
                key={`item-${item.get('uuid')}`}
                menuItem={item.get('menuItem')}
                vendorName={orderable.get('vendorName')}
              />
            ))
        ))
      }

    {
        meal.get('orderables').map(orderable => (
          orderable.get('customMenuItems').map(menuItem => (
            <MenuItem
              key={`item-${menuItem.get('uuid')}`}
              menuItem={menuItem}
              vendorName={orderable.get('vendorName')}
            />
          ))
        ))
      }
  </div>
);

CardBody.propTypes = {
  meal: object.isRequired,
  errorMsg: string,
  successMsg: string,
};

export default CardBody;
