/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { any } from 'prop-types';

const Skeleton = () => (
  <li
    className={classNames('tw-min-w-[120px] tw-max-w-[120px] tw-text-sm', {
      'tw-py-1.5 tw-px-2.5 tw-mr-4 tw-flex tw-items-center tw-rounded': true,
      'tw-bg-gradient-to-r tw-from-gray-200/50 tw-to-gray-300/50': true,
    })}
  >
    &nbsp;
  </li>
);

const CalendarSkeletal = ({
  isLoading,
}) => {
  if (!isLoading) return null;

  return (
    <section
      id="calendar-navigator-skeleton"
      data-test-id="calendar-navigator-skeleton"
      className="tw-relative tw-flex tw-gap-0 tw-my-4 tw-py-2 tw-mb-2 md:tw-mb-4"
    >
      <Fragment>
        <ul
          className={classNames('tw-flex tw-grow tw-overflow-hidden tw-h-[36px]', {
            'tw-overflow-x-auto tw-snap-x tw-animate-pulse': true,
            'tw-scroll-smooth tw-scrollable tw-m-0 tw-p-0': true,
          })}
        >
          {Array.from(new Array(5)).map((_, index) => (
            <Skeleton key={index} />
          ))}
        </ul>
      </Fragment>
    </section>
  );
};

CalendarSkeletal.propTypes = {
  isLoading: any,
};

export default CalendarSkeletal;
