import React from 'react';
import PropTypes from 'prop-types';

export default function MenuItemContainer({ name, photo, variationName }) {
  return (
    <div className="d-flex flex-column flex-sm-row">
      <div>
        <img src={photo} alt="" width={50} height={50} />
      </div>
      <div className="flex-grow-1 ml-sm-3">
        <div>{name}</div>
        <div className="text-muted">{variationName}</div>
      </div>
    </div>
  );
}

MenuItemContainer.propTypes = {
  name: PropTypes.string.isRequired,
  variationName: PropTypes.string.isRequired,
  photo: PropTypes.string,
};
