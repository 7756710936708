// BulkImportModal
export const SHOW_BULK_IMPORT_MODAL = 'SHOW_BULK_IMPORT_MODAL';
export const HIDE_BULK_IMPORT_MODAL = 'HIDE_BULK_IMPORT_MODAL';

// IMPORT USERS
export const IMPORTING_MEAL_PLAN_USERS = 'IMPORTING_MEAL_PLAN_USERS';
export const IMPORT_MEAL_PLAN_USERS_SUCCESS = 'IMPORT_MEAL_PLAN_USERS_SUCCESS';
export const IMPORT_MEAL_PLAN_USERS_FAILURE = 'IMPORT_MEAL_PLAN_USERS_FAILURE';

// NewUserForm
export const SHOW_NEW_USER_FORM = 'SHOW_NEW_USER_FORM';
export const SHOW_USER_LIST = 'SHOW_USER_LIST';
export const EDIT_MEAL_PLAN_USER = 'EDIT_MEAL_PLAN_USER';

// DELETE USER
export const DELETING_MEAL_PLAN_USER = 'DELETING_MEAL_PLAN_USER';
export const DELETE_MEAL_PLAN_USER_SUCCESS = 'DELETE_MEAL_PLAN_USER_SUCCESS';
export const DELETE_MEAL_PLAN_USER_FAILURE = 'DELETE_MEAL_PLAN_USER_FAILURE';

// SAVE USER
export const SAVING_MEAL_PLAN_USER = 'SAVING_MEAL_PLAN_USER';
export const SAVE_MEAL_PLAN_USER_SUCCESS = 'SAVE_MEAL_PLAN_USER_SUCCESS';
export const SAVE_MEAL_PLAN_USER_FAILURE = 'SAVE_MEAL_PLAN_USER_FAILURE';

// CREATE USER
export const CREATING_MEAL_PLAN_USER = 'CREATING_MEAL_PLAN_USER';
export const CREATE_MEAL_PLAN_USER_SUCCESS = 'CREATE_MEAL_PLAN_USER_SUCCESS';
export const CREATE_MEAL_PLAN_USER_FAILURE = 'CREATE_MEAL_PLAN_USER_FAILURE';
