import styled from 'styled-components';

export const AccountSettings = styled.div`
  padding: 20px;
  width: 800px;
`;

export const NotificationsSection = styled.section`
`;

export const HorizontalRule = styled.hr`
  margin-top: 0;
  margin-bottom: 3rem;
  border-top: 1px solid #EAEAEB;
`;

export const SectionTitle = styled.h3`
  font-weight: 400;
  font-size: 22px;
  color: #141415;
  margin-bottom: 20px;
`;

export const GroupHeading = styled.div`
  padding: 0 12px 0 0;
`;

export const Channels = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  font-weight: bold;
`;

export const SubSettings = styled.div`
  background-color: #F4F5F5;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  padding: 12px 12px 12px 24px;
  border-radius: 8px;
  margin-bottom: 40px;
`;


export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #141415;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #515154;
  max-width: 500px;
  @media (max-width: 450px) {
    max-width: 230px;
  }
`;

export const ChannelsInputs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
  input {
    cursor: pointer;
  }
`;

export const CheckboxPlaceholder = styled.div`
  width: 28px;
  height: 28px;
`;

export const ChannelSpan = styled.span`
  margin-left: 20px;
  color: #515154;
  font-weight: 600;
  font-size: 13px;
  visibility: ${props => props.visibility};

  @media (max-width: 450px) {
    margin-left: 6px;
  }
`;

export const NotificationGroupContainer = styled.div`
`;

export const NotificationItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #EAEAEB;
  padding: 12px 0;
  &:first-child {
    border-top: none;
  }
`;
