import React from 'react';
import PropTypes from 'prop-types';
import { DeliveryAt, VendorNameContainer } from '../styledComponents';

export function ReportMissingDeliveryStep({
  order,
}) {
  return (
    <div>
      <p className="text-dark pt-3">
        You are reporting that this order has not been received.
      </p>

      <div>
        <span className="text-muted">Order Details</span>
      </div>

      <VendorNameContainer>
        {order.vendorName}
      </VendorNameContainer>

      <DeliveryAt date={order.deliverAt} dateTo={order.endDeliverAt} />

      <div className="mt-3">
        <div>
          <p className="text-danger">
            <b>Note</b>: All submissions are final and cannot be edited.
            Please ensure all details are correct before submitting
          </p>
        </div>
      </div>
    </div>
  );
}

ReportMissingDeliveryStep.propTypes = {
  order: PropTypes.object.isRequired,
};
