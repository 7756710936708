/* eslint-disable max-len */
import React from 'react';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';
import upperCamelCase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Container = styled.div.attrs({
  className: 'd-flex align-items-center justify-content-end',
})`
  column-gap: 1rem;
`;

export const FilterBar = withI18n(({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  reportingInclusions,
  onExportOrders,
  translate,
}) => (
  <Container>
    <button className="btn btn-light" disabled>{translate(`financialReportsMoneyValueTypes${upperCamelCase(reportingInclusions)}`)}</button>
    <div className="d-flex align-items-center">
      <DatePicker
        selected={startDate}
        minDate={new Date('2023-01-01')}
        maxDate={endDate}
        onChange={onStartDateChange}
        dateFormat="MMM yyyy"
        showMonthYearPicker
        className="btn btn-light react-datepicker__start-date-input"
      />
      <DatePicker
        selected={endDate}
        minDate={startDate}
        maxDate={new Date()}
        onChange={onEndDateChange}
        dateFormat="MMM yyyy"
        showMonthYearPicker
        className="btn btn-light react-datepicker__end-date-input"
        data-testid="reporting-start-date"
      />
    </div>
    <button className="btn btn-primary" onClick={onExportOrders}>{translate('financialReportsOrdersExportAction')}</button>
  </Container>
));

FilterBar.propTypes = {
  startDate: object.isRequired,
  onStartDateChange: func.isRequired,
  endDate: object.isRequired,
  onEndDateChange: func.isRequired,
  reportingInclusions: string.isRequired,
  onExportOrders: func.isRequired,
};
