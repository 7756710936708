/* eslint-disable max-len */
import React from 'react';
import { func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import CheckRadioComponent from 'libs/components/CheckRadioComponent';
import { disabledOptions } from './constants';
import { isChannelHidden } from './utils';
import useUpdateSettingsMutation from '../queries/useUpdateSettings';
import {
  NotificationItemContainer,
  Title,
  Description,
  ChannelsInputs,
  CheckboxPlaceholder,
} from '../styles/desktop';

const NotificationItem = ({ group, name, updateSetting, currentSettings, translate }) => {
  const mutation = useUpdateSettingsMutation();

  const handleCheckboxChange = (key, currentValue) => {
    updateSetting(key, currentValue);
    const updatedData = {
      [`${key}`]: currentValue,
    };
    mutation.mutate(updatedData);
  };

  return (
    <NotificationItemContainer>
      <div>
        <Title>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(name)}Title`)}</Title>
        <Description>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(name)}Description`)}</Description>
      </div>
      <ChannelsInputs>
        {isChannelHidden(group, 'push') ? (
          <CheckboxPlaceholder />
        ) : (
          <CheckRadioComponent
            defaultChecked={currentSettings[`${name}_push`]}
            value
            disabled={disabledOptions.includes(`${name}_push`)}
            fieldType="checkbox"
            name={`${name}_push`}
            htmlFor={`${name}_push`}
            onChange={e => handleCheckboxChange(`${name}_push`, e.target.checked)}
          />
        )}

        {isChannelHidden(group, 'email') ? (
          <CheckboxPlaceholder />
        ) : (
          <CheckRadioComponent
            defaultChecked={currentSettings[`${name}_email`]}
            value
            disabled={disabledOptions.includes(`${name}_email`)}
            fieldType="checkbox"
            name={`${name}_email`}
            htmlFor={`${name}_email`}
            onChange={e => handleCheckboxChange(`${name}_email`, e.target.checked)}
          />
        )}

        {isChannelHidden(group, 'sms') ? (
          <CheckboxPlaceholder />
        ) : (
          <CheckRadioComponent
            defaultChecked={currentSettings[`${name}_sms`]}
            value
            disabled={disabledOptions.includes(`${name}_sms`)}
            fieldType="checkbox"
            name={`${name}_sms`}
            htmlFor={`${name}_sms`}
            onChange={e => handleCheckboxChange(`${name}_sms`, e.target.checked)}
          />
        )}
      </ChannelsInputs>
    </NotificationItemContainer>
  );
};

NotificationItem.propTypes = {
  name: string,
  group: object,
  updateSetting: func,
  currentSettings: object,
  translate: func,
};


export default withI18n(NotificationItem);
