import React from 'react';
import { string } from 'prop-types';
import TooltipLite from 'react-tooltip-lite';

import { InfoIcon } from 'libs/components/Icons';

const Tooltip = ({ title }) => (
  <TooltipLite
    tagName="span"
    backgroun="black"
    content={title}
    direction="up"
    className="ml-2"
  >
    <a>
      <InfoIcon />
    </a>
  </TooltipLite>
);

Tooltip.propTypes = {
  title: string,
};

export default Tooltip;
