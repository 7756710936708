/* eslint-disable max-len */
import React from 'react';
import { bool, func, number, string, array } from 'prop-types';
import classnames from 'classnames';
import { blankish } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';
import photo from 'assets/images/account_banner.png';

const AccountPage = ({
  contactSupportPath,
  notificationSettingsPath,
  isVisible,
  fullName,
  roles,
  logoutPath,
  mealPlansCount,
  mealPlansPath,
  translate,
}) => {
  const overlayClasses = classnames(
    'account-page__overlay',
    {
      'account-page__overlay--open': isVisible,
    },
  );

  return (
    <div
      className="account-page"
    >
      <div
        className={overlayClasses}
      >
        <div
          className="account-page__content"
        >
          <div
            className="account-page__hero-block"
          >
            <img src={photo} alt="" className="account-page__hero-image" />

            <div className="account-page__hero-content">
              <div>
                <span className="account-page__hero-title">
                  {translate('navAccount')}
                </span>
                <div className="account-page__user-info-content">
                  <div className="account-page__user-info-title">
                    {translate('navLoggedInAs')}
                  </div>
                  <div className="account-page__user-info">
                    <span className="account-page__user-info-name">{ fullName }</span>
                    { roles.includes('admin') && <span className="badge badge-pill badge-admin">{translate('navAdmin')}</span>}
                    { roles.includes('osc') && <span className="badge badge-pill badge-admin">{translate('navOsc')}</span>}
                    { roles.includes('user') && <span className="badge badge-pill badge-meal-plan-user">{translate('navMealPlanUser')}</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul
            className="account-page__items"
          >
            {
              (blankish(mealPlansCount) || mealPlansCount > 1) &&
                <li className="account-page__item">
                  <a href={mealPlansPath}>
                    {translate('navSelectAnotherMealPlan')}
                  </a>
                </li>
            }
            <li className="account-page__item">
              <a href={notificationSettingsPath}>
                {translate('navAccountSettings')}
              </a>
            </li>
            <li className="account-page__item">
              <a href={contactSupportPath} target="_blank" rel="noopener noreferrer" >
                {translate('navContactSupport')}
              </a>
            </li>
            <li className="account-page__item">
              <a href={logoutPath} data-method="delete" rel="noopener noreferrer" >
                {translate('navLogOut')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

AccountPage.propTypes = {
  contactSupportPath: string.isRequired,
  notificationSettingsPath: string.isRequired,
  fullName: string.isRequired,
  roles: array.isRequired,
  isVisible: bool.isRequired,
  logoutPath: string.isRequired,
  mealPlansCount: number,
  mealPlansPath: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(AccountPage);
