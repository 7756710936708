import React, { Fragment } from 'react';
import styled from 'styled-components';
import { any, string } from 'prop-types';
import TooltipLite from 'react-tooltip-lite';
import InfoIcon from 'libs/components/Icons/Info';

const IconContainer = styled.span`
  display: flex;
  align-items: center;
`;

const AmountField = ({
  className = '',
  labelClassName = '',
  tooltip = false,
  tooltipContent = '',
  tooltipClassName = '',
  title,
  value,
}) => (
  <Fragment>
    <div className={`col tw-flex tw-items-center tw-gap-1 ${labelClassName}`}>
      <span>{title}</span>
      {tooltip && (
        <TooltipLite
          tagName="span"
          content={tooltipContent}
          tipContentClassName={tooltipClassName}
          direction="up"
        >
          <IconContainer>
            <InfoIcon />
          </IconContainer>
        </TooltipLite>
      )}
    </div>

    <div className={`col text-right ${className}`}>
      { value }
    </div>

    <div className="w-100" />
  </Fragment>
);

AmountField.propTypes = {
  className: string,
  labelClassName: string,
  tooltip: any,
  tooltipContent: string,
  tooltipClassName: string,
  title: string.isRequired,
  value: string.isRequired,
};

export default AmountField;
