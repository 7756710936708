import React, { useCallback, useState } from 'react';
import { func, object, integer, string } from 'prop-types';
import styled from 'styled-components';
import { blankish, notBlank } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';

const Container = styled.div`
  border: 1px solid  ${props => props.theme.colors.gainsboro};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextArea = styled.textarea`
  border: none;
  font-size: 14px;
  line-height: 20px;
  padding: 0.5rem !important;
  resize: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const LimitContainer = styled.span`
  color: ${props => props.theme.colors.greyChateau};
  display: flex;
  font-size: 14px;
  line-height: 21px;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

let field;

const TextField = ({
  limit,
  onUpdate,
  option,
  optionId,
  optionName,
  parentId,
  parentType,
  translate,
}) => {
  const [length, setLength] = useState((option.get('text') || '').length);

  const processKey = (e, text, newLength) => {
    if (newLength <= limit) setLength(newLength);

    if (newLength > limit) {
      e.preventDefault();
      field.value = text.substring(0, limit);
      setLength(limit);
    }
  };

  const handleKeyDown = useCallback((e) => {
    if (blankish(limit)) return;

    const text = e.currentTarget.value;
    const newLength = text.length;

    processKey(e, text, newLength);
  });

  const handleKeyUp = useCallback((e) => {
    if (blankish(limit)) return;

    const text = e.currentTarget.value;
    const newLength = text.length;

    processKey(e, text, newLength);
  });

  const handlePaste = useCallback((e) => {
    if (blankish(limit)) return;

    const text = (e.clipboardData || window.clipboardData).getData('text');
    const newLength = text.length;

    processKey(e, text, newLength);
  });

  const handleBlur = useCallback((e) => {
    const text = e.currentTarget.value;

    onUpdate({
      parentType,
      parentId,
      optionId,
      optionName,
      text,
    });
  });

  const placeholder = translate('textFieldWriteYourMessage');
  const isDisabled = blankish(onUpdate);

  return (
    <Container>
      {
        isDisabled &&
          <input
            type="hidden"
            name={`cart_variation[cart_variation_item_add_on_options][${parentId}][0][text]`}
            value={option.get('text')}
          />
      }

      <TextArea
        ref={(node) => { field = node; }}
        rows={5}
        name={`cart_variation[cart_variation_item_add_on_options][${parentId}][0][text]`}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={isDisabled}
      >
        { option.get('text') }
      </TextArea>
      <LimitContainer>
        {
          notBlank(limit) &&
            `${length} / ${limit}`
        }
      </LimitContainer>
    </Container>
  );
};

TextField.propTypes = {
  limit: integer,
  onUpdate: func,
  option: object,
  optionId: string,
  optionName: string,
  parentId: string,
  parentType: string,
  translate: func,
};

export default withI18n(TextField);
