export const defaultTheme = {
  colors: {
    blackRussian: '#141415',
    gainsboro: '#dbdbdb',
    gunPowder: '#515154',
    green: '#7d9e1d',
    greyChateau: '#95989d',
    lavendar: '#eaeaeb',
    lima: '#7d9e1d',
    zircon: '#f4f5f5',
    white: '#ffffff',
    deepChestNut: '#c3423f',
  },
  breakPoints: {
    xss: '498px',
    xs: '34em',
    sm: '48em',
    md: '62em',
  },
  fonts: {
    proxima: '"proximasoft", sans-serif',
    geomanistbook: '"geomanistbook", Arial, sans-serif',
  },
};
