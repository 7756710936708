/* eslint max-len: 0 */

import React from 'react';

const Photo = (_props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33234 3C9.77436 3 10.1983 3.17559 10.5108 3.48816C10.8234 3.80072 10.999 4.22464 10.999 4.66667C10.999 5.10869 10.8234 5.53262 10.5108 5.84518C10.1983 6.15774 9.77436 6.33333 9.33234 6.33333C8.89031 6.33333 8.46638 6.15774 8.15382 5.84518C7.84126 5.53262 7.66567 5.10869 7.66567 4.66667C7.66567 4.22464 7.84126 3.80072 8.15382 3.48816C8.46638 3.17559 8.89031 3 9.33234 3ZM8.499 4.66667C8.499 4.88768 8.5868 5.09964 8.74308 5.25592C8.89936 5.4122 9.11132 5.5 9.33234 5.5C9.55335 5.5 9.76531 5.4122 9.92159 5.25592C10.0779 5.09964 10.1657 4.88768 10.1657 4.66667C10.1657 4.44565 10.0779 4.23369 9.92159 4.07741C9.76531 3.92113 9.55335 3.83333 9.33234 3.83333C9.11132 3.83333 8.89936 3.92113 8.74308 4.07741C8.5868 4.23369 8.499 4.44565 8.499 4.66667ZM1 2.58333C1 2.16341 1.16682 1.76068 1.46375 1.46375C1.76068 1.16682 2.16341 1 2.58333 1H11.4167C11.8366 1 12.2393 1.16682 12.5363 1.46375C12.8332 1.76068 13 2.16341 13 2.58333V11.4167C13.0005 11.797 12.8635 12.1647 12.6143 12.452C12.5765 12.5201 12.5204 12.5764 12.4523 12.6143C12.1649 12.8636 11.7971 13.0005 11.4167 13H2.58333C2.20231 13.0006 1.83394 12.8633 1.54633 12.6133C1.4801 12.576 1.42534 12.5212 1.388 12.455C1.13731 12.1672 0.999457 11.7983 1 11.4167V2.58333ZM12.1667 11.4167V2.58333C12.1667 2.38442 12.0877 2.19366 11.947 2.053C11.8063 1.91235 11.6156 1.83333 11.4167 1.83333H2.58333C2.38442 1.83333 2.19366 1.91235 2.053 2.053C1.91235 2.19366 1.83333 2.38442 1.83333 2.58333V11.4167C1.83333 11.469 1.83867 11.52 1.84867 11.569L6.12334 7.36167C6.35714 7.13163 6.672 7.00271 7 7.00271C7.328 7.00271 7.64286 7.13163 7.87667 7.36167L12.1513 11.5683C12.1613 11.5193 12.1667 11.4683 12.1667 11.4167ZM2.58333 12.1667H11.4167C11.465 12.1667 11.512 12.162 11.558 12.1533L7.29234 7.95567C7.21439 7.87892 7.10939 7.8359 7 7.8359C6.89061 7.8359 6.78561 7.87892 6.70767 7.95567L2.44267 12.1533C2.48834 12.162 2.53533 12.1663 2.58333 12.1663V12.1667Z" fill="#7D9E1D" stroke="#7D9E1D" strokeWidth="0.4" />
  </svg>
);

export default Photo;
