import React from 'react';
import { bool, string } from 'prop-types';
import { CalendarDates, CalendarSkeletal } from 'packs/common/components/CalendarNavigator';

import { useMealDates } from '../queries/use-meal-dates';

const MealTabsContainer = ({
  mealPlanId,
  mealId,
  mealAdmin,
}) => {
  const { data: dates, isLoading } = useMealDates(mealPlanId, mealId);

  if (isLoading) return <CalendarSkeletal isLoading={isLoading} />;

  return (
    <CalendarDates
      dates={dates}
      mealId={mealId}
      mealAdmin={mealAdmin}
    />
  );
};

MealTabsContainer.propTypes = {
  mealPlanId: string,
  mealId: string,
  mealAdmin: bool,
};

export default MealTabsContainer;
