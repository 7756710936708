/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import humps from 'lodash-humps';

import { defaultMessages } from 'libs/i18n/default';
import actionTypes from '../constants';

export const $$initialState = Immutable.fromJS({
  allIds: [],
  byId: {},
  mealPlanId: null,
  fflags: {},
  list: {
    flash: {
      success: null,
      errors: [],
    },
  },
  bulkImportModal: {
    show: false,
    flash: {
      success: null,
      errors: [],
    },
  },
  userForm: {
    show: false,
    flash: {
      success: null,
      errors: [],
    },
    selectedUserId: null, // user that is selected to be edited
  },
});

export default function mealPlanUsersReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.SHOW_BULK_IMPORT_MODAL: {
      return $$state.withMutations(state => state
        .setIn(['bulkImportModal', 'show'], true)
        .setIn(['bulkImportModal', 'flash', 'errors'], Immutable.fromJS([]))
        .setIn(['bulkImportModal', 'flash', 'success'], null)
        .setIn(['list', 'flash', 'success'], null));
    }
    case actionTypes.HIDE_BULK_IMPORT_MODAL: {
      return $$state.withMutations(state => state
        .setIn(['bulkImportModal', 'show'], false));
    }
    case actionTypes.IMPORT_MEAL_PLAN_USERS_SUCCESS: {
      return $$state.withMutations(state => state
        .setIn(['bulkImportModal', 'show'], false)
        .setIn(['bulkImportModal', 'flash', 'errors'], Immutable.fromJS([]))
        .setIn(['list', 'flash', 'success'], defaultMessages.mealPlanUsersListMessagesImportSuccess.defaultMessage));
    }
    case actionTypes.IMPORT_MEAL_PLAN_USERS_FAILURE: {
      return $$state.withMutations(state => state
        .setIn(['bulkImportModal', 'flash', 'success'], null)
        .setIn(['bulkImportModal', 'flash', 'errors'], Immutable.fromJS(response.errors)));
    }
    case actionTypes.SHOW_NEW_USER_FORM: {
      return $$state.withMutations(state => state
        .setIn(['userForm', 'show'], true)
        .setIn(['userForm', 'selectedUserId'], null)
        .setIn(['userForm', 'flash', 'errors'], Immutable.fromJS([]))
        .setIn(['list', 'flash', 'success'], null));
    }
    case actionTypes.EDIT_MEAL_PLAN_USER: {
      return $$state.withMutations(state => state
        .setIn(['userForm', 'show'], true)
        .setIn(['userForm', 'selectedUserId'], response.id));
    }
    case actionTypes.SHOW_USER_LIST: {
      return $$state.withMutations(state => state
        .setIn(['userForm', 'flash', 'errors'], Immutable.fromJS([]))
        .setIn(['userForm', 'flash', 'success'], null)
        .setIn(['userForm', 'show'], false));
    }
    case actionTypes.DELETE_MEAL_PLAN_USER_SUCCESS: {
      return $$state.withMutations(state => state
        .set('allIds', state.get('allIds').filter(id => id !== response.uuid))
        .deleteIn(['byId', response.uuid]));
    }
    case actionTypes.CREATE_MEAL_PLAN_USER_SUCCESS: {
      return $$state.withMutations(state => state
        .set('allIds', state.get('allIds').filter(id => id !== response.uuid))
        .set('allIds', state.get('allIds').push(response.uuid))
        .setIn(['byId', response.uuid], Immutable.fromJS(humps(response)))
        .setIn(['userForm', 'flash', 'success'], defaultMessages.mealPlanUsersUserFormMessageCreateSuccess.defaultMessage)
        .setIn(['userForm', 'flash', 'errors'], Immutable.fromJS([])));
    }
    case actionTypes.SAVE_MEAL_PLAN_USER_SUCCESS: {
      return $$state.withMutations(state => state
        .set('allIds', state.get('allIds').filter(id => id !== response.uuid))
        .set('allIds', state.get('allIds').push(response.uuid))
        .setIn(['byId', response.uuid], Immutable.fromJS(humps(response)))
        .setIn(['userForm', 'flash', 'success'], defaultMessages.mealPlanUsersUserFormMessageUpdateSuccess.defaultMessage)
        .setIn(['userForm', 'flash', 'errors'], Immutable.fromJS([])));
    }
    case actionTypes.CREATE_MEAL_PLAN_USER_FAILURE:
    case actionTypes.SAVE_MEAL_PLAN_USER_FAILURE: {
      return $$state.withMutations(state => state
        .setIn(['userForm', 'flash', 'success'], null)
        .setIn(['userForm', 'flash', 'errors'], Immutable.fromJS(response.errors)));
    }
    default: {
      return $$state;
    }
  }
}
