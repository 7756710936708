import React from 'react';
import { bool, func, object, number, string } from 'prop-types';

import { notBlank } from 'libs/support/string';
import BaseComponent from 'libs/components/BaseComponent';

import OptionField from './OptionField';

export default class MenuItemFormCartVariationAddOn extends BaseComponent {
  static propTypes = {
    isChecked: bool,
    isPickOne: bool,
    isQuantityOptionEnabled: bool,
    hasReachedMax: bool,
    index: number.isRequired,
    max: number,
    min: number,
    quantity: number,
    totalQuantity: number,
    parentName: string.isRequired,
    parentId: string.isRequired,
    parentType: string.isRequired,
    lastName: string.isRequired,
    name: string.isRequired,
    value: string.isRequired,
    optionName: string.isRequired,
    optionPhoto: object,
    optionPrice: string,
    onClick: func.isRequired,
    photos: object,
    showPrice: bool.isRequired,
    disabled: bool,
    onShowExceededMaxOptionsAlert: func.isRequired,
  };

  render() {
    const {
      disabled,
      isChecked,
      isPickOne,
      isQuantityOptionEnabled,
      hasReachedMax,
      index,
      max,
      min,
      quantity,
      totalQuantity,
      parentName,
      parentId,
      parentType,
      lastName,
      name,
      value,
      optionName,
      optionPhoto,
      optionPrice,
      onClick,
      photos,
      showPrice,
      onShowExceededMaxOptionsAlert,
    } = this.props;

    const intDisabled = notBlank(disabled) ? disabled : false;

    return (
      <div className="col-sm-6">
        {
          (isPickOne && !isQuantityOptionEnabled) &&
          <input
            type="hidden"
            name={`${parentName}[0][quantity]`}
            defaultValue={1}
          />
        }

        <OptionField
          isChecked={isChecked}
          isPickOne={isPickOne}
          index={index}
          parentName={parentName}
          parentId={parentId}
          parentType={parentType}
          lastName={lastName}
          name={name}
          value={value}
          optionName={optionName}
          optionPhoto={optionPhoto}
          optionPrice={optionPrice}
          onClick={onClick}
          isQuantityOptionEnabled={isQuantityOptionEnabled}
          hasReachedMax={hasReachedMax}
          min={min}
          max={max}
          quantity={quantity}
          totalQuantity={totalQuantity}
          showPrice={showPrice}
          disabled={intDisabled}
          photos={photos}
          onShowExceededMaxOptionsAlert={onShowExceededMaxOptionsAlert}
        />
      </div>
    );
  }
}
