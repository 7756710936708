import React, { } from 'react';
import classNames from 'classnames';
import { any, func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { EmptyStateIcon } from 'libs/components/Icons';
import Item from './Item';

const ItemList = ({
  items,
  searchText,
  newAddedCartVariationId,
  fetchCartVariation,
  mealPlanId,
  onDeleteItem,
  onEditAssignedUser,
  menuItemsById,
}) => {
  const filteredItems = items.filter(e =>
    e.get('vendorName')?.toLowerCase().includes(searchText.toLowerCase()) ||
    e.get('cartUserName')?.toLowerCase().includes(searchText.toLowerCase()) ||
    e.get('name')?.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <div className="order-items-table">
      <div className={classNames('edit-order-container-overflow tw-scrollable', {
        '!tw-m-0 !tw-p-0': true,
        '!tw-max-h-[65vh] md:!tw-max-h-[55vh]': true,
      })}
      >
        {
          filteredItems.size > 0 && filteredItems
            .groupBy(item => item.get('vendorName'))
            .valueSeq()
            .map((orderableVariations) => {
              const vendorName = orderableVariations.getIn([0, 'vendorName']);
              return (
                <Item
                  key={`mealCartItem-${vendorName}`}
                  vendorName={vendorName}
                  orderableVariations={orderableVariations}
                  newAddedCartVariationId={newAddedCartVariationId}
                  fetchCartVariation={fetchCartVariation}
                  mealPlanId={mealPlanId}
                  onDeleteItem={onDeleteItem}
                  onEditAssignedUser={onEditAssignedUser}
                  menuItemsById={menuItemsById}
                />
              );
            })
        }
        {filteredItems && filteredItems.size < 1 &&
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-mt-4 tw-gap-8">
            <EmptyStateIcon />
            <p className="tw-m-0">No orders yet.</p>
          </div>
        }

      </div>
      <div className="col-3" />
    </div>
  );
};

ItemList.propTypes = {
  items: any,
  searchText: string,
  newAddedCartVariationId: string,
  fetchCartVariation: func,
  mealPlanId: string,
  onDeleteItem: func,
  onEditAssignedUser: func,
  menuItemsById: any,
};

export default withI18n(ItemList);
