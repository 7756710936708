import { useMutation } from 'react-query';
import { httpClient } from 'libs/support/httpClient';
import dayjs from 'dayjs';

export const useExportOrders = ({ sid, organisationId, startDate, endDate }) =>
  useMutation(
    async () => {
      const { data } = await httpClient.post('/orders/export', {
        sid,
        organisationId,
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
      });

      return data;
    },
    {
      mutationKey: ['EXPORT_ORDERS', organisationId, startDate, endDate],
    },
  );
