import React from 'react';
import classNames from 'classnames';
import { any, string } from 'prop-types';

const ThElement = ({ children, className }) => (
  <th className={classNames('!tw-border-0 tw-whitespace-nowrap', className)}>
    { children }
  </th>
);

ThElement.propTypes = {
  children: any,
  className: string,
};

export default ThElement;
