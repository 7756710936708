import React from 'react';
import humps from 'lodash-humps';
import AppContext from 'libs/support/appContext';
import { defaultTheme } from 'libs/support/theme';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import MealTabsContainer from 'packs/meal_plans/containers/MealTabsContainer';

const queryClient = new QueryClient();

const MealTabsApp = (props, railsContext) => {
  const context = {
    fflags: railsContext.fflags,
    settings: railsContext.settings,
  };

  return (
    <AppContext.Provider value={context}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={defaultTheme}>
          <MealTabsContainer {...humps(props)} />
        </ThemeProvider>
      </QueryClientProvider>
    </AppContext.Provider>
  );
};

export default MealTabsApp;
