/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uppercamelcase from 'uppercamelcase';
import classNames from 'classnames';

import { withI18n } from 'libs/support/i18n';
import mealPlanUsersActions from 'packs/meal_plan_users/actions';

import BaseComponent from 'libs/components/BaseComponent';
import DeleteIcon from 'libs/components/Icons/DeleteIcon';
import EditIcon from 'libs/components/Icons/EditIcon';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import TdElement from './TdElement';

/**
 * MealPlanUsers
 */

class MealPlanUser extends BaseComponent {
  static propTypes = {
    translate: func.isRequired,
  }

  deleteUser = () => {
    const { mealPlanId, mealPlanUser, actions, translate } = this.props;
    const confirmOptions = {
      message: translate('mealPlanUsersListDeleteAlert', { name: mealPlanUser.name }),
      buttons: [
        {
          label: 'Yes',
          onClick: () => { actions.deleteUser(mealPlanId, mealPlanUser.get('uuid')); },
        },
        {
          label: 'No',
          onClick: () => { },
        },
      ],
    };

    confirmAlert(confirmOptions);
  }

  editUser = () => {
    const { mealPlanUser, actions } = this.props;

    return actions.editUser(mealPlanUser.get('uuid'));
  }

  render() {
    const {
      mealPlanUser,
      translate,
    } = this.props;

    const role = mealPlanUser.get('role');

    return (
      <tr className="tw-grid md:tw-table-row tw-cursor-pointer hover:tw-bg-lime-100/[0.25] tw-border tw-border-gray-200 md:!tw-border-0 tw-rounded-lg md:!tw-rounded-none tw-mb-4 md:!tw-m-0">
        <TdElement className="tw-px-2 !tw-py-1 !tw-pt-2 md:!tw-py-2">
          <div className="tw-flex tw-items-center tw-justify-between">
            <span className="tw-text-xl md:tw-text-sm tw-line-clamp-1 tw-max-w-[180px] md:tw-max-fit">
              {mealPlanUser.get('fullName')}
            </span>
            <span
              className={classNames(
                'md:!tw-hidden badge text-uppercase',
                { 'badge-primary': role !== 'user', 'badge-light': role === 'user' },
              )}
            >
              {translate(`mealPlanUsersRoles${uppercamelcase(role)}`)}
            </span>
          </div>

        </TdElement>
        <TdElement className="tw-px-2 !tw-py-1 md:!tw-py-2">{ mealPlanUser.get('email') }</TdElement>
        <TdElement className={classNames('tw-px-2 !tw-py-1 md:!tw-py-2', {
          'tw-hidden md:!tw-table-cell': !mealPlanUser.get('department'),
        })}
        >
          { mealPlanUser.get('department') || '-' }
        </TdElement>
        <TdElement className={classNames('tw-px-2 !tw-py-1 md:!tw-py-2', {
          'tw-hidden md:!tw-table-cell': !mealPlanUser.get('referenceCode'),
        })}
        >
          { mealPlanUser.get('referenceCode') || '-' }
        </TdElement>
        <TdElement className="tw-px-2 !tw-py-1 md:!tw-py-2 tw-hidden md:!tw-table-cell">
          <span
            className={classNames(
              'badge text-uppercase',
              { 'badge-primary': role !== 'user', 'badge-light': role === 'user' },
            )}
          >
            { translate(`mealPlanUsersRoles${uppercamelcase(role)}`) }
          </span>
        </TdElement>
        <TdElement className="tw-text-right">
          <div className="tw-flex tw-items-center tw-gap-1">
            <button
              className="btn btn-light btn-sm px-2 mr-1 btn-delete"
              onClick={this.deleteUser}
            >
              <DeleteIcon />
            </button>
            <button
              id="edit-user"
              className="btn btn-light btn-sm px-2 btn-edit"
              onClick={this.editUser}
            >
              <EditIcon />
            </button>
          </div>
        </TdElement>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  mealPlanId: state.$$mealPlanUsersStore.get('mealPlanId'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...mealPlanUsersActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(MealPlanUser));
