/* eslint-disable no-console */
import React from 'react';
import { injectIntl } from 'react-intl';
import { defaultMessages } from 'libs/i18n/default';
import { blankish } from 'libs/support/string';

const getCountStr = (count) => {
  if (blankish(count)) return '';
  if (typeof (count) !== 'number') return '';
  if (count <= 0) return 'Zero';
  if (count === 1) return 'One';
  return 'Other';
};

const translate = ({ intl: { formatMessage } }) => (key, params = {}) => {
  const intKey = `${key}${getCountStr(params.count)}`;

  if (Object.prototype.hasOwnProperty.call(defaultMessages, intKey)) {
    return formatMessage(defaultMessages[intKey], params);
  } else if (Object.prototype.hasOwnProperty.call(defaultMessages, key)) {
    return formatMessage(defaultMessages[key], params);
  }

  console.log(`Missing translation key ${key}`);
  return '';
};

export const withI18n = (Component) => injectIntl((props) => (
  <Component
    translate={translate(props)}
    {...props}
  />
));
