import React, { useMemo } from 'react';
import { withI18n } from 'libs/support/i18n';
import { array, object } from 'prop-types';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, CardTitle } from '../../../styles';
import { Money } from '../../Money';
import {
  formatDate,
  formatMoney,
} from '../../utils';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
);

export const SpendingTrend = withI18n(({ summary, pantryPrograms, translate }) => {
  const buildBarDataset = (pantryProgram, _i = 0) => ({
    label: pantryProgram.name,
    data: pantryProgram.data.map(({ totalSpend }) => totalSpend),
    backgroundColor: pantryProgram.color,
    borderColor: pantryProgram.color,
    barThickness: 20,
    borderRadius: 25,
    borderWidth: 3,
  });

  const buildLineDataset = (pantryProgram) => ({
    label: translate('financialReportsProgramNamesTotal'),
    data: pantryProgram.data.map(({ totalSpend }) => totalSpend),
    backgroundColor: pantryProgram.color,
    borderColor: pantryProgram.color,
    type: 'line',
    order: 0,
  });

  const datasets = useMemo(() => {
    const total = pantryPrograms.find((pantryProgram) => pantryProgram.id === 'total');
    const others = pantryPrograms.filter((pantryProgram) => pantryProgram.id !== 'total');

    return [buildLineDataset(total), ...others.map(buildBarDataset)];
  }, [pantryPrograms]);

  return (
    <Card data-testid="reporting-pantry-programs-spending-trend">
      <CardTitle>
        {translate('financialReportsTermsTotalSpend')}
      </CardTitle>
      <Money value={summary.totalSpend} fontSize={48} />
      <Bar
        height={80}
        options={{
            elements: {
              point: {
                radius: 6,
                hoverRadius: 6,
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                align: 'end',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  label: (item) => formatMoney(item.raw, { withCents: true, withSymbol: false }),
                },
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (value) => formatMoney(value, {
                      withSymbol: true,
                      withCents: false,
                      compact: true,
                      truncate: true,
                    }),
                  color: '#BFC1C4',
                },
              },
            },
          }}
        data={{
            labels: pantryPrograms[0].data.map(({ step }) => formatDate(step)),
            datasets,
          }}
      />
    </Card>
  );
});

SpendingTrend.propTypes = {
  summary: object.isRequired,
  pantryPrograms: array.isRequired,
};
