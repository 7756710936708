import React from 'react';
import { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Floater from 'react-floater';
import GuidedInfo from 'packs/common/components/GuidedInfo';
import { useWindowSize } from 'packs/common/hooks/useWindowSize';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { blankish } from 'libs/support/string';
import { saveUserMealPlanSetting } from 'packs/meals/actions/userMealPlanSettingActions';

const MealConfirmationGuidedInfo = ({
  actions,
  allowSelfCheckout,
  flagIsEnabled,
  translate,
  userMealPlanSettingStore,
}) => {
  const { width } = useWindowSize();
  const layout = width > 991 ? 'desktop' : 'mobile';

  const handleClick = () => {
    actions.saveUserMealPlanSetting({ seenMealConfirmationAt: new Date() });
  };

  const isGuidedInfoVisible = flagIsEnabled('guided_info_enabled') &&
    !allowSelfCheckout &&
    blankish(userMealPlanSettingStore.get('seenMealConfirmationAt'));

  return (
    <div>
      {
        isGuidedInfoVisible &&
          layout === 'mobile' &&
          <Floater
            open
            placement="top"
            component={() => (
              <GuidedInfo
                title={translate('guidedInfoMealConfirmationTitle')}
                content={translate('guidedInfoMealConfirmationContent')}
                onClick={handleClick}
              />
            )}
            target=".meal-confirmation--mobile"
          />
      }

      {
        isGuidedInfoVisible &&
          layout === 'desktop' &&
          <Floater
            open
            placement="left"
            component={() => (
              <GuidedInfo
                title={translate('guidedInfoMealConfirmationTitle')}
                content={translate('guidedInfoMealConfirmationContent')}
                onClick={handleClick}
              />
            )}
            target=".meal-confirmation--desktop"
          />
      }
    </div>
  );
};

MealConfirmationGuidedInfo.propTypes = {
  actions: object.isRequired,
  allowSelfCheckout: bool.isRequired,
  flagIsEnabled: func,
  translate: func.isRequired,
  userMealPlanSettingStore: object.isRequired,
};

const mapStateToProps = (state) => ({
  allowSelfCheckout: state.$$mealStore.getIn(['groupOrderSetting', 'allowSelfCheckout']),
  userMealPlanSettingStore: state.$$userMealPlanSettingStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ saveUserMealPlanSetting }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(withAppContext(MealConfirmationGuidedInfo)));
