/* eslint max-len: 0 */

import React from 'react';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';

import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import { withAppContext } from 'libs/support/appContext';

/* Components */
import PlannedMealV1 from 'packs/meals/components/PlannedMeal/v1/PlannedMeal';
import PlannedMealV2 from 'packs/meals/components/PlannedMeal/v2/PlannedMeal';
import { func } from 'prop-types';

global.Intl = Intl;

/**
 * PlannedMealContainer
 */
const PlannedMealContainer = ({
  flagIsEnabled,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];
  const Component = flagIsEnabled('planned_meal_v2_enabled') ? PlannedMealV2 : PlannedMealV1;

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Component />
    </IntlProvider>
  );
};

PlannedMealContainer.propTypes = {
  flagIsEnabled: func,
};

export default withAppContext(PlannedMealContainer);
