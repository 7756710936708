import React from 'react';
import { func, object } from 'prop-types';

import { withAppContext } from 'libs/support/appContext';
import { ThreeDotsIcon } from 'libs/components/Icons';
import { withI18n } from 'libs/support/i18n';
import PlannedMealActionsDropdown from 'packs/common/components/PlannedMealActionsDropdown';
import DeliveryTrackingDropdown from 'packs/common/components/DeliveryTrackingDropdown';
import Immutable from 'immutable';

const CardFooter = ({
  meal,
  handleOnClickDownloadPdf,
  flagIsEnabled,
  userRoleIn,
  settings,
  translate,
}) => {
  const mealId = meal.get('uuid');
  const mealPlanId = meal.get('mealPlanId');
  const mealPath = () => `/meal_plans/${mealPlanId}/meals/${mealId}`;
  const orderables = meal.get('isConfirmed') ? meal.get('orderables') : Immutable.fromJS([]);
  const trackableOrders = orderables && orderables.filter((order) => order.get('deliveryTrackable'));

  const renderFooterActions = () => {
    if (meal.get('isConfirmed')) {
      return <a href={mealPath()} className="btn btn-light btn-sm">View Menu</a>;
    } else if (userRoleIn(['admin'])) {
      return <a href={mealPath()} className="btn btn-primary btn-sm">View Menu</a>;
    }
    return <button className="btn btn-light btn-sm" disabled>View Menu</button>;
  };

  return (
    <div className="card-footer d-flex justify-content-between">
      { trackableOrders && trackableOrders.size > 0 && userRoleIn(['osc', 'admin']) ?
        <div className="text-left">
          <DeliveryTrackingDropdown
            btnClassName="btn-light btn-sm caret-off"
            className="ml-1"
            orders={trackableOrders}
            webUrl={settings.webUrl}
          >
            {translate('plannedMealCardsButtonsTrackDelivery')}
          </DeliveryTrackingDropdown>
        </div> :
        <div />
      }
      <div className="text-right">
        { renderFooterActions() }

        {
          flagIsEnabled('menu_card_enabled') &&
          userRoleIn(['osc', 'admin']) &&
            <PlannedMealActionsDropdown
              btnClassName="btn-light btn-sm caret-off"
              className="ml-1"
              mealPlanId={mealPlanId}
              mealId={mealId}
              orders={orderables}
              onClickMenuCardDownload={handleOnClickDownloadPdf}
            >
              <ThreeDotsIcon />
            </PlannedMealActionsDropdown>
        }
      </div>
    </div>
  );
};

CardFooter.propTypes = {
  meal: object.isRequired,
  handleOnClickDownloadPdf: func.isRequired,
  userRoleIn: func.isRequired,
  flagIsEnabled: func,
  settings: object.isRequired,
  translate: func.isRequired,
};

export default withAppContext(withI18n(CardFooter));
