/* eslint-disable max-len */
import React from 'react';

const DeleteIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 14 16"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4 0.989498C4 0.52189 4.3038 0 4.8 0H9.2C9.6962 0 10 0.52189 10 0.989498V1.979H12.9329C13.4799 1.979 13.7465 1.979 13.8764 2.11226C14 2.23898 14 2.48619 14 2.96849V3.95799H0V2.96849C0 2.48619 0 2.23898 0.12357 2.11226C0.253519 1.979 0.520124 1.979 1.0671 1.979H4V0.989498ZM1 15.0929C1 15.5931 1.55767 16 2.24333 16H11.757C12.4423 16 13 15.5931 13 15.0929V5H1V15.0929ZM3 7V14H11V7H3Z"
      fill="#141415"
      fillRule="evenodd"
      opacity="0.4"
    />
  </svg>
);

export default DeleteIcon;
