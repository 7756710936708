import React from 'react';
import { array } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { Card, TableWrapper } from '../../../styles';
import { formatDate } from '../../utils';

export const OrdersCountTrend = withI18n(({ mealPlans, translate }) => {
  const mealPlansTotal = mealPlans.find((mealPlan) => mealPlan.id === 'total');
  const showTotal = mealPlansTotal.data.length > 1;

  return (
    <Card data-testid="reporting-meal-plans-orders-count-trend">
      <TableWrapper fixedFirstCellWidth={180}>
        <table className="table">
          <thead>
            <tr>
              <th />
              {mealPlans[0].data.map(({ step }) => (
                <th key={step}>{formatDate(step)}</th>
              ))}
              {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
            </tr>
          </thead>
          <tbody>
            {mealPlans.filter(({ id }) => id !== 'total').map((report) => (
              <tr key={report.id}>
                <td className="font-weight-bold">{report.name}</td>
                {report.data.map(({ step, ordersCount }) => (
                  <td key={step}>{ordersCount}</td>
                ))}
                {showTotal && (
                  <td>{report.data.reduce((acc, curr) => acc + curr.ordersCount, 0)}</td>
                )}
              </tr>
            ))}
            <tr>
              <td className="font-weight-bold">{translate('financialReportsTermsTotal')}</td>
              {mealPlansTotal.data.map(({ step, ordersCount }) => (
                <td key={step} className="font-weight-bold">{ordersCount}</td>
              ))}
              {showTotal && <td>{mealPlansTotal.data.reduce((acc, curr) => acc + curr.ordersCount, 0)}</td>}
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </Card>
  );
});

OrdersCountTrend.propTypes = {
  mealPlans: array.isRequired,
};
