/* eslint max-len: 0 */

import React from 'react';

const BaristaCafe = () => (
  <svg width="203" height="158" viewBox="0 0 203 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M63.2019 109.124C63.2019 109.124 58.8497 104.008 62.9335 100.757C62.9335 100.757 65.9166 98.9377 68.3487 99.0857C68.3487 99.0857 65.6707 103.189 66.7858 103.768C67.9009 104.347 69.3934 98.7208 69.3934 98.7208C69.3934 98.7208 76.1818 98.0578 78.365 99.1155C78.365 99.1155 72.5621 103.9 73.7843 104.185C75.0065 104.471 81.2746 99.7734 81.2746 99.7734C81.2746 99.7734 87.5926 100.857 87.256 104.741C86.9193 108.624 84.7422 109.823 83.7873 109.719C82.8324 109.615 77.2336 107.464 77.1112 108.253C76.9888 109.043 80.3585 111.625 82.7222 111.748C82.7222 111.748 78.2681 116.698 75.3075 115.269C72.3469 113.84 72.0939 111.576 70.2483 110.726C68.4028 109.876 66.8286 109.879 67.5897 110.746C68.3508 111.612 71.6919 112.453 72.7468 114.334C73.8017 116.216 74.7494 117.484 71.6868 117.019C68.6242 116.555 63.6446 114.297 63.4232 111.711L63.2019 109.124Z" fill="#BCF7F0" />
    <path d="M84.1521 104.844C84.1521 104.844 66.9914 104.178 63.2024 109.124C63.2024 109.124 62.3638 111.653 59.7848 113.955L59.3828 115.058C59.3828 115.058 62.3567 111.899 63.4207 111.708C63.4207 111.709 61.7568 105.584 84.1521 104.844Z" fill="#BCF7F0" />
    <path d="M59.7856 113.956C59.7856 113.956 56.1027 116.701 55.8467 121.604L56.5159 121.665C56.5159 121.665 57.6922 116.594 59.8183 114.611C61.9444 112.628 59.7856 113.956 59.7856 113.956Z" fill="#BCF7F0" />
    <path d="M46.1774 104.447C46.1774 104.447 52.3526 99.4262 48.2851 94.7449C48.2851 94.7449 45.1949 91.9694 42.3262 91.6147C42.3262 91.6147 45.098 99.1599 43.6728 99.5927C42.2476 100.025 41.1856 90.9579 41.1856 90.9579C41.1856 90.9579 33.4087 88.7067 30.6337 89.4653C30.6337 89.4653 36.3774 96.3115 34.891 96.3773C33.4046 96.443 27.0977 89.5989 27.0977 89.5989C27.0977 89.5989 19.4942 89.4869 19.0545 94.0911C18.6148 98.6953 20.897 100.567 22.0355 100.657C23.1741 100.746 30.1655 99.4519 30.1389 100.4C30.1124 101.347 25.6266 103.626 22.8486 103.253C22.8486 103.253 26.9835 109.998 30.7439 108.976C34.5043 107.954 35.2848 105.368 37.62 104.779C39.9552 104.19 41.7926 104.535 40.7173 105.38C39.642 106.225 35.5664 106.479 33.931 108.443C32.2956 110.408 30.9194 111.682 34.5931 111.807C38.2668 111.931 44.5583 110.382 45.3684 107.413L46.1774 104.447Z" fill="#BCF7F0" />
    <path d="M22.6543 94.8926C22.6543 94.8926 42.8174 97.8521 46.1779 104.446C46.1779 104.446 46.6135 107.58 49.1283 110.828L49.3609 112.203C49.3609 112.203 46.5687 107.871 45.3689 107.416C45.3689 107.416 48.6243 100.631 22.6543 94.8926Z" fill="#BCF7F0" />
    <path d="M49.1277 110.828C49.1277 110.828 52.8361 114.837 52.0863 120.61L51.2936 120.535C51.2936 120.535 51.0089 114.368 48.9543 111.586C46.8996 108.804 49.1277 110.828 49.1277 110.828Z" fill="#BCF7F0" />
    <path d="M59.0018 97.3025C59.0018 97.3025 49.8752 92.1525 54.1345 85.1644C54.1345 85.1644 57.5409 80.8727 61.1912 79.7697C61.1912 79.7697 59.2446 90.1911 61.1912 90.4358C63.1377 90.6804 62.5297 78.6657 62.5297 78.6657C62.5297 78.6657 72.1429 74.007 75.9146 74.375C75.9146 74.375 69.9526 84.5518 71.8991 84.3061C73.8456 84.0604 80.5391 73.7613 80.5391 73.7613C80.5391 73.7613 90.3952 71.9223 91.9775 77.8073C93.5599 83.6924 91.0033 86.6354 89.5434 87.0034C88.0835 87.3714 78.714 87.2481 78.9568 88.4744C79.1996 89.7008 85.5279 91.6611 89.0567 90.5591C89.0567 90.5591 85.1627 100.245 80.0525 99.7542C74.9424 99.2639 73.36 96.0762 70.1964 95.8305C67.0328 95.5848 64.72 96.4473 66.3023 97.3025C67.8847 98.1578 73.2397 97.5832 75.7901 99.7696C78.3406 101.956 80.4147 103.307 75.6687 104.293C70.9228 105.278 62.4062 104.661 60.7015 100.983L59.0018 97.3025Z" fill="#DCF9ED" />
    <path d="M87.4731 79.6465C87.4731 79.6465 61.9203 87.9842 59.0025 97.3026C59.0025 97.3026 59.1239 101.471 56.5684 106.253V108.092C56.5684 108.092 59.2453 101.839 60.7052 100.981C60.7032 100.981 54.985 92.8886 87.4731 79.6465Z" fill="#BCF7F0" />
    <path d="M56.5654 106.253C56.5654 106.253 52.6244 112.282 54.8708 119.616L55.8849 119.343C55.8849 119.343 54.9014 111.258 56.9633 107.192C59.0251 103.127 56.5654 106.253 56.5654 106.253Z" fill="#BCF7F0" />
    <path d="M58.6964 135.539C63.4361 135.538 67.9815 133.64 71.3331 130.263C74.6847 126.886 76.5681 122.307 76.5691 117.531H40.8154C40.816 119.896 41.2789 122.238 42.1778 124.424C43.0767 126.609 44.394 128.594 46.0544 130.266C47.7148 131.938 49.6858 133.265 51.855 134.169C54.0241 135.074 56.3488 135.539 58.6964 135.539Z" fill="#BCF7F0" />
    <path d="M63.091 135.401C60.5757 135.716 58.0226 135.489 55.6009 134.735C53.1792 133.981 50.9442 132.717 49.0439 131.027C47.1436 129.337 45.6215 127.259 44.5783 124.931C43.5352 122.604 42.9949 120.079 42.9932 117.525H47.4341C47.4343 121.915 49.0254 126.154 51.9084 129.446C54.7914 132.737 58.7679 134.855 63.091 135.401Z" fill="#DCF9ED" />
    <path d="M76.8158 136.455H40.5684C40.5684 135.866 40.8007 135.301 41.2144 134.884C41.628 134.467 42.189 134.233 42.774 134.233H74.6112C75.1962 134.233 75.7572 134.467 76.1708 134.884C76.5845 135.301 76.8169 135.866 76.8169 136.455H76.8158Z" fill="#BCF7F0" />
    <path d="M76.9749 117.526H40.2275V118.079H76.9749V117.526Z" fill="#DCF9ED" />
    <path d="M155.232 67.6573C155.232 67.6573 146.643 65.4318 148.629 58.7727C148.629 58.7727 150.498 54.5108 153.253 52.8105C153.253 52.8105 153.901 61.7784 155.549 61.5543C157.198 61.3302 154.112 51.614 154.112 51.614C154.112 51.614 160.988 45.694 164.16 45.1708C164.16 45.1708 158.081 55.1276 161.609 55.4195C163.268 55.5562 167.82 43.6576 167.82 43.6576C167.82 43.6576 175.503 39.996 178.067 44.4738C180.631 48.9516 179.169 51.9224 178.052 52.5433C176.934 53.1642 169.228 55.1132 169.686 56.063C170.144 57.0128 175.758 57.24 178.413 55.5634C178.413 55.5634 177.307 64.3535 173.012 65.0689C168.717 65.7844 166.735 63.5167 164.089 64.007C161.444 64.4974 159.733 65.7073 161.214 66.063C162.696 66.4186 166.96 64.777 169.526 66.0126C172.092 67.2482 174.077 67.9009 170.398 69.743C166.719 71.5851 159.606 72.9431 157.418 70.3012L155.232 67.6573Z" fill="#BCF7F0" />
    <path d="M149.332 75.5249C149.332 75.5249 147.399 81.3288 150.821 86.854L151.593 86.4069C151.593 86.4069 149.043 79.9966 149.859 76.2126C150.675 72.4287 149.332 75.5249 149.332 75.5249Z" fill="#BCF7F0" />
    <path d="M147.667 93.5408C147.667 93.5408 137.454 88.1738 141.915 80.3408C141.915 80.3408 145.525 75.5094 149.508 74.173C149.508 74.173 147.704 85.7098 149.855 85.9154C152.005 86.121 150.945 72.9148 150.945 72.9148C150.945 72.9148 158.006 67.5355 165.539 67.7483C165.539 67.7483 159.307 79.1473 161.442 78.8132C163.577 78.4791 170.104 67.738 170.104 67.738C170.104 67.738 181.398 64.5709 183.331 70.9966C185.265 77.4224 182.547 80.7468 180.951 81.1991C179.356 81.6514 169.038 81.8272 169.347 83.1687C169.655 84.5101 176.683 86.4581 180.532 85.129C180.532 85.129 176.56 95.9225 170.919 95.5494C165.277 95.1762 163.432 91.7213 159.942 91.5558C156.452 91.3903 153.934 92.411 155.704 93.3033C157.473 94.1956 163.348 93.3835 166.232 95.7087C169.116 98.034 171.435 99.4495 166.245 100.686C161.055 101.923 151.656 101.529 149.662 97.5364L147.667 93.5408Z" fill="#DCF9ED" />
    <path d="M178.436 73.166C178.436 73.166 150.579 83.1876 147.668 93.5411C147.668 93.5411 147.937 98.1258 145.279 103.474L145.339 105.498C145.339 105.498 148.083 98.5267 149.662 97.5337C149.662 97.5337 143.103 88.8156 178.436 73.166Z" fill="#BCF7F0" />
    <path d="M139.447 103.474C139.447 103.474 135.306 110.241 138.019 118.247L139.126 117.911C139.126 117.911 137.779 109.045 139.917 104.501C142.054 99.9576 139.447 103.474 139.447 103.474Z" fill="#6BBFA6" />
    <path d="M84.6939 76.1099H145.213C146.1 76.1099 146.951 76.4651 147.579 77.0974C148.207 77.7297 148.559 78.5873 148.559 79.4816V120.423C148.559 121.317 148.207 122.174 147.579 122.806C146.952 123.439 146.101 123.794 145.214 123.794H84.6939C83.8064 123.794 82.9553 123.438 82.3277 122.806C81.7002 122.174 81.3477 121.316 81.3477 120.422V79.4816C81.3477 78.5873 81.7002 77.7297 82.3277 77.0974C82.9553 76.4651 83.8064 76.1099 84.6939 76.1099Z" fill="#49B79D" />
    <path d="M74.7507 20H155.157C156.336 20 157.467 20.4719 158.3 21.3118C159.134 22.1518 159.602 23.291 159.602 24.4788V78.8752C159.602 79.4634 159.487 80.0458 159.264 80.5892C159.04 81.1326 158.713 81.6263 158.3 82.0422C157.888 82.4581 157.398 82.788 156.858 83.0131C156.319 83.2382 155.741 83.354 155.157 83.354H74.7507C73.5715 83.354 72.4407 82.882 71.6069 82.0419C70.7731 81.2018 70.3047 80.0623 70.3047 78.8742V24.4798C70.3047 23.2917 70.7731 22.1522 71.6069 21.3121C72.4407 20.472 73.5715 20 74.7507 20V20Z" fill="#49B79D" />
    <path d="M72.4338 121.646H157.474C158.039 121.646 158.58 121.871 158.979 122.274C159.378 122.676 159.602 123.221 159.602 123.79V134.027C159.602 134.596 159.378 135.142 158.979 135.544C158.579 135.947 158.038 136.173 157.473 136.173H72.4338C71.8691 136.173 71.3276 135.947 70.9283 135.544C70.529 135.142 70.3047 134.596 70.3047 134.027V123.791C70.3047 123.222 70.529 122.676 70.9283 122.274C71.3276 121.872 71.8691 121.646 72.4338 121.646Z" fill="#49B79D" />
    <path d="M74.4868 76.1519H135.006C135.893 76.1519 136.744 76.5071 137.372 77.1394C137.999 77.7717 138.352 78.6293 138.352 79.5236V120.465C138.352 121.359 138 122.216 137.372 122.848C136.745 123.481 135.894 123.836 135.007 123.836H74.4868C73.5996 123.836 72.7487 123.481 72.1214 122.848C71.494 122.216 71.1416 121.359 71.1416 120.465V79.5184C71.143 78.6253 71.496 77.7691 72.1231 77.138C72.7503 76.5068 73.6004 76.1521 74.4868 76.1519Z" fill="#49A292" />
    <path d="M64.5427 20.042H144.949C145.533 20.0419 146.112 20.1577 146.651 20.3828C147.191 20.608 147.681 20.938 148.094 21.3542C148.507 21.7703 148.834 22.2643 149.057 22.808C149.281 23.3517 149.396 23.9344 149.395 24.5229V78.9172C149.395 80.105 148.927 81.2442 148.093 82.0842C147.26 82.9241 146.129 83.396 144.95 83.396H64.5427C63.3635 83.396 62.2327 82.924 61.3989 82.0839C60.5651 81.2438 60.0967 80.1043 60.0967 78.9162V24.5229C60.0965 23.9345 60.2114 23.3518 60.4348 22.8082C60.6582 22.2645 60.9857 21.7706 61.3985 21.3545C61.8114 20.9384 62.3016 20.6083 62.841 20.3831C63.3805 20.1579 63.9587 20.042 64.5427 20.042Z" fill="#8BCCB9" />
    <path d="M64.3437 121.688H145.15C145.701 121.688 146.229 121.908 146.618 122.3C147.008 122.693 147.226 123.225 147.226 123.779V134.122C147.226 134.677 147.007 135.209 146.618 135.602C146.228 135.994 145.7 136.215 145.149 136.215H64.3437C63.7928 136.215 63.2645 135.994 62.875 135.602C62.4854 135.209 62.2666 134.677 62.2666 134.122V123.78C62.2666 123.225 62.4854 122.693 62.875 122.301C63.2645 121.908 63.7928 121.688 64.3437 121.688Z" fill="#8BCCB9" />
    <path d="M126.391 101.815C124.881 100.875 121.942 101.242 119.814 101.693V98.5872C119.813 97.9091 119.769 97.2317 119.683 96.5591H87.7507C87.6653 97.2317 87.6217 97.9091 87.6201 98.5872V104.901C87.6201 113.822 94.8635 121.119 103.716 121.119C106.001 121.117 108.26 120.623 110.341 119.671C112.422 118.719 114.278 117.33 115.784 115.598C115.784 115.598 123.381 113.714 125.765 110.86C128.149 108.006 128.925 103.392 126.391 101.815ZM124.704 107.654C123.899 109.752 120.277 110.882 118.49 111.321C119.219 109.629 119.653 107.823 119.775 105.982C120.877 104.461 122.618 103.572 123.895 103.805C125.035 104.013 125.723 104.996 124.703 107.654H124.704Z" fill="#BCF7F0" />
    <path d="M90.2363 98.5863V104.902C90.2363 113.819 97.4797 121.122 106.329 121.122C107.053 121.123 107.776 121.07 108.492 120.964C100.655 119.897 94.564 113.081 94.564 104.902V98.5863C94.5642 97.9078 94.6078 97.23 94.6946 96.5571H90.3679C90.2808 97.23 90.2369 97.9078 90.2363 98.5863Z" fill="#DCF9ED" />
    <path d="M110.278 119.706H97.1377V121.687H110.278V119.706Z" fill="#BCF7F0" />
    <path d="M82.2414 28.8887H71.3151C69.2761 28.8887 67.623 30.5543 67.623 32.6088V67.8832C67.623 69.9378 69.2761 71.6034 71.3151 71.6034H82.2414C84.2805 71.6034 85.9335 69.9378 85.9335 67.8832V32.6088C85.9335 30.5543 84.2805 28.8887 82.2414 28.8887Z" fill="#BCF7F0" />
    <path d="M115.184 29.2487C115.184 28.6668 115.355 28.098 115.676 27.6142C115.997 27.1303 116.453 26.7533 116.986 26.5306C117.52 26.3079 118.107 26.2497 118.673 26.3632C119.239 26.4767 119.76 26.7569 120.168 27.1683C120.576 27.5798 120.854 28.104 120.967 28.6747C121.08 29.2454 121.022 29.8369 120.801 30.3745C120.58 30.9121 120.206 31.3716 119.726 31.6948C119.245 32.0181 118.681 32.1907 118.103 32.1907C117.72 32.1908 117.34 32.1148 116.986 31.967C116.632 31.8192 116.31 31.6025 116.038 31.3293C115.767 31.0561 115.552 30.7317 115.406 30.3747C115.259 30.0177 115.183 29.635 115.184 29.2487Z" fill="#BCF7F0" />
    <path d="M124.837 29.2486C124.837 28.667 125.008 28.0985 125.329 27.615C125.65 27.1315 126.106 26.7547 126.639 26.5323C127.173 26.3098 127.759 26.2516 128.325 26.3652C128.891 26.4787 129.411 26.7587 129.82 27.17C130.228 27.5812 130.506 28.1052 130.618 28.6756C130.731 29.246 130.673 29.8372 130.453 30.3746C130.232 30.9119 129.858 31.3713 129.378 31.6945C128.898 32.0177 128.334 32.1904 127.757 32.1906C127.373 32.1907 126.994 32.1147 126.639 31.9669C126.285 31.8191 125.963 31.6024 125.692 31.3292C125.421 31.056 125.206 30.7316 125.059 30.3746C124.912 30.0176 124.837 29.6349 124.837 29.2486Z" fill="#BCF7F0" />
    <path d="M134.099 29.2486C134.099 28.6669 134.27 28.0984 134.591 27.6149C134.912 27.1314 135.368 26.7546 135.901 26.5322C136.434 26.3097 137.021 26.2516 137.587 26.3652C138.154 26.4788 138.674 26.759 139.082 27.1704C139.49 27.5817 139.768 28.1057 139.88 28.6762C139.993 29.2467 139.935 29.838 139.714 30.3753C139.493 30.9126 139.119 31.3719 138.639 31.695C138.159 32.0181 137.595 32.1906 137.017 32.1906C136.634 32.1907 136.254 32.1147 135.9 31.9669C135.546 31.8191 135.224 31.6023 134.953 31.3291C134.682 31.0559 134.467 30.7315 134.32 30.3745C134.174 30.0175 134.098 29.6349 134.099 29.2486Z" fill="#BCF7F0" />
    <path d="M115.184 38.2657C115.184 37.6839 115.355 37.1151 115.676 36.6313C115.997 36.1474 116.453 35.7704 116.986 35.5477C117.52 35.325 118.107 35.2667 118.673 35.3803C119.239 35.4938 119.76 35.774 120.168 36.1854C120.576 36.5969 120.854 37.1211 120.967 37.6918C121.08 38.2625 121.022 38.854 120.801 39.3916C120.58 39.9292 120.206 40.3887 119.726 40.7119C119.245 41.0352 118.681 41.2078 118.103 41.2078C117.72 41.2079 117.34 41.1319 116.986 40.9841C116.632 40.8363 116.31 40.6196 116.038 40.3464C115.767 40.0731 115.552 39.7488 115.406 39.3918C115.259 39.0348 115.183 38.6521 115.184 38.2657Z" fill="#BCF7F0" />
    <path d="M124.837 38.2661C124.837 37.6845 125.008 37.1161 125.329 36.6326C125.65 36.1491 126.106 35.7723 126.639 35.5498C127.173 35.3274 127.759 35.2692 128.325 35.3827C128.891 35.4962 129.411 35.7763 129.82 36.1876C130.228 36.5988 130.506 37.1227 130.618 37.6931C130.731 38.2635 130.673 38.8548 130.453 39.3922C130.232 39.9295 129.858 40.3888 129.378 40.7121C128.898 41.0353 128.334 41.2079 127.757 41.2081C127.373 41.2083 126.994 41.1323 126.639 40.9845C126.285 40.8367 125.963 40.62 125.692 40.3468C125.421 40.0735 125.206 39.7492 125.059 39.3922C124.912 39.0351 124.837 38.6525 124.837 38.2661Z" fill="#BCF7F0" />
    <path d="M134.099 38.2661C134.099 37.6845 134.27 37.116 134.591 36.6325C134.912 36.149 135.368 35.7722 135.901 35.5497C136.434 35.3273 137.021 35.2692 137.587 35.3828C138.154 35.4964 138.674 35.7766 139.082 36.1879C139.49 36.5993 139.768 37.1233 139.88 37.6938C139.993 38.2642 139.935 38.8555 139.714 39.3929C139.493 39.9302 139.119 40.3895 138.639 40.7126C138.159 41.0357 137.595 41.2081 137.017 41.2081C136.634 41.2083 136.254 41.1323 135.9 40.9845C135.546 40.8366 135.224 40.6199 134.953 40.3467C134.682 40.0735 134.467 39.7491 134.32 39.3921C134.174 39.0351 134.098 38.6525 134.099 38.2661Z" fill="#BCF7F0" />
    <path d="M138.68 48.7787H116.439C116.107 48.7776 115.789 48.644 115.554 48.4071C115.319 48.1702 115.186 47.8492 115.186 47.5143C115.187 47.1796 115.319 46.8589 115.554 46.6222C115.789 46.3855 116.107 46.2521 116.439 46.251H138.68C139.012 46.2518 139.331 46.3851 139.566 46.6218C139.801 46.8586 139.933 47.1794 139.934 47.5143C139.933 47.8493 139.801 48.1703 139.566 48.4072C139.331 48.6441 139.012 48.7776 138.68 48.7787Z" fill="#BCF7F0" />
    <path d="M115.184 58.0456C115.183 57.4637 115.354 56.8948 115.675 56.4108C115.996 55.9269 116.452 55.5496 116.985 55.3268C117.519 55.1039 118.106 55.0455 118.672 55.1589C119.239 55.2723 119.759 55.5525 120.168 55.9639C120.576 56.3753 120.854 56.8995 120.967 57.4702C121.08 58.0409 121.022 58.6325 120.801 59.1702C120.58 59.7078 120.206 60.1674 119.726 60.4907C119.245 60.814 118.681 60.9866 118.103 60.9866C117.72 60.9867 117.34 60.9107 116.986 60.763C116.632 60.6153 116.31 60.3987 116.039 60.1255C115.768 59.8524 115.553 59.5282 115.406 59.1713C115.259 58.8144 115.184 58.4319 115.184 58.0456Z" fill="#BCF7F0" />
    <path d="M124.837 58.0455C124.837 57.4638 125.008 56.8953 125.329 56.4117C125.649 55.928 126.105 55.5511 126.638 55.3285C127.172 55.1058 127.759 55.0475 128.325 55.1609C128.891 55.2743 129.411 55.5543 129.819 55.9655C130.227 56.3767 130.505 56.9007 130.618 57.4711C130.731 58.0415 130.673 58.6328 130.453 59.1702C130.232 59.7076 129.858 60.167 129.378 60.4903C128.898 60.8136 128.334 60.9862 127.757 60.9864C127.373 60.9866 126.994 60.9106 126.639 60.7629C126.285 60.6151 125.963 60.3985 125.692 60.1254C125.421 59.8523 125.206 59.5281 125.059 59.1712C124.912 58.8143 124.837 58.4318 124.837 58.0455Z" fill="#BCF7F0" />
    <path d="M134.099 58.0455C134.099 57.4638 134.27 56.8952 134.591 56.4116C134.911 55.9279 135.367 55.551 135.9 55.3284C136.434 55.1058 137.021 55.0475 137.587 55.161C138.153 55.2745 138.673 55.5546 139.081 55.9659C139.49 56.3772 139.767 56.9012 139.88 57.4717C139.993 58.0422 139.935 58.6335 139.714 59.1709C139.493 59.7083 139.119 60.1677 138.639 60.4908C138.159 60.814 137.595 60.9865 137.017 60.9865C136.634 60.9866 136.254 60.9106 135.9 60.7629C135.546 60.6151 135.224 60.3985 134.953 60.1254C134.682 59.8522 134.467 59.528 134.321 59.1711C134.174 58.8142 134.098 58.4317 134.099 58.0455Z" fill="#BCF7F0" />
    <path d="M115.184 67.0577C115.184 66.4759 115.355 65.9071 115.676 65.4232C115.997 64.9394 116.453 64.5623 116.986 64.3397C117.52 64.117 118.107 64.0587 118.673 64.1723C119.239 64.2858 119.76 64.566 120.168 64.9774C120.576 65.3889 120.854 65.9131 120.967 66.4838C121.08 67.0545 121.022 67.646 120.801 68.1836C120.58 68.7212 120.206 69.1807 119.726 69.5039C119.245 69.8272 118.681 69.9997 118.103 69.9997C117.72 69.9999 117.34 69.9239 116.986 69.7761C116.632 69.6283 116.31 69.4116 116.038 69.1384C115.767 68.8651 115.552 68.5408 115.406 68.1838C115.259 67.8268 115.183 67.4441 115.184 67.0577Z" fill="#BCF7F0" />
    <path d="M124.837 67.0576C124.837 66.476 125.008 65.9076 125.329 65.4241C125.65 64.9406 126.106 64.5638 126.639 64.3414C127.173 64.1189 127.759 64.0607 128.325 64.1742C128.891 64.2877 129.411 64.5678 129.82 64.9791C130.228 65.3903 130.506 65.9143 130.618 66.4847C130.731 67.0551 130.673 67.6463 130.453 68.1837C130.232 68.721 129.858 69.1803 129.378 69.5036C128.898 69.8268 128.334 69.9994 127.757 69.9996C127.373 69.9998 126.994 69.9238 126.639 69.776C126.285 69.6282 125.963 69.4115 125.692 69.1383C125.421 68.865 125.206 68.5407 125.059 68.1837C124.912 67.8267 124.837 67.444 124.837 67.0576Z" fill="#BCF7F0" />
    <path d="M134.099 67.0576C134.099 66.476 134.27 65.9075 134.591 65.424C134.912 64.9405 135.368 64.5637 135.901 64.3413C136.434 64.1188 137.021 64.0607 137.587 64.1743C138.154 64.2879 138.674 64.5681 139.082 64.9794C139.49 65.3908 139.768 65.9148 139.88 66.4853C139.993 67.0557 139.935 67.647 139.714 68.1844C139.493 68.7217 139.119 69.181 138.639 69.5041C138.159 69.8272 137.595 69.9996 137.017 69.9996C136.634 69.9998 136.254 69.9238 135.9 69.776C135.546 69.6281 135.224 69.4114 134.953 69.1382C134.682 68.865 134.467 68.5406 134.32 68.1836C134.174 67.8266 134.098 67.444 134.099 67.0576Z" fill="#BCF7F0" />
    <path d="M138.68 77.5741H116.439C116.107 77.5733 115.789 77.4399 115.554 77.2032C115.319 76.9664 115.186 76.6455 115.186 76.3107C115.187 75.976 115.319 75.6553 115.554 75.4186C115.789 75.1819 116.107 75.0484 116.439 75.0474H138.68C139.012 75.0482 139.331 75.1815 139.566 75.4182C139.801 75.655 139.933 75.9758 139.934 76.3107C139.934 76.6457 139.801 76.9667 139.566 77.2035C139.331 77.4403 139.012 77.5736 138.68 77.5741Z" fill="#BCF7F0" />
    <path d="M85.9346 61.6183V67.8806C85.9319 68.8666 85.5419 69.8114 84.8499 70.5085C84.1578 71.2056 83.2201 71.5983 82.2415 71.6008H71.3142C70.3356 71.5983 69.3979 71.2056 68.7058 70.5085C68.0138 69.8114 67.6238 68.8666 67.6211 67.8806V61.6162L85.9346 61.6183Z" fill="#49B79D" />
    <path d="M80.4374 32.1906H73.1175C72.9822 32.1906 72.8525 32.1364 72.7568 32.04C72.6612 31.9436 72.6074 31.8129 72.6074 31.6766C72.6074 31.5403 72.6612 31.4095 72.7568 31.3131C72.8525 31.2167 72.9822 31.1626 73.1175 31.1626H80.4374C80.5727 31.1626 80.7024 31.2167 80.7981 31.3131C80.8937 31.4095 80.9475 31.5403 80.9475 31.6766C80.9475 31.8129 80.8937 31.9436 80.7981 32.04C80.7024 32.1364 80.5727 32.1906 80.4374 32.1906Z" fill="#49B79D" />
    <path d="M82.9769 36.8663H70.5775C70.4422 36.8663 70.3125 36.8122 70.2168 36.7158C70.1211 36.6194 70.0674 36.4887 70.0674 36.3524C70.0674 36.216 70.1211 36.0853 70.2168 35.9889C70.3125 35.8925 70.4422 35.8384 70.5775 35.8384H82.9769C83.1122 35.8384 83.242 35.8925 83.3376 35.9889C83.4333 36.0853 83.487 36.216 83.487 36.3524C83.487 36.4887 83.4333 36.6194 83.3376 36.7158C83.242 36.8122 83.1122 36.8663 82.9769 36.8663Z" fill="#49B79D" />
    <path d="M82.9769 39.0573H70.5775C70.4422 39.0573 70.3125 39.0031 70.2168 38.9067C70.1211 38.8103 70.0674 38.6796 70.0674 38.5433C70.0674 38.407 70.1211 38.2762 70.2168 38.1798C70.3125 38.0834 70.4422 38.0293 70.5775 38.0293H82.9769C83.1122 38.0293 83.242 38.0834 83.3376 38.1798C83.4333 38.2762 83.487 38.407 83.487 38.5433C83.487 38.6796 83.4333 38.8103 83.3376 38.9067C83.242 39.0031 83.1122 39.0573 82.9769 39.0573Z" fill="#49B79D" />
    <path d="M100.559 78.1743C100.343 78.1743 100.135 78.0876 99.9822 77.9334C99.8291 77.7792 99.7432 77.57 99.7432 77.3519V24.9117C99.7432 24.6936 99.8291 24.4844 99.9822 24.3302C100.135 24.176 100.343 24.0894 100.559 24.0894C100.776 24.0894 100.983 24.176 101.136 24.3302C101.289 24.4844 101.375 24.6936 101.375 24.9117V77.3591C101.374 77.576 101.287 77.7833 101.134 77.936C100.981 78.0886 100.775 78.1743 100.559 78.1743Z" fill="#48B596" />
    <path d="M98.5576 83.397H109.01V87.92C109.01 88.3884 108.918 88.8523 108.74 89.285C108.562 89.7178 108.301 90.111 107.973 90.4423C107.644 90.7735 107.254 91.0362 106.824 91.2155C106.395 91.3948 105.934 91.487 105.469 91.487H102.572C101.507 91.487 100.486 91.0609 99.7334 90.3023C98.9806 89.5437 98.5576 88.5148 98.5576 87.442V83.397Z" fill="#49A292" />
    <path d="M103.679 94.3494C103.397 94.3486 103.126 94.2352 102.926 94.0339C102.727 93.8326 102.614 93.5599 102.613 93.2752V91.0425C102.614 90.7578 102.727 90.4851 102.926 90.2838C103.126 90.0825 103.397 89.9691 103.679 89.9683C103.962 89.9691 104.233 90.0825 104.432 90.2838C104.632 90.4851 104.745 90.7578 104.745 91.0425V93.2711C104.746 93.5564 104.634 93.8303 104.434 94.0324C104.234 94.2346 103.963 94.3486 103.679 94.3494Z" fill="#49A292" />
    <path d="M33.042 66.8421C33.042 66.8421 34.2193 66.8421 37.0799 68.1702C37.9114 68.5557 39.3652 69.9681 40.2476 70.1829C40.416 70.2241 40.5822 70.2631 40.7495 70.3012C41.6336 70.514 42.5339 70.6516 43.4408 70.7123C43.4408 70.7123 46.835 69.3996 50.1006 65.7411C50.3312 65.4835 50.5608 65.2135 50.7893 64.9311C50.9127 64.779 50.8678 63.6616 50.7597 62.2728C50.5556 59.6978 50.1272 56.1914 50.1272 56.1914C50.1272 56.1914 47.7644 57.8176 42.288 66.1184C42.1125 66.3867 41.6667 66.3507 40.5955 66.141C39.2335 65.8737 38.4276 66.4083 34.2295 65.7874C34.2295 65.7874 33.6796 65.7031 33.6388 66.1657C33.5531 67.1823 33.042 66.8421 33.042 66.8421Z" fill="#FF7B69" />
    <path d="M54.3262 41.5112C54.3262 41.5112 60.6942 36.8617 62.3184 41.3004C62.3184 41.3004 64.0405 42.5998 63.9007 46.0804C63.7589 49.632 60.8881 51.6211 59.8985 50.6929C59.2639 50.0977 58.3763 51.2541 58.3763 51.2541L54.3262 41.5112Z" fill="#501506" />
    <path d="M59.6901 40.0992C59.6901 40.0992 63.1261 37.7914 67.1069 40.901C71.0877 44.0106 66.8733 50.533 68.1108 53.1059C68.1108 53.1059 66.0377 51.1662 66.7712 49.283C66.7712 49.283 65.6072 52.3997 66.8733 54.2449C68.9636 57.298 63.3985 61.2761 61.3468 60.0128C59.6707 58.9797 61.1816 58.3609 58.831 54.2449C57.3701 51.6863 65.0073 46.8066 59.6901 40.0992Z" fill="#501506" />
    <path d="M65.2259 81.9634C63.2508 77.499 62.3051 76.2366 62.2673 74.8314C62.2408 73.7212 62.7846 72.5226 63.8405 69.5889C64.086 68.9002 64.262 68.1883 64.3659 67.4641C65.2014 61.799 61.9501 55.6744 59.9291 55.2571C59.3669 55.107 58.4141 53.0614 58.4253 49.7637L58.1723 49.814L53.9171 50.6631C53.9171 50.6631 54.0191 52.0118 54.0191 53.2834C54.0191 54.3432 53.9579 55.3465 53.7202 55.4729C53.4141 55.5007 53.2335 55.5192 53.2335 55.5192C53.2335 55.5192 49.6853 60.4842 49.3864 65.1018C49.0875 69.7194 52.2276 71.726 49.3629 80.9539C46.4411 90.3721 66.3175 84.4305 65.2259 81.9634Z" fill="#FCBDB6" />
    <path d="M53.9219 50.6567C53.9219 50.6567 54.0239 52.0054 54.0239 53.277C56.405 53.2091 57.664 51.0155 58.1791 49.8076L53.9219 50.6567Z" fill="#F9A6A0" />
    <path d="M58.617 49.3406C58.617 49.3406 59.8647 47.3536 60.1646 45.6554C60.3779 44.4424 59.7647 39.9461 55.282 40.5937C54.3789 40.7201 53.5153 41.0488 52.755 41.5559C51.7817 42.2189 50.3769 43.6488 51.948 47.5715L52.3561 48.8965L58.617 49.3406Z" fill="#501506" />
    <path d="M60.2362 124.8C59.3716 128.252 58.1828 131.613 56.686 134.837L54.8282 134.708C54.8282 134.708 55.7392 130.34 55.6862 124.44C55.6495 120.314 55.1434 115.439 53.5254 110.784C47.9664 94.7872 48.8652 83.1445 48.8652 83.1445L59.3191 84.3781L59.8057 84.4357C59.8496 89.2732 59.973 95.846 60.0822 100.978C60.1781 105.596 60.2618 109.056 60.2618 109.056C62.2715 113.419 61.5421 119.572 60.2362 124.8Z" fill="#1C3A3E" />
    <path d="M48.6917 66.3154C48.5836 70.9206 49.0733 77.5263 49.7323 82.3515C49.8344 83.1307 49.9466 85.6255 50.5036 86.0902C53.0816 88.2489 57.3471 87.48 58.8396 87.1448C60.0829 86.8707 61.0473 85.5505 61.7329 83.1841C61.9604 84.834 62.4715 86.7655 63.6162 86.8848C63.8579 86.9105 64.0824 86.9259 64.2905 86.9351C65.68 86.9876 66.187 87.8439 66.3829 87.2219C66.4472 87.0164 65.7484 84.7641 65.729 84.4033C65.6361 82.5273 65.5066 78.8616 65.3831 74.8032C65.3434 73.5368 65.3056 72.2333 65.2689 70.9319C65.1985 68.4514 65.1383 65.7716 65.0903 63.3918C65.0495 61.3174 65.0169 59.4723 64.9955 58.1853C64.9873 57.5624 64.7659 56.9615 64.3686 56.4842C63.9713 56.0069 63.4226 55.6825 62.8153 55.566C62.4225 55.492 62.0257 55.4344 61.6574 55.3913C61.3615 55.3553 61.0861 55.3296 60.8412 55.309C60.2985 55.2628 59.9312 55.2515 59.9312 55.2515C59.9312 55.2515 59.9516 55.9258 59.9312 56.7307C59.9088 57.7977 59.819 59.095 59.5303 59.3612C59.4652 59.4082 59.3923 59.443 59.315 59.464H59.3089C57.9673 59.9297 51.6574 60.528 51.4983 59.7395C51.4848 59.6511 51.4868 59.561 51.5044 59.4733C51.5034 59.4699 51.5034 59.4663 51.5044 59.463C51.5891 58.9737 51.987 58.1698 52.4114 57.4071C52.9286 56.4819 53.4836 55.6226 53.5611 55.4828C53.4857 55.482 53.4103 55.4872 53.3357 55.4982L53.2275 55.5095L52.4297 55.6184C51.9921 55.6801 51.6472 55.7315 51.3708 55.7788C50.5362 55.9227 50.3271 56.0235 50.12 56.1838C49.3844 56.926 48.4285 58.764 48.353 62.0432C48.3321 63.4748 48.4455 64.9052 48.6917 66.3154Z" fill="#FF7B69" />
    <path d="M66.1085 124.921C65.5327 130.524 62.982 134.144 62.982 134.144L62.1285 134.708C62.1285 134.708 61.3212 130.763 60.7492 126.071C60.2048 121.611 59.4582 116.891 58.6135 115.45C55.2614 109.736 54.252 88.1661 54.252 88.1661L64.4437 87.7075C65.4689 89.272 65.9153 91.8372 66.0222 94.7542C66.29 102.115 64.3839 111.714 64.0984 113.102C64.0816 113.209 64.1051 113.318 64.1651 113.412C66.1968 117.058 66.4901 121.227 66.1085 124.921Z" fill="#24555E" />
    <path d="M66.2343 123.894C65.6334 130.357 62.987 134.537 62.987 134.537L62.0831 135.366C62.0831 135.366 61.2598 130.637 60.665 125.216C61.2914 125.261 64.0521 125.363 66.2343 123.894Z" fill="#FCBDB6" />
    <path d="M64.6565 74.5302C64.8798 74.6654 65.1261 74.7578 65.3829 74.8026C65.3431 73.5361 65.3054 72.2327 65.2686 70.9313C65.1982 68.4508 65.138 65.771 65.0901 63.3912C64.27 62.5727 63.2315 62.012 62.1009 61.7773C59.6382 62.6758 61.6082 72.7909 64.6565 74.5302Z" fill="#FF4B42" />
    <path d="M60.2362 124.8C59.3715 128.252 58.1827 131.613 56.6859 134.837L54.8281 134.708C54.8281 134.708 55.7392 130.34 55.6861 124.439C56.3166 124.759 57.6184 125.085 60.2362 124.8Z" fill="#FCBDB6" />
    <path d="M57.7555 136.407H56.5701C56.1722 136.344 55.8794 136.228 55.4713 136.175C54.8531 136.093 54.5684 136.348 54.5113 136.407L54.5001 136.419H49.4624C49.3348 136.237 49.4624 135.815 49.5756 135.503C49.6155 135.399 49.679 135.306 49.7609 135.23C49.8429 135.155 49.9412 135.1 50.048 135.07C50.5825 134.913 51.6354 134.774 52.5984 133.895C53.1402 133.4 55.2183 132.077 55.2183 132.077H55.2295C55.2754 132.077 55.4927 132.145 56.4374 132.949C56.9261 133.36 57.7586 132.363 57.7586 132.363C57.8731 132.468 57.966 132.595 58.032 132.736C58.1495 132.978 58.1874 133.251 58.1401 133.515C58.0646 133.973 58.3074 134.823 58.5013 135.398C58.6186 135.747 58.7176 135.994 58.7176 135.994C58.7176 135.994 58.7767 136.278 57.7555 136.407Z" fill="#333333" />
    <path d="M58.0334 132.734C57.7498 132.94 57.153 133.331 56.7561 133.384C56.2205 133.454 55.2197 132.077 55.2197 132.077H55.2309C55.2769 132.077 55.4942 132.145 56.4389 132.949C56.9275 133.36 57.76 132.363 57.76 132.363C57.8743 132.467 57.9672 132.594 58.0334 132.734Z" fill="#333333" />
    <path d="M57.7555 136.407H56.5701C56.1722 136.344 55.8794 136.228 55.4713 136.175C54.8531 136.093 54.5684 136.348 54.5113 136.407L54.5001 136.419H49.4624C49.3348 136.237 49.4624 135.815 49.5756 135.503C49.7286 135.367 49.7725 136.099 49.8572 136.216C49.9419 136.333 53.9665 136.258 54.1991 136.088C54.4317 135.919 55.1295 135.62 56.1018 135.854C56.9475 136.06 58.1952 135.535 58.5033 135.396C58.6206 135.745 58.7196 135.992 58.7196 135.992C58.7196 135.992 58.7767 136.278 57.7555 136.407Z" fill="#333333" />
    <path d="M64.0846 136.407H62.8991C62.5023 136.344 62.2085 136.228 61.8004 136.175C61.1822 136.093 60.8975 136.348 60.8404 136.407L60.8292 136.419H55.7914C55.6639 136.237 55.7914 135.815 55.9057 135.503C55.9455 135.399 56.0088 135.306 56.0906 135.23C56.1724 135.155 56.2705 135.1 56.377 135.07C56.9116 134.913 57.9645 134.774 58.9275 133.895C59.4703 133.4 61.5474 132.077 61.5474 132.077H61.5586C61.6045 132.077 61.8218 132.145 62.7665 132.949C63.2552 133.36 64.0928 132.363 64.0928 132.363C64.2068 132.468 64.2993 132.595 64.3652 132.736C64.4827 132.978 64.5206 133.251 64.4733 133.515C64.3978 133.973 64.6406 134.823 64.8345 135.398C64.9518 135.747 65.0507 135.994 65.0507 135.994C65.0507 135.994 65.1058 136.278 64.0846 136.407Z" fill="#333333" />
    <path d="M64.3635 132.734C64.0809 132.94 63.4831 133.331 63.0862 133.384C62.5516 133.454 61.5498 132.077 61.5498 132.077H61.561C61.6069 132.077 61.8242 132.145 62.7689 132.949C63.2576 133.36 64.0952 132.363 64.0952 132.363C64.2076 132.468 64.2987 132.594 64.3635 132.734Z" fill="#333333" />
    <path d="M64.0846 136.407H62.8991C62.5023 136.344 62.2085 136.228 61.8004 136.175C61.1822 136.093 60.8975 136.348 60.8404 136.407L60.8292 136.419H55.7914C55.6639 136.237 55.7914 135.815 55.9057 135.503C56.0577 135.367 56.1016 136.099 56.1863 136.216C56.2709 136.333 60.2956 136.258 60.5282 136.088C60.7608 135.919 61.4586 135.62 62.4309 135.854C63.2766 136.06 64.5243 135.535 64.8324 135.396C64.9497 135.745 65.0487 135.992 65.0487 135.992C65.0487 135.992 65.1058 136.278 64.0846 136.407Z" fill="#333333" />
    <path d="M40.2487 70.1826C40.417 70.2237 40.5833 70.2628 40.7506 70.3008C41.6347 70.5137 42.5349 70.6512 43.4419 70.712C43.4419 70.712 46.8361 69.3993 50.1017 65.7408C50.3323 65.4831 50.5618 65.2131 50.7904 64.9308C50.9138 64.7786 50.8689 63.6612 50.7608 62.2725C48.9081 63.9912 43.8724 68.6972 42.995 69.8197C42.995 69.8197 42.1401 70.3779 40.9597 69.5813C40.3731 69.1814 40.2375 69.6275 40.2487 70.1826Z" fill="#FF4B42" />
    <path d="M52.8955 51.2034C52.8955 51.2034 58.3321 52.6971 59.2064 49.274C60.0807 45.8509 61.3662 43.7343 57.9322 42.6354C54.4982 41.5365 53.4882 42.6693 52.922 43.7415C52.3558 44.8137 51.504 50.7275 52.8955 51.2034Z" fill="#FCBDB6" />
    <path d="M58.7158 59.4682H59.8748C59.8752 59.4662 59.8752 59.4641 59.8748 59.4621C59.9227 59.313 60.8766 56.4029 61.655 55.3903C61.3592 55.3544 61.0837 55.3287 60.8389 55.3081C60.6624 55.5558 60.3114 56.0688 59.9309 56.7298C59.4229 57.5926 59.0152 58.5115 58.7158 59.4682Z" fill="#333333" />
    <path d="M50.5039 59.4673H51.6639C51.6639 59.4673 51.9903 58.4701 52.4188 57.4114C52.7034 56.7093 53.0309 55.9804 53.337 55.5024L53.2288 55.5137L52.4311 55.6227C51.921 56.3628 51.0028 57.7567 50.5304 59.3573C50.5243 59.3933 50.5141 59.4303 50.5039 59.4673Z" fill="#333333" />
    <path d="M48.3096 105.931C48.3104 106.272 48.4449 106.598 48.6836 106.839C48.9224 107.079 49.246 107.215 49.5838 107.216H63.0146C63.3526 107.215 63.6765 107.08 63.9155 106.839C64.1545 106.598 64.2891 106.272 64.2899 105.931L64.9683 92.4815L65.7294 77.3705C65.71 77.2235 65.4315 72.7447 65.3417 72.6327C64.0695 71.0177 62.2169 66.5472 61.3405 63.2783C61.0345 62.1382 60.8488 61.1452 60.8478 60.4719C60.8508 60.2082 60.7505 59.9539 60.5687 59.7642C60.3869 59.5744 60.1382 59.4646 59.8765 59.4584H59.8571L59.1236 59.4645H58.7574C58.7295 59.463 58.7016 59.463 58.6737 59.4645C56.268 59.76 53.8353 59.7569 51.4304 59.4553C51.1569 59.4203 50.9203 59.3844 50.7162 59.3525C50.6585 59.3435 50.6 59.3411 50.5418 59.3453C50.3565 59.3613 50.1829 59.4433 50.0521 59.5764C49.9213 59.7095 49.8417 59.8852 49.8276 60.0721L49.4226 64.7965L48.8737 71.248C48.6911 72.5617 48.3096 105.931 48.3096 105.931Z" fill="#333333" />
    <path d="M55.4619 41.9111C55.4619 41.9111 55.9394 46.3149 58.0828 47.4991C60.2262 48.6833 61.378 48.3215 61.378 48.3215C61.378 48.3215 60.6884 45.3044 60.9455 42.752C60.9455 42.7499 57.0738 40.288 55.4619 41.9111Z" fill="#501506" />
    <path d="M56.1602 68.8519C56.1602 68.8519 58.3026 87.8146 64.9685 92.4816L65.7296 77.3706C65.7102 77.2236 65.4317 72.7448 65.3419 72.6327C64.0697 71.0178 62.2171 66.5472 61.3407 63.2783C61.2489 63.3158 61.1662 63.3726 61.0979 63.4448C58.0434 66.5853 56.1602 68.8519 56.1602 68.8519Z" fill="#333333" />
    <path d="M55.7499 42.3323C55.7499 42.3323 54.4074 42.9429 53.9136 43.7457C53.4198 44.5485 52.8444 46.3156 52.3404 46.1182C52.3404 46.1182 51.9058 43.0971 53.5647 41.8769C55.3184 40.5827 55.7499 42.3323 55.7499 42.3323Z" fill="#501506" />
    <path d="M55.5078 42.4147C55.5078 42.4147 55.5078 46.8987 58.0583 48.9936C58.0583 48.9936 55.8139 45.663 56.276 42.8259C56.7382 39.9887 55.5078 42.4147 55.5078 42.4147Z" fill="#501506" />
    <path d="M52.6363 49.094C52.6363 49.094 52.5853 49.4723 53.4626 49.7509C53.4626 49.7509 54.1992 49.9853 54.3227 49.5957C54.3227 49.5957 53.7289 49.0529 52.6363 49.094Z" fill="white" />
    <path d="M43.0967 67.699C43.0967 67.699 47.5662 69.9605 51.4419 70.897C52.557 71.1663 53.6313 71.3575 54.5484 71.4007C54.5484 71.4007 58.7159 70.1096 61.8938 65.6194C68.0374 56.9486 63.2935 56.0132 63.2935 56.0132C63.2935 56.0132 59.1903 58.5934 53.3976 66.8067C53.1936 67.0946 52.6835 67.0288 51.3674 66.7605C49.9586 66.4737 47.1551 66.5086 43.2855 65.9381C43.2855 65.9381 43.2202 66.0831 43.1834 66.5446C43.0916 67.5643 43.0967 67.699 43.0967 67.699Z" fill="#FF7B69" />
    <path d="M33.042 66.8419C33.042 66.8419 34.711 67.1082 37.5461 68.8783C38.4735 69.4571 39.712 70.0677 40.7495 70.301C41.1658 68.4342 40.8791 66.9797 40.5955 66.1368C39.2325 65.8695 37.3441 66.2591 33.1481 65.6382L33.042 66.8419Z" fill="#F9A6A0" />
    <path d="M40.6538 60.6084L40.6028 61.7772L40.4977 64.2186L40.4488 65.3442H37.3729L37.3035 64.2186L37.1525 61.7772L37.0801 60.6084H40.6538Z" fill="#FF4B42" />
    <path d="M40.714 59.8198H37.0699L36.9199 60.6083H40.816L40.714 59.8198Z" fill="#49B79D" />
    <path d="M40.6036 61.7773L40.4985 64.2187H37.3043L37.1533 61.7773H40.6036Z" fill="#49B79D" />
    <path d="M35.0748 60.6084L35.0237 61.7772L34.9176 64.2186L34.8697 65.3442H31.7938L31.7234 64.2186L31.5724 61.7772L31.5 60.6084H35.0748Z" fill="#FF4B42" />
    <path d="M35.133 59.8198H31.4888L31.3389 60.6083H35.236L35.133 59.8198Z" fill="#49B79D" />
    <path d="M35.0236 61.7773L34.9175 64.2187H31.7233L31.5723 61.7773H35.0236Z" fill="#49B79D" />
    <path d="M43.7204 65.3433H28.292V66.1615H43.7204V65.3433Z" fill="#B27D59" />
    <path d="M42.9951 67.8175C42.9951 67.8175 47.5676 69.9618 51.4434 70.9014C51.1105 70.2662 50.9301 69.5611 50.9168 68.8428C50.9035 68.1246 51.0577 67.4132 51.3668 66.7659C49.958 66.4791 47.1351 66.3547 43.2655 65.7832C43.2655 65.7832 43.2196 66.0916 43.1798 66.548C43.0931 67.5647 42.9951 67.8175 42.9951 67.8175Z" fill="#FCBDB6" />
    <path d="M43.7563 65.9021C43.0392 65.7522 42.3421 65.5179 41.6792 65.2041C40.7345 64.7282 38.2106 64.4115 37.6811 64.9183C37.1516 65.4251 38.2473 67.4882 40.5458 67.552C42.8443 67.6157 43.5043 67.996 43.5043 67.996L43.7563 65.9021Z" fill="#FCBDB6" />
    <path d="M65.9505 72.3721C66.282 72.3721 68.2969 73.5296 68.3877 73.9613C68.4785 74.393 65.3271 75.7499 65.3271 75.7499C65.3271 75.7499 65.6179 72.368 65.9505 72.3721ZM67.7134 74.0291C67.7674 73.8482 66.4259 73.435 66.1831 73.436C65.9403 73.437 65.4506 75.6225 65.4506 75.6225C65.4506 75.6225 67.6593 74.2101 67.7134 74.0291Z" fill="#333333" />
    <path d="M67.4486 75.6249C67.4935 75.8202 67.116 77.1669 66.8804 77.283C66.6447 77.3992 65.4082 75.7411 65.4082 75.7411C65.4082 75.7411 67.4027 75.4296 67.4486 75.6249ZM66.7447 76.8965C66.8569 76.9027 66.9028 76.0567 66.8661 75.9117C66.8293 75.7668 65.499 75.7966 65.499 75.7966C65.499 75.7966 66.6314 76.8914 66.7447 76.8965Z" fill="#333333" />
    <path d="M65.269 76.8965C65.269 76.8965 68.1102 79.341 68.5336 82.5626C68.957 85.7842 69.6966 86.2632 69.6966 86.2632C69.6966 86.2632 69.3365 86.5202 69.0723 86.3876C68.808 86.255 67.9796 83.975 67.9501 82.9131C67.9205 81.8512 66.6422 78.7437 65.2669 77.8936C63.8917 77.0435 65.269 76.8965 65.269 76.8965Z" fill="#333333" />
    <path d="M42.7644 65.638C42.7644 65.638 42.3236 65.196 42.2308 64.9298C42.138 64.6636 40.7627 64.2884 40.6515 64.3901C40.4393 64.5854 41.3085 65.231 41.3085 65.231L42.7644 65.638Z" fill="#FCBDB6" />
    <path d="M33.0432 66.8417C33.0432 66.8417 31.7169 67.3465 30.9783 67.1871C30.2397 67.0278 29.2256 66.1592 29.2256 66.1592H33.1023L33.0432 66.8417Z" fill="#F9A6A0" />
    <path d="M174.12 136.999H28.2885C28.1222 136.999 27.9824 136.813 27.9824 136.588C27.9824 136.361 28.1191 136.177 28.2885 136.177H174.12C174.286 136.177 174.426 136.363 174.426 136.588C174.423 136.813 174.284 136.999 174.12 136.999Z" fill="#1C3A3E" />
    <path d="M135.319 44.973C135.319 44.973 142.002 46.8162 145.317 45.0101C144.763 44.1082 143.91 43.4336 142.909 43.1063C142.362 42.8657 142.526 42.4895 142.5 42.0629C142.46 41.2485 142.375 40.4369 142.246 39.6318L137.925 37.3199L136.956 36.8008C137.108 37.4665 137.198 38.1452 137.224 38.8279C137.224 38.8773 137.229 38.9307 137.23 38.977C137.32 42.7527 135.319 44.973 135.319 44.973Z" fill="#FCBDB6" />
    <path d="M137.188 38.9276C137.188 38.9276 137.201 38.9451 137.228 38.978C137.546 39.3583 139.684 41.7925 142.499 42.0618C142.458 41.2474 142.373 40.4358 142.245 39.6307L137.923 37.3188C137.651 37.8026 137.417 38.3068 137.222 38.8269L137.188 38.9276Z" fill="#F9A6A0" />
    <path d="M142.926 41.1853C142.926 41.1853 136.915 41.4063 136.835 37.6326C136.755 33.859 135.928 31.3446 139.762 31.0445C143.596 30.7443 144.373 32.168 144.704 33.4211C145.034 34.6742 144.487 41.0311 142.926 41.1853Z" fill="#FCBDB6" />
    <path d="M138.036 36.8185C138.036 36.8185 137.355 35.9211 137.869 35.4822C138.384 35.0432 138.547 35.8368 138.905 35.8594C139.263 35.8821 140.036 34.9147 140.206 33.967C140.375 33.0192 143.607 34.9189 145.038 34.6434C146.47 34.3679 147.91 32.6748 145.891 31.3991C144.704 30.6497 138.782 26.8124 135.995 29.5632C132.444 33.0706 135.995 37.6841 136.461 38.38C136.928 39.0759 137.261 38.9289 137.261 38.9289C137.261 38.9289 138.393 37.7385 138.036 36.8185Z" fill="#501506" />
    <path d="M136.309 29.7881C136.272 29.5013 136.189 28.7416 136.352 28.2358C136.352 28.2358 136.484 29.0109 136.669 29.383C136.853 29.7552 136.309 29.7881 136.309 29.7881Z" fill="#501506" />
    <path d="M135.574 29.0918C135.574 29.0918 136.323 29.0291 136.481 29.6058L136.277 30.0036C136.277 30.0036 136.187 29.2512 135.574 29.0918Z" fill="#501506" />
    <path d="M150.145 69.5709L132.319 70.9432C132.319 70.9432 132.287 67.846 132.248 63.7311C132.214 59.794 132.174 54.9236 132.153 50.9443C132.135 47.3763 132.134 44.5237 132.166 43.7013C132.166 43.7013 133.321 43.1874 136.471 43.026C136.471 43.026 138.715 45.7737 140.943 44.9688C143.172 44.1639 142.533 42.77 142.533 42.77C142.754 42.77 142.974 42.7813 143.191 42.7906C143.536 42.805 143.876 42.8245 144.211 42.8492C145.401 42.9407 146.504 43.1103 147.427 43.3858C152.675 44.9565 150.145 69.5709 150.145 69.5709Z" fill="#FF7B69" />
    <path d="M136.043 136.225C135.348 135.624 135.672 130.955 135.672 130.955C135.672 130.955 139.345 129.378 139.838 131.588C140.331 133.798 143.241 134.791 144.897 135.061C146.002 135.242 146.37 135.843 146.493 136.225H136.043Z" fill="#FF4B42" />
    <path d="M154.21 136.225C153.515 135.624 153.931 132.264 153.931 132.264C153.931 132.264 156.271 129.353 157.258 131.37C158.246 133.387 161.408 134.791 163.065 135.061C164.17 135.242 164.537 135.843 164.66 136.225H154.21Z" fill="#FF4B42" />
    <path d="M150.191 77.5049C150.191 77.5049 152.866 119.878 157.503 131.657C157.503 131.657 154.387 133.636 152.673 132.322C152.673 132.322 141.978 110.14 143.471 105.1C144.471 101.723 133.126 77.8174 133.126 77.8174L150.191 77.5049Z" fill="#333333" />
    <path d="M132.582 75.0277C132.524 75.0189 132.465 75.0238 132.409 75.042C132.354 75.0601 132.303 75.091 132.261 75.1323C132.219 75.1735 132.188 75.2239 132.168 75.2796C132.149 75.3353 132.143 75.3947 132.151 75.4532C132.575 78.7766 134.884 97.6828 133.127 102.873C130.944 109.324 135.261 131.526 135.261 131.526C135.261 131.526 138.507 132.914 140.668 131.578C140.668 131.578 141.337 107.545 142.265 102.769C143.192 97.9932 148.177 77.3847 148.177 77.3847L132.582 75.0277Z" fill="#333333" />
    <path d="M132.252 63.7311C132.217 59.794 132.178 54.9236 132.157 50.9443C133.578 51.4306 135.942 52.6518 136.496 55.4817C137.038 58.2552 134.557 62.258 132.252 63.7311Z" fill="#FF4B42" />
    <path d="M162.565 53.5071C162.565 53.5071 161.869 53.4506 160.747 53.3467C158.381 53.1278 154.128 52.694 150.577 52.1245C147.732 51.6671 145.336 51.1222 144.721 50.5271C142.976 48.8402 143.778 42.8174 143.778 42.8174C145.015 42.8294 146.243 43.0198 147.427 43.3828C152.324 44.971 162.45 48.613 163.61 50.5178C165.103 52.9798 162.565 53.5071 162.565 53.5071Z" fill="#FF7B69" />
    <path d="M133.434 43.3633C133.434 43.3633 130.331 43.9287 129.619 45.4398C128.907 46.9509 124.341 58.097 124.666 62.179C124.666 62.179 125.405 63.5698 126.4 64.2925C126.4 64.2925 131.093 63.504 131.424 60.2105C131.424 60.2105 131.181 59.7386 131.449 59.3048C131.718 58.871 137.425 53.4568 137.459 51.8295C137.494 50.2023 133.434 43.3633 133.434 43.3633Z" fill="#FF7B69" />
    <path d="M152.441 91.4203L150.754 57.8544C150.765 56.0894 150.012 54.716 149.686 53.3314C149.482 52.4638 148.87 52.3548 148.345 52.4062C147.93 51.3916 145.018 44.3007 144.21 42.8462C143.877 42.8215 143.537 42.802 143.19 42.7876C143.496 43.5997 145.892 48.2358 146.742 52.5429L145.515 52.5635L138.476 52.6848C138.328 51.995 136.427 43.2574 135.703 43.0744L134.87 43.1515C134.87 43.1515 136.798 48.0775 137.043 52.7115L136.737 52.7166C136.509 52.7206 136.292 52.8131 136.131 52.9749C135.969 53.1366 135.876 53.355 135.871 53.5842C135.8 57.3651 132.217 59.8445 132.217 59.8445L132.142 75.3923C132.519 77.2118 132.945 79.4733 133.383 81.8438C134.097 85.6678 134.837 89.7745 135.467 92.7535C135.786 94.2605 138.568 94.0518 140.097 94.0518H149.975C150.311 94.0517 150.644 93.9824 150.952 93.8482C151.261 93.714 151.54 93.5177 151.77 93.2714C152.001 93.025 152.18 92.7338 152.295 92.4155C152.411 92.0972 152.46 91.7586 152.441 91.4203Z" fill="#24555E" />
    <path d="M151.339 66.4051C151.339 66.4051 150.421 67.3868 150.389 68.165L149.227 68.0067C149.227 68.0067 150.568 66.4319 151.421 66.2222L151.339 66.4051Z" fill="white" />
    <path d="M133.383 81.8406C132.945 79.4701 132.519 77.2086 132.142 75.3892L132.217 59.8413C132.217 59.8413 135.8 57.3619 135.871 53.5811C135.87 53.5811 142.941 77.1079 133.383 81.8406Z" fill="#1C3A3E" />
    <path d="M144.82 69.6275C145.126 70.1188 146.27 70.5691 146.27 70.5691C146.553 70.998 146.96 71.3295 147.435 71.5196C147.911 71.7096 148.432 71.7491 148.931 71.6327C149.429 71.5163 149.88 71.2496 150.224 70.8682C150.568 70.4868 150.788 70.0088 150.855 69.498L150.985 68.5183C150.999 68.4133 151.006 68.3076 151.006 68.2017L146.056 67.5366C146.029 67.638 146.009 67.741 145.994 67.845L145.931 68.3261C145.61 68.212 145.162 68.0938 144.911 68.2079C144.483 68.3939 144.509 69.1299 144.82 69.6275ZM145.255 68.5718C145.459 68.5625 145.71 68.7362 145.849 68.9953C145.83 69.283 145.86 69.572 145.938 69.8495C145.671 69.7467 145.132 69.4938 145.051 69.1515C144.947 68.7126 145.074 68.5749 145.255 68.5666V68.5718Z" fill="#1C3A3E" />
    <path d="M150.602 68.1422L149.93 68.0518C149.93 68.158 149.923 68.2641 149.909 68.3694L149.779 69.348C149.697 69.9553 149.4 70.5124 148.943 70.9166C148.486 71.3208 147.899 71.545 147.291 71.5478C147.4 71.5791 147.511 71.6025 147.623 71.6177C148.284 71.7043 148.953 71.5237 149.482 71.1154C150.012 70.7072 150.36 70.1043 150.451 69.4385L150.58 68.4588C150.594 68.3538 150.601 68.2481 150.602 68.1422Z" fill="#24555E" />
    <path d="M147.002 71.6183L149.039 71.8921L149.08 71.5844L147.043 71.3106L147.002 71.6183Z" fill="#1C3A3E" />
    <path d="M145.965 69.1293C145.863 69.6967 145.557 70.7277 144.788 70.774C144.018 70.8203 139.176 69.5877 139.176 69.5877C134.043 68.8846 129.587 66.0927 127.013 64.1478C128.296 63.8024 131.163 62.7539 131.422 60.2056C132.748 61.5419 140.342 66.9109 140.342 66.9109C140.764 66.8338 142.866 66.4761 143.965 66.7537C145.197 67.0682 146.067 68.5629 145.965 69.1293Z" fill="#FCBDB6" />
    <path d="M158.345 62.6601L158.549 62.9921C158.577 63.0377 158.585 63.0923 158.573 63.1443C158.562 63.1963 158.53 63.2416 158.486 63.2707L154.337 65.8786C154.291 65.9064 154.237 65.9152 154.185 65.9031C154.133 65.891 154.088 65.859 154.059 65.8138L153.855 65.4828C153.828 65.4371 153.819 65.3824 153.831 65.3304C153.843 65.2785 153.875 65.2332 153.92 65.2043L158.064 62.5964C158.086 62.5819 158.111 62.5721 158.138 62.5675C158.164 62.5629 158.191 62.5635 158.217 62.5695C158.244 62.5754 158.268 62.5864 158.29 62.602C158.312 62.6175 158.331 62.6373 158.345 62.6601Z" fill="#24555E" />
    <path d="M158.344 62.725L158.548 63.056C158.576 63.1017 158.585 63.1565 158.573 63.2087C158.561 63.2609 158.53 63.3065 158.485 63.3356L156.343 64.6853C156.297 64.7131 156.243 64.7219 156.191 64.7098C156.139 64.6977 156.094 64.6656 156.065 64.6205L155.861 64.2895C155.834 64.2438 155.825 64.1891 155.837 64.1371C155.849 64.0851 155.881 64.0399 155.925 64.0109L158.073 62.6612C158.118 62.6352 158.171 62.6273 158.221 62.6392C158.271 62.651 158.315 62.6817 158.344 62.725Z" fill="#1C3A3E" />
    <path d="M156.592 67.3424L156.686 67.7536C156.696 67.7999 156.692 67.8482 156.673 67.8919C156.655 67.9356 156.624 67.9724 156.584 67.9972L153.721 69.7962C153.688 69.8173 153.649 69.8274 153.61 69.8252C153.571 69.823 153.534 69.8085 153.504 69.7838L153.132 69.4868C153.109 69.468 153.091 69.4445 153.078 69.4179L151.796 66.6527L151.428 66.4604C151.401 66.4461 151.377 66.4245 151.36 66.3979C151.343 66.3713 151.334 66.3405 151.333 66.309C151.332 66.2775 151.34 66.2462 151.355 66.2186C151.37 66.191 151.392 66.168 151.419 66.1521L152.602 65.4325L154.28 64.4179C154.301 64.4049 154.325 64.3968 154.35 64.3944C154.375 64.3919 154.4 64.3951 154.423 64.4037C154.447 64.4122 154.468 64.426 154.485 64.444C154.503 64.4619 154.516 64.4836 154.524 64.5073L154.642 64.8568L156.533 67.2139C156.563 67.2512 156.583 67.2953 156.592 67.3424Z" fill="#24555E" />
    <path d="M156.591 67.4073L156.685 67.8184C156.696 67.8645 156.691 67.9128 156.673 67.9563C156.655 67.9999 156.623 68.0365 156.583 68.061L154.967 69.0767C154.934 69.0976 154.895 69.1077 154.856 69.1055C154.818 69.1032 154.78 69.0889 154.75 69.0643L154.379 68.7672C154.357 68.7487 154.338 68.7251 154.325 68.6984L153.042 65.9332L152.676 65.7409C152.655 65.7304 152.637 65.7159 152.622 65.6982C152.607 65.6805 152.596 65.66 152.589 65.6379C152.582 65.6158 152.58 65.5925 152.582 65.5695C152.584 65.5464 152.59 65.524 152.601 65.5035L154.279 64.4889C154.301 64.4758 154.325 64.4677 154.349 64.4652C154.374 64.4627 154.399 64.4658 154.422 64.4742C154.446 64.4827 154.467 64.4964 154.484 64.5142C154.502 64.532 154.515 64.5536 154.523 64.5773L154.642 64.9278L156.532 67.2849C156.561 67.3202 156.582 67.3622 156.591 67.4073Z" fill="#1C3A3E" />
    <path d="M141.842 39.3029C141.842 39.3029 141.936 39.7048 142.927 39.6432C142.927 39.6432 143.76 39.5907 143.734 39.1497C143.736 39.1446 142.929 38.83 141.842 39.3029Z" fill="white" />
    <path d="M160.747 53.3472C158.381 53.1282 154.128 52.6944 150.577 52.1249C150.628 52.0962 152.226 51.2111 154.014 51.4845C155.834 51.761 159.371 51.9985 160.402 51.4321C160.99 51.1052 161.152 52.2668 160.747 53.3472Z" fill="#FF4B42" />
    <path d="M164.625 53.5071C164.625 50.3935 162.304 49.5536 162.304 49.5536C162.304 49.5536 161.369 49.0612 160.15 50.598C158.754 52.3548 157.31 60.4253 157.31 60.4253C157.31 60.4253 155.908 61.701 156.048 62.8821C156.188 64.0632 156.614 65.93 157.31 65.6781C158.006 65.4263 158.84 64.3624 159.087 63.6089C159.291 62.9798 159.22 61.8315 159.189 61.4707C159.434 61.1541 164.625 54.3583 164.625 53.5071Z" fill="#FCBDB6" />
  </svg>
);

export default BaristaCafe;
