import React from 'react';
import classNames from 'classnames';
import Immutable from 'immutable';
import { any, func, object } from 'prop-types';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import DeliveryTrackingDropdown from 'packs/common/components/DeliveryTrackingDropdown';
import { SecondaryButton, Link, SecondaryLink } from './styles';

const CardFooterActions = ({
  meal,
  mealPath,
  userRoleIn,
  translate,
}) => {
  if (meal.get('isConfirmed')) {
    return (
      <SecondaryLink
        href={mealPath()}
        className="tw-text-sm"
      >
        {translate('plannedMealCardsButtonsViewMenu')}
      </SecondaryLink>
    );
  } else if (userRoleIn(['admin'])) {
    return (
      <Link
        href={mealPath()}
        className="tw-text-sm"
      >
        {translate('plannedMealCardsButtonsViewMenu')}
      </Link>
    );
  }
  return (
    <SecondaryButton
      disabled
      className="tw-text-sm"
    >
      {translate('plannedMealCardsButtonsViewMenu')}
    </SecondaryButton>
  );
};

CardFooterActions.propTypes = {
  meal: object.isRequired,
  mealPath: func.isRequired,
  userRoleIn: any,
  translate: func.isRequired,
};

const CardFooter = ({
  meal,
  userRoleIn,
  settings,
  translate,
}) => {
  const mealId = meal.get('uuid');
  const mealPlanId = meal.get('mealPlanId');
  const mealPath = () => `/meal_plans/${mealPlanId}/meals/${mealId}`;
  const orderables = meal.get('isConfirmed') ? meal.get('orderables') : Immutable.fromJS([]);
  const trackableOrders = orderables && orderables.filter((order) => order.get('deliveryTrackable'));

  return (
    <div className={classNames('card-footer tw-flex tw-justify-end tw-w-full', {
      '!tw-bg-gray-50 !tw-rounded-bl-lg !tw-rounded-br-lg !tw-p-4 !tw-py-3': true,
      'tw-absolute tw-bottom-0 tw-gap-2': true,
    })}
    >
      { trackableOrders && trackableOrders.size > 0 && userRoleIn(['osc', 'admin']) ?
        <div className="text-left">
          <DeliveryTrackingDropdown
            btnClassName={classNames('caret-off !tw-shadow-none', {
              'tw-border !tw-border-broccoli-600 !tw-bg-white !tw-leading-5': true,
              'tw-text-base !tw-text-broccoli-600 !tw-font-bold': true,
              '!tw-rounded !tw-px-4 !tw-py-1.5 !tw-flex tw-gap-1': true,
            })}
            orders={trackableOrders}
            webUrl={settings.webUrl}
          >
            {translate('plannedMealCardsButtonsTrackDelivery')}
          </DeliveryTrackingDropdown>
        </div> :
        <div />
      }
      <div className="text-right">
        <CardFooterActions
          {...{
            meal,
            mealPath,
            userRoleIn,
            translate,
          }}
        />
      </div>
    </div>
  );
};

CardFooter.propTypes = {
  meal: object.isRequired,
  handleOnClickDownloadPdf: func.isRequired,
  userRoleIn: any,
  flagIsEnabled: func.isRequired,
  settings: object.isRequired,
  translate: func.isRequired,
};

export default withAppContext(withI18n(CardFooter));
