/* eslint max-len: 0 */

import * as actionTypes from '../constants/flashConstants';

/*
 * Dismiss flash alert
 */
export function dismissFlash() {
  return {
    type: actionTypes.DISMISS_FLASH,
  };
}
