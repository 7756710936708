import Toggle from 'react-toggle';
import styled from 'styled-components';

export const AccountSettings = styled.div`
  padding: 16px;
  min-height: 100vh;
`;

export const NotificationsSection = styled.section`
`;

export const HorizontalRule = styled.hr`
  margin-top: 0;
  margin-bottom: 1.5rem;
  border-top: 1px solid #EAEAEB;
`;

export const SectionTitle = styled.h3`
  font-weight: 400;
  font-size: 22px;
  color: #141415;
  margin-bottom: 20px;
`;

export const GroupHeading = styled.div`
  padding: 0 12px 0 0;
`;

export const Channels = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  font-weight: bold;
  span {
    margin-left: 20px;
    color: #515154;
    font-weight: 600;
    font-size: 13px;
  }
`;

export const SubSettings = styled.div`
  background-color: #F4F5F5;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 32px;
  border-radius: 8px;
`;


export const Title = styled.h5`
  font-weight: 200;
  font-size: 17px;
  color: #141415;
  margin-bottom: 4px;
`;

export const SubSettingTitle = styled.h5`
  font-weight: 200;
  font-size: 14px;
  color: #141415;
  margin-bottom: 6px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #515154;
`;

export const HintText = styled.h5`
  font-size: 14px;
  font-weight: 400;
  color: #95989D;
`;

export const NextButton = styled.div`
  display: flex;
  padding: 0 7px;
  font-size: 18px;
  margin-left: 16px;
  align-self: start;
`;

export const CheckboxPlaceholder = styled.div`
  width: 28px;
  height: 28px;
`;

export const ChannelSpan = styled.span`
  margin-left: 20px;
  color: #515154;
  font-weight: 600;
  font-size: 13px;
  visibility: ${props => props.visibility};
`;

export const NotificationGroupContainer = styled.div`
`;

export const NotificationItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #EAEAEB;
  padding: 12px 0;
  &:first-child {
    border-top: none;
  }
`;

export const Badge = styled.span`
  display: inline-flex;
  height: 20px;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 128px;
  border: 1px solid #EAEAEB;
  background: #FCFFF3;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: normal;
  margin-right: 4px;
`;

export const SettingItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
`;

export const StyledToggle = styled(Toggle)`
  &.react-toggle--checked .react-toggle-track {
    background: linear-gradient(0deg, #7D9E1D, #7D9E1D),
    linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .react-toggle-track {
    background: linear-gradient(0deg, #95989D, #95989D),
    linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: #7D9E1D;
  }

  .react-toggle-thumb {
    border-color: #95989D;
  }
`;

export const SettingTitle = styled.div`
  display: inline-flex;
  padding-top: 2px;
  svg {
    align-self: center;
    width: 24px;
  }
  span {
    font-size: 13px;
    font-weight: 600;
    color: #515154;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 32px;
`;

export const BackIcon = styled.div`
  position: absolute;
  left: 0;
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  padding: 4px; /* Adds clickable area for usability */
`;

export const HeaderTitle = styled.h5`
  text-align: center;
  font-weight: 200;
  font-size: 17px;
  color: #141415;
  margin: 0;
`;
