/* eslint max-len: 0 */

import React from 'react';
import { bool, string, object, func } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { isNull } from 'lodash';
import Immutable from 'immutable';
import styled from 'styled-components';

import BaseComponent from 'libs/components/BaseComponent';
import { blankish, notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

import infoIcon from 'assets/images/info-tooltip-icon.png';
import { KIND_CHOICES, KIND_UPLOAD, KIND_TEXT } from 'packs/common/constants/addOnConstants';
import AddOn from './Fields/AddOn';
import FileUpload from './Fields/FileUpload';
import TextField from './Fields/TextField';
import PhotoPreview from './Fields/PhotoPreview';

const Header = styled.h6`
  display: flex;
  align-items: center;
`;

const TooltipContainer = styled.span`
  display: flex;
  align-items: center;
`;

const PreviewContainer = styled.span`
  margin-left: 0.5rem;
`;

class MenuItemFormCartVariationChoiceForm extends BaseComponent {
  static propTypes = {
    isEditable: bool,
    hideAddOns: bool,
    lastName: string.isRequired,
    optionName: string.isRequired,
    parentType: string.isRequired,
    choice: object.isRequired,
    selectedOptions: object,
    onClickOption: func.isRequired,
    onDeleteItemAddOnOptionPhoto: func.isRequired,
    onDropItemAddOnOptionPhoto: func.isRequired,
    onDropItemAddOnOptionPhotoFailure: func.isRequired,
    onUpdateItemAddOnOptionText: func.isRequired,
    showPrice: bool.isRequired,
    intl: intlShape.isRequired,
    onShowExceededMaxOptionsAlert: func.isRequired,
  };

  render() {
    const {
      choice,
      hideAddOns,
      intl,
      isEditable,
      lastName,
      onClickOption,
      onDeleteItemAddOnOptionPhoto,
      onDropItemAddOnOptionPhoto,
      onDropItemAddOnOptionPhotoFailure,
      onUpdateItemAddOnOptionText,
      optionName,
      parentType,
      selectedOptions,
      showPrice,
      onShowExceededMaxOptionsAlert,
    } = this.props;
    const { formatMessage } = intl;
    const limits = choice.get('limits');
    const min = choice.get('min_order_quantity');
    const max = choice.get('max_order_quantity');
    const photo = choice.getIn(['photos', 0]);
    const description = choice.get('description');
    const parentId = choice.get('uuid');
    const totalQuantity = choice.get('total_quantity');
    const isValid = choice.get('is_valid');
    const style = isValid ? {} : { color: 'red' };
    const choiceKind = choice.get('kind') || KIND_CHOICES;
    const firstSelectedOption = selectedOptions.size > 0 && selectedOptions.find((selectedOption) => (selectedOption.get('parent_id') || selectedOption.get('parentId')) === parentId);

    let finalIsValid = isValid;

    // Override hideAddOns if min order quantity is > 0
    if (hideAddOns && ((notBlank(min) && min === 0) || blankish(min))) return null;
    if (notBlank(min) && notBlank(max) && (min <= max)) finalIsValid = totalQuantity === max;

    return (
      <div
        key={`choice-${choice.get('uuid')}`}
        className="row form_options"
      >
        <div className="col-sm-12">
          <Header className="h6 display-6">
            {choice.get('question')}

            {
              (notBlank(limits)) &&
              <em className="text-nowrap" style={style}>
                &nbsp;
                {formatMessage(defaultMessages.cartVariationsFormChooseOptions, { quantity: limits })}
              </em>
            }

            {
              (notBlank(description)) &&
              <TooltipContainer>
                &nbsp;
                <img
                  src={infoIcon}
                  width="13"
                  height="auto"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-title={description}
                  alt={description}
                />
              </TooltipContainer>
            }

            <PreviewContainer>
              <PhotoPreview {...{ photo }} />
            </PreviewContainer>
          </Header>
        </div>

        {choice.get('options') && (
          <div className="col-sm-12 row">
            {
              choice.get('options').map((option, index) => {
                const optionId = option.get('uuid');
                const minimum = option.get('min_order_quantity') || 1;
                const isPickOne = (
                  (((min !== 0) || (min === 1)) && (max === 1)) ||
                  ((min === 1) && (isNull(max)))
                );
                const optionPhoto = option.getIn(['photos', 0]);

                let isChecked = false;
                let quantity = option.get('quantity');
                let photos = Immutable.fromJS([]);

                if (selectedOptions.size > 0) {
                  const selected = selectedOptions.findEntry(data => data.get(lastName) === option.get('uuid'));

                  if (notBlank(selected)) {
                    isChecked = true;
                    quantity = selected[1].get('quantity');
                    photos = selected[1].get('photos') || Immutable.fromJS([]);
                  }
                }

                return (
                  <AddOn
                    key={`option-${optionId}`}
                    isChecked={isChecked}
                    isPickOne={isPickOne}
                    isQuantityOptionEnabled={option.get('enable_quantity_option')}
                    hasReachedMax={finalIsValid}
                    lastName={lastName}
                    parentType={parentType}
                    index={index}
                    max={max}
                    min={minimum}
                    quantity={quantity}
                    totalQuantity={totalQuantity}
                    parentName={`cart_variation[cart_variation_${optionName}][${parentId}]`}
                    parentId={parentId}
                    value={optionId}
                    name={optionName}
                    optionName={option.get('name')}
                    optionPhoto={optionPhoto}
                    optionPrice={option.get('price')}
                    onClick={onClickOption}
                    showPrice={showPrice}
                    disabled={!isEditable}
                    photos={photos}
                    onShowExceededMaxOptionsAlert={onShowExceededMaxOptionsAlert}
                  />
                );
              })
            }
          </div>
        )}
        <div className="col-sm-12">
          {
            firstSelectedOption && choiceKind === KIND_UPLOAD &&
              <FileUpload
                onDelete={onDeleteItemAddOnOptionPhoto}
                onDrop={onDropItemAddOnOptionPhoto}
                onDropFailure={onDropItemAddOnOptionPhotoFailure}
                option={firstSelectedOption}
                optionId={firstSelectedOption.get(lastName)}
                optionName={optionName}
                parentId={parentId}
                parentType={parentType}
              />
          }

          {
            firstSelectedOption && choiceKind === KIND_TEXT &&
              <TextField
                option={firstSelectedOption}
                optionId={firstSelectedOption.get(lastName)}
                optionName={optionName}
                parentId={parentId}
                parentType={parentType}
                limit={choice.get('limit')}
                onUpdate={onUpdateItemAddOnOptionText}
              />
          }
        </div>
      </div>
    );
  }
}

export default injectIntl(MenuItemFormCartVariationChoiceForm);
