/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import BaseComponent from 'libs/components/BaseComponent';

import MenuItemPhoto from '../shared/MenuItemPhoto';
import MenuItemInfo from '../shared/MenuItemInfo';

/**
 * CustomMenuItem
 */

class CustomMenuItem extends BaseComponent {
  static propTypes = {
    customMenuItem: PropTypes.object.isRequired,
    mealAdmin: PropTypes.bool.isRequired,
    mealConfirmed: PropTypes.bool.isRequired,
    mealPlanId: PropTypes.string.isRequired,
    updateQuantity: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    bindAll(this, ['updateQuantityHandler']);
  }

  updateQuantityHandler(e, quantity) {
    e.preventDefault();

    const { customMenuItem, mealPlanId, updateQuantity } = this.props;
    return updateQuantity(mealPlanId, customMenuItem.get('uuid'), {
      cart_id: customMenuItem.get('cart_id'),
      custom_menu_item: { quantity },
    });
  }

  render() {
    const {
      customMenuItem,
      mealAdmin,
      mealConfirmed,
    } = this.props;

    return (
      <div className="media flex-column flex-md-row">
        <MenuItemPhoto />

        <div className="media-body">
          <div className="order-item">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="text-truncate">
                <span className="order-item-title">
                  { customMenuItem.get('name') }
                </span>
              </div>

              <MenuItemInfo
                objectId={customMenuItem.get('uuid')}
                quantity={customMenuItem.get('quantity')}
                minQuantity={1}
                subtotal={customMenuItem.get('subtotal')}
                mealAdmin={mealAdmin}
                mealConfirmed={mealConfirmed}
                updateQuantityHandler={this.updateQuantityHandler}
              />

            </div>

            <div className="mb-2 text-with-line-break">
              { customMenuItem.get('description') }
            </div>

            <div className="mb-2">
              { customMenuItem.get('special_instructions') }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomMenuItem;
