/* eslint max-len: 0 */

import React from 'react';

const Program = () => (
  <svg width="20" height="22" viewBox="0 0 18 18" className="!tw-fill-white" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="8.5" stroke="#95989D" />
    <circle cx="9" cy="9" r="4.5" stroke="#95989D" />
  </svg>

);

export default Program;
