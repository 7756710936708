/* eslint react/no-danger: 0 */

import React from 'react';
import { any, func, object, string } from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useWindowSize } from 'packs/common/hooks/useWindowSize';
import { withI18n } from 'libs/support/i18n';
import OrderLinks from 'packs/common/components/OrderLinks';

const PlannedMealActionsDropdown = ({
  btnClassName,
  className,
  mealPlanId,
  mealId,
  orders,
  onClickMenuCardDownload,
  children,
  translate,
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= 991;
  const status = (orders && orders.size > 0) ? 'confirmed' : 'draft';
  const menuCardUrl = `/meal_plans/${mealPlanId}/menu_cards/${mealId}`;
  const mealOrderLabelUrl = `/meal_plans/${mealPlanId}/meal_order_labels/${mealId}`;
  const mealLabelUrl = `/meal_plans/${mealPlanId}/meal_labels/${mealId}?status=${status}&type=individual`;

  return (
    <div style={{ display: 'inline-block' }}>
      <Dropdown className={`dropdown ${className}`}>
        <Dropdown.Toggle id="dropdown-basic" className={`${btnClassName}`}>
          { children }
        </Dropdown.Toggle>

        <Dropdown.Menu align="right" className="!tw-w-80 !tw-rounded-lg !tw-max-h-80 tw-overflow-y-auto">
          <OrderLinks orders={orders} />
          <Dropdown.Item
            href={mealOrderLabelUrl}
            target={`${isMobile ? '_self' : '_blank'}`}
            className="tw-text-base !tw-font-bold"
          >
            { translate('plannedMealActionsDropdownButtonsMealOrderLabelEditor') }
          </Dropdown.Item>
          <Dropdown.Item
            href={menuCardUrl}
            target={`${isMobile ? '_self' : '_blank'}`}
            className="tw-text-base !tw-font-bold"
          >
            { translate('plannedMealActionsDropdownButtonsMenuCardEditor') }
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            data-turbolinks="false"
            onClick={onClickMenuCardDownload}
            className="tw-text-base !tw-font-bold"
          >
            { translate('plannedMealActionsDropdownButtonsMenuCardDownload') }
          </Dropdown.Item>
          <Dropdown.Item
            href={mealLabelUrl}
            target={`${isMobile ? '_self' : '_blank'}`}
            className="d-flex align-items-center"
          >
            <span className="tw-flex tw-items-center tw-gap-1">
              <span className="tw-text-base !tw-font-bold">
                {translate('plannedMealActionsDropdownButtonsIndividualMealLabelEditor')}
              </span>
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

PlannedMealActionsDropdown.defaultProps = {
  btnClassName: '',
  className: '',
};

PlannedMealActionsDropdown.propTypes = {
  btnClassName: string,
  className: string,
  children: any.isRequired,
  mealPlanId: string.isRequired,
  mealId: string.isRequired,
  orders: object,
  onClickMenuCardDownload: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(PlannedMealActionsDropdown);
