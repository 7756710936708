/* eslint-disable no-console */
import ActionCable from 'actioncable';

export default class APICable {
  constructor(socketId) {
    this.socketId = socketId;
    this.createConsumer(socketId);
  }

  createConsumer(sid) {
    this.cable = ActionCable.createConsumer(`${window.apiCableUrl}?sid=${sid}`);
  }

  subscribeChannel(channel, method, params, dcMethod = () => {}, connectedMethod = () => {}) {
    const res = this.cable.subscriptions.create({ channel, ...params }, {
      connected: () => {
        console.log('connected ', channel);
        connectedMethod();
      },
      disconnected: () => {
        console.log('disconnected ', channel);
        dcMethod();
      },
      received: (data) => {
        method(data);
      },
    });

    return res;
  }

  unsubscribeChannel(channel) {
    if (this.cable) {
      channel.unsubscribe();
      this.cable.subscriptions.remove(channel);
    }
  }

  cleanup() {
    if (this.cable) {
      this.cable.unsubscribe();
    }
  }
}
