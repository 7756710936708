/* eslint react/no-danger: 0 */

import React from 'react';
import { any, object, string } from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import TrackingTruck from 'libs/components/Icons/Tracking';
import { useWindowSize } from 'packs/common/hooks/useWindowSize';

const DeliveryTrackingDropdown = ({
  btnClassName,
  className,
  orders,
  webUrl,
  children,
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= 991;
  const trackingPath = (code) => `${webUrl}/t/${code}`;

  return (
    <div style={{ display: 'inline-block' }}>
      <Dropdown className={`dropdown ${className}`}>
        <Dropdown.Toggle id="delivery-tracking-dropdown" className={`${btnClassName}`}>
          <TrackingTruck />
          {children}
        </Dropdown.Toggle>
        <Dropdown.Menu align="top">
          {orders.map((order) => (
            <Dropdown.Item
              href={trackingPath(order.get('displayCode'))}
              target={`${isMobile ? '_self' : '_blank'}`}
              key={order.get('uuid')}
            >
              {order.get('vendorName')}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

DeliveryTrackingDropdown.defaultProps = {
  btnClassName: '',
  className: '',
};

DeliveryTrackingDropdown.propTypes = {
  btnClassName: string,
  webUrl: string,
  children: any.isRequired,
  className: string,
  orders: object,
};

export default DeliveryTrackingDropdown;
