import React, { createContext, useContext } from 'react';
import humps from 'lodash-humps';
import Cable from 'libs/support/apiCable';

const AppContext = createContext();
export default AppContext;

const flagIsEnabled = (fflags) => (key) => fflags[key] === 'true' || fflags[key] === true;
const userRoleIn = (currentMealPlanUser) => (roles) => {
  if (!currentMealPlanUser) return false;

  return roles.reduce((prevBool, role) => (
    prevBool || currentMealPlanUser.role === role
  ), false);
};

export const withAppContext = Component => (
  (props) => {
    const appContext = useContext(AppContext);

    if (!appContext.cable) {
      // eslint-disable-next-line react/prop-types
      appContext.cable = new Cable(props.sid || Date.now());
    }

    return (
      <Component
        flagIsEnabled={flagIsEnabled(appContext.fflags)}
        currentMealPlanUser={appContext.currentMealPlanUser}
        userRoleIn={userRoleIn(appContext.currentMealPlanUser)}
        settings={humps(appContext.settings)}
        cable={appContext.cable}
        {...props}
      />
    );
  }
);
