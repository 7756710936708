/* eslint max-len: 0, camelcase: 0, no-shadow: 0, no-use-before-define: 0, no-param-reassign: 0, no-underscore-dangle: 0 */

import humps from 'lodash-humps';

import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import loggerMiddleware from 'libs/middlewares/loggerMiddleware';
import thunkMiddleware from 'redux-thunk';
import moment from 'moment';

import reducers, { initialStates } from '../reducers';

/** ******************* */
/* PRIVATE *********** */
/** ******************* */

/*
 * Construct Meal Store
 */
export const constructMealStore = (props) => (
  {
    ...humps(props.meal),
    hasPassedDeadline: moment(props.meal.deadline_at) < moment(),
    scheduleAtIsEditable: props.meal.meal_admin && props.meal.allow_customer_to_edit_time,
    orderables: humps(props.carts),
    isLoading: !props.cart_variations.length,
  }
);

export const constructCartsStore = (props) => {
  const byVendorId = props.carts.reduce((hash, cart) => {
    hash[cart.vendor_id] = { cartId: cart.uuid };
    return hash;
  }, {});

  const byId = props.carts.reduce((hash, cart) => {
    hash[cart.uuid] = humps(cart);

    return hash;
  }, {});

  const allIds = props.carts.map((cart) => cart.uuid);

  return { byVendorId, byId, allIds };
};

export const constructOrderVariationsStore = (props) => {
  const orderVariations = props.order_variations;
  const byOrderId = orderVariations.reduce((hash, orderVariation) => {
    const orderId = orderVariation.order_id;
    hash[orderId] = hash[orderId] || [];
    hash[orderId].push(orderVariation);

    return hash;
  }, {});

  return ({ orderVariations, byOrderId });
};

export const constructCartVariationsStore = (props) => {
  const cartVariations = props.cart_variations;
  const totalQuantity = props.cart_variations_total_quantity;
  const byCartId = cartVariations.reduce((hash, cartVariation) => {
    const cartId = cartVariation.cart_id;
    hash[cartId] = hash[cartId] || [];
    hash[cartId].push(cartVariation);

    return hash;
  }, {});

  return ({ cartVariations, totalQuantity, byCartId });
};

export const constructVendorsStore = (props) => {
  const byId = props.vendors.reduce((hash, vendor) => {
    hash[vendor.uuid] = {
      uuid: vendor.uuid,
      name: vendor.name,
      minimumOrderValue: vendor.minimum_order_value,
      ordinal: vendor.ordinal,
      vendorSetting: vendor.vendor_setting,
    };
    return hash;
  }, {});

  const allIds = props.vendors.map((vendor) => vendor.uuid);

  return { byId, allIds };
};

export const constructUserMealPlanSettingStore = (props) => (
  humps(props.user_meal_plan_setting)
);

/** **************** */
/* Helpers */
/** **************** */

export function groupMenuItemsByVendor(menuItems) {
  return menuItems.reduce((hash, menuItem) => {
    const vendorUuid = menuItem.vendor_id;

    hash[vendorUuid] = hash[vendorUuid] || [];
    hash[vendorUuid].push({ ...menuItem });

    return hash;
  }, {});
}

export function groupMenuItemsById(menuItems) {
  return menuItems.reduce((hash, menuItem) => {
    hash[menuItem.uuid] = menuItem;

    return hash;
  }, {});
}

export default (props) => {
  const initialState = {
    $$mealStore: initialStates.$$mealState.merge(constructMealStore(props)),
    $$userMealCartStore: initialStates.$$userMealCartState.merge(humps(props.user_meal_cart)),
    $$userMealOrderStore: initialStates.$$userMealOrderState.merge(humps(props.user_meal_order)),
    $$orderVariationsStore: initialStates.$$orderVariationsState.merge(constructOrderVariationsStore(props)),
    $$cartVariationsStore: initialStates.$$cartVariationsState.merge(constructCartVariationsStore(props)),
    $$cartsStore: initialStates.$$cartsState.merge(constructCartsStore(props)),
    $$vendorsStore: initialStates.$$vendorsState.merge(constructVendorsStore(props)),
    $$menuItemsStore: initialStates.$$menuItemsState.merge({
      byId: groupMenuItemsById(props.menu_items),
      byVendorId: groupMenuItemsByVendor(props.menu_items),
    }),
    $$customMenuItemsStore: initialStates.$$customMenuItemsState.merge({
      byId: groupMenuItemsById(props.custom_menu_items),
      byVendorId: groupMenuItemsByVendor(props.custom_menu_items),
      totalQuantity: props.custom_menu_items_total_quantity,
    }),
    $$adminMealCartStore: initialStates.$$adminMealCartState.merge(humps(props.admin_meal_cart)),
    $$userMealPlanSettingStore: initialStates.$$userMealOrderState.merge(constructUserMealPlanSettingStore(props)),
    $$flashStore: initialStates.$$flashState,
  };

  const composeEnhancers = (typeof window !== 'undefined') ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
  const reducer = combineReducers(reducers);
  const composedStore = composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware));
  return composedStore(createStore)(reducer, initialState);
};
