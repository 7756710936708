export const CHECKING_OUT_USER_MEAL_CART = 'CHECKING_OUT_USER_MEAL_CART';
export const CHECK_OUT_USER_MEAL_CART_SUCCESS = 'CHECK_OUT_USER_MEAL_CART_SUCCESS';
export const CHECK_OUT_USER_MEAL_CART_FAILURE = 'CHECK_OUT_USER_MEAL_CART_FAILURE';
export const RESETTING_USER_MEAL_CART = 'RESETTING_USER_MEAL_CART';
export const RESET_USER_MEAL_CART_SUCCESS = 'RESET_USER_MEAL_CART_SUCCESS';
export const RESET_USER_MEAL_CART_FAILURE = 'RESET_USER_MEAL_CART_FAILURE';
export const CONFIRMING_USER_MEAL_CART = 'CONFIRMING_USER_MEAL_CART';
export const CONFIRM_USER_MEAL_CART_SUCCESS = 'CONFIRM_USER_MEAL_CART_SUCCESS';
export const CONFIRM_USER_MEAL_CART_FAILURE = 'CONFIRM_USER_MEAL_CART_FAILURE';
export const SKIPING_USER_MEAL_CART = 'SKIPING_USER_MEAL_CART';
export const SKIP_USER_MEAL_CART_SUCCESS = 'SKIP_USER_MEAL_CART_SUCCESS';
export const SKIP_USER_MEAL_CART_FAILURE = 'SKIP_USER_MEAL_CART_FAILURE';
export const EDITING_USER_MEAL_CART = 'EDITING_USER_MEAL_CART';
export const EDIT_USER_MEAL_CART_SUCCESS = 'EDIT_USER_MEAL_CART_SUCCESS';
export const EDIT_USER_MEAL_CART_FAILURE = 'EDIT_USER_MEAL_CART_FAILURE';
export const UNSKIPING_USER_MEAL_CART = 'UNSKIPING_USER_MEAL_CART';
export const UNSKIP_USER_MEAL_CART_SUCCESS = 'UNSKIP_USER_MEAL_CART_SUCCESS';
export const UNSKIP_USER_MEAL_CART_FAILURE = 'UNSKIP_USER_MEAL_CART_FAILURE';
export const CREATING_USER_MEAL_CART = 'CREATING_USER_MEAL_CART';
export const CREATE_USER_MEAL_CART_SUCCESS = 'CREATE_USER_MEAL_CART_SUCCESS';
export const CREATE_USER_MEAL_CART_FAILURE = 'CREATE_USER_MEAL_CART_FAILURE';
export const FETCH_USER_MEAL_CART_SUCCESS = 'FETCH_USER_MEAL_CART_SUCCESS';

export const USER_MEAL_ORDERABLE_STATUSES = {
  confirmed: 'confirmed',
  editing: 'editing',
  pending: 'pending',
  skipped: 'skipped',
};
