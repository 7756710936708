/* eslint-disable max-len */
import React from 'react';

const Message = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.25295 3.55509C4.06787 3.55509 3.89036 3.62861 3.75949 3.75949C3.62861 3.89036 3.55509 4.06787 3.55509 4.25295V15.1098L5.22736 13.4375C5.40443 13.2605 5.64458 13.161 5.89499 13.161H15.7472C15.9323 13.161 16.1098 13.0875 16.2407 12.9566C16.3715 12.8257 16.4451 12.6482 16.4451 12.4631V4.25295C16.4451 4.06787 16.3715 3.89036 16.2407 3.75949C16.1098 3.62861 15.9323 3.55509 15.7472 3.55509H4.25295ZM2.42423 2.42423C2.90924 1.93922 3.56705 1.66675 4.25295 1.66675H15.7472C16.4331 1.66675 17.0909 1.93922 17.5759 2.42423C18.0609 2.90924 18.3334 3.56705 18.3334 4.25295V12.4631C18.3334 13.149 18.0609 13.8068 17.5759 14.2918C17.0909 14.7768 16.4331 15.0493 15.7472 15.0493H6.28608L3.27855 18.0568C3.00852 18.3269 2.60241 18.4077 2.2496 18.2615C1.89679 18.1154 1.66675 17.7711 1.66675 17.3892V4.25295C1.66675 3.56705 1.93922 2.90924 2.42423 2.42423Z" fill="#95989D" />
  </svg>
);

export default Message;
