import React, { Fragment, useState } from 'react';
import { object } from 'prop-types';
import moment from 'moment';

import { withAppContext } from 'libs/support/appContext';
import { fetchPdfLink } from 'libs/services/labelsApi';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardFooter from './CardFooter';

const PlannedMealCard = ({
  meal,
  userRoleIn,
}) => {
  const [state, setState] = useState({
    isProcessing: false,
    downloadableLink: null,
    errorMsg: null,
  });

  const buildSuccessMsg = () => {
    if (state.isProcessing) {
      return 'Processing...';
    } else if (state.downloadableLink) {
      return `Download <a href='${state.downloadableLink}'>here</a>`;
    }
    return '';
  };

  const handleOnClickDownloadPdf = () => {
    if (state.isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    const mealId = meal.get('uuid');
    const mealPlanId = meal.get('mealPlanId');
    const type = 'menu_cards';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  return (
    <Fragment>
      <CardHeader meal={meal} />
      <CardBody
        {
          ...{
            meal,
            errorMsg: state.errorMsg,
            successMsg: buildSuccessMsg(),
          }
        }
      />
      <CardFooter
        {
          ...{
            meal,
            handleOnClickDownloadPdf,
          }
        }
      />
    </Fragment>
  );
};

PlannedMealCard.propTypes = {
  meal: object.isRequired,
  userRoleIn: object.isRequired,
};

export default withAppContext(PlannedMealCard);
