/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Alert from 'react-bootstrap/Alert';
import StickyBox from 'react-sticky-box';
import moment from 'moment';
import Immutable from 'immutable';

import actions from 'packs/meals/actions';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { fetchPdfLink } from 'libs/services/labelsApi';
import MenuItemForm from 'packs/common/components/MenuItem/MenuItemForm/MenuItemForm';
import CartHeader from './CartHeader/CartHeader';
import MealCart from './MealCart/index';
import MenuItems from './MenuItems';
import Flash from '../../Shared/Flash';
import VendorsDock from '../../Shared/VendorsDock';

/**
 * PlannedMeal
 */

const PlannedMeal = withI18n(({
  actions,
  mealStore,
  cartsStore,
  cartVariationsStore,
  vendorsStore,
  userRoleIn,
  translate,
}) => {
  const [downloadableLink, setDownloadableLink] = useState();
  const [isProcessing, setProcessing] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState('');
  const [vendors, setVendors] = useState(Immutable.fromJS([]));

  const isNonZeroVat = false;

  /* For MenuItemForm */
  const cartUserId = null;
  const isGroupOrder = false;
  const selectedMenuItem = cartVariationsStore.get('selectedMenuItem');
  const selectedCartVariation = cartVariationsStore.get('selectedCartVariation');
  const catererId = selectedMenuItem.getIn(['vendor_id']);
  const cartId = cartsStore.getIn(['byVendorId', catererId, 'cartId']);
  const saveErrors = cartVariationsStore.get('saveErrors');
  const mealAdmin = mealStore.get('mealAdmin');
  const mealPlanId = mealStore.get('mealPlanId');
  const isMealConfirmed = mealStore.get('confirmed');
  const mealId = mealStore.get('uuid');

  const handleOnClickDownloadPdf = () => {
    if (isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    setProcessing(true);
    setDownloadableLink(null);

    const mealId = mealStore.get('uuid');
    const mealPlanId = mealStore.get('mealPlanId');
    const type = 'menu_cards';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        setDownloadableLink(res.link);
        setProcessing(false);
      },
      (err) => {
        setErrorMsg(err);
        setProcessing(false);
      },
    );
  };

  useEffect(() => {
    if (isProcessing) setSuccessMsg(translate('buttonsProcessing'));
    if (downloadableLink) setSuccessMsg(translate('termsDownloadHere', { link: downloadableLink }));
  }, [isProcessing, downloadableLink]);

  useEffect(() => {
    const listVendors = vendorsStore.get('allIds').map(id => vendorsStore.getIn(['byId', id]));
    setVendors(listVendors);
  }, [vendorsStore]);

  useEffect(() => {
    if (cartVariationsStore.get('cartVariations').size === 0) {
      const scope = ['custom_menu_items', 'menu_items'];
      const includes = isMealConfirmed
        ? [
          ...scope,
          'orders',
          'order_variations',
          'order_variation_option_choices',
          'order_variation_item_add_on_options',
          'order_variation_service_add_on_options',
        ]
        : [
          ...scope,
          'carts',
          'cart_variations',
          'cart_variation_option_choices',
          'cart_variation_item_add_on_options',
          'cart_variation_service_add_on_options',
        ];

      actions.fetchMeal(mealPlanId, mealId, { includes });
    }
  }, [cartVariationsStore]);

  return (
    <div className="tw-container tw-max-w-[1280px] tw-mx-auto">
      <Flash />

      <div className="tw-grid tw-grid-cols-12 tw-mb-16 md:tw-gap-8 tw-py-4 md:tw-py-8">
        {/* Vendors Navigation */}
        <VendorsDock
          vendors={vendors}
          isAdmin={mealAdmin}
          editable={mealAdmin && !mealStore.get('confirmed')}
        />

        {/* Main Section */}
        <div className={classNames('tw-flex tw-flex-col tw-col-span-12 md:tw-col-span-8 tw-gap-2 md:tw-gap-4', {
          'md:tw-col-start-3': !mealAdmin && mealStore.get('confirmed'),
        })}
        >

          <div className={classNames('card card-cart', {
            '!tw-rounded-xl !tw-shadow-lg tw-my-4 md:!tw-mt-0': true,
            '!tw-border-gray-200': true,
          })}
          >
            {/* Cart Header */}
            <CartHeader handleOnClickDownloadPdf={handleOnClickDownloadPdf} />

            {
              successMsg &&
              <div className="p-4">
                <Alert variant="success" className="text-center">
                  <div dangerouslySetInnerHTML={{ __html: successMsg }} />
                </Alert>
              </div>
            }

            {
              errorMsg &&
              <div className="p-4">
                <Alert variant="danger" className="text-center">
                  {errorMsg}
                </Alert>
              </div>
            }

            <MenuItems />
          </div>
        </div>

        {/* Meal Cart */}
        {mealAdmin &&
          <div className="tw-col-span-12 md:tw-col-span-4">
            <StickyBox offsetTop={vendors.size > 1 ? 80 : 20} offsetBottom={50}>
              <MealCart vendors={vendors} />
            </StickyBox>
          </div>
        }
      </div>

      { /* MenuItemFormContainer */}
      {
        !mealStore.get('confirmed') &&
        <div className="menuitem-form">
          <MenuItemForm
            mealAdmin={mealAdmin}
            menuItem={selectedMenuItem}
            cartVariation={selectedCartVariation}
            mealId={mealStore.get('uuid')}
            mealPlanId={mealStore.get('mealPlanId')}
            catererId={catererId}
            cartId={cartId}
            cartUserId={cartUserId}
            showPrice
            isEditable
            hide={!cartVariationsStore.get('showMenuItemForm')}
            isSavingCartVariation={cartVariationsStore.get('isSavingCartVariation')}
            isNonZeroVat={isNonZeroVat}
            isGroupOrder={isGroupOrder}
            saveErrors={saveErrors}
            onClickOption={actions.checkAddOnValidity}
            onDeleteItemAddOnOptionPhoto={actions.deleteItemAddOnOptionPhoto}
            onDropItemAddOnOptionPhoto={actions.addTemporaryItemAddOnOptionPhoto}
            onDropItemAddOnOptionPhotoFailure={actions.addTemporaryItemAddOnOptionPhotoFailure}
            onSelectVariation={actions.selectCartVariation}
            onSubmit={actions.saveCartVariation}
            onUpdateItemAddOnOptionText={actions.updateItemAddOnOptionText}
            onHide={actions.hideMenuItemForm}
          />
        </div>
      }
    </div>
  );
});

PlannedMeal.propTypes = {
  mealStore: PropTypes.object.isRequired,
  cartsStore: PropTypes.object.isRequired,
  cartVariationsStore: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func,
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
  vendorsStore: state.$$vendorsStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(PlannedMeal));
