/* eslint-disable camelcase */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import bindAll from 'lodash/bindAll';
import { defaultMessages } from 'libs/i18n/default';
import { notBlank } from 'libs/support/string';

import BaseComponent from 'libs/components/BaseComponent';

import Modal from 'react-bootstrap/Modal';

import moment from 'moment';
import DateTime from 'react-datetime';

// Moment format
const DATE_FORMAT = 'ddd MMM Do';
const TIME_FORMAT = 'hh:mm a';

/**
 * ScheduleModal
 */

class ScheduleModal extends BaseComponent {
  static propTypes = {
    mealPlanId: PropTypes.string.isRequired,
    mealId: PropTypes.string.isRequired,
    scheduleAt: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const date = moment(props.scheduleAt);
    const time = moment(props.scheduleAt);

    this.state = { date, time };

    bindAll(this, ['onDateChange', 'onTimeChange', 'submitForm']);
  }

  onDateChange(date) {
    this.setState({ date });
  }

  onTimeChange(time) {
    this.setState({ time });
  }

  submitForm(event) {
    event.preventDefault();

    const { mealPlanId, mealId, onSave } = this.props;
    const { date, time } = this.state;

    const schedule_at = `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`;

    onSave(mealPlanId, mealId, { meal: { schedule_at } });
  }

  render() {
    const {
      show,
      onHide,
      intl,
    } = this.props;

    const {
      date,
      time,
    } = this.state;

    const { formatMessage } = intl;

    return (
      <Modal show={show} onHide={onHide}>
        <div className="modal-content">

          {/* Header */}
          <div className="modal-header">

            {/* Title */}
            <h6 className="modal-title" id="editeDateTime">Date and Time</h6>

            {/* x button */}
            <button type="button" className="close" onClick={onHide}>
              <span aria-hidden="true">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M10.165 11.4881L6.12082 7.44393L1.88434 11.681C1.45895 12.1063 0.768848 12.1063 0.343457 11.681C-0.0819337 11.2556 -0.0819337 10.5655 0.343457 10.1401L4.58048 5.90359L0.536272 1.85938C0.110881 1.43399 0.110881 0.744434 0.536272 0.319043C0.961663 -0.106348 1.65122 -0.106348 2.07661 0.319043L6.12082 4.36325L9.97222 0.511858C10.3976 0.0864671 11.0872 0.0864671 11.5131 0.511858C11.9385 0.937249 11.9385 1.62681 11.5131 2.0522L7.66116 5.90359L11.7054 9.9478C12.1308 10.3732 12.1308 11.0628 11.7054 11.4881C11.28 11.9135 10.5904 11.9135 10.165 11.4881V11.4881Z" fill="#141415" />
                </svg>
              </span>
            </button>
          </div>

          {/* Body */}
          <div className="modal-body">
            <form>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  { formatMessage(defaultMessages.plannedMealsScheduleModalDate) }
                </label>
                <div className="col-8">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="dropdown">
                        <DateTime
                          className="border-0 width-50 d-inline-block ml-4"
                          value={notBlank(date) ? moment(date).format(DATE_FORMAT) : ''}
                          dateFormat={DATE_FORMAT}
                          timeformat={false}
                          onChange={this.onDateChange}
                          closeOnSelect
                          inputProps={{ placeholder: 'Select Date', autoComplete: 'off' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">Time</label>
                <div className="col-8">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="dropdown">
                        <DateTime
                          className="border-0 width-50 d-inline-block ml-4"
                          value={notBlank(time) ? moment(time).format(TIME_FORMAT) : ''}
                          dateFormat={false}
                          timeformat={TIME_FORMAT}
                          timeConstraints={{ minutes: { step: 15 } }}
                          onChange={this.onTimeChange}
                          closeOnSelect
                          inputProps={{ placeholder: 'Select Time', autoComplete: 'off' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={onHide}
            >
              { formatMessage(defaultMessages.plannedMealsScheduleModalCancel) }
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.submitForm}
            >
              { formatMessage(defaultMessages.plannedMealsScheduleModalSave) }
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(ScheduleModal);
