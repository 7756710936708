/* eslint max-len:0, no-plusplus: 0 */

import React from 'react';
import map from 'lodash/map';

export function generateMonths() {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'];

  const arr = map(months, (month, index) => <option key={`month-${month}`} value={index + 1}>{month}</option>);
  arr.unshift(<option key="month-0" value="">MM</option>);
  return arr;
}

export function generateDays() {
  const days = [];

  for (let a = 1; a <= 31; a++) {
    days.push(<option key={`day=${a}`} value={a}>{a}</option>);
  }

  days.unshift(<option key="day-0" value="">DD</option>);
  return days;
}

export function generateServingStyles(servingStyles) {
  return servingStyles.map((servingStyle) => (
    <option
      key={`serving-style-${servingStyle.get(0)}`}
      value={servingStyle.get(1)}
    >
      {servingStyle.get(0)}
    </option>
  ));
}

export function processErrors($el, isInvalid) {
  const $wrapper = $el.closest('.form-group');

  if (isInvalid) {
    $wrapper.addClass('has-danger');
    $el.addClass('form-control-danger');
  } else {
    $wrapper.removeClass('has-danger');
    $el.removeClass('form-control-danger');
  }
}

