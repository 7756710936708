import Immutable from 'immutable';
import humps from 'lodash-humps';
import Segment from 'libs/middlewares/segment';
import retry from 'p-retry';
import { blankish } from './string';

export function initializeSegment() {
  if (window.segment) return;

  CS = humps(CS);

  const segment = new Segment();
  window.segment = segment;
  segment.process();
}

export function processSegment() {
  window.segment.analyzePage();
  window.segment.analyzeAlias();
  window.segment.analyzeIdentify();
  window.segment.analyzeTrack();
}

function isValidSegment(prevSegmentTrack, segmentTrack) {
  if (Immutable.is(prevSegmentTrack, segmentTrack)) return false;

  if (blankish(segmentTrack)) return false;

  if (segmentTrack.size === 0) return false;

  return true;
}

export function updateSegment(prevStore, store) {
  const segmentTrack = store.get('segmentTrack');

  if (!isValidSegment(prevStore.get('segmentTrack'), segmentTrack)) return false;

  window.CS.segmentTrack = segmentTrack.toJS();
  window.segment.analyzeTrack();

  return true;
}

export function updateSegmentAndUser(prevStore, store) {
  const segmentTrack = store.get('segmentTrack');
  const anonymousUserTraits = store.get('anonymousUserTraits');

  if (!isValidSegment(prevStore.get('segmentTrack'), segmentTrack)) return false;

  window.CS.anonymousUserTraits = anonymousUserTraits.toJS();
  window.CS.segmentTrack = segmentTrack.toJS();
  window.segment.analyzeTrack();
  window.segment.analyzeAnonymousIdentify();

  return true;
}

export function trackEvent(event, attributes = undefined) {
  retry(async () => {
    if (!window.segment?.initialized) {
      throw new Error('Segment is not initialized');
    }

    window.segment.trackEvent(event, attributes);
  });
}
