/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import bindAll from 'lodash/bindAll';

import * as fromCartVariations from 'packs/common/reducers/cartVariationsReducer';

import BaseComponent from 'libs/components/BaseComponent';
import { withI18n } from 'libs/support/i18n';

/* Actions */
import actions from 'packs/meals/actions';

import MenuItem from './MenuItem/MenuItem';
import CustomMenuItem from './CustomMenuItem/CustomMenuItem';

/**
 * MenuItems
 */

class MenuItems extends BaseComponent {
  static propTypes = {
    mealStore: PropTypes.object.isRequired,
    cartVariationsStore: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    bindAll(this, ['onEditSelection']);
  }

  onEditSelection(e, menuItem, cartVariation) {
    e.preventDefault();

    const { actions, mealStore } = this.props;

    const cartVariationId = cartVariation.get('uuid');

    if (cartVariationId) {
      return actions.fetchCartVariation(mealStore.get('mealPlanId'), cartVariationId);
    }
    return actions.fetchMenuItem(menuItem.get('uuid'));
  }

  render() {
    const {
      actions,
      cartVariationsStore,
      cartsStore,
      customMenuItemsStore,
      mealStore,
      menuItemsStore,
      translate,
      vendorsStore,
    } = this.props;

    const mealConfirmed = mealStore.get('confirmed');
    const mealAdmin = mealStore.get('mealAdmin');
    const mealPlanId = mealStore.get('mealPlanId');
    const cartVariationsByCartId = fromCartVariations.groupByCardId(cartVariationsStore.get('cartVariations'));

    return (
      <div className="card-body menu-items">
        <div className="cart-order-list">
          {
            cartsStore.get('allIds').map((cartId, i) => {
              // Hide if the meal is confirmed and there is no cart variatiopns for the given vendor
              const vendorId = cartsStore.getIn(['byId', cartId, 'vendorId']);
              const vendor = vendorsStore.getIn(['byId', vendorId]);
              const cartVariations = cartVariationsByCartId.get(cartId);
              const customMenuItems = customMenuItemsStore.getIn(['byVendorId', vendorId]);

              return (
                <div key={`vendorMenuItem-${i}`}>
                  <div className="d-flex justify-content-between align-items-center py-3 mb-2 border-bottom">
                    <h6 className="text-uppercase m-0">
                      { vendor.get('name') }
                    </h6>
                    {
                      mealAdmin &&
                        <span className="text-muted font-italic">{`minimum spend of $${vendor.get('minimumOrderValue')} is required`}</span>
                    }
                  </div>

                  {/* Menu Items */}
                  {
                    cartVariations && cartVariations.map((cartVariation, i) => {
                      const menuItem = menuItemsStore.getIn(['byId', cartVariation.get('menu_item_id')]);

                      if (!cartVariation || !menuItem) { return null; }

                      if (!mealAdmin && !mealConfirmed) { return null; }

                      return (
                        <MenuItem
                          key={`menuitem-${i}`}
                          cartVariation={cartVariation}
                          menuItem={menuItem}
                          mealAdmin={mealAdmin}
                          mealConfirmed={mealConfirmed}
                          mealPlanId={mealPlanId}
                          onEditSelection={(e) => this.onEditSelection(e, menuItem, cartVariation)}
                          updateQuantity={actions.updateQuantityCartVariation}
                        />
                      );
                    })
                  }

                  {/* Custom Menu Items */}
                  {
                    customMenuItems && customMenuItems.map((customMenuItem, i) => {
                      if (!mealAdmin && !mealConfirmed) { return null; }

                      return (
                        <CustomMenuItem
                          key={`custommenuitem-${i}`}
                          customMenuItem={customMenuItem}
                          mealAdmin={mealAdmin}
                          mealConfirmed={mealConfirmed}
                          mealPlanId={mealPlanId}
                          updateQuantity={actions.saveCustomMenuItem}
                        />
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
        {
          mealAdmin &&
            <div className="menu-items__total-quantity">
              { translate('plannedMealsTotalQuantity') }: { cartVariationsStore.get('totalQuantity') + customMenuItemsStore.get('totalQuantity') }
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
  menuItemsStore: state.$$menuItemsStore,
  customMenuItemsStore: state.$$customMenuItemsStore,
  vendorsStore: state.$$vendorsStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(MenuItems));
