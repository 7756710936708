import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';
import DietaryBadgeContainer from 'packs/meals/components/Shared/DietaryBadgeContainer';

class MenuItemFormPresentationsAndDietaries extends BaseComponent {
  static propTypes = {
    presentations: PropTypes.object,
    dietaries: PropTypes.object,
    intl: intlShape.isRequired,
  };

  render() {
    const { presentations, dietaries, intl } = this.props;
    const { formatMessage } = intl;

    if ((presentations.size < 1) && dietaries.size < 1) return null;

    return (
      <div className="row">
        {
          (presentations.size > 0) &&
          <div className="col-md-6">
            <p className="more-details-headline">
              {formatMessage(defaultMessages.caterersShowMenuItemPresentation)}
            </p>

            <ul className="more-details-presentation">
              {
                presentations.map(presentation => {
                  const name = presentation.get('name');

                  return (
                    <li key={`presentation-${name}`}>
                      <img src={presentation.get('icon')} width="14" height="auto" alt={name.split('')[0].toUpperCase()} />
                      <span>{name}</span>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          dietaries &&
            <div className="col-md-6">
              <p className="more-details-headline">
                {formatMessage(defaultMessages.caterersShowMenuItemDietaryNotes)}
              </p>

              <div className="badges">
                <DietaryBadgeContainer
                  type="caterer"
                  dietaries={dietaries}
                />
              </div>
            </div>
        }
      </div>
    );
  }
}

export default injectIntl(MenuItemFormPresentationsAndDietaries);
