/* eslint max-len: 0 */

import React from 'react';

const DeleteX = () => (
  <svg width="8" height="8" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 3.70711L0 1.41421L1.41421 0L3.70711 2.29289L6 0L7.41421 1.41421L5.12132 3.70711L7.41421 6L6 7.41421L3.70711 5.12132L1.41421 7.41421L0 6L2.29289 3.70711Z"
      transform="translate(0.292969 0.292847)"
    />
  </svg>
);

export default DeleteX;
