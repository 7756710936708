import { createTempFile } from '../services/tempFilesApi';

export const uploadImage = (file, details) => (
  new Promise((resolve, reject) => {
    const dataForm = new FormData();
    dataForm.append('file', file);

    return createTempFile(
      dataForm,
      (res) => (resolve({ file, ...res, ...details })),
      (err) => (reject(err)),
    );
  })
);
