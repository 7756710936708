import React from 'react';
import { number } from 'prop-types';

import { withI18n } from 'libs/support/i18n';

/**
 * CartAmounts
 */

const CartAmounts = ({
  subtotal,
  subsidyAmount,
  total,
  taxAmount,
}) => (
  <div className="card-footer">
    <div className="row">
      <div className="col">Subtotal</div>
      <div className="col text-right">${subtotal}</div>
      <div className="w-100" />

      <div className="col">Delivery Fee</div>
      <div className="col text-right">Paid by Company</div>
      <div className="w-100" />

      <div className="col">Company Contribution</div>
      <div className="col text-right">- ${subsidyAmount}</div>
      <div className="w-100" />

      <div className="col">GST</div>
      <div className="col text-right">${taxAmount}</div>
      <div className="w-100" />

      <div className="col">Total</div>
      <div className="col text-right total-price">${total}</div>
    </div>
  </div>
);

CartAmounts.propTypes = {
  subtotal: number,
  subsidyAmount: number,
  total: number,
  taxAmount: number,
};

export default withI18n(CartAmounts);
