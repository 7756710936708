/* eslint-disable max-len */
import React from 'react';

const Bell = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path d="M15.6007 11.9635C14.9226 10.7971 14.5624 9.1229 14.5624 7.12524C14.5624 5.38476 13.871 3.71556 12.6403 2.48486C11.4096 1.25415 9.74038 0.562744 7.9999 0.562744C6.25942 0.562744 4.59022 1.25415 3.35951 2.48486C2.1288 3.71556 1.4374 5.38476 1.4374 7.12524C1.4374 9.12368 1.07881 10.7971 0.400681 11.9635C0.262307 12.2014 0.188958 12.4716 0.187998 12.7468C0.187038 13.022 0.258501 13.2926 0.395212 13.5315C0.531058 13.7705 0.728231 13.9689 0.966374 14.1062C1.20452 14.2436 1.475 14.3149 1.7499 14.3127H4.57646C4.65398 15.1671 5.04819 15.9617 5.68164 16.5403C6.31509 17.1188 7.14199 17.4396 7.9999 17.4396C8.85781 17.4396 9.68471 17.1188 10.3182 16.5403C10.9516 15.9617 11.3458 15.1671 11.4233 14.3127H14.2499C14.5244 14.3144 14.7944 14.243 15.0321 14.1056C15.2698 13.9683 15.4666 13.7701 15.6022 13.5315C15.7395 13.293 15.8117 13.0225 15.8114 12.7473C15.8111 12.4721 15.7385 12.2018 15.6007 11.9635ZM7.9999 15.5627C7.6397 15.5628 7.29053 15.4384 7.01149 15.2107C6.73246 14.9829 6.54069 14.6657 6.46865 14.3127H9.53115C9.45911 14.6657 9.26734 14.9829 8.98831 15.2107C8.70927 15.4384 8.3601 15.5628 7.9999 15.5627ZM2.27021 12.4377C2.96162 11.0315 3.3124 9.24556 3.3124 7.12524C3.3124 5.88204 3.80626 4.68976 4.68534 3.81068C5.56441 2.9316 6.7567 2.43774 7.9999 2.43774C9.2431 2.43774 10.4354 2.9316 11.3145 3.81068C12.1935 4.68976 12.6874 5.88204 12.6874 7.12524C12.6874 9.24478 13.0374 11.0315 13.7288 12.4377H2.27021Z" fill="#95989D" />
  </svg>
);

export default Bell;
