/* eslint-disable max-len */
import React from 'react';

const Organic = (_props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z" fill="#FFDE59" />
    <path d="M16.0137 13.7051C16.0137 15.0996 15.6641 16.1836 14.9648 16.957C14.2695 17.7305 13.2832 18.1172 12.0059 18.1172C10.7129 18.1172 9.71875 17.7344 9.02344 16.9688C8.33203 16.1992 7.98633 15.1074 7.98633 13.6934C7.98633 12.2793 8.33398 11.1934 9.0293 10.4355C9.72852 9.67773 10.7246 9.29883 12.0176 9.29883C13.291 9.29883 14.2754 9.68359 14.9707 10.4531C15.666 11.2227 16.0137 12.3066 16.0137 13.7051ZM9.47461 13.7051C9.47461 14.7598 9.6875 15.5605 10.1133 16.1074C10.5391 16.6504 11.1699 16.9219 12.0059 16.9219C12.8379 16.9219 13.4648 16.6523 13.8867 16.1133C14.3125 15.5742 14.5254 14.7715 14.5254 13.7051C14.5254 12.6543 14.3145 11.8574 13.8926 11.3145C13.4746 10.7715 12.8496 10.5 12.0176 10.5C11.1777 10.5 10.543 10.7715 10.1133 11.3145C9.6875 11.8574 9.47461 12.6543 9.47461 13.7051Z" fill="white" />
  </svg>
);

export default Organic;
