/* eslint max-len: 0 */

import React, { memo, useEffect, Fragment } from 'react';
import { object, bool } from 'prop-types';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as mealActions from 'packs/meals/actions/mealActions';

import { withAppContext } from 'libs/support/appContext';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import GroupOrderCardMealV1 from '../components/GroupOrderMeal/v1/GroupOrderCardMeal';
import GroupOrderCardMealV2 from '../components/GroupOrderMeal/v2/GroupOrderCardMeal';

global.Intl = Intl;

const GroupOrdeMealCardContainer = withAppContext(({
  meal,
  userMealOrderable,
  isLoading,
  isLoaded,
  fetchOrderables,
  flagIsEnabled,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];
  const orderables = meal.get('orderables');
  const isConfirmed = meal.get('isConfirmed');
  const Component = flagIsEnabled('v2_enabled') ? GroupOrderCardMealV2 : GroupOrderCardMealV1;

  useEffect(() => {
    if (!orderables && !isLoaded) {
      fetchOrderables(meal.get('mealPlanId'), meal.get('uuid'), {
        includes: [
          !isConfirmed ? 'carts' : 'orders',
          'vendors',
        ],
      });
    }
  }, [meal]);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Fragment>
        <Component
          {
            ...{
              meal,
              userMealOrderable,
              isLoading,
            }
          }
        />
      </Fragment>
    </IntlProvider>
  );
});

GroupOrdeMealCardContainer.propTypes = {
  meal: object.isRequired,
  userMealOrderable: object.isRequired,
  isLoading: bool,
};

const mapStateToProps = ({
  $$mealCardStore,
}) => ({
  isLoading: $$mealCardStore.get('isLoading'),
  isLoaded: $$mealCardStore.get('isLoaded'),
  meal: $$mealCardStore.get('meal'),
  userMealOrderable: $$mealCardStore.get('userMealOrderable'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchOrderables: mealActions.fetchMealOrderables,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(memo(GroupOrdeMealCardContainer));
