import React from 'react';
import { Provider } from 'react-redux';

import AppContext from 'libs/support/appContext';
import { defaultTheme } from 'libs/support/theme';
import { ThemeProvider } from 'styled-components';
import PlannedMealCardContainer from 'packs/meal_plans/containers/PlannedMealCardContainer';
import mealCardStore from 'packs/meal_plans/stores/mealCardStore';

export default (props, railsContext) => {
  const store = mealCardStore(props);
  const context = {
    fflags: railsContext.fflags,
    settings: railsContext.settings,
    currentMealPlanUser: railsContext.current_meal_plan_user,
  };

  return (
    <AppContext.Provider value={context}>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme} >
          <PlannedMealCardContainer />
        </ThemeProvider>
      </Provider>
    </AppContext.Provider>
  );
};
