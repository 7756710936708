// Meal Checkout
export const PROCESSING_CHECKOUT = 'PROCESSING_CHECKOUT';
export const PROCESS_CHECKOUT_SUCCESS = 'PROCESS_CHECKOUT_SUCCESS';
export const PROCESS_CHECKOUT_FAILURE = 'PROCESS_CHECKOUT_FAILURE';

// Alert
export const DISMISS_ALERT = 'DISMISS_ALERT';

// Meal
export const FETCHING_MEAL = 'FETCHING_MEAL';
export const FETCH_MEAL_SUCCESS = 'FETCHING_MEAL_SUCCESS';
export const FETCH_MEAL_FAILURE = 'FETCHING_MEAL_FAILURE';

// Meal orderables
export const FETCHING_MEAL_ORDERABLES = 'FETCHING_MEAL_ORDERABLES';
export const FETCH_MEAL_ORDERABLES_SUCCESS = 'FETCH_MEAL_ORDERABLES_SUCCESS';
export const FETCH_MEAL_ORDERABLES_FAILURE = 'FETCH_MEAL_ORDERABLES_FAILURE';

// Meal Status
export const SHOW_MEAL_STATUS = 'SHOW_MEAL_STATUS';
export const HIDE_MEAL_STATUS = 'HIDE_MEAL_STATUS';

// Save Meal
export const SAVING_MEAL = 'SAVING_MEAL';
export const SAVE_MEAL_SUCCESS = 'SAVE_MEAL_SUCCESS';
export const SAVE_MEAL_FAILURE = 'SAVE_MEAL_FAILURE';

// Fetch Meal Status
export const FETCHING_MEAL_STATUS = 'FETCHING_MEAL_STATUS';
export const FETCH_MEAL_STATUS_SUCCESS = 'FETCH_MEAL_STATUS_SUCCESS';
export const FETCH_MEAL_STATUS_FAILURE = 'FETCH_MEAL_STATUS_FAILURE';

// MealStatus tab
export const SELECT_MEAL_STATUS_TAB = 'SELECT_MEAL_STATUS_TAB';

// Schedule
export const SHOW_SCHEDULE_MODAL = 'SHOW_SCHEDULE_MODAL';
export const HIDE_SCHEDULE_MODAL = 'HIDE_SCHEDULE_MODAL';

// Menu Items
export const TOGGLE_MENU_ITEMS = 'TOGGLE_MENU_ITEMS';

export const MEAL_STATE = {
  confirmed: 'confirmed',
  pending: 'pending',
  skipped: 'skipped',
  editing: 'editing',
  error: 'error',
};

export const TOGGLE_SHOW_EDIT_ORDER_MODAL = 'TOGGLE_SHOW_EDIT_ORDER_MODAL';
export const SET_SEARCH_ORDER_MODAL = 'SET_SEARCH_ORDER_MODAL';
export const TOGGLE_SHOW_ORDER_FORM = 'TOGGLE_SHOW_ORDER_FORM';
export const TOGGLE_SHOW_SELECT_USER_FORM = 'TOGGLE_SHOW_SELECT_USER_FORM';
export const CHECK_ADD_ON_VALIDITY_ORDER_FORM = 'CHECK_ADD_ON_VALIDITY_ORDER_FORM';
export const GET_MEAL_PLAN_USERS = 'GET_MEAL_PLAN_USERS';

export const FETCHING_MEAL_PLAN_USERS = 'FETCHING_MEAL_PLAN_USERS';
export const FETCH_MEAL_PLAN_USERS_SUCCESS = 'FETCH_MEAL_PLAN_USERS_SUCCESS';
export const FETCH_MEAL_PLAN_USERS_FAILURE = 'FETCH_MEAL_PLAN_USERS_FAILURE';

export const SAVING_CART_VARIATION_ORDER_FORM = 'SAVING_CART_VARIATION_ORDER_FORM';
export const SAVE_CART_VARIATION_ORDER_FORM_SUCCESS = 'SAVE_CART_VARIATION_ORDER_FORM_SUCCESS';
export const SAVE_CART_VARIATION_ORDER_FORM_FAILURE = 'SAVE_CART_VARIATION_ORDER_FORM_FAILURE';

export const FETCHING_CART_VARIATION = 'FETCHING_CART_VARIATION';
export const FETCH_CART_VARIATION_ORDER_FORM_SUCCESS = 'FETCH_CART_VARIATION_ORDER_FORM_SUCCESS';
export const FETCH_CART_VARIATION_ORDER_FORM_FAILURE = 'FETCH_CART_VARIATION_ORDER_FORM_FAILURE';

export const DELETING_CART_VARIATION = 'DELETING_CART_VARIATION';
export const DELETE_CART_VARIATION_ORDER_FORM_SUCCESS = 'DELETE_CART_VARIATION_ORDER_FORM_SUCCESS';
export const DELETE_CART_VARIATION_ORDER_FORM_FAILURE = 'DELETE_CART_VARIATION_ORDER_FORM_FAILURE';
