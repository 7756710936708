import React from 'react';
import classNames from 'classnames';
import StickyBox from 'react-sticky-box';
import { object, string } from 'prop-types';
import { notBlank } from 'libs/support/string';
import { setImageUrl } from 'helpers/cartVariationsHelper';

import IMAGE_PLACEHOLDER from 'assets/images/img-placeholder.png';

const OrderVariationItems = ({
  variations,
  vendorName,
  vendorLogo,
}) => {
  if (!variations.size) return null;
  const items = variations.filter(v => notBlank(v.get('menuItem')));
  const total = items.reduce((acc, i) => acc + (i.get('quantity') || 0), 0);

  return (
    <div
      data-testid="order-menu-items"
      className="tw-flex tw-flex-col tw-gap-4 tw-mb-8"
    >
      <StickyBox offsetTop={0}>
        <div className={classNames('vendor-info !tw-bg-white', {
          'tw-flex tw-flex tw-items-center tw-justify-between': true,
          'tw-border-b tw-border-gray-200 tw-py-2': true,
        })}
        >
          <div className="tw-flex tw-flex tw-items-center tw-gap-2">
            <div className="tw-w-10 tw-min-h-10 tw-max-h-10 tw-bg-gray-50 tw-rounded-md">
              {notBlank(vendorLogo.getIn(['thumb', 'url']))
                ? (
                  <object
                    className="tw-w-full tw-h-auto tw-object-contain"
                    data={setImageUrl(vendorLogo.getIn(['thumb', 'url']))}
                  >
                    <img
                      className="tw-rounded !tw-w-full !tw-h-auto !tw-object-contain"
                      src={IMAGE_PLACEHOLDER}
                      alt=""
                    />
                  </object>
                )
                : <img
                    className="tw-rounded !tw-w-full !tw-h-auto !tw-object-contain"
                    src={IMAGE_PLACEHOLDER}
                    alt=""
                />
              }
            </div>
            <span className="tw-text-base tw-font-bold tw-text-gray-900 tw-m-0">
              {vendorName}
            </span>
          </div>
          <span className="tw-text-xs tw-text-gray-400 tw-uppercase tw-w-12">
            QTY: {total}
          </span>
        </div>
      </StickyBox>
      {items.map(item => (
        <div
          key={item.get('uuid')}
          className="media tw-flex tw-items-center tw-gap-2"
        >
          <div className="card-thumbnail">
            <div className="tw-w-10 tw-min-h-10 tw-max-h-10 tw-bg-gray-50 tw-rounded-md tw-mt-1">
              {
                notBlank(item.getIn(['menuItem', 'photos', 0, 'thumb']))
                ? (
                  <object
                    className="tw-w-full tw-h-auto tw-object-contain"
                    data={item.getIn(['menuItem', 'photos', 0, 'thumb'])}
                  >
                    <img
                      className="tw-rounded !tw-w-full !tw-h-auto !tw-object-contain !tw-bg-white"
                      alt=""
                      src={IMAGE_PLACEHOLDER}
                    />
                  </object>
                  )
                : <img
                    className="tw-rounded !tw-w-full !tw-h-auto !tw-object-contain !tw-bg-white"
                    alt=""
                    src={IMAGE_PLACEHOLDER}
                />
              }
            </div>
          </div>

          <div className="media-body tw-overflow-hidden">
            <div className="tw-flex tw-flex-col tw-justify-start tw-gap-1">
              <div className="tw-text-base tw-text-gray-900 tw-m-0 tw-truncate">
                {item.getIn(['menuItem', 'name'])}
              </div>
              <span className="tw-text-xs tw-text-gray-500 tw-uppercase">
                QTY: {item.get('quantity') || 0}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

OrderVariationItems.propTypes = {
  variations: object,
  vendorName: string,
  vendorLogo: object,
};

export default OrderVariationItems;
