import useSWR from 'swr';
import requestsManager from 'libs/middlewares/requestsManager';

const fetcher = (endpoint, params) => {
  const finalParams = JSON.parse(params);

  return new Promise((resolve, reject) => {
    requestsManager.fetchEntities(finalParams, endpoint)
      .then((res) => {
        resolve(res.data);
      })
      .catch((res) => {
        reject(res.response.data);
      });
  });
};

export const useSWRAPI = (endpoint, params = {}) => useSWR([endpoint, JSON.stringify(params)], fetcher);
