/* eslint max-len: 0 */

import { number, string } from 'prop-types';
import React from 'react';

const Plus = ({ color = '#7D9E1D', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4.375V15.625"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 10H4.375"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Plus.propTypes = {
  size: number,
  color: string,
};

export default Plus;
