/* eslint max-len: 0 */

import React from 'react';

const UploadCloud = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M8 2C5.94 2 4.32 3.565 4.0935 5.5625C3.65782 5.6327 3.24923 5.8194 2.91098 6.10282C2.57272 6.38624 2.31739 6.75584 2.172 7.1725C0.942 7.527 0 8.624 0 10C0 11.662 1.338 13 3 13H13C14.662 13 16 11.662 16 10C16 9.12 15.5725 8.332 14.953 7.781C14.837 6.024 13.4355 4.622 11.672 4.531C11.07 3.0665 9.689 2 8 2ZM8 3C9.381 3 10.485 3.885 10.875 5.14L10.985 5.5H11.5C12.8775 5.5 14 6.6225 14 8V8.25L14.203 8.4065C14.448 8.59427 14.6471 8.83532 14.7852 9.11139C14.9232 9.38747 14.9967 9.69134 15 10C15 11.138 14.138 12 13 12H3C1.862 12 1 11.138 1 10C1 8.99 1.725 8.206 2.64 8.047L2.9685 7.9845L3.031 7.6555C3.181 6.982 3.778 6.5 4.5 6.5H5V6C5 4.315 6.315 3 8 3ZM8 5.797L7.64 6.1405L5.64 8.1405L6.36 8.8605L7.5 7.7185V11H8.5V7.7185L9.64 8.8595L10.36 8.1395L8.36 6.1395L8 5.7965V5.797Z" fill="#95989D" stroke="#95989D" strokeWidth="0.2" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UploadCloud;
