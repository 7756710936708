import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { blankish, notBlank } from 'libs/support/string';

import TextPreview from './TextPreview';
import { uploadImage } from '../../support/fileUploadUtils';

const PhotoUploadComponent = ({
  details,
  errors,
  multiple,
  onDelete,
  onDrop,
  onDropFailure,
  photo,
  url,
}) => {
  const [isUploading, setUploading] = useState(false);

  const handleOnDropPreload = (files) => {
    let { index } = details;
    const promises = [];

    files.forEach((file) => {
      promises.push(uploadImage(file, { ...details, index }));

      index += 1;
    });

    Promise.all(promises).then((values) => {
      setUploading(false);
      onDrop(values);
    }).catch(response => {
      setUploading(false);
      onDropFailure({ ...details, errors: response.errors });
    });
  };

  const handleOnDrop = (files) => {
    if (blankish(onDrop)) return;

    setUploading(true);

    handleOnDropPreload(files);
  };

  const handleOnDelete = (e) => {
    if (blankish(onDelete)) return;

    e.preventDefault();
    onDelete(details);
  };

  return (
    <TextPreview
      {
        ...{
          errors,
          multiple,
          photo,
          isUploading,
          url,
        }
      }
      handleOnDrop={handleOnDrop}
      handleOnDelete={handleOnDelete}
      isDeletable={notBlank(onDelete)}
      isDroppable={notBlank(onDrop)}
    />
  );
};

PhotoUploadComponent.propTypes = {
  errors: string,
  photo: string,
  caption: string,
  link: string,
  thumb: string,
  title: string,
  subtitle: string,
  details: object,
  multiple: bool,
  onDrop: func.isRequired,
  onDropFailure: func.isRequired,
  onDelete: func.isRequired,
  preload: bool,
  url: string,
};

export default PhotoUploadComponent;
