/* eslint max-len: 0 */

import React, { memo, useEffect, Fragment } from 'react';
import { object } from 'prop-types';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as mealActions from 'packs/meals/actions/mealActions';

import { withAppContext } from 'libs/support/appContext';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import PlannedMealCardV1 from '../components/PlannedMeal/v1/PlannedMealCard';
import PlannedMealCardV2 from '../components/PlannedMeal/v2/PlannedMealCard';


global.Intl = Intl;

const PlannedMealCardContainer = withAppContext(({
  meal,
  isLoading,
  isLoaded,
  fetchOrderables,
  flagIsEnabled,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];
  const orderables = meal.get('orderables');
  const isConfirmed = meal.get('isConfirmed');
  const Component = flagIsEnabled('v2_enabled') ? PlannedMealCardV2 : PlannedMealCardV1;

  useEffect(() => {
    if (!orderables && !isLoaded) {
      fetchOrderables(meal.get('mealPlanId'), meal.get('uuid'), {
        includes: [
          !isConfirmed ? 'carts' : 'orders',
        ],
      });
    }
  }, [meal]);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Fragment>
        <Component
          {
            ...{
              isLoading,
              meal,
            }
          }
        />
      </Fragment>
    </IntlProvider>
  );
});

PlannedMealCardContainer.propTypes = {
  meal: object.isRequired,
};

const mapStateToProps = ({
  $$mealCardStore,
}) => ({
  meal: $$mealCardStore.get('meal'),
  isLoading: $$mealCardStore.get('isLoading'),
  isLoaded: $$mealCardStore.get('isLoaded'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchOrderables: mealActions.fetchMealOrderables,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(memo(PlannedMealCardContainer));
