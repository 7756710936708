/* eslint max-len: 0 */

import React from 'react';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, object } from 'prop-types';
import { withAppContext } from 'libs/support/appContext';

import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

/* Components */
import mealActions from 'packs/meals/actions';
import GroupMealV1 from 'packs/meals/components/GroupOrderMeal/v1/GroupOrderMeal';
import GroupMealV2 from 'packs/meals/components/GroupOrderMeal/v2/GroupOrderMeal';

global.Intl = Intl;

/**
 * GroupOrderMealContainer
 */

const GroupOrderMealContainer = withAppContext(({
  actions,
  mealStore,
  cartsStore,
  cartVariationsStore,
  userMealOrderable,
  vendorsStore,
  menuItemsStore,
  flagIsEnabled,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];
  const Component = flagIsEnabled('grouped_meal_v2_enabled') ? GroupMealV2 : GroupMealV1;

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Component
        {
        ...{
          actions,
          mealStore,
          cartsStore,
          cartVariationsStore,
          userMealOrderable,
          vendorsStore,
          menuItemsStore,
        }
        }
      />
    </IntlProvider>
  );
});

GroupOrderMealContainer.propTypes = {
  actions: object.isRequired,
  mealStore: object.isRequired,
  cartsStore: object.isRequired,
  cartVariationsStore: object.isRequired,
  userMealOrderable: object.isRequired,
  menuItemsStore: object.isRequired,
  vendorsStore: object.isRequired,
  flagIsEnabled: func,
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
  vendorsStore: state.$$vendorsStore,
  menuItemsStore: state.$$menuItemsStore,
  userMealOrderable: state.$$mealStore.get('confirmed') ? state.$$userMealOrderStore : state.$$userMealCartStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...mealActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrderMealContainer);
