/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import * as mealActionTypes from 'packs/meals/constants/mealConstants';
import { groupMenuItemsById, groupMenuItemsByVendor } from '../stores/mealStore';

export const $$initialState = Immutable.fromJS({
  byVendorId: [],
  byId: [],
});

export default function menuItemsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  // eslint-disable-next-line no-unused-vars
  const defaultHash = {
    isSavingCartVariation: false,
    saveErrors: [],
    segmentTrack: {},
  };

  // DEFAULT CART VARIATION
  // eslint-disable-next-line no-unused-vars
  const cartVariation = {
    cart_variation_option_choices: [],
    cart_variation_item_add_on_options: [],
    cart_variation_service_add_on_options: [],
  };

  switch (type) {
    case mealActionTypes.FETCH_MEAL_SUCCESS: {
      return $$state.withMutations(state => {
        state.merge({
          byId: groupMenuItemsById(response.menu_items),
          byVendorId: groupMenuItemsByVendor(response.menu_items),
        });
      });
    }
    default: {
      return $$state;
    }
  }
}
