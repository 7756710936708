/* eslint react/no-danger: 0 */

import React from 'react';
import {
  bool,
  string,
  object,
  func,
} from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import $ from 'jquery';
import { fetchPdfLink } from 'libs/services/labelsApi';
import { createMealReport } from 'libs/services/mealReportsApi';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import BaseComponent from 'libs/components/BaseComponent';
import GroupOrderActionsDropdown from 'packs/common/components/GroupOrderActionsDropdown';
import { BackIcon } from 'libs/components/Icons';
import { ThreeDotsIcon } from 'libs/components/Icons';

import Tab from './Tab';

const TABS = [
  'ordered',
  'skipped',
  'pending',
];

class MealStatus extends BaseComponent {
  static propTypes = {
    hide: bool,
    mealPlanId: string.isRequired,
    mealId: string.isRequired,
    mealPlanUserId: string.isRequired,
    selectedTab: string.isRequired,
    mealSelectionStatus: object.isRequired,
    onHide: func.isRequired,
    onSelectTab: func.isRequired,
    userRoleIn: func.isRequired,
    flagIsEnabled: func,
    translate: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      downloadableLink: null,
      isProcessing: false,
      errorMsg: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    const {
      hide,
      onHide,
    } = this.props;

    if (!prevProps.hide && hide) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ downloadableLink: null });
      $(this.modal).modal('hide');
    } else if (prevProps.hide && !hide) {
      $(this.modal).modal('show');
      $(this.modal).on('hidden.bs.modal', () => {
        onHide();
      });
    }
  }

  handleOnClickDownloadExcel = () => {
    if (this.state.isProcessing) return;
    if (!this.props.userRoleIn(['admin', 'osc'])) return;

    this.setState({ isProcessing: true, downloadableLink: null });

    const {
      mealId,
      mealPlanUserId,
    } = this.props;

    const params = {
      meal_report: {
        meal_plan_user_id: mealPlanUserId,
        meal_id: mealId,
      },
    };

    createMealReport(
      params,
      (res) => {
        this.setState({ isProcessing: false, downloadableLink: res.link });
      },
      (err) => {
        this.setState({ isProcessing: false, errorMsg: err });
      },
    );
  }

  handleOnClickDownloadMealLabelPdf = () => {
    if (this.state.isProcessing) return;
    if (!this.props.userRoleIn(['admin', 'osc'])) return;

    this.setState({ isProcessing: true, downloadableLink: null });

    const {
      mealId,
      mealPlanId,
    } = this.props;
    const type = 'meal_labels';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        this.setState({ isProcessing: false, downloadableLink: res.link });
      },
      (err) => {
        this.setState({ isProcessing: false, errorMsg: err });
      },
    );
  }

  handleOnClickDownloadMenuCardPdf = () => {
    if (this.state.isProcessing) return;
    if (!this.props.userRoleIn(['admin', 'osc'])) return;

    this.setState({ isProcessing: true, downloadableLink: null });

    const { mealId, mealPlanId } = this.props;

    const type = 'meal_labels';

    fetchPdfLink(
      {
        mealId,
        mealPlanId,
        params: { tz: moment.tz.guess() },
        type,
      },
      (res) => {
        this.setState({
          isProcessing: false,
          downloadableLink: res.link,
        });
      },
      (err) => {
        this.setState({
          isProcessing: false,
          errorMsg: err,
        });
      },
    );
  }

  users = () => {
    const {
      selectedTab,
      mealSelectionStatus,
    } = this.props;

    if (selectedTab === 'ordered') return mealSelectionStatus.get('ordered_users');

    if (selectedTab === 'skipped') return mealSelectionStatus.get('skipped_users');

    return mealSelectionStatus.get('pending_users');
  }

  buildSuccessMsg = () => {
    if (this.state.isProcessing) {
      return this.props.translate('buttonsProcessing');
    } else if (this.state.downloadableLink) {
      return this.props.translate('termsDownloadHere', { link: this.state.downloadableLink });
    }
    return '';
  };


  render = () => {
    const {
      mealPlanId,
      mealId,
      selectedTab,
      mealSelectionStatus,
      onSelectTab,
      onHide,
      userRoleIn,
      flagIsEnabled,
      translate,
    } = this.props;

    const { errorMsg } = this.state;

    if (mealSelectionStatus.size === 0) return null;

    const successMsg = this.buildSuccessMsg();

    return (
      <div className="responsive-mobile-modal">
        <div
          ref={(node) => { this.modal = node; }}
          className="modal fade"
          aria-hidden="true"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-responsive" role="document">
            <div className="modal-content">
              <div className="modal-mobile-header card-header d-flex flex-row justify-content-between align-items-center">
                <h6 className="my-2 d-flex align-items-center">
                  <span
                    className="mr-2 d-lg-none back-icon"
                    onClick={onHide}
                  >
                    <BackIcon />
                  </span>
                  { translate('mealStatusHeaderTitle') }
                </h6>
                <div>
                  {
                    flagIsEnabled('sticker_label_enabled') &&
                    userRoleIn(['osc', 'admin']) &&
                      <GroupOrderActionsDropdown
                        btnClassName="btn-light btn-sm caret-off"
                        mealPlanId={mealPlanId}
                        mealId={mealId}
                        onClickDownloadExcel={this.handleOnClickDownloadExcel}
                        onClickDownloadMealLabelPdf={this.handleOnClickDownloadMealLabelPdf}
                        onClickDownloadMenuCardPdf={this.handleOnClickDownloadMenuCardPdf}
                      >
                        <ThreeDotsIcon />
                      </GroupOrderActionsDropdown>
                  }
                </div>
              </div>
              <div className="modal-mobile-header px-3 pt-3 pb-0">
                {
                  successMsg &&
                    <Alert variant="success" className="text-center">
                      <div dangerouslySetInnerHTML={{ __html: successMsg }} />
                    </Alert>
                }

                {
                  errorMsg &&
                    <Alert variant="danger" className="text-center">
                      { errorMsg }
                    </Alert>
                }
              </div>
              <div className="modal-header p-0">
                <div className="d-flex justify-content-between w-100 rounded-top p-3">
                  {
                    TABS.map(tab => (
                      <Tab
                        key={`tab=${tab}`}
                        count={mealSelectionStatus.get(`${tab}_users`).size}
                        tab={tab}
                        activeTab={selectedTab}
                        onClick={onSelectTab}
                      />
                    ))
                  }

                  {
                    !flagIsEnabled('sticker_label_enabled') &&
                      <button
                        className="btn btn-md btn-primary dropdown-toggle"
                        type="button"
                        disabled={this.state.isProcessing}
                        onClick={this.handleOnClickDownloadExcel}
                      >
                        { this.state.isProcessing ? translate('buttonsProcessing') : translate('buttonsDownload') }
                      </button>
                  }

                  <div className="--hide-on-mobile">
                    {
                      flagIsEnabled('sticker_label_enabled') && userRoleIn(['admin', 'osc']) &&
                        <GroupOrderActionsDropdown
                          btnClassName="btn-md btn-primary"
                          mealPlanId={mealPlanId}
                          mealId={mealId}
                          onClickDownloadExcel={this.handleOnClickDownloadExcel}
                          onClickDownloadMealLabelPdf={this.handleOnClickDownloadMealLabelPdf}
                          onClickDownloadMenuCardPdf={this.handleOnClickDownloadMenuCardPdf}
                        >
                          { translate('buttonsDownload') }
                        </GroupOrderActionsDropdown>
                    }
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div className="--hide-on-mobile">
                  {
                    successMsg &&
                      <Alert variant="success" className="text-center">
                        <div dangerouslySetInnerHTML={{ __html: successMsg }} />
                      </Alert>
                  }

                  {
                    errorMsg &&
                      <Alert variant="danger" className="text-center">
                        { errorMsg }
                      </Alert>
                  }
                </div>

                <ul className="list-group list-group-flush">
                  {
                    this.users().map((user) => (
                      <li
                        className="list-group-item px-0"
                        key={`user-${user.get('uuid')}`}
                      >
                        <h6>{user.get('full_name')}</h6>
                        <a className="text-muted">{user.get('email')}</a>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAppContext(withI18n(MealStatus));
