import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { withI18n } from 'libs/support/i18n';
import { array, object } from 'prop-types';
import { Card, CardTitle } from '../../../styles';
import { Money } from '../../Money';
import {
  PROGRAM_COLORS,
  formatDate,
  formatMoney,
} from '../../utils';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
);

export const SpendingTrend = withI18n(({ summary, events, translate }) => {
  const buildBarDataset = (event, _i = 0) => ({
    label: translate('financialReportsProgramNamesEvent'),
    data: event.data.map(({ totalSpend }) => totalSpend),
    backgroundColor: PROGRAM_COLORS.Event,
    borderColor: PROGRAM_COLORS.Event,
    barThickness: 20,
    borderRadius: 25,
    borderWidth: 3,
  });

  const datasets = useMemo(() => [buildBarDataset(events[0])], [events]);

  return (
    <Card data-testid="reporting-events-spending-trend">
      <CardTitle>
        {translate('financialReportsTermsTotalSpend')}
      </CardTitle>
      <Money value={summary.totalSpend} fontSize={48} />
      <Bar
        height={80}
        options={{
            elements: {
              point: {
                radius: 6,
                hoverRadius: 6,
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                align: 'end',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  label: (item) => formatMoney(item.raw, { withCents: true, withSymbol: false }),
                },
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (value) => formatMoney(value, {
                      withSymbol: true,
                      withCents: false,
                      compact: true,
                      truncate: true,
                    }),
                  color: '#BFC1C4',
                },
              },
            },
          }}
        data={{
            labels: events[0].data.map(({ step }) => formatDate(step)),
            datasets,
          }}
      />
    </Card>
  );
});

SpendingTrend.propTypes = {
  summary: object.isRequired,
  events: array.isRequired,
};
