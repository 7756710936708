/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { notBlank } from 'libs/support/string';

/*
 * Returns servings details
 *
 * Ex.
 *  getServings(1, 1)
 *  => 1 - 1
 * getServings(1, null)
 *  => 1
 */
function getServings(minServings, maxServings) {
  if (minServings === maxServings) { return `${minServings}`; }
  return `${minServings} - ${maxServings}`;
}

/**
 * MenuItemMetas Static Component
 */

const MenuItemMetas = ({ quantity, quantityType, minServings, maxServings }) => {
  const servings = getServings(minServings, maxServings);
  const fullServingsDescription = `formatMessage(defaultMessages.menuItemsServes)} ${servings}`;

  return (
    <div>
      { /* Full Serving Description */}
      {
        (notBlank(fullServingsDescription)) &&
        <a
          className="tooltip-wrap text-muted"
          data-toggle="tooltip"
          href="#"
          data-turbolinks="false"
          title={fullServingsDescription}
        >
          <svg viewBox="0 0 12 16" width="12" height="16" xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M8.72689 16H3.27258C1.46503 16 0 14.6565 0 13.0004V11.0003C0 9.6783 0.937584 8.56921 2.23189 8.1692C1.52938 7.30417 1.09086 6.20971 1.09086 4.99967C1.09086 2.23858 3.2884 0 5.99973 0C8.71052 0 10.9086 2.23858 10.9086 4.99967C10.9086 6.20971 10.4695 7.30367 9.76758 8.1692C11.0619 8.56921 12 9.6788 12 11.0003V13.0004C11.9995 14.657 10.5339 16 8.72689 16ZM9.81775 4.99967C9.81775 2.7906 8.10837 0.999543 5.99973 0.999543C3.89055 0.999543 2.18172 2.7906 2.18172 4.99967C2.18172 7.20874 3.8911 9.00028 5.99973 9.00028C8.10782 9.00028 9.81775 7.20874 9.81775 4.99967ZM10.9086 11.0003C10.9086 9.94931 10.0223 9.09729 8.89923 9.01579C8.0849 9.6273 7.08895 9.99982 6.00027 9.99982C4.91104 9.99982 3.91455 9.6273 3.10077 9.01579C1.97773 9.09729 1.09139 9.94931 1.09139 11.0003V13.0004C1.09139 14.1039 2.06826 15 3.27311 15H8.72742C9.93227 15 10.9091 14.1039 10.9091 13.0004L10.9086 11.0003Z"
            />
          </svg>
          <span className="ml-1">
            { servings }
          </span>
        </a>
      }

      { /* Quantity */}
      {
        (notBlank(quantity)) &&
        <a
          className="tooltip-wrap text-muted"
          href="#"
          data-turbolinks="false"
          data-toggle="tooltip"
          title="1 Course, 36 pieces"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C3.5815 16 0 12.4185 0 7.99999C0 3.58199 3.5815 -7.08008e-06 8 -7.08008e-06C12.418 -7.08008e-06 16 3.58199 16 7.99999C16 12.4185 12.418 16 8 16ZM8 1.0005C4.134 1.0005 1 4.13449 1 7.99999C1 11.866 4.134 15.0005 8 15.0005C11.866 15.0005 15 11.866 15 7.99999C15 4.13449 11.866 1.0005 8 1.0005ZM8 12C5.7905 12 4 10.2095 4 7.99999C4 5.79099 5.7905 3.99999 8 3.99999C10.209 3.99999 12 5.79099 12 7.99999C12 10.2095 10.209 12 8 12ZM8 5.0005C6.343 5.0005 5 6.34399 5 7.99999C5 9.65699 6.343 11.001 8 11.001C9.6565 11.001 11 9.65699 11 7.99999C11 6.34349 9.6565 5.0005 8 5.0005Z"
            />
          </svg>
          <span className="ml-1">
            {quantity} {quantityType.toLowerCase()}
          </span>
        </a>
      }
    </div>
  );
};


MenuItemMetas.propTypes = {
  quantity: PropTypes.number,
  quantityType: PropTypes.string,
  minServings: PropTypes.number,
  maxServings: PropTypes.number,
};

export default injectIntl(MenuItemMetas);
