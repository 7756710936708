/* eslint max-len: 0 */

import React from 'react';

const Back = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289C9.09763 2.68342 9.09763 3.31658 8.70711 3.70711L3.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H3.41421L8.70711 16.2929C9.09763 16.6834 9.09763 17.3166 8.70711 17.7071C8.31658 18.0976 7.68342 18.0976 7.29289 17.7071L0.292893 10.7071C-0.097631 10.3166 -0.097631 9.68342 0.292893 9.29289L7.29289 2.29289Z"
      fill="#7D9E1D"
    />
  </svg>
);

export default Back;
