/* eslint-disable camelcase */
/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import { formattedPrice } from 'libs/support/formattedPrice';

import * as mealActionTypes from 'packs/meals/constants/mealConstants';
import {
  DELETE_CART_VARIATION_SUCCESS,
  UPDATE_QUANTITY_CART_VARIATION_SUCCESS,
  SAVE_CART_VARIATION_SUCCESS,
} from 'packs/common/constants/cartVariationsConstants';
import {
  CONFIRM_USER_MEAL_CART_SUCCESS,
  CHECK_OUT_USER_MEAL_CART_SUCCESS,
  EDIT_USER_MEAL_CART_SUCCESS,
  RESET_USER_MEAL_CART_SUCCESS,
} from '../constants/userMealCartConstants';
import {
  SAVE_CUSTOM_MENU_ITEM_SUCCESS,
} from '../constants/customMenuItemConstants';


import { constructCartsStore } from '../stores/mealStore';

export const $$initialState = Immutable.fromJS({
  byVendorId: {},
  byId: {},
  allIds: [],
});

export default function cartsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case mealActionTypes.FETCH_MEAL_SUCCESS: {
      return $$state.withMutations(state => {
        state.merge(constructCartsStore(response));
      });
    }
    case DELETE_CART_VARIATION_SUCCESS:
    case UPDATE_QUANTITY_CART_VARIATION_SUCCESS:
    case SAVE_CUSTOM_MENU_ITEM_SUCCESS:
    case SAVE_CART_VARIATION_SUCCESS: {
      const { cart } = response;

      if (cart) {
        return $$state.withMutations(state => (
          state.updateIn(['byId', cart.uuid], (cartState) => (
            cartState.merge({
              subtotalAmount: cart.subtotal_amount,
              deliveryAmount: cart.delivery_amount,
              surchargeAmount: cart.surcharge_amount,
              discountAmount: cart.discount_amount,
              caterspotRefund: cart.caterspot_refund,
              vendorRefund: cart.vendor_refund,
              taxAmount: cart.tax_amount,
              totalAmount: cart.total_amount,
              totalSmallOrderFee: cart.total_small_order_fee,
              surchargeName: cart.surcharge_name,
            })
          ))
        ));
      }
      return $$state;
    }
    case CONFIRM_USER_MEAL_CART_SUCCESS:
    case CHECK_OUT_USER_MEAL_CART_SUCCESS:
    case EDIT_USER_MEAL_CART_SUCCESS:
    case RESET_USER_MEAL_CART_SUCCESS: {
      const { user_meal_cart } = response;
      const { carts } = user_meal_cart;

      return $$state.withMutations(state => {
        carts.forEach(cart => {
          state.updateIn(['byId', cart.uuid], (cartState) => (
            cartState.merge({
              mealCountCap: cart.meal_count_cap,
              mealCountCapReached: cart.meal_count_cap_reached,
              orderedCount: cart.ordered_count,
            })
          ));
        });

        return state;
      });
    }

    default: {
      return $$state;
    }
  }
}

// Private
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/*
 * Get total
 */
export const getTotals = (cartsStore, denominator = 1) => {
  const totals = cartsStore.get('byId').reduce((hash, cart) => {
    hash.subtotal += cart.get('subtotalAmount');
    hash.deliveryAmount += cart.get('deliveryAmount');
    hash.surchargeAmount += cart.get('surchargeAmount');
    hash.discountAmount += cart.get('discountAmount');
    hash.caterspotRefund += cart.get('caterspotRefund') || 0;
    hash.vendorRefund += cart.get('vendorRefund') || 0;
    hash.taxAmount += cart.get('taxAmount');
    hash.total += cart.get('totalAmount');
    hash.totalSmallOrderFee += cart.get('totalSmallOrderFee');

    if (cart.get('surchargeName')) { hash.surchargeNames.push(cart.get('surchargeName')); }

    return hash;
  }, {
    average: 0,
    subtotal: 0,
    deliveryAmount: 0,
    surchargeAmount: 0,
    discountAmount: 0,
    caterspotRefund: 0,
    vendorRefund: 0,
    taxAmount: 0,
    total: 0,
    totalSmallOrderFee: 0,
    surchargeNames: [],
  });

  // total - (tax + refund + delivery)
  const netTotal = (
    totals.total -
    (totals.deliveryAmount + totals.taxAmount + totals.caterspotRefund + totals.vendorRefund)
  );

  return Immutable.fromJS({
    average: formattedPrice(netTotal / denominator),
    subtotal: formattedPrice(totals.subtotal),
    total: formattedPrice(totals.total),
    deliveryAmount: formattedPrice(totals.deliveryAmount),
    surchargeAmount: formattedPrice(totals.surchargeAmount),
    discountAmount: formattedPrice(totals.discountAmount),
    caterspotRefund: formattedPrice(totals.caterspotRefund),
    vendorRefund: formattedPrice(totals.vendorRefund),
    taxAmount: formattedPrice(totals.taxAmount),
    totalSmallOrderFee: totals.totalSmallOrderFee > 0 ? formattedPrice(totals.totalSmallOrderFee) : null,
    taxEnabled: totals.taxAmount > 0,
    surchargeNames: totals.surchargeNames.filter(onlyUnique).reduce((str, name) => `${str}, ${name}`, ''),
  });
};
