import { useEffect } from 'react';

export const useURLState = (params) => {
  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    Object.entries(params).forEach(([key, value]) => {
      currentUrl.searchParams.set(key, value);
    });

    const newUrl = currentUrl.href;

    window.history.pushState(null, null, newUrl);
  }, [params]);
};
