/* eslint-disable max-len */
import React from 'react';
import { object, func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import NotificationGroup from './NotificationGroup';
import {
  AccountSettings,
  SectionTitle,
  NotificationsSection,
  HorizontalRule,
} from '../styles/desktop';

const NotificationSettings = ({ settings, updateSetting, settingsStructure, role, translate }) => (
  <div className="container-fluid" style={{ display: 'flex', justifyContent: 'center' }}>
    <AccountSettings>
      <SectionTitle>{ translate('accountSettingsHeaderTitle') }</SectionTitle>
      <NotificationsSection>
        <div className="description" style={{ color: 'black', paddingTop: '14px' }}>{ translate('accountSettingsNotificationSettingsHeaderTitle') }</div>
        <HorizontalRule />
        {settingsStructure.map(group => (
          <NotificationGroup
            key={group.section}
            section={group.section}
            groups={group.groups}
            role={role}
            updateSetting={updateSetting}
            currentSettings={settings}
          />
          ))}
      </NotificationsSection>
    </AccountSettings>
  </div>
);

NotificationSettings.propTypes = {
  settings: object.isRequired,
  updateSetting: func.isRequired,
  settingsStructure: object.isRequired,
  role: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(NotificationSettings);
