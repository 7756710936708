import React from 'react';
import moment from 'moment';
import uppercamelcase from 'uppercamelcase';
import { func, object } from 'prop-types';

import Nbsp from 'libs/components/Nbsp';
import { withI18n } from 'libs/support/i18n';

const CardHeader = ({
  meal,
  translate,
}) => {
  const renderMealState = () => {
    const [format, timeLeft] = meal.get('timeLeft').toJS();

    if (meal.get('isConfirmed')) {
      return (
        <span className="badge badge-primary text-uppercase">
          { translate('mealPlanUserMealPlansCardHeaderDeadlineConfirmed') }
        </span>
      );
    } else if (meal.get('isOverdue')) {
      return (
        <span className="badge badge-primary text-uppercase">
          { translate('mealPlanUserMealPlansCardHeaderDeadlineOverdue') }
        </span>
      );
    } else if (timeLeft >= 0) {
      return (
        <span className="badge badge-danger text-uppercase">
          {
            translate(
              `mealPlanUserMealPlansCardHeaderDeadline${uppercamelcase(format)}`,
              { count: timeLeft },
            )
          }
        </span>
      );
    } else if (meal.get('isProcessing')) {
      return (
        <span className="badge badge-primary text-uppercase">
          {translate('mealPlanUserMealPlansCardHeaderDeadlineProcessing')}
        </span>
      );
    } else if (meal.get('isPastMealPlan')) {
      return (
        <span className="badge badge-primary text-uppercase">
          { translate('mealPlanUserMealPlansCardHeaderDeadlineProcessing') }
        </span>
      );
    }

    return null;
  };

  return (
    <div className="card-header">
      <div className="d-flex justify-content-between">
        <div className="text-uppercase">
          { moment(meal.get('scheduleAt')).format('dddd') },
          <Nbsp />
          <span className="text-muted">
            { moment(meal.get('scheduleAt')).format('DD MMM h:mmA') }
          </span>
        </div>
        <div>
          { renderMealState() }
        </div>
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  meal: object.isRequired,
  translate: func.isRequired,
};

export default withI18n(CardHeader);
