/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint no-return-assign: 0, max-len: 0, react/no-array-index-key: 0, no-param-reassign: 0 */

import React from 'react';
import { bool, func, object, string } from 'prop-types';
import $ from 'jquery';
import bindAll from 'lodash/bindAll';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MEAL_STATE } from 'packs/meals/constants/mealConstants';

import BaseComponent from 'libs/components/BaseComponent';
import { blankish, notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

import VariationForm from './VariationForm/VariationForm';
import ChoiceForm from './Options/ChoiceForm';

class MenuItemFormCartVariationForm extends BaseComponent {
  static propTypes = {
    isGroupOrder: bool,
    isEditable: bool,
    catererId: string.isRequired,
    cartId: string,
    hideAddOns: bool,
    hideSpecialInstruction: bool,
    mealId: string.isRequired,
    mealLimit: object,
    mealPlanId: string.isRequired,
    mealPlanUserId: string,
    cartUserId: string,
    saveErrors: object,
    variations: object,
    choices: object,
    itemAddOns: object,
    serviceAddOns: object,
    cartVariation: object,
    orderableVariations: object,
    onClickOption: func.isRequired,
    onDeleteItemAddOnOptionPhoto: func.isRequired,
    onDropItemAddOnOptionPhoto: func.isRequired,
    onDropItemAddOnOptionPhotoFailure: func.isRequired,
    onSelectVariation: func.isRequired,
    onSubmit: func.isRequired,
    onUpdateItemAddOnOptionText: func.isRequired,
    showPrice: bool.isRequired,
    intl: intlShape.isRequired,
    cartVariationBackButton: bool,
    onClickCartVariationBackButton: func,
    onShowExceededMaxOptionsAlert: func,
  };

  constructor() {
    super();

    bindAll(this, 'handleOnSubmit');
  }

  handleOnSubmit(e) {
    e.preventDefault();

    const {
      isGroupOrder,
      catererId,
      cartId,
      cartUserId,
      choices,
      itemAddOns,
      serviceAddOns,
      cartVariation,
      mealPlanId,
      onSubmit,
      intl,
      mealPlanUserId,
    } = this.props;

    const { formatMessage } = intl;
    const id = cartVariation.get('uuid');
    const params = $(this.form).serializeJSON();

    if (notBlank(cartId)) params.cart_variation.cart_id = cartId;

    if (blankish(id)) params.vendor_id = catererId;

    if (isGroupOrder) {
      const isGuest = cartVariation.get('cart_user_is_guest');
      params.cart_id = cartId || cartVariation.get('cart_id');
      params.cart_user_id = cartVariation.get('meal_plan_user_id');
      params.cart_variation.cart_user_id = cartVariation.get('cart_user_id');
      params.cart_variation.cart_id = cartId || cartVariation.get('cart_id');
      params.cart_variation.cart_user_is_guest = isGuest;
      params.cart_variation.cart_user_name = cartVariation.get('cart_user_name');

      if (isGuest) {
        params.is_guest = isGuest;
        params.guest_name = cartVariation.cart_user_name;
      }
    }

    if ((choices.filter(choice => !choice.getIn(['choice', 'is_valid'])).size > 0)) {
      alert(formatMessage(defaultMessages.cartVariationsFormErrorCompleteSelections));
      return false;
    }

    if ((itemAddOns.filter(choice => !choice.getIn(['choice', 'is_valid'])).size > 0)) {
      alert(formatMessage(defaultMessages.cartVariationsFormErrorCompleteSelections));
      return false;
    }

    if ((serviceAddOns.filter(choice => !choice.getIn(['choice', 'is_valid'])).size > 0)) {
      alert(formatMessage(defaultMessages.cartVariationsFormErrorCompleteSelections));
      return false;
    }

    onSubmit(mealPlanId, id, params);

    return true;
  }

  render() {
    const {
      cartId,
      cartVariation,
      choices,
      hideAddOns,
      hideSpecialInstruction,
      intl,
      isEditable,
      itemAddOns,
      mealId,
      mealLimit,
      mealPlanUserId,
      orderableVariations,
      onClickOption,
      onDeleteItemAddOnOptionPhoto,
      onDropItemAddOnOptionPhoto,
      onDropItemAddOnOptionPhotoFailure,
      onSelectVariation,
      onUpdateItemAddOnOptionText,
      saveErrors,
      serviceAddOns,
      showPrice,
      variations,
      cartVariationBackButton,
      onClickCartVariationBackButton,
      onShowExceededMaxOptionsAlert,
      mealIsProcessing,
      userMealOrderable,
    } = this.props;

    const { formatMessage } = intl;
    const text = notBlank(cartVariation.get('uuid')) ? 'Update' : 'AddToCart';
    const submitText = formatMessage(defaultMessages[`cartVariationsForm${text}`]);
    const selectedCartVariationId = cartVariation.get('uuid');
    const userOtherOrderableVariations = (orderableVariations || Immutable.fromJS([]))
      .filter((orderableVariation) => orderableVariation.get('uuid') !== selectedCartVariationId)
      .filter((orderableVariation) => orderableVariation.get('mealPlanUserId') === mealPlanUserId);
    const userTotalQuantity = userOtherOrderableVariations.reduce((total, orderableVariation) => total += orderableVariation.get('quantity'), 0);
    const mealAvailableLimit = (blankish(mealLimit) || mealLimit.size === 0) ? 9999 : (mealLimit.get('mealAvailableLimit') || 0);

    let selectedVariation = null;

    const selectedVariationId = cartVariation.get('variation_id');

    if (notBlank(selectedVariationId)) {
      selectedVariation = variations.find(data => data.get('uuid') === selectedVariationId);
    } else {
      selectedVariation = variations.first();
    }

    const minOrderQuantity = notBlank(selectedVariation) && (selectedVariation.get('min_order_quantity') || 1);
    const quantity = cartVariation.get('quantity') || minOrderQuantity;
    const processingAndNotOrdered = mealIsProcessing && userMealOrderable.get('currentState') === MEAL_STATE.confirmed;
    const disabledAddToCart = processingAndNotOrdered || (blankish(selectedCartVariationId) && mealAvailableLimit < userTotalQuantity + quantity);

    return (
      <form ref={node => this.form = node}>
        { /* MealId */}
        <input
          type="hidden"
          name="meal_id"
          defaultValue={mealId}
        />

        { /* CartId */}
        <input
          type="hidden"
          name="cart_id"
          defaultValue={cartId}
        />

        { /* MealPlanUserId */}
        <input
          type="hidden"
          name="meal_plan_user_id"
          defaultValue={mealPlanUserId}
        />

        {
          (variations.size > 0) &&
          <VariationForm
            {...{
              isEditable,
              mealAvailableLimit,
              mealLimit,
              minOrderQuantity,
              onSelectVariation,
              quantity,
              selectedVariationId,
              showPrice,
              userTotalQuantity,
              variations,
            }}
          />
        }

        {
          choices.map(choice => (
            <ChoiceForm
              key={`choice-${choice.get('uuid')}`}
              parentType="choices"
              optionName="option_choices"
              lastName="option_choice_id"
              choice={choice.get('choice')}
              selectedOptions={cartVariation.get('cart_variation_option_choices')}
              onShowExceededMaxOptionsAlert={onShowExceededMaxOptionsAlert}
              {...{
                onClickOption, hideAddOns, showPrice, isEditable,
              }}
            />
          ))
        }

        {
          itemAddOns.map(choice => (
            <ChoiceForm
              key={`choice-${choice.get('uuid')}`}
              parentType="item_add_ons"
              optionName="item_add_on_options"
              lastName="item_add_on_option_id"
              choice={choice.get('choice')}
              selectedOptions={cartVariation.get('cart_variation_item_add_on_options')}
              onShowExceededMaxOptionsAlert={onShowExceededMaxOptionsAlert}
              {...{
                hideAddOns,
                isEditable,
                onClickOption,
                onDeleteItemAddOnOptionPhoto,
                onDropItemAddOnOptionPhoto,
                onDropItemAddOnOptionPhotoFailure,
                onUpdateItemAddOnOptionText,
                showPrice,
              }}
            />
          ))
        }

        {
          serviceAddOns.map(choice => (
            <ChoiceForm
              key={`choice-${choice.get('uuid')}`}
              parentType="service_add_ons"
              optionName="service_add_on_options"
              lastName="service_add_on_option_id"
              choice={choice.get('choice')}
              selectedOptions={cartVariation.get('cart_variation_service_add_on_options')}
              onShowExceededMaxOptionsAlert={onShowExceededMaxOptionsAlert}
              {...{
                onClickOption, hideAddOns, showPrice, isEditable,
              }}
            />
          ))
        }

        {
          !hideSpecialInstruction &&
          <div className="row">
            <div className="col-sm-12">
              <h6 className="display-6">
                <a
                  className="btn-special-instruction collapsed"
                  aria-controls="special-instructions"
                  aria-expanded={notBlank(cartVariation.get('additional_instructions'))}
                  data-toggle="collapse"
                  href="#special-instruction"
                >
                  {formatMessage(defaultMessages.cartVariationsFormAddSpecialInstruction)}
                </a>

                <div
                  id="special-instruction"
                  className={classNames('collapse', { show: notBlank(cartVariation.get('additional_instructions')) })}
                >
                  <textarea
                    name="cart_variation[additional_instructions]"
                    className="form-control"
                    placeholder={formatMessage(defaultMessages.cartVariationsFormSpecialInstructionPlaceholder)}
                    defaultValue={cartVariation.get('additional_instructions')}
                    disabled={!isEditable}
                  />
                </div>
              </h6>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-7">
            {
              (saveErrors.size > 0) &&
              <div className="alert alert-danger m-b-md-0">
                {
                  (saveErrors.size > 1) ?
                    <div>
                      <h6>{formatMessage(defaultMessages.errorsMessagesNotSaved)}</h6>

                      <ul>
                        {
                        saveErrors.map((message, index) => <li key={`error-${index}`}>{message}</li>)
                      }
                      </ul>
                    </div> :
                  saveErrors.first()
                }
              </div>
            }
          </div>

          {
            isEditable && !disabledAddToCart &&
            <div className="col-md-5">
              <input
                type="submit"
                name="commit"
                className="btn btn-primary btn-cart pull-right"
                value={submitText}
                onClick={this.handleOnSubmit}
              />
              {cartVariationBackButton &&
                <button
                  className="btn btn-light pull-right mr-2"
                  aria-label="Close"
                  data-dismiss="modal"
                  onClick={onClickCartVariationBackButton}
                  type="button"
                >
                  Back
                </button>
              }
            </div>
          }
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  mealIsProcessing: state.$$mealStore.get('processing'),
  userMealOrderable: state.$$mealStore.get('confirmed') ? state.$$userMealOrderStore : state.$$userMealCartStore,
});

export default connect(mapStateToProps)(injectIntl(MenuItemFormCartVariationForm));
