import React from 'react';

const Halal = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#9A009A"
    />
    <path
      d="M8.67483 16H10.0068V12.352H13.9908V16H15.3228V7.6H13.9908V11.14H10.0068V7.6H8.67483V16Z"
      fill="white"
    />
  </svg>
);

export default Halal;
