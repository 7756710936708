import React from 'react';
import { string } from 'prop-types';
import { Group } from '../styles';

export const SectionDivider = ({ text }) => (
  <Group style={{ alignItems: 'center', marginTop: '1.5rem' }}>
    <div className="h2">{text}</div>
    <div style={{ background: 'black', flexGrow: 1, border: '2px solid black' }} />
  </Group>
);

SectionDivider.propTypes = {
  text: string.isRequired,
};
