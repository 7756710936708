/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'libs/support/i18n';

const MenuItemIncludedItems = ({
  includedItems,
  translate,
}) => {
  if (!includedItems || includedItems.size < 1) { return null; }

  return (
    <div className="mb-2">
      <small className="w-100 d-block text-uppercase text-muted">
        {translate('menuItemsWhatsIncluded')}
      </small>

      {
        includedItems.map((includedItem, i) =>
          (
            <div key={`includedItem-${i}`}>
              {includedItem.get('name')}
            </div>
          ))
      }
    </div>
  );
};

MenuItemIncludedItems.propTypes = {
  includedItems: PropTypes.object,
  translate: PropTypes.func,
};

export default withI18n(MenuItemIncludedItems);
