/* eslint max-len: 0 */

import React from 'react';

const CloseModal = (_props) => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="20" y="20" width="20" height="20" rx="10" transform="rotate(-180 20 20)" fill="#F3F4F6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.8751 13.8111L10.0666 11.0026L7.12462 13.945C6.82921 14.2404 6.34998 14.2404 6.05457 13.945C5.75916 13.6496 5.75916 13.1704 6.05457 12.875L8.99694 9.93297L6.18846 7.12449C5.89305 6.82908 5.89305 6.35022 6.18846 6.05481C6.48388 5.7594 6.96273 5.7594 7.25815 6.05481L10.0666 8.86329L12.7412 6.18871C13.0366 5.8933 13.5155 5.8933 13.8113 6.18871C14.1067 6.48412 14.1067 6.96298 13.8113 7.25839L11.1363 9.93297L13.9448 12.7414C14.2402 13.0369 14.2402 13.5157 13.9448 13.8111C13.6494 14.1065 13.1705 14.1065 12.8751 13.8111Z" fill="#6B7280" />
  </svg>

);

export default CloseModal;
