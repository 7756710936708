/* eslint max-len: 0 */

import React from 'react';

const MasterCard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="33" viewBox="0 0 52 33" fill="none" style={{ fill: '#fff' }}>
    <g filter="url(#filter0_d)">
      <rect width="52" height="32" rx="2.66667" fill="white" />
      <rect x="0.5" y="0.5" width="51" height="31" rx="2.16667" stroke="#DBDBDB" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M37.1118 16.1603C37.1118 20.115 33.9119 23.3209 29.9647 23.3209C26.0175 23.3209 22.8176 20.115 22.8176 16.1603C22.8176 12.2056 26.0175 8.99966 29.9647 8.99966C33.9119 8.99966 37.1118 12.2056 37.1118 16.1603V16.1603Z" fill="#F79F1A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.2941 16.1605C28.2941 20.1152 25.0943 23.3211 21.1471 23.3211C17.1998 23.3211 14 20.1152 14 16.1605C14 12.2058 17.1998 8.99994 21.1471 8.99994C25.0943 8.99994 28.2941 12.2058 28.2941 16.1605V16.1605Z" fill="#EA001B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.5557 10.5235C23.8881 11.8344 22.8176 13.8714 22.8176 16.1597C22.8176 18.448 23.8881 20.4866 25.5557 21.7975C27.2232 20.4866 28.2937 18.448 28.2937 16.1597C28.2937 13.8714 27.2232 11.8344 25.5557 10.5235V10.5235Z" fill="#FF5F01" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="52" height="32.6667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="0.666667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.917647 0 0 0 0 0.921569 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default MasterCard;
