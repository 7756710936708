/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAppContext } from 'libs/support/appContext';
import moment from 'moment-timezone';
import MobileIllustration from 'libs/components/Illustrations/Mobile';

/* Actions */
import actions from 'packs/meals/actions';

import { prevent } from 'libs/support/prevent';


/**
 * MealSkippedHeader
 */
const MealSkippedHeader = ({
  actions,
  mealStore,
  userMealCartStore,
}) => {
  const userName = mealStore.getIn(['currentUser', 'firstName']);
  const timezone = mealStore.get('timezone');
  const scheduleAt = moment(mealStore.get('scheduleAt')).tz(timezone);

  const handleReset = (_e) => {
    prevent(actions.unskipUserMealCart(mealStore.get('mealPlanId'), userMealCartStore.get('uuid')));
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card card-panel meal-banner meal-banner--warning">
          <div className="card-body meal-banner__body">
            <div className="meal-banner__image" >
              <MobileIllustration />
            </div>
            <div className="meal-banner__content" >
              <h5>Hi, { userName }</h5>

              <p className="lead meal-banner__lead">
                You&apos;ve chosen to skip your meal for { scheduleAt.format('dddd, D MMMM') }
              </p>

              {
                mealStore.get('open') && !mealStore.get('processing') &&
                <div className="meal-banner__actions" >
                  <button
                    className="btn btn-primary btn-sm btn-600"
                    onClick={handleReset}
                  >
                    Change
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MealSkippedHeader.propTypes = {
  actions: object.isRequired,
  mealStore: object.isRequired,
  userMealCartStore: object.isRequired,
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  userMealCartStore: state.$$userMealCartStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(MealSkippedHeader));
