/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import CalendarIllustration from 'libs/components/Illustrations/Calendar';
import { object } from 'prop-types';


/**
 * MissedOrderHeader
 */
const MissedOrderHeader = ({ mealStore }) => {
  const firstName = mealStore.getIn(['currentUser', 'firstName']);

  return (
    <div className="row">
      <div className="col">
        <div className="card card-panel meal-banner meal-banner--success">
          <div className="card-body meal-banner__body">
            <div className="meal-banner__image" >
              <CalendarIllustration />
            </div>
            <div className="meal-banner__content" >
              <h5>Hi, { firstName }</h5>

              <p className="lead meal-banner__lead">
                Sorry! The deadline for this meal has passed. You did not order a meal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
});

MissedOrderHeader.propTypes = {
  mealStore: object,
};

export default connect(mapStateToProps, null)(injectIntl(MissedOrderHeader));
