/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import $ from 'jquery';
import { blankish } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';

import DietaryBadgeContainer from 'packs/meals/components/Shared/DietaryBadgeContainer';
import EditIcon from 'libs/components/Icons/EditIcon';

import MenuItemSelection from './MenuItemSelection';
import MenuItemIncludedItems from './MenuItemIncludedItems';
import MenuItemMetas from '../shared/MenuItemMetas';
import MenuItemPhoto from '../shared/MenuItemPhoto';
import MenuItemInfo from '../shared/MenuItemInfo';

const MenuItemModifier = ({
  mealConfirmed,
  mealAdmin,
  mealEditable,
  onUpdate,
  cartVariation,
  onEditSelection,
  translate,
}) => (
  <Fragment>
    {
      mealEditable &&
      <button
        id="edit-user"
        className="!tw-flex tw-items-center !tw-px-2 tw-text-broccoli-600 tw-font-semibold"
        onClick={onEditSelection}
      >
        <EditIcon />
        {translate('menuItemsEdit')}
      </button>
    }
    <MenuItemInfo
      objectId={cartVariation.get('uuid')}
      quantity={cartVariation.get('quantity')}
      minQuantity={cartVariation.get('min_order_quantity') || 1}
      subtotal={cartVariation.get('subtotal')}
      mealAdmin={mealAdmin}
      mealConfirmed={mealConfirmed}
      mealEditable={mealEditable}
      updateQuantityHandler={onUpdate}
    />
  </Fragment>
);

MenuItemModifier.propTypes = {
  mealConfirmed: PropTypes.bool.isRequired,
  cartVariation: PropTypes.object.isRequired,
  onEditSelection: PropTypes.func.isRequired,
  mealAdmin: PropTypes.bool.isRequired,
  mealEditable: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

/**
 * MenuItem
 */

const MenuItem = ({
  cartVariation,
  menuItem,
  mealPlanId,
  onEditSelection,
  onAddAnotherSelection,
  mealAdmin,
  mealConfirmed,
  mealEditable,
  updateQuantity,
  translate,
}) => {
  const dietaries = menuItem.get('dietaries');
  const variationQuantity = cartVariation.get('quantity');
  const hasVariation = cartVariation.getIn(['variation', 'name']);
  const hasOptionChoices = cartVariation.get('cart_variation_option_choices').size > 0;
  const hasItemAddOns = cartVariation.get('cart_variation_item_add_on_options').size > 0;
  const hasServiceAddOns = cartVariation.get('cart_variation_service_add_on_options').size > 0;

  const hasMandatorySelections = (hasOptionChoices || hasItemAddOns || hasServiceAddOns);
  const hasSelections = hasVariation || hasMandatorySelections;
  const isRequired = menuItem.get('required');

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  const updateQuantityHandler = (e, quantity) => {
    e.preventDefault();

    if (variationQuantity === 0 && isRequired) {
      return onEditSelection(e, menuItem, cartVariation);
    }

    return updateQuantity(
      mealPlanId,
      cartVariation.get('uuid'),
      quantity,
      cartVariation,
    );
  };

  return (
    <div className="media tw-flex-col">
      <div className="tw-w-full tw-flex tw-items-start md:tw-items-start tw-justify-between">
        <MenuItemPhoto photo={menuItem.getIn(['main_photo', 'medium'])} />
        <div className="md:tw-hidden tw-m-0 tw-flex tw-justify-end tw-gap-1">
          <MenuItemModifier
            mealConfirmed={mealConfirmed}
            cartVariation={cartVariation}
            mealAdmin={mealAdmin}
            mealEditable={mealEditable}
            onUpdate={(e, quantity) => updateQuantityHandler(e, quantity)}
            onEditSelection={(e) => onEditSelection(e, menuItem, cartVariation)}
            translate={translate}
          />
        </div>
      </div>

      <div className="media-body tw-w-full">
        <div className="order-item">
          <div className="tw-grid tw-grid-cols-6 tw-flex tw-justify-between tw-items-start tw-gap-2">
            <div className="tw-col-span-6 md:tw-col-span-3 md:tw-m-0">
              <span className="order-item-title tw-text-base tw-font-bold tw-text-gray-900">
                { menuItem.get('name') }
              </span>

              {
                dietaries &&
                  <div className="badges !tw-mx-0 !tw-px-0">
                    <DietaryBadgeContainer
                      type="caterer"
                      dietaries={dietaries}
                    />
                  </div>
              }
            </div>

            <div className="tw-hidden tw-col-span-3 tw-m-0 md:tw-flex tw-justify-end tw-gap-2">
              <MenuItemModifier
                mealConfirmed={mealConfirmed}
                cartVariation={cartVariation}
                mealAdmin={mealAdmin}
                mealEditable={mealEditable}
                onUpdate={(e, quantity) => updateQuantityHandler(e, quantity)}
                onEditSelection={(e) => onEditSelection(e, menuItem, cartVariation)}
                translate={translate}
              />
            </div>
          </div>

          <div className={classNames('tw-mb-4', {
            '!tw-mt-1': !blankish(menuItem.get('description')),
          })}
          >
            { renderHTML(menuItem.get('description')) }
          </div>

          <MenuItemIncludedItems includedItems={menuItem.get('included_items')} />
          {
            hasSelections && (
              <MenuItemSelection
                cartVariation={cartVariation}
              />
            )
          }
          <MenuItemMetas
            quantity={menuItem.get('quantity')}
            quantityType={menuItem.get('quantity_type')}
            minServings={menuItem.get('min_servings')}
            maxServings={menuItem.get('max_servings')}
            onAddAnother={onAddAnotherSelection}
            mealEditable={mealEditable}
          />

          {/* Additional Instructions */}
          {
            cartVariation.get('additional_instructions') &&
            <div className={classNames('tw-w-full tw-flex tw-flex-row tw-gap-1', {
              'tw-px-2 tw-py-2 tw-my-2 tw-bg-blueberry-50 tw-rounded': true,
            })}
            >
              <span className="tw-text-gray-900">
                <span className="tw-inline-block tw-font-bold tw-mr-2">
                  {translate('menuItemsSpecialInstructions')}
                </span>
                {cartVariation.get('additional_instructions')}
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  cartVariation: PropTypes.object.isRequired,
  menuItem: PropTypes.object.isRequired,
  mealPlanId: PropTypes.string.isRequired,
  onEditSelection: PropTypes.func.isRequired,
  onAddAnotherSelection: PropTypes.func.isRequired,
  mealAdmin: PropTypes.bool.isRequired,
  mealConfirmed: PropTypes.bool.isRequired,
  mealEditable: PropTypes.bool.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  translate: PropTypes.func,
};

export default withI18n(MenuItem);
