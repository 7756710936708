/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { object, func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { ChevronLeftIcon } from 'libs/components/Icons';
import NotificationGroup from './NotificationGroup';
import NotificationDetails from './NotificationDetails';
import {
  AccountSettings,
  NotificationsSection,
  HorizontalRule,
  Header,
  HeaderTitle,
  BackIcon,
} from '../../styles/mobile';

const NotificationSettings = ({ settings, updateSetting, settingsStructure, role, translate }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleNotificationSelect = (notification) => {
    setScrollPosition(window.scrollY); // Save the current scroll position
    setSelectedNotification(notification);
  };

  useEffect(() => {
    if (selectedNotification === null) {
      window.scrollTo(0, scrollPosition);
    }
  }, [selectedNotification, scrollPosition]);

  const handleBackClick = () => {
    setSelectedNotification(null);
  };

  const goBack = () => {
    // back to account page
    window.history.back();
  };

  return (
    <AccountSettings>
      {selectedNotification ? (
        <NotificationDetails
          goBack={handleBackClick}
          groupName={selectedNotification.groupName}
          settingName={selectedNotification.settingName}
          updateSetting={updateSetting}
          currentSettings={settings}
        />
      ) : (
        <>
          <Header>
            <BackIcon onClick={goBack}><ChevronLeftIcon /></BackIcon>
            <HeaderTitle>{ translate('accountSettingsHeaderTitle') }</HeaderTitle>
          </Header>
          <NotificationsSection>
            <h5 className="description" style={{ color: 'black', fontSize: '14px', fontWeight: '300' }}>{ translate('accountSettingsNotificationSettingsHeaderTitle') }</h5>
            <HorizontalRule />
            {settingsStructure.map(group => (
              <NotificationGroup
                key={group.section}
                section={group.section}
                groups={group.groups}
                role={role}
                onNotificationSelect={handleNotificationSelect}
                currentSettings={settings}
              />
            ))}
          </NotificationsSection>
        </>
      )}
    </AccountSettings>
  );
};

NotificationSettings.propTypes = {
  settings: object.isRequired,
  updateSetting: func.isRequired,
  settingsStructure: object.isRequired,
  role: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(NotificationSettings);
