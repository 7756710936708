const disabledOptions = ['order_availability_email'];

const hiddenChannelsByGroup = {
  order_reminders: ['sms'],
  order_alerts: ['sms'],
  order_reminders_for_admin: ['sms'],
};

const sectionsByRole = {
  admin: {
    admin_notifications: {
      order_reminders_for_admin: ['meal_schedule', 'meal_approval'],
      delivery_notifications: ['order_arrived', 'order_arriving', 'order_on_the_way'],
    },
  },
  user: {
    user_notifications: {
      order_reminders: ['pick_your_meal', 'upcoming_meals'],
      order_alerts: ['order_availability'],
    },
  },
  all: {
    admin_notifications: {
      order_reminders_for_admin: ['meal_schedule', 'meal_approval'],
      delivery_notifications: ['order_arrived', 'order_arriving', 'order_on_the_way'],
    },
    user_notifications: {
      order_reminders: ['pick_your_meal', 'upcoming_meals'],
      order_alerts: ['order_availability', 'order_confirmation'],
    },
  },
};

export { disabledOptions, hiddenChannelsByGroup, sectionsByRole };
