/* eslint max-len: 0 */

import fflagsReducer, * as fromFFlags from 'packs/common/reducers/fflagsReducer';
import mealPlanUsersReducer, * as fromMealPlanUsers from './mealPlanUsersReducer';

export default {
  $$mealPlanUsersStore: mealPlanUsersReducer,
  $$fflagsStore: fflagsReducer,
};

export const initialStates = {
  $$mealPlanUsersState: fromMealPlanUsers.$$initialState,
  $$fflagsState: fromFFlags.$$initialState,
};
