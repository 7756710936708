import React from 'react';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';
import PhotoUploadComponent from 'libs/components/PhotoUploadComponent';

const Container = styled.div`

`;

const FileUpload = ({
  onDelete,
  onDrop,
  onDropFailure,
  option,
  optionName,
  optionId,
  parentId,
  parentType,
}) => {
  const errors = option.get('errors');
  const photo = option.getIn(['photos', 0]);
  const photoUrl = photo && (photo.get('id') ? photo.get('thumb') : photo.get('hash'));
  const url = photo && (photo.get('id') ? photo.get('url') : photo.get('thumb'));

  return (
    <Container>
      <PhotoUploadComponent
        details={{
          parentId,
          parentType,
          optionName,
          optionId,
        }}
        photo={photoUrl}
        url={url}
        onDrop={onDrop}
        onDropFailure={onDropFailure}
        onDelete={onDelete}
        errors={errors}
        isTextPreview
      />
    </Container>
  );
};

FileUpload.propTypes = {
  onDelete: func.isRequired,
  onDrop: func.isRequired,
  onDropFailure: func.isRequired,
  option: object,
  optionId: string,
  optionName: string,
  parentId: string,
  parentType: string,
};

export default FileUpload;
