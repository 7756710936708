import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Button } from 'react-bootstrap';
import { PhotosFeedbackModal } from './photosFeedbackModal';

export default function PhotosFeedback({
  pantryProgram, dailyLog, logDate, saveDailyLog,
}) {
  const [showDetail, setShowDetail] = useState(false);
  const submitted = _get(dailyLog, 'status.status') === 'submitted';

  return (
    <div>
      <div className="px-3">
        <Button
          variant={submitted ? 'outline-dark' : 'primary'}
          onClick={() => {
            setShowDetail(true);
          }}
          block
          disabled={submitted}
        >
          Start Entry
        </Button>
      </div>

      <PhotosFeedbackModal
        pantryProgram={pantryProgram}
        dailyLog={dailyLog}
        show={showDetail}
        onHide={() => { setShowDetail(false); }}
        saveDailyLog={saveDailyLog}
        logDate={logDate}
      />
    </div>
  );
}

PhotosFeedback.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  saveDailyLog: PropTypes.func.isRequired,
  logDate: PropTypes.string.isRequired,
};
