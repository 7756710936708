import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/menuItemConstants';

const API_URL = '/menu_items';

export function hideMenuItemForm() {
  return { type: actionTypes.HIDE_MENU_ITEM_FORM };
}

export function fetchMenuItem(id) {
  const { FETCHING_MENU_ITEM, FETCH_MENU_ITEM_SUCCESS, FETCH_MENU_ITEM_FAILURE } = actionTypes;

  return api.get({
    types: [FETCHING_MENU_ITEM, FETCH_MENU_ITEM_SUCCESS, FETCH_MENU_ITEM_FAILURE],
    endpoint: API_URL,
    id,
  });
}
