/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import { ViewOrderStep } from './viewOrderStep';
import { ConfirmReportStep } from './confirmReportStep';
import { SubmittedSuccessfullyStep } from './submittedSuccessfullyStep';
import { SAVE_DAILY_LOG_FAILURE } from '../../../constants/pantryProgramConstants';
import { ReportMissingDeliveryStep } from './reportMissingDeliveryStep';

const VIEW_ORDER_STEP = 0;
const CONFIRM_REPORT_STEP = 1;
const SUBMITTED_SUCCESSFULLY_STEP = 2;
const REPORT_MISSING_DELIVERY_STEP = 3;

const sortOrderVariations = (ov) => {
  // eslint-disable-next-line no-unused-expressions
  [ov.menuItem.categoryName, ov.menuItem.name];
};

export function DeliveryReportModal({
  pantryProgram,
  dailyLog,
  onHide,
  show,
  order,
  saveDailyLog,
  logDate,
}) {
  if (!order) {
    return null;
  }

  const [error, setError] = useState('');
  const [step, setStep] = useState(VIEW_ORDER_STEP);
  const [deliveryReports, setDeliveryReports] = useState([]);
  const [deliveryPhotos, setDeliveryPhotos] = useState([]);

  useEffect(() => {
    const { orderVariations } = order;
    const categoryNames = new Set();
    setDeliveryReports(_sortBy(orderVariations, sortOrderVariations).map(variation => ({
      name: variation.menuItem.name,
      categoryName: variation.menuItem.categoryName,
      showCategory: !categoryNames.has(variation.menuItem.categoryName) && categoryNames.add(variation.menuItem.categoryName) && true,
      variationName: variation.name,
      photo: variation?.menuItem?.photos?.[0]?.thumb ?? '',
      orderVariationId: variation.uuid,
      orderVariationQuantity: variation?.quantity,
      deliveredQuantity: variation?.quantity,
      description: '',
      checked: false,
    })));
    setDeliveryPhotos(_filter(dailyLog.deliveryPhotos, deliveryPhoto => (deliveryPhoto.orderId === order.uuid)));
  }, [order, dailyLog]);

  useEffect(() => {
    setError('');
  }, [step]);

  function handleOnHide() {
    setStep(VIEW_ORDER_STEP);
    onHide();
  }

  function handleSubmit() {
    if (step === VIEW_ORDER_STEP) {
      setStep(CONFIRM_REPORT_STEP);
    }
    if (step === CONFIRM_REPORT_STEP) {
      if (deliveryPhotos.length === 0) {
        setError('Please attach photos of delivery');
        return;
      }

      saveDailyLog({
        ...(dailyLog?.uuid && { uuid: dailyLog.uuid }),
        status: {
          ...(dailyLog?.status),
          status: 'ongoing',
          deliveryReport: {
            data: [
              ...(dailyLog?.status?.deliveryReport?.data || []),
              {
                orderId: order.uuid,
                status: 'submitted',
              },
            ],
          },
        },
        deliveryReportsAttributes: deliveryReports.map(dr => ({
          ...dr,
          quantity: dr.checked ? dr.deliveredQuantity : 0,
          // eslint-disable-next-line no-nested-ternary
          status: dr.checked
            ? (dr.deliveredQuantity >= dr.orderVariationQuantity ? 'submitted' : 'missing_delivery')
            : 'missing_delivery',
        })),
        deliveryPhotos: [...(dailyLog?.deliveryPhotos ?? []), ...deliveryPhotos],
        logDate,
      }, pantryProgram.uuid)
        .then((data) => {
          if (data.type === SAVE_DAILY_LOG_FAILURE) {
            setError(data.response.errors.join(', '));
          } else {
            setError('');
            setStep(SUBMITTED_SUCCESSFULLY_STEP);
          }
        });
    }
  }

  function handleReportMissingDelivery() {
    if (step === VIEW_ORDER_STEP) {
      setStep(REPORT_MISSING_DELIVERY_STEP);
    }
  }

  function handleReportMissingDeliverySubmit() {
    saveDailyLog({
      ...(dailyLog?.uuid && { uuid: dailyLog.uuid }),
      status: {
        ...(dailyLog?.status),
        status: 'ongoing',
        deliveryReport: {
          data: [
            ...(dailyLog?.status?.deliveryReport?.data ?? []),
            {
              orderId: order.uuid,
              status: 'missing_delivery',
            },
          ],
        },
      },
      deliveryReportsAttributes: deliveryReports.map(dr => ({
        ...dr,
        quantity: 0,
        status: 'missing_delivery',
      })),
      logDate,
    }, pantryProgram.uuid)
      .then((data) => {
        if (data.type === SAVE_DAILY_LOG_FAILURE) {
          alert(data.response.errors.join(', '));
        } else {
          setStep(SUBMITTED_SUCCESSFULLY_STEP);
        }
      });
  }

  function handleBack() {
    switch (step) {
      case VIEW_ORDER_STEP:
        handleOnHide();
        break;
      case CONFIRM_REPORT_STEP:
        setStep(VIEW_ORDER_STEP);
        break;
      case SUBMITTED_SUCCESSFULLY_STEP:
        setStep(CONFIRM_REPORT_STEP);
        break;
      case REPORT_MISSING_DELIVERY_STEP:
        setStep(VIEW_ORDER_STEP);
        break;
      default:
        break;
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleOnHide}
      className="fullscreen-modal"
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex">
            <div>
              <Button variant="link" size="sm" onClick={handleBack} style={{ padding: 0 }}>
                <FontAwesomeIcon icon={faArrowLeft} color="#7D9E1D" fontSize={20} />
              </Button>
            </div>
            <div className="flex-grow-1 ml-4">
              {step === VIEW_ORDER_STEP && 'View Order'}
              {step === CONFIRM_REPORT_STEP && 'Confirm Report'}
              {step === SUBMITTED_SUCCESSFULLY_STEP && 'Submitted Successfully'}
              {step === REPORT_MISSING_DELIVERY_STEP && 'Missing Delivery'}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'auto', paddingTop: 0 }}>
        {
          error && (
            <div className="alert-danger px-2 py-2 mb-2">
              {error}
            </div>
          )
        }

        {step === VIEW_ORDER_STEP && <ViewOrderStep
          deliveryReports={deliveryReports}
          setDeliveryReports={setDeliveryReports}
          order={order}
        />}
        {step === CONFIRM_REPORT_STEP && <ConfirmReportStep
          deliveryReports={deliveryReports}
          setDeliveryReports={setDeliveryReports}
          deliveryPhotos={deliveryPhotos}
          setDeliveryPhotos={setDeliveryPhotos}
          order={order}
        />}
        {step === SUBMITTED_SUCCESSFULLY_STEP && <SubmittedSuccessfullyStep
          vendorName={order.vendorName}
          logDate={logDate}
          hasMissingItem={dailyLog.deliveryReports.some(dr => dr.status === 'missing_delivery')}
        />}
        {step === REPORT_MISSING_DELIVERY_STEP && <ReportMissingDeliveryStep
          order={order}
        />}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex-column">
          {step === VIEW_ORDER_STEP && (
            <>
              <div>
                <Button
                  variant="outline-danger"
                  onClick={handleReportMissingDelivery}
                  block
                >
                  Report Missing Delivery
                </Button>
              </div>
              <div className="mt-2">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  block
                >
                  Submit
                </Button>
              </div>
            </>
          )}
          {step === CONFIRM_REPORT_STEP && (
            <div>
              <Button variant="warning" onClick={handleSubmit} block>
                Confirm and Submit
              </Button>
            </div>
          )}
          {step === SUBMITTED_SUCCESSFULLY_STEP && (
            <div>
              <Button variant="primary" onClick={handleOnHide} block>
                Back to Daily Log
              </Button>
            </div>
          )}
          {step === REPORT_MISSING_DELIVERY_STEP && (
            <div>
              <Button variant="warning" onClick={handleReportMissingDeliverySubmit} block>
                Confirm and Submit
              </Button>
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

DeliveryReportModal.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  saveDailyLog: PropTypes.func,
  logDate: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
};
