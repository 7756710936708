/* eslint max-len: 0 */

import React from 'react';

const Person = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0.333496C4.162 0.333496 2.66667 1.82883 2.66667 3.66683C2.66667 5.50483 4.162 7.00016 6 7.00016C7.838 7.00016 9.33333 5.50483 9.33333 3.66683C9.33333 1.82883 7.838 0.333496 6 0.333496ZM6 5.66683C4.89733 5.66683 4 4.7695 4 3.66683C4 2.56416 4.89733 1.66683 6 1.66683C7.10267 1.66683 8 2.56416 8 3.66683C8 4.7695 7.10267 5.66683 6 5.66683ZM12 13.0002V12.3335C12 9.76083 9.906 7.66683 7.33333 7.66683H4.66667C2.09333 7.66683 0 9.76083 0 12.3335V13.0002H1.33333V12.3335C1.33333 10.4955 2.82867 9.00016 4.66667 9.00016H7.33333C9.17133 9.00016 10.6667 10.4955 10.6667 12.3335V13.0002H12Z" fill="#7D9E1D" />
  </svg>
);

export default Person;
