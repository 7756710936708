/* eslint-disable max-len */
import React from 'react';
import { any, bool, func, object, string } from 'prop-types';

import { prevent } from 'libs/support/prevent';
import { DeleteXIcon } from 'libs/components/Icons';

import VariationItem from '../VariationItem';

const CartItem = ({
  itemName,
  quantity,
  addOnDetails,
  additionalInstructions,
  isGuest,
}) => (
  <div className="tw-w-full tw-flex tw-flex-col tw-text-left">
    <VariationItem
      name={itemName}
      quantity={quantity}
      isGuest={isGuest}
    />
    {addOnDetails && (
    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-text-xs tw-text-gray-600">
      {addOnDetails}
    </div>
      )}
    {additionalInstructions && (
    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-text-xs tw-text-gray-600">
      {additionalInstructions}
    </div>
      )}
  </div>
);

CartItem.propTypes = {
  itemName: string,
  quantity: any,
  addOnDetails: any,
  additionalInstructions: any,
  isGuest: bool,
};

const UserMealCartItem = (props) => {
  const {
    onDelete,
    onEdit,
    isEditable,
    mealId,
    mealPlanId,
    orderableVariation,
    showPrice,
  } = props;

  const itemName = orderableVariation.get('name');
  const addOnDetails = orderableVariation.get('addOnDetails') || orderableVariation.get('add_on_details');
  const additionalInstructions = orderableVariation.get('additionalInstructions') || orderableVariation.get('additional_instructions');
  const isGuestMeal = orderableVariation.get('orderUserIsGuest') || orderableVariation.get('cartUserIsGuest');

  return (
    <div className="order-item">
      <div className="tw-grid tw-grid-cols-12 tw-w-full">
        {
          isEditable ?
            <button
              className="btn-link order-edit tw-col-span-8 md:tw-col-span-10 !tw-no-underline"
              onClick={_ => onEdit(mealPlanId, orderableVariation.get('uuid'))}
            >
              <CartItem
                itemName={itemName}
                quantity={orderableVariation.getIn(['quantity'])}
                addOnDetails={addOnDetails}
                additionalInstructions={additionalInstructions}
                isGuest={isGuestMeal}
              />
            </button> :
            <div className="tw-w-full tw-col-span-8 md:tw-col-span-10">
              <CartItem
                itemName={itemName}
                quantity={orderableVariation.getIn(['quantity'])}
                addOnDetails={addOnDetails}
                additionalInstructions={additionalInstructions}
                isGuest={isGuestMeal}
              />
            </div>
        }

        <div className="order-actions tw-col-span-4 md:tw-col-span-2 tw-flex tw-justify-end tw-items-center">
          {
            showPrice &&
            <strong className="text-nowrap">{orderableVariation.get('subtotal')}</strong>
          }

          {
            isEditable &&
            <button
              className="order-delete btn !tw-flex !tw-items-center !tw-justify-center"
              onClick={(_) => prevent(onDelete(mealPlanId, orderableVariation.get('uuid'), { meal_id: mealId }))}
            >
              <DeleteXIcon />
            </button>
          }
        </div>
      </div>
    </div>
  );
};

UserMealCartItem.propTypes = {
  onDelete: func.isRequired,
  onEdit: func.isRequired,
  isEditable: bool.isRequired,
  mealId: string.isRequired,
  mealPlanId: string.isRequired,
  showPrice: bool.isRequired,
  orderableVariation: object.isRequired,
};

export default UserMealCartItem;
