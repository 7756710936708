import React from 'react';
import { func, number, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { formattedPrice } from 'libs/support/formattedPrice';

import Tooltip from './Tooltip';

/**
 * SubsidyCard
 */

const SubsidyCard = ({
  subsidyAmount,
  totalPaidByCompany,
  totalPaidByUsers,
  translate,
}) => (
  <div className="subsidy">
    <div className="subsidy__row">
      <div className="row">
        <div className="col-4 subsidy__col">
          <div className="subsidy__amount">
            <strong>{formattedPrice(subsidyAmount)}</strong>
          </div>

          <div>
            {translate('subsidyCardTermsSubsidyPerPersonText')}
          </div>

          <div className="subsidy__subtext">
            {translate('subsidyCardTermsSubsidyPerPersonSubtext')}
          </div>
        </div>

        <div className="col-4 subsidy__col subsidy__col--border">
          <div className="subsidy__amount">
            <strong>{formattedPrice(totalPaidByUsers)}</strong>
          </div>

          <div>
            {translate('subsidyCardTermsTotalAmountPaidByUsersText')}
            <Tooltip
              title={translate('subsidyCardTermsTotalAmountPaidByUsersSubtext')}
            />
          </div>
        </div>

        <div className="col-4 subsidy__col subsidy__col--border">
          <div className="subsidy__amount">
            <strong>{formattedPrice(totalPaidByCompany)}</strong>
          </div>

          <div>
            {translate('subsidyCardTermsTotalAmountPaidByCompanyText')}
            <Tooltip
              title={translate('subsidyCardTermsTotalAmountPaidByCompanySubtext')}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

SubsidyCard.propTypes = {
  subsidyAmount: string,
  totalPaidByCompany: number,
  totalPaidByUsers: number,
  translate: func.isRequired,
};

export default withI18n(SubsidyCard);
