import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

export default function FiltersStep({ filters, filterCategories, setFilterCategories }) {
  return (
    <div>
      {
        _map(filters, (f) => (
          <div className="form-check h5" key={f}>
            <input
              className="form-check-input"
              type="checkbox"
              value={f}
              id={f}
              checked={filterCategories.indexOf(f) > -1}
              onChange={event => {
               if (event.target.checked) {
                 setFilterCategories([...filterCategories, f]);
               } else {
                 setFilterCategories(_filter(filterCategories, filter => filter !== f));
               }
             }}
            />
            <label className="form-check-label" htmlFor={f}>
              {f}
            </label>
          </div>
          ))
      }
    </div>
  );
}
FiltersStep.propTypes = {
  filters: PropTypes.array.isRequired,
  filterCategories: PropTypes.array.isRequired,
  setFilterCategories: PropTypes.func.isRequired,
};
