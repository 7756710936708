/* eslint max-len: 0 */

import React from 'react';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';

import BaseComponent from 'libs/components/BaseComponent';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

/* Components */
import MealPlanUsers from 'packs/meal_plan_users/components/MealPlanUsers/MealPlanUsers';

global.Intl = Intl;

/**
 * MealPlanUsersContainer
 */
class MealPlanUsersContainer extends BaseComponent {
  static propTypes = {};

  render() {
    const locale = defaultLocale;
    const messages = translations[locale];

    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <MealPlanUsers />
      </IntlProvider>
    );
  }
}

export default MealPlanUsersContainer;
