import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Immutable from 'immutable';
import uppercamelcase from 'uppercamelcase';
import { func, object } from 'prop-types';

import { withI18n } from 'libs/support/i18n';

import GroupOrderMealCardHeadlineContainer from 'packs/meals/containers/GroupOrderMealCardHeadlineContainer';
import GroupOrderActionsDropdown from 'packs/common/components/GroupOrderActionsDropdown';
import { ThreeDotsIcon } from 'libs/components/Icons';

const MealStatus = ({ meal, translate }) => {
  const [format, timeLeft] = meal.get('timeLeft').toJS();

  if (meal.get('isConfirmed')) {
    return (
      <span className="tw-badge-success">
        {translate('mealPlanUserMealPlansCardHeaderDeadlineConfirmed')}
      </span>
    );
  } else if (meal.get('isOverdue')) {
    return (
      <span className="tw-badge-error">
        {translate('mealPlanUserMealPlansCardHeaderDeadlineOverdue')}
      </span>
    );
  } else if (meal.get('isInPreview')) {
    return (
      <span className="tw-badge-error">
        {translate('mealPlanUserMealPlansCardHeaderDeadlineInPreview')}
      </span>
    );
  } else if (timeLeft >= 0) {
    return (
      <span className="tw-badge-error">
        {
          translate(
            `mealPlanUserMealPlansCardHeaderDeadline${uppercamelcase(format)}`,
            { count: timeLeft },
          )
        }
      </span>
    );
  } else if (meal.get('isProcessing')) {
    return (
      <span className="tw-badge-success">
        {translate('mealPlanUserMealPlansCardHeaderDeadlineProcessing')}
      </span>
    );
  } else if (meal.get('isPastMealPlan')) {
    return (
      <span className="tw-badge-success">
        {translate('mealPlanUserMealPlansCardHeaderDeadlineProcessing')}
      </span>
    );
  }

  return null;
};

MealStatus.propTypes = {
  meal: object.isRequired,
  translate: func.isRequired,
};

const CardHeader = ({
  meal,
  translate,
  userRoleIn,
  onClickDownloadExcel,
  onClickDownloadMealLabelPdf,
  onClickDownloadMenuCardPdf,
}) => {
  const orders = meal.get('isConfirmed') ? meal.get('orderables') : Immutable.fromJS([]);

  return (
    <div className={classNames('card-header', {
      'tw-flex tw-flex-col tw-gap-2': true,
      '!tw-rounded-tl-lg !tw-rounded-tr-lg ': true,
    })}
    >
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-justify-start tw-gap-4">
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-base tw-text-gray-900 tw-font-bold">
              {moment(meal.get('scheduleAt')).format('dddd, DD MMM')}
            </span>
            <span className="tw-text base tw-text-gray-900">
              {moment(meal.get('scheduleAt')).format('h:mm A')}
            </span>
          </div>
          <div className="tw-mt-1">
            <MealStatus meal={meal} translate={translate} />
          </div>
        </div>
        <div>
          {
            userRoleIn(['osc', 'admin']) &&
            <GroupOrderActionsDropdown
              btnClassName={classNames('caret-off !tw-text-gray-900', {
                '!tw-border-0 !tw-shadow-none !tw-p-0 !tw-bg-transparent': true,
                'tw-transform tw-origin-center tw-rotate-[90deg]': true,
              })}
              className="tw-relative"
              mealPlanId={meal.get('mealPlanId')}
              mealId={meal.get('uuid')}
              onClickDownloadExcel={onClickDownloadExcel}
              onClickDownloadMealLabelPdf={onClickDownloadMealLabelPdf}
              onClickDownloadMenuCardPdf={onClickDownloadMenuCardPdf}
              orders={orders}
            >
              <ThreeDotsIcon />
            </GroupOrderActionsDropdown>
          }
        </div>
      </div>
      {
        userRoleIn(['admin', 'osc']) &&
        <GroupOrderMealCardHeadlineContainer />
      }
    </div>
  );
};

CardHeader.propTypes = {
  meal: object.isRequired,
  translate: func.isRequired,
  userRoleIn: func.isRequired,
  onClickDownloadExcel: func.isRequired,
  onClickDownloadMealLabelPdf: func.isRequired,
  onClickDownloadMenuCardPdf: func.isRequired,
};

export default withI18n(CardHeader);
