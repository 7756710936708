/**
 * Small shim for preventDefault
 *
 * Ex.
 *   prevent(function () { console.log('ok') })
 */
export function prevent(fn /* : any => any */) {
  return (e /* : Event */) => {
    e.preventDefault();
    fn(e);
  };
}
