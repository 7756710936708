/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';

const CircleCheck = ({ color = '#515154', size = 31 }) => (
  <svg width={size} height={size} viewBox="0 0 31 31" fill="none" preserveAspectRatio xmlns="http://www.w3.org/2000/svg">
    <path d="M21.161 10.6865H19.7412C19.4324 10.6865 19.1388 10.8349 18.9571 11.0892L14.1981 17.6888L12.0427 14.6978C11.861 14.4465 11.5704 14.2951 11.2586 14.2951H9.83876C9.64198 14.2951 9.52695 14.5191 9.64198 14.6796L13.4141 19.9108C13.5032 20.0352 13.6206 20.1366 13.7567 20.2065C13.8928 20.2764 14.0436 20.3128 14.1966 20.3128C14.3496 20.3128 14.5004 20.2764 14.6365 20.2065C14.7726 20.1366 14.8901 20.0352 14.9792 19.9108L21.3548 11.071C21.4728 10.9105 21.3578 10.6865 21.161 10.6865Z" fill={color} />
    <path d="M15.5 1.9375C8.01035 1.9375 1.9375 8.01035 1.9375 15.5C1.9375 22.9896 8.01035 29.0625 15.5 29.0625C22.9896 29.0625 29.0625 22.9896 29.0625 15.5C29.0625 8.01035 22.9896 1.9375 15.5 1.9375ZM15.5 26.7617C9.28184 26.7617 4.23828 21.7182 4.23828 15.5C4.23828 9.28184 9.28184 4.23828 15.5 4.23828C21.7182 4.23828 26.7617 9.28184 26.7617 15.5C26.7617 21.7182 21.7182 26.7617 15.5 26.7617Z" fill={color} />
  </svg>
);

CircleCheck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default CircleCheck;
