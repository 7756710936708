import * as api from 'libs/middlewares/api';

import {
  FETCH_MEAL_REPORT_FAILURE,
  FETCH_MEAL_REPORT_SUCCESS,
  FETCHING_MEAL_REPORT,
  PROCESS_MEAL_REPORT_FAILURE,
  PROCESS_MEAL_REPORT_SUCCESS,
  PROCESSING_MEAL_REPORT,
} from '../constants/mealReportConstants';

const API_URL = '/meal_reports';

export const fetchMealReport = (id) => api.get({
  types: [FETCHING_MEAL_REPORT, FETCH_MEAL_REPORT_SUCCESS, FETCH_MEAL_REPORT_FAILURE],
  endpoint: API_URL,
  id,
});

export const processMealReport = (params) => api.create({
  types: [PROCESSING_MEAL_REPORT, PROCESS_MEAL_REPORT_SUCCESS, PROCESS_MEAL_REPORT_FAILURE],
  endpoint: API_URL,
  params,
});
