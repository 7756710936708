import React from 'react';
import { object } from 'prop-types';
import { notBlank } from 'libs/support/string';
import { LocationIcon } from 'libs/components/Icons';

const display = (address) => (
  [address.get('streetAddress'), address.get('unitAddress')].filter((e) => notBlank(e)).join(', ')
);

const Address = ({
  address,
}) => {
  if (address.size === 0) return null;

  return (
    <div className="cart-order-form">
      <div className="d-flex align-items-center">
        <span>
          <LocationIcon />
        </span>

        <span className="ml-2">
          {display(address)}
        </span>
      </div>
    </div>
  );
};

Address.propTypes = {
  address: object,
};

export default Address;
