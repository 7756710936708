import mealReducer, * as fromMeal from 'packs/meals/reducers/mealReducer';
import mealCardReducers, * as fromMealCard from './mealCardReducer';

export default {
  $$mealCardStore: mealCardReducers,
  $$mealStore: mealReducer,
};

export const initialStates = {
  $$mealCardState: fromMealCard.$$initialState,
  $$mealState: fromMeal.$$initialState,
};
