/* eslint max-len: 0 */

import React from 'react';

const TwoPeople = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.1875 5.0625C6.1875 3.5092 7.4467 2.25 9 2.25C10.5533 2.25 11.8125 3.5092 11.8125 5.0625C11.8125 6.6158 10.5533 7.875 9 7.875C7.4467 7.875 6.1875 6.6158 6.1875 5.0625Z" fill="#4B5563" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.8125 7.3125C11.8125 6.06986 12.8199 5.0625 14.0625 5.0625C15.3051 5.0625 16.3125 6.06986 16.3125 7.3125C16.3125 8.55514 15.3051 9.5625 14.0625 9.5625C12.8199 9.5625 11.8125 8.55514 11.8125 7.3125Z" fill="#4B5563" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.6875 7.3125C1.6875 6.06986 2.69486 5.0625 3.9375 5.0625C5.18014 5.0625 6.1875 6.06986 6.1875 7.3125C6.1875 8.55514 5.18014 9.5625 3.9375 9.5625C2.69486 9.5625 1.6875 8.55514 1.6875 7.3125Z" fill="#4B5563" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.7324 11.3381C5.63087 9.93331 7.20626 9 9 9C10.794 9 12.3695 9.93354 13.2679 11.3387C13.8891 12.3101 14.1578 13.4814 14.0317 14.6234C14.012 14.8019 13.9083 14.9603 13.7526 15.0496C12.3521 15.8532 10.7288 16.3125 9 16.3125C7.27119 16.3125 5.64794 15.8532 4.24743 15.0496C4.09169 14.9603 3.98797 14.8019 3.96827 14.6234C3.8422 13.4812 4.11106 12.3096 4.7324 11.3381Z" fill="#4B5563" />
    <path d="M3.81171 10.6903C3.80268 10.7041 3.7937 10.7181 3.78478 10.732C3.06069 11.8642 2.72931 13.2125 2.83104 14.5418C2.375 14.4725 1.93252 14.3611 1.50782 14.2115L1.4216 14.1811C1.26432 14.1257 1.15442 13.9829 1.14119 13.8167L1.13394 13.7256C1.12801 13.651 1.125 13.5758 1.125 13.5C1.125 11.9889 2.31678 10.7561 3.81171 10.6903Z" fill="#4B5563" />
    <path d="M15.1692 14.5417C15.2709 13.2127 14.9397 11.8647 14.2159 10.7326C14.2068 10.7185 14.1977 10.7044 14.1886 10.6903C15.6834 10.7562 16.875 11.9889 16.875 13.5C16.875 13.5758 16.872 13.6511 16.8661 13.7256L16.8588 13.8167C16.8456 13.9829 16.7357 14.1257 16.5784 14.1811L16.4922 14.2115C16.0676 14.361 15.6252 14.4724 15.1692 14.5417Z" fill="#4B5563" />
  </svg>
);

export default TwoPeople;
