import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import DropZone from 'react-dropzone';
import { withI18n } from 'libs/support/i18n';
import { uploadImage } from 'libs/support/fileUploadUtils';

const fileUploaded = (startDate, setUploading) => () => {
  const timeDiff = new Date() - startDate;
  if (timeDiff < 1000) {
    setTimeout(() => { setUploading(false); }, 1000 - timeDiff);
  } else {
    setUploading(false);
  }
};

const UploadButton = ({
  setPhotos,
  translate,
}) => {
  const [uploading, setUploading] = useState(false);

  const onDrop = (files) => {
    const details = {};
    setUploading(true);
    const startDate = new Date();
    Promise.all(files.map((file, index) => uploadImage(file, { ...details, index })))
      .then((photos) => { setPhotos(photos); })
      .then(fileUploaded(startDate, setUploading))
      .catch(fileUploaded(startDate, setUploading));
  };

  return (
    <DropZone
      onDrop={onDrop}
      multiple
      className="w-100 flex-grow-1"
    >
      <Button disabled={uploading} variant="outline-dark" className="w-100">
        {uploading ? translate('buttonsUploading') : translate('buttonsTakePhotosAttachPhotos')}
      </Button>
    </DropZone>
  );
};

UploadButton.propTypes = {
  setPhotos: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withI18n(UploadButton);
