/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import Immutable from 'immutable';
/* Actions */
import actions from 'packs/meals/actions';

import { withAppContext } from 'libs/support/appContext';
import { ThreeDotsIcon } from 'libs/components/Icons';
import PlannedMealActionsDropdown from 'packs/common/components/PlannedMealActionsDropdown';
import ScheduleModal from './ScheduleModal/ScheduleModal';

/**
 * CartHeader
 */

const CartHeader = withAppContext(({
  mealStore,
  actions,
  handleOnClickDownloadPdf,
  userRoleIn,
}) => {
  const scheduleAt = mealStore.get('scheduleAt');
  const isConfirmed = mealStore.get('confirmed');
  const orders = isConfirmed ? mealStore.get('orderables') : Immutable.fromJS([]);
  const day = moment(scheduleAt).format('dddd');
  const datetime = moment(scheduleAt).format('D MMM h:mmA');

  return (
    <div className={classNames('card-header', {
      'tw-flex tw-justify-between tw-py-4': true,
      '!tw-rounded-tl-xl !tw-rounded-tr-xl': true,
    })}
    >
      <div className="tw-flex tw-items-center tw-py-4">
        <h6 className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 tw-uppercase tw-text-md tw-m-0">
          <span className="tw-text-gray-900">{day},</span>
          <span className="tw-text-gray-400">{datetime}</span>
        </h6>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2">
        {
          mealStore.get('scheduleAtIsEditable') &&
          !mealStore.get('confirmed') &&
            <a
              href=""
              data-toggle="modal"
              data-target="#editeDateTime"
              onClick={actions.showScheduleModal}
              className={classNames('tw-font-display tw-uppercase', {
                'tw-text-xs tw-font-bold !tw-leading-3': true,
                'tw-rounded tw-border tw-border-broccoli-600 tw-px-4 tw-py-2': true,
                'hover:!tw-no-underline': true,
              })}
            >
              Edit Date
            </a>
        }
        <ScheduleModal
          mealPlanId={mealStore.get('mealPlanId')}
          mealId={mealStore.get('uuid')}
          show={mealStore.get('showScheduleModal')}
          scheduleAt={mealStore.get('scheduleAt')}
          onHide={actions.hideScheduleModal}
          onSave={actions.saveMeal}
        />

        {/* Dropdown */}
        {
          userRoleIn(['osc', 'admin']) &&
            <PlannedMealActionsDropdown
              btnClassName="btn-light btn-sm caret-off px-2"
              className="m-0 py-1"
              mealPlanId={mealStore.get('mealPlanId')}
              mealId={mealStore.get('uuid')}
              orders={orders}
              onClickMenuCardDownload={handleOnClickDownloadPdf}
            >
              <ThreeDotsIcon />
            </PlannedMealActionsDropdown>
        }
      </div>
    </div>
  );
});

CartHeader.propTypes = {
  mealStore: object.isRequired,
  actions: object.isRequired,
  handleOnClickDownloadPdf: func.isRequired,
  userRoleIn: func,
  flagIsEnabled: func,
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartHeader);
