/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React from 'react';
import Immutable from 'immutable';
import { bool, object, string } from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import OrderVariationItems from '../../shared/OrderVariationItems';
import CartVariationItems from '../../shared/CartVariationItems';

const CardBody = ({
  meal,
  isLoading,
  errorMsg,
  successMsg,
}) => {
  const orderables = !isLoading && meal.get('orderables');

  const constructMenuItem = (items) => items.map((item) => Immutable.fromJS({
    uuid: item.get('uuid'),
    quantity: item.get('quantity'),
    menuItem: { name: item.get('name') },
  }));

  return (
    <div className="card-body tw-bg-white !tw-px-4 !tw-py-0 !tw-pb-16 !tw-min-h-[330px] !tw-max-h-[330px] tw-scrollable">
      {
        errorMsg &&
          <Alert variant="danger" className="text-center">
            { errorMsg }
          </Alert>
      }

      {
        successMsg &&
          <Alert variant="success" className="text-center">
            <div dangerouslySetInnerHTML={{ __html: successMsg }} />
          </Alert>
      }

      {isLoading && <CartVariationItems isLoading={isLoading} />}

      {
        orderables && orderables.map((orderable, index) => {
          const isConfirmed = meal.get('isConfirmed');
          const cart = orderable.get('cartVariations') || orderable.get('orderVariations');
          const customMenu = orderable.get('customMenuItems');
          const variations = (customMenu.size > 0)
              ? cart.concat(constructMenuItem(customMenu))
              : cart;
          return (isConfirmed
            ? <OrderVariationItems
                key={index}
                variations={variations}
                vendorLogo={orderable.get('vendorLogo')}
                vendorName={orderable.get('vendorName')}
                displayCode={orderable.get('displayCode')}
            />
            : <CartVariationItems
                key={index}
                variations={variations}
                vendorLogo={orderable.get('vendorLogo')}
                vendorName={orderable.get('vendorName')}
            />
          );
        })
      }
    </div>
  );
};

CardBody.propTypes = {
  meal: object.isRequired,
  isLoading: bool,
  errorMsg: string,
  successMsg: string,
};

export default CardBody;
