/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint max-len: 0 */

import { func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { Tabs } from './components/Tabs';
import { Summary } from './components/tabs/summary';
import { MealPlans } from './components/tabs/meal_plans';
import { PantryPrograms } from './components/tabs/pantry_programs';
import { FilterBar } from './components/FilterBar';
import { Events } from './components/tabs/events';
import { useLocalStorage } from '../common/hooks/useLocalStorage';
import { useURLState } from '../common/hooks/useURLState';
import { useExportOrders } from './queries/use-export-orders';
import { OrdersExportModal } from './components/OrdersExportModal';
import { trackFilteredDateRange, trackClickedExportOrdersButton, trackViewedReportingPage } from './events';

const OrganisationName = styled.div`
  color: black;
  font-weight: bold;
  font-size: 2rem;
`;

const twoMonthsAgo = dayjs().subtract(2, 'month').startOf('month').toDate();

const firstBudgetDate = new Date('2023-01-01');

const initialStartDate =
  twoMonthsAgo > firstBudgetDate ? twoMonthsAgo : firstBudgetDate;

const FinancialReportsContainer = ({ organisation, cable, translate }) => {
  const [showOrdersExportModal, setShowOrdersExportModal] = useState(false);
  const [ordersExportResult, setOrdersExportResult] = useState({});

  const searchParams = new URLSearchParams(location.search);

  const initialTab = searchParams.get('tab') ?? 'summary';

  const [tab, setTab] = useLocalStorage('reporting-tab', initialTab, {
    raw: true,
  });

  let defaultStartDate = new Date(searchParams.get('startDate') || initialStartDate);
  if (isNaN(defaultStartDate.getTime())) {
    defaultStartDate = initialStartDate;
  }

  const endDateParam = searchParams.get('endDate');
  let defaultEndDate = dayjs(endDateParam).endOf('month').toDate() || dayjs().endOf('month').toDate();
  if (isNaN(defaultEndDate.getTime())) {
    defaultEndDate = dayjs().endOf('month').toDate();
  }

  const [startDate, setStartDate] = useLocalStorage(
    'reporting-start-date',
    defaultStartDate,
    {
      serializer: (date) => dayjs(date).format('YYYY-MM-DD'),
      deserializer: (date) => new Date(date),
    },
  );

  const [endDate, setEndDate] = useLocalStorage(
    'reporting-end-date',
    defaultEndDate,
    {
      serializer: (date) => dayjs(date).format('YYYY-MM-DD'),
      deserializer: (date) => dayjs(new Date(date)).endOf('month').toDate(),
    },
  );

  const { mutate: exportOrders } = useExportOrders({
    organisationId: organisation.uuid,
    sid: cable.socketId,
    startDate,
    endDate,
  });

  useEffect(() => {
    trackViewedReportingPage();

    const channel = cable.subscribeChannel(
      'programReportingOrdersExportChannel',
      (response) => {
        if (response.status === 'succeeded') {
          return setOrdersExportResult({
            message: translate('financialReportsOrdersExportSuccess'),
            link: response.link,
          });
        }

        if (response.status === 'failed') {
          return setOrdersExportResult({ errors: response.errors });
        }

        if (response.msg) {
          return setOrdersExportResult({ message: response.msg });
        }

        return null;
      },
    );

    return () => cable.unsubscribeChannel(channel);
  }, [cable]);

  const filters = {
    startDate,
    endDate,
    moneyValueType: organisation.reporting_inclusions,
  };

  useURLState({
    tab,
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
  });

  return (
    <div style={{ background: 'white', color: 'black' }}>
      <div className="tw-container tw-max-w-[1280px]">
        <div className="px-3 pt-3">
          <OrganisationName data-testid="reporting-organisation">
            {organisation.name}
          </OrganisationName>
        </div>
        <Tabs organisation={organisation} tab={tab} onChange={setTab} />
        <div className="p-3">
          <FilterBar
            startDate={startDate}
            onStartDateChange={(startDate) => {
              if (startDate !== null) {
                setStartDate(startDate);
                trackFilteredDateRange(startDate, endDate);
              }
            }}
            endDate={endDate}
            onEndDateChange={(endDate) => {
              if (endDate !== null) {
                // eslint-disable-next-line no-param-reassign
                endDate = dayjs(endDate).endOf('month').toDate();
                setEndDate(endDate);
                trackFilteredDateRange(startDate, endDate);
              }
            }}
            reportingInclusions={organisation.reporting_inclusions}
            onExportOrders={() => {
              setShowOrdersExportModal(true);
              exportOrders();
              trackClickedExportOrdersButton(startDate, endDate);
            }}
          />
          <div className="font-weight-bold" style={{ fontSize: 24 }}>
            {dayjs(startDate).isSame(endDate, 'month')
              ? dayjs(startDate).format('MMMM YYYY')
              : `${dayjs(startDate).format('MMMM YYYY')} - ${dayjs(endDate).format('MMMM YYYY')}`}
          </div>
        </div>
        {tab === 'summary' && (
          <Summary
            organisation={organisation}
            filters={filters}
          />
        )}
        {tab === 'meal_plans' && (
          <MealPlans organisation={organisation} filters={filters} />
        )}
        {tab === 'pantry_programs' && (
          <PantryPrograms organisation={organisation} filters={filters} />
        )}
        {tab === 'events' && (
          <Events organisation={organisation} filters={filters} />
        )}
      </div>
      <OrdersExportModal
        show={showOrdersExportModal}
        onHide={() => setShowOrdersExportModal(false)}
        message={ordersExportResult.message}
        startDate={startDate}
        endDate={endDate}
        downloadLink={ordersExportResult.link}
      />
    </div>
  );
};

FinancialReportsContainer.propTypes = {
  organisation: object.isRequired,
  cable: object.isRequired,
  translate: func.isRequired,
};

export default withAppContext(withI18n(FinancialReportsContainer));
