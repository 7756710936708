import React from 'react';

import AppContext from 'libs/support/appContext';
import { defaultTheme } from 'libs/support/theme';
import { ThemeProvider } from 'styled-components';
import FinancialReportsContainer from 'packs/financial_reports/FinancialReportsContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';
import { defaultLocale } from 'libs/i18n/default';
import { translations } from 'libs/i18n/translations';

const queryClient = new QueryClient();

export default (props, railsContext) => {
  const context = {
    fflags: railsContext.fflags,
    settings: railsContext.settings,
  };

  const locale = defaultLocale;
  const messages = translations[locale];

  window.apiCableUrl = railsContext.settings.api_cable_url;

  return (
    <AppContext.Provider value={context}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={defaultTheme}>
          <IntlProvider locale={locale} key={locale} messages={messages}>
            <FinancialReportsContainer {...props} />
          </IntlProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AppContext.Provider>
  );
};
