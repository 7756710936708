/* eslint-disable max-len */
import React from 'react';

const DairyFree = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#5BC0EB"
    />
    <path
      d="M6.18459 16H8.16459C10.9366 16 12.6406 14.536 12.6406 11.8C12.6406 9.172 10.9726 7.6 8.16459 7.6H6.18459V16ZM7.51659 14.836V8.776H8.29659C10.0966 8.776 11.2606 9.868 11.2606 11.8C11.2606 13.804 10.0966 14.836 8.29659 14.836H7.51659ZM13.9424 16H15.2744V12.424H17.7344V11.236H15.2744V8.8H18.0824V7.6H13.9424V16Z"
      fill="white"
    />
  </svg>
);

export default DairyFree;
