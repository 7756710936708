export const FETCHING_ORDER_FOR_DELIVERY = 'FETCHING_ORDER_FOR_DELIVERY';
export const FETCH_ORDER_FOR_DELIVERY_SUCCESS = 'FETCH_ORDER_FOR_DELIVERY_SUCCESS';
export const FETCH_ORDER_FOR_DELIVERY_FAILURE = 'FETCH_ORDER_FOR_DELIVERY_FAILURE';
export const SAVE_DAILY_LOG_FAILURE = 'SAVE_DAILY_LOG_FAILURE';
export const SAVE_DAILY_LOG_SUCCESS = 'SAVE_DAILY_LOG_SUCCESS';
export const SAVING_DAILY_LOG = 'SAVING_DAILY_LOG';
export const FETCH_DAILY_LOG_FAILURE = 'FETCH_DAILY_LOG_FAILURE';
export const FETCH_DAILY_LOG_SUCCESS = 'FETCH_DAILY_LOG_SUCCESS';
export const FETCHING_DAILY_LOG = 'FETCHING_DAILY_LOG';
export const FETCHING_MASTER_SUPPLY_ITEMS = 'FETCHING_MASTER_SUPPLY_ITEMS';
export const FETCH_MASTER_SUPPLY_ITEMS_SUCCESS = 'FETCH_MASTER_SUPPLY_ITEMS_SUCCESS';
export const FETCH_MASTER_SUPPLY_ITEMS_FAILURE = 'FETCH_MASTER_SUPPLY_ITEMS_FAILURE';
