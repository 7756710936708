/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import humps from 'lodash-humps';

import {
  SAVE_CART_VARIATION_SUCCESS,
  DELETE_CART_VARIATION_SUCCESS,
} from 'packs/common/constants/cartVariationsConstants';

import {
  CHECKING_OUT_USER_MEAL_CART,
  CHECK_OUT_USER_MEAL_CART_SUCCESS,
  CHECK_OUT_USER_MEAL_CART_FAILURE,
  RESET_USER_MEAL_CART_SUCCESS,
  CONFIRMING_USER_MEAL_CART,
  CONFIRM_USER_MEAL_CART_FAILURE,
  CONFIRM_USER_MEAL_CART_SUCCESS,
  SKIP_USER_MEAL_CART_SUCCESS,
  UNSKIP_USER_MEAL_CART_SUCCESS,
  EDIT_USER_MEAL_CART_SUCCESS,
  CREATE_USER_MEAL_CART_SUCCESS,
  FETCH_USER_MEAL_CART_SUCCESS,
} from 'packs/meals/constants/userMealCartConstants';

export const statuses = {
  confirmed: 'confirmed',
  editing: 'editing',
  pending: 'pending',
  skipped: 'skipped',
};

export const $$initialState = Immutable.fromJS({
  alertMsg: null,
});

export default function userMealCartReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case CHECKING_OUT_USER_MEAL_CART:
    case CONFIRMING_USER_MEAL_CART: {
      return $$state.set('alertMsg', null);
    }
    case CHECK_OUT_USER_MEAL_CART_FAILURE:
    case CONFIRM_USER_MEAL_CART_FAILURE: {
      return $$state.set('alertMsg', response.errors.join(','));
    }
    case EDIT_USER_MEAL_CART_SUCCESS:
    case RESET_USER_MEAL_CART_SUCCESS:
    case CONFIRM_USER_MEAL_CART_SUCCESS:
    case SKIP_USER_MEAL_CART_SUCCESS:
    case UNSKIP_USER_MEAL_CART_SUCCESS:
    case DELETE_CART_VARIATION_SUCCESS:
    case SAVE_CART_VARIATION_SUCCESS:
    case CHECK_OUT_USER_MEAL_CART_SUCCESS:
    case CREATE_USER_MEAL_CART_SUCCESS: {
      return $$state
        .set('alertMsg', null)
        .merge(humps(response.user_meal_cart));
    }
    case FETCH_USER_MEAL_CART_SUCCESS: {
      return $$state.merge(humps(response.user_meal_cart));
    }
    default: {
      if (response && response.user_meal_cart) {
        return $$state.merge(humps(response.user_meal_cart));
      }
      return $$state;
    }
  }
}
