/* eslint-disable camelcase */
/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import humps from 'lodash-humps';
import * as actionTypes from 'packs/meals/constants/mealConstants';

export const $$initialState = Immutable.fromJS({
  isLoading: true,
  isLoaded: false,
});

export default function mealCardReducers($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_MEAL_ORDERABLES_SUCCESS: {
      return $$state.withMutations(state => {
        const {
          orderables,
          vendors,
          vendor_categories,
          vendor_menu_items,
        } = response.meal;

        state
          .set('isLoading', false)
          .set('isLoaded', true)
          .setIn(['meal', 'orderables'], Immutable.fromJS(humps(orderables)))
          .setIn(['meal', 'vendorCategories'], Immutable.fromJS(humps(vendor_categories)))
          .setIn(['meal', 'vendorMenuItems'], Immutable.fromJS(humps(vendors || vendor_menu_items)));

        return state;
      });
    }
    case actionTypes.FETCH_MEAL_ORDERABLES_FAILURE: {
      return $$state.withMutations(state =>
        state
          .set('isLoading', false)
          .set('isLoaded', true));
    }
    default: {
      return $$state;
    }
  }
}
