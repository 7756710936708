/* eslint-disable no-alert */
/* eslint jsx-a11y/label-has-for: 0, no-return-assign: 0, max-len: 0 */

import React, { Fragment } from 'react';
import { bool, func, number, string } from 'prop-types';
import $ from 'jquery';
import bindAll from 'lodash/bindAll';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import classnames from 'classnames';

import BaseComponent from 'libs/components/BaseComponent';
import TypeNumberComponent from 'libs/components/TypeNumberComponent';
import { notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';
import PhotoPreview from './PhotoPreview';

const PreviewContainer = styled.span`
  display: inline-block !important;
  vertical-align: top;
  margin-left: 0.5rem;
  margin-top: -1px;
  padding: 0 !important;

  span {
    padding: 0 !important;
  }
`;

class Option extends BaseComponent {
  static propTypes = {
    disabled: bool,
    isPickOne: bool,
    isChecked: bool,
    isQuantityOptionEnabled: bool,
    hasReachedMax: bool,
    index: number.isRequired,
    min: number,
    max: number,
    quantity: number,
    totalQuantity: number,
    parentName: string.isRequired,
    parentId: string.isRequired,
    parentType: string.isRequired,
    lastName: string.isRequired,
    name: string.isRequired,
    value: string.isRequired,
    optionName: string.isRequired,
    optionPrice: string,
    onClick: func.isRequired,
    showPrice: bool.isRequired,
    intl: intlShape.isRequired,
    onShowExceededMaxOptionsAlert: func.isRequired,
  };

  constructor() {
    super();

    bindAll(this, 'handleOnClick');
  }

  shouldComponentUpdate(nextProps) {
    const { quantity, isChecked, hasReachedMax, photos } = this.props;

    if ((nextProps.quantity !== quantity) || (nextProps.isChecked !== isChecked) ||
        nextProps.hasReachedMax !== hasReachedMax || nextProps.photos !== photos) {
      return true;
    }

    return false;
  }

  handleOnShowExceededMaxOptionsAlert(max) {
    this.props.onShowExceededMaxOptionsAlert(max);
  }

  handleOnClick(prevValue) {
    const {
      isChecked, isPickOne, isQuantityOptionEnabled, hasReachedMax, max, parentId, parentType,
      lastName, name, value, onClick, intl,
    } = this.props;
    const { formatMessage } = intl;
    const $input = $(this.input);
    const checked = $input.is(':checked');

    if (isQuantityOptionEnabled) {
      const quantity = parseInt($(this.textFieldBlock).find('.number').val(), 10) || 1;

      if (!isPickOne && hasReachedMax && checked && ((quantity >= prevValue) || (typeof prevValue === 'object')) && notBlank(max)) {
        this.handleOnShowExceededMaxOptionsAlert(max);

        if (!isChecked) $input.prop('checked', false);
      } else {
        onClick({
          lastName, name, parentType, parentId, value, checked, quantity,
        });
      }
    } else if (!isPickOne && hasReachedMax && checked && notBlank(max)) {
      this.handleOnShowExceededMaxOptionsAlert(max);
      $input.prop('checked', false);
    } else {
      onClick({
        lastName, name, parentType, parentId, value, checked,
      });
    }
  }

  render() {
    const {
      disabled,
      isPickOne,
      isChecked,
      isQuantityOptionEnabled,
      hasReachedMax,
      index,
      min,
      max,
      quantity,
      totalQuantity,
      parentName,
      lastName,
      value,
      optionName,
      optionPhoto,
      optionPrice,
      photos,
      showPrice,
    } = this.props;

    let finalMax = ((max - totalQuantity) + quantity);

    if (finalMax <= 0) finalMax = null;

    let finalIndex = index;
    let type = 'checkbox';

    if (isPickOne) {
      finalIndex = 0;
      type = 'radio';
    }

    const intDisabled = notBlank(disabled) ? disabled : false;
    const labelClassName = classnames({
      'w-100': !isQuantityOptionEnabled,
    });

    return (
      <div
        ref={node => this.textFieldBlock = node}
        className={type}
      >
        <label className={labelClassName}>
          <input
            ref={node => this.input = node}
            type={type}
            name={`${parentName}[${finalIndex}][${lastName}]`}
            disabled={intDisabled}
            value={value}
            defaultChecked={isChecked}
            onClick={this.handleOnClick}
          />

          <span>
            {optionName}
            &nbsp;

            <em>{showPrice && optionPrice}</em>

            <PreviewContainer>
              <PhotoPreview photo={optionPhoto} />
            </PreviewContainer>
          </span>
        </label>

        {
          (!isPickOne && !isQuantityOptionEnabled) &&
          <input
            type="hidden"
            name={`${parentName}[${finalIndex}][quantity]`}
            value={1}
          />
        }

        {
          (isChecked && isQuantityOptionEnabled) &&
          <TypeNumberComponent
            shouldDisablePlus={hasReachedMax && notBlank(max)}
            placeholder={min}
            min={min}
            max={finalMax}
            value={quantity || min}
            name={`${parentName}[${finalIndex}][quantity]`}
            onChange={this.handleOnClick}
            disabled={intDisabled}
          />
        }

        {
          isChecked && photos.size > 0 &&
            <Fragment>
              <input
                type="hidden"
                name={`${parentName}[${finalIndex}][photos][][id]`}
                value={photos.getIn([0, 'id']) || 'new'}
              />

              <input
                type="hidden"
                name={`${parentName}[${finalIndex}][photos][][hash]`}
                value={photos.getIn([0, 'hash'])}
              />
            </Fragment>
        }
      </div>
    );
  }
}

export default injectIntl(Option);
