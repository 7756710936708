/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import React, { useState, useEffect, Fragment } from 'react';
import { withI18n } from 'libs/support/i18n';
import { any, func } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import Select from 'react-select';
import Toggle from 'react-toggle';
import FieldComponent from 'libs/components/FieldComponent';
import { withAppContext } from 'libs/support/appContext';
import { CloseModalIcon, SearchIcon, AddIcon, BackIcon } from 'libs/components/Icons';

import ItemList from './ItemList';
import MenuItem from '../../MenuItems/MenuItem';
import ConfirmationModal from './ConfirmationModal';
import { DELETE_CART_VARIATION_ORDER_FORM_SUCCESS } from '../../../../constants/mealConstants';

const Breadcrumbs = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lavendar};
  border-top: 1px solid #eaeaeb;
  display: flex;
  justify-content: space-between;
  margin: -18px -16px 0;
`;

const Crumb = styled.div`
  flex: 1;
  background-color: ${props => (props.isActive ? props.theme.colors.green : props.theme.colors.white)};
  color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.gunPowder)};
  padding: 15px;
  position: relative;
  text-align: center;

  strong {
    margin-right: 10px;
  }

  &:not(:last-child) {
    &::before {
      border-bottom: 25px solid ${props => props.theme.colors.white};
      border-left: 25px solid transparent;
      border-top: 0 solid ${props => props.theme.colors.white};
      content: '';
      display: ${props => (props.isActive ? 'block' : 'none')};
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      z-index: 1;
    }

    &::after {
      border-bottom: 0 solid ${props => props.theme.colors.white};
      border-left: 25px solid transparent;
      border-top: 25px solid ${props => props.theme.colors.white};
      content: '';
      display: ${props => (props.isActive ? 'block' : 'none')};
      height: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      z-index: 1;
    }
  }

  &:not(:first-child) {
    &::before {
      border-bottom: 25px solid ${props => props.theme.colors.green};
      border-left: 25px solid ${props => props.theme.colors.white};
      border-top: 0 solid transparent;
      content: '';
      display: ${props => (props.isActive ? 'block' : 'none')};
      height: 100%;
      left: -15px;
      position: absolute;
      top: 0;
      width: 0;
      z-index: 1;
    }

    &::after {
      border-bottom: 0 solid transparent;
      border-left: 25px solid ${props => props.theme.colors.white};
      border-top: 25px solid ${props => props.theme.colors.green};
      content: '';
      display: ${props => (props.isActive ? 'block' : 'none')};
      height: 0;
      left: -15px;
      position: absolute;
      top: 0;
      width: 0;
      z-index: 1;
    }
  }

`;

const StyledToggle = styled(Toggle)`
  &.react-toggle--checked .react-toggle-track {
    background: linear-gradient(0deg, #7D9E1D, #7D9E1D),
    linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .react-toggle-track {
    background: linear-gradient(0deg, #95989D, #95989D),
    linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: #7D9E1D;
  }

  .react-toggle-thumb {
    border-color: #95989D;
  }
`;

const MealCartItemMeta = ({ addons }) => (
  <div className="tw-inline-block">
    {addons.map((option, index) => (
      <Fragment>
        <span key={index} className="tw-text-sm tw-text-gray-700">
          {option.get('name')}
        </span>
        {index < (addons.size - 1) && <span className="tw-mx-1">&#8226;</span>}
      </Fragment>
      ))}
  </div>
);

MealCartItemMeta.propTypes = {
  addons: any,
};

const MealCartItemModal = ({
  items,
  show,
  onHide,
  onSearch,
  searchText,
  translate,
  showOrderForm,
  toggleShowOrderForm,
  menuItemsByVendorId,
  orderables,
  selectedMenuItem,
  selectedCartVariation,
  fetchMenuItem,
  showUserSelectionForm,
  resetCartVariation,
  mealPlanUsers,
  saveCartVariationOrderForm,
  mealPlanId,
  cartId,
  mealId,
  isSavingCartVariation,
  newAddedCartVariationId,
  errors,
  fetchCartVariation,
  deleteCartVariation,
  toggleShowSelectUserForm,
  menuItemsById,
  mealAdmin,
  showUserSelectionFormOnly,
  getAdminMealCart,
  flagIsEnabled,
}) => {
  const [selectedMealPlanUser, setSelectedMealPlanUser] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [mealPlanIdToDelete, setMealPlanIdToDelete] = useState(null);
  const [orderableVariationIdToDelete, setOrderableVariationIdToDelete] = useState(null);
  const [showGuestInput, setShowGuestInput] = useState(false);
  const [selectedMealPlanGuestName, setSelectedMealPlanGuestName] = useState(null);
  const userOptions = mealPlanUsers.map(e => ({ value: e.uuid, label: e.full_name }));
  const groupOrderMealGuestEnabled = flagIsEnabled('grouped_meal_v2_guest_enabled');

  const debouncedSearch = debounce((query) => {
    onSearch(query);
  }, 500);

  const onChangeSearchInput = (evt) => {
    debouncedSearch(evt.target.value);
  };

  const handleSubmit = async () => {
    const id = selectedCartVariation.get('uuid');
    const mealPlanUser = !showGuestInput ? selectedMealPlanUser.value : null;

    let params;
    if (id) {
      const cartVariation = selectedCartVariation.toJS();
      const {
        additional_instructions,
        cart_variation_item_add_on_options,
        cart_variation_option_choices,
        cart_variation_service_add_on_options,
        quantity,
      } = cartVariation;

      params = {
        cart_variation: {
          quantity,
          additional_instructions,
        },
        cart_user_id: selectedMealPlanUser.value,
        meal_id: mealId,
        admin: mealAdmin,
      };

      // This will duplicate cart_variation_item_add_on_options if available
      if (cart_variation_item_add_on_options.length) {
        const addons = cart_variation_item_add_on_options.map(addon =>
          ({
            item_add_on_option_id: addon.itemAddOnOptionId,
            quantity: addon.quantity,
          }));

        params = Object.assign(params, {
          cart_variation_item_add_on_options: addons,
        });
      }

      // This will duplicate cart_variation_option_choices if available
      if (cart_variation_option_choices.length) {
        const addons = cart_variation_option_choices.map(addon =>
          ({
            option_choice_id: addon.optionChoiceId,
            quantity: addon.quantity,
          }));

        params = Object.assign(params, {
          cart_variation_option_choices: addons,
        });
      }

      // This will duplicate cart_variation_service_add_on_options if available
      if (cart_variation_service_add_on_options.length) {
        const addons = cart_variation_service_add_on_options.map(addon =>
          ({
            service_add_on_option_id: addon.serviceAddOnOptionId,
            quantity: addon.quantity,
          }));

        params = Object.assign(params, {
          cart_variation_service_add_on_options: addons,
        });
      }
    } else {
      let newCartVariation = selectedCartVariation;
      newCartVariation = newCartVariation.set('cart_id', cartId);
      newCartVariation = newCartVariation.set('cart_user_id', mealPlanUser);
      newCartVariation = newCartVariation.set('admin', mealAdmin);
      params = {
        cart_id: cartId,
        meal_id: mealId,
        cart_variation: newCartVariation,
        cart_user_id: mealPlanUser,
        admin: mealAdmin,
      };
    }

    if (showGuestInput) {
      params = { ...params,
        ...{
          is_guest: showGuestInput,
          guest_name: selectedMealPlanGuestName,
        },
      };
    }

    await saveCartVariationOrderForm(mealPlanId, id, params);
    setShowGuestInput(false);
    await getAdminMealCart();
  };

  const handleToggleAddGuest = (e) => {
    setShowGuestInput(e.target.checked);
  };

  const handleChangeGuestInput = (e) => {
    setSelectedMealPlanGuestName(e.target.value);
  };

  const onDelete = async () => {
    if (mealPlanIdToDelete && orderableVariationIdToDelete) {
      let params = {
        admin: mealAdmin,
        meal_id: mealId,
      };

      if (selectedMealPlanGuestName) {
        params = Object.assign(params, { is_guest: true });
      }
      const deleteCartVariationAction = await deleteCartVariation(
        mealPlanIdToDelete,
        orderableVariationIdToDelete,
        params,
      );
      if (deleteCartVariationAction.type === DELETE_CART_VARIATION_ORDER_FORM_SUCCESS) {
        getAdminMealCart();
      }
      setShowConfirmationModal(false);
      setSelectedMealPlanGuestName(null);
    }
  };

  const onDeleteItem = (mealPlanId, id, isGuest) => {
    setShowConfirmationModal(true);
    setMealPlanIdToDelete(mealPlanId);
    setOrderableVariationIdToDelete(id);
    setSelectedMealPlanGuestName(isGuest);
  };

  const onCloseModal = () => {
    onHide();

    const backdrop = $('.modal-backdrop');
    if (backdrop && backdrop.length > 1) {
      backdrop.each((i, e) => { if (i < (backdrop.length - 1)) e.remove(); });
    }
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const onEditAssignedUser = (_mealPlanId, cartVariation, menuItem) => {
    const params = { cartVariation, menuItem };
    toggleShowSelectUserForm(params);
  };

  const renderListItem = () => (
    <>
      <div
        data-test-id="list-item-information"
        className="tw-rounded tw-p-4 tw-bg-blue-50"
      >
        <div className="tw-flex tw-gap-1 tw-items-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM10.9562 10.5584C12.1025 9.98533 13.3931 11.0206 13.0823 12.2639L12.3733 15.0999L12.4148 15.0792C12.7852 14.894 13.2357 15.0441 13.421 15.4146C13.6062 15.7851 13.4561 16.2356 13.0856 16.4208L13.0441 16.4416C11.8979 17.0147 10.6072 15.9794 10.9181 14.7361L11.6271 11.9001L11.5856 11.9208C11.2151 12.1061 10.7646 11.9559 10.5793 11.5854C10.3941 11.2149 10.5443 10.7644 10.9148 10.5792L10.9562 10.5584ZM12 9C12.4142 9 12.75 8.66421 12.75 8.25C12.75 7.83579 12.4142 7.5 12 7.5C11.5858 7.5 11.25 7.83579 11.25 8.25C11.25 8.66421 11.5858 9 12 9Z" fill="#1280B7" />
          </svg>
          <p className="tw-font-bold tw-text-base tw-m-0 tw-text-gray-900">
            {translate('editOrderModalTitle')}
          </p>
        </div>
        <p className="tw-font-normal tw-text-blueberry-900 tw-m-0">
          {translate('editOrderModalDescription')}
        </p>
      </div>
      <div className="tw-grid tw-grid-cols-6 tw-gap-x-2 md:tw-gap-x-6">
        <div className="tw-col-span-4 md:tw-cols-span-4">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search user, item..."
              aria-label="Search user, item..."
              aria-describedby="basic-addon2"
              onChange={(e) => onChangeSearchInput(e)}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-text-primary" id="basic-addon2">
                <SearchIcon />
              </span>
            </div>
          </div>
        </div>
        <div className="tw-col-span-2 md:tw-col-span-2">
          <button
            className={classNames('btn-search-edit-modal !tw-flex tw-gap-2', {
              'tw-w-full tw-h-9 tw-items-center tw-justify-center tw-rounded': true,
              'tw-border tw-border-broccoli-600': true,
            })}
            onClick={toggleShowOrderForm}
          >
            <span className="tw-hidden md:tw-block"><AddIcon color="#7D9E1D" /></span>
            <span className="tw-text-broccoli-600">{translate('editOrderModalButtonsAdd')}</span>
          </button>
        </div>
      </div>

      <ItemList
        items={items}
        searchText={searchText}
        newAddedCartVariationId={newAddedCartVariationId}
        fetchCartVariation={fetchCartVariation}
        mealPlanId={mealPlanId}
        onDeleteItem={onDeleteItem}
        onEditAssignedUser={onEditAssignedUser}
        menuItemsById={menuItemsById}
      />
    </>
  );

  const itemSelectionForm = () => (
    <>
      <div className="edit-order-container-overflow !tw-min-h-[75vh] md:!tw-min-h-[55vh] !tw-p-0 !tw-py-4 !tw-overflow-hidden">
        <p>Please select an item from the menu below: </p>
        <div className="tw-max-h-[68vh] md:tw-max-h-[55vh] tw-scrollable tw-overflow-hidden tw-overflow-y-auto ">
          { orderables && orderables.map((orderable, index) => {
            const vendorName = orderable.get('vendorName');
            const vendorId = orderable.get('vendorId');
            const vendorMenuItems = menuItemsByVendorId.getIn([vendorId]);

            return (
              <>
                <div key={index} className={classNames('category-header', { 'tw-pt-4': index > 0 })}>
                  <div className="d-md-block d-md-flex justify-content-between">
                    <h5 className="category-title">
                      { vendorName }
                    </h5>
                  </div>
                </div>
                <div className={classNames('card-deck tw-gap-4', {
                  '!tw-grid tw-grid-cols-1 md:tw-grid-cols-2': true,
                  '!tw-m-0': true,
                })}
                >
                  {vendorMenuItems && vendorMenuItems.map((menuItem) => {
                    const menuItemId = menuItem.get('uuid');
                    return (
                      <MenuItem
                        key={`menuItem-${menuItemId}`}
                        onClick={_ => {
                          fetchMenuItem(menuItemId);
                        }}
                        {
                          ...{
                            showPrice: true,
                            isSelected: selectedMenuItem === menuItem,
                            menuItem,
                          }
                        }
                      />
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="modal-edit-order-footer-container">
        <Button variant="light" className="btn btn-light pull-right mt-3 btn-footer-edit-modal" onClick={toggleShowOrderForm}>
          <strong>Back to All Orders</strong>
        </Button>
      </div>
    </>
  );

  const userSelectionForm = () => {
    const photo = selectedMenuItem.getIn(['photos', 0]);
    const itemAddOnOptions = selectedCartVariation.get('cart_variation_item_add_on_options');
    const serviceAddOnOptions = selectedCartVariation.get('cart_variation_service_add_on_options');
    return (
      <>
        <div className="edit-order-container-overflow !tw-min-h-[78vh] md:!tw-min-h-[55vh] !tw-p-0 !tw-py-4 tw-scrollable">
          <div className="card card-menu !tw-bg-white !tw-shadow-none">
            <div className="card-body !tw-p-4">
              <div className="tw-grid tw-grid-cols-12 tw-gap-4 tw-bg-gray-100 tw-rounded-lg tw-p-2">
                <div className="tw-col-span-3">
                  <div className="menu-thumbnail tw-p-2 tw-rounded-lg tw-overflow-hidden">
                    <img
                      className="tw-rounded-lg"
                      src={photo?.get('url')}
                      alt={photo?.get('url')}
                    />
                  </div>
                </div>
                <div className="tw-col-span-9 tw-flex tw-items-center">
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <div className="tw-text-base tw-text-gray-900 tw-font-bold">
                      {selectedMenuItem.get('quantity') && (
                        <span className="tw-mr-2">
                          {selectedMenuItem.get('quantity')}x
                        </span>
                      )}
                      <span>{selectedMenuItem.get('name')}</span>
                    </div>
                    {(
                      (itemAddOnOptions && itemAddOnOptions.size > 0) ||
                      (serviceAddOnOptions && serviceAddOnOptions.size > 0)) &&
                      (
                        <Fragment>
                          <div className="tw-my-0">
                            <span className="tw-text-xs tw-text-gray-600">Selection</span>
                          </div>
                          <div className="tw-inline-block">
                            {itemAddOnOptions.map((option, index) => (
                              <Fragment key={index}>
                                <span className="tw-text-sm tw-text-gray-700">
                                  {option.get('name')}
                                </span>
                                {index < (itemAddOnOptions.size - 1) && <span className="tw-mx-1">&#8226;</span>}
                              </Fragment>
                            ))}
                          </div>
                          <div className="tw-inline-block">
                            {serviceAddOnOptions.map((option, index) => (
                              <Fragment key={index} >
                                <span className="tw-text-sm tw-text-gray-700">
                                  {option.get('name')}
                                </span>
                                {index < (serviceAddOnOptions.size - 1) && <span className="tw-mx-1">&#8226;</span>}
                              </Fragment>
                            ))}
                          </div>
                        </Fragment>
                      )
                    }
                  </div>
                </div>
              </div>
              {errors && errors.size > 0 &&
                <div className="alert alert-danger text-center mt-4">
                  {
                    errors.map((message, index) => (
                      <p
                        className="tw-m-0"
                        key={`error-${index}`}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    ))
                  }
                </div>
              }
              <p className="tw-mt-4 tw-text-gray-700 tw-text-sm">Who is this meal intended for?</p>
              <div className="tw-mt-4">
                <Select
                  isDisabled={showGuestInput}
                  className="user-selection"
                  placeholder="Choose from the meal plan user list"
                  options={userOptions}
                  onChange={setSelectedMealPlanUser}
                  value={selectedMealPlanUser}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                    ...theme.colors,
                      primary25: '#F2F5E8',
                      primary: '#7d9e1d',
                      primary75: '#F2F5E8',
                      primary50: '#F2F5E8',
                    },
                  })}
                />
              </div>
              {groupOrderMealGuestEnabled && (
                <label className="tw-mt-4 tw-flex tw-gap-4 tw-items-center">
                  <span>Assign to guest</span>
                  <StyledToggle
                    defaultChecked={showGuestInput}
                    onChange={handleToggleAddGuest}
                    name="cart_variation[is_guest]"
                  />
                </label>
              )}
              {showGuestInput && (
                <div className="tw-mt-4 tw-rounded tw-bg-gray-100 tw-p-4">
                  <FieldComponent
                    label="editOrderModalFormGuestName"
                    type="text"
                    name="cart_variation[guest_name]"
                    placeholder="Guest"
                    fieldClassName="tw-flex tw-flex-col"
                    labelClassName="tw-p-0 tw-m-0"
                    inputClassName="tw-w-full tw-min-w-full tw-p-0 tw-my-2"
                    onChange={handleChangeGuestInput}
                  />
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <span className="tw-text-md tw-text-gray-500">
                      The meal will be labelled using this name.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-edit-order-footer-container">
          <Button variant="light" className="btn btn-light mr-2 btn-footer-edit-modal mr-3" onClick={showUserSelectionFormOnly ? toggleShowOrderForm : resetCartVariation}>
            <strong>Back</strong>
          </Button>
          <Button className="btn btn-primary btn-footer-edit-modal" onClick={handleSubmit}>
            <strong>Add to Order</strong>
          </Button>
        </div>
      </>
    );
  };

  const renderForm = () => (
    <>
      {!showUserSelectionFormOnly && // hide breadcrumb when edit assign form
        <Breadcrumbs>
          <Crumb isActive={!showUserSelectionForm}>
            <strong>
              { translate('editOrderModalTabsStep1') }
            </strong>
            <span>
              { translate('editOrderModalTabsSelectItem') }
            </span>
          </Crumb>
          <Crumb isActive={showUserSelectionForm}>
            <strong>
              { translate('editOrderModalTabsStep2') }
            </strong>
            <span>
              { translate('editOrderModalTabsSelectUser') }
            </span>
          </Crumb>
        </Breadcrumbs>
      }
      {showUserSelectionForm ? userSelectionForm() : itemSelectionForm()}
    </>
  );

  useEffect(() => {
    if (selectedCartVariation.get('meal_plan_user_id')) {
      const user = userOptions.find(e => e.value === selectedCartVariation.get('meal_plan_user_id'));
      setSelectedMealPlanUser(user);
    } else {
      setSelectedMealPlanUser(null);
    }
  }, [selectedCartVariation]);

  useEffect(() => {
    if (errors && errors.size > 0) {
      setShowGuestInput(false);
    }
  }, [errors]);

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      size="lg"
      dialogClassName="confirmation-modal menuitem-form admin-modal"
    >
      <div className="modal-content !tw-min-h-screen md:!tw-min-h-[60vh]">
        {
          (isSavingCartVariation) &&
          <div className="loading" />
        }
        <div className="modal-header">
          <h6 className="modal-title ml-2">
            <span
              className="mr-2 d-lg-none back-icon"
              onClick={onHide}
            >
              <BackIcon />
            </span>
            {translate('editOrderModalHeaderEditOrder')}
          </h6>
          <button type="button" className="close-icon-modal" onClick={onCloseModal}>
            <span aria-hidden="true">
              <CloseModalIcon />
            </span>
          </button>
        </div>
        {
          showConfirmationModal &&
          <ConfirmationModal
            onDelete={onDelete}
            onCancel={closeConfirmationModal}
          />
        }
        <div className={`modal-body !tw-p-0 ${showOrderForm ? 'show-order-form-modal' : ''}`}>
          <div className="tw-p-4 tw-p-4 tw-flex tw-flex-col tw-gap-4">
            {showOrderForm ? renderForm() : renderListItem()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

MealCartItemModal.propTypes = {
  items: any,
  show: any,
  onHide: any,
  onSearch: any,
  searchText: any,
  translate: any,
  showOrderForm: any,
  toggleShowOrderForm: any,
  menuItemsByVendorId: any,
  orderables: any,
  selectedMenuItem: any,
  selectedCartVariation: any,
  fetchMenuItem: any,
  showUserSelectionForm: any,
  resetCartVariation: any,
  mealPlanUsers: any,
  saveCartVariationOrderForm: any,
  mealPlanId: any,
  cartId: any,
  mealId: any,
  mealPlanUserId: any,
  isSavingCartVariation: any,
  newAddedCartVariationId: any,
  errors: any,
  fetchCartVariation: any,
  deleteCartVariation: any,
  toggleShowSelectUserForm: any,
  menuItemsById: any,
  mealAdmin: any,
  showUserSelectionFormOnly: any,
  getAdminMealCart: any,
  flagIsEnabled: func,
};

export default withAppContext(withI18n(MealCartItemModal));
