/* eslint max-len: 0 */

import React from 'react';
import { string } from 'prop-types';
import { getHexColor } from 'libs/support/hexColors';

const Home = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.5089 3.27411C12.3271 3.09137 12.1453 3 11.9635 3C11.7816 3 11.5089 3.09137 11.418 3.27411L3.23619 11.4975C2.96346 11.7716 2.96346 12.0457 3.05437 12.4112C3.14528 12.6853 3.50892 12.868 3.78164 12.868H4.78164V19.3553C4.78164 20.269 5.50892 21.0914 6.50892 21.0914H17.418C18.3271 21.0914 19.1453 20.3604 19.1453 19.3553V12.868H20.1453C20.5089 12.868 20.7816 12.6853 20.8726 12.4112C20.9635 12.1371 20.9635 11.7716 20.6907 11.4975L12.5089 3.27411ZM13.8726 19.4467H10.0544V14.6954H13.8726V19.4467ZM15.5089 19.4467V14.7868C15.5089 13.8731 14.7816 13.0508 13.7816 13.0508H10.1453C9.23619 13.0508 8.41801 13.7817 8.41801 14.7868V19.4467H6.41801V10.4924L11.9635 4.91878L17.5089 10.4924V19.4467H15.5089Z" fill={getHexColor(fill)} />
  </svg>
);

Home.propTypes = {
  fill: string,
};

Home.defaultProps = {
  fill: 'grey-chateau',
};

export default Home;
