import React, { Fragment, useState } from 'react';
import { any, bool, func, object } from 'prop-types';
import moment from 'moment';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { fetchPdfLink } from 'libs/services/labelsApi';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardFooter from './CardFooter';

const PlannedMealCard = withI18n(({
  meal,
  isLoading,
  userRoleIn,
  translate,
}) => {
  const [state, setState] = useState({
    isProcessing: false,
    downloadableLink: null,
    errorMsg: null,
  });

  const buildSuccessMsg = () => {
    if (state.isProcessing) {
      return translate('plannedMealCardsProcessing');
    } else if (state.downloadableLink) {
      return translate('plannedMealCardsDownload', { link: state.downloadableLink });
    }
    return '';
  };

  const handleOnClickDownloadPdf = () => {
    if (state.isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    const mealId = meal.get('uuid');
    const mealPlanId = meal.get('mealPlanId');
    const type = 'menu_cards';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  return (
    <Fragment>
      <CardHeader
        {...{
          meal,
          userRoleIn,
          isLoading,
          onClickDownloadPdf: handleOnClickDownloadPdf,
        }}
      />
      <CardBody
        {
          ...{
            meal,
            isLoading,
            errorMsg: state.errorMsg,
            successMsg: buildSuccessMsg(),
          }
        }
      />
      <CardFooter
        {
          ...{
            meal,
            isLoading,
            handleOnClickDownloadPdf,
          }
        }
      />
    </Fragment>
  );
});

PlannedMealCard.propTypes = {
  meal: object.isRequired,
  userRoleIn: any,
  isLoading: bool,
  translate: func,
};

export default withAppContext(PlannedMealCard);
