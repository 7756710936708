/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { notBlank } from 'libs/support/string';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import moment from 'moment';
import { SAVE_DAILY_LOG_FAILURE } from '../../../constants/pantryProgramConstants';
import ViewStockCountStep from './viewStockCountStep';
import { SubmittedSuccessfullyStep } from './submittedSuccessfullyStep';
import { ConfirmStockCount } from './confirmStockCount';
import { DRAFT } from './stockCount';
import FiltersStep from './filtersStep';

const VIEW_STOCK_COUNT = 'VIEW_STOCK_COUNT';
const CONFIRM_STOCK_COUNT = 'CONFIRM_STOCK_COUNT';
const SUBMIT_STOCK_COUNT_SUCCESSFULLY = 'SUBMIT_STOCK_COUNT_SUCCESSFULLY';
const CATEGORY_FILTER = 'CATEGORY_FILTER';


export function StockCountModal({
  pantryProgram,
  dailyLog,
  masterSupplyItems,
  show,
  onHide,
  saveDailyLog,
  logDate,
}) {
  if (!masterSupplyItems || masterSupplyItems.length === 0) {
    return null;
  }

  function initializeStockCount() {
    const status = dailyLog?.status?.stockCount?.status;

    let stockCounts;
    if (status === DRAFT) {
      stockCounts = _map(masterSupplyItems, msi => {
        const st = _find(dailyLog.stockCounts, stockCount => stockCount.masterSupplyItemId === msi.uuid);
        return ({
          id: st?.id,
          carton: st?.carton?.toString() || '',
          looseItems: st?.looseItems?.toString() || '',
          name: msi?.menuItem?.name,
          variationName: msi?.variation?.name,
          photo: msi?.thumbnail,
          masterSupplyItemId: msi?.uuid,
          category: msi?.menuItem?.categoryName,
        });
      });
    } else {
      stockCounts =
        _map(masterSupplyItems, msi => ({
          name: msi?.menuItem?.name,
          variationName: msi?.variation?.name,
          photo: msi?.thumbnail,
          masterSupplyItemId: msi?.uuid,
          carton: '',
          looseItems: '',
          category: msi?.menuItem?.categoryName,
        }));
    }

    stockCounts = _sortBy(stockCounts, ['category', 'name']);

    let cat;
    _each(stockCounts, sc => {
      if (sc.category !== cat) {
        // eslint-disable-next-line no-param-reassign
        sc.showCategory = true;
        cat = sc.category;
      }
    });
    return stockCounts;
  }

  const [stockCounts, setStockCounts] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);
  const [appliedFilterCategories, setAppliedFilterCategories] = useState([]);
  const [step, setStep] = useState(VIEW_STOCK_COUNT);
  const [isDraft, setIsDraft] = useState(false);
  const [error, setError] = useState('');
  const [draftCount, setDraftCount] = useState(0);

  const filters = useMemo(() => _uniq(_map(stockCounts, 'category')), [stockCounts]);

  function handleIsDraftUpdate(value) {
    setIsDraft(value);
    setDraftCount(count => count + 1); // Increment submitCount every time this function is called
  }

  function handleOnHide() {
    setStep(VIEW_STOCK_COUNT);
    onHide();
  }

  function handleSubmit() {
    if (!draftCount) {
      return;
    }

    switch (step) {
      case VIEW_STOCK_COUNT:
        setStep(CONFIRM_STOCK_COUNT);
        break;
      case CONFIRM_STOCK_COUNT:
        saveDailyLog({
          ...(dailyLog?.uuid && { uuid: dailyLog.uuid }),
          status: {
            ...(dailyLog?.status),
            status: 'ongoing',
            stockCount: isDraft ? {
              status: 'draft',
              draftTimestamp: moment().unix(),
            } : {
              status: 'submitted',
            },
          },
          stockCountsAttributes: _map(_filter(stockCounts, st => !isDraft || !!st.looseItems || !!st.carton || !!st.id), st => {
            st.looseItems = (notBlank(st.looseItems)) ? st.looseItems : (isDraft ? null : 0);
            st.carton = (notBlank(st.carton)) ? st.carton : (isDraft ? null : 0);
            return st;
          }),
          logDate,
        }, pantryProgram.uuid)
          .then((data) => {
            if (data.type === SAVE_DAILY_LOG_FAILURE) {
              setError(data.response.errors.join(', '));
            } else {
              setError('');
              setStep(SUBMIT_STOCK_COUNT_SUCCESSFULLY);
            }
          });
        break;
      default:
        break;
    }
  }

  function handleBack() {
    switch (step) {
      case VIEW_STOCK_COUNT:
        handleOnHide();
        break;
      case CATEGORY_FILTER:
        setStep(VIEW_STOCK_COUNT);
        break;
      case CONFIRM_STOCK_COUNT:
        setStep(VIEW_STOCK_COUNT);
        break;
      case SUBMIT_STOCK_COUNT_SUCCESSFULLY:
        setStep(CONFIRM_STOCK_COUNT);
        break;
      default:
        break;
    }
  }

  function handleCategoryFilterClicked() {
    setStep(CATEGORY_FILTER);
  }

  useEffect(() => {
    setStockCounts(initializeStockCount);
    setFilterCategories([]);
    setAppliedFilterCategories([]);
  }, [masterSupplyItems, dailyLog]);

  useEffect(() => {
    setError('');
  }, [step]);

  useEffect(() => {
    handleSubmit();
  }, [draftCount]);

  return (
    <Modal
      show={show}
      onHide={handleOnHide}
      className="fullscreen-modal"
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex">
            <div>
              <Button variant="link" size="sm" onClick={handleBack} style={{ padding: 0 }}>
                <FontAwesomeIcon icon={faArrowLeft} color="#7D9E1D" fontSize={20} />
              </Button>
            </div>
            <div className="flex-grow-1 ml-4">
              {step === VIEW_STOCK_COUNT && 'Stock Count'}
              {step === CONFIRM_STOCK_COUNT && (isDraft ? 'Save Stock Count' : 'Submit Stock Count')}
              {step === SUBMIT_STOCK_COUNT_SUCCESSFULLY && 'Submitted Successfully'}
              {step === CATEGORY_FILTER && 'Filters'}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'auto', paddingTop: 0 }}>
        {
          error && (
            <div className="alert-danger px-2 py-2 mb-2">
              {error}
            </div>
          )
        }
        {step === VIEW_STOCK_COUNT && (
          <ViewStockCountStep
            setStockCounts={setStockCounts}
            stockCounts={stockCounts}
            filterCategories={appliedFilterCategories}
            onClickCategoryFilter={handleCategoryFilterClicked}
          />
        )}
        {step === CATEGORY_FILTER && (
          <FiltersStep
            filters={filters}
            filterCategories={filterCategories}
            setFilterCategories={setFilterCategories}
          />
        )}
        {step === CONFIRM_STOCK_COUNT && (
          <ConfirmStockCount logDate={logDate} isDraft={isDraft} />
        )}
        {step === SUBMIT_STOCK_COUNT_SUCCESSFULLY && (
          <SubmittedSuccessfullyStep logDate={logDate} isDraft={isDraft} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-2 w-100">
          {
            step === VIEW_STOCK_COUNT && (
              <div className="d-flex" style={{ gap: '12px' }}>
                <div className="flex-grow-1">
                  <Button
                    variant="outline-dark"
                    onClick={() => {
                      handleIsDraftUpdate(true);
                    }}
                    block
                  >
                    Save for Later
                  </Button>
                </div>
                <div className="flex-grow-1">
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleIsDraftUpdate(false);
                    }}
                    block
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )
          }
          {
            step === CATEGORY_FILTER && (
              <div className="d-flex" style={{ gap: '12px' }}>
                <div className="flex-grow-1">
                  <Button
                    variant="outline-dark"
                    onClick={() => { setFilterCategories([]); }}
                    block
                  >
                    Clear All
                  </Button>
                </div>
                <div className="flex-grow-1">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setAppliedFilterCategories(filterCategories);
                      handleBack();
                    }}
                    block
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )
          }
          {
            step === CONFIRM_STOCK_COUNT && (
              <Button
                variant="warning"
                onClick={() => handleSubmit()}
                block
              >
                {isDraft ? 'Save Draft' : 'Confirm and Submit'}
              </Button>
            )
          }
          {
            step === SUBMIT_STOCK_COUNT_SUCCESSFULLY && (
              <Button
                variant="primary"
                onClick={handleOnHide}
                block
              >
                Back to Daily Log
              </Button>
            )
          }
        </div>
      </Modal.Footer>
    </Modal>
  );
}

StockCountModal.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  masterSupplyItems: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  saveDailyLog: PropTypes.func,
  logDate: PropTypes.string.isRequired,
};
