/* eslint max-len: 0 */

import * as api from 'libs/middlewares/api';
import * as actionTypes from 'packs/meals/constants/userMealCartConstants';

export function fetchSuccess(payload) {
  return {
    type: actionTypes.FETCH_USER_MEAL_CART_SUCCESS,
    payload,
  };
}

export function checkoutUserMealCart(mealPlanId, id, params) {
  const {
    CHECKING_OUT_USER_MEAL_CART,
    CHECK_OUT_USER_MEAL_CART_SUCCESS,
    CHECK_OUT_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [
      CHECKING_OUT_USER_MEAL_CART,
      CHECK_OUT_USER_MEAL_CART_SUCCESS,
      CHECK_OUT_USER_MEAL_CART_FAILURE,
    ],
    endpoint: `/meal_plans/${mealPlanId}/user_meal_carts/${id}/checkout`,
    params,
  });
}

export function resetUserMealCart(mealPlanId, id, params = null) {
  const {
    RESETTING_USER_MEAL_CART,
    RESET_USER_MEAL_CART_SUCCESS,
    RESET_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [RESETTING_USER_MEAL_CART, RESET_USER_MEAL_CART_SUCCESS, RESET_USER_MEAL_CART_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/reset_user_meal_carts`,
    params,
    id,
  });
}

export function confirmUserMealCart(mealPlanId, id, params = null) {
  const {
    CONFIRMING_USER_MEAL_CART,
    CONFIRM_USER_MEAL_CART_SUCCESS,
    CONFIRM_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [CONFIRMING_USER_MEAL_CART, CONFIRM_USER_MEAL_CART_SUCCESS, CONFIRM_USER_MEAL_CART_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/confirm_user_meal_carts`,
    params,
    id,
  });
}

export function skipUserMealCart(mealPlanId, id, params = null) {
  const {
    SKIPING_USER_MEAL_CART,
    SKIP_USER_MEAL_CART_SUCCESS,
    SKIP_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [SKIPING_USER_MEAL_CART, SKIP_USER_MEAL_CART_SUCCESS, SKIP_USER_MEAL_CART_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/skip_user_meal_carts`,
    params,
    id,
  });
}

export function editUserMealCart(mealPlanId, id, params = null) {
  const {
    EDITING_USER_MEAL_CART,
    EDIT_USER_MEAL_CART_SUCCESS,
    EDIT_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [EDITING_USER_MEAL_CART, EDIT_USER_MEAL_CART_SUCCESS, EDIT_USER_MEAL_CART_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/edit_user_meal_carts`,
    params,
    id,
  });
}

export function unskipUserMealCart(mealPlanId, id, params = null) {
  const {
    UNSKIPING_USER_MEAL_CART,
    UNSKIP_USER_MEAL_CART_SUCCESS,
    UNSKIP_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [UNSKIPING_USER_MEAL_CART, UNSKIP_USER_MEAL_CART_SUCCESS, UNSKIP_USER_MEAL_CART_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/unskip_user_meal_carts`,
    params,
    id,
  });
}

export function createUserMealCart(mealPlanId, params) {
  const {
    CREATING_USER_MEAL_CART,
    CREATE_USER_MEAL_CART_SUCCESS,
    CREATE_USER_MEAL_CART_FAILURE,
  } = actionTypes;

  return api.save({
    types: [
      CREATING_USER_MEAL_CART,
      CREATE_USER_MEAL_CART_SUCCESS,
      CREATE_USER_MEAL_CART_FAILURE,
    ],
    endpoint: `/meal_plans/${mealPlanId}/user_meal_carts`,
    params,
  });
}
