/* eslint-disable max-len */
import _map from 'lodash/map';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSliders } from '@fortawesome/free-solid-svg-icons';
import MenuItemContainer from '../menuItemContainer';

export default function ViewStockCountStep({ stockCounts, setStockCounts, filterCategories, onClickCategoryFilter }) {
  const [search, setSearch] = useState('');

  const onKeyPress = (event) => {
    // skip enter (45) and `-` (46) key press.
    if ([45, 46].includes(event.charCode)) event.preventDefault();
  };

  const debouncedSearch = debounce((query) => {
    setSearch(query);
  }, 800);

  const onChangeSearchInput = (evt) => {
    debouncedSearch(evt.target.value);
  };

  return (
    <div>
      <div className=" bg-white" style={{ position: 'sticky', top: 0, background: 'white', zIndex: 10 }}>
        <div className="mb-2 d-flex pt-3">
          <div className="input-group position-relative flex-grow-1 mr-1">
            <div className="position-absolute" style={{ left: 10, bottom: 5, zIndex: 100 }}>
              <FontAwesomeIcon icon={faSearch} color="#95989D" fontSize={20} />
            </div>
            <input
              placeholder="Search items..."
              type="text"
              className="form-control"
              defaultValue={search}
              onChange={event => onChangeSearchInput(event)}
              style={{ paddingLeft: '40px' }}
            />
          </div>
          <div className="pt-1" onClick={onClickCategoryFilter}>
            <FontAwesomeIcon icon={faSliders} color={filterCategories.length === 0 ? '#95989D' : '#EE8434'} fontSize={20} />
          </div>
        </div>
        <table className="table table-borderless">
          <thead className="thead-light">
            <tr>
              <th>TITLE</th>
              <th width="80px">CARTON</th>
              <th width="80px">LOOSE ITEMS</th>
            </tr>
          </thead>
        </table>
      </div>
      <table className="table table-borderless">
        <thead className="thead-light d-none">
          <tr>
            <th>TITLE</th>
            <th width="80px">CARTON</th>
            <th width="80px">LOOSE ITEMS</th>
          </tr>
        </thead>
        <tbody>
          {
            _map(stockCounts, sc => {
              if (filterCategories.length > 0 && filterCategories.indexOf(sc.category) === -1) {
                return null;
              }
              if (search && !sc.name.toLowerCase().includes(search.toLowerCase())) {
                return null;
              }

              return (
                <tr key={sc.masterSupplyItemId} className="px-2">
                  <td>
                    <div>
                      {sc.showCategory && (
                        <h6>{sc.category}</h6>
                      )}
                      <MenuItemContainer {...sc} />
                    </div>
                  </td>
                  <td style={{ minWidth: '80px', textAlign: 'center', paddingTop: sc.showCategory ? '25px' : '12px' }}>
                    <input
                      type="number"
                      min="0"
                      className="form-control d-inline text-center"
                      style={{ maxWidth: '50px', padding: '0' }}
                      aria-label="Quantity"
                      aria-describedby="button-addon3"
                      onKeyPress={onKeyPress}
                      onChange={(event) => {
                        const newStockCounts = stockCounts.map(item =>
                          (item.masterSupplyItemId === sc.masterSupplyItemId ? { ...item, carton: event.target.value } : item));
                        setStockCounts(newStockCounts);
                      }}
                      value={sc.carton}
                    />
                  </td>
                  <td style={{ minWidth: '80px', textAlign: 'center', paddingTop: sc.showCategory ? '25px' : '12px' }}>
                    <input
                      type="number"
                      min="0"
                      className="form-control d-inline text-center"
                      style={{ maxWidth: '50px', padding: '0' }}
                      aria-label="Quantity"
                      aria-describedby="button-addon3"
                      onKeyPress={onKeyPress}
                      onChange={(event) => {
                        const newStockCounts = stockCounts.map(item =>
                          (item.masterSupplyItemId === sc.masterSupplyItemId ? { ...item, looseItems: event.target.value } : item));
                        setStockCounts(newStockCounts);
                      }}
                      value={sc.looseItems}
                    />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

ViewStockCountStep.propTypes = {
  stockCounts: PropTypes.array.isRequired,
  setStockCounts: PropTypes.func.isRequired,
  filterCategories: PropTypes.array.isRequired,
  onClickCategoryFilter: PropTypes.func.isRequired,
};
