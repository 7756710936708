/* eslint max-len: 0 */

import React from 'react';

const Info = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 9.30846 2.69154 12 6.00003 12C9.30851 12 12 9.30846 12 6C12 2.69152 9.30851 0 6.00003 0C2.69154 0 0 2.69154 0 6ZM6.13759 3.3325C6.13759 2.68714 6.61051 2.25 7.19435 2.25C7.77704 2.25 8.25 2.68714 8.25 3.3325C8.25 3.97791 7.77704 4.41501 7.19435 4.41501C6.61051 4.41501 6.13759 3.97796 6.13759 3.3325ZM5.93972 9.06035C6.05313 9.0936 6.29223 8.97069 6.48477 8.79481L7.41311 7.8596C7.43043 7.96546 7.41694 8.12576 7.4064 8.25099C7.40136 8.31086 7.397 8.36271 7.397 8.39676C6.69921 9.2733 5.98907 9.94966 4.79426 9.94966C3.97858 9.83855 3.64383 9.34965 3.82078 8.85152L5.35771 5.67459C5.39464 5.57046 5.33249 5.45878 5.21878 5.42552C5.10506 5.39196 4.88294 5.51549 4.69101 5.69137L3.76206 6.62635C3.74351 6.51228 3.75056 6.34286 3.75572 6.21896C3.75763 6.17316 3.75928 6.13358 3.75928 6.10531C4.45756 5.2285 5.60385 4.53714 6.38114 4.53714C7.1212 4.60018 7.4698 5.09429 7.34223 5.63813L5.79405 8.83505C5.7735 8.93192 5.83474 9.02972 5.93972 9.06035Z"
      fill="#95989d"
    />
  </svg>
);

export default Info;
