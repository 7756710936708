/* eslint-disable camelcase */
/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import { checkAddOnValidity } from 'libs/support/addOnUtils';

import * as userMealCartActionTypes from 'packs/meals/constants/userMealCartConstants';
import * as actionTypes from '../constants/mealConstants';
import * as menuItemActionTypes from '../../common/constants/menuItemConstants';
import * as cartVariationTypes from '../../common/constants/cartVariationsConstants';
import * as cartVariationReducer from '../../common/reducers/cartVariation/cartVariationReducer';
import * as userMealCartAction from '../actions/userMealCartActions';

import { constructMealStore } from '../stores/mealStore';

export const defaultCartVariation = Immutable.fromJS({
  quantity: 0,
  variation: {},
  cart_variation_option_choices: [],
  cart_variation_item_add_on_options: [],
  cart_variation_service_add_on_options: [],
});

export const $$initialState = Immutable.fromJS({
  uuid: null,
  mealPlanId: null,
  mealPlanUserId: null,
  scheduleAt: '',
  deadlineAt: '',
  timezone: 'Asia/Singapore',
  isLoading: false,

  // Planned Meal related,
  open: false,
  overdue: false,
  confirmed: false,
  processing: false,

  // Group Order Meal related
  budget: 0,
  selected: 0,
  total: 0,

  // MealPlanUser details
  mealSkipped: false,
  mealSelected: false,
  mealAdmin: false,

  mealSelectionStatus: {},
  mealStatusSelectedTab: 'ordered', // MealStatus, selected tab

  // Group Order settings related
  groupOrderSetting: {
    showPrice: false,
    budget: '0',
    picksOneMeal: false,
    canSpendBudget: true,
  },

  // views related
  showMenuItems: false,
  showMealStatus: false,
  showScheduleModal: false,
  processingMealCheckout: false, // If the order is being processed (order now | submit button)
  mealCheckoutError: '', // For meal checkout button error, message displayed above the button, error alert
  mealCheckoutSuccesMsg: '', // For meal checkout button error, message displayed above the button, success alert

  // edit orders modal related
  showModalEditOrder: false,
  searchOrderModal: '', // Search text on top of menu item list in Edit Order Modal
  showOrderForm: false,
  showMenuItemForm: false,
  showUserSelectionForm: false,
  selectedCartVariation: defaultCartVariation,
  mealPlanUsers: [],
  selectedMenuItem: Immutable.fromJS({}),
  newAddedCartVariationId: '',
  showUserSelectionFormOnly: false, // will be true when edit assign form only
});

export default function mealReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCHING_MEAL: {
      return $$state.withMutations(state => state.set('isLoading', true));
    }
    case actionTypes.FETCH_MEAL_SUCCESS: {
      return $$state.withMutations(state => {
        state.merge(constructMealStore(response));
        state.set('isLoading', false);
      });
    }
    case actionTypes.FETCH_MEAL_FAILURE: {
      return $$state.withMutations(state => state.set('isLoading', false));
    }
    case actionTypes.DISMISS_ALERT: {
      return $$state
        .set('mealCheckoutErrorMsg', '')
        .set('mealCheckoutSuccessMsg', '');
    }
    case actionTypes.PROCESSING_CHECKOUT: {
      return $$state.set('processingMealCheckout', true);
    }
    case actionTypes.PROCESS_CHECKOUT_SUCCESS: {
      return $$state
        .set('confirmed', true)
        .set('processingMealCheckout', false)
        .set('mealCheckoutSuccessMsg', response.notice);
    }
    case actionTypes.PROCESS_CHECKOUT_FAILURE: {
      return $$state
        .set('processingMealCheckout', false)
        .set('mealCheckoutErrorMsg', response.errors);
    }
    case actionTypes.SHOW_MEAL_STATUS: {
      return $$state
        .set('showMealStatus', true);
    }
    case actionTypes.HIDE_MEAL_STATUS: {
      return $$state
        .set('showMealStatus', false);
    }
    case actionTypes.FETCH_MEAL_STATUS_SUCCESS: {
      return $$state
        .set('showMealStatus', true)
        .set('mealSelectionStatus', Immutable.fromJS(response.status));
    }
    case actionTypes.SELECT_MEAL_STATUS_TAB: {
      return $$state
        .set('mealStatusSelectedTab', response);
    }
    case actionTypes.SHOW_SCHEDULE_MODAL: {
      return $$state
        .set('showScheduleModal', true);
    }
    case actionTypes.HIDE_SCHEDULE_MODAL: {
      return $$state
        .set('showScheduleModal', false);
    }
    case actionTypes.SAVE_MEAL_SUCCESS: {
      return $$state
        .withMutations(state => state
          .set('scheduleAt', response.schedule_at)
          .set('showScheduleModal', false));
    }
    case actionTypes.TOGGLE_MENU_ITEMS: {
      return $$state
        .set('showMenuItems', !$$state.get('showMenuItems'));
    }
    case actionTypes.TOGGLE_SHOW_EDIT_ORDER_MODAL: {
      return $$state
        .withMutations(state => state
          .set('showModalEditOrder', !$$state.get('showModalEditOrder'))
          .set('showUserSelectionForm', false)
          .set('showOrderForm', false)
          .set('showMenuItemForm', false)
          .set('selectedMenuItem', Immutable.fromJS({}))
          .set('searchOrderModal', '')
          .set('selectedCartVariation', Immutable.fromJS(defaultCartVariation)));
    }
    case actionTypes.SET_SEARCH_ORDER_MODAL: {
      return $$state
        .set('searchOrderModal', response);
    }
    case actionTypes.TOGGLE_SHOW_ORDER_FORM: {
      return $$state
        .withMutations(state => state
          .set('showOrderForm', !$$state.get('showOrderForm'))
          .set('showUserSelectionForm', false)
          .set('showUserSelectionFormOnly', false)
          .set('selectedCartVariation', Immutable.fromJS(defaultCartVariation))
          .set('selectedCartUser', Immutable.fromJS({})));
    }
    case actionTypes.TOGGLE_SHOW_SELECT_USER_FORM: {
      const { cartVariation, menuItem } = response;
      return $$state
        .withMutations(state => state
          .set('showMenuItemForm', false)
          .set('showModalEditOrder', true)
          .set('showUserSelectionForm', true)
          .set('showUserSelectionFormOnly', true)
          .set('showOrderForm', true)
          .set('saveErrors', Immutable.fromJS({}))
          .update('selectedCartVariation', data => data
            .withMutations(d => d
              .set('uuid', cartVariation.get('uuid'))
              .set('addOnOptions', cartVariation.get('cartVariationItemAddOnOptions'))
              .set('variation_id', cartVariation.get('vendorId'))
              .set('additional_instructions', cartVariation.get('additional_instructions'))
              .set('quantity', cartVariation.get('quantity'))
              .set('cart_variation_option_choices', cartVariation.get('cartVariationOptionChoices'))
              .set('cart_variation_item_add_on_options', cartVariation.get('cartVariationItemAddOnOptions'))
              .set('cart_variation_service_add_on_options', cartVariation.get('cartVariationServiceAddOnOptions'))
              .set('variation', cartVariation.get('variation'))
              .set('meal_plan_user_id', cartVariation.get('mealPlanUserId'))))
          .update('selectedMenuItem', () => {
            let newData = Immutable.fromJS({
              menu_item_choices: [],
              menu_item_item_add_ons: [],
              menu_item_service_add_ons: [],
              variations: [],
              presentations: [],
              dietaries: [],
              included_items: [],
            });
            newData = newData.set('photos', menuItem.get('photos'));
            newData = newData.set('name', menuItem.get('name'));
            newData = newData.set('vendor_id', menuItem.get('vendor_id'));
            return newData;
          }));
    }
    case menuItemActionTypes.FETCH_MENU_ITEM_ORDER_SUCCESS: {
      const { menu_item, segment_track } = response;

      return $$state.withMutations(state => (
        state
          .set('showMenuItemForm', true)
          .set('showModalEditOrder', false)
          .set('selectedMenuItem', Immutable.fromJS(menu_item))
          .set('segmentTrack', Immutable.fromJS(segment_track))
          .set('selectedCartVariation', Immutable.fromJS(defaultCartVariation))
      ));
    }
    case menuItemActionTypes.RESET_SELECTED_MENU_ITEM: {
      return $$state.withMutations(state => (
        state
          .set('showModalEditOrder', true)
          .set('showMenuItemForm', false)
          .set('selectedMenuItem', Immutable.fromJS({}))
          .set('segmentTrack', Immutable.fromJS({}))
          .set('selectedCartVariation', Immutable.fromJS(defaultCartVariation))
      ));
    }
    case cartVariationTypes.SET_CART_VARIATION: {
      return $$state
        .set('showMenuItemForm', false)
        .set('showModalEditOrder', true)
        .set('showUserSelectionForm', true)
        .set('saveErrors', Immutable.fromJS({}))
        .update('selectedCartVariation', data => {
          let newData = data;
          newData = newData.set('addOnOptions', data.get('cart_variation_item_add_on_options'));
          newData = newData.set('variation_id', response.variation_id);
          newData = newData.set('additional_instructions', response.additional_instructions);
          newData = newData.set('quantity', response.quantity);
          newData = newData.set('cart_variation_option_choices', response.cart_variation_option_choices);
          newData = newData.set('cart_variation_item_add_on_options', response.cart_variation_item_add_on_options);
          newData = newData.set('cart_variation_service_add_on_options', response.cart_variation_service_add_on_options);
          return newData;
        });
    }
    case cartVariationTypes.RESET_CART_VARIATION: {
      return $$state.withMutations(state => (
        state
          .set('showMenuItemForm', true)
          .set('showModalEditOrder', false)
          .set('showUserSelectionForm', false)
          .set('selectedCartVariation', Immutable.fromJS(defaultCartVariation))
      ));
    }
    case actionTypes.CHECK_ADD_ON_VALIDITY_ORDER_FORM: {
      const defaultHash = {};
      return checkAddOnValidity(response, defaultHash, $$state);
    }
    case actionTypes.FETCH_MEAL_PLAN_USERS_SUCCESS: {
      return $$state.withMutations(state => {
        state
          .set('mealPlanUsers', response.meal_plan_users);
      });
    }
    case actionTypes.SAVING_CART_VARIATION_ORDER_FORM: {
      return $$state.withMutations(state => {
        state
          .set('saveErrors', Immutable.fromJS({}))
          .merge({ isSavingCartVariation: true });
      });
    }
    case userMealCartActionTypes.RESET_USER_MEAL_CART_SUCCESS:
    case userMealCartActionTypes.CONFIRM_USER_MEAL_CART_SUCCESS: {
      return $$state.withMutations(state => {
        state
          .set('saveErrors', Immutable.fromJS({}))
          .set('mealSelectedCount', response.meal.meal_selected_count);
      });
    }
    case actionTypes.SAVE_CART_VARIATION_ORDER_FORM_SUCCESS: {
      userMealCartAction.fetchSuccess(response);

      return $$state.withMutations(state => {
        state
          .set('saveErrors', Immutable.fromJS({}))
          .set('showMenuItemForm', false)
          .set('showModalEditOrder', true)
          .set('showOrderForm', false)
          .set('showUserSelectionForm', false)
          .set('selectedMenuItem', Immutable.fromJS({}))
          .set('segmentTrack', Immutable.fromJS({}))
          .set('selectedCartVariation', Immutable.fromJS(defaultCartVariation))
          .set('isSavingCartVariation', false)
          .set('mealSelectedCount', response.meal.meal_selected_count)
          .set('newAddedCartVariationId', response.cart_variation.uuid);
      });
    }
    case actionTypes.SAVE_CART_VARIATION_ORDER_FORM_FAILURE: {
      return $$state.withMutations(state => {
        state
          .set('saveErrors', Immutable.fromJS(response.errors))
          .set('isSavingCartVariation', false);
      });
    }
    case actionTypes.FETCH_CART_VARIATION_ORDER_FORM_SUCCESS: {
      const defaultHash = Immutable.fromJS({
        showModalEditOrder: false,
        selectedCartUser: response.cart_user,
      });
      userMealCartAction.fetchSuccess(response);

      return cartVariationReducer.fetchSuccess(response, defaultHash, $$state, true);
    }
    case actionTypes.DELETE_CART_VARIATION_ORDER_FORM_SUCCESS: {
      userMealCartAction.fetchSuccess(response);

      return $$state.withMutations(state => {
        state
          .set('mealSelectedCount', response.meal.meal_selected_count);
      });
    }
    default: {
      return $$state;
    }
  }
}
