import React from 'react';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import {
  HomeIcon,
  CutleryIcon,
  AvatarIcon,
  GroupIcon,
  ReportIcon,
  ProgramIcon,
} from 'libs/components/Icons';

const ICONS = {
  home: HomeIcon,
  meals: ProgramIcon,
  order_now: CutleryIcon,
  team: GroupIcon,
  account: AvatarIcon,
  reporting: ReportIcon,
};

const Item = ({
  code,
  isActive = false,
  path = '#',
  onClick,
  translate,
}) => {
  const Icon = ICONS[code];
  const itemClassName = classnames(
    `mobile-nav__item mobile-nav__item-${code}`,
    {
      'mobile-nav__item-active': isActive,
    },
  );

  return (
    <a
      href={isActive ? '#' : path}
      onClick={onClick}
      className={itemClassName}
    >
      <div className="mobile-nav__icon">
        <Icon fill={isActive ? 'lima' : 'grey-chateau'} />
      </div>

      { translate(`nav${uppercamelcase(code)}`) }
    </a>
  );
};

Item.propTypes = {
  code: string.isRequired,
  isActive: bool.isRequired,
  onClick: func,
  path: string,
  translate: func.isRequired,
};

export default withI18n(Item);
