/* eslint-disable max-len */
import React from 'react';

const NutFree = (_props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z" fill="#5271FF" />
    <path d="M12.375 18H10.6582L6.44531 11.1738H6.39844L6.42773 11.5547C6.48242 12.2812 6.50977 12.9453 6.50977 13.5469V18H5.23828V9.43359H6.9375L11.1387 16.2246H11.1738C11.166 16.1348 11.1504 15.8086 11.127 15.2461C11.1035 14.6797 11.0918 14.2383 11.0918 13.9219V9.43359H12.375V18ZM16.0254 18H14.6367V9.43359H19.4707V10.6172H16.0254V13.2949H19.2539V14.4844H16.0254V18Z" fill="white" />
  </svg>
);

export default NutFree;
