/* eslint max-len: 0 */

import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/mealConstants';
import * as cartVariationTypes from '../../common/constants/cartVariationsConstants';

/*
 * Fetch meal
 */
export function fetchMeal(mealPlanId, mealId, params) {
  const { FETCHING_MEAL, FETCH_MEAL_SUCCESS, FETCH_MEAL_FAILURE } = actionTypes;

  return api.get({
    types: [FETCHING_MEAL, FETCH_MEAL_SUCCESS, FETCH_MEAL_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/meals/${mealId}`,
    params,
  });
}

/*
 * Fetch meal orderables
 */
export function fetchMealOrderables(mealPlanId, mealId, params) {
  const {
    FETCHING_MEAL_ORDERABLES,
    FETCH_MEAL_ORDERABLES_SUCCESS,
    FETCH_MEAL_ORDERABLES_FAILURE,
  } = actionTypes;

  return api.get({
    types: [FETCHING_MEAL_ORDERABLES, FETCH_MEAL_ORDERABLES_SUCCESS, FETCH_MEAL_ORDERABLES_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/meals/${mealId}/orderables`,
    params,
  });
}

/*
 * Checkout meal
 */
export function mealCheckout(mealPlanId, mealId) {
  const { PROCESSING_CHECKOUT, PROCESS_CHECKOUT_SUCCESS, PROCESS_CHECKOUT_FAILURE } = actionTypes;

  return api.create({
    types: [PROCESSING_CHECKOUT, PROCESS_CHECKOUT_SUCCESS, PROCESS_CHECKOUT_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/meals/${mealId}/checkout`,
    params: {},
  });
}

/*
 * Dismiss any alert
 */
export function dismissAlert() {
  return {
    type: actionTypes.DISMISS_ALERT,
  };
}

/*
 * Show Schedule Modal
 */
export function showScheduleModal() {
  return {
    type: actionTypes.SHOW_SCHEDULE_MODAL,
  };
}

/*
 * Hide Schedule Modal
 */
export function hideScheduleModal() {
  return {
    type: actionTypes.HIDE_SCHEDULE_MODAL,
  };
}

export function saveMeal(mealPlanId, id, params) {
  const { SAVING_MEAL, SAVE_MEAL_SUCCESS, SAVE_MEAL_FAILURE } = actionTypes;

  return api.save({
    types: [SAVING_MEAL, SAVE_MEAL_SUCCESS, SAVE_MEAL_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/meals/`,
    params,
    id,
  });
}

/*
 * Show Meal Status
 */
export function showMealStatus() {
  return {
    type: actionTypes.SHOW_MEAL_STATUS,
  };
}

/*
 * Hide Meal Status
 */
export function hideMealStatus() {
  return {
    type: actionTypes.HIDE_MEAL_STATUS,
  };
}

/*
 * MealStatus Modal
 */
export function fetchMealStatus(mealPlanId, mealId) {
  const { FETCHING_MEAL_STATUS, FETCH_MEAL_STATUS_SUCCESS, FETCH_MEAL_STATUS_FAILURE } = actionTypes;

  return api.get({
    types: [FETCHING_MEAL_STATUS, FETCH_MEAL_STATUS_SUCCESS, FETCH_MEAL_STATUS_FAILURE],
    endpoint: `/meal_plans/${mealPlanId}/meals/${mealId}/status`,
    params: { meal_id: mealId },
  });
}

/*
 * selectMealStatusTab
 */
export function selectMealStatusTab(tabName) {
  return {
    type: actionTypes.SELECT_MEAL_STATUS_TAB,
    response: tabName,
  };
}

/*
 * Toggle Menu Items
 */
export function toggleMenuItems() {
  return {
    type: actionTypes.TOGGLE_MENU_ITEMS,
  };
}


/*
 * Toggle Edit Order Modal
 */
export function toggleShowEditOrderModal() {
  return {
    type: actionTypes.TOGGLE_SHOW_EDIT_ORDER_MODAL,
  };
}

/*
 * Set search text input on Edit Order Modal
 */
export function setSearchOrderModal(text) {
  return {
    type: actionTypes.SET_SEARCH_ORDER_MODAL,
    response: text,
  };
}

/*
 * Toggle Show Order Form
 */
export function toggleShowOrderForm() {
  return {
    type: actionTypes.TOGGLE_SHOW_ORDER_FORM,
  };
}

/*
 * Set Selected Cart Variation to State
 */
export function setCartVariation(cartVariation) {
  return {
    type: cartVariationTypes.SET_CART_VARIATION,
    response: cartVariation,
  };
}

/*
 * Reset Selected Cart Variation to State
 */
export function resetCartVariation() {
  return {
    type: cartVariationTypes.RESET_CART_VARIATION,
  };
}

/*
 * Set Selected Cart Variation to State
 */
export function toggleShowSelectUserForm(params) {
  return {
    type: actionTypes.TOGGLE_SHOW_SELECT_USER_FORM,
    response: params,
  };
}
/*
 * Check Add on Validity Order form
 */
export function checkAddOnValidityOrderForm(response) {
  return { type: actionTypes.CHECK_ADD_ON_VALIDITY_ORDER_FORM, response };
}

/*
 * Check Add on Validity Order form
 */
export const getMealPlanUsers = (mealPlanId) => api.get({
  types: [actionTypes.FETCHING_MEAL_PLAN_USERS, actionTypes.FETCH_MEAL_PLAN_USERS_SUCCESS, actionTypes.FETCH_MEAL_PLAN_USERS_FAILURE],
  endpoint: `/meal_plans/${mealPlanId}/meal_plan_users`,
});

/*
 * Save Cart Variation Admin Form
 */
export const saveCartVariationOrderForm = (mealPlanId, id, params) => {
  const types = [
    actionTypes.SAVING_CART_VARIATION_ORDER_FORM,
    actionTypes.SAVE_CART_VARIATION_ORDER_FORM_SUCCESS,
    actionTypes.SAVE_CART_VARIATION_ORDER_FORM_FAILURE,
  ];

  return api.save({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params,
    id,
  });
};

export function fetchCartVariation(mealPlanId, id) {
  const types = [
    actionTypes.FETCHING_CART_VARIATION,
    actionTypes.FETCH_CART_VARIATION_ORDER_FORM_SUCCESS,
    actionTypes.FETCH_CART_VARIATION_ORDER_FORM_FAILURE,
  ];

  return api.get({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params: { fetch_cart_user: true },
    id,
  });
}

export function deleteCartVariation(mealPlanId, id, params = null) {
  const types = [
    actionTypes.DELETING_CART_VARIATION,
    actionTypes.DELETE_CART_VARIATION_ORDER_FORM_SUCCESS,
    actionTypes.DELETE_CART_VARIATION_ORDER_FORM_FAILURE,
  ];

  return api.destroy({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params,
    id,
  });
}

export function updateHeadcount(mealPlanId, mealId, plannedMealSettingId, headcount) {
  return saveMeal(mealPlanId, mealId, {
    meal: {
      planned_meal_setting_attributes: {
        id: plannedMealSettingId,
        headcount,
      },
    },
  });
}
