/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint arrow-body-style: 0 */

import React from 'react';
import { bool, func, number } from 'prop-types';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { formattedPrice } from 'libs/support/formattedPrice';
import AdminMealCart from './AdminMealCart/AdminMealCart';
import UserMealCartContainer from '../../containers/GroupOrderMeal/UserMealCartContainer';

let userMealCartRef = null;
let adminMealCartRef = null;
const OPEN_CLASS = 'responsive-meal-cart__overlay--open';

const toggleUserMealCart = (e) => {
  e.preventDefault();

  if (userMealCartRef.classList.contains(OPEN_CLASS)) {
    userMealCartRef.classList.remove(OPEN_CLASS);
  } else {
    userMealCartRef.classList.add(OPEN_CLASS);
  }
};

const toggleAdminMealCart = (e) => {
  e.preventDefault();

  if (adminMealCartRef.classList.contains(OPEN_CLASS)) {
    adminMealCartRef.classList.remove(OPEN_CLASS);

    if ($('.modal-backdrop').length) {
      $('.modal-backdrop').remove();
    }
  } else {
    adminMealCartRef.classList.add(OPEN_CLASS);
  }
};

const cartPrice = ({ showPrice, totalAmount }) => (
  showPrice ? ` ${formattedPrice(totalAmount)}` : ''
);

const cartInfo = ({
  showPrice,
  totalAmount,
  totalQuantity,
  translate,
}) => (
  totalQuantity > 0 ? ` | ${translate('groupedOrdersCartInfo', { quantity: totalQuantity })}${cartPrice({ showPrice, totalAmount })}` : ''
);

const ResponsiveMealCart = ({
  mealAdmin,
  mealSkipped,
  showPrice,
  totalAmount,
  totalQuantity,
  userRoleIn,
  translate,
  isInPreview,
}) => {
  const showUserMealCart = !mealSkipped && !userRoleIn(['osc']);
  return (
    <div className="col-12 col-lg-4 responsive-meal-cart">
      <div
        className="responsive-meal-cart__overlay"
        ref={node => userMealCartRef = node}
      >
        <div className="responsive-meal-cart__content">
          {
            showUserMealCart &&
              <UserMealCartContainer
                closeModal={toggleUserMealCart}
              />
          }

          <div className="responsive-meal-cart__spacer" />
        </div>
      </div>

      {
        mealAdmin &&
          <div
            className="responsive-meal-cart__overlay"
            ref={node => adminMealCartRef = node}
          >
            <div className="responsive-meal-cart__content">
              <AdminMealCart
                closeModal={toggleAdminMealCart}
                mealAdmin={mealAdmin}
              />

              <div className="responsive-meal-cart__spacer" />
            </div>
          </div>
      }

      <div className="responsive-meal-cart__actions d-lg-none">
        <div className="responsive-meal-cart__actions-wrapper">
          {
            isInPreview ? (
              <button
                className="btn btn-light btn-lg col responsive-meal-cart__button"
                disabled
              >
                Meal in Preview
              </button>
            ) : (
              <>
                {
                  mealAdmin &&
                    <button
                      className="btn btn-light btn-lg col responsive-meal-cart__button"
                      onClick={toggleAdminMealCart}
                    >
                      View All Orders
                    </button>
                }

                {
                  showUserMealCart &&
                    <button
                      className="btn btn-primary btn-lg col responsive-meal-cart__button meal-confirmation--mobile"
                      onClick={toggleUserMealCart}
                    >
                      {
                        `${translate('groupedOrdersActionsViewMeal')}${
                          cartInfo({
                            showPrice,
                            totalAmount,
                            totalQuantity,
                            translate,
                          })
                        }`
                      }
                    </button>
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

ResponsiveMealCart.propTypes = {
  mealAdmin: bool.isRequired,
  mealSkipped: bool.isRequired,
  showPrice: bool.isRequired,
  totalAmount: number,
  totalQuantity: number,
  userRoleIn: func.isRequired,
  isInPreview: bool.isRequired,
  translate: func.isRequired,
};

export default withAppContext(withI18n(ResponsiveMealCart));
