import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { QuantityInput } from './quantityInput';
import { DeliveryAt, VendorNameContainer } from '../styledComponents';
import MenuItemContainer from '../menuItemContainer';

const ItemRow = styled.tr`
  background-color: ${props => (props.checked ? '#FCFFF3' : '#FFFFFF')};
`;

export function ViewOrderStep({
  order,
  deliveryReports,
  setDeliveryReports,
}) {
  return (
    <div>
      <div className=" bg-white" style={{ position: 'sticky', top: 0, background: 'white', zIndex: 10 }}>
        <div className="pt-3">
          <span className="text-muted">Order Details</span>
        </div>

        <VendorNameContainer>
          {order.vendorName}
        </VendorNameContainer>

        <DeliveryAt date={order.deliverAt} dateTo={order.endDeliverAt} />

        <p className="text-muted">
          The order details are listed below. Tick and enter the quantity of the items that has been delivered.
        </p>

        <div>
          <b>Total Quantity</b>
        </div>

        <table className="table w-100 m-0">
          <thead className="thead-light">
            <tr>
              <th>
                <div className="form-check">
                  <input
                    className="form-check-input position-static"
                    type="checkbox"
                    id="checkAll"
                    onChange={event => {
                      setDeliveryReports(deliveryReports.map(variation => ({
                        ...variation,
                        checked: event.target.checked,
                      })));
                    }}
                  />
                </div>
              </th>
              <th>TITLE</th>
              <th width="120px">QTY</th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="">
        <table className="table w-100 m-0">
          <thead className="thead-light d-none">
            <tr>
              <th />
              <th>TITLE</th>
              <th width="120px">QTY</th>
            </tr>
          </thead>
          <tbody>
            {
              deliveryReports.map((dr) => (
                <Fragment key={`row-${dr.orderVariationId}`}>
                  { dr.showCategory &&
                  <tr key={dr.categoryName} className="row-category">
                    <td colSpan="3">
                      <h6 className="m-0">{dr.categoryName}</h6>
                    </td>
                  </tr>
                  }
                  <ItemRow
                    className="row-item"
                    key={dr.orderVariationId}
                    checked={dr.checked}
                  >
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input position-static"
                          type="checkbox"
                          id="blankCheckbox"
                          checked={!!dr.checked}
                          onChange={event => {
                            // eslint-disable-next-line no-param-reassign
                            dr.checked = event.target.checked;
                            setDeliveryReports([...deliveryReports]);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <MenuItemContainer {...dr} />
                    </td>
                    <td className="!tw-pl-0">
                      <QuantityInput
                        value={dr.deliveredQuantity}
                        onChange={(value) => {
                          if (value >= 0) {
                            // eslint-disable-next-line no-param-reassign
                            dr.deliveredQuantity = value;
                            setDeliveryReports([...deliveryReports]);
                          }
                        }}
                        disabled={!dr.checked}
                      />
                    </td>
                  </ItemRow>
                </Fragment>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

ViewOrderStep.propTypes = {
  order: PropTypes.object.isRequired,
  deliveryReports: PropTypes.array.isRequired,
  setDeliveryReports: PropTypes.func.isRequired,
};
