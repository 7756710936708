/* eslint max-len: 0 */

import React from 'react';

const Nomeal = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="42" viewBox="0 0 46 42" fill="none">
    <g opacity="0.4">
      <path clipRule="evenodd" d="M2 21C2 10.5066 10.5066 2 21 2C29.7622 2 37.1409 7.93222 39.3355 16H36.3655C35.2679 16 34.6204 17.2676 35.2422 18.1989L39.3767 24.3915C39.9183 25.2028 41.0817 25.2028 41.6233 24.3915L45.7578 18.1989C46.3796 17.2676 45.7321 16 44.6345 16H41.4009C39.1574 6.81608 30.8757 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C21.5523 42 22 41.5523 22 41C22 40.4477 21.5523 40 21 40C10.5066 40 2 31.4934 2 21ZM40.0904 22.4148C40.2894 22.6992 40.7106 22.6992 40.9096 22.4148L43.4493 18.7867C43.6813 18.4553 43.4442 18 43.0397 18H37.9603C37.5558 18 37.3187 18.4553 37.5507 18.7867L40.0904 22.4148Z" fill="#141415" fillRule="evenodd" />
      <path clipRule="evenodd" d="M12 11C12.5523 11 13 11.4477 13 12V18C13 18.5523 13.4477 19 14 19V12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12V19C16.5523 19 17 18.5523 17 18V12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12V18C19 19.6569 17.6569 21 16 21V30C16 30.5523 15.5523 31 15 31C14.4477 31 14 30.5523 14 30V21C12.3431 21 11 19.6569 11 18V12C11 11.4477 11.4477 11 12 11ZM26 13C25.4477 13 25 13.4477 25 14V18C25 18.5523 25.4477 19 26 19H28C28.5523 19 29 18.5523 29 18V14C29 13.4477 28.5523 13 28 13H26ZM23 14C23 12.3431 24.3431 11 26 11H28C29.6569 11 31 12.3431 31 14V18C31 19.6569 29.6569 21 28 21V30C28 30.5523 27.5523 31 27 31C26.4477 31 26 30.5523 26 30V21C24.3431 21 23 19.6569 23 18V14Z" fill="#141415" fillRule="evenodd" />
    </g>
  </svg>
);

export default Nomeal;
