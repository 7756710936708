/* eslint max-len: 0 */

import React from 'react';

const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3822 4.75699L6.77508 12.3641L2.04297 7.63199L3.45718 6.21777L6.77507 9.53567L12.968 3.34277L14.3822 4.75699Z" fill="white" />
  </svg>
);

export default Check;
