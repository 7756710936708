/* eslint-disable max-len */
import { number } from 'prop-types';
import React from 'react';

const ChevronLeft = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3538 12.6465C10.4002 12.693 10.4371 12.7481 10.4622 12.8088C10.4874 12.8695 10.5003 12.9346 10.5003 13.0003C10.5003 13.066 10.4874 13.131 10.4622 13.1917C10.4371 13.2524 10.4002 13.3076 10.3538 13.354C10.3073 13.4005 10.2522 13.4373 10.1915 13.4625C10.1308 13.4876 10.0657 13.5006 10 13.5006C9.93433 13.5006 9.86928 13.4876 9.80858 13.4625C9.74788 13.4373 9.69273 13.4005 9.64628 13.354L4.64628 8.35403C4.59979 8.30759 4.56291 8.25245 4.53775 8.19175C4.51259 8.13105 4.49963 8.06599 4.49963 8.00028C4.49963 7.93457 4.51259 7.86951 4.53775 7.80881C4.56291 7.74811 4.59979 7.69296 4.64628 7.64653L9.64628 2.64653C9.7401 2.55271 9.86735 2.5 10 2.5C10.1327 2.5 10.26 2.55271 10.3538 2.64653C10.4476 2.74035 10.5003 2.8676 10.5003 3.00028C10.5003 3.13296 10.4476 3.26021 10.3538 3.35403L5.7069 8.00028L10.3538 12.6465Z" fill="#373A3C" />
  </svg>

);

ChevronLeft.propTypes = {
  size: number,
};

export default ChevronLeft;
