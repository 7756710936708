/* eslint-disable max-len */
import React from 'react';
import uppercamelcase from 'uppercamelcase';
import { func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { BellIcon, EmailIcon, MessageIcon, ChevronLeftIcon } from 'libs/components/Icons';
import { isChannelHidden } from '../utils';
import { disabledOptions } from '../constants';
import useUpdateSettingsMutation from '../../queries/useUpdateSettings';
import {
  Description,
  HorizontalRule,
  SubSettingTitle,
  HintText,
  SettingItem,
  StyledToggle,
  SettingTitle,
  Header,
  HeaderTitle,
  BackIcon,
} from '../../styles/mobile';

const NotificationDetails = ({ goBack, groupName, settingName, updateSetting, currentSettings, translate }) => {
  const mutation = useUpdateSettingsMutation();

  const handleToggleChange = (key, currentValue) => {
    updateSetting(key, currentValue);
    const updatedData = {
      [`${key}`]: currentValue,
    };
    mutation.mutate(updatedData);
  };
  return (
    <div>
      <Header>
        <BackIcon onClick={goBack}><ChevronLeftIcon /></BackIcon>
        <HeaderTitle>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(groupName)}Title`)}</HeaderTitle>
      </Header>
      <SubSettingTitle>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(settingName)}Title`)}</SubSettingTitle>
      <Description style={{ margin: '4px 0' }}>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(settingName)}Description`)}</Description>
      <HorizontalRule style={{ marginBottom: '16px' }} />
      <HintText>{translate('accountSettingsNotificationSettingsLabelReceiveTheseNotificationsVia')}</HintText>
      {
        !isChannelHidden(groupName, 'push') &&
        <SettingItem>
          <SettingTitle>
            <BellIcon />
            <span>{ translate('accountSettingsNotificationSettingsChannelsPush') }</span>
          </SettingTitle>
          <StyledToggle onChange={e => handleToggleChange(`${settingName}_push`, e.target.checked)} defaultChecked={currentSettings[`${settingName}_push`]} disabled={disabledOptions.includes(`${settingName}_push`)} icons={!disabledOptions.includes(`${settingName}_push`)} />
        </SettingItem>
      }
      {
        !isChannelHidden(groupName, 'email') &&
        <SettingItem>
          <SettingTitle>
            <EmailIcon />
            <span>{ translate('accountSettingsNotificationSettingsChannelsEmail') }</span>
          </SettingTitle>
          <StyledToggle onChange={e => handleToggleChange(`${settingName}_email`, e.target.checked)} defaultChecked={currentSettings[`${settingName}_email`]} disabled={disabledOptions.includes(`${settingName}_email`)} icons={!disabledOptions.includes(`${settingName}_email`)} />
        </SettingItem>
      }
      {
        !isChannelHidden(groupName, 'sms') &&
        <SettingItem>
          <SettingTitle>
            <MessageIcon />
            <span>{ translate('accountSettingsNotificationSettingsChannelsSms') }</span>
          </SettingTitle>
          <StyledToggle onChange={e => handleToggleChange(`${settingName}_sms`, e.target.checked)} defaultChecked={currentSettings[`${settingName}_sms`]} disabled={disabledOptions.includes(`${settingName}_sms`)} icons={!disabledOptions.includes(`${settingName}_sms`)} />
        </SettingItem>
      }
    </div>
  );
};

NotificationDetails.propTypes = {
  goBack: func,
  groupName: string,
  settingName: string,
  updateSetting: func,
  currentSettings: object,
  translate: func,
};

export default withI18n(NotificationDetails);
