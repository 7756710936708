/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

const EditIcon = ({ color = '#141415' }) => (
  <svg className={classNames('!tw-fill-none', `hover:tw-fill-[${color}]`)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.9165 17.5H17.9165" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.58301 11.1333V14.1667H7.63176L14.7288 7.06658C15.5688 6.22613 15.5688 4.86388 14.7287 4.02344V4.02344C13.8879 3.18227 12.5242 3.18254 11.6837 4.02404L4.58301 11.1333Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
  </svg>

);

EditIcon.propTypes = {
  color: string,
};

export default EditIcon;
