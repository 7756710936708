/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';
import * as mealActionTypes from 'packs/meals/constants/mealConstants';
import * as actionTypes from '../constants/customMenuItemConstants';

import { groupMenuItemsById, groupMenuItemsByVendor } from '../stores/mealStore';

export const $$initialState = Immutable.fromJS({
  byVendorId: [],
  byId: [],
  totalQuantity: 0,
});

export default function customMenuItemsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case mealActionTypes.FETCH_MEAL_SUCCESS: {
      return $$state.withMutations(state => {
        state.merge({
          byId: groupMenuItemsById(response.custom_menu_items),
          byVendorId: groupMenuItemsByVendor(response.custom_menu_items),
          totalQuantity: response.custom_menu_items_total_quantity,
        });
      });
    }
    case actionTypes.SAVING_CUSTOM_MENU_ITEM: {
      return $$state;
    }
    case actionTypes.SAVE_CUSTOM_MENU_ITEM_SUCCESS: {
      const { custom_menu_item } = response;
      const { vendor_id } = custom_menu_item;

      const newData = Immutable.fromJS(custom_menu_item);

      return $$state.withMutations(state => {
        state.updateIn(['byVendorId', vendor_id], customMenuItems => {
          const index = customMenuItems.findLastIndex(data => data.get('uuid') === custom_menu_item.uuid);

          if (index >= 0) return customMenuItems.update(index, () => newData);

          return customMenuItems;
        })
          .merge({
            totalQuantity: state
              .get('byVendorId')
              .toList()
              .map(vendor => vendor.reduce((total, item) => (total + item.get('quantity')), 0))
              .first(),
          });
      });
    }

    case actionTypes.SAVE_CUSTOM_MENU_ITEM_FAILURE: {
      return $$state;
    }
    default: {
      return $$state;
    }
  }
}
