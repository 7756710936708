import { sectionsByRole, hiddenChannelsByGroup } from './constants';

const isChannelHidden = (groupName, channel) => hiddenChannelsByGroup[groupName]?.includes(channel);

const restructureSettings = (role) => {
  const sections = sectionsByRole[role];

  const result = Object.entries(sections).map(([section, groups]) => ({
    section,
    groups: Object.entries(groups).map(([title, notifications]) => ({
      name: title,
      subNotifications: notifications.map(key => ({
        name: key,
      })),
    })),
  }));

  return result;
};

export { isChannelHidden, restructureSettings };
