/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';

export const $$initialState = Immutable.fromJS({
  allIds: [],
  byId: {},
});

export default function vendorsReducer($$state = $$initialState, action = null) {
  const { type } = action;

  switch (type) {
    default: {
      return $$state;
    }
  }
}
