import React from 'react';
import Intl from 'intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import {
  bool,
  string,
  object,
  func,
} from 'prop-types';

import BaseComponent from 'libs/components/BaseComponent';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

import * as mealActions from '../actions/mealActions';
import * as mealReportActions from '../actions/mealReportActions';

import MealStatus from '../components/GroupOrderMeal/MealStatus/MealStatus';

global.Intl = Intl;

const mapStateToProps = state => {
  const {
    $$mealStore,
  } = state;

  return {
    hide: !state.$$mealStore.get('showMealStatus'),
    mealAdmin: $$mealStore.get('mealAdmin'),
    mealPlanId: $$mealStore.get('mealPlanId'),
    mealId: $$mealStore.get('uuid'),
    mealPlanUserId: $$mealStore.get('mealPlanUserId'),
    selectedTab: state.$$mealStore.get('mealStatusSelectedTab'),
    mealSelectionStatus: $$mealStore.get('mealSelectionStatus'),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...mealActions,
  ...mealReportActions,
}, dispatch);

class MealStatusContainer extends BaseComponent {
  static propTypes = {
    hide: bool,
    mealAdmin: bool,
    mealPlanId: string.isRequired,
    mealId: string.isRequired,
    mealPlanUserId: string.isRequired,
    selectedTab: string.isRequired,
    link: string,
    mealSelectionStatus: object.isRequired,
    fetchMealReport: func.isRequired,
    hideMealStatus: func.isRequired,
    selectMealStatusTab: func.isRequired,
  };

  render = () => {
    const {
      hide,
      mealAdmin,
      mealPlanId,
      mealId,
      mealPlanUserId,
      link,
      selectedTab,
      mealSelectionStatus,
      fetchMealReport,
      hideMealStatus,
      selectMealStatusTab,
    } = this.props;

    const locale = defaultLocale;
    const messages = translations[locale];

    return (
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
      >
        <MealStatus
          {
            ...{
              hide,
              mealAdmin,
              mealId,
              mealPlanId,
              mealPlanUserId,
              selectedTab,
              link,
              mealSelectionStatus,
              onFetchMealReport: fetchMealReport,
              onHide: hideMealStatus,
              onSelectTab: selectMealStatusTab,
            }
          }
        />
      </IntlProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MealStatusContainer);
