import React, { Fragment, useState } from 'react';
import { any, func, object } from 'prop-types';
import moment from 'moment';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { fetchPdfLink } from 'libs/services/labelsApi';
import { createMealReport } from 'libs/services/mealReportsApi';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardFooter from './CardFooter';

const GroupOrderCardMeal = ({
  meal,
  userMealOrderable,
  currentMealPlanUser,
  userRoleIn,
  isLoading,
  translate,
}) => {
  const [state, setState] = useState({
    isProcessing: false,
    downloadableLink: null,
    errorMsg: null,
  });

  const handleOnClickDownloadExcel = () => {
    if (state.isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    const mealId = meal.get('uuid');
    const mealPlanUserId = currentMealPlanUser.uuid;

    const params = {
      meal_report: {
        meal_plan_user_id: mealPlanUserId,
        meal_id: mealId,
      },
    };

    createMealReport(
      params,
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  const handleOnClickDownloadMealLabelPdf = (evt) => {
    if (state.isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    evt.preventDefault();

    const mealId = meal.get('uuid');
    const mealPlanId = meal.get('mealPlanId');
    const type = 'meal_labels';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  const handleOnClickDownloadMenuCardPdf = () => {
    if (state.isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    setState(prev => ({ ...prev, isProcessing: true, downloadableLink: null, errorMsg: null }));

    const mealId = meal.get('uuid');
    const mealPlanId = meal.get('mealPlanId');
    const type = 'menu_cards';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        setState(prev => ({ ...prev, isProcessing: false, downloadableLink: res.link }));
      },
      (err) => {
        setState(prev => ({ ...prev, isProcessing: false, errorMsg: err }));
      },
    );
  };

  const buildSuccessMsg = () => {
    if (state.isProcessing) {
      return translate('groupOrderCardsProcessing');
    } else if (state.downloadableLink) {
      return translate('groupOrderCardsDownload', { link: state.downloadableLink });
    }
    return '';
  };

  return (
    <Fragment>
      <CardHeader
        {...{
          meal,
          userRoleIn,
          isLoading,
          onClickDownloadExcel: handleOnClickDownloadExcel,
          onClickDownloadMealLabelPdf: handleOnClickDownloadMealLabelPdf,
          onClickDownloadMenuCardPdf: handleOnClickDownloadMenuCardPdf,
        }}
      />
      <CardBody
        {
          ...{
            meal,
            userMealOrderable,
            isLoading,
            errorMsg: state.errorMsg,
            successMsg: buildSuccessMsg(),
          }
        }
      />
      <CardFooter
        {
          ...{
            meal,
            userMealOrderable,
            isLoading,
            onClickDownloadExcel: handleOnClickDownloadExcel,
            onClickDownloadMealLabelPdf: handleOnClickDownloadMealLabelPdf,
            onClickDownloadMenuCardPdf: handleOnClickDownloadMenuCardPdf,
          }
        }
      />
    </Fragment>
  );
};

GroupOrderCardMeal.propTypes = {
  meal: object.isRequired,
  userMealOrderable: object.isRequired,
  currentMealPlanUser: object.isRequired,
  userRoleIn: any,
  isLoading: any,
  translate: func,
};

export default withAppContext(withI18n(GroupOrderCardMeal));
