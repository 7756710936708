/* eslint max-len: 0 */

import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/cartVariationsConstants';
import * as addOnActionTypes from '../constants/addOnConstants';

export function checkAddOnValidity(response) {
  return { type: actionTypes.CHECK_ADD_ON_VALIDITY, response };
}

export function selectCartVariation(response) {
  return { type: actionTypes.SELECT_VARIATION, response };
}

export function fetchCartVariation(mealPlanId, id) {
  const types = [
    actionTypes.FETCHING_CART_VARIATION,
    actionTypes.FETCH_CART_VARIATION_SUCCESS,
    actionTypes.FETCH_CART_VARIATION_FAILURE,
  ];

  return api.get({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params: null,
    id,
  });
}

export function saveCartVariation(mealPlanId, id, params) {
  const types = [
    actionTypes.SAVING_CART_VARIATION,
    actionTypes.SAVE_CART_VARIATION_SUCCESS,
    actionTypes.SAVE_CART_VARIATION_FAILURE,
  ];

  return api.save({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params,
    id,
  });
}

export function deleteCartVariation(mealPlanId, id, params = null) {
  const types = [
    actionTypes.DELETING_CART_VARIATION,
    actionTypes.DELETE_CART_VARIATION_SUCCESS,
    actionTypes.DELETE_CART_VARIATION_FAILURE,
  ];

  return api.destroy({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params,
    id,
  });
}

export function updateQuantityCartVariation(mealPlanId, id, quantity, cartVariation) {
  const types = [
    actionTypes.UPDATING_QUANTITY_CART_VARIATION,
    actionTypes.UPDATE_QUANTITY_CART_VARIATION_SUCCESS,
    actionTypes.UPDATE_QUANTITY_CART_VARIATION_FAILURE,
  ];

  return api.save({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params: {
      cart_variation: {
        quantity,
        cart_variation_service_add_on_options: cartVariation.get('cart_variation_service_add_on_options').reduce((a, v) => ({ ...a, [v.get('uuid')]: { 0: v } }), []),
        cart_variation_item_add_on_options: cartVariation.get('cart_variation_item_add_on_options').reduce((a, v) => ({ ...a, [v.get('uuid')]: { 0: v } }), []),
      },
    },
    id,
  });
}

export function resetQuantityCartVariation(mealPlanId, id, quantity, _cartVariation) {
  const types = [
    actionTypes.RESET_QUANTITY_CART_VARIATION,
    actionTypes.RESET_QUANTITY_CART_VARIATION_SUCCESS,
    actionTypes.RESET_QUANTITY_CART_VARIATION_FAILURE,
  ];

  return api.save({
    types,
    endpoint: `/meal_plans/${mealPlanId}/cart_variations`,
    params: {
      cart_variation: {
        quantity,
        cart_variation_item_add_on_options: [],
        cart_variation_option_choices: [],
        cart_variation_service_add_on_options: [],
      },
    },
    id,
  });
}

export function addTemporaryItemAddOnOptionPhoto(params) {
  const response = { params };
  return { type: addOnActionTypes.ADD_TEMPORARY_ITEM_ADD_ON_OPTION_PHOTO, response };
}

export function addTemporaryItemAddOnOptionPhotoFailure(response) {
  return { type: addOnActionTypes.ADD_TEMPORARY_ITEM_ADD_ON_OPTION_PHOTO_FAILURE, response };
}

export function deleteItemAddOnOptionPhoto(details) {
  const response = { details };
  return { type: addOnActionTypes.DELETE_ITEM_ADD_ON_OPTION_PHOTO, response };
}

export function updateItemAddOnOptionText(params) {
  const response = params;
  return { type: addOnActionTypes.UPDATE_ITEM_ADD_ON_OPTION_TEXT, response };
}
