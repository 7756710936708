/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { Fragment, useLayoutEffect } from 'react';
import { func, bool, object } from 'prop-types';
import Immutable from 'immutable';
import { useBeforeunload } from 'react-beforeunload';
import classNames from 'classnames';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { statuses } from 'packs/meals/reducers/userMealCartReducer';
import MealStatusContainer from 'packs/meals/containers/MealStatusContainer';
import MenuItemsContainer from 'packs/meals/containers/GroupOrderMeal/MenuItemsContainer';
import SelectedMenuItemsContainer from 'packs/meals/containers/GroupOrderMeal/SelectedMenuItemsContainer';
import MenuItemForm from 'packs/common/components/MenuItem/MenuItemForm/MenuItemForm';
import { MEAL_STATE } from 'packs/meals/constants/mealConstants';

import EditingIndicator from '../EditingIndicator';
import Header from '../Header/Header';

import Flash from '../Flash';
import ResponsiveMealCart from '../ResponsiveMealCart';
import MealConfirmationGuidedInfo from '../MealConfirmationGuidedInfo';


/**
 * GroupOrderMeal
 */
const GroupOrderMeal = ({
  actions,
  cartVariationsStore,
  cartsStore,
  mealStore,
  userMealOrderable,
  vendorsStore,
  userRoleIn,
  translate,
  v2Enabled,
}) => {
  /* TODO: Update this */
  const isNonZeroVat = false;

  const isMealConfirmed = mealStore.get('confirmed');
  const isMealPreview = mealStore.get('preview');

  /* For MenuItemForm */
  const isGroupOrder = true;
  const selectedMenuItem = cartVariationsStore.get('selectedMenuItem');
  const selectedCartVariation = cartVariationsStore.get('selectedCartVariation');
  const catererId = selectedMenuItem.getIn(['vendor_id']);
  const cartId = cartsStore.getIn(['byVendorId', catererId, 'cartId']);
  const isEditable = !userRoleIn(['osc']) &&
                     !isMealConfirmed &&
                     !isMealPreview &&
                     userMealOrderable.get('currentState') !== MEAL_STATE.confirmed;
  const mealAdmin = userRoleIn(['osc', 'admin']);
  const vendorSetting = vendorsStore.getIn(['byId', catererId, 'vendorSetting']) || Immutable.fromJS({});
  const userTotalQuantity = (userMealOrderable.get('cartVariations') || userMealOrderable.get('orderVariations'))
    .reduce((total, variation) => (total + variation.get('quantity')), 0);
  const isShowMenuItems = mealStore.get('showMenuItems');
  const orderableVariations = userMealOrderable.get('orderVariations') || userMealOrderable.get('cartVariations');

  useBeforeunload((e) => {
    if ((orderableVariations.size > 0 && userMealOrderable.get('currentState') === MEAL_STATE.pending) ||
        userMealOrderable.get('currentState') === MEAL_STATE.editing) {
      e.preventDefault();
    }
  });

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      const alertMessage = document.getElementsByClassName('alert')[0];
      if (alertMessage?.classList?.contains('--no-scroll')) {
        return;
      }

      if (alertMessage) {
        if (alertMessage.scrollIntoViewIfNeeded) {
          alertMessage.scrollIntoViewIfNeeded();
        } else {
          alertMessage.scrollIntoView();
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [userMealOrderable.get('currentState')]);

  return (
    <div className={classNames({
      'container-fluid pt-4 pb-5 position-relative': !v2Enabled,
      '!tw-container-fluid !tw-relative !tw-px-4': v2Enabled,
      'md:!tw-px-32 !-tw-mt-[52px]': v2Enabled,
    })}
    >
      <EditingIndicator />

      {/* Flash */}
      <Flash />

      { /* Header */}
      <Header />

      { /* Body */}
      <div className="row">
        <div className="col">
          { /* Selected Menu Items */ }
          <SelectedMenuItemsContainer />

          {
            isMealConfirmed &&
            <Fragment>
              <div className="mb-4">
                <button
                  className="pl-0 btn btn-link"
                  onClick={actions.toggleMenuItems}
                >
                  <span className="mr-2">
                    <i
                      className={classNames('fa', { 'fa-chevron-down': isShowMenuItems, 'fa-chevron-right': !isShowMenuItems })}
                      aria-hidden="true"
                    />
                  </span>
                  <span>{ translate('groupedOrdersActionsViewFullMenu') }</span>
                </button>
              </div>
              <div className="py-4 d-block d-sm-none" />
            </Fragment>
          }

          { /* Menu Items */}
          <MenuItemsContainer />
        </div>

        { /* Responsive Meal Cart */}
        <ResponsiveMealCart
          key={`user-meal-orderable--${userMealOrderable.get('currentState')}`}
          mealSkipped={userMealOrderable.get('currentState') === statuses.skipped}
          showPrice={mealStore.getIn(['groupOrderSetting', 'showPrice'])}
          totalAmount={userMealOrderable.get('totalAmount')}
          totalQuantity={userTotalQuantity}
          {
            ...{
              mealAdmin,
            }
          }
        />
      </div>

      { /* MenuItemFormContainer */ }
      <div className="menuitem-form">
        <MenuItemForm
          mealAdmin={mealAdmin}
          menuItem={selectedMenuItem}
          cartVariation={selectedCartVariation}
          mealId={mealStore.get('uuid')}
          mealLimit={mealStore.get('mealLimit')}
          mealPlanId={mealStore.get('mealPlanId')}
          catererId={catererId}
          cartId={cartId}
          cartUserId={null}
          mealPlanUserId={mealStore.get('mealPlanUserId')}
          showPrice={mealStore.getIn(['groupOrderSetting', 'showPrice'])}
          maxVariationQuantity={mealAdmin ? null : 1}
          budget={mealStore.getIn(['groupOrderSetting', 'budget'])}
          hideSpecialInstruction={vendorSetting.get('hide_special_instruction')}
          hideAddOns={vendorSetting.get('hide_add_ons')}
          hide={!cartVariationsStore.get('showMenuItemForm')}
          isSavingCartVariation={cartVariationsStore.get('isSavingCartVariation')}
          isNonZeroVat={isNonZeroVat}
          isGroupOrder={isGroupOrder}
          isEditable={isEditable}
          saveErrors={cartVariationsStore.get('saveErrors')}
          onClickOption={actions.checkAddOnValidity}
          onDeleteItemAddOnOptionPhoto={actions.deleteItemAddOnOptionPhoto}
          onDropItemAddOnOptionPhoto={actions.addTemporaryItemAddOnOptionPhoto}
          onDropItemAddOnOptionPhotoFailure={actions.addTemporaryItemAddOnOptionPhotoFailure}
          onSelectVariation={actions.selectCartVariation}
          onSubmit={actions.saveCartVariation}
          onUpdateItemAddOnOptionText={actions.updateItemAddOnOptionText}
          onHide={actions.hideMenuItemForm}
          orderableVariations={orderableVariations}
        />
      </div>

      <MealStatusContainer />

      <MealConfirmationGuidedInfo />
    </div>
  );
};

GroupOrderMeal.propTypes = {
  actions: object.isRequired,
  cartVariationsStore: object.isRequired,
  cartsStore: object.isRequired,
  mealStore: object.isRequired,
  userMealOrderable: object.isRequired,
  vendorsStore: object.isRequired,
  userRoleIn: func.isRequired,
  translate: func.isRequired,
  v2Enabled: bool.isRequired,
};

export default withAppContext(withI18n(GroupOrderMeal));
