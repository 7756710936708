/* eslint-disable */

import clone from 'lodash/clone';
import each from 'lodash/each';
import { blankish } from 'libs/support/string';

export default class Segment {
  constructor() {
    this.analytics = window.analytics = window.analytics || [];
  }

  get initialized() {
    return analytics.initialized;
  }

  process() {
    if (analytics.initialize) return;

    if (analytics.invoked) {
      if (window.console && console.error) console.error('Segment snippet included twice.');
      return;
    }

    analytics.invoked = true;

    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on'
    ];

    analytics.factory = (method) => () => {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };

    for (var i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    analytics.load = (key) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = ('https:' === document.location.protocol
        ? 'https://' : 'http://')
        + 'cdn.segment.com/analytics.js/v1/'
        + key + '/analytics.min.js';

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
    };

    analytics.SNIPPET_VERSION = '4.0.0';
    analytics.load(CS.segmentKey);
  }

  analyzePage() {
    const data = CS.segmentPage;

    if (blankish(data)) return analytics.page();

    setTimeout(() => {
      each(data, (track) => analytics.page(track.name, track.properties || {}));
    }, 300);
  }

  analyzeAlias() {
    if (CS.aliasUserIdentifier) analytics.alias(CS.aliasUserIdentifier);
  }

  analyzeIdentify(traitsData, force) {
    if (CS.userIdentifier || force) {
      setTimeout(() => {
        const traits = clone(traitsData || CS.userTraits);
        analytics.identify(CS.userIdentifier, traits);
      }, 500);
    }
  }

  analyzeAnonymousIdentify() {
    if (CS.anonymousUserTraits) analytics.identify(CS.anonymousUserTraits);
  }

  analyzeTrack() {
    const data = CS.segmentTrack;

    if (data) {
      setTimeout(() => {
        each(data, (track) => {
          analytics.track(track.event, track.properties, track.userIdentifier);
        });
      }, 700);
    }
  }

  trackEvent(event, attributes = undefined) {
    analytics.track(event, attributes, CS.userIdentifier);
  }
}
