/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint react/no-danger: 0 */
/* eslint jsx-a11y/alt-text: 0 */

import React from 'react';
import { bool, object, func, string } from 'prop-types';
import { notBlank } from 'libs/support/string';
import { withAppContext } from 'libs/support/appContext';
import classnames from 'classnames';

import DietaryBadgeContainer from 'packs/meals/components/Shared/DietaryBadgeContainer';
import { CheckIcon } from 'libs/components/Icons';

const MenuItem = ({
  isSelected,
  isActive,
  menuItem,
  selectedText,
  showPrice,
  onClick,
}) => {
  if (!menuItem) {
    return (<div className="card card-menu" style={{ opacity: 0.0 }} />);
  }

  const fullServingsDescription = menuItem.get('full_servings_description');
  const dietaries = menuItem.get('dietaries');
  const cardClasses = classnames(
    'card card-menu !tw-m-0',
    {
      'card-menu--selected': isSelected,
    },
  );

  const style = isActive ? { cursor: 'pointer' } : { opacity: 0.7 };

  return (
    <div
      className={cardClasses}
      onClick={isActive ? onClick : null}
      style={style}
    >
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-60">
            <h6 className="card-menu-title m-0">
              { menuItem.get('name') }
              {
                dietaries &&
                  <div className="badges">
                    <DietaryBadgeContainer
                      type="caterer"
                      dietaries={dietaries}
                    />
                  </div>
              }
            </h6>
          </div>

          {
            showPrice &&
              <div className="d-flex justify-content-end align-items-start w-40">
                <div className="d-flex align-items-center">
                  <h6 className="m-0 menu-item__price">
                    { menuItem.get('price') }
                  </h6>
                </div>
              </div>
          }

        </div>
      </div>
      <div className="card-body">
        <div className="d-flex flex-column flex-sm-row justify-content-between h-100">
          <div className="d-flex flex-column justify-content-between">
            <div dangerouslySetInnerHTML={{ __html: menuItem.get('description') }} />
            <div className="card-meta mt-3">
              { /* Full Serving Description */}
              {
                (notBlank(fullServingsDescription)) &&
                  <a
                    className="tooltip-wrap text-muted"
                    data-toggle="tooltip"
                    href="#"
                    data-turbolinks="false"
                    title={fullServingsDescription}
                  >
                    <svg viewBox="0 0 12 16" width="12" height="16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M8.72689 16H3.27258C1.46503 16 0 14.6565 0 13.0004V11.0003C0 9.6783 0.937584 8.56921 2.23189 8.1692C1.52938 7.30417 1.09086 6.20971 1.09086 4.99967C1.09086 2.23858 3.2884 0 5.99973 0C8.71052 0 10.9086 2.23858 10.9086 4.99967C10.9086 6.20971 10.4695 7.30367 9.76758 8.1692C11.0619 8.56921 12 9.6788 12 11.0003V13.0004C11.9995 14.657 10.5339 16 8.72689 16ZM9.81775 4.99967C9.81775 2.7906 8.10837 0.999543 5.99973 0.999543C3.89055 0.999543 2.18172 2.7906 2.18172 4.99967C2.18172 7.20874 3.8911 9.00028 5.99973 9.00028C8.10782 9.00028 9.81775 7.20874 9.81775 4.99967ZM10.9086 11.0003C10.9086 9.94931 10.0223 9.09729 8.89923 9.01579C8.0849 9.6273 7.08895 9.99982 6.00027 9.99982C4.91104 9.99982 3.91455 9.6273 3.10077 9.01579C1.97773 9.09729 1.09139 9.94931 1.09139 11.0003V13.0004C1.09139 14.1039 2.06826 15 3.27311 15H8.72742C9.93227 15 10.9091 14.1039 10.9091 13.0004L10.9086 11.0003Z"
                      />
                    </svg>
                    <span className="ml-1">
                      { fullServingsDescription.replace(/\D/g, '') }
                    </span>
                  </a>
              }
            </div>
          </div>

          {/* Main Photo */}
          {
            menuItem.get('main_photo') &&
              <div className="menu-thumbnail align-self-sm-end mt-3 mt-sm-0 ml-sm-3">
                <img className="rounded" src={menuItem.getIn(['main_photo', 'medium'])} />
              </div>
          }
        </div>
      </div>
      {
        isSelected &&
          <div className="card-menu__selected-text">
            { selectedText }
            <span className="card-menu__selected-icon"><CheckIcon /></span>
          </div>
      }
    </div>
  );
};

MenuItem.propTypes = {
  isActive: bool,
  isSelected: bool.isRequired,
  menuItem: object,
  selectedText: string.isRequired,
  showPrice: bool.isRequired,
  onClick: func.isRequired,
};

MenuItem.defaultProps = {
  isActive: true,
};

export default withAppContext(MenuItem);
