import React from 'react';
import { func, number, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { CutleryIcon } from 'libs/components/Icons';

const HeadcountCapReachedAlert = ({
  mealCountCap,
  vendorName,
  translate,
}) => (
  <div className="alert --no-scroll alert-grey d-flex align-items-center" style={{ color: '515154' }}>
    <div className="mr-1">
      <CutleryIcon />
    </div>
    <p className="mb-0">
      { translate('groupedOrdersCountCapReached', { vendor_name: vendorName, cap: mealCountCap }) }
    </p>
  </div>
);

HeadcountCapReachedAlert.propTypes = {
  mealCountCap: number.isRequired,
  vendorName: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(HeadcountCapReachedAlert);
