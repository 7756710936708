import React, { Fragment } from 'react';
import { func, object } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { ThreeDotsIcon } from 'libs/components/Icons';
import * as mealsApi from 'libs/services/mealsApi';
import GroupOrderActionsDropdown from 'packs/common/components/GroupOrderActionsDropdown';
import DeliveryTrackingDropdown from 'packs/common/components/DeliveryTrackingDropdown';

const CardFooter = ({
  meal,
  userMealOrderable,
  onClickDownloadExcel,
  onClickDownloadMealLabelPdf,
  onClickDownloadMenuCardPdf,
  flagIsEnabled,
  userRoleIn,
  settings,
  translate,
}) => {
  const mealId = meal.get('uuid');
  const mealPlanId = meal.get('mealPlanId');
  const mealPath = () => `/meal_plans/${mealPlanId}/meals/${mealId}`;
  const orders = meal.get('orders');
  const trackableOrders = orders.filter((order) => order.get('deliveryTrackable'));

  const handleOnSkipMeal = () => {
    // NOTE: LEGACY BEHAVIOUR
    // This can be improved without page reload. Will be improved in a different PR.
    mealsApi.skipMeal({ mealId, mealPlanId }).then(_ => window.location.reload());
  };

  const renderFooterActions = () => {
    if (meal.get('isOpen') && !meal.get('isProcessing')) {
      if (userMealOrderable.get('isSelected') || userMealOrderable.get('isSkipped')) {
        return <a href={mealPath()} className="btn btn-light btn-sm">Edit Meal</a>;
      } else if (meal.get('isCafeOrdering')) {
        return <a href={mealPath()} className="btn btn-primary btn-sm">Pick a meal</a>;
      }
      return (
        <Fragment>
          <button
            className="btn btn-light btn-sm mr-1"
            onClick={handleOnSkipMeal}
          >
            Skip
          </button>
          <a href={mealPath()} className="btn btn-primary btn-sm">Pick a meal</a>
        </Fragment>
      );
    }
    return <a href={mealPath()} className="btn btn-light btn-sm">View meal</a>;
  };

  return (
    <div className="card-footer d-flex justify-content-between">
      { trackableOrders.size > 0 && userRoleIn(['osc', 'admin']) ?
        <div className="text-left">
          <DeliveryTrackingDropdown
            btnClassName="btn-light btn-sm caret-off"
            className="ml-1"
            orders={trackableOrders}
            webUrl={settings.webUrl}
          >
            {translate('groupOrderCardsButtonsTrackDelivery')}
          </DeliveryTrackingDropdown>
        </div> :
        <div />
      }
      <div className="text-right">
        { renderFooterActions() }

        {
          flagIsEnabled('sticker_label_enabled') &&
          userRoleIn(['osc', 'admin']) &&
            <GroupOrderActionsDropdown
              btnClassName="btn-light btn-sm caret-off"
              className="ml-1"
              mealPlanId={meal.get('mealPlanId')}
              mealId={meal.get('uuid')}
              onClickDownloadExcel={onClickDownloadExcel}
              onClickDownloadMealLabelPdf={onClickDownloadMealLabelPdf}
              onClickDownloadMenuCardPdf={onClickDownloadMenuCardPdf}
              orders={orders}
            >
              <ThreeDotsIcon />
            </GroupOrderActionsDropdown>
        }
      </div>
    </div>
  );
};

CardFooter.propTypes = {
  meal: object.isRequired,
  settings: object.isRequired,
  userMealOrderable: object.isRequired,
  onClickDownloadExcel: func.isRequired,
  onClickDownloadMealLabelPdf: func.isRequired,
  onClickDownloadMenuCardPdf: func.isRequired,
  userRoleIn: func.isRequired,
  flagIsEnabled: func,
  translate: func.isRequired,
};

export default withAppContext(withI18n(CardFooter));
