import React, { Fragment } from 'react';
import classNames from 'classnames';
import Immutable from 'immutable';
import { func, object } from 'prop-types';
import { blankish } from 'libs/support/string';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { ExternalIcon } from 'libs/components/Icons';
import Dropdown from 'react-bootstrap/Dropdown';

const OrderLinks = ({
  orders = Immutable.fromJS([]),
  settings,
  translate,
}) => {
  if (blankish(orders) || orders.size === 0) return null;

  return (
    <Fragment>
      {
        orders.map(order => (
          <Dropdown.Item
            key={`order-${order.get('displayCode')}`}
            href={`${settings.webUrl}/account/orders/${order.get('displayCode')}`}
            target="_blank"
            className={classNames('!tw-flex tw-items-start tw-gap-2 !tw-whitespace-break-spaces', {
              '!tw-px-4 !tw-py-2 !tw-w-full': true,
            })}
          >
            <span className="tw-relative tw-mt-1">
              <ExternalIcon />
            </span>
            <span className="tw-flex tw-flex-col">
              <span className="tw-text-base tw-font-bold tw-text-gray-900">
                {translate('mealStatusButtonsEditOrderFor', {
                  name: order.get('vendorName'),
                })}
              </span>
              {!blankish(order.get('displayCode')) &&
                <span className="tw-text-xs tw-text-gray-500">
                  {translate('mealStatusButtonsOrderId', {
                    code: order.get('displayCode'),
                  })}
                </span>
              }
            </span>
          </Dropdown.Item>
        ))
      }
      { orders.size > 0 && <hr className="my-2" /> }
    </Fragment>
  );
};

OrderLinks.propTypes = {
  orders: object,
  settings: object.isRequired,
  translate: func.isRequired,
};

export default withAppContext(withI18n(OrderLinks));
