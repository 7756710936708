/* eslint max-len: 0, no-bitwise: 0, no-return-assign: 0, jsx-a11y/no-static-element-interactions: 0, react/no-will-update-set-state: 0 */

import React from 'react';
import { bool, func, number, string } from 'prop-types';
import classnames from 'classnames';
import $ from 'jquery';
import bindAll from 'lodash/bindAll';
import includes from 'lodash/includes';

import BaseComponent from 'libs/components/BaseComponent';
import { blankish, notBlank } from 'libs/support/string';

function valAfterType($input, typedStr, keyDownCode) {
  const { selectionStart, selectionEnd } = $input.get(0);
  const currentVal = $input.val();

  let deltaStart = 0;
  let deltaEnd = 0;

  if (keyDownCode === 8) deltaStart = -1;

  if (keyDownCode === 46) deltaEnd = 1;

  return currentVal.substr(0, selectionStart + deltaStart) + typedStr + currentVal.substr(selectionEnd + deltaEnd);
}

function isValid(val, min, max) {
  if (blankish(min)) return true;

  const parsed = parseInt(val, 10);
  const regexStr = '^[0-9]+$'.replace(/[.]/g, '\\$&');
  const regex = new RegExp(regexStr, 'g');

  // eslint-disable-next-line no-restricted-globals
  let result = !isNaN(parsed) && !!(`${val}`).match(regex);

  if (min) result &= parsed >= min;

  if (max) result &= parsed <= max;

  return !!result;
}

export default class TypeNumberComponent extends BaseComponent {
  static propTypes = {
    shoudDisablePlus: bool,
    min: number.isRequired,
    value: number.isRequired,
    max: number,
    placeholder: number,
    name: string.isRequired,
    onChange: func,
    disabled: bool,
  };

  constructor(props) {
    super();

    this.state = { value: props.value || '' };

    bindAll(this, ['handleOnKeyDown', 'handleOnKeyPress', 'handleOnBlur', 'handleOnClickPlus',
      'handleOnClickMinus']);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { value } = nextProps;

    if (value !== this.props.value) this.setState({ value });
  }

  handleOnClickPlus() {
    const { max } = this.props;
    const value = parseInt(this.state.value, 10);

    if ((notBlank(max) && (value < max)) || blankish(max)) {
      this.setState({ value: value + 1 });
      this.handleOnChange();
    }
  }

  handleOnClickMinus() {
    const { min } = this.props;
    const value = parseInt(this.state.value, 10);

    if ((notBlank(min) && (value > min)) || blankish(min)) {
      this.setState({ value: value - 1 });
      this.handleOnChange();
    }
  }

  handleOnKeyDown(e) {
    if (includes([38, 40], e.keyCode)) {
      if (e.keyCode === 38) this.handleOnClickPlus();

      if (e.keyCode === 40) this.handleOnClickMinus();

      e.preventDefault();
    }

    if (includes([8, 46], e.keyCode)) {
      const str = valAfterType($(this.input), '', e.keyCode);
      this.setState({ value: str });
    }
  }

  handleOnKeyPress(e) {
    const str = valAfterType($(this.input), String.fromCharCode(e.charCode));
    this.setState({ value: str });
  }

  handleOnBlur() {
    const { min, max } = this.props;

    if (!isValid(this.state.value, min, max)) this.setState({ value: min });

    this.handleOnChange();
  }

  handleOnChange() {
    const { onChange } = this.props;
    const prevValue = this.state.value;

    if (onChange) {
      setTimeout(() => { onChange(prevValue, this.state.value); }, 10);
    }
  }

  render() {
    const {
      shouldDisablePlus, min, placeholder, name, disabled,
    } = this.props;
    const { value } = this.state;
    const intDisabled = notBlank(disabled) ? disabled : false;
    const minusClassName = classnames('minus', { disabled: intDisabled || value === min });
    const plusClassName = classnames('plus', { disabled: intDisabled || shouldDisablePlus });
    const finalPlaceHolder = notBlank(value) ? placeholder : '';

    return (
      <div className="type-number-imitate">
        <input
          ref={node => this.input = node}
          type="text"
          className="number"
          name={name}
          disabled={intDisabled}
          value={this.state.value}
          placeholder={finalPlaceHolder}
          onChange={() => false}
          onKeyDown={this.handleOnKeyDown}
          onKeyPress={this.handleOnKeyPress}
          onBlur={this.handleOnBlur}
        />

        <span className={plusClassName} onClick={this.handleOnClickPlus}>+</span>
        <span className={minusClassName} onClick={this.handleOnClickMinus}>–</span>
      </div>
    );
  }
}
