/* eslint camelcase: 0 */

import humps from 'lodash-humps';
import {
  compose,
  createStore,
  applyMiddleware,
  combineReducers,
} from 'redux';

import thunkMiddleware from 'redux-thunk';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    group_order_meal: {
      allow_self_checkout,
      budget,
      meal_plan_id,
      selected,
      total,
      uuid,
    },
    meal_plan_user,
    user_meal_orderable,
  } = props;

  const { $$mealState } = initialStates;

  const initialState = {
    $$mealStore: $$mealState.merge({
      allowSelfCheckout: allow_self_checkout,
      budget,
      mealAdmin: meal_plan_user.is_admin,
      mealPlanId: meal_plan_id,
      mealPlanUserId: meal_plan_user.uuid,
      selected,
      total,
      uuid,
    }).merge({
      meal: humps(props.group_order_meal),
      userMealOrderable: humps(user_meal_orderable),
    }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = compose(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
