/* eslint max-len: 0 */

import React from 'react';

const External = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2001 3.59998C12.5374 3.59998 12.0001 4.13723 12.0001 4.79998C12.0001 5.46272 12.5374 5.99998 13.2001 5.99998H16.303L8.75157 13.5514C8.28294 14.0201 8.28294 14.7799 8.75157 15.2485C9.2202 15.7171 9.98 15.7171 10.4486 15.2485L18.0001 7.69703V10.8C18.0001 11.4627 18.5374 12 19.2001 12C19.8628 12 20.4001 11.4627 20.4001 10.8V4.79998C20.4001 4.13723 19.8628 3.59998 19.2001 3.59998H13.2001Z" fill="#111827" />
    <path d="M6.0001 5.99998C4.67461 5.99998 3.6001 7.07449 3.6001 8.39997V18C3.6001 19.3255 4.67461 20.4 6.0001 20.4H15.6001C16.9256 20.4 18.0001 19.3255 18.0001 18V14.4C18.0001 13.7372 17.4628 13.2 16.8001 13.2C16.1374 13.2 15.6001 13.7372 15.6001 14.4V18H6.0001V8.39997L9.6001 8.39998C10.2628 8.39998 10.8001 7.86272 10.8001 7.19998C10.8001 6.53723 10.2628 5.99998 9.6001 5.99998H6.0001Z" fill="#111827" />
  </svg>

);

export default External;
