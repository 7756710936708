import React from 'react';
import Intl from 'intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import {
  bool,
  number,
  string,
  func,
} from 'prop-types';

import BaseComponent from 'libs/components/BaseComponent';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

import * as mealActions from '../actions/mealActions';

import CardHeadline from '../components/GroupOrderMeal/CardHeadline';

global.Intl = Intl;

const mapStateToProps = state => {
  const { $$mealStore } = state;

  return {
    allowSelfCheckout: $$mealStore.get('allowSelfCheckout'),
    budget: $$mealStore.get('budget'),
    selected: $$mealStore.get('selected'),
    total: $$mealStore.get('total'),
    mealPlanId: $$mealStore.get('mealPlanId'),
    uuid: $$mealStore.get('uuid'),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...mealActions,
}, dispatch);

class GroupOrderMealCardHeadlineContainer extends BaseComponent {
  static propTypes = {
    allowSelfCheckout: bool,
    budget: number,
    selected: number,
    total: number,
    mealPlanId: string.isRequired,
    uuid: string.isRequired,
    fetchMealStatus: func.isRequired,
  };

  render = () => {
    const {
      allowSelfCheckout,
      budget,
      selected,
      total,
      mealPlanId,
      uuid,
      fetchMealStatus,
    } = this.props;

    const locale = defaultLocale;
    const messages = translations[locale];

    return (
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
      >
        <CardHeadline
          allowSelfCheckout={allowSelfCheckout}
          budget={budget}
          selected={selected}
          total={total}
          mealPlanId={mealPlanId}
          uuid={uuid}
          onFetchMealStatus={fetchMealStatus}
        />
      </IntlProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrderMealCardHeadlineContainer);
