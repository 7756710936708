/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { withI18n } from 'libs/support/i18n';
import DairyFreeIcon from 'packs/meals/components/Icons/Dietaries/DairyFree';
import GlutenFreeIcon from 'packs/meals/components/Icons/Dietaries/GlutenFree';
import HalalIcon from 'packs/meals/components/Icons/Dietaries/Halal';
import MuslimFriendlyIcon from 'packs/meals/components/Icons/Dietaries/MuslimFriendly';
import NutFreeIcon from 'packs/meals/components/Icons/Dietaries/NutFree';
import OrganicIcon from 'packs/meals/components/Icons/Dietaries/Organic';
import VeganIcon from 'packs/meals/components/Icons/Dietaries/Vegan';
import VegetarianIcon from 'packs/meals/components/Icons/Dietaries/Vegetarian';
import CatererTooltip from 'packs/meals/components/Shared/CatererTooltip';

const DIETARY_BADGES = {
  'Dairy Free': { badge: DairyFreeIcon },
  'Gluten Free': { badge: GlutenFreeIcon },
  'Muslim-Friendly': { badge: MuslimFriendlyIcon },
  'Nut Free': { badge: NutFreeIcon },
  'Halal Certified': { badge: HalalIcon },
  Organic: { badge: OrganicIcon },
  Vegan: { badge: VeganIcon },
  Vegetarian: { badge: VegetarianIcon },
};

const UNKNOWN_DIETARY = { badge: null };
const ALCOHOL = 'Alcohol';

const DietaryBadgeContainer = (props) => {
  const {
    dietaries,
    type,
    translate,
  } = props;

  return (
    dietaries
    // We should remove next line once we've removed alcohol related dietaries data
      .filter(dietary => dietary.get('name') !== ALCOHOL)
      .map((dietary, index) => {
        const dietaryInfo = DIETARY_BADGES[dietary.get('name')] || UNKNOWN_DIETARY;
        const DietaryBadge = dietaryInfo.badge;
        const name = dietary.get('name');
        const title = translate([
          'caterersDietaryBadges',
          startCase(type),
          startCase(name).replace(' ', ''),
        ].join(''));

        return (
          DietaryBadge &&
          <CatererTooltip
            key={`${name}-${index}`}
            classes="tooltip-wraps"
            title={title}
          >
            <span className="badges__icon transform !tw-m-0 tw-transform tw-scale-[0.7] tw-origin-top-left">
              <DietaryBadge className="tw-flex" />
            </span>
          </CatererTooltip>
        );
      })
  );
};

DietaryBadgeContainer.propTypes = {
  dietaries: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withI18n(DietaryBadgeContainer);
