/* eslint max-len: 0 */

import React from 'react';

const Food = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="203" height="124" viewBox="0 0 203 124" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M71.4053 111.796C98.907 111.796 121.201 89.5019 121.201 62.0002C121.201 34.4986 98.907 12.2041 71.4053 12.2041C43.9037 12.2041 21.6092 34.4986 21.6092 62.0002C21.6092 89.5019 43.9037 111.796 71.4053 111.796Z" fill="#F2F2F2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.4053 25.3575C72.962 25.3575 74.224 24.0956 74.224 22.5389C74.224 20.9822 72.962 19.7202 71.4053 19.7202C69.8487 19.7202 68.5867 20.9822 68.5867 22.5389C68.5867 24.0956 69.8487 25.3575 71.4053 25.3575Z" fill="black" fillOpacity="0.1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31.9443 64.8189C33.501 64.8189 34.7629 63.557 34.7629 62.0003C34.7629 60.4436 33.501 59.1816 31.9443 59.1816C30.3876 59.1816 29.1256 60.4436 29.1256 62.0003C29.1256 63.557 30.3876 64.8189 31.9443 64.8189Z" fill="black" fillOpacity="0.1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M110.866 64.8189C112.423 64.8189 113.685 63.557 113.685 62.0003C113.685 60.4436 112.423 59.1816 110.866 59.1816C109.31 59.1816 108.048 60.4436 108.048 62.0003C108.048 63.557 109.31 64.8189 110.866 64.8189Z" fill="black" fillOpacity="0.1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.4053 104.28C72.962 104.28 74.224 103.018 74.224 101.461C74.224 99.9045 72.962 98.6426 71.4053 98.6426C69.8487 98.6426 68.5867 99.9045 68.5867 101.461C68.5867 103.018 69.8487 104.28 71.4053 104.28Z" fill="black" fillOpacity="0.1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.4053 28.1768L73.2844 61.061H69.5262L71.4053 28.1768Z" fill="#AFB9C5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M81.7972 42.5394L73.0847 63.4221L69.7484 61.692L81.7972 42.5394Z" fill="#AFB9C5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.4053 65.7581C73.4809 65.7581 75.1635 64.0755 75.1635 61.9999C75.1635 59.9243 73.4809 58.2417 71.4053 58.2417C69.3297 58.2417 67.6471 59.9243 67.6471 61.9999C67.6471 64.0755 69.3297 65.7581 71.4053 65.7581Z" fill="#C5CFD6" />
    <path d="M176.389 43.6641L166.748 110.158H143.2L133.559 43.6641" fill="#DEDEDE" />
    <path d="M152.903 102.104C152.92 102.276 152.929 102.45 152.929 102.627V104.88C152.923 106.314 152.351 107.688 151.337 108.702C150.322 109.717 148.948 110.289 147.514 110.294H103.401C101.965 110.29 100.589 109.718 99.573 108.703C98.5573 107.687 97.9848 106.311 97.9805 104.875V102.627C97.9799 102.452 97.9883 102.278 98.0057 102.104" fill="#DE8E68" />
    <path d="M151.19 96.9292H99.7165C98.7568 96.9292 97.9787 97.7073 97.9787 98.667V101.434C97.9787 102.393 98.7568 103.172 99.7165 103.172H151.19C152.15 103.172 152.928 102.393 152.928 101.434V98.667C152.928 97.7073 152.15 96.9292 151.19 96.9292Z" fill="#893112" />
    <path d="M97.5389 90.333V94.4956C97.5389 95.4725 98.2998 96.2719 99.2279 96.2719H151.679C152.607 96.2719 153.366 95.4725 153.366 94.4956V90.333H97.5389Z" fill="#893112" />
    <path d="M152.764 77.8582C152.764 69.4369 140.537 62.6113 125.453 62.6113C110.369 62.6113 98.1417 69.4384 98.1417 77.8582V81.7632C98.1433 82.2582 98.3406 82.7324 98.6906 83.0824C99.0406 83.4324 99.5149 83.6298 100.01 83.6313H150.896C151.391 83.6302 151.866 83.433 152.216 83.0829C152.566 82.7328 152.763 82.2583 152.764 81.7632V77.8582Z" fill="#DE8E68" />
    <path d="M99.4268 86.7583C96.2738 86.7583 94.8942 88.4118 94.8942 89.9128C94.8942 92.0414 96.2679 92.8941 98.1419 92.8941C100.692 92.8941 101.547 91.0733 103.853 91.0733C106.84 91.0733 107.243 92.8615 109.889 92.8615C112.612 92.8615 115.253 90.6144 117.423 90.6144C120.451 90.6144 122.623 93.0214 125.65 93.0214C128.677 93.0214 130.696 90.8217 133.725 90.8217C135.895 90.8217 137.207 93.0214 139.93 93.0214C142.577 93.0214 143.424 91.097 146.409 91.097C148.716 91.097 150.215 92.7653 152.764 92.7653C154.64 92.7653 155.957 92.04 155.957 89.9113C155.957 88.4103 154.579 86.7568 151.426 86.7568" fill="#74D5DE" />
    <path d="M151.25 97.6768H147.056H138.176H120.318H109.814H99.6573C99.5038 97.6768 99.3566 97.6158 99.2481 97.5072C99.1395 97.3987 99.0786 97.2515 99.0786 97.098V96.8508C99.0786 96.7748 99.0935 96.6995 99.1226 96.6293C99.1517 96.559 99.1943 96.4952 99.2481 96.4415C99.3018 96.3877 99.3656 96.3451 99.4358 96.316C99.5061 96.2869 99.5813 96.272 99.6573 96.272H151.25C151.403 96.272 151.55 96.333 151.659 96.4415C151.767 96.55 151.828 96.6973 151.828 96.8508V97.098C151.828 97.2515 151.767 97.3987 151.659 97.5072C151.55 97.6158 151.403 97.6768 151.25 97.6768Z" fill="#FCC486" />
    <path d="M150.557 83.6318H100.35C99.8703 83.6318 99.4813 84.0209 99.4813 84.5008V87.5457C99.4813 88.0256 99.8703 88.4146 100.35 88.4146H150.557C151.037 88.4146 151.426 88.0256 151.426 87.5457V84.5008C151.426 84.0209 151.037 83.6318 150.557 83.6318Z" fill="#FCC486" />
    <path d="M176.807 40.9702H133.14C132.481 40.9702 131.947 41.5044 131.947 42.1633C131.947 42.8223 132.481 43.3564 133.14 43.3564H176.807C177.466 43.3564 178 42.8223 178 42.1633C178 41.5044 177.466 40.9702 176.807 40.9702Z" fill="#DEDEDE" />
    <path d="M156.484 27.1235H154.556V40.9701H156.484V27.1235Z" fill="#DEDEDE" />
    <path d="M146.693 17.3315L145.33 18.6943L155.121 28.4853L156.484 27.1225L146.693 17.3315Z" fill="#DEDEDE" />
    <path d="M106.17 77.9088C106.446 77.9088 106.669 77.6855 106.669 77.41C106.669 77.1345 106.446 76.9111 106.17 76.9111C105.895 76.9111 105.671 77.1345 105.671 77.41C105.671 77.6855 105.895 77.9088 106.17 77.9088Z" fill="#FCC486" />
    <path d="M113.055 73.8815C113.33 73.8815 113.554 73.6582 113.554 73.3826C113.554 73.1071 113.33 72.8838 113.055 72.8838C112.779 72.8838 112.556 73.1071 112.556 73.3826C112.556 73.6582 112.779 73.8815 113.055 73.8815Z" fill="#FCC486" />
    <path d="M133.267 71.5275C133.543 71.5275 133.766 71.3041 133.766 71.0286C133.766 70.7531 133.543 70.5298 133.267 70.5298C132.992 70.5298 132.768 70.7531 132.768 71.0286C132.768 71.3041 132.992 71.5275 133.267 71.5275Z" fill="#FCC486" />
    <path d="M113.055 68.1852C113.33 68.1852 113.554 67.9619 113.554 67.6864C113.554 67.4108 113.33 67.1875 113.055 67.1875C112.779 67.1875 112.556 67.4108 112.556 67.6864C112.556 67.9619 112.779 68.1852 113.055 68.1852Z" fill="#FCC486" />
    <path d="M116.315 78.9049C116.59 78.9049 116.814 78.6816 116.814 78.4061C116.814 78.1306 116.59 77.9072 116.315 77.9072C116.039 77.9072 115.816 78.1306 115.816 78.4061C115.816 78.6816 116.039 78.9049 116.315 78.9049Z" fill="#FCC486" />
    <path d="M132.27 79.404C132.546 79.404 132.769 79.1806 132.769 78.9051C132.769 78.6296 132.546 78.4062 132.27 78.4062C131.995 78.4062 131.772 78.6296 131.772 78.9051C131.772 79.1806 131.995 79.404 132.27 79.404Z" fill="#FCC486" />
    <path d="M147.056 80.7834C147.331 80.7834 147.555 80.56 147.555 80.2845C147.555 80.009 147.331 79.7856 147.056 79.7856C146.78 79.7856 146.557 80.009 146.557 80.2845C146.557 80.56 146.78 80.7834 147.056 80.7834Z" fill="#FCC486" />
    <path d="M123.556 73.8815C123.832 73.8815 124.055 73.6582 124.055 73.3826C124.055 73.1071 123.832 72.8838 123.556 72.8838C123.281 72.8838 123.057 73.1071 123.057 73.3826C123.057 73.6582 123.281 73.8815 123.556 73.8815Z" fill="#FCC486" />
    <path d="M141.111 71.5275C141.386 71.5275 141.609 71.3041 141.609 71.0286C141.609 70.7531 141.386 70.5298 141.111 70.5298C140.835 70.5298 140.612 70.7531 140.612 71.0286C140.612 71.3041 140.835 71.5275 141.111 71.5275Z" fill="#FCC486" />
    <path d="M123.449 68.1852C123.724 68.1852 123.948 67.9619 123.948 67.6864C123.948 67.4108 123.724 67.1875 123.449 67.1875C123.173 67.1875 122.95 67.4108 122.95 67.6864C122.95 67.9619 123.173 68.1852 123.449 68.1852Z" fill="#FCC486" />
  </svg>
);

export default Food;
