import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const IndexIcon = styled.div`
  border: 2px solid #7D9E1D;
  color: #7D9E1D;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  justify-content: center;
`;

export function ExpandableCard({ index, title, subtitle, children, isCompleted }) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="pt-2">
      <div className="d-flex py-2 px-3 w-100">
        {isCompleted && <FontAwesomeIcon icon={faCheckCircle} color="#BC2C9C" fontSize={32} />}
        {
          !isCompleted && (
            <IndexIcon className="d-flex" >
              <span>{index}</span>
            </IndexIcon>
          )
        }
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className="ml-2 flex-grow-1"
        >
          <div><b>{title}</b></div>
          <div>{subtitle}</div>
        </div>
        <div>
          {isExpanded && <FontAwesomeIcon icon={faAngleDown} color="#7D9E1D" fontSize={14} />}
          {!isExpanded && <FontAwesomeIcon icon={faAngleUp} color="#7D9E1D" fontSize={14} />}
        </div>
      </div>
      {isExpanded && children}
    </div>
  );
}

ExpandableCard.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isCompleted: PropTypes.bool,
};
