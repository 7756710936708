/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withI18n } from 'libs/support/i18n';

/* Actions */
import actions from 'packs/meals/actions';

import MenuItemList from './MenuItemList';
import MenuItemsSkeletal from './MenuItemSkeletal';

/**
 * MenuItems
 */

const MenuItems = ({
  actions,
  cartVariationsStore,
  cartsStore,
  customMenuItemsStore,
  mealStore,
  menuItemsStore,
  vendorsStore,
}) => {
  const isLoading = mealStore.get('isLoading');

  const onEditSelection = (e, menuItem, cartVariation) => {
    e.preventDefault();

    const cartVariationId = cartVariation.get('uuid');

    return (cartVariationId)
      ? actions.fetchCartVariation(mealStore.get('mealPlanId'), cartVariationId)
      : actions.fetchMenuItem(menuItem.get('uuid'));
  };

  const onAddAnotherSelection = (e, menuItem) => {
    e.preventDefault();

    return actions.fetchMenuItem(menuItem.get('uuid'));
  };

  return (
    <div className="card-body menu-items menu-items-vendors !tw-py-4 !tw-px-0">
      {isLoading ?
        <MenuItemsSkeletal /> :
        <MenuItemList
          cartVariationsStore={cartVariationsStore}
          cartsStore={cartsStore}
          customMenuItemsStore={customMenuItemsStore}
          mealStore={mealStore}
          menuItemsStore={menuItemsStore}
          vendorsStore={vendorsStore}
          onSaveCustomMenuItem={actions.saveCustomMenuItem}
          onUpdateSelection={actions.updateQuantityCartVariation}
          onEditSelection={onEditSelection}
          onAddSelection={onAddAnotherSelection}
        />
      }
    </div>
  );
};

MenuItems.propTypes = {
  mealStore: PropTypes.object.isRequired,
  cartsStore: PropTypes.object.isRequired,
  cartVariationsStore: PropTypes.object.isRequired,
  menuItemsStore: PropTypes.object.isRequired,
  customMenuItemsStore: PropTypes.object.isRequired,
  vendorsStore: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
  menuItemsStore: state.$$menuItemsStore,
  customMenuItemsStore: state.$$customMenuItemsStore,
  vendorsStore: state.$$vendorsStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withI18n(MenuItems));
