import React from 'react';
import { bool, object, string } from 'prop-types';
import { notBlank } from 'libs/support/string';
import { setImageUrl } from 'helpers/cartVariationsHelper';

import IMAGE_PLACEHOLDER from 'assets/images/img-placeholder.png';

const MenuItem = ({
  menuItem,
  vendorName,
  isGuest = false,
}) => (
  <div className="media tw-border-b tw-border-gray-100 tw-py-2 tw-px-0 tw-flex tw-gap-2">
    <div className="card-thumbnail">
      <div className="tw-w-10 tw-min-h-10 tw-max-h-10 tw-bg-gray-50 tw-rounded-md">
        {notBlank(menuItem.getIn(['photos', 0, 'thumb']))
          ? (
            <object
              className="tw-w-full tw-h-auto tw-object-contain"
              data={setImageUrl(menuItem.getIn(['photos', 0, 'thumb']))}
            >
              <img
                className="tw-rounded !tw-w-full !tw-h-auto !tw-object-contain"
                src={IMAGE_PLACEHOLDER}
                alt=""
              />
            </object>
          )
          : <img
              className="tw-rounded !tw-w-full !tw-h-auto !tw-object-contain"
              src={IMAGE_PLACEHOLDER}
              alt=""
          />
        }
      </div>
    </div>
    <div className="media-body">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-flex-col">
          <div className="!tw-m-0 tw-text-base tw-text-gray-900 tw-font-bold tw-truncate tw-leading-5">
            { menuItem.get('name') }
          </div>
          <span className="tw-text-sm tw-text-gray-400">
            {`from ${vendorName}`}
          </span>
        </div>
        {isGuest && (
          <span className="tw-bg-gray-200 tw-text-gray-900 tw-rounded-sm tw-px-2 tw-text-[10px]">
            Guest
          </span>
        )}
      </div>
    </div>
  </div>
);

MenuItem.propTypes = {
  menuItem: object,
  vendorName: string,
  isGuest: bool,
};

export default MenuItem;
