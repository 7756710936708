/* eslint-disable max-len */
import React from 'react';
import uppercamelcase from 'uppercamelcase';
import { func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { isChannelHidden } from './utils';
import NotificationItem from './NotificationItem';
import {
  NotificationGroupContainer,
  SectionTitle,
  GroupHeading,
  Title,
  Wrapper,
  Description,
  Channels,
  SubSettings,
  ChannelSpan,
} from '../styles/desktop';

const NotificationGroup = ({ section, groups, role, updateSetting, currentSettings, translate }) => (
  <NotificationGroupContainer>
    { role === 'all' && <SectionTitle>{translate(`accountSettingsNotificationSettingsSection${uppercamelcase(section)}Title`)}</SectionTitle>}
    {groups.map(group => (
      <div key={group.name}>
        <GroupHeading>
          <Title>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(group.name)}Title`)}</Title>
          <Wrapper>
            <Description>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(group.name)}Description`)}</Description>
            <Channels>
              <ChannelSpan visibility={isChannelHidden(group.name, 'push') ? 'hidden' : 'visible'}>{ translate('accountSettingsNotificationSettingsChannelsPush') }</ChannelSpan>
              <ChannelSpan visibility={isChannelHidden(group.name, 'email') ? 'hidden' : 'visible'}>{ translate('accountSettingsNotificationSettingsChannelsEmail') }</ChannelSpan>
              <ChannelSpan visibility={isChannelHidden(group.name, 'sms') ? 'hidden' : 'visible'}>{ translate('accountSettingsNotificationSettingsChannelsSms') }</ChannelSpan>
            </Channels>
          </Wrapper>
        </GroupHeading>
        <SubSettings>
          {group.subNotifications.map(notification => (
            <NotificationItem
              group={group.name}
              key={notification.name}
              name={notification.name}
              updateSetting={updateSetting}
              currentSettings={currentSettings}
            />
            ))}
        </SubSettings>
      </div>
      ))}
  </NotificationGroupContainer>
);

NotificationGroup.propTypes = {
  section: string,
  groups: object,
  role: func,
  updateSetting: func,
  currentSettings: object,
  translate: func,
};

export default withI18n(NotificationGroup);
