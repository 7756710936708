/* eslint-disable react/no-array-index-key */
import React from 'react';
import dayjs from 'dayjs';
import { object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { Card } from '../../../styles';
import { Stack, TableWrapper } from '../../../styles';
import { Money } from '../../Money';
import { BudgetBar } from '../../BudgetBar';
import { Percentage } from '../../Percentage';

export const MealPlan = withI18n(({ mealPlan, translate }) => {
  const showTotal = mealPlan.data.length > 1;

  return (
    <Card
      style={{ borderLeft: `8px solid ${mealPlan.color}` }}
      data-testid="reporting-meal-plans-meal-plan"
    >
      <Stack>
        <div style={{ flex: 1, fontSize: 24, fontWeight: 600 }}>
          {mealPlan.name}
        </div>
        {mealPlan.summary.totalBudget > 0 && (
          <BudgetBar
            total={mealPlan.summary.totalBudget}
            spend={mealPlan.summary.budgetSpend}
            color={mealPlan.color}
          />
        )}
        <TableWrapper fixedFirstCellWidth={180}>
          <table className="table">
            <thead>
              <tr>
                <th />
                {mealPlan.data.map(({ step }) => (
                  <th key={step}>{dayjs(step, 'YYYY-MM').format("MMM'YY")}</th>
                ))}
                {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsBudget')}
                </td>
                {mealPlan.data.map(({ totalBudget }, i) => (
                  <td className="font-weight-bold" key={i}>
                    <Money value={totalBudget} />
                  </td>
                ))}
                {showTotal && (
                  <td>
                    <Money value={mealPlan.summary.totalBudget} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsSpend')}
                </td>
                {mealPlan.data.map(({ totalSpend, budgetSpendPercentage }, i) => (
                  <td key={i}>
                    <Money value={totalSpend} />
                    <br />
                    <Percentage value={budgetSpendPercentage} />
                  </td>
                ))}
                {showTotal && (
                  <td>
                    <Money value={mealPlan.summary.totalSpend} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsUnused')}
                </td>
                {mealPlan.data.map(({ budgetUnused, budgetUnusedPercentage }, i) => (
                  <td key={i}>
                    <Money value={budgetUnused} />
                    <br />
                    <Percentage value={budgetUnusedPercentage} />
                  </td>
                ))}
                {showTotal && (
                  <td>
                    <Money value={mealPlan.summary.budgetUnused} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsPax')}
                </td>
                {mealPlan.data.map(({ totalPax }, i) => (
                  <td key={i}>
                    {totalPax}
                  </td>
                ))}
                {showTotal && <td>-</td>}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsServingDays')}
                </td>
                {mealPlan.data.map(({ servingDaysCount }, i) => (
                  <td key={i}>
                    {servingDaysCount}
                  </td>
                ))}
                {showTotal && <td>-</td>}
              </tr>
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsAverageCostPerPax')}
                </td>
                {mealPlan.data.map(({ averageCost }, i) => (
                  <td key={i}>
                    <Money value={averageCost} />
                  </td>
                ))}
                {showTotal && <td>-</td>}
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </Stack>
    </Card>
  );
});

MealPlan.propTypes = {
  mealPlan: object.isRequired,
};
