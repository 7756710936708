export const buildAddOnDetails = (orderableVariation) => (
  [
    ...(
      (
        orderableVariation.cartVariationOptionChoices ||
          orderableVariation.orderVariationOptionChoices
      ).map((o) => o.name)
    ),
    ...(
      (
        orderableVariation.cartVariationItemAddOnOptions ||
        orderableVariation.orderVariationItemAddOnOptions
      ).map((o) => o.name)
    ),
    ...(
      (
        orderableVariation.cartVariationServiceAddOnOptions ||
        orderableVariation.orderVariationServiceAddOnOptions
      ).map((o) => o.name)
    ),
  ].join(', ')
);
