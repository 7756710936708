import React from 'react';
import { object } from 'prop-types';
import TooltipLite from 'react-tooltip-lite';
import CameraIcon from 'libs/components/Icons/Camera2';
import styled from 'styled-components';
import { blankish } from 'libs/support/string';

const Container = styled.span`
`;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.span`
  img {
    width: 100%;

    @media (min-width: ${props => props.theme.breakPoints.xss}) {
      width: 400px;
    }
  }
`;

const Content = ({ photo }) => (
  <ContentContainer>
    <img src={photo.get('medium')} alt="preview" />
  </ContentContainer>
);

const PhotoPreview = ({
  photo,
}) => {
  if (blankish(photo)) return null;

  return (
    <Container className="photo-preview">
      <img src={photo.get('medium')} alt="preview" style={{ visibility: 'hidden', position: 'absolute' }} />

      <TooltipLite
        tagName="span"
        content={<Content {...{ photo }} />}
        tipContentClassName="photo-preview-tooltip"
        direction="up"
      >
        <IconContainer>
          <CameraIcon />
        </IconContainer>
      </TooltipLite>
    </Container>
  );
};

Content.propTypes = {
  photo: object,
};

PhotoPreview.propTypes = {
  photo: object,
};

export default PhotoPreview;
