import Immutable from 'immutable';

import * as mealActionTypes from '../constants/mealConstants';
import * as actionTypes from '../constants/mealReportConstants';

export const $$initialState = Immutable.fromJS({
  isProcessing: false,
  isProcessSuccess: false,
  mealReport: {},
});

const mealReportReducer = ($$state = $$initialState, action = null) => {
  const {
    response,
    type,
  } = action;

  const defaultHash = {
    isProcessing: false,
    isProcessSuccess: false,
  };

  switch (type) {
    case actionTypes.PROCESSING_MEAL_REPORT: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isProcessing', true)
          .set('mealReport', Immutable.fromJS({}))
      ));
    }

    case actionTypes.PROCESS_MEAL_REPORT_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isProcessSuccess', true)
          .set('mealReport', Immutable.fromJS(response))
      ));
    }

    case mealActionTypes.HIDE_MEAL_STATUS:
    case mealActionTypes.SHOW_MEAL_STATUS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('mealReport', Immutable.fromJS({}))
      ));
    }

    default: {
      return $$state;
    }
  }
};

export default mealReportReducer;
