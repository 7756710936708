/* eslint max-len: 0 */

import React from 'react';

const Select = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="203" height="124" viewBox="0 0 203 124" fill="none">
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M56.7841 42.1309C64.0617 25.6832 110.332 28.6935 124.569 50.0452C138.807 71.3969 122.536 118.833 107.95 118.833C93.365 118.833 93.365 86.8056 87.3511 77.4C81.3371 67.9943 49.5065 58.5786 56.7841 42.1309Z" fill="#EEEEEE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.6516 88.7693C45.7765 96.4961 53.0038 90.6727 62.2958 82.5897C71.5878 74.5067 83.3687 66.5266 79.2439 58.7998C75.119 51.0731 60.2995 52.0602 51.0075 60.1432C41.7155 68.2262 37.5268 81.0425 41.6516 88.7693Z" fill="#F4F4F4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M121.805 112.477C127.684 112.477 134.169 106.848 134.169 99.6754C134.169 92.5028 127.684 86.874 121.805 86.874C115.926 86.874 113.74 95.0824 113.74 100.965C113.74 106.848 115.926 112.477 121.805 112.477Z" fill="#FF73C6" fillOpacity="0.7" />
      <g clipPath="url(#clip1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M24.3096 93.4277C23.9485 93.4277 23.5884 93.435 23.2277 93.4538C22.9528 93.4681 22.5326 93.4111 22.2999 93.5825C22.0582 93.7605 22.0749 94.1249 22.1126 94.3822C22.3122 95.7429 22.7994 96.9496 23.8187 97.9136C25.388 99.3981 27.187 100.152 29.3158 100.488C30.8112 100.724 32.1917 100.397 33.2926 99.3332C34.0969 100.826 34.7071 102.478 35.0132 104.145C35.1643 104.968 35.2104 105.781 35.1838 106.618C35.1667 107.155 35.1371 107.69 35.0796 108.224C35.0547 108.456 34.9363 108.808 35.0272 109.027C35.1338 109.285 35.4934 109.309 35.6548 109.09C35.7891 108.907 35.7644 108.548 35.792 108.332C35.8377 107.973 35.8801 107.613 35.9104 107.252C35.9596 106.668 35.996 106.082 35.9934 105.496C35.9872 104.178 35.6943 102.855 35.2935 101.604C34.5248 99.2048 33.2775 97.0723 31.3376 95.399C30.1448 94.3702 28.7796 93.9535 27.2308 93.7298C26.2665 93.5906 25.2865 93.4277 24.3096 93.4277Z" fill="#89C5CC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.8793 102.969C25.9814 102.969 23.3561 106.11 22.1148 109.375C21.6469 110.606 21.5372 111.958 21.3569 113.25C21.299 113.665 21.3204 114.297 21.1521 114.666C21.091 114.8 20.951 114.848 21.0179 115.031C21.0585 115.141 21.138 115.209 21.2574 115.231C21.5026 115.276 21.8493 115.009 22.0595 114.904C23.8031 114.029 24.9487 112.677 25.8832 111.023C26.4625 109.998 27.0503 108.933 27.3945 107.807C27.6047 107.12 27.7271 106.396 27.7989 105.682C27.8272 105.401 27.8546 105.114 27.8529 104.831C27.8513 104.551 27.7208 104.402 27.627 104.158C28.9277 103.666 30.3957 103.532 31.7132 104.042C32.9318 104.514 33.944 105.426 34.5108 106.571C34.6581 106.869 34.7716 107.183 34.8544 107.504C34.9085 107.714 34.9111 107.986 35.0115 108.176C35.2548 108.636 35.7668 108.328 35.7176 107.9C35.5806 106.705 34.8973 105.506 34.0239 104.676C32.9225 103.629 31.4294 102.969 29.8793 102.969Z" fill="#89C5CC" />
        <rect x="32.6813" y="32" width="45.3188" height="86" rx="2.18084" fill="#DDE3E9" />
        <path fillRule="evenodd" clipRule="evenodd" d="M55.621 111.147C57.8793 111.147 59.7101 109.317 59.7101 107.058C59.7101 104.8 57.8793 102.969 55.621 102.969C53.3626 102.969 51.5319 104.8 51.5319 107.058C51.5319 109.317 53.3626 111.147 55.621 111.147Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M52.0771 39.4522C52.3782 39.4522 52.6223 39.2081 52.6223 38.907C52.6223 38.6059 52.3782 38.3618 52.0771 38.3618C51.776 38.3618 51.5319 38.6059 51.5319 38.907C51.5319 39.2081 51.776 39.4522 52.0771 39.4522Z" fill="white" />
        <rect x="53.9853" y="38.3618" width="5.72471" height="1.09042" rx="0.545211" fill="white" />
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <rect x="35.9015" y="39.6328" width="38.7113" height="14.7207" rx="1.09042" fill="white" />
            <g clipPath="url(#clip4)">
              <rect x="38.3551" y="44.0488" width="22.9002" height="2.2081" rx="0.817816" fill="#DDE3E9" />
              <rect x="38.3552" y="47.729" width="15.3714" height="2.2081" rx="0.817816" fill="#DDE3E9" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M68.7961 48.9014C69.8499 48.9014 70.7043 48.0471 70.7043 46.9932C70.7043 45.9393 69.8499 45.085 68.7961 45.085C67.7422 45.085 66.8878 45.9393 66.8878 46.9932C66.8878 48.0471 67.7422 48.9014 68.7961 48.9014Z" fill="#DDE3E9" />
            <path fillRule="evenodd" clipRule="evenodd" d="M68.7963 47.8109C69.248 47.8109 69.6141 47.4448 69.6141 46.9931C69.6141 46.5414 69.248 46.1753 68.7963 46.1753C68.3447 46.1753 67.9785 46.5414 67.9785 46.9931C67.9785 47.4448 68.3447 47.8109 68.7963 47.8109Z" fill="#F2F2F2" />
          </g>
          <g clipPath="url(#clip5)">
            <rect x="35.9014" y="56.5342" width="38.7113" height="11.4494" rx="1.09042" fill="white" />
            <g clipPath="url(#clip6)">
              <rect x="46.5331" y="59.9692" width="22.9002" height="1.71741" rx="0.817816" fill="#DDE3E9" />
              <rect x="46.5331" y="62.8315" width="15.3714" height="1.71741" rx="0.817816" fill="#DDE3E9" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M41.6263 65.5303C43.4329 65.5303 44.8975 64.0657 44.8975 62.2591C44.8975 60.4524 43.4329 58.9878 41.6263 58.9878C39.8196 58.9878 38.355 60.4524 38.355 62.2591C38.355 64.0657 39.8196 65.5303 41.6263 65.5303Z" fill="#C5CFD6" />
          </g>
          <g clipPath="url(#clip7)">
            <rect x="35.9014" y="70.1646" width="38.7113" height="11.4494" rx="1.09042" fill="white" />
            <g clipPath="url(#clip8)">
              <rect x="38.355" y="73.5991" width="22.9002" height="1.71741" rx="0.817816" fill="#DDE3E9" />
              <rect x="38.355" y="76.4614" width="15.3714" height="1.71741" rx="0.817816" fill="#AFB9C5" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M68.7959 77.7974C69.8498 77.7974 70.7042 76.9431 70.7042 75.8892C70.7042 74.8353 69.8498 73.981 68.7959 73.981C67.742 73.981 66.8877 74.8353 66.8877 75.8892C66.8877 76.9431 67.742 77.7974 68.7959 77.7974Z" fill="#DDE3E9" />
            <path fillRule="evenodd" clipRule="evenodd" d="M68.7962 76.7069C69.2479 76.7069 69.614 76.3408 69.614 75.8891C69.614 75.4374 69.2479 75.0713 68.7962 75.0713C68.3445 75.0713 67.9784 75.4374 67.9784 75.8891C67.9784 76.3408 68.3445 76.7069 68.7962 76.7069Z" fill="#F2F2F2" />
          </g>
          <g clipPath="url(#clip9)">
            <rect x="35.9014" y="83.7949" width="38.7113" height="11.4494" rx="1.09042" fill="white" />
            <g clipPath="url(#clip10)">
              <rect x="46.5331" y="87.23" width="22.9002" height="1.71741" rx="0.817816" fill="#DDE3E9" />
              <rect x="46.5331" y="90.0923" width="15.3714" height="1.71741" rx="0.817816" fill="#DDE3E9" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M41.6263 92.7911C43.4329 92.7911 44.8975 91.3265 44.8975 89.5198C44.8975 87.7131 43.4329 86.2485 41.6263 86.2485C39.8196 86.2485 38.355 87.7131 38.355 89.5198C38.355 91.3265 39.8196 92.7911 41.6263 92.7911Z" fill="#C5CFD6" />
          </g>
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M118.611 49.3495C115.827 51.0276 113.091 51.8384 111.807 51.5074C108.588 50.6774 108.291 38.8983 110.783 34.2546C113.274 29.6109 126.082 27.5868 126.727 36.7875C126.951 39.9807 125.611 42.8305 123.63 45.1559L127.182 61.7124H116.863L118.611 49.3495Z" fill="#F5D0AE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M116.664 28.9024C118.024 28.6794 121.685 28.5261 122.361 28.5262C122.792 28.5262 123.26 28.6247 123.515 28.2285C123.725 27.904 123.648 27.3553 123.711 26.9787C123.783 26.5413 123.886 26.1095 123.99 25.6789C124.287 24.4533 124.735 23.2874 125.363 22.1927C126.54 20.139 128.265 18.5303 130.442 17.5942C132.846 16.5606 135.567 16.324 138.153 16.5336C140.921 16.7579 143.426 17.6715 146.039 18.5313C148.529 19.3506 151.137 19.6731 153.742 19.273C156.366 18.8699 158.348 17.4477 160.562 16.0979C162.828 14.716 165.417 13.8121 168.095 14.0199C170.596 14.2139 172.9 15.2463 174.968 16.6255C176.902 17.9158 178.498 19.4683 179.4 21.6533C180.369 24.0037 180.368 26.5836 178.774 28.6608C176.463 31.6717 172.174 32.4503 168.605 32.3729C166.54 32.3282 164.513 31.9514 162.564 31.2659C159.965 30.3517 157.719 28.8022 155.287 27.545C154.146 26.9551 152.967 26.4705 151.73 26.1215C150.53 25.7827 149.349 25.6273 148.262 26.3521C147.113 27.1188 146.084 27.872 144.769 28.3519C143.508 28.8124 142.172 29.07 140.828 29.0596C138.214 29.0394 135.637 28.2248 133.098 27.6792C131.027 27.2344 128.812 26.7911 126.701 27.2187C125.82 27.3971 124.534 27.7656 124.216 28.681C129.57 29.6514 131.673 36.2735 130.164 41.1347C129.349 43.7585 124.452 46.8256 122.547 45.4463C121.341 44.5724 122.073 43.4252 122.547 42.6678C123.455 41.2175 123.766 39.3545 122.086 38.3448C120.637 37.4739 119.114 39.542 119.085 39.5397C118.687 39.5084 115.247 37.5625 112.876 34.7231C110.538 34.8618 110.183 33.3198 110.741 32.0588C111.3 30.7977 114.785 29.2107 116.664 28.9024Z" fill="#191847" />
      <path fillRule="evenodd" clipRule="evenodd" d="M131.481 172.204C113.198 172.204 98.3763 158.057 98.3763 140.605C98.3763 123.153 113.198 109.005 131.481 109.005C149.764 109.005 164.585 123.153 164.585 140.605C164.585 158.057 149.764 172.204 131.481 172.204Z" fill="#C5CFD6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M80.0133 138.919C78.4143 144.52 74.4305 155.136 68.922 167.328L63.6874 165.801C68.2669 137.746 72.0781 119.173 74.2612 113.519C75.7036 109.784 86.616 110.222 85.3606 117.737C84.8342 120.889 82.7513 129.328 80.0133 138.919ZM101.528 119.048C110.065 120.817 127.764 127.069 132.818 129.066C135.994 130.322 133.947 134.58 131.237 134.207C130.778 134.144 129.155 133.963 126.732 133.692C118.952 132.821 102.928 131.029 90.7754 129.28C84.3764 128.359 87.8186 116.373 94.4627 117.641C96.698 118.068 99.0755 118.54 101.528 119.048Z" fill="#F5D0AE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M73.9266 110.354C74.7993 107.506 114.472 97.3965 114.472 97.3965H117.723V100.426L90.3851 114.342C90.4215 114.792 90.3992 115.266 90.311 115.767C90.5085 126.084 86.093 133.791 81.7213 141.422C78.8625 146.412 76.0224 151.369 74.5034 157.002L64.4595 155.504C64.7521 154.756 65.2147 151.382 65.8473 146.769C67.4422 135.138 70.1174 115.629 73.8704 110.468C73.8834 110.45 73.8965 110.432 73.91 110.414C73.9148 110.394 73.9203 110.374 73.9266 110.354Z" fill="#7D9E1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M115.452 122.608C117.953 122.107 120.256 121.66 122.228 121.314C134.959 119.081 138.672 112.266 136.477 97.3965H113.019C109.187 98.4972 89.273 106.848 78.9408 111.29C72.1025 114.23 74.2579 124.827 78.1303 127.586C78.1513 127.755 78.221 127.87 78.3452 127.921C93.0276 134.007 105.548 133.553 113.588 133.261C116.956 133.139 119.537 133.045 121.162 133.473L123.709 125.44L115.452 122.608Z" fill="#99B93D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M75.4893 78.7822L91.0675 85.6236L86.0933 91.9961L74.4261 83.0508C68.5985 81.9804 65.8959 81.0609 66.3182 80.2921C66.6742 79.644 67.3367 79.6074 67.947 79.5738C68.4224 79.5476 68.866 79.5231 69.1084 79.2126C69.6619 78.5035 68.2959 76.3131 66.9107 74.2899C65.5255 72.2666 66.8238 72.3397 67.5837 72.5817C70.1251 73.996 72.7603 76.0628 75.4893 78.7822ZM145.238 89.2559L154.048 87.5079C160.023 103.256 163.585 111.886 164.734 113.397C165.577 114.506 170.775 117.361 174.293 118.886C175.705 119.498 175.402 120.691 174.988 121.073C172.375 123.481 169.947 122.637 167.303 121.718C166.537 121.452 165.754 121.18 164.943 120.978C162.844 120.457 162.205 121.537 161.601 122.557C161.067 123.46 160.56 124.317 159.091 123.975C159.158 123.687 159.24 123.364 159.328 123.015C159.901 120.762 160.745 117.44 159.492 115.509C158.85 114.518 154.098 105.767 145.238 89.2559Z" fill="#F5D0AE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M121.293 54.5464L117.576 53.9088C109.478 61.7636 106.174 80.6346 100.778 83.1325C95.6758 85.4942 88.4433 83.2088 79.8464 80.123L77.2136 86.0053C85.0247 94.456 101.665 101.595 108.411 97.5197C119.195 91.0033 121.684 67.7287 121.293 54.5464Z" fill="#B792AF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M137.5 101.266C137.5 101.266 115.14 101.266 104.605 101.266C103.102 101.266 103.385 99.0952 103.603 98C106.11 85.36 115.9 71.7346 115.9 53.7418L125.388 52.2539C133.236 64.8759 135.95 80.4161 137.5 101.266Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M124.287 52.2539H126C147.282 65.3686 160.144 85.6983 164.585 113.243L157.69 114.593C153.123 109.272 147.486 100.564 140.78 88.4677C142.838 95.7543 144.34 103.228 144.808 110.294H115.198C109.466 95.8079 122.243 52.2539 122.243 52.2539L124.287 52.2539Z" fill="#E9ADDB" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="203" height="124" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="59" height="86" fill="white" transform="translate(19 32)" />
      </clipPath>
      <clipPath id="clip2">
        <rect width="38.7113" height="55.6115" fill="white" transform="translate(35.9015 39.6328)" />
      </clipPath>
      <clipPath id="clip3">
        <rect width="38.7113" height="14.7207" fill="white" transform="translate(35.9015 39.6328)" />
      </clipPath>
      <clipPath id="clip4">
        <rect width="22.9002" height="5.88828" fill="white" transform="translate(38.3551 44.0488)" />
      </clipPath>
      <clipPath id="clip5">
        <rect width="38.7113" height="11.4494" fill="white" transform="translate(35.9014 56.5342)" />
      </clipPath>
      <clipPath id="clip6">
        <rect width="22.9002" height="4.57977" fill="white" transform="translate(46.5331 59.9692)" />
      </clipPath>
      <clipPath d="clip7">
        <rect width="38.7113" height="11.4494" fill="white" transform="translate(35.9014 70.1646)" />
      </clipPath>
      <clipPath id="clip8">
        <rect width="22.9002" height="4.57977" fill="white" transform="translate(38.355 73.5991)" />
      </clipPath>
      <clipPath id="clip9">
        <rect width="38.7113" height="11.4494" fill="white" transform="translate(35.9014 83.7949)" />
      </clipPath>
      <clipPath id="clip10">
        <rect width="22.9002" height="4.57977" fill="white" transform="translate(46.5331 87.23)" />
      </clipPath>
    </defs>
  </svg>
);

export default Select;
