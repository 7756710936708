/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { Stack } from '../../../styles';
import { useReport } from '../../../queries/use-report';
import { BudgetAndSpending } from './BudgetAndSpending';
import { SectionDivider } from '../../SectionDivider';
import { SpendingTrend } from './SpendingTrend';
import { Program } from './Program';
import { OrdersCountTrend } from './OrdersCountTrend';
import { PROGRAM_SORTING } from '../../utils';
import { trackViewedSummaryTab } from '../../../events';

export const Summary = withI18n(({
  organisation,
  translate,
  filters,
}) => {
  const { data: budgetAndSpending } = useReport(
    organisation.uuid,
    'summary/budget_and_spending',
    filters,
  );

  const { data: programs } = useReport(
    organisation.uuid,
    'summary/programs',
    filters,
  );

  useEffect(() => trackViewedSummaryTab(), []);

  const sortPrograms = (programs) => programs.sort((a, b) => PROGRAM_SORTING[a.type] - PROGRAM_SORTING[b.type]);

  const filterNonTotalPrograms = (programs) => programs.filter((program) => program.type !== 'Total');

  return (
    <Stack className="p-3" data-testid="reporting-summary">
      {budgetAndSpending && programs && (
      <BudgetAndSpending
        budgetAndSpending={budgetAndSpending}
        programs={programs}
      />
        )}
      <SectionDivider
        text={translate('financialReportsTermsSpendBreakdown')}
      />
      {budgetAndSpending && programs && (
      <SpendingTrend
        budgetAndSpending={budgetAndSpending}
        programs={sortPrograms(programs)}
      />
        )}
      {programs &&
          sortPrograms(filterNonTotalPrograms(programs)).map((program) => (
            <Program key={program.type} program={program} />
          ))}
      <SectionDivider text={translate('financialReportsTermsOrders')} />
      {programs && <OrdersCountTrend programs={programs} />}
    </Stack>
  );
});

Summary.propTypes = {
  organisation: object.isRequired,
  filters: object.isRequired,
};
