/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

const SIZE_PLACEHOLDER = 2;

const MenuItemsSkeletal = ({ size = 4 }) => (
  <div className="cart-order-list">
    {Array.from(new Array(size)).map((_, index) => (
      <div key={index} className="tw-flex tw-flex-col tw-gap-2 tw-mb-8 tw-animate-pulse">
        <div
          className={classNames('tw-flex tw-justify-between tw-items-center', {
            'tw-bg-gray-50 tw-py-3 tw-px-6 tw-mb-2': true,
          })}
        >
          <span className={classNames('tw-m-0 tw-bg-gray-200 tw-rounded', {
            'tw-h-6 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
          })}
          />
          <span className={classNames('tw-m-0 tw-bg-gray-100 tw-rounded', {
            'tw-h-6 tw-w-24 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
          })}
          />
        </div>
        {
          Array.from(new Array(SIZE_PLACEHOLDER)).map((_, index) => (
            <div
              key={index}
              className={classNames('tw-px-6', {
                'tw-border-b tw-border-gray-200': index !== SIZE_PLACEHOLDER,
              })}
            >
              <div className="media tw-flex-col md:tw-flex-row">
                <div className="card-thumbnail md:tw-m-2 md:tw-mr-3">
                  <div className="tw-w-32 tw-h-20 tw-bg-gray-50 tw-rounded-md tw-mt-1" />
                </div>
                <div className="media-body tw-w-full">
                  <div className="order-item !tw-pb-4">
                    <div className="tw-flex tw-flex-row md:tw-flex-wrap md:tw-justify-between tw-items-start">
                      <div className="tw-flex tw-flex-col tw-gap-2 tw-grow">
                        <span className={classNames('tw-bg-gray-100 tw-rounded', {
                          'tw-h-4 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                        })}
                        />
                        <span className={classNames('tw-bg-gray-100 tw-rounded', {
                          'tw-h-4 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                        })}
                        />
                        <span className={classNames('tw-bg-gray-100 tw-rounded', {
                          'tw-h-4 tw-w-full tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                        })}
                        />
                        <span className={classNames('tw-bg-gray-100 tw-rounded', {
                          'tw-h-4 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                        })}
                        />
                        <span className={classNames('tw-bg-gray-100 tw-rounded', {
                          'tw-h-4 tw-w-full tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                        })}
                        />
                      </div>
                      <span className={classNames('tw-bg-gray-100 tw-rounded', {
                        'tw-h-4 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
                      })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    ))}
  </div>
);

MenuItemsSkeletal.propTypes = {
  size: string,
};

export default MenuItemsSkeletal;
