/* eslint camelcase: 0 */
/* eslint max-len: 0 */
/* eslint no-cond-assign: 0 */

import Immutable from 'immutable';

import { getIndex } from 'libs/support/findUtil';
import * as orderableVariationReducer from 'packs/common/reducers/orderableVariation/orderableVariationReducer';
import { processValidAddOn } from './addOnReducer';

const updateStateForQuantity = (state, forceValid) => {
  const selectedMappings = [
    {
      selectedMenuItem: 'menu_item_choices',
      selectedCartVariation: 'cart_variation_option_choices',
    },
    {
      selectedMenuItem: 'menu_item_item_add_ons',
      selectedCartVariation: 'cart_variation_item_add_on_options',
    },
    {
      selectedMenuItem: 'menu_item_service_add_ons',
      selectedCartVariation: 'cart_variation_service_add_on_options',
    },
  ];

  selectedMappings.forEach((selectedMapping) => {
    const { selectedMenuItem, selectedCartVariation } = selectedMapping;
    const options = [];

    state.getIn(['selectedMenuItem', selectedMenuItem]).forEach((q) => {
      options.push(q.getIn(['choice', 'uuid']));
    });

    options.forEach((parentId, index) => {
      state.updateIn(['selectedMenuItem', selectedMenuItem, index, 'choice'], data => {
        const selectedOptions = state.getIn(['selectedCartVariation', selectedCartVariation])
          .filter(option => option.get('parent_id') === parentId);

        return processValidAddOn(data, selectedOptions, forceValid);
      });
    });
  });
};

export function fetchSuccess(response, defaultHash, $$state, forceValid = false) {
  const { cart_variation, menu_item } = response;

  return $$state.withMutations(state => {
    state
      .merge(defaultHash)
      .merge({ showMenuItemForm: true })
      .set('selectedCartVariation', Immutable.fromJS(cart_variation))
      .set('selectedMenuItem', Immutable.fromJS(menu_item));

    updateStateForQuantity(state, forceValid);

    return state;
  });
}

export function saving($$state) {
  return $$state.withMutations(state => {
    state
      .set('saveErrors', Immutable.fromJS({}))
      .merge({ isSavingCartVariation: true });
  });
}

export function save(response, defaultHash, $$state) {
  const { cart_variation } = response;

  const newData = Immutable.fromJS(cart_variation);
  const index = getIndex(cart_variation.uuid, $$state.get('cartVariations'));

  return $$state.withMutations(state => {
    state
      .updateIn(['cartVariations'], cartVariations => {
        if (index >= 0) return cartVariations.update(index, () => newData);

        const cartUserId = state.get('cartUserId');
        const index2 = cartVariations.findLastIndex(data => data.get('cart_user_id') === cartUserId);

        if (index2 < 0) {
          if (cartUserId === cart_variation.cart_user_id) return cartVariations.unshift(newData);
          return cartVariations.push(newData);
        }

        return cartVariations.splice(index2 + 1, 0, newData);
      })
      .merge(defaultHash)
      .merge({ showMenuItemForm: false })
      .merge({ totalQuantity: state.get('cartVariations').reduce((total, variation) => (total + variation.get('quantity')), 0) });
  });
}

export function resetUserCartVariations(response, defaultHash, $$state) {
  const { uuid } = response;

  return $$state.withMutations(state => {
    let index = -1;

    const findUserCartVariations = (cv) => cv.get('user_meal_cart_id') === uuid;

    while ((index = state.get('cartVariations').findIndex(findUserCartVariations)) >= 0) {
      state.deleteIn(['cartVariations', index]);
    }

    state
      .merge({ totalQuantity: state.get('cartVariations').reduce((total, variation) => (total + variation.get('quantity')), 0) })
      .merge(defaultHash);
  });
}

export function destroy(response, defaultHash, $$state) {
  const { cart_variation } = response;
  const index = getIndex(cart_variation.uuid, $$state.get('cartVariations'));

  return $$state.withMutations(state => {
    state
      .deleteIn(['cartVariations', index])
      .merge({ totalQuantity: state.get('cartVariations').reduce((total, variation) => (total + variation.get('quantity')), 0) })
      .merge(defaultHash);
  });
}

export function error(response, defaultHash, $$state) {
  return $$state.withMutations(state => {
    state
      .merge(defaultHash)
      .set('saveErrors', Immutable.fromJS(response.errors));
  });
}

export function addTemporaryItemAddOnOptionPhoto(response, defaultHash, $$state) {
  return orderableVariationReducer.addTemporaryItemAddOnOptionPhoto(response, defaultHash, $$state, 'cart');
}

export function addTemporaryItemAddOnOptionPhotoFailure(response, $$state) {
  return orderableVariationReducer.addTemporaryItemAddOnOptionPhotoFailure(response, $$state, 'cart');
}

export function deleteItemAddOnOptionPhoto(response, defaultHash, $$state) {
  return orderableVariationReducer.deleteItemAddOnOptionPhoto(response, defaultHash, $$state, 'cart');
}

export function updateItemAddOnOptionText(response, defaultHash, $$state) {
  return orderableVariationReducer.updateItemAddOnOptionText(response, defaultHash, $$state, 'cart');
}
