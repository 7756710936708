import React from 'react';
import { any, string } from 'prop-types';
import classNames from 'classnames';

const TdElement = ({ className, children }) => (
  <td className={classNames('align-middle !tw-border-0 md:!tw-border-b md:!tw-border-gray-200', className)}>
    { children }
  </td>
);

TdElement.propTypes = {
  children: any,
  className: string,
};

export default TdElement;
