/* eslint-disable max-len */
import React from 'react';
import TooltipLite from 'react-tooltip-lite';
import { func, number, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { InfoIcon } from 'libs/components/Icons';

const Content = ({
  mealCountCap,
  vendorName,
  translate,
}) => (
  <div className="d-flex justify-content-between align-items-center text-center" style={{ width: 'max-content', maxWidth: '200px' }}>
    { translate('groupedOrdersCountCapTooltip', { vendor_name: vendorName, cap: mealCountCap }) }
  </div>
);

const MealCountCapHeader = ({
  orderedCount,
  mealCountCap,
  vendorName,
  translate,
}) => (
  <div className="d-flex align-items-center">
    <p className="small text-muted text-uppercase mb-0">
      { translate('groupedOrdersCountCapHeader', { ordered_count: orderedCount, cap: mealCountCap }) }
    </p>
    <span className="ml-1">
      <TooltipLite
        tagName="div"
        content={<Content {...{ mealCountCap, vendorName, translate }} />}
        direction="up"
      >
        <InfoIcon />
      </TooltipLite>
    </span>
  </div>
);

Content.propTypes = {
  vendorName: string.isRequired,
  mealCountCap: number.isRequired,
  translate: func.isRequired,
};

MealCountCapHeader.propTypes = {
  vendorName: string.isRequired,
  orderedCount: number.isRequired,
  mealCountCap: number.isRequired,
  translate: func.isRequired,
};

export default withI18n(MealCountCapHeader);
