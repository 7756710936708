import Immutable from 'immutable';

export const $$initialState = Immutable.fromJS({
  alertMsg: null,
});

export default function userMealOrderReducer($$state = $$initialState, action = null) {
  const { type } = action;

  switch (type) {
    default: {
      return $$state;
    }
  }
}
