/* eslint max-len: 0 */

import React from 'react';

const CloseCircle = (_props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7816 4.21844L19.7816 4.21837C15.49 -0.0727906 8.51003 -0.0727906 4.21844 4.21837L4.21837 4.21844C-0.0727906 8.51003 -0.0727906 15.49 4.21837 19.7816L4.21844 19.7816C8.51003 24.0728 15.49 24.0728 19.7816 19.7816L19.7816 19.7816C24.0728 15.49 24.0728 8.51003 19.7816 4.21844Z" fill="#C3423F" stroke="white" strokeWidth="2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.4708 16.5735L12.1006 13.2033L8.5702 16.7341C8.21571 17.0886 7.64063 17.0886 7.28613 16.7341C6.93164 16.3796 6.93164 15.8046 7.28613 15.4501L10.817 11.9197L7.44681 8.54949C7.09232 8.19499 7.09232 7.62036 7.44681 7.26587C7.8013 6.91138 8.37594 6.91138 8.73043 7.26587L12.1006 10.636L15.3101 7.42655C15.6646 7.07206 16.2392 7.07206 16.5942 7.42655C16.9487 7.78104 16.9487 8.35567 16.5942 8.71016L13.3842 11.9197L16.7544 15.2898C17.1089 15.6443 17.1089 16.219 16.7544 16.5735C16.3999 16.9279 15.8253 16.9279 15.4708 16.5735Z" fill="white" />
  </svg>

);

export default CloseCircle;
