import Immutable from 'immutable';

export const groupMenuItemsByVendor = (menuItems, vendorsStore, cartsStore = null) => (
  menuItems.reduce((hash, menuItem) => {
    const vendorId = menuItem.get('vendor_id');

    let isMealCountCapReached;
    let orderedCount;
    let mealCountCap;

    if (cartsStore) {
      const cartId = cartsStore.getIn(['byVendorId', vendorId, 'cartId']);
      const cart = cartsStore.getIn(['byId', cartId]);

      if (cart) {
        isMealCountCapReached = cart.get('mealCountCapReached');
        orderedCount = cart.get('orderedCount');
        mealCountCap = cart.get('mealCountCap');
      }
    }

    if (!hash.get(vendorId)) {
      const vendor = vendorsStore.getIn(['byId', vendorId]);

      if (!vendor) return hash;

      // eslint-disable-next-line no-param-reassign
      hash = hash.set(vendorId, vendor.merge({
        menuItems: [], isMealCountCapReached, orderedCount, mealCountCap,
      }));
    }

    return hash.updateIn([vendorId, 'menuItems'], currentMenuItems => (
      currentMenuItems.push(menuItem)
    ));
  }, Immutable.fromJS({}))
    .valueSeq().sort((a, b) => a.get('ordinal') - b.get('ordinal'))
);

export const selectedMenuItems = (menuItemsStore, selectedOrderableVariations) => (
  selectedOrderableVariations.reduce((arr, selectedOrderableVariation) => {
    const menuItemId = selectedOrderableVariation.get('menuItemId');
    const menuItem = menuItemsStore.getIn(['byId', menuItemId]);

    if (!menuItem) return arr;

    return arr.push(menuItem.merge({ orderableVariationId: selectedOrderableVariation.get('uuid') }));
  }, Immutable.fromJS([]))
);

/*
 * Compute menu Items, add buffer in each 2 slice.
 * @params [Immutable] items
 *
 * Ex.
 *   [Immutable, Immutable, null, Immutable]
 */
export const groupedItems = (items) => {
  const newArray = [];
  for (let idx = 0; idx < items.size; idx += 2) {
    newArray.push(items.get(idx));
    newArray.push(items.get(idx + 1));
  }
  return newArray;
};
