import { useMutation } from 'react-query';
import { httpClient } from 'libs/support/httpClient';

const useUpdateSettingsMutation = () => useMutation(async (updatedData) => {
  try {
    const response = await httpClient.put('/account/notification_settings', updatedData);

    return response.data;
  } catch (error) {
    throw new Error(error.message || 'Network response was not ok');
  }
});

export default useUpdateSettingsMutation;
