/* eslint max-len: 0 */

import React from 'react';

const AmericanExpress = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="33" viewBox="0 0 52 33" fill="none" style={{ fill: '#fff' }}>
    <rect width="52" height="33" fill="white" />
    <g filter="url(#filter0_d)">
      <rect width="52" height="32" rx="2.62564" fill="white" />
      <rect x="0.5" y="0.5" width="51" height="31" rx="2.12564" stroke="#DBDBDB" />
    </g>
    <path d="M27.8527 11H24.8619L22.6118 17.1639L20.1907 11H17.2V19.924L14.1096 11H11.304L8 20.2H10.0223L10.6917 18.1024H14.5368L15.3059 20.2H19.0229V12.7111L21.6576 20.2H23.395L26.0156 12.84V20.2H27.8527V11ZM40.2 13.9808L38.0638 11H29.4904V20.2H37.779L40.1288 17.1824L42.4929 20.2H44.7003L41.325 15.5448L44.8 11.0001H42.4929L40.2 13.9808ZM35.6855 18.36H31.3276V16.336H35.6855V14.496H31.3276V12.84H35.6855V11.2759L38.9182 15.4527L35.6855 19.6479V18.36ZM12.657 12.5087L13.9103 16.2439H11.304L12.657 12.5087Z" fill="url(#paint0_radial)" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="52" height="32.6564" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="0.65641" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 0.917647 0 0 0 0 0.921569 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.1338 12.5789) scale(43.6153 10.9294)">
        <stop stopColor="#86D4F7" />
        <stop offset="0.0754" stopColor="#7FCFF4" />
        <stop offset="0.1814" stopColor="#6BC4EE" />
        <stop offset="0.305" stopColor="#43B3E6" />
        <stop offset="0.3708" stopColor="#19A9E1" />
        <stop offset="0.4361" stopColor="#139FDA" />
        <stop offset="0.5634" stopColor="#0E90D0" />
        <stop offset="0.6964" stopColor="#0C86C8" />
        <stop offset="0.8374" stopColor="#0A80C4" />
        <stop offset="1" stopColor="#0A7EC2" />
      </radialGradient>
    </defs>
  </svg>
);

export default AmericanExpress;
