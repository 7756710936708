/* eslint-disable max-len */
import Immutable from 'immutable';

export function findCartVariation(cartVariationsStore, menuItemId) {
  const index = cartVariationsStore
    .get('cartVariations')
    .findLastIndex((data) =>
      data.getIn(['menu_item', 'uuid']) === menuItemId);

  let cartVariation = {
    quantity: 0,
    cart_variation_option_choices: [],
    cart_variation_item_add_on_options: [],
    cart_variation_service_add_on_options: [],
  };

  if (index >= 0) {
    cartVariation = cartVariationsStore.get('cartVariations').get(index);
  }

  return Immutable.fromJS(cartVariation);
}

export function findCart(cartsStore, vendorId) {
  const index = cartsStore.get('carts').findLastIndex((data) => data.get('vendor_id') === vendorId);

  let cart = {};

  if (index >= 0) {
    cart = cartsStore.get('carts').get(index);
  }

  return Immutable.fromJS(cart);
}

export const setImageUrl = (path) => {
  if (!path) return;

  // eslint-disable-next-line consistent-return
  return (/^htt(p|ps):\/\//i).test(path) ? path : `https://${path}`;
};
