/* eslint max-len: 0 */

import React from 'react';

const TrackingTruck = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3173_11023)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3872 11.8916V13.3403C18.3872 13.7946 18.2262 14.1489 17.9125 14.3505C17.6544 14.5164 17.3774 14.5152 17.2952 14.5148C17.2918 14.5148 17.2887 14.5148 17.2859 14.5148L16.3235 14.5148C16.0386 14.5148 15.8076 14.2838 15.8076 13.9989C15.8076 13.7139 16.0386 13.4829 16.3235 13.4829L17.2859 13.4829C17.3116 13.4829 17.3277 13.4826 17.3405 13.4814C17.3471 13.4579 17.3554 13.4137 17.3554 13.3403V11.8916H18.3872Z" fill="#7D9E1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.3618 3.57921V11.49H10.9924V3.57921H1.3618ZM0.788555 2.54736C0.535278 2.54736 0.329956 2.75268 0.329956 3.00596V12.0633C0.329956 12.3166 0.535278 12.5219 0.788555 12.5219H12.0242V3.00596C12.0242 2.75268 11.8189 2.54736 11.5656 2.54736H0.788555Z" fill="#7D9E1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0497 5.87218C11.0497 5.4606 11.3833 5.12695 11.7949 5.12695H14.2401C14.5099 5.12695 14.7585 5.27271 14.8903 5.50806L16.3399 8.09653L17.9152 8.7171C18.2 8.82931 18.3873 9.10431 18.3873 9.41046V11.7766C18.3873 12.1882 18.0536 12.5219 17.642 12.5219H11.7949C11.3833 12.5219 11.0497 12.1882 11.0497 11.7766V5.87218ZM12.0815 6.1588V11.49H17.3554V9.60561L15.8666 9.01911C15.7067 8.95612 15.5735 8.8398 15.4895 8.68986L14.0721 6.1588H12.0815Z" fill="#7D9E1D" />
      <rect x="0.44458" y="13.4963" width="2.06369" height="1.03185" rx="0.515924" fill="#7D9E1D" />
      <path d="M5.60388 13.9549C5.60388 13.7017 5.8092 13.4963 6.06248 13.4963H12.4829C12.7361 13.4963 12.9415 13.7017 12.9415 13.9549V14.0696C12.9415 14.3229 12.7361 14.5282 12.4829 14.5282H6.06248C5.8092 14.5282 5.60388 14.3229 5.60388 14.0696V13.9549Z" fill="#7D9E1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.9988 15.1586C4.69532 15.1586 5.25995 14.594 5.25995 13.8975C5.25995 13.201 4.69532 12.6363 3.9988 12.6363C3.30229 12.6363 2.73766 13.201 2.73766 13.8975C2.73766 14.594 3.30229 15.1586 3.9988 15.1586ZM3.9988 16.1905C5.26519 16.1905 6.2918 15.1639 6.2918 13.8975C6.2918 12.6311 5.26519 11.6045 3.9988 11.6045C2.73242 11.6045 1.70581 12.6311 1.70581 13.8975C1.70581 15.1639 2.73242 16.1905 3.9988 16.1905Z" fill="#7D9E1D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3172 15.1586C15.0137 15.1586 15.5783 14.594 15.5783 13.8975C15.5783 13.201 15.0137 12.6363 14.3172 12.6363C13.6207 12.6363 13.056 13.201 13.056 13.8975C13.056 14.594 13.6207 15.1586 14.3172 15.1586ZM14.3172 16.1905C15.5835 16.1905 16.6102 15.1639 16.6102 13.8975C16.6102 12.6311 15.5835 11.6045 14.3172 11.6045C13.0508 11.6045 12.0242 12.6311 12.0242 13.8975C12.0242 15.1639 13.0508 16.1905 14.3172 16.1905Z" fill="#7D9E1D" />
    </g>
    <defs>
      <clipPath id="clip0_3173_11023">
        <rect width="18" height="18" fill="white" transform="translate(0.329956)" />
      </clipPath>
    </defs>
  </svg>

);

export default TrackingTruck;
