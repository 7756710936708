/* eslint-disable no-nested-ternary */
import React, { Fragment, useState } from 'react';
import { bool, number, string, array } from 'prop-types';
import Item from './Item';
import AccountPage from './AccountPage';

const MEAL_PLAN = 'meal_plan';

const MobileNav = (props) => {
  const {
    currentPath,
    contactSupportPath,
    notificationSettingsPath,
    fullName,
    roles,
    email,
    homePath,
    isAdmin,
    logoutPath,
    mealPlansCount,
    mealPlansPath,
    orderNowPath,
    teamPath,
    programType,
    version,
  } = props;

  const [isAccountPageVisible, setAccountPageVisibility] = useState(false);

  const toggleAccountPageVisibility = (e) => {
    e.preventDefault();
    setAccountPageVisibility(!isAccountPageVisible);
  };

  const handleClickMeals = (e) => {
    e.preventDefault();
    document.getElementById('meal-plan-programs').showModal();
    document.getElementById('meal-plan-programs').addEventListener('click', (event) => {
      if (event.target.nodeName === 'DIALOG') {
        event.target.close();
      }
    });
  };

  return (
    <Fragment>
      <div className="mobile-nav">
        {version !== 'v2'
          ? (
            <Item
              code="home"
              isActive={!isAccountPageVisible && homePath === currentPath}
              path={homePath}
            />
          )
          : (mealPlansCount > 1)
            ? (
              <Item
                code="meals"
                isActive={!isAccountPageVisible && orderNowPath === currentPath}
                path="#"
                onClick={handleClickMeals}
              />
            )
            : (
              <Item
                code="home"
                isActive={!isAccountPageVisible && homePath === currentPath}
                path={homePath}
              />
            )
        }

        {/* todo set this to report */}
        {
          programType === MEAL_PLAN && (
            <Item
              code="order_now"
              isActive={!isAccountPageVisible && orderNowPath === currentPath}
              path={orderNowPath}
            />
          )
        }
        {
          // todo set path accordingly
          programType !== MEAL_PLAN && (
            <Item
              code="reporting"
              isActive={false}
              path={homePath}
            />
          )
        }
        {
          isAdmin &&
            <Item
              code="team"
              isActive={!isAccountPageVisible && teamPath === currentPath}
              path={teamPath}
            />
        }
        <Item
          code="account"
          isActive={isAccountPageVisible}
          onClick={toggleAccountPageVisibility}
        />
      </div>

      <AccountPage
        isVisible={isAccountPageVisible}
        {
          ...{
            contactSupportPath,
            notificationSettingsPath,
            fullName,
            roles,
            email,
            logoutPath,
            mealPlansCount,
            mealPlansPath,
          }
        }
      />
    </Fragment>
  );
};

MobileNav.propTypes = {
  currentPath: string.isRequired,
  contactSupportPath: string.isRequired,
  notificationSettingsPath: string.isRequired,
  fullName: string.isRequired,
  roles: array.isRequired,
  email: string.isRequired,
  homePath: string.isRequired,
  isAdmin: bool.isRequired,
  logoutPath: string.isRequired,
  mealPlansCount: number,
  mealPlansPath: string.isRequired,
  orderNowPath: string.isRequired,
  teamPath: string.isRequired,
  programType: string.isRequired,
  version: string.isRequired,
};

export default MobileNav;
