import React, { useEffect, useMemo, useState } from 'react';

import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import moment from 'moment';
import _map from 'lodash/map';
import _get from 'lodash/get';
import { ExpandableCard } from './expandableCard';
import DeliveryReport from './DeliveryReport/deliveryReport';
import StockCount from './StockCount/stockCount';
import PhotosFeedback from './PhotosFeedback/photosFeedback';


const DATE_FORMAT = 'YYYY-MM-DD';

export default function DailyLog({
  pantryProgram,
  dailyLog,
  ordersForDelivery,
  masterSupplyItems,
  fetchOrdersForDelivery,
  fetchMasterSupplyItems,
  fetchDailyLog,
  saveDailyLog,
}) {
  const [logDate, setLogDate] = useState(moment().format(DATE_FORMAT));

  useEffect(() => {
    fetchOrdersForDelivery(pantryProgram.uuid, logDate);
    fetchMasterSupplyItems(pantryProgram.uuid);
    fetchDailyLog(pantryProgram.uuid, logDate);
  }, [logDate]);

  const isDeliveryReportCompleted = useMemo(() => {
    const submittedOrders = _map(_get(dailyLog, 'status.deliveryReport.data', []), 'orderId');
    return submittedOrders.includes(..._map(ordersForDelivery, 'uuid'));
  }, [ordersForDelivery, dailyLog]);

  const isStockCountCompleted = _get(dailyLog, 'status.stockCount.status') === 'submitted';

  const isPhotoFeedBackCompleted = _get(dailyLog, 'status.status') === 'submitted';

  return (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="w-100 h-100 shadow-sm bg-white rounded" id="daily-log-tasks-box">
          <div className="p-3 bg-light">
            <h5 className="text-muted">Date</h5>
            <div>
              <Datetime
                viewMode="days"
                defaultValue={moment(logDate)}
                dateFormat="ddd, DD-MMM-YYYY"
                timeFormat=""
                onChange={(date) => {
                  setLogDate(date.format(DATE_FORMAT));
                }}
                closeOnSelect
              />
            </div>
          </div>
          <div className="py-3">
            <ExpandableCard
              index={1}
              title="Submit Delivery Report"
              subtitle="To do before doing stock count."
              isCompleted={isDeliveryReportCompleted}
            >
              <DeliveryReport
                pantryProgram={pantryProgram}
                dailyLog={dailyLog}
                ordersForDelivery={ordersForDelivery}
                saveDailyLog={saveDailyLog}
                logDate={logDate}
              />
            </ExpandableCard>
            <ExpandableCard
              index={2}
              title="Submit Stock Count"
              subtitle="Do this near the end of your shift."
              isCompleted={isStockCountCompleted}
            >
              <StockCount
                pantryProgram={pantryProgram}
                dailyLog={dailyLog}
                masterSupplyItems={masterSupplyItems}
                logDate={logDate}
                saveDailyLog={saveDailyLog}
              />
            </ExpandableCard>
            <ExpandableCard
              index={3}
              title="Photos and Feedback"
              subtitle="Provide additional information required."
              isCompleted={isPhotoFeedBackCompleted}
            >
              <PhotosFeedback
                dailyLog={dailyLog}
                logDate={logDate}
                saveDailyLog={saveDailyLog}
                pantryProgram={pantryProgram}
              />
            </ExpandableCard>
          </div>
        </div>
      </div>
    </div>
  );
}

DailyLog.propTypes = {
  pantryProgram: PropTypes.object.isRequired,
  dailyLog: PropTypes.object.isRequired,
  ordersForDelivery: PropTypes.array.isRequired,
  masterSupplyItems: PropTypes.array.isRequired,
  fetchOrdersForDelivery: PropTypes.func.isRequired,
  fetchMasterSupplyItems: PropTypes.func.isRequired,
  fetchDailyLog: PropTypes.func.isRequired,
  saveDailyLog: PropTypes.func.isRequired,
};
