/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

import actions from 'packs/meals/actions';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import BaseComponent from 'libs/components/BaseComponent';
import MenuItemForm from 'packs/common/components/MenuItem/MenuItemForm/MenuItemForm';
import { fetchPdfLink } from 'libs/services/labelsApi';
import CartHeader from './CartHeader/CartHeader';
import MealCart from './MealCart/MealCart';
import MenuItems from './MenuItems/MenuItems';
import Flash from '../../Shared/Flash';

/**
 * PlannedMeal
 */

class PlannedMeal extends BaseComponent {
  static propTypes = {
    mealStore: PropTypes.object.isRequired,
    cartsStore: PropTypes.object.isRequired,
    cartVariationsStore: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      downloadableLink: null,
      isProcessing: false,
      errorMsg: null,
    };
  }

  handleOnClickDownloadPdf = () => {
    const { mealStore, userRoleIn } = this.props;
    if (this.state.isProcessing) return;
    if (!userRoleIn(['admin', 'osc'])) return;

    this.setState({ isProcessing: true, downloadableLink: null });

    const mealId = mealStore.get('uuid');
    const mealPlanId = mealStore.get('mealPlanId');
    const type = 'menu_cards';

    fetchPdfLink(
      { mealId, mealPlanId, params: { tz: moment.tz.guess() }, type },
      (res) => {
        this.setState({ isProcessing: false, downloadableLink: res.link });
      },
      (err) => {
        this.setState({ isProcessing: false, errorMsg: err });
      },
    );
  }

  buildSuccessMsg = () => {
    if (this.state.isProcessing) {
      return this.props.translate('buttonsProcessing');
    } else if (this.state.downloadableLink) {
      return this.props.translate('termsDownloadHere', { link: this.state.downloadableLink });
    }
    return '';
  };

  render() {
    const {
      mealStore, cartsStore, cartVariationsStore, actions,
    } = this.props;
    const { errorMsg } = this.state;
    const successMsg = this.buildSuccessMsg();
    /* TODO: Update this */
    const isNonZeroVat = false;

    /* For MenuItemForm */
    const selectedMenuItem = cartVariationsStore.get('selectedMenuItem');
    const selectedCartVariation = cartVariationsStore.get('selectedCartVariation');
    const catererId = selectedMenuItem.getIn(['vendor_id']);
    const cartId = cartsStore.getIn(['byVendorId', catererId, 'cartId']);
    const saveErrors = cartVariationsStore.get('saveErrors');
    const cartUserId = null;
    const isGroupOrder = false;
    const mealAdmin = mealStore.get('mealAdmin');

    return (
      <div className="tw-container tw-max-w-[1280px] tw-mx-auto pt-4">

        <Flash />

        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="card card-cart mb-5">

              <CartHeader handleOnClickDownloadPdf={this.handleOnClickDownloadPdf} />

              {
                successMsg &&
                  <div className="p-4">
                    <Alert variant="success" className="text-center">
                      <div dangerouslySetInnerHTML={{ __html: successMsg }} />
                    </Alert>
                  </div>
              }

              {
                errorMsg &&
                  <div className="p-4">
                    <Alert variant="danger" className="text-center">
                      { errorMsg }
                    </Alert>
                  </div>
              }

              <MenuItems />

              { mealAdmin && <MealCart /> }

            </div>
          </div>

          { /* MenuItemFormContainer */ }
          {
            !mealStore.get('confirmed') &&
              <div className="menuitem-form">
                <MenuItemForm
                  mealAdmin={mealAdmin}
                  menuItem={selectedMenuItem}
                  cartVariation={selectedCartVariation}
                  mealId={mealStore.get('uuid')}
                  mealPlanId={mealStore.get('mealPlanId')}
                  catererId={catererId}
                  cartId={cartId}
                  cartUserId={cartUserId}
                  showPrice
                  isEditable
                  hide={!cartVariationsStore.get('showMenuItemForm')}
                  isSavingCartVariation={cartVariationsStore.get('isSavingCartVariation')}
                  isNonZeroVat={isNonZeroVat}
                  isGroupOrder={isGroupOrder}
                  saveErrors={saveErrors}
                  onClickOption={actions.checkAddOnValidity}
                  onDeleteItemAddOnOptionPhoto={actions.deleteItemAddOnOptionPhoto}
                  onDropItemAddOnOptionPhoto={actions.addTemporaryItemAddOnOptionPhoto}
                  onDropItemAddOnOptionPhotoFailure={actions.addTemporaryItemAddOnOptionPhotoFailure}
                  onSelectVariation={actions.selectCartVariation}
                  onSubmit={actions.saveCartVariation}
                  onUpdateItemAddOnOptionText={actions.updateItemAddOnOptionText}
                  onHide={actions.hideMenuItemForm}
                />
              </div>
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  cartsStore: state.$$cartsStore,
  cartVariationsStore: state.$$cartVariationsStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(withI18n(PlannedMeal)));
