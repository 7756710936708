import React from 'react';
import { string, any } from 'prop-types';
import { formatMoney } from './utils';

export const Money = ({ value, fontSize = '1rem', color = 'black' }) => {
  const computedValue = formatMoney(value && value !== 0
    ? parseFloat(value).toFixed(2)
    : value.toString());

  const [wholeNumber, cents] = computedValue.split('.');

  if (value < 0) {
    // eslint-disable-next-line no-param-reassign
    color = 'red';
  }

  return (
    <span className="font-weight-bold" data-testid="money" style={{ fontSize, color }}>
      {wholeNumber}
      {cents !== '00' && <span className="font-weight-normal">.{cents}</span>}
    </span>
  );
};

Money.propTypes = {
  value: any,
  fontSize: string,
  color: string,
};
