/* eslint-disable no-alert */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */

import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { bool, func, object, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { CalendarIcon } from 'libs/components/Icons';
import { BackIcon } from 'libs/components/Icons';

import AlertBox from 'libs/components/AlertBox';
import PastUserMealCartsContainer from 'packs/meals/containers/GroupOrderMeal/PastUserMealCartsContainer';
import Address from './Address';
import UserMealCartItem from './UserMealCartItem';
import CartAmounts from './CartAmounts';
import StripePaymentForm from './StripePaymentForm';

/**
 * UserMealCart
 */
const UserMealCart = ({
  address,
  allowSelfCheckout,
  isCafeOrdering,
  closeModal,
  checkoutUserMealCart,
  confirmUserMealCart,
  resetUserMealCart,
  deleteCartVariation,
  fetchCartVariation,
  hasPassedDeadline,
  isInPreview,
  mealConfirmed,
  mealId,
  mealPlanId,
  scheduleAt,
  showPrice,
  skipUserMealCart,
  translate,
  userMealOrderable,
}) => {
  const variations = userMealOrderable.get('orderVariations') || userMealOrderable.get('cartVariations');
  const orderableVariationIsDeletable = !mealConfirmed &&
                                        (userMealOrderable.get('currentState') === 'editing' ||
                                         userMealOrderable.get('currentState') === 'pending');

  const isUserMealOrderablePending = userMealOrderable.get('currentState') === 'pending';
  const isUserMealOrderableEditing = userMealOrderable.get('currentState') === 'editing';
  const [orderableVariations, setOrderableVariations] = useState([]);
  const [showAlert, setAlert] = useState(false);

  const handleAlertClose = () => {
    setAlert(false);
  };

  const handleSkip = () => {
    skipUserMealCart(mealPlanId, userMealOrderable.get('uuid'));
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    if (orderableVariations.size === 0) {
      if (isUserMealOrderableEditing) {
        resetUserMealCart(mealPlanId, userMealOrderable.get('uuid'));
      } else {
        setAlert(true);
      }
    } else {
      confirmUserMealCart(mealPlanId, userMealOrderable.get('uuid'), { cafe_ordering: isCafeOrdering });
    }
  };

  useEffect(() => {
    setOrderableVariations(variations.sort(ov => (
      (ov.get('cartUserIsGuest') || ov.get('orderUserIsGuest')) ? 1 : -1
    )));
  }, [variations]);

  const alertMsg = userMealOrderable.get('alertMsg');
  if (alertMsg) alert(alertMsg);

  return (
    <Fragment>
      {/* Custom Alert */}
      <AlertBox
        show={showAlert}
        title={translate('groupedOrdersMessagesNoItemsSelected')}
        message={translate('groupedOrdersMessagesPleaseSelectAnItem')}
        cancelText="Okay"
        onCancel={handleAlertClose}
        cancelBtnColor="broccoli-600"
        cancelBtnTextColor="white"
      />
      <div className="card card-cart user-cart" id="cart">
        <div className="card-header d-flex flex-row justify-content-between align-items-center">
          <h6 className="my-2 d-flex align-items-center">
            <span
              className="mr-2 d-lg-none back-icon"
              onClick={closeModal}
            >
              <BackIcon />
            </span>

            {
              isCafeOrdering ?
                translate('groupedOrdersMessagesYourOrder') :
                translate('groupedOrdersMessagesYourMeal')
            }

          </h6>
        </div>

        {
          !isCafeOrdering &&
            <Address address={address} />
        }

        {/* MealCartDate */}
        <div className="cart-order-form">

          <div className="d-flex align-items-center">
            <span>
              <CalendarIcon />
            </span>
            <span className="ml-2">
              { moment(scheduleAt).format('dddd, D MMMM') }

              {
                !isCafeOrdering &&
                  <Fragment>
                    <span>&nbsp;</span>
                    at
                    <span>&nbsp;</span>
                    { moment(scheduleAt).format('h:mmA') }
                  </Fragment>
              }
            </span>
          </div>
        </div>

        {/* Cart List */}
        {
          orderableVariations.size > 0 &&
          <div className="card-body card-body-fixed">
            <div id="user-order-list" className="cart-order-list">
              {
                orderableVariations.map(orderableVariation => (
                  <UserMealCartItem
                    key={`variation-${orderableVariation.get('uuid')}`}
                    orderableVariation={orderableVariation}
                    isEditable={orderableVariationIsDeletable}
                    showPrice={showPrice}
                    mealId={mealId}
                    mealPlanId={mealPlanId}
                    onDelete={deleteCartVariation}
                    onEdit={fetchCartVariation}
                  />
                ))
              }
            </div>
          </div>
        }

        {
          isInPreview ? (
            <div className="card-body card-body-empty">
              { translate('groupedOrdersMessagesInPreview') }
            </div>
          ) : (
            <>
              {orderableVariations.size === 0 && (
                <div className="card-body card-body-empty">
                  { translate('groupedOrdersMessagesEmptyCart') }
                </div>
              )}
              <div className="card-body order-total">
                Total Quantity:
                <span>&nbsp;</span>
                {orderableVariations.reduce((total, variation) => total + variation.get('quantity'), 0)}
              </div>
            </>
          )
        }

        { /* Self Checkout */ }
        {
          allowSelfCheckout &&
          <Fragment>
            <div style={{ borderTop: '1px solid #EAEAEB' }} />

            <CartAmounts
              subtotal={userMealOrderable.get('subtotalAmount')}
              subsidyAmount={userMealOrderable.get('subsidyAmount')}
              taxAmount={userMealOrderable.get('taxAmount')}
              total={userMealOrderable.get('totalAmount')}
            />

            <div style={{ borderTop: '1px solid #EAEAEB' }} />

            {
              !mealConfirmed && userMealOrderable.get('currentState') === 'pending' &&
              <StripePaymentForm
                amountToBePaid={userMealOrderable.get('totalAmount')}
                checkoutIsAllowed={orderableVariations.size > 0}
                mealPlanId={mealPlanId}
                onCheckout={checkoutUserMealCart}
                userMealOrderableId={userMealOrderable.get('uuid')}
              />
            }
          </Fragment>
        }

        {
          !allowSelfCheckout &&
          !mealConfirmed &&
          !hasPassedDeadline &&
          !isInPreview &&
          isUserMealOrderablePending &&
            <div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-primary btn-block meal-confirmation--desktop"
                      onClick={handleConfirm}
                    >
                      { translate('groupedOrdersActionsSubmitSelection') }
                    </button>
                  </div>
                </div>
                {
                  !isCafeOrdering &&
                    <div className="row">
                      <div className="col">
                        <button
                          className="btn btn-link w-100 mt-2"
                          onClick={handleSkip}
                        >
                          { translate('groupedOrdersActionsSkipForThisDay') }
                        </button>
                      </div>
                    </div>
                }
              </div>
            </div>
        }
        {
          !allowSelfCheckout &&
          !mealConfirmed &&
          !hasPassedDeadline &&
          isUserMealOrderableEditing &&
            <div>
              <div className="card-footer">
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-primary btn-block meal-confirmation--desktop"
                      onClick={handleConfirm}
                    >
                      {
                        orderableVariations.size === 0
                        ? translate('groupedOrdersActionsRemoveSelections')
                        : translate('groupedOrdersActionsSubmitOrderChanges')
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
        }

        {
          /* Past Orders */
          isCafeOrdering &&
            <PastUserMealCartsContainer />
        }
      </div>
    </Fragment>
  );
};

UserMealCart.propTypes = {
  address: object.isRequired,
  allowSelfCheckout: bool.isRequired,
  isCafeOrdering: bool.isRequired,
  closeModal: func,
  confirmUserMealCart: func.isRequired,
  resetUserMealCart: func.isRequired,
  checkoutUserMealCart: func.isRequired,
  deleteCartVariation: func.isRequired,
  fetchCartVariation: func.isRequired,
  hasPassedDeadline: bool.isRequired,
  isInPreview: bool.isRequired,
  mealConfirmed: bool.isRequired,
  mealId: string.isRequired,
  mealPlanId: string.isRequired,
  scheduleAt: string.isRequired,
  showPrice: bool.isRequired,
  skipUserMealCart: func.isRequired,
  translate: func.isRequired,
  userMealOrderable: object.isRequired,
};

export default withI18n(UserMealCart);
