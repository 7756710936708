/* eslint max-len: 0 */

import React from 'react';

const DeleteXCircle = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 1.45455C6.70543 1.45455 5.43993 1.83843 4.36354 2.55766C3.28715 3.27688 2.4482 4.29914 1.95279 5.49517C1.45738 6.69119 1.32776 8.00727 1.58031 9.27696C1.83287 10.5467 2.45627 11.7129 3.37166 12.6283C4.28706 13.5437 5.45335 14.1671 6.72305 14.4197C7.99274 14.6722 9.30881 14.5426 10.5048 14.0472C11.7009 13.5518 12.7231 12.7129 13.4423 11.6365C14.1616 10.5601 14.5455 9.29457 14.5455 8C14.5455 6.26404 13.8558 4.59918 12.6283 3.37167C11.4008 2.14416 9.73596 1.45455 8 1.45455ZM0 8C0 6.41775 0.469192 4.87104 1.34824 3.55544C2.22729 2.23985 3.47672 1.21447 4.93853 0.608967C6.40034 0.0034663 8.00887 -0.15496 9.56072 0.153721C11.1126 0.462403 12.538 1.22433 13.6569 2.34315C14.7757 3.46197 15.5376 4.88743 15.8463 6.43928C16.155 7.99113 15.9965 9.59966 15.391 11.0615C14.7855 12.5233 13.7602 13.7727 12.4446 14.6518C11.129 15.5308 9.58225 16 8 16C5.87827 16 3.84344 15.1572 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8Z" fill="#95989D" />
    <path d="M4.4509 11.4254C4.38274 11.3578 4.32863 11.2774 4.29171 11.1888C4.25479 11.1001 4.23578 11.0051 4.23578 10.9091C4.23578 10.8131 4.25479 10.718 4.29171 10.6294C4.32863 10.5408 4.38274 10.4603 4.4509 10.3927L10.6255 4.22544C10.7662 4.12136 10.9399 4.07158 11.1144 4.08522C11.289 4.09886 11.4528 4.17502 11.5757 4.29971C11.6986 4.4244 11.7724 4.58924 11.7835 4.76397C11.7947 4.93871 11.7424 5.1116 11.6364 5.25089L5.48363 11.4254C5.41602 11.4936 5.33559 11.5477 5.24696 11.5846C5.15834 11.6216 5.06328 11.6406 4.96727 11.6406C4.87126 11.6406 4.7762 11.6216 4.68758 11.5846C4.59895 11.5477 4.51851 11.4936 4.4509 11.4254Z" fill="#95989D" />
    <path d="M4.15271 4.15289C4.28897 4.01744 4.4733 3.94141 4.66544 3.94141C4.85757 3.94141 5.0419 4.01744 5.17816 4.15289L11.3527 10.3202C11.4897 10.4571 11.5666 10.6429 11.5666 10.8365C11.5666 11.0302 11.4897 11.2159 11.3527 11.3529C11.2158 11.4898 11.03 11.5668 10.8363 11.5668C10.6427 11.5668 10.4569 11.4898 10.32 11.3529L4.15271 5.17835C4.01725 5.04208 3.94122 4.85775 3.94122 4.66562C3.94122 4.47348 4.01725 4.28916 4.15271 4.15289Z" fill="#95989D" />
  </svg>
);

export default DeleteXCircle;
