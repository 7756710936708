/* eslint-disable max-len */
import { useQuery } from 'react-query';
import humps from 'lodash-humps';
import dayjs from 'dayjs';

export const useReport = (organisationId, kind, filters = {}) => useQuery(['REPORT', organisationId, kind, filters], async () => {
  const params = new URLSearchParams({
    start_date: dayjs(filters.startDate).startOf('month').format('YYYY-MM-DD'),
    end_date: dayjs(filters.endDate).endOf('month').format('YYYY-MM-DD'),
    money_value_type: filters.moneyValueType,
  });

  const response = await fetch(`/financial_reports/${organisationId}/${kind}?${params}`);

  const data = await response.json();

  return humps(data);
}, {
  keepPreviousData: true,
  staleTime: Infinity,
});
