/* eslint new-cap: 0, no-return-assign: 0, no-param-reassign: 0 */

import Immutable from 'immutable';

import * as userMealCartActionTypes from 'packs/meals/constants/userMealCartConstants';
import actionTypes from '../constants';

export const $$initialState = Immutable.fromJS({
  error: '',
  success: '',
});

export default function flashReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case userMealCartActionTypes.RESET_USER_MEAL_CART_SUCCESS:
    case userMealCartActionTypes.CONFIRM_USER_MEAL_CART_FAILURE:
    case actionTypes.UPDATE_QUANTITY_CART_VARIATION_FAILURE:
    case actionTypes.CHECK_OUT_USER_MEAL_CART_FAILURE:
    case actionTypes.SAVE_MEAL_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('error', response.errors.join(','))
          .set('success', '')
      ));
    }

    // eslint-disable-next-line no-duplicate-case
    case userMealCartActionTypes.RESET_USER_MEAL_CART_SUCCESS:
    case actionTypes.SAVE_CART_VARIATION_SUCCESS:
    case actionTypes.UPDATE_QUANTITY_CART_VARIATION_SUCCESS:
    case actionTypes.DISMISS_FLASH: {
      return $$state.withMutations(state => (
        state
          .set('error', '')
          .set('success', '')
      ));
    }

    case userMealCartActionTypes.CONFIRM_USER_MEAL_CART_SUCCESS:
    case actionTypes.CHECK_OUT_USER_MEAL_CART_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('success', response.flash.success)
          .set('error', '')
      ));
    }

    default:
      return $$state;
  }
}
