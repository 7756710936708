/* eslint-disable no-underscore-dangle */
/* eslint max-len: 0, camelcase: 0, no-shadow: 0 */


import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import loggerMiddleware from 'libs/middlewares/loggerMiddleware';
import thunkMiddleware from 'redux-thunk';
import humps from 'lodash-humps';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const initialState = {
    $$mealPlanUsersStore: initialStates.$$mealPlanUsersState.merge({
      currentMealPlanUserId: props.current_meal_plan_user_id,
      mealPlanId: props.meal_plan_id,
      allIds: props.meal_plan_users.map((mpu) => mpu.uuid),
      byId: props.meal_plan_users.reduce((hash, mpu) => {
        // eslint-disable-next-line no-param-reassign
        hash[mpu.uuid] = humps(mpu);
        return hash;
      }, {}),
    }),
    $$fflagsStore: initialStates.$$fflagsState.merge(humps(props.fflags)),
  };

  const composeEnhancers = (typeof window !== 'undefined') ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
  const reducer = combineReducers(reducers);
  const composedStore = composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware));
  return composedStore(createStore)(reducer, initialState);
};
