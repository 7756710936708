/* eslint-disable no-shadow */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Immutable from 'immutable';
/* Actions */
import actions from 'packs/meals/actions';

import { withAppContext } from 'libs/support/appContext';
import BaseComponent from 'libs/components/BaseComponent';
import Nbsp from 'libs/components/Nbsp';
import { ThreeDotsIcon } from 'libs/components/Icons';
import PlannedMealActionsDropdown from 'packs/common/components/PlannedMealActionsDropdown';
import ScheduleModal from './ScheduleModal/ScheduleModal';

/**
 * CartHeader
 */

class CartHeader extends BaseComponent {
  static propTypes = {
    mealStore: object.isRequired,
    actions: object.isRequired,
    handleOnClickDownloadPdf: func.isRequired,
    userRoleIn: func.isRequired,
    flagIsEnabled: func.isRequired,
  }

  render() {
    const {
      mealStore,
      actions,
      handleOnClickDownloadPdf,
      userRoleIn,
      flagIsEnabled,
    } = this.props;
    const scheduleAt = mealStore.get('scheduleAt');
    const isConfirmed = mealStore.get('confirmed');
    const orders = isConfirmed ? mealStore.get('orderables') : Immutable.fromJS([]);

    return (
      <div className="card-header d-flex justify-content-between">
        <h6 className="text-uppercase m-0 py-3">
          { moment(scheduleAt).format('dddd') }
          <span>,&nbsp;</span>
          <span className="text-muted">
            { moment(scheduleAt).format('D MMM h:mmA') }
          </span>
          <span><Nbsp /></span>

          {
            mealStore.get('scheduleAtIsEditable') &&
            !mealStore.get('confirmed') &&
              <a
                href=""
                data-toggle="modal"
                data-target="#editeDateTime"
                onClick={actions.showScheduleModal}
              >
                Edit
              </a>
          }

          <ScheduleModal
            mealPlanId={mealStore.get('mealPlanId')}
            mealId={mealStore.get('uuid')}
            show={mealStore.get('showScheduleModal')}
            scheduleAt={mealStore.get('scheduleAt')}
            onHide={actions.hideScheduleModal}
            onSave={actions.saveMeal}
          />
        </h6>

        {
          flagIsEnabled('menu_card_enabled') &&
          userRoleIn(['osc', 'admin']) &&
            <PlannedMealActionsDropdown
              btnClassName="btn-light btn-sm caret-off px-2"
              className="m-0 py-1"
              mealPlanId={mealStore.get('mealPlanId')}
              mealId={mealStore.get('uuid')}
              orders={orders}
              onClickMenuCardDownload={handleOnClickDownloadPdf}
            >
              <ThreeDotsIcon />
            </PlannedMealActionsDropdown>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(CartHeader));
