import styled from 'styled-components';
import { defaultTheme } from '../../libs/support/theme';

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const Group = styled.div`
  display: flex;
  align-items: start;
  column-gap: 1rem;
`;

export const BigValue = styled.strong`
  font-size: 48px;
  font-weight: bold;
`;

export const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-bottom: 0.2rem;
`;

export const IconWrapper = styled.div`
  background-color: #f4f5f5;
  border-radius: 100%;
  padding: ${(props) => props.padding ?? '14px'};
`;

export const Card = styled.div`
  box-shadow: 0px 6px 20px 0px #0000001a;
  padding: 1rem;
  border-radius: 8px;
`;

export const TableWrapper = styled.div.attrs({
  className: 'table-responsive',
})`
  table {
    margin-bottom: 0px;
  }
  table thead th {
    border: none;
    background: #F4F5F5;
    color: ${defaultTheme.colors.gunPowder};
    font-size: 14px;
  }
  table thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  table thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  table tbody td {
    padding: 16px;
    font-size: 16px;
  }
  table tbody tr:first-child td {
    border-top: none;
  }
  ${props => props.fixedFirstCellWidth && `
    table thead tr th:first-child {
      width: ${props.fixedFirstCellWidth}px;
    }
  `}
`;

export const ProgramCardHeaderItem = styled(Stack)`
  row-gap: 0.2rem;
  flex: 1;
  border-left: 1px solid #dbdbdb;
  padding-left: 1rem;
  font-size: 16px;
  font-weight: 600;
`;
