import * as api from 'libs/middlewares/api';
import {
  FETCH_DAILY_LOG_FAILURE,
  FETCH_DAILY_LOG_SUCCESS,
  FETCH_ORDER_FOR_DELIVERY_FAILURE,
  FETCH_ORDER_FOR_DELIVERY_SUCCESS,
  FETCHING_DAILY_LOG,
  FETCHING_ORDER_FOR_DELIVERY,
  SAVE_DAILY_LOG_FAILURE,
  SAVE_DAILY_LOG_SUCCESS,
  SAVING_DAILY_LOG,
  FETCHING_MASTER_SUPPLY_ITEMS,
  FETCH_MASTER_SUPPLY_ITEMS_SUCCESS,
  FETCH_MASTER_SUPPLY_ITEMS_FAILURE,
} from '../constants/pantryProgramConstants';
import { toSnakeCase } from '../../../libs/support/object';


export function fetchOrdersForDelivery(pantryProgramId, deliverAt) {
  return api.get({
    types: [FETCHING_ORDER_FOR_DELIVERY, FETCH_ORDER_FOR_DELIVERY_SUCCESS, FETCH_ORDER_FOR_DELIVERY_FAILURE],
    endpoint: `/pantry_programs/${pantryProgramId}/orders_for_delivery`,
    params: { deliver_at: deliverAt },
  });
}

export function fetchMasterSupplyItems(pantryProgramId) {
  return api.get({
    types: [FETCHING_MASTER_SUPPLY_ITEMS, FETCH_MASTER_SUPPLY_ITEMS_SUCCESS, FETCH_MASTER_SUPPLY_ITEMS_FAILURE],
    endpoint: `/pantry_programs/${pantryProgramId}/master_supply_items`,
  });
}


export function fetchDailyLog(pantryProgramId, logDate) {
  return api.get({
    types: [FETCHING_DAILY_LOG, FETCH_DAILY_LOG_SUCCESS, FETCH_DAILY_LOG_FAILURE],
    endpoint: `/pantry_programs/${pantryProgramId}/daily_logs`,
    params: { log_date: logDate },
  });
}

export function saveDailyLog(dailyLog, pantryProgramId) {
  return api.save({
    types: [SAVING_DAILY_LOG, SAVE_DAILY_LOG_SUCCESS, SAVE_DAILY_LOG_FAILURE],
    endpoint: `/pantry_programs/${pantryProgramId}/daily_logs`,
    params: toSnakeCase(dailyLog),
  });
}
