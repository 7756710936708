import React, { Fragment } from 'react';
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';

import { notBlank } from 'libs/support/string';
import { groupedItems } from 'packs/meals/helpers/menuItems';

/* Components */
import MenuItem from './MenuItem';
import MealCountCapHeader from './MealCountCapHeader';
import MealCountCappedAlert from './MealCountCappedAlert';

/**
 * MenuItems
 */
const MenuItems = ({
  fetchOrderableVariation = null,
  fetchMenuItem,
  hide,
  mealPlanId,
  menuItemsByVendor,
  selectedText = '',
  showPrice,
  showMealCountCap,
}) => {
  if (hide) return null;

  const onClick = (menuItemId, cartVariationId) => {
    if (notBlank(cartVariationId) && fetchOrderableVariation) return fetchOrderableVariation(mealPlanId, cartVariationId);

    return fetchMenuItem && fetchMenuItem(menuItemId);
  };

  return (
    <Fragment>
      {
        menuItemsByVendor.map(vendor => {
          const isMealCountCapReached = vendor.get('isMealCountCapReached');
          const orderedCount = vendor.get('orderedCount');
          const mealCountCap = vendor.get('mealCountCap');
          const isMealCountCapEnabled = showMealCountCap && notBlank(mealCountCap);

          return (
            <div
              id={vendor.get('uuid')}
              className={classNames('mb-4')}
              key={`vendor-${vendor.get('uuid')}`}

            >
              <div className="category-header">
                <div className="d-md-block d-md-flex justify-content-between">
                  <h5 className="category-title">
                    { vendor.get('name') }
                  </h5>

                  {
                    isMealCountCapEnabled && !isMealCountCapReached &&
                      <MealCountCapHeader {...{ mealCountCap, orderedCount, vendorName: vendor.get('name') }} />
                  }
                </div>
              </div>

              {
                isMealCountCapEnabled && isMealCountCapReached &&
                  <MealCountCappedAlert {...{ mealCountCap, vendorName: vendor.get('name') }} />
              }

              <div className="card-deck menu-items-vendors !tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-4">
                {
                  groupedItems(vendor.get('menuItems')).map((menuItem, i) => {
                    const menuItemId = notBlank(menuItem) ? menuItem.get('uuid') : i;
                    const orderableVariationId = notBlank(menuItem) ? menuItem.get('orderableVariationId') : null;
                    const isSelected = notBlank(orderableVariationId);

                    return (
                      <MenuItem
                        key={`menuItem-${menuItemId}-${orderableVariationId}`}
                        onClick={_ => onClick(menuItemId, orderableVariationId)}
                        {
                          ...{
                            showPrice,
                            menuItem,
                            isActive: !isMealCountCapReached,
                            isSelected,
                            selectedText,
                          }
                        }
                      />
                    );
                  })
                }
              </div>
            </div>
          );
        })
      }
    </Fragment>
  );
};

MenuItems.propTypes = {
  fetchOrderableVariation: func,
  fetchMenuItem: func,
  hide: bool.isRequired,
  mealPlanId: string.isRequired,
  menuItemsByVendor: object.isRequired,
  selectedText: string,
  showPrice: bool.isRequired,
  showMealCountCap: bool,
};

MenuItems.defaultProps = {
  showMealCountCap: false,
};

export default MenuItems;
