import { number } from 'prop-types';
import React from 'react';

export const Percentage = ({ value }) => {
  if (!value) {
    return <span data-testid="percentage">-</span>;
  }

  return <span data-testid="percentage" style={{ color: value >= 0 ? 'black' : 'red' }}>{Math.round(value)}%</span>;
};

Percentage.propTypes = {
  value: number.isRequired,
};
