/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';

const ExclamationMarkCircle = ({ color = '#EE8434', size = 40 }) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none" preserveAspectRatio xmlns="http://www.w3.org/2000/svg">
    <path d="M24 4.00049C20.0444 4.00049 16.1776 5.17347 12.8886 7.3711C9.59962 9.56872 7.03617 12.6923 5.52242 16.3468C4.00867 20.0013 3.6126 24.0227 4.3843 27.9023C5.15601 31.7819 7.06082 35.3456 9.85787 38.1426C12.6549 40.9397 16.2186 42.8445 20.0982 43.6162C23.9778 44.3879 27.9992 43.9918 31.6537 42.4781C35.3082 40.9643 38.4318 38.4009 40.6294 35.1119C42.827 31.8229 44 27.9561 44 24.0005C44 21.374 43.4827 18.7733 42.4776 16.3468C41.4725 13.9203 39.9993 11.7155 38.1421 9.85835C36.285 8.00118 34.0802 6.52799 31.6537 5.5229C29.2272 4.5178 26.6264 4.00049 24 4.00049V4.00049ZM24 40.0005C20.8355 40.0005 17.7421 39.0621 15.1109 37.304C12.4797 35.5459 10.4289 33.047 9.21794 30.1234C8.00693 27.1998 7.69008 23.9827 8.30745 20.879C8.92481 17.7753 10.4487 14.9244 12.6863 12.6868C14.9239 10.4491 17.7749 8.92529 20.8786 8.30792C23.9823 7.69056 27.1993 8.00741 30.1229 9.21842C33.0466 10.4294 35.5454 12.4802 37.3035 15.1114C39.0616 17.7425 40 20.836 40 24.0005C40 28.2439 38.3143 32.3136 35.3137 35.3142C32.3131 38.3148 28.2435 40.0005 24 40.0005V40.0005Z" fill={color} />
    <path d="M24 34C25.1046 34 26 33.1046 26 32C26 30.8954 25.1046 30 24 30C22.8954 30 22 30.8954 22 32C22 33.1046 22.8954 34 24 34Z" fill={color} />
    <path d="M24 13.9995C23.4696 13.9995 22.9609 14.2102 22.5858 14.5853C22.2107 14.9604 22 15.4691 22 15.9995L22 25.9995C22 26.5299 22.2107 27.0386 22.5858 27.4137C22.9609 27.7888 23.4696 27.9995 24 27.9995C24.5304 27.9995 25.0391 27.7888 25.4142 27.4137C25.7893 27.0386 26 26.5299 26 25.9995L26 15.9995C26 15.4691 25.7893 14.9604 25.4142 14.5853C25.0391 14.2102 24.5304 13.9995 24 13.9995Z" fill={color} />
  </svg>
);

ExclamationMarkCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default ExclamationMarkCircle;
