/* eslint-disable max-len */
import React from 'react';

const EmptyState = (_props) => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_24_270)">
      <path d="M86.7788 17.0325L69.7617 0.0168457C69.6379 0.00659179 69.5112 0 69.386 0H12.4717C11.6162 0 10.9233 0.692871 10.9233 1.54834V92.9033C10.9233 93.7588 11.6162 94.4517 12.4717 94.4517H85.2459C86.1006 94.4517 86.7942 93.7588 86.7942 92.9033V17.4082C86.7942 17.2822 86.7891 17.157 86.7788 17.0325Z" fill="#ECEAEC" />
      <path d="M62.02 18.5808H51.1816C50.3254 18.5808 49.6333 17.8879 49.6333 17.0325C49.6333 16.1763 50.3254 15.4841 51.1816 15.4841H62.02C62.8762 15.4841 63.5684 16.1763 63.5684 17.0325C63.5684 17.8879 62.8762 18.5808 62.02 18.5808Z" fill="#C8C5C9" />
      <path d="M62.02 24.7742H51.1816C50.3254 24.7742 49.6333 24.0813 49.6333 23.2258C49.6333 22.3696 50.3254 21.6775 51.1816 21.6775H62.02C62.8762 21.6775 63.5684 22.3704 63.5684 23.2258C63.5684 24.0813 62.8762 24.7742 62.02 24.7742Z" fill="#C8C5C9" />
      <path d="M71.3101 30.9675H51.1816C50.3254 30.9675 49.6333 30.2754 49.6333 29.4192C49.6333 28.5637 50.3254 27.8708 51.1816 27.8708H71.3101C72.1663 27.8708 72.8584 28.5637 72.8584 29.4192C72.8584 30.2754 72.1663 30.9675 71.3101 30.9675Z" fill="#C8C5C9" />
      <path d="M85.4341 14.1233L72.6709 1.36084C71.8894 0.579346 70.8552 0.106201 69.7617 0.0168457V15.4834C69.7617 16.3389 70.4553 17.0325 71.3101 17.0325H86.7781C86.6887 15.939 86.2148 14.9048 85.4341 14.1233Z" fill="#DAD8DB" />
      <path d="M29.5042 30.1934C29.5042 31.4766 28.4641 32.5159 27.1816 32.5159C25.8984 32.5159 24.8584 31.4766 24.8584 30.1934C24.8584 28.9109 25.8984 27.8708 27.1816 27.8708C28.4641 27.8708 29.5042 28.9109 29.5042 30.1934Z" fill="#A49FA6" />
      <path d="M43.4392 30.1934C43.4392 31.4766 42.3999 32.5159 41.1167 32.5159C39.8342 32.5159 38.7942 31.4766 38.7942 30.1934C38.7942 28.9109 39.8342 27.8708 41.1167 27.8708C42.3999 27.8708 43.4392 28.9109 43.4392 30.1934Z" fill="#A49FA6" />
      <path d="M29.5525 25.6765C26.9348 25.6765 24.5156 23.9941 23.6646 21.4424L21 13.4458C20.7883 12.813 20.1973 12.3875 19.53 12.3875H18.665C17.8088 12.3875 17.1167 11.6938 17.1167 10.8391C17.1167 9.98364 17.8096 9.29077 18.665 9.29077H19.53C21.5317 9.29077 23.3027 10.5667 23.9363 12.4666L26.6008 20.4631C27.0864 21.9155 28.5923 22.8032 30.0938 22.53L41.8909 20.3855V15.4841H29.5034C28.6479 15.4841 27.9551 14.7905 27.9551 13.9358C27.9551 13.0803 28.6479 12.3875 29.5034 12.3875H41.8909C43.5981 12.3875 44.9875 13.7761 44.9875 15.4841V20.3855C44.9875 21.8833 43.9182 23.165 42.4438 23.4324L30.6467 25.5769C30.2812 25.6436 29.915 25.6765 29.5525 25.6765Z" fill="#C4C8BA" />
      <path d="M27.9558 49.5483H21.7617C20.907 49.5483 20.2134 48.8555 20.2134 48V43.355C20.2134 42.4995 20.907 41.8066 21.7617 41.8066H27.9558C28.8105 41.8066 29.5042 42.4995 29.5042 43.355V48C29.5042 48.8555 28.8105 49.5483 27.9558 49.5483Z" fill="#C4C8BA" />
      <path d="M40.3425 49.5483H34.1492C33.2937 49.5483 32.6008 48.8555 32.6008 48V43.355C32.6008 42.4995 33.2937 41.8066 34.1492 41.8066H40.3425C41.198 41.8066 41.8909 42.4995 41.8909 43.355V48C41.8909 48.8555 41.198 49.5483 40.3425 49.5483Z" fill="#C8C5C9" />
      <path d="M52.73 49.5483H46.5359C45.6812 49.5483 44.9875 48.8555 44.9875 48V43.355C44.9875 42.4995 45.6812 41.8066 46.5359 41.8066H52.73C53.5847 41.8066 54.2783 42.4995 54.2783 43.355V48C54.2783 48.8555 53.5847 49.5483 52.73 49.5483Z" fill="#C8C5C9" />
      <path d="M74.4075 49.5483H58.9233C58.0679 49.5483 57.375 48.8555 57.375 48V43.355C57.375 42.4995 58.0679 41.8066 58.9233 41.8066H74.4075C75.2622 41.8066 75.9558 42.4995 75.9558 43.355V48C75.9558 48.8555 75.2622 49.5483 74.4075 49.5483Z" fill="#C8C5C9" />
      <path d="M40.3425 60.3875H34.1492C33.2937 60.3875 32.6008 59.6938 32.6008 58.8391V54.1934C32.6008 53.3386 33.2937 52.645 34.1492 52.645H40.3425C41.198 52.645 41.8909 53.3386 41.8909 54.1934V58.8391C41.8909 59.6938 41.198 60.3875 40.3425 60.3875Z" fill="white" />
      <path d="M52.73 60.3875H46.5359C45.6812 60.3875 44.9875 59.6938 44.9875 58.8391V54.1934C44.9875 53.3386 45.6812 52.645 46.5359 52.645H52.73C53.5847 52.645 54.2783 53.3386 54.2783 54.1934V58.8391C54.2783 59.6938 53.5847 60.3875 52.73 60.3875Z" fill="white" />
      <path d="M74.4075 60.3875H58.9233C58.0679 60.3875 57.375 59.6938 57.375 58.8391V54.1934C57.375 53.3386 58.0679 52.645 58.9233 52.645H74.4075C75.2622 52.645 75.9558 53.3386 75.9558 54.1934V58.8391C75.9558 59.6938 75.2622 60.3875 74.4075 60.3875Z" fill="white" />
      <path d="M40.3425 71.2258H34.1492C33.2937 71.2258 32.6008 70.533 32.6008 69.6775V65.0325C32.6008 64.177 33.2937 63.4841 34.1492 63.4841H40.3425C41.198 63.4841 41.8909 64.177 41.8909 65.0325V69.6775C41.8909 70.533 41.198 71.2258 40.3425 71.2258Z" fill="white" />
      <path d="M52.73 71.2258H46.5359C45.6812 71.2258 44.9875 70.533 44.9875 69.6775V65.0325C44.9875 64.177 45.6812 63.4841 46.5359 63.4841H52.73C53.5847 63.4841 54.2783 64.177 54.2783 65.0325V69.6775C54.2783 70.533 53.5847 71.2258 52.73 71.2258Z" fill="white" />
      <path d="M74.4075 71.2258H58.9233C58.0679 71.2258 57.375 70.533 57.375 69.6775V65.0325C57.375 64.177 58.0679 63.4841 58.9233 63.4841H74.4075C75.2622 63.4841 75.9558 64.177 75.9558 65.0325V69.6775C75.9558 70.533 75.2622 71.2258 74.4075 71.2258Z" fill="white" />
      <path d="M40.3425 82.0642H34.1492C33.2937 82.0642 32.6008 81.3713 32.6008 80.5159V75.8708C32.6008 75.0161 33.2937 74.3225 34.1492 74.3225H40.3425C41.198 74.3225 41.8909 75.0161 41.8909 75.8708V80.5159C41.8909 81.3713 41.198 82.0642 40.3425 82.0642Z" fill="white" />
      <path d="M52.73 82.0642H46.5359C45.6812 82.0642 44.9875 81.3713 44.9875 80.5159V75.8708C44.9875 75.0161 45.6812 74.3225 46.5359 74.3225H52.73C53.5847 74.3225 54.2783 75.0161 54.2783 75.8708V80.5159C54.2783 81.3713 53.5847 82.0642 52.73 82.0642Z" fill="white" />
      <path d="M74.4075 82.0642H58.9233C58.0679 82.0642 57.375 81.3713 57.375 80.5159V75.8708C57.375 75.0161 58.0679 74.3225 58.9233 74.3225H74.4075C75.2622 74.3225 75.9558 75.0161 75.9558 75.8708V80.5159C75.9558 81.3713 75.2622 82.0642 74.4075 82.0642Z" fill="white" />
      <path d="M27.9558 82.0642H21.7617C20.907 82.0642 20.2134 81.3713 20.2134 80.5159V54.1934C20.2134 53.3386 20.907 52.645 21.7617 52.645H27.9558C28.8105 52.645 29.5042 53.3386 29.5042 54.1934V80.5159C29.5042 81.3713 28.8105 82.0642 27.9558 82.0642Z" fill="#C4C8BA" />
    </g>
    <defs>
      <clipPath id="clip0_24_270">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyState;
