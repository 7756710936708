import styled from 'styled-components';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

export const VendorNameContainer = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #141415;
`;

const DeliveryAtContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #515154;
`;

// NOTE: ignore date, dateTo timezone, refer to meal_checkout_processor.rb
export const DeliveryAt = ({ date, dateTo }) => (
  <DeliveryAtContainer>
    Deliver at {date && moment(date.substring(0, date.length - 6))
      .format('h:mm A')}
    {
        !!dateTo && (
          <>
            {'-'}
            {moment(dateTo.substring(0, dateTo.length - 6))
              .format('h:mm A')}
          </>
        )
      }
  </DeliveryAtContainer>
);

DeliveryAt.propTypes = {
  date: PropTypes.string.isRequired,
  dateTo: PropTypes.string,
};
