/* eslint max-len: 0 */

import Immutable from 'immutable';
import { checkAddOnValidity } from 'packs/common/reducers/cartVariation/addOnReducer';
import { getOrderableVariationField } from 'packs/common/helpers/orderableVariation';

export function addTemporaryItemAddOnOptionPhoto(response, defaultHash, $$state, orderableType) {
  const {
    file,
    hash,
    parentType,
    parentId,
    optionId,
    optionName,
  } = response.params[0];
  const photos = Immutable.fromJS([{ thumb: file.preview, temporaryDetails: file, hash }]);
  let orderableVariationItemAddOnOptionIndex = -1;

  const newState = $$state.withMutations(state => {
    state.updateIn([getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`], orderableVariationItemAddOnOptions => {
      orderableVariationItemAddOnOptionIndex = orderableVariationItemAddOnOptions
        .findIndex(o => o.get('parent_id') === parentId && o.get('item_add_on_option_id') === optionId);

      if (orderableVariationItemAddOnOptionIndex >= 0) {
        return orderableVariationItemAddOnOptions.update(
          orderableVariationItemAddOnOptionIndex,
          (option) => (option.set('photos', photos).set('errors', null)),
        );
      }

      return orderableVariationItemAddOnOptions;
    });
  });

  const orderableVariationItemAddOnOption = newState.getIn([
    getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`, orderableVariationItemAddOnOptionIndex,
  ]);

  const validateParams = {
    parentType,
    parentId,
    lastName: 'item_add_on_option_id',
    quantity: orderableVariationItemAddOnOption.get('quantity'),
    name: optionName,
    checked: true,
    value: optionId,
  };

  return checkAddOnValidity(validateParams, defaultHash, newState, orderableType);
}

export function addTemporaryItemAddOnOptionPhotoFailure(response, $$state, orderableType) {
  const { errors, optionId, parentId } = response;

  return $$state.withMutations(state => {
    state.updateIn([getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`], orderableVariationItemAddOnOptions => {
      const orderableVariationItemAddOnOptionIndex = orderableVariationItemAddOnOptions
        .findIndex(o => o.get('parent_id') === parentId && o.get('item_add_on_option_id') === optionId);

      if (orderableVariationItemAddOnOptionIndex >= 0) {
        return orderableVariationItemAddOnOptions.update(
          orderableVariationItemAddOnOptionIndex,
          (option) => (option.set('errors', errors)),
        );
      }

      return orderableVariationItemAddOnOptions;
    });
  });
}

export function deleteItemAddOnOptionPhoto(response, defaultHash, $$state, orderableType) {
  const {
    parentType,
    parentId,
    optionId,
    optionName,
  } = response.details;
  let orderableVariationItemAddOnOptionIndex = -1;

  const newState = $$state.withMutations(state => {
    state.updateIn([getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`], orderableVariationItemAddOnOptions => {
      orderableVariationItemAddOnOptionIndex = orderableVariationItemAddOnOptions
        .findIndex(o => o.get('parent_id') === parentId && o.get('item_add_on_option_id') === optionId);

      if (orderableVariationItemAddOnOptionIndex >= 0) {
        return orderableVariationItemAddOnOptions.update(
          orderableVariationItemAddOnOptionIndex,
          (option) => (option.set('photos', Immutable.fromJS([]))),
        );
      }

      return orderableVariationItemAddOnOptions;
    });
  });

  const orderableVariationItemAddOnOption = newState.getIn([
    getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`, orderableVariationItemAddOnOptionIndex,
  ]);

  const validateParams = {
    parentType,
    parentId,
    lastName: 'item_add_on_option_id',
    quantity: orderableVariationItemAddOnOption.get('quantity'),
    name: optionName,
    checked: true,
    value: optionId,
  };

  return checkAddOnValidity(validateParams, defaultHash, newState, orderableType);
}

export function updateItemAddOnOptionText(response, defaultHash, $$state, orderableType) {
  const {
    text,
    parentType,
    parentId,
    optionId,
    optionName,
  } = response;
  let orderableVariationItemAddOnOptionIndex = -1;

  const newState = $$state.withMutations(state => {
    state.updateIn([getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`], orderableVariationItemAddOnOptions => {
      orderableVariationItemAddOnOptionIndex = orderableVariationItemAddOnOptions
        .findIndex(o => o.get('parent_id') === parentId && o.get('item_add_on_option_id') === optionId);

      if (orderableVariationItemAddOnOptionIndex >= 0) {
        return orderableVariationItemAddOnOptions.update(
          orderableVariationItemAddOnOptionIndex,
          (option) => (option.set('text', text)),
        );
      }

      return orderableVariationItemAddOnOptions;
    });
  });

  const orderableVariationItemAddOnOption = newState.getIn([
    getOrderableVariationField(orderableType), `${orderableType}_variation_item_add_on_options`, orderableVariationItemAddOnOptionIndex,
  ]);

  const validateParams = {
    parentType,
    parentId,
    lastName: 'item_add_on_option_id',
    quantity: orderableVariationItemAddOnOption.get('quantity'),
    name: optionName,
    checked: true,
    value: optionId,
  };

  return checkAddOnValidity(validateParams, defaultHash, newState, orderableType);
}
