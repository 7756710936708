/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';

class MenuItemIncludedItems extends BaseComponent {
  static propTypes = {
    includedItems: PropTypes.object,
    intl: intlShape.isRequired,
  };

  render() {
    const { includedItems } = this.props;

    if (!includedItems || includedItems.size < 1) { return null; }

    return (
      <div className="mb-2">
        <small className="w-100 d-block text-uppercase text-muted">What’s Included:</small>

        {
          includedItems.map((includedItem, i) =>
            (
              <div key={`includedItem-${i}`}>
                {includedItem.get('name')}
              </div>
            ))
        }
      </div>
    );
  }
}

export default injectIntl(MenuItemIncludedItems);
