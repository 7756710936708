import Immutable from 'immutable';
import { constructOrderVariationsStore } from 'packs/meals/stores/mealStore';

import * as mealActionTypes from 'packs/meals/constants/mealConstants';

export const $$initialState = Immutable.fromJS({});

export default function orderVariationsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case mealActionTypes.FETCH_MEAL_SUCCESS: {
      return $$state.withMutations(state => {
        state.merge(constructOrderVariationsStore(response));
      });
    }
    default: {
      return $$state;
    }
  }
}
