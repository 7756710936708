/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import EditIcon from 'libs/components/Icons/EditIcon';

/**
 * MenuItemSelection
 */

const MenuItemSelection = ({ cartVariation, editable, onEdit }) => (
  <div className="mb-2">
    <small className="w-100 d-block text-uppercase text-muted">
      Selections:
      &nbsp;
      {
          editable &&
            <button
              id="edit-user"
              className="btn btn-light btn-sm btn-icon px-2"
              onClick={onEdit}
            >
              <EditIcon />
              Edit
            </button>
        }
    </small>

    <div>
      {
          cartVariation.get('variation') &&
          <div>
            { cartVariation.getIn(['variation', 'name']) }
          </div>
        }
    </div>

    <div>
      {
        cartVariation.get('cart_variation_option_choices').map((option, i) =>
          (
            <div key={`menuItemSelection-${i}`}>
              { option.get('name') }
            </div>
          ))
      }
    </div>

    <div>
      {
        cartVariation.get('cart_variation_item_add_on_options').map((option, i) =>
          (
            <div key={`menuItemSelection-${i}`}>
              { option.get('name') }
            </div>
          ))
      }
    </div>

    <div>
      {
        cartVariation.get('cart_variation_service_add_on_options').map((option, i) =>
          (
            <div key={`menuItemSelection-${i}`}>
              { option.get('name') }
            </div>
          ))
      }
    </div>

    {
      cartVariation.get('additional_instructions') &&
      <div>{cartVariation.get('additional_instructions')}</div>
    }
  </div>
);

MenuItemSelection.propTypes = {
  cartVariation: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default injectIntl(MenuItemSelection);
