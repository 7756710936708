/* eslint-disable max-len */
import React from 'react';

const Vegan = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#5B7315"
    />
    <path
      d="M8.2598 16L11.4038 7.6H9.9398L7.5398 14.428L5.1518 7.6H3.6878L6.8198 16H8.2598ZM16.218 7.444C13.71 7.444 11.922 9.364 11.922 11.8C11.922 14.404 13.806 16.156 16.194 16.156C17.79 16.156 18.858 15.652 19.758 14.812V11.608H16.77V12.748H18.498V14.164C18.282 14.392 17.55 14.92 16.266 14.92C14.574 14.92 13.314 13.66 13.314 11.788C13.314 10.024 14.478 8.68 16.266 8.68C17.526 8.68 18.258 9.304 18.582 9.628L19.422 8.752C18.99 8.272 17.994 7.444 16.218 7.444Z"
      fill="white"
    />
  </svg>
);

export default Vegan;
