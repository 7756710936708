import React from 'react';
import { object, string } from 'prop-types';

import OrderableVariation from './OrderableVariation';
import { buildAddOnDetails } from './helpers';

/**
 * MealCartItem
 */

const MealCartItem = ({
  vendorName,
  orderableVariations,
}) => {
  if (orderableVariations.isEmpty()) return null;

  return (
    <div>
      <h6 className="mb-0 mt-2 cart-order-list__vendor-name">
        { vendorName }
      </h6>

      <div>
        {
          orderableVariations &&
          orderableVariations
            .sort(ov => ((ov.get('cartUserIsGuest') || ov.get('orderUserIsGuest')) ? 1 : -1))
            .map((orderableVariation) => (
              <OrderableVariation
                key={`cartVariation-${orderableVariation.get('uuid')}`}
                userName={orderableVariation.get('cartUserName') || orderableVariation.get('orderUserName')}
                isGuest={orderableVariation.get('cartUserIsGuest') || orderableVariation.get('orderUserIsGuest')}
                itemName={orderableVariation.get('name')}
                quantity={orderableVariation.get('quantity')}
                subtotal={orderableVariation.get('subtotal')}
                addOnDetails={buildAddOnDetails(orderableVariation.toJS())}
                additionalInstructions={orderableVariation.get('additionalInstructions')}
              />
            ))
        }
      </div>
    </div>
  );
};

MealCartItem.propTypes = {
  vendorName: string.isRequired,
  orderableVariations: object.isRequired,
};

export default MealCartItem;
