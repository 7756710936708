import React from 'react';
import PropTypes from 'prop-types';
import CircleCheckIcon from 'libs/components/Icons/CircleCheck';

export function SubmittedSuccessfullyStep({ logDate, isDraft }) {
  return (
    <div>
      <div className="pt-3">
        <CircleCheckIcon color="#7D9E1D" size={48} />
      </div>
      <div className="my-3">
        <h3 className="mt-2">Step Completed</h3>
        <p className="mt-2">Stock count {isDraft && '(draft)'} submitted successfully for {logDate}</p>
      </div>
    </div>
  );
}
SubmittedSuccessfullyStep.propTypes = {
  logDate: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
};
