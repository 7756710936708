/* eslint-disable max-len */
import React from 'react';

const Calendar = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodad"
      clipRule="evenodd"
      d="M3 9.00002H4V8H3V9.00002ZM2 10H5V7H2V10ZM3 13H4V12H3V13ZM2 14H5V11H2V14ZM7 9.00002H8V8H7V9.00002ZM6 10H9V7H6V10ZM7 13H8V12H7V13ZM6 14H9V11H6V14ZM11 13H12V12H11V13ZM10 14H13V11H10V14ZM11 9.00002H12V8H11V9.00002ZM10 10H13V7H10V10ZM14 3.99997H1V2.49999C1 2.22 1.224 2 1.5 2H4V2.99998H5V2H10V2.99998H11V2H13.5C13.776 2 14 2.22 14 2.49999V3.99997ZM14 14.5C14 14.78 13.776 15 13.5 15H1.5C1.224 15 1 14.78 1 14.5V5H14V14.5ZM14.5 1H11V0H10V1H5V0H4V1H1C0.448 1 0 1.45 0 2V15C0 15.55 0.448 16 1 16H14C14.552 16 15 15.55 15 15V2C15 1.45 15.052 1 14.5 1Z"
      fill="#95989D"
    />
  </svg>
);

export default Calendar;
