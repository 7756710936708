import React, { Fragment } from 'react';
import { string } from 'prop-types';

import AmericanExpressIcon from './AmericanExpress';
import VisaIcon from './Visa';
import MasterCardIcon from './MasterCard';

const CREDIT_CARD_ICONS = {
  'American Express': AmericanExpressIcon,
  MasterCard: MasterCardIcon,
  Visa: VisaIcon,
  JCB: Fragment,
};

const CreditCard = ({ type }) => {
  const CreditCardIcon = CREDIT_CARD_ICONS[type];

  return (
    <span>
      <CreditCardIcon />
    </span>
  );
};

CreditCard.propTypes = {
  type: string.isRequired,
};

export default CreditCard;
