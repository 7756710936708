import React from 'react';
import PropTypes from 'prop-types';
import PhotoUploadContainer from './photoUploadContainer';

export default function PhotosFeedbackStep({
  wastage,
  setWastage,
  feedback,
  setFeedback,
  pantryRestockPointPhotos,
  setRestockPointPhotos,
  storeRoomPhotos,
  setStoreRoomPhotos,
}) {
  return (
    <div>
      <div className="mt-3">
        <PhotoUploadContainer
          header="1. Take a photo of EACH pantry restocking point."
          setPhotos={setRestockPointPhotos}
          photos={pantryRestockPointPhotos}
        />
      </div>
      <div className="mt-3">
        <PhotoUploadContainer
          header="2. Take photos of the store room."
          setPhotos={setStoreRoomPhotos}
          photos={storeRoomPhotos}
        />
      </div>
      <div className="mt-3">
        <h6>3. Any wastage?</h6>
        <div>
          <textarea
            className="form-control"
            rows="3"
            value={wastage}
            placeholder="Describe item and quantity of items discarded."
            onChange={(event) => {
              setWastage(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="mt-3">
        <h6>4. Feedback on items</h6>
        <div>
          <textarea
            className="form-control"
            rows="3"
            value={feedback}
            placeholder="Add any feedback on items from customer e.g. likes, dislikes"
            onChange={(event) => {
              setFeedback(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

PhotosFeedbackStep.propTypes = {
  wastage: PropTypes.string.isRequired,
  setWastage: PropTypes.func.isRequired,
  feedback: PropTypes.string.isRequired,
  setFeedback: PropTypes.func.isRequired,
  pantryRestockPointPhotos: PropTypes.array.isRequired,
  storeRoomPhotos: PropTypes.array.isRequired,
  setRestockPointPhotos: PropTypes.func.isRequired,
  setStoreRoomPhotos: PropTypes.func.isRequired,
};
