/* eslint-disable react/no-array-index-key */
import dayjs from 'dayjs';
import { withI18n } from 'libs/support/i18n';
import { object } from 'prop-types';
import React from 'react';
import { Card, Stack, TableWrapper } from '../../../styles';
import { Money } from '../../Money';
import { PROGRAM_COLORS } from '../../utils';
import { BudgetBar } from '../../BudgetBar';
import { Percentage } from '../../Percentage';

export const Program = withI18n(({ program, translate }) => {
  const showTotal = program.data.length > 1;

  return (
    <Card
      style={{ borderLeft: `8px solid ${PROGRAM_COLORS[program.type]}` }}
      data-testid={`reporting-summary-program-${program.type}`}
    >
      <Stack>
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          {translate(`financialReportsProgramNames${program.type}`)}
        </div>
        {program.summary.totalBudget > 0 && (
          <BudgetBar
            total={program.summary.totalBudget}
            spend={program.summary.budgetSpend}
            color={PROGRAM_COLORS[program.type]}
          />
        )}
        <TableWrapper fixedFirstCellWidth={180}>
          <table className="table">
            <thead>
              <tr>
                <th />
                {program.data.map(({ step }) => (
                  <th key={step}>{dayjs(step, 'YYYY-MM').format("MMM'YY")}</th>
                ))}
                {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
              </tr>
            </thead>
            <tbody>
              {program.type !== 'Event' && (
                <tr>
                  <td className="font-weight-bold">
                    {translate('financialReportsTermsBudget')}
                  </td>
                  {program.data.map(({ totalBudget }, i) => (
                    <td className="font-weight-bold" key={i}>
                      <Money value={totalBudget} />
                    </td>
                  ))}
                  {showTotal && (
                    <td>
                      <Money value={program.summary.totalBudget} />
                    </td>
                  )}
                </tr>
              )}
              <tr>
                <td className="font-weight-bold">
                  {translate('financialReportsTermsSpend')}
                </td>
                {program.data.map(({ budgetSpend, budgetSpendPercentage }, i) => (
                  <td className="font-weight-bold" key={i}>
                    <Money value={budgetSpend} />
                    {program.type !== 'Event' && (
                      <>
                        <br />
                        <Percentage value={budgetSpendPercentage} />
                      </>
                    )}
                  </td>
                ))}
                {showTotal && (
                  <td className="font-weight-bold">
                    <Money value={program.summary.totalSpend} />
                    {program.type !== 'Event' && (
                      <>
                        <br />
                        <Percentage value={program.summary.budgetSpendPercentage} />
                      </>
                    )}
                  </td>
                )}
              </tr>
              {program.type !== 'Event' && (
                <tr>
                  <td className="font-weight-bold">
                    {translate('financialReportsTermsUnused')}
                  </td>
                  {program.data.map(({ budgetUnused, budgetUnusedPercentage }, i) => (
                    <td key={i}>
                      <Money value={budgetUnused} />
                      <br />
                      <Percentage value={budgetUnusedPercentage} />
                    </td>
                  ))}
                  {showTotal && (
                    <td className="font-weight-bold">
                      <Money value={program.summary.budgetUnused} />
                      <br />
                      <Percentage value={program.summary.budgetUnusedPercentage} />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </TableWrapper>
      </Stack>
    </Card>
  );
});

Program.propTypes = {
  program: object.isRequired,
};
