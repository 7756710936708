/* eslint-disable max-len */
import React from 'react';
import uppercamelcase from 'uppercamelcase';
import { func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { ChevronRightIcon } from 'libs/components/Icons';
import { isChannelHidden } from '../utils';
import {
  NotificationItemContainer,
  SubSettingTitle,
  Description,
  NextButton,
  Badge,
} from '../../styles/mobile';

const NotificationItem = ({ group, name, onNotificationSelect, currentSettings, translate }) => {
  const handleClick = () => {
    onNotificationSelect({ name });
  };

  return (
    <NotificationItemContainer onClick={handleClick}>
      <div>
        <SubSettingTitle>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(name)}Title`)}</SubSettingTitle>
        <Description style={{ margin: '4px 0' }}>{translate(`accountSettingsNotificationSettingsNotificationInfo${uppercamelcase(name)}Description`)}</Description>
        <div>
          { (currentSettings[`${name}_push`] && !isChannelHidden(group, 'push')) && <Badge>{ translate('accountSettingsNotificationSettingsChannelsPush') }</Badge> }
          { (currentSettings[`${name}_email`] && !isChannelHidden(group, 'email')) && <Badge>{ translate('accountSettingsNotificationSettingsChannelsEmail') }</Badge> }
          { (currentSettings[`${name}_sms`] && !isChannelHidden(group, 'sms')) && <Badge>{ translate('accountSettingsNotificationSettingsChannelsSms') }</Badge> }
        </div>
      </div>
      <NextButton>
        <ChevronRightIcon />
      </NextButton>
    </NotificationItemContainer>
  );
};

NotificationItem.propTypes = {
  name: string,
  group: object,
  onNotificationSelect: func,
  currentSettings: object,
  translate: func,
};

export default withI18n(NotificationItem);
