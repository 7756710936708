/* eslint camelcase: 0 */

import Immutable from 'immutable';
import findLastIndex from 'lodash/findLastIndex';
import each from 'lodash/each';

export function saveLocal(response, $$state) {
  const { cart_variations } = response.cart;

  const finalCartVariations = [];

  each(cart_variations, cart_variation => {
    const cartUserId = $$state.get('cartUserId');

    let index = 0;

    if (cartUserId === cart_variation.cart_user_id) {
      index = findLastIndex(finalCartVariations, data => data.cart_user_id === cartUserId) + 1;
    } else {
      index = finalCartVariations.length;
    }

    finalCartVariations.splice(index, 0, cart_variation);
  });

  return $$state.set('cartVariations', Immutable.fromJS(finalCartVariations));
}
