/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import SelectIllustration from 'libs/components/Illustrations/Select';

/**
 * CafeOrderingGreetingHeader
 */
const CafeOrderingGreetingHeader = ({
  name,
  translate,
}) => (
  <div className="row">
    <div className="col">
      <div className="card card-panel meal-banner meal-banner--success">
        <div className="card-body meal-banner__body">
          <div className="meal-banner__image" >
            <SelectIllustration />
          </div>
          <div className="meal-banner__content" >
            <h5>
              { translate('groupedOrdersMessagesCafeOrderingGreetingTitle', { name }) }
            </h5>

            <p className="lead meal-banner__lead">
              { translate('groupedOrdersMessagesCafeOrderingGreetingSubtext') }
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = ({
  $$mealStore,
}) => ({
  name: $$mealStore.getIn(['currentUser', 'firstName']),
});

CafeOrderingGreetingHeader.propTypes = {
  name: string.isRequired,
  translate: func.isRequired,
};

export default connect(mapStateToProps)(withI18n(CafeOrderingGreetingHeader));
