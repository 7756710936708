import React, { Fragment } from 'react';
import { bool, func, string } from 'prop-types';
import { blankish, notBlank } from 'libs/support/string';
import DropZone from 'react-dropzone';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';
import UploadIcon from 'libs/components/Icons/UploadCloud';
import PhotoIcon from 'libs/components/Icons/Photo';
import CloseIcon from 'libs/components/Icons/Close';

const Container = styled.div`

`;

const IconContainer = styled.span`
  display: flex;
  margin-right: 0.5rem;
`;

const DeleteIconContainer = styled.span`
  display: flex;
  margin-left: 1rem;
`;

const ButtonContent = styled.span`
  align-items: center;
  display: flex;
`;

const PreviewButton = styled.a`
  background: ${props => props.theme.colors.zircon};
  border: 1px solid ${props => props.theme.colors.gainsboro};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
`;

const UploadButton = styled.a`
  border: 1px solid ${props => props.theme.colors.gainsboro};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${props => props.theme.colors.blackRussian};
  cursor: pointer;
  display: inline-block;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;

  &:focus {
    color: ${props => props.theme.colors.blackRussian};
  }

  &:hover {
    color: ${props => props.theme.colors.blackRussian};
  }

  &:active {
    color: ${props => props.theme.colors.blackRussian};
  }
`;

const MessageContainer = styled.span`
  color: ${props => props.theme.colors.greyChateau};
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  margin-left: 1rem;
`;

const LabelContainer = styled.div`
  color: ${props => props.theme.colors.gunPowder};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0.5rem;
`;

const FileLink = styled.a`
  align-items: center;
  display: flex;
`;

const TextPreview = ({
  errors,
  handleOnDelete,
  handleOnDrop,
  isMultiple,
  photo,
  translate,
  isDeletable,
  isDroppable,
  isUploading,
  url,
}) => {
  const dropzoneClass = notBlank(photo) ? 'dropzone-uploaded' : '';
  const uploadButtonText = blankish(photo) ? 'fileUploadChooseFile' : 'fileUploadReuploadFile';
  const uploadingText = 'fileUploadUploading';
  const labelText = 'fileUploadUploaded';
  const message = 'fileUploadUploadMessage';

  return (
    <Container>
      {
        notBlank(photo) &&
          <Fragment>
            <LabelContainer>
              { `${translate(labelText)}:` }
            </LabelContainer>

            <PreviewButton>
              <ButtonContent>
                <Fragment>
                  <FileLink
                    target="_blank"
                    href={url}
                  >
                    <IconContainer><PhotoIcon /></IconContainer>

                    { photo.split('/').pop().replace(/^thumb_/, '') }
                  </FileLink>

                  {
                    isDeletable &&
                      <DeleteIconContainer
                        className="text-preview__delete"
                        onClick={handleOnDelete}
                      >
                        <CloseIcon />
                      </DeleteIconContainer>
                  }
                </Fragment>
              </ButtonContent>
            </PreviewButton>
          </Fragment>
      }

      {
        isDroppable &&
          <DropZone
            onDrop={handleOnDrop}
            multiple={isMultiple || false}
            accept="image/*, application/pdf"
            className={`dropzone ${dropzoneClass}`}
            style={{}}
          >
            <UploadButton>
              <ButtonContent>
                <IconContainer><UploadIcon /></IconContainer>
                {
                  isUploading ?
                    `${translate(uploadingText)}...` :
                    translate(uploadButtonText)
                }
              </ButtonContent>
            </UploadButton>

            <MessageContainer>
              { translate(message) }
            </MessageContainer>
          </DropZone>
      }

      {
        errors &&
          <em style={{ color: 'red' }}>
            { errors }
          </em>
      }
    </Container>
  );
};

TextPreview.propTypes = {
  errors: string,
  handleOnDelete: func.isRequired,
  handleOnDrop: func.isRequired,
  isDeletable: bool,
  isDroppable: bool,
  isMultiple: bool,
  isUploading: bool,
  photo: string,
  translate: func.isRequired,
  url: string,
};

export default withI18n(TextPreview);
