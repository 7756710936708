/* eslint-disable max-len */
import React from 'react';
import uppercamelcase from 'uppercamelcase';
import { array } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { Card, TableWrapper } from '../../../styles';
import { formatDate } from '../../utils';

export const OrdersCountTrend = withI18n(({ programs, translate }) => {
  const programsTotal = programs.find(({ type }) => type === 'Total');
  const showTotal = programsTotal.data.length > 1;

  return (
    <Card data-testid="reporting-summary-orders-count-trend">
      <TableWrapper fixedFirstCellWidth={180}>
        <table className="table">
          <thead>
            <tr>
              <th />
              {programsTotal.data.map(({ step }) => (
                <th key={step}>{formatDate(step)}</th>
              ))}
              {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
            </tr>
          </thead>
          <tbody>
            {programs.filter(({ type }) => type !== 'Total').map((report) => (
              <tr key={report.type}>
                <td className="font-weight-bold">{translate(`financialReportsProgramNames${uppercamelcase(report.type)}`)}</td>
                {report.data.map(({ step, ordersCount }) => (
                  <td key={step}>{ordersCount}</td>
                ))}
                {showTotal && <td>{report.data.reduce((acc, curr) => acc + curr.ordersCount, 0)}</td>}
              </tr>
            ))}
            <tr>
              <td className="font-weight-bold">{translate('financialReportsTermsTotal')}</td>
              {programs.find(({ type }) => type === 'Total').data.map(({ step, ordersCount }) => (
                <td key={step} className="font-weight-bold">{ordersCount}</td>
              ))}
              {showTotal && <td className="font-weight-bold">{programsTotal.data.reduce((acc, curr) => acc + curr.ordersCount, 0)}</td>}
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </Card>
  );
});

OrdersCountTrend.propTypes = {
  programs: array.isRequired,
};
