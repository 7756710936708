/* eslint max-len: 0 */

import React from 'react';

const Location = (_props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M0 6C0 2.686 2.686 0 6 0C9.313 0 12 2.686 12 6C12 9.313 6 16 6 16C6 16 0 9.313 0 6ZM6 1C3.243 1 1 3.243 1 6C1 7.906 3.816 11.8831 6 14.4751C8.184 11.8831 11 7.907 11 6C11 3.243 8.757 1 6 1ZM4 4.99805C4 3.89405 4.896 2.99805 6 2.99805C7.105 2.99805 8 3.89405 8 4.99805C8 6.10205 7.104 6.99805 6 6.99805C4.895 6.99805 4 6.10305 4 4.99805ZM7 5C7 4.447 6.552 4 6 4C5.448 4 5 4.447 5 5C5 5.552 5.448 6 6 6C6.552 6 7 5.552 7 5Z" fill="#95989D" />
  </svg>
);

export default Location;
