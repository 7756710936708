/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';

import { withI18n } from 'libs/support/i18n';
import { useSWRAPI } from 'libs/middlewares/useSWRAPI';

import PastUserMealCart from './PastUserMealCart';

const Container = styled.div`
  box-shadow: 0px -1px 0px ${props => props.theme.colors.lavendar}, 0px -1px 0px #EAEAEB;

  a {
    cursor: pointer;
  }
`;

const PastUserMealCarts = ({
  mealId,
  mealPlanId,
  translate,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const handleOnClick = () => {
    setIsHidden(isHidden => !isHidden);
  };

  const { data: userMealCarts } = useSWRAPI(`/meal_plans/${mealPlanId}/user_meal_carts`, { meal_id: mealId });

  return (
    <Container>
      <div className="card-footer">
        <div className="row">
          <div className="col">
            <a onClick={handleOnClick}>
              {
                isHidden ?
                  <span className="glyphicon glyphicon-chevron-right mr-2" /> :
                  <span className="glyphicon glyphicon-chevron-down mr-2" />
              }

              <span className="text-uppercase">
                { translate('pastUserMealCartsTitle') }
              </span>
            </a>
          </div>
        </div>
      </div>

      {
        !isHidden &&
        userMealCarts &&
        userMealCarts.sort((a, b) => new Date(b.current_state_changed_at) - new Date(a.current_state_changed_at)).map(userMealCart => (
          <PastUserMealCart
            key={`pastUserMealCart-${userMealCart.uuid}`}
            userMealCart={userMealCart}
          />
          ))
      }

      {
        !isHidden && !userMealCarts &&
          <div className="card-footer">Loading</div>
      }

    </Container>
  );
};

PastUserMealCarts.propTypes = {
  mealId: string.isRequired,
  mealPlanId: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(PastUserMealCarts);
