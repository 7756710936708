import React from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { withI18n } from 'libs/support/i18n';

const Container = styled.div`
`;

const Text = styled.span`
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.greyChateau};
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lavendar};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 0.3rem;
  padding: 0 16px;
`;

const ItemContainer = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.colors.blackRussian};
  }

  > div:first-child {
    padding-top: 16px;
  }

  > div:last-child {
    padding-bottom: 16px;
    box-shadow: 0px 1px 0px ${props => props.theme.colors.lavendar};
  }
`;

const PastUserMealCart = ({
  userMealCart,
  translate,
}) => (
  <Container>
    <div className="card-footer">
      <div className="row">
        <Text className="col text-uppercase">
          {
              translate(
                'pastUserMealCartsOrderSubmittedAt',
                { date: moment(userMealCart.current_state_changed_at).format('h:mm A') },
              )
            }
        </Text>
      </div>
      <ItemsContainer>
        {
            userMealCart.cart_variations.map(cartVariation => (
              <ItemContainer key={`cartVariation-${cartVariation.uuid}`}>
                <div>
                  <div>
                    { `${cartVariation.quantity} x ${cartVariation.name}` }
                  </div>
                  <div>
                    { `${cartVariation.subtotal}` }
                  </div>
                </div>

                {
                  cartVariation.add_on_details &&
                    <div>
                      { cartVariation.add_on_details }
                    </div>
                }

                {
                  cartVariation.additional_instructions &&
                    <div>
                      { cartVariation.additional_instructions }
                    </div>
                }
              </ItemContainer>
            ))
          }
      </ItemsContainer>
    </div>
  </Container>
);

PastUserMealCart.propTypes = {
  userMealCart: object,
  translate: func.isRequired,
};

export default withI18n(PastUserMealCart);
