/* eslint max-len: 0 */

import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/userMealPlanSettingConstants';

const API_URL = '/user_meal_plan_settings';

export function saveUserMealPlanSetting(params) {
  const { SAVING_USER_MEAL_PLAN_SETTING, SAVE_USER_MEAL_PLAN_SETTING_SUCCESS, SAVE_USER_MEAL_PLAN_SETTING_FAILURE } = actionTypes;
  const snakes = createHumps(snakeCase);

  return api.save({
    types: [SAVING_USER_MEAL_PLAN_SETTING, SAVE_USER_MEAL_PLAN_SETTING_SUCCESS, SAVE_USER_MEAL_PLAN_SETTING_FAILURE],
    endpoint: API_URL,
    params: snakes(params),
    forceUpdate: true,
  });
}
