import { func, object, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { withI18n } from 'libs/support/i18n';

export const Tabs = withI18n(({ organisation, tab, onChange, translate }) => {
  const hasSummary =
    [
      organisation.has_active_meal_plan,
      organisation.has_active_pantry_program,
      organisation.has_event,
    ].filter((value) => value).length > 1;

  return (
    <div
      className="navbar-secondary px-3 pt-3"
      style={{ boxShadow: 'none' }}
      data-testid="reporting-tabs"
    >
      <ul className="nav">
        {hasSummary && (
          <li
            className={classNames('nav-item', { active: tab === 'summary' })}
            data-testid="reporting-tab"
            style={{ cursor: 'pointer' }}
          >
            <a className="nav-link" onClick={() => onChange('summary')}>
              {translate('financialReportsTabsSummary')}
            </a>
          </li>
        )}
        {organisation.has_active_meal_plan && (
          <li
            className={classNames('nav-item', { active: tab === 'meal_plans' })}
            data-testid="reporting-tab"
            style={{ cursor: 'pointer' }}
          >
            <a className="nav-link" onClick={() => onChange('meal_plans')}>
              {translate('financialReportsTabsMealPlans')}
            </a>
          </li>
        )}
        {organisation.has_active_pantry_program && (
          <li
            className={classNames('nav-item', {
              active: tab === 'pantry_programs',
            })}
            data-testid="reporting-tab"
            style={{ cursor: 'pointer' }}
          >
            <a className="nav-link" onClick={() => onChange('pantry_programs')}>
              {translate('financialReportsTabsPantryPrograms')}
            </a>
          </li>
        )}
        {organisation.has_event && (
          <li
            className={classNames('nav-item', { active: tab === 'events' })}
            data-testid="reporting-tab"
            style={{ cursor: 'pointer' }}
          >
            <a className="nav-link" onClick={() => onChange('events')}>
              {translate('financialReportsTabsEvents')}
            </a>
          </li>
        )}
      </ul>
      <hr style={{ margin: 0 }} />
    </div>
  );
});

Tabs.propTypes = {
  tab: string.isRequired,
  onChange: func.isRequired,
  organisation: object.isRequired,
};
