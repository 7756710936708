/* eslint max-len: 0 */

import React from 'react';

const Report = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.820821 1.09512C1.3023 0.614728 1.95533 0.344849 2.63625 0.344849H9.74596C9.95547 0.344849 10.1564 0.427889 10.3046 0.5757L15.6368 5.8959C15.785 6.04371 15.8682 6.24419 15.8682 6.45322V17.0936C15.8682 17.773 15.5977 18.4245 15.1162 18.9049C14.6348 19.3853 13.9817 19.6552 13.3008 19.6552H2.63625C1.95533 19.6552 1.3023 19.3853 0.820821 18.9049C0.339341 18.4245 0.0688477 17.773 0.0688477 17.0936V2.90643C0.0688477 2.22705 0.33934 1.57551 0.820821 1.09512ZM2.63625 1.9212C2.37435 1.9212 2.12319 2.025 1.93801 2.20977C1.75282 2.39453 1.64878 2.64513 1.64878 2.90643V17.0936C1.64878 17.3549 1.75282 17.6055 1.93801 17.7903C2.12319 17.975 2.37435 18.0788 2.63625 18.0788H13.3008C13.5627 18.0788 13.8139 17.975 13.9991 17.7903C14.1842 17.6055 14.2883 17.3549 14.2883 17.0936V6.7797L9.41875 1.9212H2.63625Z" fill="#7D9E1D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.74596 0.344849C10.1823 0.344849 10.5359 0.697728 10.5359 1.13303V5.66505H15.0783C15.5145 5.66505 15.8682 6.01793 15.8682 6.45322C15.8682 6.88852 15.5145 7.2414 15.0783 7.2414H9.74596C9.30967 7.2414 8.95599 6.88852 8.95599 6.45322V1.13303C8.95599 0.697728 9.30967 0.344849 9.74596 0.344849Z" fill="#7D9E1D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.62371 10.8867C3.62371 10.4514 3.97739 10.0985 4.41367 10.0985H11.5234C11.9597 10.0985 12.3134 10.4514 12.3134 10.8867C12.3134 11.322 11.9597 11.6749 11.5234 11.6749H4.41367C3.97739 11.6749 3.62371 11.322 3.62371 10.8867Z" fill="#7D9E1D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.62371 14.4335C3.62371 13.9982 3.97739 13.6453 4.41367 13.6453H11.5234C11.9597 13.6453 12.3134 13.9982 12.3134 14.4335C12.3134 14.8688 11.9597 15.2217 11.5234 15.2217H4.41367C3.97739 15.2217 3.62371 14.8688 3.62371 14.4335Z" fill="#7D9E1D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.62371 7.33992C3.62371 6.90463 3.97739 6.55175 4.41367 6.55175H6.1911C6.62739 6.55175 6.98107 6.90463 6.98107 7.33992C6.98107 7.77522 6.62739 8.1281 6.1911 8.1281H4.41367C3.97739 8.1281 3.62371 7.77522 3.62371 7.33992Z" fill="#7D9E1D" />
  </svg>
);

export default Report;
