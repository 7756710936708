import React from 'react';
import classNames from 'classnames';
import { bool, number, string } from 'prop-types';
import { formattedPrice } from 'libs/support/formattedPrice';
import VariationItem from '../../VariationItem';

const OrderableVariation = ({
  additionalInstructions,
  addOnDetails,
  itemName,
  quantity,
  subtotal,
  userName,
  isGuest,
}) => (
  <div className="order-item">
    <div className="tw-flex tw-items-center tw-gap-4">
      <div className="text-body tw-font-bold">
        {userName}
      </div>
      {isGuest && (
        <span className={classNames('tw-bg-gray-200 tw-text-gray-900', {
          'tw-rounded-sm tw-px-2 tw-text-[10px]': true,
        })}
        >
          Guest
        </span>
      )}
    </div>
    <div className="tw-w-full tw-grid tw-grid-cols-12 tw-justify-between tw-items-center">
      <div className="tw-w-full tw-col-span-8 md:tw-col-span-10 tw-overflow-hidden tw-flex tw-flex-col tw-gap-1">
        <VariationItem
          name={itemName}
          quantity={quantity}
        />
        {addOnDetails && (
          <div className={classNames('tw-flex tw-flex-row tw-justify-between tw-items-center', {
            'tw-text-xs tw-text-gray-700': true,
          })}
          >
            { addOnDetails }
          </div>
        )}
        {additionalInstructions && (
          <div className="truncate-instruction-text">
            { additionalInstructions }
          </div>
        )}
      </div>
      <div className="tw-col-span-4 md:tw-col-span-2 tw-font-bold tw-text-right">
        { formattedPrice(subtotal) }
      </div>
    </div>
  </div>
);

OrderableVariation.propTypes = {
  additionalInstructions: string,
  addOnDetails: string,
  itemName: string.isRequired,
  quantity: number.isRequired,
  subtotal: string.isRequired,
  userName: string.isRequired,
  isGuest: bool,
};

export default OrderableVariation;
