/* eslint-disable react/no-array-index-key */
/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React, { useRef, useState } from 'react';
import uppercamelcase from 'uppercamelcase';
import { object, bool, string, func } from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import mealPlanUsersActions from 'packs/meal_plan_users/actions';

import FieldComponent from 'libs/components/FieldComponent';

import FieldInput from './FieldInput';

const ROLES_LEGACY = ['user', 'admin'];
const ROLES = ['user', 'admin', 'osc'];

const renderRoleOptions = ({ flagIsEnabled, translate }) => (
  (flagIsEnabled('sticker_label_enabled') ? ROLES : ROLES_LEGACY).map((role) => (
    <option key={`option-${role}`} value={role}>
      { translate(`mealPlanUsersRoles${uppercamelcase(role)}`) }
    </option>
  ))
);

/**
 * NewUserForm
 */

const NewUserForm = ({
  actions,
  editing,
  flash,
  mealPlanId,
  selectedMealPlanUser,
  flagIsEnabled,
  translate,
}) => {
  const {
    firstName,
    lastName,
    email,
    role,
    department,
    referenceCode,
  } = selectedMealPlanUser.toJS();

  const form = useRef();
  const [internalError, setInternalError] = useState();

  const saveUser = (event) => {
    event.preventDefault();

    const params = $(form.current).serializeJSON({
      checkboxUncheckedValue: 'false',
    });

    return actions.saveUser(mealPlanId, selectedMealPlanUser.get('uuid'), params);
  };

  const handleReferenceCodeInputChange = (e) => {
    e.preventDefault();
    if (e.target.value.length > 3) {
      setInternalError('This field can accept a maximum of 3 characters.');
      e.target.value = e.target.value.substring(0, 3);
      return;
    }

    setInternalError(null);
  };

  return (
    <div className="card-body">
      {/* Buttons */}
      <div className="responsive-actions">
        <button className="btn btn-light btn-sm" onClick={actions.showUserList}>
          { translate('mealPlanUsersButtonBack') }
        </button>
        <button className="btn btn-primary btn-sm" type="submit" form="newUserForm">
          {
            editing ?
              translate('mealPlanUsersButtonUpdate')
              : translate('mealPlanUsersButtonCreate')
          }
        </button>
      </div>

      <form id="newUserForm" onSubmit={saveUser} ref={form}>
        <h6 className="mb-4">
          { translate('mealPlanUsersUserFormTitle') }
        </h6>

        { /* Errors */ }
        {
          !flash.get('errors').isEmpty() &&
          <div className="form-group row">
            <div className="col-sm-9 offset-md-3">
              <div className="alert alert-danger">
                <h6>
                  { translate('mealPlanUsersUserFormErrorTitle') }
                </h6>
                <ul>
                  {
                    flash.get('errors').map((error, i) => (
                      <li key={i}>
                        {error}
                      </li>
                      ))
                  }
                </ul>
              </div>
            </div>
          </div>
        }

        { /* Succes Alert */ }
        {
          flash.get('success') &&
          <div className="form-group row">
            <div className="col-sm-9 offset-md-3">
              <div className="alert alert-full alert-success text-center">
                { flash.get('success') }
              </div>
            </div>
          </div>
        }

        <FieldInput
          label={translate('mealPlanUsersUserFormFirstName')}
          defaultValue={firstName}
          name="meal_plan_user[first_name]"
          required
        />

        <FieldInput
          label={translate('mealPlanUsersUserFormLastName')}
          defaultValue={lastName}
          name="meal_plan_user[last_name]"
          required
        />

        <FieldInput
          label={translate('mealPlanUsersUserFormEmail')}
          defaultValue={email}
          name="meal_plan_user[email]"
          required
          disabled={editing}
        />

        <FieldComponent
          isRequired
          label="mealPlanUsersUserFormRole"
          type="select"
          name="meal_plan_user[role]"
          labelClassName="col-md-3 col-form-label"
          inputClassName="col-md-9"
          defaultValue={role}
          options={renderRoleOptions({ flagIsEnabled, translate })}
        />

        <FieldInput
          label={translate('mealPlanUsersUserFormDepartment')}
          defaultValue={department}
          name="meal_plan_user[department]"
          type="text"
          required={false}
        />

        <div className="tw-flex tw-flex-col">
          <FieldInput
            label={translate('mealPlanUsersUserFormReferenceCode')}
            defaultValue={referenceCode}
            name="meal_plan_user[reference_code]"
            required={false}
            type="text"
            onKeyUp={handleReferenceCodeInputChange}
          />
          {internalError &&
            <div className="row">
              <span className="col-md-3" />
              <span className="col-md-9 tw-text-xs tw-text-red-400 -tw-mt-2">{internalError}</span>
            </div>
          }
        </div>
      </form>
    </div>
  );
};

NewUserForm.propTypes = {
  actions: object.isRequired,
  editing: bool.isRequired,
  flash: object.isRequired,
  mealPlanId: string.isRequired,
  selectedMealPlanUser: object.isRequired,
  flagIsEnabled: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  mealPlanId: state.$$mealPlanUsersStore.get('mealPlanId'),
  flash: state.$$mealPlanUsersStore.getIn(['userForm', 'flash']),
  editing: !!state.$$mealPlanUsersStore.getIn(['byId', state.$$mealPlanUsersStore.getIn(['userForm', 'selectedUserId'])]),
  selectedMealPlanUser: state.$$mealPlanUsersStore.getIn(['byId', state.$$mealPlanUsersStore.getIn(['userForm', 'selectedUserId'])]) || Immutable.fromJS({}),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...mealPlanUsersActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(withI18n(NewUserForm)));
