/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import { number } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const MenuItemSkeletal = () => (
  <div className="card card-menu">
    <div className="card-header">
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-items-center w-60">
          <h6 className="card-menu-title tw-m-0">
            <span className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
          </h6>
        </div>
        <div className="tw-flex tw-justify-end tw-items-start w-40">
          <div className="tw-flex tw-items-center">
            <h6 className="tw-m-0 menu-item__price">
              <span className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-24 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body">
      <div className="tw-flex tw-flex-col flex-sm-row tw-justify-between h-100">
        <div className="tw-flex tw-flex-col tw-justify-between">
          <div className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-32 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
          <div className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-12 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
          <div className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-48 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
          <div className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-32 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
          <div className="tw-bg-gray-100 tw-rounded tw-h-4 tw-w-48 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80" />
        </div>
        <div className="tw-flex tw-items-end tw-justify-end">
          <div className="tw-w-32 tw-h-20 tw-bg-gray-50 tw-rounded-md tw-mt-1" />
        </div>
      </div>
    </div>
  </div>
);

const MenuItemsSkeletal = ({ size = 2 }) => (
  <div className="tw-flex tw-flex-col tw-gap-2 tw-mb-8 tw-animate-pulse">

    <div className="category-header">
      <div className="tw-block md:tw-flex tw-justify-between">
        <span className={classNames('tw-m-0 tw-bg-gray-200 tw-rounded', {
          'tw-h-8 tw-w-64 tw-bg-gradient-r tw-from-gray-300/50 tw-to-gray-400/80': true,
        })}
        />
      </div>
    </div>
    <div className="card-deck menu-items-vendors !tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-4">
      {
        Array.from(new Array(size)).map((_, index) => (
          <MenuItemSkeletal key={index} />
        ))
      }
    </div>
  </div>
);

MenuItemsSkeletal.propTypes = {
  size: number,
};

export default MenuItemsSkeletal;
