import { useMutation } from 'react-query';
import { httpClient } from 'libs/support/httpClient';

export const useExportMealPlanUsers = ({ sid, mealPlanId }) =>
  useMutation(
    async () => {
      const { data } = await httpClient.post(`/meal_plans/${mealPlanId}/meal_plan_users/export`, {
        sid,
      });

      return data;
    },
    {
      mutationKey: ['EXPORT_MEAL_PLAN_USERS', mealPlanId],
    },
  );
