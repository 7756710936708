/* eslint-disable no-underscore-dangle */
/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React from 'react';
import { string, bool, func } from 'prop-types';

/**
 * FieldInput
 */

const FieldInput = ({ name, defaultValue, label, type, disabled, required, onKeyUp, onBlur }) => {
  let _type = 'text';

  if (type) {
    _type = type;
  }

  let _required = false;

  if (required != null) {
    _required = required;
  }

  let _disabled = false;

  if (disabled != null) {
    _disabled = disabled;
  }

  return (
    <div className="form-group row">
      <label className="col-md-3 col-form-label" htmlFor={name}>
        {label}

        { _required && <span className="label-required"> * </span> }
      </label>
      <div className="col-md-9">
        <div className="row align-items-center">
          <div className="col">
            <input
              className="required-field form-control form-control-sm"
              name={name}
              type={_type}
              disabled={_disabled}
              defaultValue={defaultValue}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FieldInput.propTypes = {
  defaultValue: string,
  disabled: bool,
  type: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  onKeyUp: func,
  onBlur: func,
  required: bool,
};

export default FieldInput;
