/* eslint-disable max-len */
import React from 'react';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card } from '../../../styles';
import { Money } from '../../Money';
import {
  PROGRAM_COLORS,
  formatDate,
  formatMoney,
} from '../../utils';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
);

export const SpendingTrend = withI18n(({ budgetAndSpending, programs, translate }) => (
  <Card data-testid="reporting-summary-spending-trend">
    <div className="mb-2">Total Spend</div>
    <Money value={budgetAndSpending.totalSpend} fontSize={48} />
    <Bar
      height={80}
      options={{
          elements: {
            point: {
              radius: 6,
              hoverRadius: 6,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'end',
              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
              },
            },
            tooltip: {
              callbacks: {
                label: (item) => formatMoney(item.raw, { withCents: true, withSymbol: false }),
              },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: (value) => formatMoney(value, { withSymbol: true, withCents: false, compact: true }).replace(',000', 'k'),
                color: '#BFC1C4',
              },
            },
          },
        }}
      data={{
          labels: programs[0].data.map(({ step }) =>
            formatDate(step)),
          datasets: programs.map((report) => ({
            label: translate(`financialReportsProgramNames${uppercamelcase(report.type)}`),
            data: report.data.map(({ totalSpend }) => totalSpend),
            backgroundColor: PROGRAM_COLORS[report.type],
            borderColor: PROGRAM_COLORS[report.type],
            ...(report.type === 'Total' && {
              type: 'line',
              order: 0,
            }),
            ...(report.type !== 'Total' && {
              barThickness: 20,
              borderRadius: 25,
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: 3,
            }),
          })),
        }}
    />
  </Card>
));
