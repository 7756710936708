/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CatererTooltip = ({ children, classes, placement, title }) => (
  <a
    data-toggle="tooltip"
    data-placement={placement || 'top'}
    data-original-title={title}
  >
    {children}
  </a>
);

CatererTooltip.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string,
};

export default CatererTooltip;
