/* eslint max-len: 0 */

import React, { Fragment, memo } from 'react';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import PropTypes from 'prop-types';
import DailyLog from '../components/DailyLog';
import actions from '../actions';

global.Intl = Intl;

const PantryProgramDailyLogContainer = ({
  pantryProgram, dailyLog, ordersForDelivery, masterSupplyItems,
  fetchOrdersForDelivery, fetchMasterSupplyItems, saveDailyLog, fetchDailyLog,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Fragment>
        <DailyLog
          pantryProgram={pantryProgram}
          dailyLog={dailyLog}
          ordersForDelivery={ordersForDelivery}
          masterSupplyItems={masterSupplyItems}
          fetchOrdersForDelivery={fetchOrdersForDelivery}
          fetchMasterSupplyItems={fetchMasterSupplyItems}
          fetchDailyLog={fetchDailyLog}
          saveDailyLog={saveDailyLog}
        />
      </Fragment>
    </IntlProvider>
  );
};

PantryProgramDailyLogContainer.propTypes = {
  pantryProgram: PropTypes.object,
  dailyLog: PropTypes.object,
  ordersForDelivery: PropTypes.array,
  masterSupplyItems: PropTypes.array,
  fetchOrdersForDelivery: PropTypes.func.isRequired,
  fetchMasterSupplyItems: PropTypes.func.isRequired,
  fetchDailyLog: PropTypes.func.isRequired,
  saveDailyLog: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  $$pantryProgramStore,
}) => ({
  pantryProgram: $$pantryProgramStore.pantryProgram,
  dailyLog: $$pantryProgramStore.dailyLog,
  ordersForDelivery: $$pantryProgramStore.ordersForDelivery,
  masterSupplyItems: $$pantryProgramStore.masterSupplyItems,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...actions,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(memo(PantryProgramDailyLogContainer));
