/* eslint-disable react/no-array-index-key */
import React from 'react';
import dayjs from 'dayjs';
import { array, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { Card, Stack, TableWrapper } from '../../../styles';
import { Money } from '../../Money';
import { BudgetBar } from '../../BudgetBar';
import { Percentage } from '../../Percentage';

export const BudgetAndSpending = withI18n(({ budgetAndSpending, programs, translate }) => {
  const programsTotal = programs.find(({ type }) => type === 'Total');
  const showTotal = programsTotal.data.length > 1;

  return (
    <Card data-testid="reporting-summary-budget-and-spending">
      <Stack>
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          {translate('financialReportsTabsSummary')}
        </div>
        <BudgetBar total={budgetAndSpending.totalBudget} spend={budgetAndSpending.budgetSpend} />
        <TableWrapper fixedFirstCellWidth={180}>
          <table className="table">
            <thead>
              <tr>
                <th />
                {programsTotal.data.map(({ step }) => (
                  <th key={step}>{dayjs(step, 'YYYY-MM').format("MMM'YY")}</th>
                ))}
                {showTotal && <th>{translate('financialReportsTermsTotal')}</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold">{translate('financialReportsTermsBudget')}</td>
                {programsTotal.data.map(({ totalBudget }, i) => (
                  <td className="font-weight-bold" key={i}>
                    <Money value={totalBudget} />
                  </td>
                  ))}
                {showTotal && (
                  <td className="font-weight-bold">
                    <Money value={budgetAndSpending.totalBudget} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">{translate('financialReportsTermsSpend')}</td>
                {programsTotal.data.map(({ totalSpend, budgetSpendPercentage }, i) => (
                  <td className="font-weight-bold" key={i}>
                    <Money value={totalSpend} />
                    <br />
                    <Percentage value={budgetSpendPercentage} />
                  </td>
                  ))}
                {showTotal && (
                  <td className="font-weight-bold">
                    <Money value={budgetAndSpending.totalSpend} />
                    <br />
                    <Percentage value={budgetAndSpending.budgetSpendPercentage} />
                  </td>
                )}
              </tr>
              <tr>
                <td className="font-weight-bold">{translate('financialReportsTermsUnused')}</td>
                {programsTotal.data.map(({ budgetUnused, budgetUnusedPercentage }, i) => (
                  <td key={i}>
                    <Money value={budgetUnused} />
                    <br />
                    <Percentage value={budgetUnusedPercentage} />
                  </td>
                  ))}
                {showTotal && (
                  <td>
                    <Money value={budgetAndSpending.budgetUnused} />
                    <br />
                    <Percentage value={budgetAndSpending.budgetUnusedPercentage} />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </Stack>
    </Card>
  );
});

BudgetAndSpending.propTypes = {
  budgetAndSpending: object.isRequired,
  programs: array.isRequired,
};
