import React from 'react';
import { string } from 'prop-types';

const AmountField = ({
  labelClassName = '',
  className = '',
  title,
  value,
  ...props
}) => (
  <div className="tw-grid tw-grid-cols-12 tw-gap-2 tw-flex tw-items-center tw-py-1">
    <div className={`tw-col-span-6 ${labelClassName}`}>
      {title}
    </div>

    <div className={`tw-col-span-6 tw-text-right ${className}`} {...props}>
      {value}
    </div>
  </div>
);

AmountField.propTypes = {
  className: string,
  labelClassName: string,
  title: string.isRequired,
  value: string.isRequired,
};

export default AmountField;
