/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';

import { statuses } from 'packs/meals/reducers/userMealCartReducer';

import SelectYourMealHeader from './SelectYourMealHeader';
import MealSkippedHeader from './MealSkippedHeader';
import MealSelectedHeader from './MealSelectedHeader';
import MissedOrderHeader from './MissedOrderHeader';
import MealEditingHeader from './MealEditingHeader';
import CafeOrderingGreetingHeader from './CafeOrderingGreetingHeader';
import CafeOrderingConfirmedHeader from './CafeOrderingConfirmedHeader';

const statusIsConfirmed = (status) => (
  [statuses.confirmed].includes(status)
);

/**
 * Header
 */
const Header = ({
  mealStore,
  userMealOrderable,
}) => {
  let status = null;
  const cafeOrdering = mealStore.getIn(['groupOrderSetting', 'cafeOrdering']);
  status = userMealOrderable.get('currentState');

  /* CAFE ORDERING */
  if (cafeOrdering) {
    if (statusIsConfirmed(status)) { return (<CafeOrderingConfirmedHeader />); }
    return (<CafeOrderingGreetingHeader />);
  }

  /* NON CAFE ORDERING */
  if (status === statuses.skipped) {
    return (<MealSkippedHeader />);
  } else if (statusIsConfirmed(status)) {
    return (<MealSelectedHeader />);
  } else if ([statuses.editing].includes(status)) {
    return (<MealEditingHeader />);
  } else if (mealStore.get('preview') || (mealStore.get('open') && !mealStore.get('processing'))) {
    return (<SelectYourMealHeader />);
  }

  return (<MissedOrderHeader />);
};

const mapStateToProps = (state) => ({
  mealStore: state.$$mealStore,
  userMealOrderable: state.$$mealStore.get('confirmed') ? state.$$userMealOrderStore : state.$$userMealCartStore,
});

Header.propTypes = {
  mealStore: object.isRequired,
  userMealOrderable: object.isRequired,
};

export default connect(mapStateToProps, null)(Header);
