/* eslint max-len: 0 */

import mealReducer, * as fromMeal from 'packs/meals/reducers/mealReducer';
import mealReportReducer from 'packs/meals/reducers/mealReportReducer';
import userMealCartReducer, * as fromUserMealCart from 'packs/meals/reducers/userMealCartReducer';
import userMealOrderReducer, * as fromUserMealOrder from 'packs/meals/reducers/userMealOrderReducer';
import cartsReducer, * as fromCarts from 'packs/meals/reducers/cartsReducer';
import vendorsReducer, * as fromVendors from 'packs/meals/reducers/vendorsReducer';
import orderVariationsReducer, * as fromOrderVariations from 'packs/common/reducers/orderVariationsReducer';
import cartVariationsReducer, * as fromCartVariations from 'packs/common/reducers/cartVariationsReducer';
import menuItemsReducer, * as fromMenuItems from 'packs/meals/reducers/menuItemsReducer';
import customMenuItemsReducer, * as fromCustomMenuItems from 'packs/meals/reducers/customMenuItemsReducer';
import userMealPlanSettingReducer, * as fromUserMealPlanSetting from 'packs/meals/reducers/userMealPlanSettingReducer';
import adminMealCartReducer, * as fromAdminMealCart from 'packs/meals/reducers/adminMealCartReducer';
import flashReducer, * as fromFlash from 'packs/meals/reducers/flashReducer';

export default {
  $$mealStore: mealReducer,
  $$mealReportStore: mealReportReducer,
  $$userMealCartStore: userMealCartReducer,
  $$userMealOrderStore: userMealOrderReducer,
  $$orderVariationsStore: orderVariationsReducer,
  $$cartVariationsStore: cartVariationsReducer,
  $$cartsStore: cartsReducer,
  $$vendorsStore: vendorsReducer,
  $$menuItemsStore: menuItemsReducer,
  $$customMenuItemsStore: customMenuItemsReducer,
  $$userMealPlanSettingStore: userMealPlanSettingReducer,
  $$adminMealCartStore: adminMealCartReducer,
  $$flashStore: flashReducer,
};

export const initialStates = {
  $$mealState: fromMeal.$$initialState,
  $$userMealCartState: fromUserMealCart.$$initialState,
  $$userMealOrderState: fromUserMealOrder.$$initialState,
  $$cartsState: fromCarts.$$initialState,
  $$orderVariationsState: fromOrderVariations.$$initialState,
  $$cartVariationsState: fromCartVariations.$$initialState,
  $$vendorsState: fromVendors.$$initialState,
  $$menuItemsState: fromMenuItems.$$initialState,
  $$customMenuItemsState: fromCustomMenuItems.$$initialState,
  $$userMealPlanSettingState: fromUserMealPlanSetting.$$initialState,
  $$adminMealCartState: fromAdminMealCart.$$initialState,
  $$flashState: fromFlash.$$initialState,
};
