import React from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { number, string } from 'prop-types';
import { formatMoney } from './utils';

ChartJS.register(
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  LineController,
);

export const BudgetBar = ({ total, spend, color = '#B0D958' }) => (
  <Bar
    height={20}
    data={{
        labels: ['Budget', 'Spend'],
        datasets: [
          {
            label: 'Budget',
            data: [total, spend],
            barThickness: 20,
            backgroundColor: [color, '#EAEAEB'],
            borderRadius: 32,
          },
        ],
      }}
    options={{
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (item) => `${formatMoney(item.raw, { withCents: true, withSymbol: false })}`,
            },
          },
        },
      }}
  />
);

BudgetBar.propTypes = {
  total: number.isRequired,
  spend: number.isRequired,
  color: string,
};
