/* eslint-disable max-len */
import React from 'react';

const RefreshIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M14 8.00615C14 9.08942 13.684 10.092 13.052 11.0138C12.42 11.9357 11.5662 12.6638 10.4906 13.1983C9.41495 13.7328 8.24933 14 6.99372 14C5.713 14 4.53692 13.7346 3.46547 13.2037C2.39402 12.6728 1.54858 11.9482 0.929148 11.03C0.309716 10.1117 1.57798e-07 9.10377 2.53754e-07 8.00615C2.77587e-07 7.73354 0.106727 7.50038 0.32018 7.30669C0.533633 7.11299 0.795217 7.01614 1.10493 7.01614C1.41465 7.01614 1.68251 7.11299 1.90852 7.30669C2.13453 7.50038 2.24754 7.73354 2.24754 8.00615C2.24753 9.13246 2.71002 10.092 3.63498 10.8847C4.55994 11.6774 5.67952 12.0738 6.99372 12.0738C8.29118 12.0738 9.39611 11.6774 10.3085 10.8847C11.2209 10.092 11.6771 9.13246 11.6771 8.00615C11.6771 7.03049 11.3235 6.1463 10.6161 5.35357C9.90882 4.56085 9.0864 4.13938 8.14888 4.08916L8.66368 4.50884C8.88132 4.70971 8.99013 4.9321 8.99013 5.17602C8.99013 5.43428 8.88132 5.66385 8.66368 5.86472C8.17818 6.26646 7.63827 6.26646 7.04395 5.86472L4.58296 3.77709C4.36532 3.63361 4.2565 3.39687 4.2565 3.06687C4.2565 2.77274 4.36532 2.55393 4.58296 2.41045L7.04395 0.279784C7.27833 0.093261 7.53363 -5.65308e-07 7.80987 -5.41159e-07C8.14469 -5.11887e-07 8.42511 0.0950546 8.65112 0.285165C8.87713 0.475275 8.99014 0.706636 8.99014 0.979246C8.99014 1.25903 8.88132 1.49219 8.66368 1.67871L8.14888 2.08762C9.82302 2.33871 11.2167 3.02562 12.33 4.14835C13.4433 5.27107 14 6.55701 14 8.00615Z" fill="#95989D" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" transform="matrix(-1 0 0 1 14 0)" />
      </clipPath>
    </defs>
  </svg>
);

export default RefreshIcon;
