import _, { snakeCase } from 'lodash';
import createHumps from 'lodash-humps/lib/createHumps';
import humps from 'lodash-humps';

export function toSnakeCase(data) {
  return _.transform(data, (result, value, key) => {
    const newKey = _.snakeCase(_.trim(key));
    if (_.isArray(value) || _.isObject(value)) {
      // eslint-disable-next-line no-param-reassign
      value = toSnakeCase(value);
    }
    // eslint-disable-next-line no-param-reassign
    result[newKey] = value;
  }, _.isArray(data) ? [] : {});
}

export const camelize = (object) => humps(object);

export const decamelize = (object) => createHumps(snakeCase)(object);
