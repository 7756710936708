/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import moment from 'moment';
import { func, object, string } from 'prop-types';
import Alert from 'react-bootstrap/Alert';

import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { NomealIcon } from 'libs/components/Icons';
import GroupOrderMealCardHeadlineContainer from 'packs/meals/containers/GroupOrderMealCardHeadlineContainer';
import { notBlank } from 'libs/support/string';
import MenuItem from '../../shared/MenuItem';

const CardBody = ({
  meal,
  userMealOrderable,
  errorMsg,
  successMsg,
  userRoleIn,
}) => {
  const renderCategories = ({ categories }) => categories.map(category => (
    <div className="media border-bottom p-2" key={`category-${category.get('vendorName')}`}>
      <div className="card-thumbnail">
        {
          notBlank(category.get('vendorLogo')) ?
            <img className="rounded mr-3" alt="" src={category.get('vendorLogo')} /> :
            <img className="rounded mr-3" alt="" src="https://via.placeholder.com/56x56" />
        }
      </div>
      <div className="media-body">
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="mt-0">
              { category.get('vendorName') }
            </h6>
            <span className="text-muted">
              {
                (category.get('categories') || category.get('menuItems'))
                  .map(v => v.get('name'))
                  .join(', ')
              }
            </span>
          </div>
          <div />
        </div>
      </div>
    </div>
  ));

  const renderOrderHeader = () => {
    if (userMealOrderable.get('isSkipped')) {
      const scheduleAt = meal.get('scheduleAt');
      return (
        <div className="d-flex flex-column align-items-center">
          <div className="mb-3">
            <NomealIcon />
          </div>
          You’ve chosen to skip your meal for &#x2028;
          <strong>
            { `${moment(scheduleAt).format('dddd D')} ${moment(scheduleAt).format('MMM').toUpperCase()}` }
          </strong>
        </div>
      );
    } else if (userMealOrderable.get('isSelected')) {
      if (meal.get('isConfirmed')) {
        return meal.get('orders').map(order => (
          order
            .get('orderVariations')
            .filter(ov => notBlank(ov.get('menuItem')))
            .map(orderVariation => (
              <MenuItem
                key={`item-${orderVariation.get('uuid')}`}
                menuItem={orderVariation.get('menuItem')}
                vendorName={order.get('vendorName')}
              />
            ))
        ));
      }
      return meal.get('carts').map(cart => (
        cart
          .get('cartVariations')
          .filter(ov => notBlank(ov.get('menuItem')))
          .map(cartVariation => (
            <MenuItem
              key={`item-${cartVariation.get('uuid')}`}
              menuItem={cartVariation.get('menuItem')}
              vendorName={cart.get('vendorName')}
            />
          ))
      ));
    }
    if (meal.get('isConfirmed')) {
      return (
        <div className="d-flex flex-column align-items-center">
          <div className="mb-3">
            <NomealIcon />
          </div>
          <div className="text-danger">You did not order a meal</div>
        </div>
      );
    }
    return (
      <Fragment>
        { renderCategories({ categories: meal.get('vendorCategories') }) }
        { renderCategories({ categories: meal.get('vendorMenuItems') }) }
      </Fragment>
    );
  };

  return (
    <div className="card-body">
      {
        errorMsg &&
          <Alert variant="danger" className="text-center">
            { errorMsg }
          </Alert>
      }

      {
        successMsg &&
          <Alert variant="success" className="text-center">
            <div dangerouslySetInnerHTML={{ __html: successMsg }} />
          </Alert>
      }

      {
        userRoleIn(['admin', 'osc']) &&
          <GroupOrderMealCardHeadlineContainer />
      }

      { renderOrderHeader() }
    </div>
  );
};

CardBody.propTypes = {
  meal: object.isRequired,
  userMealOrderable: object.isRequired,
  successMsg: string,
  errorMsg: string,
  userRoleIn: func.isRequired,
};

export default withAppContext(withI18n(CardBody));
