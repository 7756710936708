/* eslint-disable max-len */
/* eslint react/no-danger: 0 */

import React from 'react';
import { any, bool, func, object, string } from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useWindowSize } from 'packs/common/hooks/useWindowSize';
import Immutable from 'immutable';
import OrderLinks from 'packs/common/components/OrderLinks';

import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { EditIcon } from 'libs/components/Icons';

const GroupOrderActionsDropdown = ({
  btnClassName,
  btnStyle = {},
  className,
  mealPlanId,
  mealId,
  onClickDownloadExcel,
  onClickDownloadMealLabelPdf,
  onClickDownloadMenuCardPdf,
  orders,
  children,
  translate,
  flagIsEnabled,
  toggleShowEditOrderModal,
  mealOpen,
  userRoleIn,
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= 991;
  const status = (orders && orders.size > 0) ? 'confirmed' : 'draft';
  const mealLabelUrl = `/meal_plans/${mealPlanId}/meal_labels/${mealId}?status=${status}`;
  const menuCardUrl = `/meal_plans/${mealPlanId}/menu_cards/${mealId}`;

  return (
    <div style={{ display: 'inline-block' }}>
      <Dropdown className={`dropdown ${className}`}>
        <Dropdown.Toggle id="dropdown-basic" className={`${btnClassName}`} style={btnStyle}>
          {children}
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" className="!tw-w-80 !tw-rounded-lg !tw-max-h-80 tw-overflow-y-auto">
          <OrderLinks orders={orders} />
          {
            flagIsEnabled('group_order_edit_items') && userRoleIn(['admin']) && mealOpen &&
            <>
              <Dropdown.Item
                href="#"
                data-turbolinks="false"
                onClick={toggleShowEditOrderModal}
                className="tw-flex tw-items-center"
              >
                <span className="tw-flex tw-items-center tw-gap-2">
                  <EditIcon />
                  <span className="tw-text-base !tw-font-bold">
                    {translate('groupOrderActionsDropdownButtonsEditOrders')}
                  </span>
                </span>
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          }
          <div className="tw-relative tw-max-h-52 tw-overflow-hidden tw-overflow-y-auto">
            <Dropdown.Item
              href="#"
              data-turbolinks="false"
              onClick={onClickDownloadExcel}
              className="tw-flex tw-items-center"
            >
              {
                flagIsEnabled('group_order_edit_items') &&
                <span className="tw-flex tw-items-center tw-gap-1">
                  <span className="tw-text-base !tw-font-bold">
                    {translate('groupOrderActionsDropdownButtonsDownloadMealData')}
                  </span>
                </span>
              }
            </Dropdown.Item>
            {flagIsEnabled('sticker_label_enabled') &&
            <>
              <Dropdown.Item
                href={mealLabelUrl}
                target={`${isMobile ? '_self' : '_blank'}`}
                className="d-flex align-items-center"
              >
                {flagIsEnabled('group_order_edit_items') &&
                  <span className="tw-flex tw-items-center tw-gap-1">
                    <span className="tw-text-base !tw-font-bold">
                      {translate('groupOrderActionsDropdownButtonsLabelEditor')}
                    </span>
                  </span>
                }
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                data-turbolinks="false"
                onClick={onClickDownloadMealLabelPdf}
                className="d-flex align-items-center"
              >
                {flagIsEnabled('group_order_edit_items') &&
                  <span className="tw-flex tw-items-center tw-gap-1">
                    <span className="tw-text-base !tw-font-bold">
                      {translate('groupOrderActionsDropdownButtonsDownloadLabels')}
                    </span>
                  </span>
                }
              </Dropdown.Item>
            </>}
            {flagIsEnabled('menu_card_enabled') &&
            <>
              <Dropdown.Item
                href={menuCardUrl}
                target={`${isMobile ? '_self' : '_blank'}`}
                className="tw-flex tw-items-center"
              >
                <span className="tw-flex tw-items-center tw-gap-1">
                  <span className="tw-text-base !tw-font-bold">
                    {translate('groupOrderActionsDropdownButtonsMenuCardEditor')}
                  </span>
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                data-turbolinks="false"
                onClick={onClickDownloadMenuCardPdf}
                className="tw-flex tw-items-center"
              >
                <span className="tw-flex tw-items-center tw-gap-1">
                  <span className="tw-text-base !tw-font-bold">
                    {translate('groupOrderActionsDropdownButtonsDownloadMenuCard')}
                  </span>
                </span>
              </Dropdown.Item>
            </>}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

GroupOrderActionsDropdown.defaultProps = {
  btnClassName: '',
  className: '',
  orders: Immutable.fromJS([]),
};

GroupOrderActionsDropdown.propTypes = {
  btnClassName: string,
  btnStyle: any,
  children: any.isRequired,
  className: string,
  mealPlanId: string.isRequired,
  mealId: string.isRequired,
  onClickDownloadExcel: func.isRequired,
  onClickDownloadMealLabelPdf: func.isRequired,
  onClickDownloadMenuCardPdf: func.isRequired,
  orders: object,
  translate: func.isRequired,
  flagIsEnabled: func,
  toggleShowEditOrderModal: func,
  mealOpen: bool,
  userRoleIn: any,
};

export default withAppContext(withI18n(GroupOrderActionsDropdown));
