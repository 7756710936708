/* eslint-disable max-len */
import React from 'react';

const Search = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L15.7071 14.8787C16.0976 15.2692 16.0976 15.9024 15.7071 16.2929C15.3166 16.6834 14.6834 16.6834 14.2929 16.2929L10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14ZM7 12C9.76142 12 12 9.76142 12 7C12 4.23858 9.76142 2 7 2C4.23858 2 2 4.23858 2 7C2 9.76142 4.23858 12 7 12Z" fill="white" />
  </svg>
);

export default Search;
