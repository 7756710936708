export function getHexColor(name) {
  switch (name) {
    case 'white':
      return '#ffffff';
    case 'lima':
      return '#7d9e1d';
    case 'grey-chateau':
      return '#95989d';
    default:
      return '#000000';
  }
}
