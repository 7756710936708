import React from 'react';
import { string, number, bool } from 'prop-types';

const VariationItem = ({ name, quantity, isGuest = false }) => (
  <div className="order-item-title tw-w-full tw-flex-row tw-gap-1">
    {isGuest && (
      <span className="tw-bg-gray-200 tw-text-gray-900 tw-rounded-sm tw-h-[1rem] tw-px-2 tw-text-[10px]">
        Guest
      </span>
    )}
    <div className="tw-flex tw-items-start tw-gap-1">
      <span className="tw-min-w-4">
        {`${quantity} x`}
      </span>
      <span className="tw-inline-block tw-font-bold text-truncate tw-max-w-[90%]">
        {name}
      </span>
    </div>
  </div>
);

VariationItem.propTypes = {
  name: string.isRequired,
  quantity: number.isRequired,
  isGuest: bool,
};

export default VariationItem;
