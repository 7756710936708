/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { useReport } from '../../../queries/use-report';
import { Stack } from '../../../styles';
import { Summary } from './Summary';
import { SpendingTrend } from './SpendingTrend';
import { PantryProgram } from './PantryProgram';
import { PANTRY_PROGRAM_COLORS, PROGRAM_COLORS } from '../../utils';
import { SectionDivider } from '../../SectionDivider';
import { OrdersCountTrend } from './OrdersCountTrend';
import { DailyCostTrend } from './DailyCostTrend';
import { trackViewedPantryProgramsTab } from '../../../events';

export const PantryPrograms = withI18n(({ organisation, filters, translate }) => {
  const { data: summary } = useReport(
    organisation.uuid,
    'pantry_programs/pantry_program_summary',
    filters,
  );

  const { data: pantryPrograms } = useReport(
    organisation.uuid,
    'pantry_programs/pantry_programs',
    filters,
  );

  const filterPantryPrograms = (pantryPrograms) => pantryPrograms.filter((pantryProgram) => pantryProgram.id !== 'total');

  const assignColorToPantryPrograms = (pantryPrograms) => {
    const colors = [...PANTRY_PROGRAM_COLORS];

    return pantryPrograms.map((pantryProgram) => {
      if (pantryProgram.id === 'total') {
        return { ...pantryProgram, color: PROGRAM_COLORS.Total };
      }
      return { ...pantryProgram, color: colors.shift() };
    });
  };

  useEffect(() => trackViewedPantryProgramsTab(), []);

  return (
    <Stack className="p-3" data-testid="reporting-pantry-programs">
      {summary && pantryPrograms && (
        <Summary
          summary={summary}
          data={pantryPrograms.find((pantryProgram) => pantryProgram.id === 'total').data}
        />
      )}
      <SectionDivider text={translate('financialReportsTermsPantryProgramBreakdown')} />
      {summary && pantryPrograms && (
        <SpendingTrend
          summary={summary}
          pantryPrograms={assignColorToPantryPrograms(pantryPrograms)}
        />
      )}
      {pantryPrograms &&
        filterPantryPrograms(assignColorToPantryPrograms(pantryPrograms)).map((pantryProgram) => (
          <PantryProgram key={pantryProgram.id} pantryProgram={pantryProgram} />
        ))}
      <SectionDivider text={translate('financialReportsTermsOrders')} />
      {pantryPrograms && <OrdersCountTrend pantryPrograms={pantryPrograms} />}
      <SectionDivider text={translate('financialReportsDailyCostTitle')} />
      {pantryPrograms && <DailyCostTrend pantryPrograms={assignColorToPantryPrograms(pantryPrograms)} />}
    </Stack>
  );
});

PantryPrograms.propTypes = {
  organisation: object.isRequired,
  filters: object.isRequired,
};
