import React from 'react';
import { func } from 'prop-types';

import { withI18n } from 'libs/support/i18n';

const ConfirmationModal = ({
  onDelete,
  onCancel,
  translate,
}) => (
  <div
    data-testid="group-order-confirmation-modal"
    className="alert tw-overflow-hidden tw-bg-[#FAE6E6] tw-m-4"
  >
    <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-gap-4">
      <p className="tw-m-0">
        {translate('editOrderModalConfirmationModalDescription')}
      </p>
      <div className="tw-flex tw-justify-end tw-gap-2 tw-w-full md:tw-w-64">
        <button type="button" className="btn btn-light btn-sm tw-w-full md:tw-w-auto" onClick={onCancel}>
          Cancel
        </button>
        <button type="button" className="btn btn-primary btn-sm tw-w-full md:tw-w-auto" onClick={onDelete}>
          Confirm
        </button>
      </div>
    </div>
  </div>
);

ConfirmationModal.propTypes = {
  onDelete: func,
  onCancel: func,
  translate: func.isRequired,
};

export default withI18n(ConfirmationModal);
