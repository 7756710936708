import React from 'react';
import CircleCheckIcon from 'libs/components/Icons/CircleCheck';

export default function SubmittedSuccessfullyStep() {
  return (
    <div>
      <div>
        <CircleCheckIcon color="#7D9E1D" size={48} />
      </div>
      <div className="my-3">
        <h3 className="mt-2">Step Completed</h3>
        <p className="mt-2">Photo of pantry have been uploaded successfully.</p>
      </div>
    </div>
  );
}
SubmittedSuccessfullyStep.propTypes = {
};
