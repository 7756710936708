/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import defaultPhoto from 'assets/images/food-placeholder.jpg';

/**
 * MenuItemPhoto
 */

const MenuItemPhoto = ({ photo }) => (
  <div className="card-thumbnail mr-3 mt-2">
    <img src={photo || defaultPhoto} alt="" className="rounded" />
  </div>
);

MenuItemPhoto.propTypes = {
  photo: PropTypes.string,
};

export default injectIntl(MenuItemPhoto);
