
import * as api from 'libs/middlewares/api';
import * as actionTypes from '../../common/constants/menuItemConstants';

const API_URL = '/menu_items';

/*
 * Fetch Menu Items in Order Form
 */
export function fetchMenuItem(id) {
  const { FETCHING_MENU_ITEM, FETCH_MENU_ITEM_ORDER_SUCCESS, FETCH_MENU_ITEM_ORDER_FAILURE } = actionTypes;

  return api.get({
    types: [FETCHING_MENU_ITEM, FETCH_MENU_ITEM_ORDER_SUCCESS, FETCH_MENU_ITEM_ORDER_FAILURE],
    endpoint: API_URL,
    params: null,
    id,
  });
}

/*
 * Reset Selected Menu Items in Order Form
 */
export function resetSelectedMenuItem() {
  return {
    type: actionTypes.RESET_SELECTED_MENU_ITEM,
  };
}
