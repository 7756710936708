/* eslint no-return-assign: 0, max-len: 0, consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import QuantityModifier from '../../../../Shared/QuantityModifier';

/**
 * MenuItemInfo
 */

const MenuItemInfo = ({
  quantity,
  minQuantity,
  subtotal,
  mealAdmin,
  mealEditable,
  updateQuantityHandler,
}) => {
  if (mealAdmin && mealEditable) {
    return (
      <div className="order-actions tw-flex tw-justify-end tw-items-center md:tw-gap-1">
        <div className="tw-min-w-[80px]">
          <QuantityModifier
            quantity={quantity}
            minQuantity={minQuantity}
            updateQuantityHandler={updateQuantityHandler}
            isEditable
          />
        </div>

        <div className="tw-min-w-14 tw-text-right tw-font-semibold tw-text-gray-900 tw-text-sm md:tw-text-base">
          {/* Subtotal */}
          { subtotal }
        </div>
      </div>
    );
  }
  if (!mealAdmin) return null;

  return (
    <div className="order-actions tw-flex tw-flex-col md:tw-flex-row tw-justify-end align-items-end md:tw-divide-x md:tw-gap-2">
      <div className="tw-relative tw-text-sm md:tw-text-base">
        Quantity: {quantity}
      </div>
      {
        mealAdmin && (
          <div className="md:tw-pl-2 tw-text-sm md:tw-text-base tw-font-bold tw-text-gray-900">
            {/* Subtotal */}
            { subtotal }
          </div>
        )
      }
    </div>
  );
};

MenuItemInfo.propTypes = {
  quantity: PropTypes.number.isRequired,
  minQuantity: PropTypes.number.isRequired,
  subtotal: PropTypes.string.isRequired,
  mealAdmin: PropTypes.bool.isRequired,
  mealEditable: PropTypes.bool,
  updateQuantityHandler: PropTypes.func.isRequired,
};

export default injectIntl(MenuItemInfo);
